import React, { useCallback } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.43;
  border: none;
  padding: 5px;
  text-align: right;
  &.error {
    border: 1px solid ${props => props.theme.negative};
  }

  /* &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.primaryText} !important;
  } */

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  } /* FF 4-18 */
  &:focus::-moz-placeholder {
    color: transparent;
  } /* FF 19+ */
  &:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

const Input = React.forwardRef(
  (
    { inputMode = 'text', value = '', reset = false, onChange, ...restProps },
    ref
  ) => {
    let inputRef = React.useRef();
    let inputEl = ref;

    if (!inputEl) {
      inputEl = inputRef;
    }

    const handleInputChange = useCallback(
      event => {
        if (event.target.type !== 'number') {
          const caret = event.target.selectionStart;
          const element = event.target;
          window.requestAnimationFrame(() => {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          });
        }
        onChange(event);
      },
      [onChange]
    );

    return (
      <StyledInput
        {...restProps}
        value={value}
        inputMode={inputMode}
        onChange={handleInputChange}
        autoComplete={'new-password'}
        ref={inputEl}></StyledInput>
    );
  }
);

export default Input;
