/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';

import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { selectedProductState } from 'selectors';

import RangeSilderStyle, { SliderLabel } from './rangeSlider_style';

class RangeSlider extends React.Component {
  constructor(props) {
    super(props);
    // leverages = [1, 2, 25, 50, 100]
    // selectedValue = 25
    // sliderValue = 2 // index of 25 in leverages array
    this.state = {
      maxLeverage: props.leverages.length - 1,
      minLeverage: 0,
      rangeLabels: props.leverages,
      selectedValue: props.initialValue,
      sliderValue: props.leverages.indexOf(props.initialValue),
      totalLables: props.leverages.length,
    };
    this.rangeInput = React.createRef();
  }

  static getDerivedStateFromProps(props) {
    return {
      maxLeverage: props.leverages.length - 1,
      minLeverage: 0,
      rangeLabels: props.leverages,
      selectedValue: props.initialValue,
      totalLables: props.leverages.length,
      sliderValue: props.leverages.indexOf(props.initialValue),
    };
  }

  // value = 1, 2, 25, 50, 100
  onLabelClick = value => {
    const { symbol, contract_type: contractType } = this.props.selected_product;
    const leverageValue = this.state.rangeLabels.indexOf(value);
    this.setState(prevState => ({
      selectedValue: value,
      sliderValue: prevState.rangeLabels.indexOf(value),
    }));
    this.rangeInput.current.value = leverageValue;
    this.props.onChange(value);

    trackMixpanelEvent('Leverage Slider Moved - Trade - Ordering', {
      product_name: symbol,
      product_type: contractType,
    });
  };

  // index of the value
  // 1, 2, 3, 4, 5
  handleChange = event => {
    const leverageValue = Number(event.target.value);
    const newValue = this.state.rangeLabels[leverageValue];
    this.setState(prevState => ({
      selectedValue: newValue,
      sliderValue: prevState.rangeLabels[leverageValue],
    }));
    this.rangeInput.current.value = leverageValue;
    this.props.onChange(newValue);
  };

  render() {
    return (
      <RangeSilderStyle className="range-slider">
        <div className="range">
          <input
            type="range"
            ref={this.rangeInput}
            step="1"
            min={this.state.minLeverage}
            max={this.state.maxLeverage}
            onChange={this.handleChange}
            value={this.state.sliderValue}
            data-testid="range-slider-input"
          />
        </div>
        <ul className="range-labels">
          {this.state.rangeLabels.map(label => (
            <SliderLabel
              data-testid={`slider-label-${label}`}
              key={label}
              totalItems={this.state.totalLables}
              totalLables={100 / (this.state.totalLables - 1)}
              onClick={() => this.onLabelClick(label)}
              className={classNames({
                active: label === this.state.selectedValue,
              })}
            >{`${label}x`}</SliderLabel>
          ))}
        </ul>
      </RangeSilderStyle>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected_product: selectedProductState(state),
  };
};

export default connect(mapStateToProps)(RangeSlider);
