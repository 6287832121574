import React, {
  createContext,
  MouseEvent,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from 'react';

import Popover from '@material-ui/core/Popover';
import { StylesProvider } from '@material-ui/core/styles';
import cx from 'classnames';

import { noop } from 'helpers/utils';
import Box from 'UIKit/Box';

import classes from './popover.module.scss';
import {
  PopoverTooltip as PopoverTooltipProps,
  PopoverContent as PopoverContentProps,
  TooltipContext as TooltipContextValues,
  PopoverAnchor,
} from './PopoverMaterial.interface';

const TooltipContext = createContext<TooltipContextValues | null>(null);

/**
 * Popover(compound component pattern) tooltip component that wraps,
 * the Content and the Anchor component to show a clickable popover
 *
 * @typedef {object} PopoverTooltipProps
 *
 * @param {Function} onOpen - callback function to be called when popover is opened
 * @param {Function} onClose - callback function to be called when popover is closed
 *
 * @component Default usage of a PopoverTooltip
 * @example
 * ```tsx
 * import { PopoverTooltip } from 'UIKit/PopoverMaterial';
 *
 * return (
 *    <PopoverTooltip>
 *     <PopoverTooltip.Anchor data-testid={`bracket-${bracket}-title`}>
 *       Anchor(Text or Element or Component)
 *     </PopoverTooltip.Anchor>
 *
 *     <PopoverTooltip.Content>
 *       Tooltip Content(Text or Element or Component)
 *     </PopoverTooltip.Content>
 *   </PopoverTooltip>
 * );
 * ```
 *
 * @component Capture Popover's open and close events, using `onOpen` and `onClose` props
 * @example
 * ```tsx
 * import { PopoverTooltip } from 'UIKit/PopoverMaterial';
 *
 * return (
 *    <PopoverTooltip onOpen={(event) => {}} onClose={(event) => {}}>
 *     <PopoverTooltip.Anchor data-testid={`bracket-${bracket}-title`}>
 *       Anchor Text or Element or Component
 *     </PopoverTooltip.Anchor>
 *
 *     <PopoverTooltip.Content>
 *       Tooltip Content(Text or Element or Component)
 *     </PopoverTooltip.Content>
 *   </PopoverTooltip>
 * );
 * ```
 */
const Tooltip = ({ children, onOpen = null, onClose = null }: PopoverTooltipProps) => {
  const [anchorElement, setAnchorElement] = useState<HTMLSpanElement | null>(null);

  const providerValue = useMemo(() => {
    const handleOnOpen = (event: MouseEvent<HTMLSpanElement>) => {
      setAnchorElement(event.currentTarget);

      if (typeof onOpen === 'function') {
        onOpen(event);
      }
    };

    const handleOnClose = (event: MouseEvent) => {
      setAnchorElement(null);

      if (typeof onClose === 'function') {
        onClose(event);
      }
    };

    return {
      onOpen: handleOnOpen,
      onClose: handleOnClose,
      anchorElement,
    };
  }, [anchorElement, onClose, onOpen]);

  return (<TooltipContext.Provider value={providerValue} data-palette="Tooltip">{children}</TooltipContext.Provider>);
};

const Anchor = ({
  children,
  className,
  'data-testid': testId = 'tooltip-anchor',
  as = 'span',
  isUnderlined = true,
  shouldAttachActiveClass = true,
  renderAnchor,
}: PopoverAnchor) => {
  const context = useContext(TooltipContext);

  if (!context) {
    throw new Error(
      'UIKit/PopoverMaterial: "Anchor" must be used within "PopoverTooltip"'
    );
  }

  const { onOpen, anchorElement } = context;

  const isOpen = Boolean(anchorElement);

  return (
    <Box
      data-testid={testId}
      as={as}
      role="button"
      tabIndex={0}
      className={cx(className, {
        'dashed-underline pointer': isUnderlined,
        active: shouldAttachActiveClass && isOpen,
      })}
      onClick={onOpen}
      onKeyDown={noop}
    >
      {renderAnchor ? renderAnchor({ isOpen }) : children}
    </Box>
  );
};

const Content = ({
  'data-testid': testId = 'tooltip-content',
  children,
  noPreAppliedStyles = false,
  contentText,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin = { vertical: 'bottom', horizontal: 'center' },
  contentTextClassName = '',
  popoverPaperClass,
  as = 'div',
  ...delegateProps
}: PopoverContentProps) => {
  const context = useContext(TooltipContext);

  if (!context) {
    throw new Error(
      'UIKit/PopoverMaterial: "Content" must be used within "PopoverTooltip"'
    );
  }

  const { onClose, anchorElement } = context;

  let content: ReactNode = null;

  if (children) {
    content = noPreAppliedStyles ? (
      children
    ) : (
      <Box
        as={as}
        className={cx(classes.tooltipContent, [contentTextClassName])}
        data-testid={testId}
      >
        {children}
      </Box>
    );
  } else {
    content = (
      <p
        className={cx(classes.popoverContent, {
          [contentTextClassName]: contentTextClassName,
        })}
        data-palette="Content">
        {contentText}
      </p>
    );
  }

  return (
    <StylesProvider injectFirst>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={onClose}
        classes={{
          paper: cx(classes.popoverContainer, popoverPaperClass),
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...delegateProps}
      >
        {content}
      </Popover>
    </StylesProvider>
  );
};

Tooltip.Anchor = Anchor;
Tooltip.Content = Content;

export { Tooltip as PopoverTooltip };
