/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Render from 'components/render';
import { ERC20_DISABLED_TIME } from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import { dateFormat, getDiff, timeNow, toLocal, toUTC } from 'helpers/day';
import { CloseIcon, ExternalLink } from 'styles/assets/icons';
import Box from 'UIKit/Box';

import styles from './ercDisableStrip.module.scss';

const ERC20DisableStrip = () => {
  const { t } = useTranslation(['common']);

  const [showStrip, setShowStrip] = useState(false);

  const disableTime = dateFormat(
    toLocal(toUTC(ERC20_DISABLED_TIME)),
    `${TIME_FORMATS.DD_MMM_h_mm_A_z}`
  );
  const currentTime = timeNow();
  const timeDiff = getDiff(toUTC(currentTime), toUTC(ERC20_DISABLED_TIME), 'seconds');
  const isPastDisableTime = timeDiff > 0;

  useEffect(() => {
    // strip should not be shown after 10:59PM UTC. Its a temporary time, so adding here instead of constants file.
    const stripClosingTime = new Date('2023-04-12T22:59:00.000Z');

    const diffFromClosingTime = getDiff(
      toUTC(currentTime),
      toUTC(stripClosingTime),
      'seconds'
    );
    const shouldShowStrip = diffFromClosingTime < 0;
    setShowStrip(shouldShowStrip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    setShowStrip(false);
  };

  return (
    <Render when={showStrip}>
      <Box as="div" className={styles.stripContainer} flex="flex" alignItems="center">
        <Box as="p" className={styles.text}>
          {isPastDisableTime
            ? t('common:disabledErc20')
            : t('common:disablingErc20', {
                disableTime,
              })}
          <a
            href="https://www.delta.exchange/blog/support/announcement-regarding-upcoming-ethereum-network-upgrade"
            className={styles.blogLink}
            data-testid="erc20-announcement-url-link"
            aria-hidden="true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLink className={styles.linkIcon} />
          </a>
        </Box>
        <Box
          data-testid="erc20-close-modal-icon"
          as="span"
          onClick={onCloseModal}
          className={styles.closeIcon}
        >
          <CloseIcon />
        </Box>
      </Box>
    </Render>
  );
};

export default ERC20DisableStrip;
