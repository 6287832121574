import { type ReactNode } from 'react';

import cx from 'classnames';

import Render from 'components/render';
import { Warning } from 'styles/assets/icons';
import Box, { type BoxCustomProps } from 'UIKit/Box';

import classes from './deltaHelperText.module.scss';

export interface DeltaHelperTextProps {
  id: string;
  content: string | ReactNode;
  isError?: boolean;
  className?: string;
  showIcon?: boolean;
  gap?: BoxCustomProps['gap'];
}

const DeltaHelperText = ({
  id,
  isError = true,
  content,
  className = '',
  gap = 8,
  showIcon = true,
}: DeltaHelperTextProps) => {
  return (
    <Box
      renderWhen={Boolean(content)}
      data-testid={`${id}-helper`}
      flexDir="row"
      alignItems="start"
      justifyContent="start"
      fontSize="sm2"
      gap={gap}
      textColor={isError ? 'negative' : 'secondary'}
      className={cx(classes.root, [className])}
    >
      <Render when={showIcon}>
        <Warning
          data-testid={`${id}-helper-icon`}
          width={14}
          height={14}
          color={isError ? 'var(--negative)' : 'var(--highlight)'}
        />
      </Render>

      <Box
        data-testid={`${id}-helper-text`}
        as={typeof content === 'string' ? 'span' : 'div'}
      >
        {content}
      </Box>
    </Box>
  );
};

export default DeltaHelperText;
