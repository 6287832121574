/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const VerificationInProgressIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="92"
    height="96"
    viewBox="0 0 92 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="VerificationInProgressIcon">
    <path
      d="M46 85.6667C22.8034 85.6667 4 66.8633 4 43.6667C4 20.4701 22.8034 1.66666 46 1.66666C69.1966 1.66666 88 20.4701 88 43.6667C88 66.8633 69.1966 85.6667 46 85.6667ZM46 77.2667C54.9113 77.2667 63.4576 73.7267 69.7588 67.4255C76.06 61.1242 79.6 52.5779 79.6 43.6667C79.6 34.7554 76.06 26.2091 69.7588 19.9079C63.4576 13.6067 54.9113 10.0667 46 10.0667C37.0887 10.0667 28.5424 13.6067 22.2412 19.9079C15.94 26.2091 12.4 34.7554 12.4 43.6667C12.4 52.5779 15.94 61.1242 22.2412 67.4255C28.5424 73.7267 37.0887 77.2667 46 77.2667Z"
      fill="url(#paint0_angular_97_14)"
      stroke="url(#paint1_angular_97_14)"
      strokeWidth="2"
    />
    <g clipPath="url(#clip0_97_14)">
      <path
        d="M57.3459 32.0792C57.7061 31.9944 57.9746 31.6717 57.9746 31.2857V28.8159C57.9746 28.3654 57.6092 28 57.1586 28H34.8415C34.391 28 34.0256 28.3654 34.0256 28.8159V31.2858C34.0256 31.6719 34.294 31.9946 34.6542 32.0793C34.9006 36.4735 37.1828 40.5006 40.8663 42.9685L42.4062 44.0001L40.8663 45.0317C37.1826 47.4994 34.9005 51.5266 34.6542 55.9208C34.294 56.0056 34.0256 56.3283 34.0256 56.7143V59.1842C34.0256 59.6347 34.391 60.0001 34.8415 60.0001H57.1586C57.6092 60.0001 57.9746 59.6347 57.9746 59.1842V56.7143C57.9746 56.3283 57.7061 56.0056 57.3459 55.9208C57.0995 51.5266 54.8174 47.4995 51.1337 45.0317L49.5938 44.0001L51.1337 42.9685C54.8174 40.5006 57.0995 36.4734 57.3459 32.0792ZM35.6574 29.6318H56.3427V30.4699H35.6574V29.6318ZM56.3427 58.3682H35.6574V57.5301H56.3427V58.3682ZM47.6738 43.3222C47.4476 43.4737 47.3119 43.7278 47.3119 44.0001C47.3119 44.2723 47.4476 44.5265 47.6738 44.678L50.2255 46.3874C53.4498 48.5473 55.4596 52.0587 55.7103 55.8984H36.2899C36.5405 52.0588 38.5504 48.5474 41.7746 46.3874L44.3264 44.678C44.5525 44.5265 44.6882 44.2723 44.6882 44.0001C44.6882 43.7278 44.5525 43.4737 44.3264 43.3222L41.7746 41.6127C38.5504 39.4527 36.5405 35.9413 36.2899 32.1017H55.7103C55.4596 35.9414 53.4498 39.4527 50.2255 41.6127L47.6738 43.3222Z"
        fill="#F6A609"
      />
      <path
        d="M48.1278 39.2406H43.8722C43.534 39.2406 43.2307 39.4493 43.11 39.7652C42.9892 40.0812 43.076 40.439 43.3281 40.6645L45.4559 42.5683C45.6107 42.7068 45.8053 42.7761 45.9998 42.7761C46.1943 42.7761 46.389 42.7068 46.5437 42.5683L48.6715 40.6645C48.9236 40.4389 49.0104 40.0811 48.8896 39.7652C48.7693 39.4493 48.466 39.2406 48.1278 39.2406Z"
        fill="#F6A609"
      />
      <path
        d="M45.9666 47.117L43.8972 48.4463C42.0878 49.6584 41.0018 51.693 41.0018 53.871H51.4062C51.4062 51.693 50.3202 49.6584 48.5108 48.4463L46.4413 47.117C46.2953 47.0294 46.1128 47.0294 45.9666 47.117Z"
        fill="#F6A609"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_angular_97_14"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46 43.6667) rotate(172.504) scale(41.9937)"
      >
        <stop stopColor="var(--textTertiary)" />
        <stop offset="1" stopColor="var(--primaryBackground)" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_97_14"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46 43.6667) rotate(173.517) scale(48.519)"
      >
        <stop stopColor="var(--textTertiary)" />
        <stop offset="0.997314" stopColor="var(--primaryBackground)" />
      </radialGradient>
      <clipPath id="clip0_97_14">
        <rect width="32" height="32" fill="white" transform="translate(30 28)" />
      </clipPath>
    </defs>
  </svg>
);

export default VerificationInProgressIcon;
