import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { isAuthenticated } from 'actions/user';
import Render from 'components/render';
import routes from 'constants/routes';
import { useAppSelector } from 'storeHooks';
import AlreadyVerifiedIcon from 'styles/assets/icons/AlreadyVerifiedIcon';
import { SetTimeOut } from 'types/utils';
import { Box } from 'UIKit';

import styles from './emailVerificationWithLink.module.scss';

const UserAlreadyVerified = () => {
  const history = useHistory();
  const isLoggedIn = useAppSelector(reduxState => isAuthenticated(reduxState?.user));
  const { t } = useTranslation(['auth']);
  const timerRef = useRef<SetTimeOut>();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (isLoggedIn) {
        history.push(routes.webAppHome);
      } else {
        history.push(routes.login);
      }
    }, 3000);

    return () => clearTimeout(timerRef.current);
  }, [isLoggedIn, history]);
  return (
    <Box className={styles.alreadyVerified}>
      <AlreadyVerifiedIcon className={styles.alreadyVerifiedIcon} />
      <Box className={styles.heading}>{t('auth:alreadyVerified')}</Box>
      <Box className={styles.subHeading}>
        <Render when={isLoggedIn} else={t('auth:redirectToLoginPage')}>
          {t('auth:redirectToMarketsPage')}
        </Render>
      </Box>
    </Box>
  );
};

export default UserAlreadyVerified;
