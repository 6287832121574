import { matchPath } from 'react-router';

import { any } from 'helpers/ramda';

// Check whether to render a component on a particular routes or not.
export const showComponentOnSelectedRoute = matchRoutes => location => {
  return any(path => matchPath(location.pathname, path), matchRoutes);
};

export const postMessageToMobileApp = (messageType, payload) => {
  try {
    if (window.isRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          messageType,
          payload,
        })
      );
    }
  } catch (error) {}
};

/**
 * Problem:
 * We render ReCAPTCHA component and pass it a ref to access various methods on it.
 * In case recaptcha is disabled, the recaptchaRef will be null, any of the code relying on recaptcha methods will break.
 * 
 * Solution:
 * These recaptchaRefDefaultMethods will act as a pollyfill in case recaptcha is disabled.
 * This way we won't have to worry about recaptcha being disabled while calling these methods.
 * 
 * Usage example:
 * ```
 * const recaptchaRef = useRef<ReCAPTCHA>(recaptchaRefDefaultMethods);
 * <Render when={!RECAPTCHA_DISABLED}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={RECAPTCHA_SITE_KEY}
        size="invisible"
        theme="dark"
        badge="bottomleft"
     />
  </Render>
 * ```
 */
export const recaptchaRefDefaultMethods = {
  executeAsync: async () => '',
  reset: () => {},
  execute: () => {},
  getValue: () => '',
  getWidgetId: () => null,
};
