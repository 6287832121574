import { ifElse, equals } from 'helpers/ramda';
import useMount from 'hooks/useMount';
import { Fn } from 'types/utils';

const useAppOnlineStatus = (onlinefn: Fn, offlineFn: Fn) => {
  useMount(() => {
    const listener = () => ifElse(equals(true), onlinefn, offlineFn)(navigator.onLine);

    window.addEventListener('online', listener);
    window.addEventListener('offline', listener);
  });
};

export default useAppOnlineStatus;
