/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

import {
  addProductToBasket,
  changeBasketOrdersScreen,
  deleteProductFromBasket,
  editOrdersFromBasket,
} from 'actions/basketOrders';
import { ORDER_SIDE, BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
import { ORDER_TYPE } from 'constants/enums';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import {
  basketOrderCurrentScreenSelector,
  basketOrderOrdersSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { Product } from 'types/IProducts';

type side = 'buy' | 'sell';
type valueOf<T> = T[keyof T];

const useBasketBuySellButtonState = (
  product: Product,
  page?: string
): {
  buyActive: boolean;
  sellActive: boolean;
  handleBuySellClick: (side: valueOf<side>, isTableHoverButton: boolean) => void;
} => {
  const [buyActive, setbuyActive] = useState(false);
  const [sellActive, setSellActive] = useState(false);
  const currentScreenView = useAppSelector(basketOrderCurrentScreenSelector);
  const ordersInBasket = useAppSelector(basketOrderOrdersSelector);
  const marginMode = useAppSelector(userMarginModeSelector);
  const dispatch = useAppDispatch();

  const checkBuySellActive = () => {
    const order = ordersInBasket[product?.id];
    if (order) {
      if (order.side === ORDER_SIDE.BUY) {
        setbuyActive(true);
        setSellActive(false);
      } else if (order.side === ORDER_SIDE.SELL) {
        setbuyActive(false);
        setSellActive(true);
      }
    } else {
      setbuyActive(false);
      setSellActive(false);
    }
  };

  useEffect(() => {
    checkBuySellActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersInBasket, product]);

  const handleBuySellClick = (side: valueOf<side>, isTableHoverButton: boolean) => {
    const order = ordersInBasket[product.id];
    // in mobile orders are getting added to basket on input quantity changes, so on clicking buy/sell ,order
    // side gets edited .
    if (order) {
      if (order.side === side) {
        dispatch(deleteProductFromBasket(order));
      } else {
        const payload = {
          ...order,
          side,
        };
        dispatch(editOrdersFromBasket(payload));
      }
    } else {
      const payload = {
        product_id: product.id,
        side,
        symbol: product.symbol,
        asset: product?.underlying_asset?.symbol,
        order_type: ORDER_TYPE.LIMIT,
        limit_price: 0,
        size: 0,
        contract_type: product.contract_type,
      };
      dispatch(addProductToBasket(payload));
      trackMixpanelEvent(`Basket Order - ${page} - Buy/Sell Button Clicked`, {
        underlying_coin: product?.underlying_asset?.symbol,
        side,
        margin_type: marginMode,
      });
    }

    if (
      currentScreenView !== BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW &&
      isTableHoverButton
    ) {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
    }
  };

  return { buyActive, sellActive, handleBuySellClick };
};

export default useBasketBuySellButtonState;
