import React, { memo } from 'react';

import MediaQuery from 'react-responsive';
import styled from 'styled-components';

// import { TICK_FALLBACK } from 'constants/constants';
import { device } from 'helpers/screenSizes';
import {
  getFormattedVolume,
  numberPrettified,
  // afterDecimalLength,
  addZeroesUntilCorrectPrecision,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
// import PropTypes from 'PropTypes';
import { turnoverStateBySymbolSelector } from 'selectors/tickerSelectors';

const Wrapper = styled.div`
  .currency {
    color: ${props => props.theme.secondaryText};
  }
  .volume {
    color: ${props => props.theme.primaryText};
  }
`;

// All OI and volume numbers are in $. so their precisions will always be 2 decimal digits
const fractionLength = 2;

export const RenderVolume = subscribe(
  ({ product, isMobile, className, showUSDSymbol = false }) => {
    if (!product) {
      return (
        <span
          className={`24-hr-volume ${className} volume`}
          data-palette="RenderVolume">-</span>
      );
    }

    if (product.trading_status !== 'operational') {
      return '';
    }

    const volume = turnoverStateBySymbolSelector(product.symbol);
    const number = getFormattedVolume(isMobile)(volume);

    if (!parseFloat(number)) {
      return (
        (<span
          className={`24-hr-volume ${className} volume`}
          data-palette="RenderVolume">
          {showUSDSymbol
            ? `$${addZeroesUntilCorrectPrecision(0, fractionLength)}`
            : addZeroesUntilCorrectPrecision(0, fractionLength)}
        </span>)
      );
    }

    const prettifiedNumber = numberPrettified(volume, fractionLength, fractionLength);

    return isMobile ? (
      <>
        <div className="volume" data-palette="RenderVolume">
          {' '}
          {showUSDSymbol ? `$${prettifiedNumber}` : prettifiedNumber}{' '}
        </div>
        {!showUSDSymbol && <div className="currency" data-palette="RenderVolume">USD</div>}
      </>
    ) : (
      <div data-palette="RenderVolume">
        <div className="volume" title={volume} data-precision={fractionLength}>
          {' '}
          {showUSDSymbol ? `$${prettifiedNumber}` : prettifiedNumber}{' '}
        </div>
        {!showUSDSymbol && <div className="currency" data-palette="RenderVolume">USD</div>}
      </div>
    );
  }
);

const Volume24Hr = ({ product, className, symbol }) => {
  return (
    <Wrapper className="24-hr-volume">
      <MediaQuery query={device.up.md}>
        <RenderVolume
          product={product}
          isMobile={false}
          className={className}
          showUSDSymbol
          symbol={symbol}
        />
      </MediaQuery>

      <MediaQuery query={device.down.md}>
        <RenderVolume product={product} isMobile className={className} showUSDSymbol />
      </MediaQuery>
    </Wrapper>
  );
};

// Volume24Hr.propTypes = {
//   product: PropTypes.marketProducts.isRequired,
//   symbol: PropTypes.string,
// };

export default memo(Volume24Hr);
