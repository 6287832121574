import React, { useState, useEffect } from 'react';

import ReactTable from 'react-table';

import ExpiryDatesList from 'components/options_chain/OptionsChartDropdown/ExpiryDatesList';
import { formatDataInTableFormat } from 'components/options_chain/OptionsChartDropdown/utils';
import WidgetTabs from 'components/tabs';
import { getFilteredDataForOptionChains } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { useAppSelector } from 'hooks';
import {
  futureOrdersInBasketBySelectedUnderlying,
  futuresProductsSelector,
  OptionChainsProductList,
  optionsOrdersInBasketBySelectedUnderlying,
  selectedProductSelector,
} from 'selectors';
// import { Product } from 'types/IOpenPosition';

import BasketTable from './basketTableStyles';
import ClearAndDoneButton from './clearAndDoneButton';
import { FuturesColumn, OptionsColumn } from './column';
import styles from './productsSelection.module.scss';

const ProductsSelectionScreen = () => {
  const [currentTab, setCurrentTab] = useState('options-tab');
  const [productList, setProductList] = useState([]);
  const [expiryDates, setExpiryDates] = useState([]);
  const [currentSelectedDate, setCurrentSelectedDate] = useState('All Expiries');

  const optionsProductsData = useAppSelector(state => OptionChainsProductList(state));
  const futuresProductsData = useAppSelector(state => futuresProductsSelector(state));
  const selectedProduct = useAppSelector(state => selectedProductSelector(state));
  // const ordersInBasket = useAppSelector(state => basketOrderOrdersSelector(state));
  const optionsOrders = useAppSelector(state =>
    optionsOrdersInBasketBySelectedUnderlying(state)
  );
  const futuresOrders = useAppSelector(state =>
    futureOrdersInBasketBySelectedUnderlying(state)
  );
  // const {
  //   underlying_asset,
  // } = selectedProduct as Product;

  const underlyingAssetSymbol = selectedProduct?.underlying_asset?.symbol;

  // const totalOptionsInBasket = () => {
  //   console.log('DEBUG options', Object.values(ordersInBasket), underlyingAssetSymbol);
  //   return Object.values(ordersInBasket).reduce((acc, order) => {
  //     console.log('DEBUG options',order.asset === underlyingAssetSymbol , order.asset , underlyingAssetSymbol);
  //     if (isOptions(order.contract_type) && order.asset === underlyingAssetSymbol) {
  //       console.log('DEBUG options', ordersInBasket, underlyingAssetSymbol);
  //       // eslint-disable-next-line no-param-reassign
  //       acc += 1;
  //     }
  //     return acc;
  //   }, 0);
  // };

  // const totalFuturesInBasket = () => {
  //   console.log('DEBUG futures', Object.values(ordersInBasket), underlyingAssetSymbol);
  //   return Object.values(ordersInBasket).reduce((acc, order) => {
  //     console.log('DEBUG futures',order.asset === underlyingAssetSymbol , order.asset , underlyingAssetSymbol);
  //     if (isFutures(order.contract_type && order.asset === underlyingAssetSymbol)) {
  //       console.log('DEBUG futures', ordersInBasket, underlyingAssetSymbol);
  //       // eslint-disable-next-line no-param-reassign
  //       acc += 1;
  //     }
  //     return acc;
  //   }, 0);
  // };

  // const optionsOrdersCount = totalOptionsInBasket();
  // const futuresOrdersCount = totalFuturesInBasket();

  const updateDesktopView = (expiryDate: string | null) => {
    let data = getFilteredDataForOptionChains(
      optionsProductsData.tableData,
      expiryDate,
      underlyingAssetSymbol
    );
    data = formatDataInTableFormat(data, '');
    setProductList(data);
  };

  const changeExpiryAndProductList = () => {
    if (currentTab === 'options-tab') {
      if (optionsProductsData) {
        setExpiryDates(
          optionsProductsData.expiryTimesDropDownList[underlyingAssetSymbol]
        );
        updateDesktopView(
          currentSelectedDate === 'All Expiries' ? null : currentSelectedDate
        );
        // setProductList(optionsProductsData.tableData);
      }
    } else if (futuresProductsData) {
      setProductList(futuresProductsData);
    }
  };

  useEffect(() => {
    changeExpiryAndProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedDate, selectedProduct]);

  const tabList = [
    {
      label: `${underlyingAssetSymbol} Options (${optionsOrders.length})`,
      id: 'options-tab',
      tabName: `${underlyingAssetSymbol} Options`,
    },
    {
      label: `${underlyingAssetSymbol} Futures (${futuresOrders.length})`,
      id: 'futures-tab',
      tabName: `${underlyingAssetSymbol} Futures`,
    },
  ];

  const handleTabClick = ({ id }: any) => {
    setCurrentTab(id);
    if (id === 'futures-tab') {
      trackMixpanelEvent('Basket Order - Futures Tab Clicked', {
        underlying_coin: underlyingAssetSymbol,
        margin_type: 'portfolio',
      });
    }
  };

  return (
    (<div
      className={styles['productsSelection-container']}
      data-palette="ProductsSelectionScreen">
      <div className={styles['tab-header']}>
        <WidgetTabs
          data-testid="tabs-list"
          tabList={tabList}
          onClick={handleTabClick}
          activeTab={currentTab}
          className={styles['products-tabs']}
        />
        <ClearAndDoneButton
          data-testid="clear-done-button"
          asset={underlyingAssetSymbol}
        />
      </div>
      {currentTab === 'options-tab' && (
        <ExpiryDatesList
          expiryDates={expiryDates ?? []}
          selectedExpiryDate={currentSelectedDate}
          setSelectedExpiryDate={setCurrentSelectedDate}
          selectedAsset={underlyingAssetSymbol}
          sliderClassName={styles['slider-container']}
          listItemClassName={styles['list-item']}
        />
      )}
      <BasketTable>
        {currentTab === 'options-tab' && (
          <ReactTable
            columns={OptionsColumn(underlyingAssetSymbol)}
            data={productList}
            showPagination={false}
            className={styles['baskets-product-table']}
            defaultPageSize={5000}
            minRows={0}
          />
        )}
        {currentTab === 'futures-tab' && (
          <ReactTable
            columns={FuturesColumn()}
            data={futuresProductsData.filter(
              product =>
                product?.underlying_asset?.symbol === underlyingAssetSymbol &&
                product?.settling_asset?.symbol === 'USDT'
            )}
            showPagination={false}
            className={styles['baskets-product-table']}
            defaultPageSize={5000}
            minRows={0}
          />
        )}
      </BasketTable>
    </div>)
  );
};

export default ProductsSelectionScreen;
