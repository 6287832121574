import React, { ReactNode } from 'react';

import Portal from '@material-ui/core/Portal';

const STRIPS_ORDER = {
  CONNECTION_STATUS_STRIP: 1,
  MAINTENANCE_STRIP: 2,
  KYC_REFRESH_STRIP: 3,
  TRADING_REVOKED_STRIP: 4,
  INFORMATION_STRIP: 5,
};

const StripPortal = ({
  isSticky,
  children,
}: {
  isSticky: boolean;
  children: ReactNode;
}) => {
  const stickyContainer = document.getElementById('stickyStripsContainer');
  const nonStickyContainer = document.getElementById('nonStickyStripsContainer');
  const container = isSticky ? stickyContainer : nonStickyContainer;
  return <Portal container={container}>{children}</Portal>;
};

export { STRIPS_ORDER };
export default StripPortal;
