import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { scrollToTop } from 'helpers/utils';

// Scrolls page to top on route change
const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    requestAnimationFrame(() => {
      scrollToTop();
    });
  }, [pathname]);
};

export default useScrollToTop;
