/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useMemo } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import { noop } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
import { basketOrderSwitchSelector, contractTypeSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';

import { SortDown, SortUp } from './icons';
import styles from './index.module.scss';

export const Sort = props => {
  const { isUpActive, isDownActive, onUpClick, onDownClick } = props;
  return (
    (<div
      data-current-sort={isUpActive ? 'sort-desc' : isDownActive ? 'sort-asc' : ''}
      className={styles['sort-wrapper']}
      data-palette="Sort">
      <SortUp
        data-testid="JEIhsvhgo6zGO5a8cdtRu"
        className={`${styles.sortUp} ${isUpActive ? styles.active : ''}`}
        onClick={onUpClick}
      />
      <SortDown
        data-testid="c36amkg4GzMHb3sx_NxPX"
        className={isDownActive ? styles.active : ''}
        onClick={onDownClick}
      />
    </div>)
  );
};

Sort.propTypes = {
  isUpActive: PropTypes.bool,
  isDownActive: PropTypes.bool,
  onUpClick: PropTypes.func,
  onDownClick: PropTypes.func,
};

const TableHeaders = ({
  noData,
  setSortBy,
  sortBy,
  showFunds,
  favoritesShown,
  className,
}) => {
  const { t } = useTranslation(['trading']);
  const contractType = useAppSelector(contractTypeSelector);
  const onSortChangeHandler = useCallback(
    (field, direction) => {
      trackMixpanelEvent('Sort Clicked - Product Dropdown - Product Table', {
        column_name: field,
      });
      if (sortBy.field === field && sortBy.direction === direction) {
        setSortBy({ field: '', direction: '' });
      } else {
        setSortBy({ field, direction });
      }
    },
    [sortBy, setSortBy]
  );

  const onColumnLabelClick = useCallback(
    field => {
      trackMixpanelEvent('Sort Clicked - Product Dropdown - Product Table', {
        column_name: field,
      });
      if (field !== sortBy.field) {
        setSortBy({ field, direction: 'asc' });
      } else {
        setSortBy(prev => ({
          ...prev,
          direction: prev.direction === 'asc' ? 'desc' : 'asc',
        }));
      }
    },
    [sortBy, setSortBy]
  );

  const isBasketOrdersView = useSelector(state => basketOrderSwitchSelector(state));

  const isSortable = !isBasketOrdersView;
  const showVolume = !isBasketOrdersView;

  const activeSort = useMemo(
    () =>
      isSortable && {
        volumeUp: sortBy.field === 'volume' && sortBy.direction === 'asc',
        volumeDown: sortBy.field === 'volume' && sortBy.direction === 'desc',
        change24Up: sortBy.field === 'change24' && sortBy.direction === 'asc',
        change24Down: sortBy.field === 'change24' && sortBy.direction === 'desc',
        returns24Up: sortBy.field === 'returns24' && sortBy.direction === 'asc',
        returns24Down: sortBy.field === 'returns24' && sortBy.direction === 'desc',
        itdUp: sortBy.field === 'itd' && sortBy.direction === 'asc',
        itdDown: sortBy.field === 'itd' && sortBy.direction === 'desc',
      },
    [sortBy, isSortable]
  );

  return (
    (<div
      className={cn(styles['dropdown-table-header'], {
        [styles[className]]: className,
      })}
      data-palette="TableHeaders">
      <div
        className={cn(styles.header, {
          'no-contracts': noData,
          [styles.spacedHeader]: favoritesShown,
        })}
      >
        <span className={styles.contract}>
          {showFunds
            ? t('trading:dropdownTableHeaders.strategy')
            : isBasketOrdersView
            ? t('trading:dropdownTableHeaders.contract')
            : t('trading:dropdownTableHeaders.name')}
        </span>
      </div>
      <MediaQuery query={device.up.md}>
        <div className={`${styles.header}`}>
          <span className={styles['last-price-label']}>
            {t('trading:dropdownTableHeaders.lastPrice')}
          </span>
        </div>
        <div className={`${styles.header}`}>
          <span
            data-testid="change-24h-header"
            className={styles.change}
            onClick={() => onColumnLabelClick('change24')}
            role="button"
            tabIndex={0}
            onKeyPress={noop}
          >
            24h {t('trading:dropdownTableHeaders.chg')}
          </span>
          <Sort
            isUpActive={activeSort.change24Up}
            isDownActive={activeSort.change24Down}
            onUpClick={() => onSortChangeHandler('change24', 'asc')}
            onDownClick={() => onSortChangeHandler('change24', 'desc')}
          />
        </div>
        <div className={`${styles.header}`}>
          <span
            data-testid="volume-24h-header"
            className={styles.volume}
            onClick={() => onColumnLabelClick('volume')}
            role="button"
            tabIndex={0}
            onKeyPress={noop}
          >
            24h {t('trading:dropdownTableHeaders.vol')}
          </span>
          <Sort
            isUpActive={activeSort.volumeUp}
            isDownActive={activeSort.volumeDown}
            onUpClick={() => onSortChangeHandler('volume', 'asc')}
            onDownClick={() => onSortChangeHandler('volume', 'desc')}
          />
        </div>
      </MediaQuery>
      <MediaQuery query={device.down.md}>
        {!showFunds && (
          <>
            <div
              data-testid="mobile-price-header"
              className={cn(styles.header, styles.priceHeader)}
              onClick={() => onColumnLabelClick('volume')}
              role="button"
              tabIndex={0}
              onKeyPress={noop}
              data-palette="TableHeaders">
              <div className={styles.customPriceColumn}>
                <div className={styles.change}>
                  {contractType === 'options_combos'
                    ? t('trading:markPrice')
                    : t('trading:dropdownTableHeaders.lastPrice')}{' '}
                </div>
                {showVolume && (
                  <div className={styles.volumeHeader} data-palette="TableHeaders">
                    / {t('trading:dropdownTableHeaders.vol')}
                    {isSortable && (
                      <Sort
                        isUpActive={activeSort.volumeUp}
                        isDownActive={activeSort.volumeDown}
                        onUpClick={e => {
                          onSortChangeHandler('volume', 'asc');
                          e.stopPropagation();
                        }}
                        onDownClick={e => {
                          onSortChangeHandler('volume', 'desc');
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles.header}`} data-palette="TableHeaders">
              <span
                data-testid="mobile-change-24h-header"
                className={styles.volume}
                onClick={() => onColumnLabelClick('change24')}
                role="button"
                tabIndex={0}
                onKeyPress={noop}
              >
                24h {t('trading:dropdownTableHeaders.chg')}
              </span>
              {isSortable && (
                <Sort
                  isUpActive={activeSort.change24Up}
                  isDownActive={activeSort.change24Down}
                  onUpClick={() => onSortChangeHandler('change24', 'asc')}
                  onDownClick={() => onSortChangeHandler('change24', 'desc')}
                />
              )}
            </div>
          </>
        )}

        {showFunds && (
          <>
            <div className={`${styles.header}`} data-palette="TableHeaders">
              <span
                data-testid="returns-24h-header"
                className={styles.volume}
                onClick={() => onColumnLabelClick('returns24')}
                role="button"
                tabIndex={0}
                onKeyPress={noop}
              >
                24hr {t('trading:dropdownTableHeaders.returns')}
              </span>
              <Sort
                isUpActive={activeSort.returns24Up}
                isDownActive={activeSort.returns24Down}
                onUpClick={() => onSortChangeHandler('returns24', 'asc')}
                onDownClick={() => onSortChangeHandler('returns24', 'desc')}
              />
            </div>
            <div className={`${styles.header}`} data-palette="TableHeaders">
              <div className="flex-set flex--column">
                <span
                  data-testid="itd-returns-header"
                  onClick={() => onColumnLabelClick('itd')}
                  role="button"
                  tabIndex={0}
                  onKeyPress={noop}
                >
                  {t('trading:dropdownTableHeaders.itd')}{' '}
                  {t('trading:dropdownTableHeaders.returns')}
                </span>
                <span className={styles.itdDescriptionHeader}>
                  {t('trading:dropdownTableHeaders.inceptionToDate')}
                </span>
              </div>

              <Sort
                isUpActive={activeSort.itdUp}
                isDownActive={activeSort.itdDown}
                onUpClick={() => onSortChangeHandler('itd', 'asc')}
                onDownClick={() => onSortChangeHandler('itd', 'desc')}
              />
            </div>
          </>
        )}
      </MediaQuery>
    </div>)
  );
};

TableHeaders.propTypes = {
  noData: PropTypes.bool,
  setSortBy: PropTypes.func,
  sortBy: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string,
  }),
  showFunds: PropTypes.bool,
  favoritesShown: PropTypes.bool,
};

export default TableHeaders;
