enum STRIP_TYPES {
  ERROR = 'error',
  WARN = 'warn',
  INFORMATION = 'information',
}

interface PathNameItem {
  exact?: boolean;
  path: string;
}

interface IpDetailsResponse {
  success: true;
  result: {
    country: string;
    region: string;
  };
}

enum INFO_STRIP_LOGIN_STATES {
  POST = 'postLogin',
  PRE = 'preLogin',
}

interface StripData {
  countriesValue?: string[];
  name: string;
  showWhenLogin?: INFO_STRIP_LOGIN_STATES;
  // countries to show or not show strip based on ip address,
  ipCountries?: string[];
  type?: STRIP_TYPES;
  heading?: string;
  message?: string;
  webAppPathnames?: (PathNameItem | string)[] | null;
  redirectionLink?: string;
  openInNewTab?: boolean;
  analyticsTag?: string;
  remainVisible?: boolean;
  displayTime?: {
    start: string;
    end?: string;
  };
  userIds?: string[];
}

export type { StripData, PathNameItem, IpDetailsResponse };

export { STRIP_TYPES, INFO_STRIP_LOGIN_STATES };
