/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { isNan, isPositive, noop, numberPrettified } from 'helpers';

import Editable from './Editable';
import { TableCellProps } from './interfaces';
import styles from './tableCell.module.scss';
import Box from '../Box';

/**
 * @summary - Single Table Cell stylings with support for main and sub-values.
 * @see "AllPositions" and "OptionsPositions" Table columns for reference
 *
 * @param {displayValue} - Main value to display
 * @param {symbol} - Symbol to display next to main value
 * @param {displayValueColored} - Flag to indicate if main value should be colored (+ve & -ve values)
 * @param {offsetValueColored} - Flag to indicate if offset value should be colored (+ve & -ve values)
 * @param {displayOffsetValue} - Flag to hide/show offset/sub value
 * @param {textOffsetSymbol} - Symbol to display next to sub/offset value
 * @param {displayOffsetValueClassName} - Class name to apply to sub/offset value
 * @param {onClick} - Callback to handle click event
 * @param {title} - Title tooltip to display on hover
 * @param {wrapperClass} - Class name to apply to wrapper
 * @param {editable} - makes the cell editable
 */
const TableCell = memo(
  ({
    displayValue,
    symbol,
    displayValueColored,
    offsetValueColored,
    displayOffsetValue,
    textOffsetSymbol,
    displayOffsetValueClassName,
    onClick,
    title,
    wrapperClass,
    displayValueClass,
    prettifiedDisplay,
    editable,
    tooltipPlacement,
    tooltipText,
    onOk,
    onCancel,
    inputProps,
    ...rest
  }: TableCellProps) => {
    const { t } = useTranslation(['common']);
    // checks for prettified number
    const lastChar = String(displayValue)?.slice(-1);
    // @ts-ignore
    const isPrettified = isNan(lastChar);
    const extractText = isPrettified
      ? String(displayValue)?.slice(0, -1)?.split(' ')?.[0]
      : String(displayValue)?.split(' ')?.[0];
    // const extractText = String(displayValue)?.slice(0, -1)?.split(' ')[0];
    const isClickable = typeof onClick === 'function';

    const value = Number(extractText);
    const positiveColor = isPositive(value);

    return (
      <Editable
        editable={editable}
        displayValueColored={
          displayValueColored || (displayValueColored && parseFloat(String(value)) !== 0)
        }
        positiveColor={positiveColor}
        displayValue={displayValue}
        tooltipPlacement={tooltipPlacement}
        tooltipText={tooltipText}
        onOk={onOk}
        onCancel={onCancel}
        inputProps={inputProps}
      >
        <Box
          data-testid={rest['data-testid'] || 'table-cell'}
          testId={rest['data-testid'] || 'table-cell'}
          as="span"
          onClick={isClickable ? onClick : noop}
          onKeyDown={(e: any) => {
            if (e?.key === 'Enter' && isClickable && onClick) {
              onClick(e);
            }
          }}
          role={isClickable ? 'button' : ''}
          tabIndex={-1}
          className={cx({ pointer: isClickable, [styles.editableBottom]: editable })}
          title={isClickable && !title ? t('common:clickToExpand') : title || ''}
        >
          <Box
            className={cx(styles.cell, 'cell', {
              [String(wrapperClass)]: wrapperClass,
            })}
            tabIndex={-1}
          >
            <h5
              title={title}
              tabIndex={-1}
              className={cx(styles.display, {
                [styles.white]: !displayValueColored || parseFloat(String(value)) === 0,
                [styles.positive]:
                  displayValueColored && positiveColor && parseFloat(String(value)) !== 0,
                [styles.negative]:
                  displayValueColored &&
                  !positiveColor &&
                  parseFloat(String(value)) !== 0,
                [displayValueClass]: displayValueClass,
              })}
            >
              {prettifiedDisplay ? numberPrettified(displayValue) : displayValue}{' '}
              {symbol && (
                <Box as="span" className={cx(styles.displaySymbol)}>
                  {symbol}
                </Box>
              )}
            </h5>
            {displayOffsetValue && (
              <Box
                as="span"
                style={{ lineHeight: '12px' }}
                className={styles.offsetWrapper}
              >
                <p
                  className={cx(styles.offset, {
                    [styles.grey]: !offsetValueColored && !displayOffsetValueClassName,
                    [styles.positive]:
                      !displayOffsetValueClassName &&
                      offsetValueColored &&
                      Math.sign(Number(displayOffsetValue)) === 1,
                    [styles.negative]:
                      !displayOffsetValueClassName &&
                      offsetValueColored &&
                      Math.sign(Number(displayOffsetValue)) === -1,
                    [String(displayOffsetValueClassName)]: displayOffsetValueClassName,
                  })}
                >
                  {displayOffsetValue}
                </p>
                {textOffsetSymbol && (
                  <Box as="span" className={styles.offsetSymbol}>
                    {textOffsetSymbol}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Editable>
    );
  }
);

export default TableCell;
