/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { memo } from 'react';

import cn from 'classnames';

import { maxNotionalAtSelectedLeverage } from 'helpers/formulas';
import { compose } from 'helpers/ramda';
import { numberCommaSeparator } from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import useTradeTabMobile from 'hooks/useTradeTabMobile';
import { spotPriceState } from 'selectors';

import styles from './leverage.module.scss';

const enhance = compose(memo, subscribe);

const NumberComp = memo(({ maxNotional }) => (
  <span key={maxNotional} data-testid={maxNotional} data-palette="NumberComp">
    {Number.isNaN(maxNotional) ? '-' : numberCommaSeparator(maxNotional)}{' '}
  </span>
));

const MaxNotionalVal = enhance(({ leverage, selectedProduct = {} }) => {
  const spotPrice = spotPriceState();

  const maxNotional = maxNotionalAtSelectedLeverage(leverage, selectedProduct, spotPrice);
  return <NumberComp maxNotional={maxNotional} />;
});

const MaxNotional = ({ leverage, selectedProduct = {} }) => {
  const isTradeTabMobile = useTradeTabMobile();

  return (
    (<div
      className={cn(styles.maxLeveragetext, styles.maxNotional, {
        [styles.inTradeTabMobile_maxNotional]: isTradeTabMobile,
      })}
      data-palette="MaxNotional">
      <MaxNotionalVal leverage={leverage} selectedProduct={selectedProduct} />
      <span>{selectedProduct?.settling_asset?.symbol}</span>
    </div>)
  );
};

export default memo(MaxNotional);
