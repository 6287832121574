/* eslint-disable react/state-in-constructor */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

// import * as Sentry from '@sentry/browser';
import * as Sentry from '@sentry/react';

import Whoops from 'components/whoops';
// import { TIME_FORMATS } from 'constants/timeformats';
// import { dateFormat, timeNow } from 'helpers/day';
// import { trackMixpanelEvent } from 'helpers/mixpanel-init';
// import PropTypes from 'PropTypes';

import ChunkLoadErrorPage from '../ChunkLoadError';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    eventId: null,
    chunkLoadError: false,
  };

  clearError = () => {
    window.location.reload(true);
  };

  componentDidCatch(error, errorInfo) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      error.toString().includes('ChunkLoadError: ') ||
      error.toString().includes('Loading CSS chunk') ||
      chunkFailedMessage.test(error?.message)
    ) {
      this.setState({ error, errorInfo, chunkLoadError: true });
      // trackMixpanelEvent('ErrorBoundary', {
      //   errorType: 'ChunkLoadError',
      //   error: error.toString(),
      //   errorInfo: JSON.stringify(errorInfo, null, 4),
      //   errorTime: dateFormat(timeNow(), TIME_FORMATS.MMMM_Do_YYYY_h_mm_ss_a),
      // });
    } else {
      this.setState({ error, errorInfo });
      // trackMixpanelEvent('ErrorBoundary', {
      //   errorType: 'JavaScriptError',
      //   error: error.toString(),
      //   errorInfo: JSON.stringify(errorInfo, null, 4),
      //   errorTime: dateFormat(timeNow(), TIME_FORMATS.MMMM_Do_YYYY_h_mm_ss_a),
      // });
    }

    if (process.env.SENTRY_PROJECT_NAME) {
      Sentry.captureException(error);
      Sentry.captureMessage(JSON.stringify(errorInfo));
    }

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError, chunkLoadError } = this.state;

    if (hasError) {
      if (chunkLoadError) {
        return <ChunkLoadErrorPage />;
      }
      return <Whoops />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

// ErrorBoundary.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default ErrorBoundary;
