import { TIME_IN_MS } from 'constants/dateTime';
import { SetInterval } from 'types/utils';

import { devError } from './logger';

/**
 * Executes a callback function at a specified interval until a maximum duration is reached or a condition is met.
 *
 * @template TReturn The return type of the callback function.
 *
 * @param {() => TReturn} callback - The callback function to be executed.
 * @param {number} intervalInSeconds - The time interval in seconds between successive callback invocations.
 * @param {number} maxDurationInSeconds - The maximum duration in seconds to run the callback for.
 * @param {boolean} [immediate=false] - If set to `true`, the callback will be executed immediately upon invocation. Otherwise, it waits for the first interval to pass.
 * @param {(callbackResult: Awaited<TReturn>) => boolean} [shouldStopSchedule=() => false] - An optional function that decides when to stop the schedule based on the result of the callback. If this function returns `true`, the callback execution is stopped.
 *
 * @example
 * ```javascript
 * // Example callback which returns random numbers
 * const getRandomNumber = () => Math.random();
 *
 * // Run callback every 2 seconds for a maximum of 10 seconds
 * // and stop if the returned number is greater than 0.9
 * runCallbackWithInterval(
 *     getRandomNumber,
 *     2,
 *     10,
 *     false,
 *     result => result > 0.9
 * );
 * ```
 *
 * @returns {void}
 */
function runCallbackWithInterval<TReturn>(
  callback: () => TReturn,
  intervalInSeconds: number,
  maxDurationInSeconds: number,
  immediate: boolean = false,
  shouldStopSchedule: (callbackResult: Awaited<TReturn>) => boolean = () => false
) {
  let elapsedSeconds = 0;
  let isRunning = false;
  let intervalId: SetInterval;

  function executeCallback() {
    if (isRunning) {
      return;
    }

    isRunning = true;

    Promise.resolve(callback())
      .then(async result => {
        elapsedSeconds += intervalInSeconds;

        const shouldStop = await Promise.resolve(shouldStopSchedule(result));

        if (shouldStop || elapsedSeconds >= maxDurationInSeconds) {
          clearInterval(intervalId);
        }

        isRunning = false;
      })
      .catch(error => {
        devError(false, 'useBankDetails:runCallbackWithInterval', error);

        clearInterval(intervalId);

        isRunning = false;
      });
  }

  if (immediate) {
    executeCallback();
  }

  intervalId = setInterval(executeCallback, intervalInSeconds * TIME_IN_MS.ONE_SECOND);
}

export { runCallbackWithInterval };
