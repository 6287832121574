/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const HoldingsTableCellWrapperStyles = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  // Following attribute is primarily used for cells with secondary-text (.ht-secondary-text)
  white-space: ${props => (props.wrapContent ? 'normal' : 'nowrap')};
`;

/**
 * Cell Wrapper / Renderer for hiding overflowing text using ellipsis +
 * showing its full value in a native tooltip (title attribute)
 * Usage: <HoldingsTableCellWrapper>{content}</HoldingsTableCellWrapper>
 * if `content` is a string, it uses it as its `title` by default
 * Accepts wrapContent to either show a single-line value (truncated with ellipsis)
 * or multi-line wrapped value
 * @param props
 * @returns {*}
 * @constructor
 */
const HoldingsTableCellWrapper = props => {
  const { wrapContent, children, titleText, shouldDisplayTooltip } = props;
  const title = typeof children === 'string' ? children : titleText;

  if (shouldDisplayTooltip) {
    return (
      <HoldingsTableCellWrapperStyles wrapContent={wrapContent} title={title} {...props}>
        {children}
      </HoldingsTableCellWrapperStyles>
    );
  }

  return (
    <HoldingsTableCellWrapperStyles wrapContent={wrapContent} {...props}>
      {children}
    </HoldingsTableCellWrapperStyles>
  );
};

HoldingsTableCellWrapper.propTypes = {
  wrapContent: PropTypes.bool,
  children: PropTypes.node.isRequired,
  titleText: PropTypes.string,
  shouldDisplayTooltip: PropTypes.bool,
};

HoldingsTableCellWrapper.defaultProps = {
  wrapContent: false,
  titleText: '',
  shouldDisplayTooltip: true,
};

export default HoldingsTableCellWrapper;
