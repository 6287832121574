/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const LinkExpiredIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={81}
    height={75}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="LinkExpiredIcon">
    <path
      d="M36 66C19.431 66 6 52.569 6 36 6 19.431 19.431 6 36 6c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30Zm0-6a24 24 0 1 0 0-48 24 24 0 0 0 0 48Zm3-24h12v6H33V21h6v15Z"
      fill="currentColor"
    />
    <path
      d="M70.083 62.583h-1.5V64.47l-1.518-1.516-1.061-1.06-1.06 1.06-2.003 2.003-.005.005a5.582 5.582 0 1 1-7.896-7.896l.005-.005 2.003-2.003 1.06-1.06-1.059-1.06-1.518-1.52H57.417V49.53l1.518 1.517 1.061 1.06 1.06-1.06 2.004-2.005.004-.004a5.583 5.583 0 1 1 7.896 7.896l-.005.005-2.003 2.003-1.06 1.06 1.06 1.06 1.517 1.52h-.386Zm-3.017 6.496 1.517-1.516v5.104h5.834v-4.25h4.25v-5.834h-5.103l1.518-1.515v-.001A11.417 11.417 0 1 0 58.936 44.92l-1.52 1.518v-5.105h-5.833v4.25h-4.25v5.834h5.104l-1.517 1.516A11.417 11.417 0 1 0 67.064 69.08Zm1.001-19.15-1.06-1.061-1.061 1.06-10.017 10.018-1.062 1.06 1.062 1.061 2.004 2.003 1.061 1.06 1.06-1.06 10.017-10.016 1.061-1.06-1.06-1.061-2.005-2.005Z"
      fill="#F24E53"
      stroke="var(--primaryBackground)"
      strokeWidth={3}
    />
  </svg>
);

export default LinkExpiredIcon;
