import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  changeBasketOrdersScreen,
  deleteAllOrdersFromBasket,
} from 'actions/basketOrders';
import { BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { values } from 'helpers/ramda';
import { isEmpty } from 'helpers/utils';
import { basketOrderOrdersSelector, userMarginModeSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import styles from './productsSelection.module.scss';

interface ClearAndDoneButtonProps {
  asset: string;
}

const ClearAndDoneButton = ({ asset }: ClearAndDoneButtonProps) => {
  const { t } = useTranslation(['basketOrders']);
  const orders = useAppSelector(state => basketOrderOrdersSelector(state));
  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  const dispatch = useAppDispatch();

  const onClearAllClick = () => {
    dispatch(deleteAllOrdersFromBasket());
    trackMixpanelEvent('Basket Orders - Contracts Page - Clear All Clicked', {
      underlying_coin: asset,
      margin_type: marginMode,
    });
  };

  const onDoneClick = () => {
    dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
    trackMixpanelEvent('Basket Orders - Contracts Page - Done Clicked', {
      underlying_coin: asset,
      margin_type: marginMode,
    });
  };

  const showButtons = () => {
    if (marginMode === MARGIN_MODE.PORTFOLIO) {
      return !isEmpty(orders);
    }

    if (marginMode === MARGIN_MODE.ISOLATED || marginMode === MARGIN_MODE.CROSS) {
      const assetOrders = values(orders).filter(order => order.asset === asset);
      return assetOrders.length > 0;
    }
    return false;
  };

  return showButtons() ? (
    <>
      <button
        type="button"
        data-testid="clear-all"
        className={styles['clear-all']}
        onClick={onClearAllClick}
        data-palette="ClearAndDoneButton">
        {t('basketOrders:clearAll')}
      </button>
      <button
        type="button"
        data-testid="done"
        className={styles.done}
        onClick={onDoneClick}
        data-palette="ClearAndDoneButton">
        {t('basketOrders:done')}
      </button>
    </>
  ) : null;
};

export default ClearAndDoneButton;
