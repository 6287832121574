import { Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { postMessageToMobileApp } from 'components/app/helpers';
import { redirectToEmailVerification } from 'components/Auth/Common/helpers';
import InfoBox from 'components/Auth/Common/InfoBox';
import { NewRedirectIcon } from 'components/customicons/holdings';
import Render from 'components/render';
import { RenderForIndianExchange } from 'components/render/RenderByExchangeType';
import Seo from 'components/seo';
import {
  IS_INDIAN_EXCHANGE,
  IS_RN_WEBVIEW,
  IS_RN_WEBVIEW_IOS,
  TESTNET_URL,
} from 'constants/constants';
import { POST_MESSAGE_TO_MOBILE_EVENTS } from 'constants/enums';
import { SIGNUP_CARDS_FLAG } from 'constants/featureFlag';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import routes from 'constants/routes';
import { getMobileOperatingSystem } from 'helpers';
import * as mixPanel from 'helpers/mixpanel-init';
import i18n from 'i18n/config';
import { CircularExclamationWarning, Info, ValidationCorrect } from 'styles/assets/icons';
import { Box } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import { INFO_LIST } from './Messages.constants';
import styles from './styles.module.scss';

const AlreadyRegisteredMessage = () => {
  const onClickAlreadyRegisteredUserResetPassword = () => {
    mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SET_OR_CHANGE_PASSWORD, {
      Action: 'set',
      page_name: 'Web App',
      Operating_system: getMobileOperatingSystem(),
    });
  };
  return (
    (<div
      className={`${styles.errorMessage} ${styles.alreadyRegistered}`}
      data-palette="AlreadyRegisteredMessage">
      <span>{i18n.t('auth:accountExistsError')}</span>
      <span className={styles.loginOrReset}>
        <Link
          className={styles.link}
          to={routes.login}
          data-testid="alreadyRegistered-loginPageLink"
        >
          {i18n.t('auth:logIn') as string}
        </Link>
        <span className={styles.or}> {` ${i18n.t('auth:or')} `}</span>
        <Link
          className={styles.link}
          to={routes.forgotPassword}
          onClick={onClickAlreadyRegisteredUserResetPassword}
          data-testid="alreadyRegistered-forgotPasswordLink"
        >
          {i18n.t('auth:resetPassword') as string}
        </Link>
      </span>
    </div>)
  );
};

const AlreadyRegisteredUnverifiedMessage = ({ email }: { email: string }) => {
  const history = useHistory();

  const onClickVerifyNow = () => {
    redirectToEmailVerification(email, history);
  };

  return (
    (<div
      className={styles.errorMessage}
      data-palette="AlreadyRegisteredUnverifiedMessage">
      {i18n.t('errors:user.already_registered')}.{' '}
      <Box
        as="span"
        className={`${styles.link} ${styles.verifyNow}`}
        onClick={onClickVerifyNow}
        role="button"
        data-testid="signup-verifyLink"
      >
        {i18n.t('auth:verifyNow')}
      </Box>
    </div>)
  );
};

const goToTestnet = () => {
  if (IS_RN_WEBVIEW_IOS) {
    postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.OPEN_LINK_IN_EXTERNAL_BROWSER, {
      url: TESTNET_URL,
    });
    return;
  }
  window.open(TESTNET_URL, '_blank');
};

const CountryNotServedMessage = () => {
  return (
    (<div className={styles.countryMessage} data-palette="CountryNotServedMessage">
      <div className={styles.important}>
        <Info />
        <span className={styles.countryInfoImportant}>{i18n.t('auth:important')}</span>
      </div>
      <div className={styles.countryInfoTextBox}>
        <p>
          {i18n.t('auth:countryNotServed')} {i18n.t('common:title')}
          <DeltaButton
            className={styles.link}
            wrapperClassName={styles.testnet}
            onClick={goToTestnet}
            data-testid="testNetLink"
            useBasicButton
          >
            {i18n.t('common:testnet')} <NewRedirectIcon />
          </DeltaButton>
          .
        </p>
      </div>
    </div>)
  );
};

const IndividualInvestorsCountryMessage = () => {
  return (
    (<div
      className={styles.countryMessage}
      data-palette="IndividualInvestorsCountryMessage">
      <div className={styles.important}>
        <Info />
        <span className={styles.countryInfoImportant}>{i18n.t('auth:important')}</span>
      </div>
      <div className={styles.countryInfoTextBox}>
        <p>{i18n.t('auth:individualInvestorsCountryMessage')}</p>
      </div>
    </div>)
  );
};

const TestNetWarningMessage = () => {
  return (
    (<div className={styles.testNetWarning} data-palette="TestNetWarningMessage">
      <CircularExclamationWarning />
      <span>{i18n.t('auth:testNetWarning')}</span>
    </div>)
  );
};

const DiscountAppliedMessage = (props: {
  referralCode?: string;
  discountPercentage: number;
}) => {
  const { referralCode, discountPercentage } = props;

  return (
    (<div
      className={`${styles.discountText} ${styles.successMessage}`}
      data-palette="DiscountAppliedMessage">
      <ValidationCorrect />
      <Render
        when={IS_RN_WEBVIEW}
        else={
          <>
            {i18n.t('auth:signUpDiscount', {
              referralCode,
              discountPercentage,
            })}
          </>
        }
      >
        <>
          {i18n.t('auth:discountApplied', {
            discountPercentage,
          })}
        </>
      </Render>
    </div>)
  );
};

const InvalidReferralCodeMessage = () => {
  return (
    (<div
      className={`${styles.discountText} ${styles.invalidMessage}`}
      data-palette="InvalidReferralCodeMessage">
      {i18n.t('auth:invalidReferralCode')}
    </div>)
  );
};

const ExistingUserLoginMessage = () => {
  return (
    (<div className={styles.existingUser} data-palette="ExistingUserLoginMessage">
      <Trans i18nKey="auth:existingUser">
        Already have an account? Click here to
        <Link className={styles.link} to={routes.login} data-testid="loginPageLink">
          Log In
        </Link>
      </Trans>
    </div>)
  );
};

const InfoBoxes = () => {
  return INFO_LIST[SIGNUP_CARDS_FLAG]?.map(
    ({ description, header, id, imageSrc, imageClassName, alt }) => {
      return (
        (<InfoBox
          key={id}
          header={header}
          description={description}
          image={<img
            src={imageSrc}
            className={imageClassName}
            alt={alt}
            data-palette="InfoBoxes" />}
        />)
      );
    }
  );
};

const TermsAndConditionsMessage = () => {
  const links = {
    termsOfUse: 'https://www.delta.exchange/terms-of-use',
    privacyPolicy: 'https://www.delta.exchange/privacy-policy',
  };

  if (IS_INDIAN_EXCHANGE) {
    links.termsOfUse = 'https://india.delta.exchange/terms-of-use';
    links.privacyPolicy = 'https://india.delta.exchange/privacy-policy';
  }

  return (
    (<div className={styles.termCondition} data-palette="TermsAndConditionsMessage">
      {i18n.t('auth:agreeToDelta')}{' '}
      <a href={links.termsOfUse} target="__blank" data-testID="termsOfUseLink">
        {i18n.t('auth:termsOfUse')}{' '}
      </a>
      {i18n.t('common:and')}{' '}
      <a href={links.privacyPolicy} target="__blank" data-testID="privacyPolicyLink">
        {i18n.t('auth:privacyPolicy')}
      </a>.</div>)
  );
};

const SignupSEO = () => {
  const title = `${i18n.t('auth:signUp')} | ${i18n.t('auth:register')} - ${
    IS_INDIAN_EXCHANGE ? i18n.t('common:titleIndian') : i18n.t('common:title')
  }`;

  const description = IS_INDIAN_EXCHANGE
    ? i18n.t('auth:seoRegisterIndian')
    : i18n.t('auth:seoRegister');

  return <Seo title={title} description={description} />;
};

const AvoidRedoKYC = () => {
  return (
    <RenderForIndianExchange>
      <Box className={styles.avoidRedoKYCMessage}>
        <Trans i18nKey="auth:avoidRedoKYC">
          Already a Delta Exchange user?
          <br />
          Sign up with the same email to avoid re-doing KYC.
        </Trans>
      </Box>
    </RenderForIndianExchange>
  );
};

export {
  AlreadyRegisteredMessage,
  AlreadyRegisteredUnverifiedMessage,
  CountryNotServedMessage,
  TestNetWarningMessage,
  DiscountAppliedMessage,
  InvalidReferralCodeMessage,
  ExistingUserLoginMessage,
  InfoBoxes,
  TermsAndConditionsMessage,
  SignupSEO,
  IndividualInvestorsCountryMessage,
  AvoidRedoKYC,
};
