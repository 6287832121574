/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react';

const SignalTradingIcon = ({
  className,
  width = 20,
  height = 20,
  color = 'var(--primaryText)',
  ...restProps
}: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 20 20"
    className={className}
    color={color}
    {...restProps}
    data-palette="SignalTradingIcon">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.084 5.833a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Zm3.333-2.577a2.577 2.577 0 1 0 0 5.154 2.577 2.577 0 0 0 0-5.154Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.417 3.733c.209 0 .378.154.378.343v.187c.293.065.577.194.776.374a.32.32 0 0 1 0 .485.406.406 0 0 1-.535 0c-.123-.112-.382-.213-.63-.219-.142-.003-.232.026-.276.057-.03.02-.074.061-.074.187 0 .046.01.065.017.074.007.01.022.029.062.051.094.055.236.093.455.147l.024.006c.185.046.437.108.639.224a.894.894 0 0 1 .305.278c.083.126.124.27.124.425 0 .342-.17.61-.435.779a1.312 1.312 0 0 1-.452.174v.252c0 .19-.17.343-.378.343-.21 0-.378-.153-.378-.343v-.27c-.31-.07-.612-.22-.812-.461a.322.322 0 0 1 .077-.48.405.405 0 0 1 .53.069c.102.124.333.227.596.235.17.006.304-.03.383-.081.065-.04.112-.1.112-.217 0-.045-.01-.065-.016-.074a.177.177 0 0 0-.063-.05c-.094-.055-.236-.093-.455-.148l-.024-.005c-.185-.046-.436-.108-.638-.224a.893.893 0 0 1-.306-.279.759.759 0 0 1-.123-.425c0-.31.13-.567.373-.734.112-.077.238-.127.366-.157v-.18c0-.19.169-.343.378-.343Zm-.014 3.602.014-.342-.014.342Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M3.454 14.583v-1.25h-.993a.652.652 0 0 1-.48-.193.652.652 0 0 1-.193-.48V5.68A.658.658 0 0 1 2.46 5h.993V3.75c0-.118.04-.217.12-.297.08-.08.18-.12.297-.12.118 0 .217.04.297.12.08.08.12.18.12.297V5h.993c.191 0 .351.064.48.193a.652.652 0 0 1 .193.48v6.98a.659.659 0 0 1-.673.68h-.993v1.25A.407.407 0 0 1 3.87 15a.403.403 0 0 1-.297-.12.401.401 0 0 1-.12-.297ZM2.621 12.5h2.5V5.833h-2.5V12.5Z"
    />
    <path
      fill="currentColor"
      d="M8.454 14.583v-2.916h-.993a.652.652 0 0 1-.48-.194.652.652 0 0 1-.193-.48V7.347a.658.658 0 0 1 .673-.68h.993v-1.25c0-.119.04-.218.12-.297.08-.08.18-.12.297-.12.118 0 .217.04.297.12.08.08.12.179.12.297v1.25h.993c.191 0 .351.064.48.193a.652.652 0 0 1 .193.48v3.647a.658.658 0 0 1-.673.68h-.993v2.916A.408.408 0 0 1 8.87 15a.403.403 0 0 1-.297-.12.401.401 0 0 1-.12-.297Zm-.833-3.75h2.5V7.5h-2.5v3.333Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.427 11.95-4.388 4.728c-.24.259-.576.405-.928.405H2.084a.417.417 0 0 1 0-.833H9.77c.338 0 .66-.14.89-.389l4.156-4.478.61.567Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.109 11.334a.333.333 0 0 1 .345.32l.112 2.998a.333.333 0 1 1-.666.025l-.082-2.194-4.092 4.41-.489-.453 4.092-4.41-2.193.082a.333.333 0 1 1-.025-.667l2.998-.111Z"
      clipRule="evenodd"
    />
  </svg>)
);
export default SignalTradingIcon;
