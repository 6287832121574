import { useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { changePassword, changePasswordNew, getUserProfile } from 'actions/user';
import { getPasswordValidation } from 'components/Auth/Signup/helpers';
import { parseErrorMsg, resetTimeErrMsg } from 'helpers';
import useToggle from 'hooks/useToggle';
import { userSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { ERROR_CODES } from 'constants/constants';

type ChangePasswordHookProps = {
  mfaFlag: boolean;
};

type PasswordType = {
  error: boolean;
  errorMessage: string;
  errorCode: string;
  score?: null | number | undefined;
  value: string;
};

/**
 * Hook to handle the change password flow
 * @param mfaFlag boolean value to check if email otp flow is required or not
 */
const useChangePassword = ({ mfaFlag }: ChangePasswordHookProps) => {
  const { t } = useTranslation(['account', 'errors']);

  const user = useAppSelector(userSelector);
  const appDispatch = useAppDispatch();
  const libRef = useRef(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [loading, toggleLoading] = useToggle(false);

  const [oldPassword, setOldPassword] = useState<PasswordType>({
    error: false,
    errorMessage: '',
    errorCode: '',
    value: '',
  });
  const [newPassword, setNewPassword] = useState<PasswordType>({
    error: false,
    errorMessage: '',
    errorCode: '',
    score: null,
    value: '',
  });
  // const [oldPasswordError, setOldPasswordError] = useState<string>('');
  // const [confirmPassError, setConfirmPassError] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<PasswordType>({
    error: false,
    errorMessage: '',
    errorCode: '',
    value: '',
  });

  const [showMfaPopup, setShowMfaPopup] = useState(false);
  const [emailOtp, setEmailOtp] = useState('');
  const [twoFaOtp, setTwoFaOtp] = useState('');
  const [pre2faToken, setPre2faToken] = useState('');
  const [submitErrorMessage, setSubmitError] = useState('');
  const [isMfaRequired, setIsMfaRequired] = useState(false);

  const oldPasswordErrorMessages = {
    old_new_same: t('account:passwordError.old_new_same'),
    invalid_password: t('account:passwordError.invalid_password'),
  };

  const confirmPasswordErrorMessages = {
    new_confirm_not_same: t('account:passwordError.new_confirm_not_same'),
  };

  const onOldPasswordChange = ({ target: { value } }) => {
    setOldPassword({
      ...oldPassword,
      value,
    });
    if (value && value === newPassword.value) {
      setOldPassword({
        ...oldPassword,
        value,
        error: true,
        errorMessage: t('account:passwordError.old_new_same'),
        errorCode: 'old_new_same',
      });
    } else if (value !== newPassword) {
      if (
        newPassword.value &&
        confirmNewPassword.value &&
        newPassword.value !== confirmNewPassword.value
      ) {
        setOldPassword({
          ...oldPassword,
          value,
          error: true,
          errorMessage: confirmPasswordErrorMessages.new_confirm_not_same,
          errorCode: 'new_confirm_not_same',
        });
      } else {
        setOldPassword({
          ...oldPassword,
          value,
          error: false,
          errorMessage: '',
          errorCode: '',
        });
      }
    } else if (oldPassword.errorCode === 'invalid_password') {
      setOldPassword({
        ...oldPassword,
        value,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
    } else {
      setOldPassword({
        ...oldPassword,
        value,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
    }
  };

  const onNewPasswordChange = ({ target: { value } }) => {
    if (submitErrorMessage) {
      setSubmitError('');
    }
    setNewPassword({
      value,
      ...getPasswordValidation(value, user.email, libRef?.current.default),
    });

    if (confirmNewPassword.value && value !== confirmNewPassword.value) {
      setNewPassword(prevState => ({
        ...prevState,
        value,
        error: true,
        errorMessage: t('account:passwordError.new_confirm_not_same'),
        errorCode: 'new_confirm_not_same',
      }));
    } else {
      setConfirmNewPassword({
        ...confirmNewPassword,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
    }

    if (oldPassword.value && value === oldPassword.value) {
      setOldPassword({
        ...oldPassword,
        error: true,
        errorMessage: t('account:passwordError.old_new_same'),
        errorCode: 'old_new_same',
      });
    } else {
      setOldPassword({
        ...oldPassword,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
    }
  };

  const onConfirmPasswordChange = ({ target: { value } }) => {
    if (submitErrorMessage) {
      setSubmitError('');
    }
    setConfirmNewPassword({
      ...confirmNewPassword,
      value,
    });
    if (newPassword.value !== value) {
      setConfirmNewPassword({
        ...confirmNewPassword,
        value,
        error: true,
        errorMessage: confirmPasswordErrorMessages.new_confirm_not_same,
        errorCode: 'new_confirm_not_same',
      });
    } else {
      setConfirmNewPassword({
        ...confirmNewPassword,
        value,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
      setNewPassword({
        ...newPassword,
        error: false,
        errorMessage: '',
        errorCode: '',
      });
    }
  };

  const btnDisable =
    !newPassword.value ||
    newPassword.error ||
    newPassword.value !== confirmNewPassword.value ||
    newPassword.value === user.email ||
    oldPassword.value === newPassword.value ||
    oldPassword.value.length === 0;

  // Function to handle the form submission
  const handleSubmit = async () => {
    if (!btnDisable) {
      toggleLoading();
      setSubmitError('');
      if (mfaFlag) {
        // if mfaFlag is true, email otp verification is required
        recaptchaRef.current
          ?.executeAsync()
          .then(token => {
            appDispatch(
              changePasswordNew({
                existing_password: oldPassword.value,
                new_password: newPassword.value,
                recaptcha_response: token,
              })
            )
              .then(res => {
                const { code, pre_2fa_token: pre2faTokenValue } = res.result;
                toggleLoading();
                setIsMfaRequired(code !== 'email_code');
                setPre2faToken(pre2faTokenValue);
                setShowMfaPopup(true);
                recaptchaRef.current?.reset();
              })
              .catch(err => {
                toggleLoading();
                recaptchaRef.current?.reset();

                const errorObj = parseErrorMsg(err);
                const { code, context } = errorObj.error;

                if (code === 'bad_schema') {
                  const schemaError = context?.schema_errors[0]?.message;
                  setSubmitError(schemaError);
                } else if (code === 'invalid_password') {
                  const { count = 0, max_attempts: maxAttempts = 5 } = context || {};
                  const attemptsRemaining = maxAttempts - count;
                  let errorMsg = t('errors:custom.invalid_psw_plural', {
                    count: attemptsRemaining,
                  });
                  if (attemptsRemaining === 1) {
                    errorMsg = t('errors:custom.invalid_psw', {
                      count: attemptsRemaining,
                    });
                  }
                  setOldPassword({
                    ...oldPassword,
                    error: true,
                    errorMessage: errorMsg,
                    errorCode: 'invalid_password',
                  });
                } else if (code === 'change_password_blocked') {
                  const resetTime = context.time ? Math.ceil(context.time / 60) : null;
                  setSubmitError(resetTimeErrMsg(resetTime));
                } else if (code === ERROR_CODES.BCRYPT_HASH_LIMIT) {
                  setSubmitError(t(`errors:custom.${ERROR_CODES.BCRYPT_HASH_LIMIT}`));
                } else {
                  setSubmitError(code);
                }
              });
          })
          .catch(() => recaptchaRef.current?.reset());
      } else {
        appDispatch(
          changePassword({
            existing_password: oldPassword.value,
            new_password: newPassword.value,
          })
        )
          .then(() => {
            toggleLoading();
            appDispatch(getUserProfile());
          })
          .catch(err => {
            toggleLoading();
            const errorObj = parseErrorMsg(err);
            const { code } = errorObj.error;
            if (code === ERROR_CODES.BCRYPT_HASH_LIMIT) {
              return setSubmitError(t(`errors:custom.${ERROR_CODES.BCRYPT_HASH_LIMIT}`));
            }
            setOldPassword({
              ...oldPassword,
              error: true,
              errorMessage: oldPasswordErrorMessages.invalid_password,
              errorCode: 'invalid_password',
            });
          });
      }
    }
  };

  // Return the necessary state variables and functions
  return {
    libRef,
    oldPassword,
    setOldPassword,
    onOldPasswordChange,
    newPassword,
    setNewPassword,
    onNewPasswordChange,
    confirmNewPassword,
    setConfirmNewPassword,
    onConfirmPasswordChange,
    btnDisable,
    emailOtp,
    setEmailOtp,
    twoFaOtp,
    setTwoFaOtp,
    showMfaPopup,
    setShowMfaPopup,
    loading,
    handleSubmit,
    recaptchaRef,
    pre2faToken,
    isMfaRequired,
    submitErrorMessage,
  };
};

export default useChangePassword;
