/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { StylesProvider } from '@material-ui/core/styles';

import Checked from './CheckedIcon';
import styles from './muiCheckBox.module.scss';
import UnChecked from './UnCheckedIcon';

interface MuiCheckBoxProps extends CheckboxProps {
  checkedClassName?: string;
  unCheckedClassName?: string;
}

/**
 * @see https://v4.mui.com/api/checkbox/
 */
const MuiCheckBox = ({
  unCheckedClassName,
  checkedClassName,
  checked,
  id,
  ...rest
}: MuiCheckBoxProps) => {
  const unCheckedClass =
    !checked && unCheckedClassName
      ? `${styles.root} ${unCheckedClassName} Mui-UnChecked`
      : null;

  return (
    <StylesProvider injectFirst>
      <Checkbox
        id={id?.toString()}
        classes={{
          root: unCheckedClass || styles.root,
          ...(checkedClassName && { checked: checkedClassName }),
        }}
        checkedIcon={<Checked />}
        icon={<UnChecked />}
        checked={checked}
        {...rest}
      />
    </StylesProvider>
  );
};

export default MuiCheckBox;
