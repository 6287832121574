/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable import/named */
import { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import { ShareIcon } from 'components/customicons';
import { SummationIcon, SummationIconHovered } from 'components/customicons/holdings';
import { Highlighter } from 'components/highlighter';
import { HoldingBracketOrderDesktop } from 'components/holdings/holding-bracket-order-desktop';
import Modal from 'components/modal';
// import NetworkBar from 'components/network';
import PositionMargin from 'components/old_positon_margin';
import { ToolTipWithCustomIcons } from 'components/popover/popover_helpers';
import {
  holdingsContractTypeList,
  // shareCardContractTypes,
  // MARGIN_TYPES,
  VANILLA_SETTLING_ASSET,
} from 'constants/constants';
import { STORAGE_KEYS } from 'constants/enums';
import { isReduceOnlyEnable } from 'helpers/bracketOrder/bracketOrder';
import {
  // eslint-disable-next-line import/named
  percentageDifference,
  getEffectiveLeverage,
  round,
  getUnrealisedPnl,
  // getROE,
  getPayOff,
  getIrsPayoff,
  getIrsUnPnl,
  // getIrsRoe,
  getIrsPremium,
  round_by_tick_size,
  getPremium,
  // eslint-disable-next-line import/named
  getPositionValue,
  isUsdStableCoin,
  // getUplPercentage,
} from 'helpers/formulas';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { isNil } from 'helpers/ramda';
import {
  calcPrecision,
  // convertExponentialToDecimal,
  cropAfterDecimals,
  handleExponentialAndCropDecimals,
  isOptions,
  addZeroesUntilCorrectPrecision,
  isNan,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import useOpenPositionExitPrice from 'hooks/componentHooks/useOpenPositionExitPrice';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  holdingsContractTypeSelector,
  holdingsFilterType,
  selectedContractTypeOpenPositions,
  selectedProductIdSelector,
  selectedProductSelector,
} from 'selectors';
import {
  openPositionMarkPriceSelectorById,
  spotPriceSelectorBySymbol,
  spotPriceState,
} from 'selectors/priceSelectors';
// import { lastPriceSelector } from 'selectors/tickerSelectors';
// import { ShareIcon } from 'components/customicons';
// import { ShareIcon } from 'styles/assets/icons';
import TableCell from 'UIKit/TableCell';

// import Editable from '../holdings/editable';
import HoldingsCancelButton from '../holdings/holdings_cancel_button';
import HoldingsTableCellWrapper from '../holdings/holdings_table_cell_wrapper';
import {
  // ADLIndicatorWrapper,
  HoldingsPrice,
  HoldingsPriceFlexWrapper,
  HoldingsPriceSymbol,
  // UnrealisedPnLWrapper,
  // FlexCenterWrapper,
  TotalUnrealisedPnlPopoverWrapper,
} from '../holdings/holdingStyle';
import styles from './index.module.scss';

// import { socketActiveSelector } from 'selectors/socketSelectors';

export const MarkPrice = subscribe(prop => {
  const { product_id, product } = prop;
  const { spot_index, tick_size, contract_type } = product;

  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const markPriceObj = openPositionMarkPriceSelectorById(product_id) || {};
  const { mark_price: markPrice } = markPriceObj;
  const precision = calcPrecision(tick_size);
  const selectedProdSpotPrice = spotPriceState();
  const spotPrice =
    selectedProductId === product_id.toString()
      ? selectedProdSpotPrice
      : spotPriceSelectorBySymbol(spot_index.symbol);
  const indexPrice = round_by_tick_size(spotPrice, tick_size);
  // reqd since initial value is 0.
  if (product?.contract_type === 'interest_rate_swaps' || markPrice) {
    return (
      <HoldingsTableCellWrapper>
        {parseFloat(markPrice).toFixed(precision || 1)}{' '}
        {product?.contract_type === 'interest_rate_swaps' ? '%' : ''}
        <HoldingsPriceSymbol className="index-price">
          <i>{isOptions(contract_type) ? indexPrice : ''}</i>
        </HoldingsPriceSymbol>
      </HoldingsTableCellWrapper>
    );
  }
  return null;
});

export const IndexPrice = subscribe(prop => {
  const { product_id, product } = prop;
  const { spot_index, tick_size } = product;

  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const selectedProdSpotPrice = spotPriceState();
  const spotPrice =
    selectedProductId === product_id.toString()
      ? selectedProdSpotPrice
      : spotPriceSelectorBySymbol(spot_index.symbol);
  const indexPrice = addZeroesUntilCorrectPrecision(
    round_by_tick_size(spotPrice, tick_size),
    tick_size
  );
  const displayValue = isNan(indexPrice) ? 0 : indexPrice;
  // reqd since initial value is 0.
  return <TableCell displayValue={displayValue} />;
});

export const PayOff = subscribe(prop => {
  const { product, size } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const markPriceObj = openPositionMarkPriceSelectorById(product.id) || {};
  const { mark_price: markPrice } = markPriceObj;
  const spotPrice =
    selectedProductId === prop.product_id.toString()
      ? spotPriceState()
      : spotPriceSelectorBySymbol(prop.product.spot_index.symbol);
  const { contract_value, settling_asset, contract_type } = product;

  let payOff;
  // [#testNaNInfinityIssues] Tested. Pass.
  if (isNil(markPrice)) {
    return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
  }
  if (isOptions(contract_type)) {
    payOff = getPayOff(contract_value, size, markPrice);
  } else if (contract_type === 'interest_rate_swaps') {
    payOff = getIrsPayoff(size, markPrice, product, spotPrice);
  } else {
    return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
  }

  return (
    <HoldingsPriceFlexWrapper>
      <HoldingsPrice
        title={`${payOff.toFixed(settling_asset.precision)} ${settling_asset.symbol}`}
      >
        {payOff.toFixed(settling_asset.minimum_precision)}
      </HoldingsPrice>
      <HoldingsPriceSymbol>{settling_asset.symbol}</HoldingsPriceSymbol>
    </HoldingsPriceFlexWrapper>
  );
});

export const LiqPrice = subscribe(prop => {
  const markPriceObj = openPositionMarkPriceSelectorById(prop.product.id) || {};
  const { mark_price: markPrice } = markPriceObj;
  const { liquidation_price } = prop;
  let percentageDiff;

  if (markPrice) {
    percentageDiff =
      prop.product?.contract_type !== 'interest_rate_swaps'
        ? percentageDifference(liquidation_price, markPrice)
        : '';
    return (
      (<TableCell
        offsetValueColored
        displayOffsetValueClassName={styles.negative}
        wrapperClass={styles.negative}
        displayValue={
          <div className={styles.negative} data-palette="LiqPrice">
            {`${round_by_tick_size(liquidation_price, prop.product.tick_size)} ${prop.product?.contract_type === 'interest_rate_swaps' ? '%' : ' '
              }`}
          </div>
        }
        title={`${liquidation_price} ${prop.product?.contract_type === 'interest_rate_swaps' ? '%' : ' '
          }  ${percentageDiff}`}
        displayOffsetValue={
          <span data-palette="LiqPrice">
            {prop?.product?.contract_type !== 'interest_rate_swaps' && (
              <span className="ht-secondary-text" data-palette="LiqPrice">{percentageDiff}</span>
            )}
          </span>
        }
      />)
    );
  }
  return (
    <TableCell
      displayValueColored
      displayValue={`${round_by_tick_size(liquidation_price, prop.product.tick_size)} ${prop.product?.contract_type === 'interest_rate_swaps' ? '%' : ' '
        }`}
    />
  );
});

export const Margin = subscribe(prop => {
  const { t } = useTranslation(['trading']);
  const [modalIsOpen, changeModalIsOpen] = useState(false);
  const { product, margin } = prop;
  const markPriceObj = openPositionMarkPriceSelectorById(product.id.toString()) || {};

  const { mark_price: markPrice } = markPriceObj;

  // Return '-' if values aren't available JiraWeb#459#3 [#testNaNInfinityIssues]
  if (product?.contract_type !== 'interest_rate_swaps' && !markPrice) {
    // return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
  }

  const margin_min_precision = round(margin, product.settling_asset.minimum_precision);
  const margin_precise = round(margin, product.settling_asset.precision);
  const priceSymbol = product.settling_asset.symbol;
  const handleCloseModal = () => {
    changeModalIsOpen(false);
  };
  const submitMargin = () => { };
  return (
    (<div data-palette="Margin">
      <TableCell
        editable
        tooltipPlacement="right"
        displayValue={margin_min_precision}
        symbol={`${priceSymbol} (${prop.margin_mode})`}
        title={`${margin_precise} ${priceSymbol}`}
        onOk={() => {
          changeModalIsOpen(true);
        }}
        onCancel={handleCloseModal}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel={t('trading:margin')}
      >
        <PositionMargin
          position={prop}
          submitMargin={submitMargin}
          positionProductId={prop?.product?.id?.toString()}
          cancel={handleCloseModal}
        />
      </Modal>
    </div>)
  );
});

// eslint-disable-next-line react/prop-types
// const Roe = ({ value }) => {
//   if (value === 'Infinity' || value === '-Infinity') {
//     return null;
//   }
//   if (value === 0) {
//     return <span className="ht-secondary-text"> </span>;
//   }
//   return <span className="ht-secondary-text">({value}%)</span>;
// };

// export const UnrealizedPnl = subscribe(prop => {
//   const { size, product, margin, margin_mode } = prop;
//   const selectedProduct = useSelector(state => selectedProductSelector(state));
//   const selectedProductId = selectedProduct?.id;

//   const markPriceObj = openPositionMarkPriceSelectorById(product.id) || {};
//   const { mark_price: markPrice } = markPriceObj;
//   const spotPrice =
//     selectedProductId === prop.product_id.toString()
//       ? spotPriceState()
//       : spotPriceSelectorBySymbol(prop.product.spot_index.symbol);

//   const position = {
//     realized_funding: prop.realized_funding,
//     realized_pnl: prop.realized_pnl,
//     size,
//     margin: prop.margin,
//   };

//   const exitPrice = useOpenPositionExitPrice(prop, selectedProduct);

//   // [#testNaNInfinityIssues] Tested. Pass.
//   if (isNil(markPrice)) {
//     return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
//   }

//   const unrealised_pnl =
//     product?.contract_type === 'interest_rate_swaps'
//       ? getIrsUnPnl(
//           product,
//           spotPrice,
//           exitPrice,
//           position,
//           prop.entry_price,
//           prop.created_at
//         )
//       : getUnrealisedPnl(
//           prop.product?.notional_type,
//           prop.product?.contract_value,
//           prop.entry_price,
//           exitPrice,
//           prop.size,
//           prop.product?.contract_type
//         );

//   const roe =
//     product?.contract_type === 'interest_rate_swaps'
//       ? round(getIrsRoe(position, unrealised_pnl), 2)
//       : round(
//           getROE(
//             unrealised_pnl,
//             prop.margin,
//             prop.product,
//             prop.entry_price,
//             prop.size,
//             prop.margin_mode,
//             markPrice
//           ),
//           2
//         );

//   const { adl_level } = prop;
//   const roundedUnrealisedPnL = cropAfterDecimals(
//     convertExponentialToDecimal(unrealised_pnl),
//     prop.product.settling_asset.minimum_precision
//   );
//   const preciseUnrealisedPnL = cropAfterDecimals(
//     convertExponentialToDecimal(unrealised_pnl),
//     prop.product.settling_asset.precision
//   );
//   const { symbol } = prop.product.settling_asset;
//   const textValue = `${
//     Number.isNaN(Number(roundedUnrealisedPnL)) ||
//     !Number.isFinite(Number(roundedUnrealisedPnL))
//       ? '-'
//       : roundedUnrealisedPnL
//   } ${symbol}`;
//   const fontColor = unrealised_pnl >= 0 ? 'var(--positive)' : 'var(--negative)';
//   // const impactPriceSymbol =
//   //   prop.product.contract_type === 'interest_rate_swaps'
//   //     ? '%'
//   //     : prop.product.quoting_asset.symbol;
//   // const popoverBodyText =
//   //   prop.product.contract_type === 'interest_rate_swaps'
//   //     ? 'Indicative closing rate'
//   //     : 'Indicative closing price';

//   return (
//     <UnrealisedPnLWrapper>
//       <div className="value-wrapper">
//         <HoldingsTableCellWrapper
//           wrapContent
//           style={{
//             color: fontColor,
//             display: 'flex',
//             flexDirection: 'column',
//           }}
//           titleText={`${preciseUnrealisedPnL} ${
//             margin_mode !== MARGIN_TYPES.PORTFOLIO ? `(${roe}%)` : ''
//           }`}
//         >
//           <span>{textValue}</span>
//           <span>
//             {margin_mode !== MARGIN_TYPES.PORTFOLIO && (
//               <Roe
//                 value={
//                   Number.isNaN(Number(roe)) ||
//                   !Number.isFinite(Number(roe)) ||
//                   ((prop.product?.contract_type === 'perpetual_futures' ||
//                     prop.product?.contract_type === 'interest_rate_swaps') &&
//                     Number(margin) <= 0)
//                     ? '-'
//                     : roe
//                 }
//                 contract_type={prop.product?.contract_type}
//               />
//             )}
//             {/* <Popover
//               body={`${popoverBodyText} :  ${
//                 impactPrice
//                   ? round_by_tick_size(impactPrice, product.tick_size)
//                   : '-'
//               } ${impactPriceSymbol}`}
//               style={{
//                 maxWidth: 360,
//                 width: 'max-content',
//                 textAlign: 'center',
//               }}>
//               {thisProps => <PopoverBase {...thisProps} />}
//             </Popover> */}
//           </span>
//         </HoldingsTableCellWrapper>
//       </div>

//       {adl_level ? (
//         <ADLIndicatorWrapper>
//           {/* <Popover
//             body={
//               'This indicator shows your position in ADL queue. Higher the level, higher the chance that your position will be reduced due to ADL.'
//             }> */}
//           <NetworkBar adl_level={adl_level} />
//           {/* </Popover> */}
//         </ADLIndicatorWrapper>
//       ) : null}
//     </UnrealisedPnLWrapper>
//   );
// });

// export const SharePosition = subscribe(prop => {
//   const {
//     size,
//     product,
//     showPositionInShareCard,
//     entry_price,
//     realized_funding,
//     realized_pnl,
//     bracket_orders,
//     margin,
//     margin_mode: marginMode,
//     referral_code,
//   } = prop;
//   const {
//     id,
//     symbol,
//     contract_type,
//     tick_size,
//     notional_type,
//     contract_value,
//     underlying_asset,
//     contract_unit_currency,
//     settling_asset,
//   } = product;
//   const selectedProduct = useSelector(state => selectedProductSelector(state));
//   const selectedProductId = selectedProduct?.id;
//   const markPriceObj = openPositionMarkPriceSelectorById(product.id) || {};
//   const exitPrice = useOpenPositionExitPrice(prop, selectedProduct);
//   const { mark_price: markPrice } = markPriceObj;
//   const spotPrice =
//     selectedProductId === prop.product_id.toString()
//       ? spotPriceState()
//       : spotPriceSelectorBySymbol(prop.product.spot_index.symbol);
//   // [#testNaNInfinityIssues] Tested. Pass.

//   if (isNil(markPrice)) {
//     return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
//   }

//   let shareCardNotional = null;
//   if (showPositionInShareCard) {
//     const position_value = getPositionValue(
//       notional_type,
//       contract_value,
//       entry_price,
//       size,
//       contract_type
//     );

//     const pv_precision =
//       contract_type === 'interest_rate_swaps'
//         ? tick_size
//         : product.underlying_asset.minimum_precision;

//     const precise_value = round(position_value, pv_precision);
//     const priceSymbol = (() => {
//       switch (contract_type) {
//         case 'call_options':
//         case 'put_options':
//         case 'move_options':
//         case 'turbo_put_options':
//         case 'turbo_call_options':
//         case 'options_combos':
//           return underlying_asset.symbol;
//         case 'interest_rate_swaps':
//         case 'spreads':
//           return contract_unit_currency;
//         default:
//           return settling_asset.symbol;
//       }
//     })();

//     shareCardNotional = `${precise_value} ${priceSymbol}`;
//   }

//   const position = {
//     realized_funding,
//     realized_pnl,
//     size,
//     margin,
//   };

//   // unrealised pnl
//   const unrealised_pnl =
//     product?.contract_type === 'interest_rate_swaps'
//       ? getIrsUnPnl(
//           product,
//           spotPrice,
//           exitPrice,
//           position,
//           prop.entry_price,
//           prop.created_at
//         )
//       : getUnrealisedPnl(
//           prop.product?.notional_type,
//           prop.product?.contract_value,
//           prop.entry_price,
//           exitPrice,
//           prop.size,
//           prop.product?.contract_type
//         );
//   // roe
//   const roe = (() => {
//     switch (product?.contract_type) {
//       case 'interest_rate_swaps':
//         return Number(margin) >= 0 ? round(getIrsRoe(position, unrealised_pnl), 2) : '-';
//       case 'perpetual_futures':
//         return Number(margin) >= 0
//           ? round(
//               getROE(
//                 unrealised_pnl,
//                 prop.margin,
//                 prop.product,
//                 prop.entry_price,
//                 prop.size,
//                 prop.margin_mode,
//                 markPrice
//               ),
//               2
//             )
//           : '-';
//       default:
//         return round(
//           getROE(
//             unrealised_pnl,
//             prop.margin,
//             prop.product,
//             prop.entry_price,
//             prop.size,
//             marginMode,
//             markPrice
//           ),
//           2
//         );
//     }
//   })();

//   const { take_profit_order, stop_loss_order } = bracket_orders;

//   // TP/ SL values if bracket order has been set.
//   // If limit take profit/ SL orders have been set,
//   // then send the limit prices.
//   // If not, send trigger prices,

//   const takeProfit = take_profit_order.limit_price || take_profit_order.stop_price || '-';
//   const stopLoss = (() => {
//     if (stop_loss_order.trail_amount) {
//       return round_by_tick_size(
//         Number(stop_loss_order.trail_amount) + Number(markPrice),
//         tick_size
//       );
//     }
//     return stop_loss_order.limit_price || stop_loss_order.stop_price || '-';
//   })();

//   const uplPercentage = getUplPercentage(
//     markPrice,
//     prop.entry_price,
//     prop.size,
//     settling_asset.minimum_precision
//   );

//   const payload = {
//     template: 'open_position',
//     data: {
//       roe: `${prop.margin_mode === MARGIN_TYPES.PORTFOLIO ? uplPercentage : roe}%`,
//       symbol,
//       entry_price: round_by_tick_size(entry_price, tick_size),
//       tp: takeProfit,
//       sl: stopLoss,
//       current_price: round_by_tick_size(markPrice, tick_size),
//       side: size < 0 ? 'Short' : 'Long',
//       notional: shareCardNotional,
//       contract_type: shareCardContractTypes[contract_type],
//       referral_code,
//     },
//   };

//   const params = {
//     id,
//     symbol,
//     contract_type,
//     underlyingAssetSymbol: underlying_asset.symbol,
//     side: size < 0 ? 'sell' : 'buy',
//   };

//   const onShareClick = () => {
//     prop.openSharePopup(payload, params);
//     trackMixpanelEvent('Position Card Share Button Clicked - Trade - Table', {
//       product_name: symbol,
//       product_type: contract_type,
//       transaction_type: params.side,
//     });
//   };
//   return (
//     <FlexCenterWrapper>
//       <div className={cx('value-wrapper share-cell f', styles.shareButton)}>
//         <ShareIcon data-testid="9-b8s_jR3n4qWbvTanR_f" onClick={onShareClick} />
//       </div>
//     </FlexCenterWrapper>
//   );
// });

export const MovePremium = prop => {
  const { product, entry_price, size } = prop;
  const premium = getPremium(product.contract_value, size, entry_price);
  const roundedPremium = round(premium, product.settling_asset.minimum_precision);
  const priceSymbol = product.settling_asset.symbol;
  return (
    <HoldingsPriceFlexWrapper>
      <HoldingsPrice
        title={`${Number(premium).toFixed(
          product.settling_asset.precision
        )} ${priceSymbol}`}
      >
        {Number.isFinite(Number(roundedPremium)) ? roundedPremium : '-'}
      </HoldingsPrice>
      <HoldingsPriceSymbol className="ht-secondary-text">
        {priceSymbol}
      </HoldingsPriceSymbol>
    </HoldingsPriceFlexWrapper>
  );
};

export const IrsPremium = subscribe(prop => {
  const { product, size, entry_price, created_at } = prop;
  const spotPrice = spotPriceSelectorBySymbol(prop.product.spot_index.symbol);
  const premium = getIrsPremium(size, entry_price, spotPrice, product, created_at);
  const roundedPremium = round(premium, product.settling_asset.minimum_precision);
  return (
    <HoldingsPriceFlexWrapper>
      <HoldingsPrice
        title={`${Number(premium).toFixed(prop.product.settling_asset.precision)} ${product.settling_asset.symbol
          }`}
      >
        {Number.isFinite(Number(roundedPremium)) ? roundedPremium : ''}
      </HoldingsPrice>
      <HoldingsPriceSymbol className="ht-secondary-text">
        {product.settling_asset.symbol}
      </HoldingsPriceSymbol>
    </HoldingsPriceFlexWrapper>
  );
});

export const RealisedPnl = prop => {
  const { product, realized_pnl, realized_funding, entry_price, size } = prop;
  const { contract_type, settling_asset, contract_value } = product;
  const priceSymbol = product.settling_asset.symbol;

  if (!realized_pnl && !realized_funding) {
    return <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>;
  }

  const premium = getPremium(contract_value, size, entry_price);
  const value = (() => {
    switch (contract_type) {
      case 'call_options':
      case 'put_options':
      case 'move_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        return Number(realized_pnl) - Number(premium);
      default:
        return Number(realized_pnl) + Number(realized_funding);
    }
  })();

  return (
    <HoldingsPriceFlexWrapper>
      <HoldingsPrice
        title={`${handleExponentialAndCropDecimals(
          value,
          settling_asset.precision
        )} ${priceSymbol}`}
      >
        {handleExponentialAndCropDecimals(value, settling_asset.minimum_precision)}
      </HoldingsPrice>
      <HoldingsPriceSymbol className="ht-secondary-text">
        {priceSymbol}
      </HoldingsPriceSymbol>
    </HoldingsPriceFlexWrapper>
  );
};

export const IrsRealisedPnl = subscribe(prop => {
  const { product, size, entry_price, created_at, realized_pnl, realized_funding } = prop;
  const spotPrice = spotPriceSelectorBySymbol(prop.product.spot_index.symbol);
  const premium = getIrsPremium(size, entry_price, spotPrice, product, created_at);
  const computedRealizedPnl =
    Number(realized_pnl) - Number(premium) + Number(realized_funding);

  return Number.isNaN(Number(computedRealizedPnl)) ? (
    <HoldingsTableCellWrapper>-</HoldingsTableCellWrapper>
  ) : (
    <HoldingsPriceFlexWrapper>
      <HoldingsPrice
        title={`${Number(computedRealizedPnl).toFixed(
          product.settling_asset.precision
        )} ${product.settling_asset.symbol}`}
      >
        {Number(computedRealizedPnl).toFixed(product.settling_asset.minimum_precision)}
      </HoldingsPrice>
      <HoldingsPriceSymbol className="ht-secondary-text">
        {product.settling_asset.symbol}
      </HoldingsPriceSymbol>
    </HoldingsPriceFlexWrapper>
  );
});

export const Trail = subscribe(prop => {
  // console.log("debug: trail take_profit_order: ", prop);
  const { take_profit_order, stop_loss_order } = prop.bracket_orders;
  const {
    handleOnClick,
    onClickCondition,
    product,
    entry_price,
    size,
    margin,
    cleanBracketOrder,
    shouldDisable,
    margin_mode,
  } = prop;

  const selectedProductPricePrecision = calcPrecision(product.tick_size);
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const markPriceObj = openPositionMarkPriceSelectorById(product.id) || {};
  const { mark_price: markPrice } = markPriceObj;
  const spotPrice =
    selectedProductId === prop.product_id.toString()
      ? spotPriceState()
      : spotPriceSelectorBySymbol(prop.product.spot_index.symbol);

  const position = {
    realized_funding: prop.realized_funding,
    realized_pnl: prop.realized_pnl,
    size,
    margin,
  };

  const effective_leverage = getEffectiveLeverage(
    product,
    entry_price,
    markPrice,
    spotPrice,
    position,
    prop.created_at
  );

  const { bracket_orders } = prop;
  if (isNil(stop_loss_order)) {
    bracket_orders.stop_loss_order = {
      trail_amount: null,
      limit_price: null,
      stop_price: null,
    };
  }
  if (isNil(take_profit_order)) {
    bracket_orders.take_profit_order = {
      limit_price: null,
      stop_price: null,
    };
  }
  const stopTrailAmount = bracket_orders.stop_loss_order.trail_amount;
  const stopLossLimitPrice = bracket_orders.stop_loss_order.limit_price;
  const stopLossTriggerPrice = bracket_orders.stop_loss_order.stop_price;
  const takeProfitLimitPrice = bracket_orders.take_profit_order.limit_price;
  const takeProfitTriggerPrice = bracket_orders.take_profit_order.stop_price;
  let isNew = isNil(stop_loss_order) && isNil(take_profit_order);
  if (!isNew) {
    if (
      isNil(stopTrailAmount) &&
      isNil(takeProfitTriggerPrice) &&
      isNil(stopLossTriggerPrice)
    ) {
      isNew = true;
    } else {
      isNew = false;
    }
  }
  const removeBracketOrder = () => {
    cleanBracketOrder({
      selected_product: product,
      existingStopLossOrder: prop.bracket_orders.stop_loss_order,
      existingTakeProfitOrder: prop.bracket_orders.take_profit_order,
    });
  };
  const onClickAction = () => {
    handleOnClick({
      takeProfit: onClickCondition(
        take_profit_order,
        'takeprofit',
        selectedProductPricePrecision
      ),
      stopLoss: onClickCondition(
        stop_loss_order,
        'stoploss',
        selectedProductPricePrecision
      ),
      trailStop: onClickCondition(
        stop_loss_order,
        'trail_amount',
        selectedProductPricePrecision
      ),
      productquoteSymbol: product.quoting_asset.symbol,
      productId: product.id,
      quantity: size,
      price: entry_price,
      selected_product: product,
      leverage: effective_leverage,
      markPrice,
      existingBraketOrder: {
        takeProfitId:
          take_profit_order && take_profit_order.id ? take_profit_order.id : null,
        stopLossId: stop_loss_order && stop_loss_order.id ? stop_loss_order.id : null,
      },
      isNew,
      margin: prop.margin,
      margin_mode: prop.margin_mode,
      stopTrailAmount,
      stopLossLimitPrice: stopTrailAmount ? null : stopLossLimitPrice,
      stopLossTriggerPrice: stopTrailAmount ? null : stopLossTriggerPrice,
      takeProfitLimitPrice,
      takeProfitTriggerPrice,
      existingStopLossOrder: prop.bracket_orders.stop_loss_order,
      existingTakeProfitOrder: prop.bracket_orders.take_profit_order,
    });
  };
  return (
    (<div data-palette="Trail">
      <HoldingsTableCellWrapper wrapContent shouldDisplayTooltip={false}>
        <HoldingBracketOrderDesktop
          takeProfitTriggerPrice={takeProfitTriggerPrice}
          takeProfitLimitPrice={takeProfitLimitPrice}
          stopLossTriggerPrice={stopLossTriggerPrice}
          stopLossLimitPrice={stopLossLimitPrice}
          stopTrailAmount={stopTrailAmount}
          onClickAction={onClickAction}
          removeBracketOrder={removeBracketOrder}
          isNew={isNew}
          margin_mode={margin_mode}
          disableAddBracketOrder={shouldDisable}
        />
      </HoldingsTableCellWrapper>
    </div>)
  );
});

export const ClosePosition = subscribe(prop => {
  const markPriceObj = openPositionMarkPriceSelectorById(prop?.product?.id) || {};
  const { mark_price: markPrice } = markPriceObj;
  return (
    <HoldingsCancelButton
      data-testid="AsAAozGKN7DCbRHE4BBXp"
      className={cx('close-position-button', styles.closePositionButton)}
      tooltipDisable
      disabled={prop.shouldDisable}
      onClick={prop.onClosePositionClick({
        symbol: prop.product.symbol,
        size: prop.size,
        quoting_symbol: prop.product.quoting_asset.symbol,
        settling_symbol: prop.product.settling_asset.symbol,
        mark_price: markPrice,
        productId: prop.product.id,
        reduce_only: isReduceOnlyEnable(prop.product),
        tick_size: prop.product.tick_size,
        entry_Price: prop.entry_price,
        allProps: prop,
      })}
    />
  );
});

export const TotalUnrealisedPnl = subscribe(({ children, className }) => {
  const { t } = useTranslation(['trading']);
  const positions = useSelector(selectedContractTypeOpenPositions);
  const holdingsContractType = useSelector(state => holdingsContractTypeSelector(state));
  const holdingsFilter = useSelector(state => holdingsFilterType(state));
  const selectedProduct = useSelector(state => selectedProductSelector(state));

  const selectedContractType =
    holdingsContractType === 'all' || holdingsContractType === 'selected'
      ? 'selected'
      : holdingsContractTypeList.filter(
        contractType => contractType.value === holdingsContractType
      )[0].label;

  const totalUnpnl = positions.reduce((acc, position) => {
    const { size, product, entry_price, created_at } = position;
    const spotPrice = spotPriceSelectorBySymbol(product.spot_index.symbol);
    const exitPrice = useOpenPositionExitPrice(position, selectedProduct);

    const unrealised_pnl =
      product?.contract_type === 'interest_rate_swaps'
        ? getIrsUnPnl(product, spotPrice, exitPrice, position, entry_price, created_at)
        : getUnrealisedPnl(
          product?.notional_type,
          product?.contract_value,
          entry_price,
          exitPrice,
          size,
          product?.contract_type
        );

    const settlingSpotIndex = `.DE${product.settling_asset.symbol === 'BTC' ? 'XBT' : product.settling_asset.symbol
      }${VANILLA_SETTLING_ASSET}`;
    const settlingSpotPrice = isUsdStableCoin(product.settling_asset.symbol)
      ? 1
      : spotPriceSelectorBySymbol(settlingSpotIndex);

    const unpnlInUsdt = Number(unrealised_pnl) * Number(settlingSpotPrice);

    return acc + unpnlInUsdt;
  }, 0);

  const headerText =
    holdingsFilter === 'selected'
      ? t('trading:pnlPopup.selected_product')
      : t('trading:pnlPopup.selected_products', {
        contractType: selectedContractType,
      });

  if (children) {
    return children(
      !Number.isNaN(Number(totalUnpnl)) ? cropAfterDecimals(totalUnpnl, 2) : '-'
    );
  }

  return (
    <TotalUnrealisedPnlPopoverWrapper className={className}>
      <span className="header">{headerText}</span>
      <span className="text">
        {t('trading:pnlPopup.total_unpnl')} = &nbsp;
        <span
          className={cx({
            negative: Number(totalUnpnl) < 0,
            positive: Number(totalUnpnl) > 0,
            neutral: Number(totalUnpnl) === 0 || Number.isNaN(Number(totalUnpnl)),
          })}
        >
          {!Number.isNaN(Number(totalUnpnl)) ? cropAfterDecimals(totalUnpnl, 2) : '-'}{' '}
          {VANILLA_SETTLING_ASSET}
        </span>
      </span>
      <span className="note">{t('trading:pnlPopup.note')}</span>
    </TotalUnrealisedPnlPopoverWrapper>
  );
});

export const TotalUnpnlComponent = () => {
  const { t } = useTranslation(['common']);
  const [placard, setPlacard] = useLocalStorage(
    STORAGE_KEYS.POSITIONS_PLACARD_TRADE,
    true
  );

  return (
    (<ToolTipWithCustomIcons
      popoverOptions={{
        body: <TotalUnrealisedPnl />,
        shouldEnableClickListener: true,
      }}
      filledIcon={<SummationIconHovered />}
      unfilledIcon={
        <>
          <Highlighter
            dataVersion={12}
            dataId=""
            title={t('common:placard.pnl')}
            isOpen={placard}
            toggleHighLigherAction={() => setPlacard(false)}
            className={styles.placard}
          />
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => { }}
            data-testid="unpnl-tooltip"
            onClick={() => trackMixpanelEvent('PnL Sum Clicked - Trade - Table')}
            data-palette="TotalUnpnlComponent">
            <SummationIcon />
          </span>
        </>
      }
    />)
  );
};

export const calculateOpenPositionValue = ({ product, entry_price, size }) => {
  const positionValue = getPositionValue(
    product?.notional_type,
    product?.contract_value,
    entry_price,
    size,
    product?.contract_type
  );

  let selectedAsset = product.settling_asset;

  switch (product?.contract_type) {
    case 'call_options':
    case 'put_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos': {
      selectedAsset = product.underlying_asset;
      break;
    }
    case 'interest_rate_swaps':
    case 'spreads': {
      selectedAsset = {
        symbol: product.contract_unit_currency,
        precision: product.tick_size,
        minimum_precision: product.tick_size,
      };
      break;
    }
    default: {
      selectedAsset = product.settling_asset;
      break;
    }
  }
  const minimum_precision = selectedAsset?.minimum_precision;
  const precision = selectedAsset?.precision;
  const symbol = selectedAsset?.symbol;
  const title = cropAfterDecimals(positionValue, precision);
  const value = cropAfterDecimals(positionValue, minimum_precision);
  const untrimmedValue = positionValue;

  return { minimum_precision, precision, value, title, symbol, untrimmedValue };
};
