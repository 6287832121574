/* eslint-disable camelcase */
import React from 'react';

import { Link } from 'react-router-dom';

import DownloadButton from 'components/download_button';
import {
  getBalanceEquity,
  getBalanceUnrealizedPnl,
} from 'components/holdings_balance/helpers';
import { PAGINATION_PERSIST_STATE_TABLES } from 'constants/constants';
import routes from 'constants/routes';
import { noop } from 'helpers';
import { cond, equals, always, filter, values } from 'helpers/ramda';
import {
  convertExponentialToDecimal,
  cropAfterDecimals,
  getUSDT,
  getInt,
  downloadData,
} from 'helpers/utils';
import { spotPriceSelectorBySymbol } from 'selectors';
import { markPriceSelectorById } from 'selectors/priceSelectors';
import { Conversion, WithdrawalsIcon, DepositsIcon } from 'styles/assets/icons';
// import CoinIcon from 'components/coin_selector';
import { AccountTable } from 'UIKit';

import { HeaderText } from './balance_styles';
import { getTransactionsColumns } from './columns';

const TransactionsHeader = props => {
  const { symbol, downloadTransactions, assetId } = props;

  const fileName = `DeltaTransactionLog-${symbol}.csv`;

  const handleDownload = () => {
    downloadTransactions({ asset_ids: assetId }).then(downloadData(fileName, 'text/csv'));
  };

  return (
    <HeaderText className="sub-page-title">
      <span>{symbol || ''} Transaction History</span>
      <DownloadButton data-testid="helper-handleDownload" onClick={handleDownload} />
    </HeaderText>
  );
};
const getTableName = cond([
  [equals('BTC'), always(PAGINATION_PERSIST_STATE_TABLES.BTC_TRANSACTIONS)],
  [equals('USDC'), always(PAGINATION_PERSIST_STATE_TABLES.USDC_TRANSACTIONS)],
]);

export const TransactionHistory = React.memo(props => {
  const {
    wallet,
    transactions,
    pageSize,
    totalPages,
    fetchNextData,
    symbol,
    paginationState,
    updatePaginationPage,
    updatePaginationRowsPerPage,
    downloadTransactions,
  } = props;

  const table = getTableName(symbol);
  const { page, rowsPerPage } = paginationState[table] || {};
  const size = getInt(pageSize);
  const count = getInt(totalPages);
  const columns = getTransactionsColumns(wallet, symbol);

  return (<>
    <TransactionsHeader
      symbol={symbol}
      downloadTransactions={downloadTransactions}
      assetId={wallet.asset.id}
    />
    <div className="transactions-history" data-palette="TransactionHistory">
      <AccountTable
        columns={columns}
        data={transactions}
        pageSize={size}
        showPagination
        paginationConfig={{
          totalCount: count,
          fetchNextData,
          page,
          rowsPerPage,
          onPageChange: page => updatePaginationPage({ page, table }),
          onRowsPerPageChange: rowsPerPage =>
            updatePaginationRowsPerPage({
              rowsPerPage,
              table,
            }),
        }}
      />
    </div>
  </>);
});

export const CurrencyConverter = ({ coin, setCurrencyConverter }) => {
  if (coin === 'BTC') {
    return (
      (<div
        className="btc-action d-flex justify-content-around"
        data-palette="CurrencyConverter">
        <Link className="d-flex" to={routes.account.withdrawal}>
          <span className="d-none d-md-flex">
            <WithdrawalsIcon className="icon_color" />
          </span>
          <label className="withdrawal-text">Withdraw</label>
        </Link>
        <Link className="d-flex" to={routes.account.deposit}>
          <span className="d-none d-md-flex">
            <DepositsIcon className="icon_color" />
          </span>
          <label className="deposit-text">Deposit</label>
        </Link>
      </div>)
    );
  }

  return (
    (<div
      className="convert-action d-flex justify-content-around"
      data-palette="CurrencyConverter">
      <div
        role="tab"
        tabIndex={0}
        onKeyDown={noop}
        data-testid="setCurrencyConverter"
        className="d-flex"
        onClick={() => setCurrencyConverter(coin)}
      >
        <span className="d-none d-md-flex">
          <Conversion className="conversion-icon" />
        </span>
        <label className="convert-text">Convert</label>
      </div>
    </div>)
  );
};

export const getWalletData = (balances, activecurrency) =>
  filter(balance => `${balance.asset.symbol}` === activecurrency, values(balances))[0] ||
  values(balances)[0];

export const getTotalEquity = (data, openPositionsList) => {
  const getMarkPrice = productId => markPriceSelectorById(productId);
  let total = 0;
  const calcPrecision = ({ symbol, minimum_precision, precision }) =>
    symbol === 'BTC' ? precision : minimum_precision;

  data.forEach(equity => {
    const equityValue = convertExponentialToDecimal(
      getBalanceEquity({ ...equity }, openPositionsList, getMarkPrice)
    );
    const roundedValue = cropAfterDecimals(equityValue, calcPrecision(equity.asset));
    total += Number(
      getUSDT(equity.asset.symbol, roundedValue, spotPriceSelectorBySymbol)
    );
  });
  return cropAfterDecimals(total, 2);
};

export const getUnrealizedCashFlow = (
  data,
  openPositionsList,
  // eslint-disable-next-line default-param-last
  positionsUpl = 0,
  assetId
) => {
  const getMarkPrice = productId => markPriceSelectorById(productId);
  let total = 0;
  const calcPrecision = ({ symbol, minimum_precision, precision }) =>
    symbol === 'BTC' ? precision : minimum_precision;

  data.forEach(equity => {
    const normalisedPnl = convertExponentialToDecimal(
      getBalanceUnrealizedPnl(equity.id, openPositionsList, getMarkPrice)
    );
    const isPortfolio = equity?.asset_id === assetId;
    const totalPnl = isPortfolio
      ? Number(normalisedPnl) + Number(positionsUpl)
      : Number(normalisedPnl);

    const roundedValue = cropAfterDecimals(totalPnl, calcPrecision(equity.asset));
    total += Number(
      getUSDT(equity.asset.symbol, roundedValue, spotPriceSelectorBySymbol)
    );
  });

  return cropAfterDecimals(total, 2);
};
