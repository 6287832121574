import { lazy } from '@loadable/component';

const ImpsIcon = lazy(() => import(/* webpackChunkName: "ImpsIcon" */ './ImpsIcon'));
const MasterCardIcon = lazy(
  () => import(/* webpackChunkName: "MasterCardIcon" */ './MasterCardIcon')
);
const NeftIcon = lazy(() => import(/* webpackChunkName: "NeftIcon" */ './NeftIcon'));
const UpiIcon = lazy(() => import(/* webpackChunkName: "UpiIcon" */ './UpiIcon'));
const VisaIcon = lazy(() => import(/* webpackChunkName: "VisaIcon" */ './VisaIcon'));

export { ImpsIcon, MasterCardIcon, NeftIcon, UpiIcon, VisaIcon };
