import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

type Hook = (fn: EffectCallback, inputs: DependencyList) => void;

/**
 * @summary - Works like useEffect but doesn't run on initial load.
 */
const useDidUpdateEffect: Hook = (fn, inputs) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    fn();
  }, inputs);
};

export default useDidUpdateEffect;
