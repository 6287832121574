import React from 'react';

export const noOpenStopOrderIcon = () => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="71"
      viewBox="0 0 64 71"
      data-palette="noOpenStopOrderIcon">
      <g
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round">
        <g stroke-width="2" opacity=".845">
          <path
            stroke="#717782"
            d="M32.772 1H7.354C3.844 1 1 3.865 1 7.4v51.195c0 3.534 2.845 6.4 6.354 6.4l34.313-.029c-.022-.256-2.78-5.547-1.152-10.254 1.2-3.468 5.466-7.665 10.552-7.797 2.488-.064.93.283.614-.796l.154-25.92L32.772 1z"
          />
          <path stroke="#717782" d="M33 1v19h19" />
          <path stroke="#5F6671" d="M38 34.5H11M38 44.5H11M19 24h-8" />
        </g>
        <g transform="translate(40 47)">
          <circle
            cx="11.5"
            cy="11.5"
            r="11.5"
            stroke="#636A75"
            stroke-width="2"
          />
          <g stroke="#D75750">
            <path d="M15 9l-6 6M9 9l6 6" />
          </g>
        </g>
      </g>
    </svg>)
  );
};

export const noOpenOrdersIcon = () => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="71"
      viewBox="0 0 64 71"
      data-palette="noOpenOrdersIcon">
      <g fill="none" fill-rule="evenodd">
        <g
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          opacity=".845">
          <path
            stroke="#717782"
            d="M32.772 1H7.354C3.844 1 1 3.865 1 7.4v51.195c0 3.534 2.845 6.4 6.354 6.4l34.313-.029c-.022-.256-2.78-5.547-1.152-10.254 1.2-3.468 5.466-7.665 10.552-7.797 2.488-.064.93.283.614-.796l.154-25.92L32.772 1z"
          />
          <path stroke="#717782" d="M33 1v19h19" />
          <path stroke="#5F6671" d="M38 34.5H11M38 44.5H11M19 24h-8" />
        </g>
        <g transform="translate(40 47)">
          <circle
            cx="11.5"
            cy="11.5"
            r="11.5"
            stroke="#636A75"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            fill="#54B986"
            fill-rule="nonzero"
            d="M16.827 8.173a.592.592 0 0 0-.837 0l-5.228 5.228-2.751-2.752a.592.592 0 0 0-.838.837l3.17 3.17a.59.59 0 0 0 .838 0l5.646-5.646a.592.592 0 0 0 0-.837z"
          />
        </g>
      </g>
    </svg>)
  );
};

export const noOpenPositionIcon = () => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="67"
      viewBox="0 0 54 67"
      data-palette="noOpenPositionIcon">
      <g
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2">
        <g stroke="#636A75">
          <path d="M33.5 1h-26A6.5 6.5 0 0 0 1 7.5v52A6.5 6.5 0 0 0 7.5 66h39a6.5 6.5 0 0 0 6.5-6.5v-39L33.5 1z" />
          <path d="M33.5 1v19.5H53" />
        </g>
        <g stroke="#54B986">
          <path d="M20 41V26M15 31l5-5 5 5" />
        </g>
        <g stroke="#D75750">
          <path d="M35 36v15M30 46l5 5 5-5" />
        </g>
      </g>
    </svg>)
  );
};

export const noHistoryAvailableIcon = () => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="74"
      viewBox="0 0 66 74"
      data-palette="noHistoryAvailableIcon">
      <g
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2">
        <g opacity=".845">
          <path
            stroke="#717782"
            d="M32.772 1H7.354C3.844 1 1 3.865 1 7.4v51.195c0 3.534 2.845 6.4 6.354 6.4l34.313-.029c-.022-.256-2.78-5.547-1.152-10.254 1.2-3.468 5.466-7.665 10.552-7.797 2.488-.064.93.283.614-.796l.154-25.92L32.772 1z"
          />
          <path stroke="#717782" d="M33 1v19h19" />
          <path stroke="#5F6671" d="M38 34.5H11M38 44.5H11M19 24h-8" />
        </g>
        <g stroke="#0090BC" transform="translate(40 47)">
          <circle cx="11.5" cy="11.5" r="11.5" />
          <path d="M25 26l-6-6" />
        </g>
      </g>
    </svg>)
  );
};

export const userLoginIcon = () => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="70"
      viewBox="0 0 90 70"
      data-palette="userLoginIcon">
      <g fill="none" fill-rule="evenodd">
        <path
          stroke="#636A75"
          stroke-width="2"
          d="M6 57h78V6.41c0-1.881-.142-2.62-.55-3.38a3.543 3.543 0 0 0-1.48-1.48c-.76-.408-1.499-.55-3.38-.55H11.41c-1.881 0-2.62.142-3.38.55-.641.342-1.138.839-1.48 1.48C6.142 3.79 6 4.528 6 6.41V57zM1 57h88v6.59c0 1.881-.142 2.62-.55 3.38a3.543 3.543 0 0 1-1.48 1.48c-.76.408-1.499.55-3.38.55H6.41c-1.881 0-2.62-.142-3.38-.55a3.543 3.543 0 0 1-1.48-1.48c-.408-.76-.55-1.499-.55-3.38V57z"
        />
        <path
          stroke="#636A75"
          stroke-width="2"
          d="M36 57h19c-.005 1.497-.163 2.248-.55 2.97a3.543 3.543 0 0 1-1.48 1.48c-.734.393-1.5.55-3.05.55h-8.84c-1.55 0-2.316-.157-3.05-.55a3.543 3.543 0 0 1-1.48-1.48c-.387-.722-.545-1.473-.55-2.97z"
        />
        <rect
          width="69"
          height="46.887"
          x="10.5"
          y="5.5"
          stroke="#636A75"
          rx="1"
        />
        <g transform="translate(28 37)">
          <rect width="33" height="7" x=".5" y=".5" stroke="#636A75" rx="1" />
          <path
            fill="#636A75"
            d="M4 3h2v2H4zM9 3h2v2H9zM14 3h2v2h-2zM19 3h2v2h-2zM24 3h2v2h-2zM29 3h2v2h-2z"
          />
        </g>
        <circle cx="45" cy="22" r="10" stroke="#2AA8CF" stroke-width="2" />
        <circle cx="45" cy="20" r="3" stroke="#2AA8CF" stroke-width="2" />
        <path
          stroke="#2AA8CF"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M51 30.125V28.75A2.75 2.75 0 0 0 48.25 26h-5.5A2.75 2.75 0 0 0 40 28.75v1.375"
        />
      </g>
    </svg>)
  );
};

export const NewRedirectIcon = props => {
  const className = props.className;
  const style = props.style;
  return className !== null ? (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      data-palette="NewRedirectIcon">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L16 0 16 16 0 16z" />
        <path
          fill="var(--primaryTheme)"
          fillRule="nonzero"
          d="M6.667 4v1.333H3.333v7.334h7.334V9.333H12v4c0 .369-.298.667-.667.667H2.667C2.298 14 2 13.702 2 13.333V4.667C2 4.298 2.298 4 2.667 4h4zM14 2v5.333h-1.333V4.275L7.47 9.471 6.53 8.53l5.194-5.196H8.667V2H14z"
        />
      </g>
    </svg>
  ) : (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      data-palette="NewRedirectIcon">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L16 0 16 16 0 16z" />
        <path
          fill="var(--primaryText)"
          fillRule="nonzero"
          d="M6.667 4v1.333H3.333v7.334h7.334V9.333H12v4c0 .369-.298.667-.667.667H2.667C2.298 14 2 13.702 2 13.333V4.667C2 4.298 2.298 4 2.667 4h4zM14 2v5.333h-1.333V4.275L7.47 9.471 6.53 8.53l5.194-5.196H8.667V2H14z"
        />
      </g>
    </svg>
  );
};

export const SummationIcon = props => {
  return (
    (<svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      className={props.className}
      data-palette="SummationIcon">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Desktop-HD1" transform="translate(-926.000000, -860.000000)">
          <g id="Group-45" transform="translate(926.000000, 860.000000)">
            <circle
              id="Oval"
              stroke="var(--primaryTheme)"
              cx="9"
              cy="9"
              r="8.5"></circle>
            <g
              id="sigma-sum"
              transform="translate(5.000000, 5.000000)"
              fill="var(--primaryTheme)"
              fillRule="nonzero">
              <path
                d="M7.06060368,1.70288086 L7.06060368,1.00048828 L1.67248499,1.00048828 L4.59864262,4.13305664 C4.78088275,4.32861328 4.78019506,4.64428711 4.59657952,4.83837891 L1.60509052,8.00024414 L7.05991598,8.00024414 L7.05991598,7.31689453 L7.9986246,7.31689453 L7.9986246,8.49975586 C7.9986246,8.77587891 7.78818882,9 7.52892644,9 L0.4683247,9 L0.4683247,8.99853516 C0.347977441,8.99853516 0.227630183,8.94946289 0.136166266,8.85131836 C-0.0460738686,8.65576172 -0.04538617,8.34008789 0.138229362,8.14599609 L3.60491811,4.48242188 L0.238633361,0.878173828 C0.13891706,0.786621094 0.0756487873,0.651123047 0.0756487873,0.500244141 C0.0756487873,0.224121094 0.286084565,0 0.545346946,0 L7.53030184,0 C7.78956422,0 8,0.224121094 8,0.500244141 L8,1.70288086 L7.06060368,1.70288086 L7.06060368,1.70288086 Z"
                id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>)
  );
};

export const SummationIconHovered = props => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={props.className}
      data-palette="SummationIconHovered">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-926 -860)">
          <g transform="translate(926 860)">
            <circle cx="9" cy="9" r="9" fill="var(--primaryTheme)"></circle>
            <g fill="#FFF" fillRule="nonzero" transform="translate(5 5)">
              <path d="M7.06 1.703V1H1.673L4.6 4.133a.522.522 0 01-.002.705L1.605 8H7.06v-.683h.939V8.5c0 .276-.21.5-.47.5H.469v-.001c-.121 0-.241-.05-.333-.148a.522.522 0 01.002-.705l3.467-3.664L.239.878A.512.512 0 01.076.5c0-.276.21-.5.47-.5H7.53c.26 0 .47.224.47.5v1.203h-.94z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>)
  );
};
