import Box from '@material-ui/core/Box';

import i18n from 'i18n/config';
import { MailSpam } from 'styles/assets/icons';

import twoFaStyles from '../../Auth/Reset2FA/reset2fa.module.scss';

interface SpamCheckProps {
  width?: string | number;
}

const spamCheckMessage = i18n.t('account:security.checkSpam');

const SpamCheck = ({ width }: SpamCheckProps) => {
  return (
    <Box width={width} className={twoFaStyles.disclaimerContainer}>
      <div className={twoFaStyles.svg}>
        <MailSpam />
      </div>
      <div className={twoFaStyles.disclaimerText}>{spamCheckMessage}</div>
    </Box>
  );
};

export default SpamCheck;
