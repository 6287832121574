/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import styled from 'styled-components';

import LeverageSlider from 'components/leverage/leverageSlider';
import { isMobileOrTablet } from 'helpers';
import { useLeverage } from 'hooks';
import { orderMarginSelector, productsSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';
import { OrdersObject } from 'types/basketOrders';
import { Product } from 'types/IProducts';
import { MaterialConfirmDialog } from 'UIKit';

import styles from './orders.module.scss';

interface BasketLeverageButtonProps {
  order: OrdersObject;
}

const LeverageStyleWrapper = styled.div`
  .isolated-content {
    .label {
      display: none;
    }

    .error-container {
      font-size: 12px;
      margin-top: 20px;
      > div {
        margin-bottom: 0px;
      }
    }

    .error-bottom {
      margin-bottom: 30px;
    }

    .margin-mode-info {
      padding: 0 24px 30px;
    }

    .submit-btn {
      width: 162px;
      height: 36px;
      margin: 0px 20px 27px 0;
    }
  }

  .inputSection {
    width: 100%;
    background: var(--separator);
    height: 36px;
    margin-top: 8px;
    border-radius: var(--inputBorderRadius);
    border: 1px solid var(--inputBorderColor);
    padding: 0px 1rem;
    &:focus {
      border: 1px solid var(--primaryTheme);
    }
    &:focus-within {
      border: 1px solid var(--primaryTheme);
    }

    // @media screen and (max-width: 1023px) {
    //   height: 44px;
    // }

    input {
      border: none;
      width: 50%;
      color: var(--primaryText);
      text-align: right;
      font-size: 12.1px;

      // @media screen and (max-width: 1023px) {
      //   font-size: 14px;
      // }
    }

    .incDecBtns {
      height: 100%;
      cursor: pointer;
      font-size: 24px;
      color: var(--secondaryText);
      &:hover {
        color: var(--primaryText);
      }
      // @media screen and (max-width: 1023px) {
      //   display: flex;
      //   align-items: center;
      // }
    }

    .valSuffix {
      width: 50%;
      font-size: 12.1px;
      color: var(--primaryText);
      margin-top: 1px;
      // @media screen and (max-width: 1023px) {
      //   font-size: 14px;
      // }
    }
  }

  .maxLeveragetext {
    color: var(--primaryText);
    font-size: 12.1px;
    font-family: var(--fontRegular) !important;
    span {
      color: var(--secondaryText);
    }

    @media screen and (max-width: 1023px) {
      color: var(--secondaryText);
      &.maxNotional {
        font-size: 14px;
        color: var(--primaryText);
      }
    }
  }

  .warning-label {
    font-size: 12.1px;
    margin-top: 28px;
    flex-direction: column;
    background: var(--tertiaryBackground);
    color: var(--secondaryText);
    padding: 15px;
    border-radius: 2px;
    line-height: 20px;
    .head {
      color: var(--highlight);
      margin-bottom: 10px;
      svg {
        margin-right: 8px;
        path {
          fill: var(--highlight);
        }
      }
    }
  }
`;

const BasketLeverageButton = ({ order }: BasketLeverageButtonProps) => {
  const { product_id, side } = order;

  const products = useAppSelector(state => productsSelector(state));
  const orderMargins = useAppSelector(state => orderMarginSelector(state));
  const productLeverage = orderMargins[product_id].leverage;

  const [showModal, setShowModal] = useState(false);

  const selectedProduct: Product = products[product_id];
  const { enableSubmit, handleLeverageSubmit } = useLeverage({
    product: selectedProduct,
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const submitLeverageAndClosePopup = () => {
    handleLeverageSubmit();
    handleModalClose();
  };

  return (<>
    <button
      data-testid="basket-leverage-button"
      onClick={() => setShowModal(true)}
      type="button"
      className={styles.leverageButton}
      data-palette="BasketLeverageButton">
      {productLeverage} x
    </button>
    {showModal && (
      <MaterialConfirmDialog
        showCancel={false}
        // accessoryTitleLeft={<span />}
        title={selectedProduct?.symbol || ''}
        marginTopInherit={!isMobileOrTablet()}
        // transition={isMobileOrTablet()}
        maxWidth={400}
        fullWidth={false}
        open={showModal}
        // contentClassName={styles.leverageModal}
        onClose={handleModalClose}
        onCancel={handleModalClose}
        confirmClassName={styles.confirmButton}
        onConfirm={enableSubmit ? submitLeverageAndClosePopup : handleModalClose}
        hideConfirmButton
      >
        <LeverageStyleWrapper>
          <LeverageSlider
            onCancel={handleModalClose}
            side={side}
            product={selectedProduct}
          />
        </LeverageStyleWrapper>
      </MaterialConfirmDialog>
    )}
  </>);
};

export default BasketLeverageButton;
