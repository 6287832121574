import React, { ComponentProps } from 'react';

import Popover from '@material-ui/core/Popover';
import { StylesProvider } from '@material-ui/core/styles';
import cx from 'classnames';

import classes from './popover.module.scss';

type PopoverMaterialType = ComponentProps<typeof Popover>;

interface PopoverMaterialProps extends PopoverMaterialType {
  contentText?: string;
  contentTextClassName?: string;
}

/**
 * @deprecated use "PopoverTooltip" from "UIKit/PopoverMaterial" instead
 * @see https://v4.mui.com/api/popover/
 */
const PopoverMaterial = ({
  open,
  anchorEl,
  onClose,
  contentText,
  contentTextClassName,
  children,
  ...rest
}: PopoverMaterialProps) => {
  return (
    (<StylesProvider injectFirst>
      <Popover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{
          paper: classes.popoverContainer,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children || (
          <p
            className={cx(classes.popoverContent, {
              [contentTextClassName as string]: contentTextClassName,
            })}
            data-palette="PopoverMaterial">
            {contentText}
          </p>
        )}
      </Popover>
    </StylesProvider>)
  );
};

export default PopoverMaterial;
