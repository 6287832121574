import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logout } from 'actions/user';
import routes from 'constants/routes';
import LinkExpiredIcon from 'styles/assets/icons/LinkExpiredIcon';
import { SetTimeOut } from 'types/utils';
import { Box } from 'UIKit';

import styles from './emailVerificationWithLink.module.scss';

const LinkExpiredText = () => {
  const history = useHistory();
  const { t } = useTranslation(['auth']);
  const dispatch = useDispatch();
  const timerRef = useRef<SetTimeOut>();

  useEffect(() => {
    dispatch(logout());
    timerRef.current = setTimeout(() => {
      history.push(routes.login);
    }, 5000);

    return () => clearTimeout(timerRef.current);
  }, [history, dispatch]);

  return (
    <Box className={styles.linkExpiredText}>
      <LinkExpiredIcon className={styles.linkExpiredIcon} />
      <Box className={styles.heading}>{t('auth:linkExpired')}</Box>
      <Box className={styles.subHeading}>{t('auth:redirectToLoginPage')}</Box>
    </Box>
  );
};

export default LinkExpiredText;
