/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';

import mixpanel from 'mixpanel-browser';
import { useHistory, useLocation } from 'react-router';

import { getKycStatus, isAuthenticated, updateProfile } from 'actions/user';
import { postMessageToMobileApp } from 'components/app/helpers';
import { LANDING_PAGE_URL, LOCAL_STORAGE_KEYS } from 'constants/constants';
import { dispatchGTMEvent } from 'helpers/utils';
import { getUserId, selectUserTrackingInfo, isUserKycDoneSelector } from 'selectors';
import { selectSignupReferralCode } from 'selectors/user';
import { useAppDispatch, useAppSelector } from 'storeHooks';

const TRACKING_INFO_GTM_KEYS = {
  KYC: 'is_kyc_gtm_tracked',
} as const;

const GTM_KYC_SUCCESS_EVENT_NAME = `delta_kyc_successful`;

const useUserTracking = () => {
  const userId = useAppSelector(getUserId);
  const isLoggedIn = useAppSelector(({ user }) => isAuthenticated(user));
  const location = useLocation();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const isKycDone = useAppSelector(isUserKycDoneSelector);
  const trackingInfo = useAppSelector(selectUserTrackingInfo);
  const signupReferralCode = useAppSelector(selectSignupReferralCode);
  const isValidTrackingInfo = trackingInfo && typeof trackingInfo === 'object';

  /**
   * GCLID is an id which tells that this user came from ads
   */
  const isGCLIDRemoved = useRef(false);

  /**
   * Using this variable to keep a track whether a update request was already sent to backend.
   * So no more additional requests will be made during KYC GTM tracking effect.
   */
  const isKYCDoneTrackedRequestSent = useRef(false);

  /**
   * On backend we are storing `is_kyc_gtm_tracked` whenever users kyc approved is tracked for the first time and sent to Google Tag Manager.
   * So this tracking is added to URL and stored on backend so we don't track it further for the same user.
   */
  const isKYCDoneGTMTracked =
    isValidTrackingInfo && TRACKING_INFO_GTM_KEYS.KYC in trackingInfo
      ? Boolean(trackingInfo[TRACKING_INFO_GTM_KEYS.KYC])
      : false;

  const shouldUserBeTrackedForKYC = Boolean(
    !isKYCDoneGTMTracked && isKycDone && !isKYCDoneTrackedRequestSent.current
  );

  useEffect(() => {
    async function trackUserKYCDone() {
      try {
        await appDispatch(updateProfile({ [TRACKING_INFO_GTM_KEYS.KYC]: true }));
        dispatchGTMEvent(GTM_KYC_SUCCESS_EVENT_NAME, {
          userId,
          referralCode: signupReferralCode,
        });
        await appDispatch(getKycStatus());
      } catch {}
    }

    if (shouldUserBeTrackedForKYC) {
      isKYCDoneTrackedRequestSent.current = true;
      trackUserKYCDone();
    }
  }, [appDispatch, userId, signupReferralCode, shouldUserBeTrackedForKYC]);

  useEffect(() => {
    if (!isGCLIDRemoved.current) {
      const searchParams = new URLSearchParams(location.search);
      const GCLID = searchParams.get('gclid');
      if (GCLID) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('gclid');
        newSearchParams.set('code', 'USER010');
        isGCLIDRemoved.current = true;
        history.replace({ ...location, search: `?${newSearchParams.toString()}` });
      }
    }
  }, [history, history.replace, location]);

  useEffect(() => {
    try {
      postMessageToMobileApp('APPSFLYER_SET_CUSTOMER_USER_ID', {
        id: mixpanel.get_distinct_id(),
      });
    } catch {}
  }, [isLoggedIn]);

  useEffect(() => {
    try {
      postMessageToMobileApp('SIGNUP_NOTIFICATION_PAYLOAD', {
        title: 'Sign-up & Trade in just 30 secs 📈',
        message: `Deposit & get up to $500 bonus! 💰`,
        userInfo: { url: `${LANDING_PAGE_URL}/app/signup` },
      });
    } catch {}
  }, []);

  useEffect(() => {
    /**
     * Can be tested using this link for prod - https://delta.gotrackier.com/click?campaign_id=2&pub_id=2
     * @description - Trackier is being used for analytics and sends a click_id found in the url query params which needs to be sent to the backend in verify email api.
     */
    try {
      const queryParams = new URLSearchParams(location.search);
      const trackierClickIdParam = queryParams.get('click_id');
      if (trackierClickIdParam) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.TRACKIER_CLICK_ID, trackierClickIdParam);
      }
    } catch {}
  }, [location.search]);
};

export { useUserTracking };
