/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import { useSelector } from 'react-redux';

import { getUnrealizedCashFlow } from 'components/balance/helpers';
import { getBalanceEquity } from 'components/holdings_balance/helpers';
import Render from 'components/render';
import { ASSET_SYMBOL, DETO_PRECISION } from 'constants/constants';
import { CURRENCY_TYPE } from 'constants/enums';
import decorateBalance from 'decorators/balance';
import { formatFiat, prettifyINR } from 'helpers/currency';
import {
  convertExponentialToDecimal,
  cropAfterDecimals,
  numberCommaSeparator,
  sortBalancesBySortPriority,
  indianNumberCommaSeparator,
  convertFromUSDtoINR,
  addZeroesUntilCorrectPrecision,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import {
  allOpenPositionsSelector,
  // markPriceSelectorById,
  openPositionMarkPriceSelectorById,
  roboTradingEquitySelector,
  walletSpotPriceBySymbol,
  portfolioMarginSelector,
  enableWalletsSelector,
  inrConversionRateSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import INR from 'styles/assets/coins/INR';
import Box from 'UIKit/Box';

/**
 *
 * @param {Array} balanceArray
 * @param {Array} openPosition
 * @param {Function} getMarkPrice
 * @param {Function} spotPriceFunc
 * @param {Number} positions_upl
 * @param {Number} [portfolioEnabledAsset]
 * @returns {Number} totalEquity
 */
const getTotalTradingEquity = (
  balanceArray,
  openPositions,
  getMarkPrice,
  spotPriceFunc,
  portfolio_upl,
  portfolioEnabledAsset
) =>
  balanceArray.reduce((acc, coinBalance) => {
    let spotPrice = 0;
    const assetSymbol = coinBalance.asset.symbol;

    switch (assetSymbol) {
      case 'BTC':
        spotPrice = Number(spotPriceFunc(`.DEXBT${ASSET_SYMBOL}`));
        break;
      case ASSET_SYMBOL:
        spotPrice = 1;
        break;
      case 'INR':
        spotPrice = 80;
        break;
      default:
        spotPrice = Number(spotPriceFunc(`.DE${assetSymbol}${ASSET_SYMBOL}`));
        break;
    }
    const balanceEquity = getBalanceEquity(
      coinBalance,
      openPositions,
      getMarkPrice,
      portfolio_upl,
      portfolioEnabledAsset
    );
    const value =
      acc +
      Number(Number.isNaN(spotPrice) ? 0 : spotPrice) *
        (Number.isNaN(balanceEquity) ? 0 : balanceEquity);

    return value;
  }, 0);

const TotalEquity = subscribe(
  ({
    className,
    usdClassName,
    btcClassName,
    hideBTC = false,
    isInr = false,
    isForWalletIndianExchange = false,
  }) => {
    const balance = useSelector(enableWalletsSelector);
    const balancesArray = sortBalancesBySortPriority(balance);

    const spotPriceFunc = symbol => walletSpotPriceBySymbol(symbol);
    const getMarkPrice = productId => openPositionMarkPriceSelectorById(productId);

    const openPositions = useSelector(allOpenPositionsSelector);
    const totalRoboStrategiesEquity = useSelector(roboTradingEquitySelector);
    const { positions_upl, asset_id: portfolioEnabledAsset } =
      portfolioMarginSelector()?.aggregate || {};
    const totalTradingEquity = getTotalTradingEquity(
      balancesArray,
      openPositions,
      getMarkPrice,
      spotPriceFunc,
      positions_upl,
      portfolioEnabledAsset
    );

    const btcusdSpotPrice = walletSpotPriceBySymbol(`.DEXBT${ASSET_SYMBOL}`);
    const inrConversionRate = useSelector(inrConversionRateSelector);
    const balances = useAppSelector(enableWalletsSelector);
    const usdBalanceObject = balances?.USD ?? {};
    const usdAssetMinPrecision = usdBalanceObject?.asset?.minimum_precision ?? 2;

    const totalEquityInUsd = cropAfterDecimals(
      Number(totalRoboStrategiesEquity) + Number(totalTradingEquity),
      2
    );
    const totalEquityInINR = cropAfterDecimals(
      convertExponentialToDecimal(totalEquityInUsd / inrConversionRate),
      2
    );
    const totalEquityInBTC = cropAfterDecimals(
      convertExponentialToDecimal(totalEquityInUsd / btcusdSpotPrice),
      8
    );

    if (isForWalletIndianExchange && !isInr) {
      return formatFiat(
        CURRENCY_TYPE.USD,
        cropAfterDecimals(
          addZeroesUntilCorrectPrecision(
            Number(totalTradingEquity),
            usdAssetMinPrecision
          ),
          usdAssetMinPrecision
        )
      );
    }

    if (isForWalletIndianExchange && isInr) {
      return prettifyINR(
        cropAfterDecimals(
          addZeroesUntilCorrectPrecision(
            convertFromUSDtoINR(Number(totalTradingEquity), inrConversionRate),
            usdAssetMinPrecision
          ),
          usdAssetMinPrecision
        )
      );
    }

    if (isInr) {
      return (
        (<div className={className} data-palette="TotalEquity">
          <span className={usdClassName}>
            <INR />{' '}
            {Number.isNaN(Number(totalEquityInINR))
              ? '-'
              : indianNumberCommaSeparator(totalEquityInINR)}
          </span>{' '}
          <span className={usdClassName}>
            ${' '}
            {Number.isNaN(Number(totalEquityInUsd))
              ? '-'
              : numberCommaSeparator(totalEquityInUsd)}
          </span>
        </div>)
      );
    }

    return (
      (<div className={className} data-palette="TotalEquity">
        <span className={usdClassName}>
          ${' '}
          {Number.isNaN(Number(totalEquityInUsd))
            ? '-'
            : numberCommaSeparator(totalEquityInUsd)}
        </span>{' '}
        <Render when={!hideBTC}>
          <span className={btcClassName}>
            ~
            {Number.isNaN(Number(totalEquityInBTC))
              ? '-'
              : numberCommaSeparator(totalEquityInBTC)}{' '}
            BTC
          </span>
        </Render>
      </div>)
    );
  }
);

const UnrealisedCashflow = subscribe(
  ({ coinBalance, returnInInr = false, showFullPrecision = false, className }) => {
    const balances = useAppSelector(enableWalletsSelector);
    const balanceFormatted = decorateBalance(sortBalancesBySortPriority(balances));
    const openPositions = useAppSelector(allOpenPositionsSelector);
    const portfolioMarginData = useAppSelector(portfolioMarginSelector);
    // eslint-disable-next-line camelcase
    const { positions_upl = 0, asset_id } = portfolioMarginData?.aggregate || {};
    const unrealizedCashFlow = getUnrealizedCashFlow(
      balanceFormatted,
      openPositions,
      positions_upl,
      asset_id
    );
    const inrConversionRate = useAppSelector(inrConversionRateSelector);

    const getPrecision = () => {
      if (showFullPrecision) return coinBalance?.asset?.precision;
      if (coinBalance?.asset?.symbol === 'DETO') return DETO_PRECISION;
      return coinBalance?.asset?.minimum_precision;
    };

    const getValue = () => {
      if (returnInInr) {
        return cropAfterDecimals(
          convertFromUSDtoINR(unrealizedCashFlow, inrConversionRate),
          getPrecision()
        );
      }

      return cropAfterDecimals(unrealizedCashFlow, getPrecision());
    };

    const displayValue = numberCommaSeparator(getValue());

    return (
      <Box as="span" className={className}>
        {displayValue}
      </Box>
    );
  }
);

export { TotalEquity, getTotalTradingEquity, UnrealisedCashflow };
