import { useCallback, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getUserKycStatusFromGlobal, importKycStatusFromGlobal } from 'actions/account';
import { getKycStatus, showKycShareConsentPopup } from 'actions/user';
import { STORAGE_KEYS } from 'constants/enums';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import {
  useToggle,
  useAppDispatch,
  useAppSelector,
  useApi,
  useLocalStorage,
} from 'hooks';
import {
  isUserLoggedInSelector,
  showKycShareConsentPopupSelector,
  userSelector,
} from 'selectors';
import type {
  KycConcentResult,
  KycConcentError,
  KycConcentUpdateError,
} from 'types/KycConcent';
import type { AllUnionMemberKeys } from 'types/utils';

export const KYC_CONSENT_SHARE_STEPS = {
  WELCOME: 'WELCOME',
  CONSENT: 'CONSENT',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
} as const;

type KycConsentShareSteps = AllUnionMemberKeys<typeof KYC_CONSENT_SHARE_STEPS>;

function useKycShareConcent() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isUserLoggedInSelector);
  const { is_kyc_done: isKycDone } = useAppSelector(userSelector);
  const [popupAlreadySeen, setPopupAlreadySeen] = useLocalStorage(
    STORAGE_KEYS.KYC_SHARE_CONSENT_POPUP_SEEN,
    false
  );

  const [agreeToTerms, toggleAgreeToTerms] = useToggle(false);
  const kycShareConsentPopupConfig = useAppSelector(showKycShareConsentPopupSelector);
  const concentModalOpen = !!kycShareConsentPopupConfig?.show;
  const initialStep = kycShareConsentPopupConfig?.showWelcomeScreen
    ? KYC_CONSENT_SHARE_STEPS.WELCOME
    : KYC_CONSENT_SHARE_STEPS.CONSENT;
  const isWelcomePopupVariant = initialStep === KYC_CONSENT_SHARE_STEPS.WELCOME;

  const openConsentModal = () => {
    dispatch(showKycShareConsentPopup({ show: true }));
  };

  const closeConsentModal = () => {
    if (isWelcomePopupVariant) {
      setPopupAlreadySeen(true);
    }
    dispatch(showKycShareConsentPopup({ show: false }));
  };

  const [stateOfResidence, setStateOfResidence] = useState('');
  const [consentModalStep, setConsentModalStep] =
    useState<KycConsentShareSteps>(initialStep);

  const { data } = useApi<undefined, KycConcentResult, KycConcentError>({
    requestOnMount:
      isAuthenticated && !isKycDone && (isWelcomePopupVariant ? !popupAlreadySeen : true),
    requestFn: () => dispatch(getUserKycStatusFromGlobal()),
    onSuccess: response => {
      if (response?.is_kyc_done) {
        openConsentModal();
      }
    },
  });

  const {
    requestor: handleKycImport,
    isLoading,
    error: importKycError,
  } = useApi<string, undefined, KycConcentUpdateError>({
    requestOnMount: false,
    requestFn: region => dispatch(importKycStatusFromGlobal(region)),
    onSuccess: () => {
      dispatch(getKycStatus());
      setConsentModalStep(KYC_CONSENT_SHARE_STEPS.SUCCESS);
    },
    onError: error => {
      setConsentModalStep(KYC_CONSENT_SHARE_STEPS.FAILED);
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.IMPORT_KYC.IMPORT_KYC_FAILED, {
        Source: 'Web app',
        error: error?.code || 'unknown_error',
      });
    },
  });

  const handleConfirm = useCallback(() => {
    handleKycImport(stateOfResidence);
  }, [handleKycImport, stateOfResidence]);

  return {
    data,
    agreeToTerms,
    toggleAgreeToTerms,
    concentModalOpen,
    closeConsentModal,
    handleConfirm,
    isLoading,
    stateOfResidence,
    setStateOfResidence,
    consentModalStep,
    setConsentModalStep,
    importKycError,
  };
}

export default useKycShareConcent;
