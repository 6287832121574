/* eslint-disable no-nested-ternary */
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';

import { isAuthenticated } from 'actions/user';
import {
  SUBSCRIBE_OPEN_POSITION_SPOT,
  SUBSCRIBE_SELECTED_PRODUCT_SPOT,
  SUBSCRIBE_WALLET_SPOT,
  UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_OPEN_POSITION_SPOT,
  UNSUBSCRIBE_SELECTED_PRODUCT_SPOT,
  UNSUBSCRIBE_WALLET_SPOT,
} from 'actionTypes/socket';
import { isNotEmpty } from 'ramdax';
import { selectedProductSelector } from 'selectors';
import { updateSpotMarkData } from 'variableStore/actions/tradingview';

import {
  updateOpenPositionSpotPrices,
  updateSelectedProductSpot,
  updateWalletSpotPrices,
} from '../variableStore/actions/price-index';
import { enabledWalletSpotSymbols, wsSubject } from './socket';

const payloadGenerator = (type, name, symbols) => {
  return {
    type,
    payload: {
      channels: [
        {
          name,
          symbols,
        },
      ],
    },
  };
};

const spotChannel = symbols =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'v2/spot_price', symbols),
    () => payloadGenerator('unsubscribe', 'v2/spot_price', symbols),
    message => message.type === 'v2/spot_price'
  );

const subscribeSpotChannel = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'v2/spot_price', symbol),
    () => {},
    message => message.type === 'v2/spot_price'
  );

const unsubscribeSpotChannel = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('unsubscribe', 'v2/spot_price', symbol),
    () => {},
    message => message.type === 'v2/spot_price'
  );

export const unsubscribeSelectedProductSpotChannelEpic = action$ =>
  action$.pipe(
    ofType(UNSUBSCRIBE_SELECTED_PRODUCT_SPOT),
    mergeMap(action =>
      unsubscribeSpotChannel([action.payload]).pipe(
        catchError(err => {
          console.error('UNSUBSCRIBE_SELECTED_PRODUCT_SPOT', err);
          return EMPTY;
        })
      )
    )
  );

export const subscribeSelectedProductSpotChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_SELECTED_PRODUCT_SPOT),
    mergeMap(action =>
      subscribeSpotChannel([action.payload]).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.error('Selected product spot', err);
          return EMPTY;
        })
      )
    ),
    filter(data => {
      const {
        spot_index: { symbol },
      } = selectedProductSelector(state$.value);
      return isNotEmpty(data) && symbol === data.s;
    }),
    map(data => updateSpotMarkData({ symbol: data.s, price: data.p })), // updateChartSpotData
    map(updateSelectedProductSpot),
    ignoreElements()
  );

export const subscribeWalletSpotChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_WALLET_SPOT),
    filter(() => isAuthenticated(state$.value.user)),
    mergeMap(() => {
      const spotSymbols = enabledWalletSpotSymbols();
      return spotChannel(spotSymbols).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_ALL, UNSUBSCRIBE_WALLET_SPOT)),
        catchError(err => {
          console.error('Wallet spot channels', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    map(updateWalletSpotPrices)
  );

export const subscribeOpenPositionsSpotEpic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_OPEN_POSITION_SPOT),
    mergeMap(action => {
      return subscribeSpotChannel([action.payload]).pipe(
        catchError(err => {
          console.error('Wallet spot channels', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    map(updateOpenPositionSpotPrices)
  );

export const unsubscribeOpenPositionsSpotEpic = (action$, state$) =>
  action$.pipe(
    ofType(UNSUBSCRIBE_OPEN_POSITION_SPOT),
    filter(action => {
      const {
        spot_index: { symbol },
      } = selectedProductSelector(state$.value);
      if (enabledWalletSpotSymbols().includes(action.payload)) return false;
      if (symbol === action.payload) return false;
      return true;
    }),
    mergeMap(action => {
      return unsubscribeSpotChannel(action.payload).pipe(
        catchError(err => {
          console.error('Wallet spot channels', err);
          return EMPTY;
        })
      );
    })
  );

export default combineEpics(
  subscribeSelectedProductSpotChannelEpic,
  unsubscribeSelectedProductSpotChannelEpic,
  subscribeWalletSpotChannelEpic,
  subscribeOpenPositionsSpotEpic,
  unsubscribeOpenPositionsSpotEpic
);
