import React from 'react';
import styled from 'styled-components';

const ChevronLeftStyle = styled.div`
  background: transparent;
  margin-top: 4px;
  height: 22px;
  width: 26px;
  transform: rotate(45deg);

  .vertical {
    position: absolute;
    top: 4px;
    right: 17px;
    height: 13px;
    border-right: 2px solid ${props => props.theme.secondaryText};
  }

  .horizontal {
    position: absolute;
    top: 16px;
    right: 7px;
    width: 11px;
    border-top: 2px solid ${props => props.theme.secondaryText};
  }
`;

const ChevronLeft = ({ onClick, className }) => (
  <ChevronLeftStyle onClick={onClick} className={'close ' + className}>
    <div className="vertical" />
    <div className="horizontal" />
  </ChevronLeftStyle>
);

export default ChevronLeft;
