import React from 'react';
import { PopoverBase } from '.';
import Popover from 'containers/Popover';
import { CustomPopoverBase } from './popover_base';

// When an element let's say tab is selected, show icon
// When an element is hovered, show icon
// else show nothing
export const ConditionallyRenderPopoverBase = ({
  isActive,
  showIcon,
  popoverOptions,
  baseProps = {},
}) => {
  if (!popoverOptions) {
    return null;
  }

  const komponent = (
    <Popover {...popoverOptions}>
      {thisProps => <PopoverBase {...thisProps} {...baseProps} />}
    </Popover>
  );

  if (isActive) {
    return komponent;
  }
  if (showIcon) {
    return komponent;
  }

  return null;
};

export const ToolTipWithCustomIcons = ({
  popoverOptions,
  baseProps,
  filledIcon,
  unfilledIcon,
}) => {
  return (
    <Popover
      {...popoverOptions}
      headerConfig={{
        headerStyle: {
          padding: '0',
        },
      }}
      placement={'top'}>
      {thisProps => (
        <CustomPopoverBase
          {...thisProps}
          {...baseProps}
          filledIcon={filledIcon}
          unfilledIcon={unfilledIcon}
        />
      )}
    </Popover>
  );
};
