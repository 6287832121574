/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import { Suspense, useCallback, useEffect } from 'react';

import { withSentryRouting } from '@sentry/react';
import cx from 'classnames';
import qs from 'qs';
import { withCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Notifications from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Route as ReactRoute, Redirect, Switch } from 'react-router';

import { changeBasketOrdersScreen, toggleBasketOrdersView } from 'actions/basketOrders';
import { userConnected, userDisconnected } from 'actions/user';
import { closeCurrencyConverterPopup } from 'actions/wallet';
import { AnalyticsTrackingGlobal } from 'components/analytics/AnalyticsTrackingGlobal';
import { AnalyticsTrackingIndia } from 'components/analytics/AnalyticsTrackingIndia';
import EmailVerificationWithLink from 'components/Auth/EmailVerificationWithLink';
import Reset2FA from 'components/Auth/Reset2FA';
import BalanceWalletCoin from 'components/balanceWalletCoin';
import { BankDetailsLoader } from 'components/BankDetails/BankDetails.components';
import MobileBasketOrder from 'components/basketOrders/mobileBasketOrders';
import ConvertBlockedPopup from 'components/convertBlockedPopup/convertBlockedPopup';
import ERC20DisableStrip from 'components/ERC20DisableStrip';
import ErrorBoundary from 'components/error_boundary';
import { InformationStrip } from 'components/InformationStrip';
import KycUpgradeCountdownStrip from 'components/kycUpgradeCountdownStrip/KycUpgradeCountdownStrip';
import KycUpgradeModal from 'components/kycUpgradeModal/KycUpgradeModal';
import { CenterLoader } from 'components/loader';
import Logos from 'components/logos/Logos';
import { LEADERBOARD_SCREENS } from 'components/OptionsLeaderBoard/types';
import Render from 'components/render';
import {
  RenderByExchange,
  RenderForIndianExchange,
} from 'components/render/RenderByExchangeType';
import StateOfResidenceModal from 'components/StateOfResidenceModal/StateOfResidenceModal';
import GenericPopup from 'components/trade/generic_popup';
import TradingRevokedStrip from 'components/TradingRevokedStrip/tradingRevokedStrip';
import {
  BASKET_ORDERS_SCREEN_VIEW,
  contractTypeRedirection,
  DISABLE_SEARCH_ENGINE_CRAWLING,
  FOOTER_PATH_LARGE_SCREENS_PATHS,
  IS_INDIAN_EXCHANGE,
  // isTestnet,
  isDevnet,
  LOCAL_STORAGE_KYC_POPUP_KEY,
  SHOW_2FA_RESET_POP_UP,
  SHOW_CONNECTION_STRIP,
  SHOW_CONNECTION_STRIP_MOBILE,
  SHOW_ERC20_DISABLE_STRIP,
  SHOW_LIQUIDATION_RISK,
  SHOW_MAINTENANCE_NOTIF,
  SHOW_MOBILE_FOOTER,
  SHOW_POPUPS,
  SHOW_PRIMARY_HEADER,
  SHOW_PRIMARY_HEADER_MOBILE,
  // INFORMATION_STRIP,
  SHOW_TRADING_REVOKED_STRIP,
} from 'constants/constants';
import { STORAGE_KEYS } from 'constants/enums';
import routes from 'constants/routes';
import { equals, pipe } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import { isUserIndian, isUserRegisteredBeforeKYCDate } from 'helpers/user';
import {
  checkEnvironmentMessageCondition,
  isKycRefreshExpired,
  isTestNet,
  isUserAtleastOneLevelVerified,
  shouldKYCPopupAppear,
  shouldShowKycUpgradeStrip,
  shouldShowKycUpgradeWarning,
  shouldShowStateUpdateModal,
} from 'helpers/utils';
import { useAppSelector, useLocalStorage, usePaletteTags } from 'hooks';
import useAppOnlineStatus from 'hooks/useAppOnlineStatus';
import useMediaQuery from 'hooks/useMediaQuery';
import usePageVisibility from 'hooks/usePageVisibility';
import useRNMessageEvents from 'hooks/useRNMessageEvents';
import useScrollToTopOnRouteChange from 'hooks/useScrollToTop';
import { useTrackierMobileMessage } from 'hooks/useTrackierMobileMessage';
import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import {
  activeInputFocusStatusSelector,
  appDrawerSelector,
  otherSelector,
  selectCountryFromIp,
  selectIsCorporateUser,
  userSelector,
} from 'selectors';
import { tradingViewChartSelector } from 'selectors/tradingViewSelector';
import MobileMenuBackButton from 'UIKit/menuBackButton';

import './app.scss';
import AppWrapper from './appStyle';
import { showComponentOnSelectedRoute } from './helpers';
import PortalContainers from './portalContainers';
import {
  AccountHeader,
  AppFooter,
  AsyncAccount,
  AsyncApi,
  AsyncAppDrawer,
  AsyncBellAlerts,
  AsyncDetoTradingCompetition,
  AsyncDisable2FA,
  AsyncENJTradingCompetition,
  AsyncExpiredFuturesPage,
  AsyncExpiredProductCharts,
  AsyncForgotPassword,
  AsyncFuturesTradingCompetition,
  AsyncHomePage,
  AsyncKNCTradingCompetition,
  AsyncLendTradingCompetition,
  AsyncLinkAccount,
  AsyncLogin,
  AsyncMarkets,
  AsyncMobileRiskViewAll,
  AsyncOffers,
  AsyncOptions,
  AsyncOptionsAnalyticsDashboard,
  AsyncOptionsLeaderBoardPage,
  AsyncReferences,
  AsyncReferral,
  AsyncResetPassword,
  AsyncSignup,
  AsyncSignupVerify,
  AsyncTamilTradingCompetition,
  AsyncTomoTradingCompetition,
  AsyncTrade,
  AsyncTradeHistoryPage,
  AsyncTradingCompetition,
  AsyncTradingViewChart,
  AsyncTradingViewChartNewTab,
  AsyncUniversalSearchMobile,
  AsyncUpdatePassword,
  AsyncWithdrawalConfirmation,
  AsyncXEMTradingCompetition,
  ConnectionStatusStrip,
  CurrencyConverter,
  DeltaIndiaStrip,
  IconsList,
  KycModal,
  KycReduceOnlyModal,
  KycRefreshDeadlineStrip,
  KycRefreshModal,
  KycShareConsentPopup,
  LiquidationRiskBanner,
  Maintenance,
  MaintenanceBanner,
  MobileQuickLinks,
  MobileSideMenu,
  NotFound,
  PrimaryHeader,
  PrimaryHeaderMobile,
  ResetPasswordPopup,
  TradingViewPrimaryHeader,
} from './Routes';

// Create Custom Sentry Route component
const Route = withSentryRouting(ReactRoute);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isloggedin ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = ({
  burgerMenuIsOpen,
  closePopup,
  coin_selected,
  cookies,
  forgotPassword,
  is_currency_popup_open,
  isInternetConnected,
  isloggedin,
  isSocketReconnecting,
  location,
  logout,
  notifications,
  offlineTime,
  popupType,
  resetPassword,
  selectedProduct,
  settings,
  showPopup,
  socketDisconnectTime,
  theme,
  toggleMenu,
  toggleTheme,
  user,
}) => {
  useRNMessageEvents();
  const dispatch = useDispatch();

  /**
   * Adding all namespace's here as we are loading only the file's for required languages.
   * If we dont load alerts or error's initially and then load it on demand then notifications dont render correctly.
   * Also <Suspense /> get's triggered again and whole page flashes blank screen.
   * So loading all namespaces for a particular language in start itself.
   */
  const { t } = useTranslation([
    'common',
    'trading',
    'alerts',
    'errors',
    'account',
    'auth',
    'headerFooter',
    'portfolioMargin',
    'markets',
    'errorBoundary',
    'basketOrders',
  ]);
  const [locale] = useLocalStorage('@delta/locale', 'EN');
  const { whenBelowMd, whenAboveMd } = useMediaQuery();
  const activeInputFocusStatus = useSelector(activeInputFocusStatusSelector);

  const {
    show_kyc_refresh_popup,
    is_kyc_refresh_required,
    show_reduce_only_popup,
    force_change_password,
    // force_change_mfa,
    country,
  } = useSelector(state => userSelector(state));
  const isCorporateUser = useAppSelector(selectIsCorporateUser);
  const countryFromIp = useAppSelector(selectCountryFromIp);
  const isIndiaIpRegionUser = countryFromIp === 'India';

  const { activeTab } = useAppSelector(appDrawerSelector);

  const other = useAppSelector(otherSelector);

  const { isFullScreen: isTradingViewFullScreen } = useSelector(state =>
    tradingViewChartSelector(state)
  );
  const [reset2faPopupShown] = useLocalStorage(
    STORAGE_KEYS.RESET_2FA_POPUP_SHOWN_KEY,
    false
  );

  useEffect(() => {
    if (!isloggedin) {
      localStorage.removeItem(LOCAL_STORAGE_KYC_POPUP_KEY);
    }
    // reset basket order to default state on mobile
    if (isMobile) {
      const isBasketOrderRoute = window.location.pathname.includes('basketOrder');
      !isBasketOrderRoute && dispatch(toggleBasketOrdersView(false));
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.DEFAULT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloggedin]);

  useEffect(() => {
    if (!isInternetConnected) {
      localStorage.setItem('isOnline', 'no');
    } else if (isInternetConnected) {
      if (localStorage.getItem('isOnline') === 'no') {
        window.location.reload(true);
        localStorage.setItem('isOnline', 'yes');
      }
    }
  }, [isInternetConnected]);

  // useTickerSubscription();
  usePaletteTags();
  useScrollToTopOnRouteChange();
  usePageVisibility();
  useAppOnlineStatus(pipe(userConnected, dispatch), pipe(userDisconnected, dispatch));

  useTrackierMobileMessage();

  const { under_maintenance } = settings;

  const setUserVisitCookie = () => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    const currentDate = `${expires.getDate()}-${
      expires.getMonth() + 1
    }-${expires.getFullYear()}`;
    cookies.set('user_last_visit', currentDate, { path: '/', expires });
  };

  const deleteUserVisitCookie = () => {
    cookies.remove('user_last_visit', { path: '/' });
  };

  useEffect(() => {
    if (IS_INDIAN_EXCHANGE && cookies.get('user_last_visit')) {
      deleteUserVisitCookie();
    } else {
      setUserVisitCookie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function setCookies(value) {
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      cookies.set('referral_code', value, { path: '/', expires: date });
    }

    if (window.location.search.includes('code')) {
      const referral_code = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      }).code;

      const investingClickId = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      }).click_id;

      // regex for referral code (alpha numeric with max length 20)
      const regexp = /^[a-zA-Z0-9]{0,20}$/;
      const regexMatch = regexp.test(referral_code);
      if (regexMatch) {
        setCookies(referral_code);
        if (
          referral_code === 'InvestCom' &&
          typeof investingClickId === 'string' &&
          investingClickId
        ) {
          localStorage.setItem('investing_click_id', investingClickId);
        }
      }
    }
  }, [cookies]);

  // const redirectPathName = localStorage.getItem('previousPathName');
  const fullPath = window.location.pathname + window.location.search;

  const getOptionsRedirectRoute = () => {
    const { pathname } = location;
    const selectedCoin = typeof pathname === 'string' ? pathname.split('/')[3] : null;
    const selectedContract = typeof pathname === 'string' ? pathname.split('/')[4] : null;
    if (!selectedContract) {
      return routes.options_chain.trade;
    }
    if (selectedContract.includes('MV')) {
      return `${routes.move_options.trade}/${selectedCoin}/${selectedContract}`;
    }
    return `${routes.options_chain.trade}/${selectedCoin}/${selectedContract}`;
  };

  const closeConvertPopup = useCallback(
    () => dispatch(closeCurrencyConverterPopup()),
    [dispatch]
  );

  // const openPositions = useAppSelector(allOpenPositionsSelector);
  // const isPositionsEmpty = openPositions.length === 0;
  const { pathname } = location;
  const isLoginAndSignupPage = pathname === routes.login || pathname === routes.signup;
  const seoKey = IS_INDIAN_EXCHANGE ? 'seoIndian' : 'seo';
  const showDeltaIndiaStrip =
    !IS_INDIAN_EXCHANGE && !isloggedin && isIndiaIpRegionUser && !isLoginAndSignupPage;

  return (
    (<Suspense
      fallback={
        <div style={{ flex: 1, height: '100%' }} data-palette="App">
          <CenterLoader />
        </div>
      }
    >
      <HelmetProvider>
        <Helmet>
          {DISABLE_SEARCH_ENGINE_CRAWLING ? (
            <meta name="robots" content="noindex, nofollow" data-palette="App" />
          ) : (
            <meta name="robots" content="index, follow" data-palette="App" />
          )}
          <title>{t(`common:${seoKey}.pageTitle`)}</title>
          <link
            rel="alternate"
            hrefLang={locale.toLowerCase() || 'en'}
            href={`https://www.delta.exchange${fullPath}`}
          />
          <link rel="alternate" hrefLang="x-default" href="https://www.delta.exchange/" />
          <meta name="title" content={t(`common:${seoKey}.title`)} />
          <meta name="description" content={t(`common:${seoKey}.description`)} />
          <meta
            property="og:title"
            name="og:title"
            content={t(`common:${seoKey}.title`)}
          />
          <meta
            data-rh="true"
            property="og:description"
            name="og:description"
            content={t(`common:${seoKey}.description`)}
          />
        </Helmet>

        <AppWrapper
          className="main-app"
          id="outer-container"
          isTestNet={isTestNet()}
          showEnvironmentMessage={checkEnvironmentMessageCondition(isloggedin)}
        >
          <ErrorBoundary>
            <Notifications notifications={notifications} />
            <RenderForIndianExchange>
              <Render when={isloggedin}>
                <BankDetailsLoader />
              </Render>
            </RenderForIndianExchange>
            <Route
              render={({ location }) => {
                const shouldShowOnRoute =
                  isloggedin &&
                  !isTestNet() &&
                  !is_kyc_refresh_required &&
                  !show_kyc_refresh_popup &&
                  !show_reduce_only_popup &&
                  shouldKYCPopupAppear() &&
                  isUserRegisteredBeforeKYCDate(user) &&
                  !user.is_kyc_done &&
                  !isUserIndian(user) &&
                  !isUserAtleastOneLevelVerified(user) &&
                  !showComponentOnSelectedRoute([
                    routes.account.identityVerification,
                    routes.login,
                  ])(location);

                return (
                  <Render when={shouldShowOnRoute}>
                    <KycModal />
                  </Render>
                );
              }}
            />

            <ConvertBlockedPopup />
            <RenderByExchange
              indian={<AnalyticsTrackingIndia />}
              global={<AnalyticsTrackingGlobal />}
            />
            <Route
              render={({ history }) => (
                <GenericPopup
                  history={history}
                  isLoggedIn={isloggedin}
                  showPopup={SHOW_POPUPS && showPopup}
                  closePopup={closePopup}
                  popupType={popupType}
                />
              )}
            />
            <Route
              render={() => {
                return (
                  <Render when={is_currency_popup_open && whenAboveMd}>
                    <CurrencyConverter coin={coin_selected} />
                  </Render>
                );
              }}
            />
            <Render when={showDeltaIndiaStrip}>
              <DeltaIndiaStrip />
            </Render>

            <main id="page-wrap" className={cx('page-wrap')}>
              <div id="portal" />
              <PortalContainers />
              <RenderForIndianExchange>
                <Render when={isloggedin}>
                  <KycShareConsentPopup />
                </Render>
              </RenderForIndianExchange>

              <Route
                render={({ location }) => {
                  const showOnRoute =
                    showComponentOnSelectedRoute(SHOW_MAINTENANCE_NOTIF)(location);

                  return (
                    <Render when={shouldShowKycUpgradeStrip(user, other) && showOnRoute}>
                      <KycUpgradeCountdownStrip />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const isRefreshDeadlineCrossed = isKycRefreshExpired(user);
                  const showComponentOnRoute =
                    showComponentOnSelectedRoute(SHOW_MAINTENANCE_NOTIF)(location);
                  const isChartTab = whenBelowMd
                    ? activeTab === APP_DRAWER_TAB_ID_MAPPING.CHART_ORDERBOOK
                    : false;

                  const showRefreshKycStrip =
                    isloggedin &&
                    showComponentOnRoute &&
                    is_kyc_refresh_required &&
                    !isRefreshDeadlineCrossed &&
                    !isChartTab;

                  return (
                    <Render when={showRefreshKycStrip}>
                      <KycRefreshDeadlineStrip user={user} />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showComponentOnRoute = showComponentOnSelectedRoute(
                    SHOW_TRADING_REVOKED_STRIP
                  )(location);
                  const isTradeTab = activeTab === APP_DRAWER_TAB_ID_MAPPING.TRADE;
                  const isSingaporeIndividual =
                    country === 'Singapore' && !isCorporateUser;

                  const showTradingRevokedStrip = whenBelowMd
                    ? showComponentOnRoute && isTradeTab && isSingaporeIndividual
                    : showComponentOnRoute && isSingaporeIndividual;

                  return (
                    <Render when={showTradingRevokedStrip}>
                      <TradingRevokedStrip />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showOnAllPagesExceptUpdatePsw =
                    location.pathname !== routes.updatePassword;
                  const showResetPswPopup =
                    !isTestNet() &&
                    user?.showPasswordChangePopup &&
                    showOnAllPagesExceptUpdatePsw;

                  return (
                    <Render when={showResetPswPopup}>
                      <ResetPasswordPopup />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showComponentOnRoute =
                    showComponentOnSelectedRoute(SHOW_2FA_RESET_POP_UP)(location);
                  const popUpShownBefore = JSON.parse(reset2faPopupShown);
                  const showPopup =
                    !isTestNet() &&
                    user?.show2faResetPopup &&
                    showComponentOnRoute &&
                    !popUpShownBefore &&
                    isloggedin;

                  return (
                    <Render when={showPopup}>
                      <Reset2FA />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  // const showMaintenance = showComponentOnSelectedRoute(
                  //   SHOW_MAINTENANCE_NOTIF
                  // )(location);
                  const showMaintenanceOnMobile =
                    showComponentOnSelectedRoute(SHOW_MAINTENANCE_NOTIF)(location);

                  return (
                    <MediaQuery query={device.down.md}>
                      <Render when={showMaintenanceOnMobile}>
                        <MaintenanceBanner isLoggedIn={isloggedin} isTopBanner />
                      </Render>
                    </MediaQuery>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const shouldShowErc20DisbaleStrip = showComponentOnSelectedRoute(
                    SHOW_ERC20_DISABLE_STRIP
                  )(location);

                  return (
                    <Render when={shouldShowErc20DisbaleStrip}>
                      <ERC20DisableStrip />
                    </Render>
                  );
                }}
              />
              <RenderByExchange
                indian={<InformationStrip />}
                global={
                  <Render when={!isTestNet()}>
                    <InformationStrip />
                  </Render>
                }
              />
              <Route
                render={({ location }) => {
                  const showPrimaryHeader =
                    showComponentOnSelectedRoute(SHOW_PRIMARY_HEADER)(location);

                  const showPrimaryHeaderMobile = showComponentOnSelectedRoute(
                    SHOW_PRIMARY_HEADER_MOBILE
                  )(location);

                  return (
                    <>
                      <MediaQuery query={device.up.md}>
                        <Render when={showPrimaryHeader}>
                          <Render
                            when={isTradingViewFullScreen}
                            else={<PrimaryHeader isloggedin={isloggedin} />}
                          >
                            <TradingViewPrimaryHeader />
                          </Render>
                        </Render>
                      </MediaQuery>

                      <MediaQuery query={device.down.md}>
                        <Render when={showPrimaryHeaderMobile}>
                          <PrimaryHeaderMobile isloggedin={isloggedin} />
                        </Render>
                      </MediaQuery>
                    </>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showLiquidationRisk =
                    showComponentOnSelectedRoute(SHOW_LIQUIDATION_RISK)(location);

                  return (
                    <MediaQuery query={device.up.md}>
                      <Render when={showLiquidationRisk}>
                        <LiquidationRiskBanner />
                      </Render>
                    </MediaQuery>
                  );
                }}
              />
              <Route
                path={routes.account.default}
                render={({ ...rest }) => {
                  return (
                    <AccountHeader
                      burgerMenuIsOpen={burgerMenuIsOpen}
                      theme={theme}
                      isloggedin={isloggedin}
                      toggleMenu={toggleMenu}
                      logout={logout}
                      toggleTheme={toggleTheme}
                      selectedProduct={selectedProduct}
                      {...rest}
                    />
                  );
                }}
              />
              <Switch location={location}>
                <PrivateRoute
                  exact
                  isloggedin={isloggedin}
                  path={routes.updatePassword}
                  component={AsyncUpdatePassword}
                />
                <Route
                  exact
                  path={routes.maintenance}
                  render={() => {
                    return <Maintenance />;
                  }}
                />
                {equals(force_change_password, true) && (
                  <Redirect to={routes.updatePassword} />
                )}
                {equals(under_maintenance, 'true') && (
                  <Redirect to={routes.maintenance} />
                )}
                <Redirect
                  strict
                  exact
                  from="/"
                  to={whenBelowMd ? routes.homepage.default : routes.webAppHome}
                  state={{ status: 301 }}
                />
                {[
                  `${routes.default}markets`,
                  `${routes.default}markets/`,
                  `${routes.default}trade`,
                  `${routes.default}trade/*`,
                ].map((path, i) => (
                  <Redirect
                    strict
                    exact
                    state={{ status: 301 }}
                    from={path}
                    to={routes.webAppHome}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i * 2000}
                  />
                ))}

                {contractTypeRedirection.map((path, i) => (
                  <Redirect
                    strict
                    exact
                    state={{ status: 301 }}
                    from={path.from}
                    to={path.to}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}$ß`}
                  />
                ))}

                {contractTypeRedirection.map((path, i) => (
                  <Redirect
                    strict
                    exact
                    state={{ status: 301 }}
                    from={`${path.from}/`} // redirection should work even after removing /
                    to={path.to}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}@`}
                  />
                ))}

                {!whenBelowMd && (
                  <Redirect
                    strict
                    exact
                    state={{ status: 301 }}
                    from={`${routes.options.trade}/*`}
                    to={getOptionsRedirectRoute()}
                  />
                )}

                {/* {[
                  routes.move_options.trade,
                  routes.move_options.trade + '/:assetSymbol?/:productSymbol?',
                ].map((path, i) => (
                  <Redirect
                    strict
                    exact
                    key={i + 'q'}
                    state={{ status: 301 }}
                    from={path}
                    to={routes.options.trade}
                  />
                ))} */}
                <Route
                  path={routes.homepage.default}
                  render={({ location }) => {
                    // Homepage does not exist in desktop view so redirecting to markets page.
                    const { search, hash } = location;
                    return (
                      <>
                        <MediaQuery query={device.up.md}>
                          <Redirect
                            to={{
                              search,
                              hash,
                              pathname: routes.options_chain.markets,
                            }}
                          />
                        </MediaQuery>
                        <MediaQuery query={device.down.md}>
                          <AsyncHomePage />
                        </MediaQuery>
                      </>
                    );
                  }}
                />
                <Route
                  strict
                  exact
                  path={routes.crypto_trading_league}
                  component={AsyncTradingCompetition}
                />

                <Route strict exact path={routes.resources} component={MobileSideMenu} />
                <Route
                  strict
                  exact
                  path={routes.usefulResources}
                  component={MobileQuickLinks}
                />

                <Route
                  strict
                  exact
                  path={routes.lend_trading_competition}
                  component={AsyncLendTradingCompetition}
                />

                <Route
                  strict
                  exact
                  path={routes.xem_trading_competition}
                  component={AsyncXEMTradingCompetition}
                />

                <Route
                  strict
                  exact
                  path={routes.tamil_trading_competition}
                  component={AsyncTamilTradingCompetition}
                />

                <Route
                  strict
                  exact
                  path={routes.tomo_trading_competition}
                  component={AsyncTomoTradingCompetition}
                />

                <Route
                  strict
                  exact
                  path={routes.knc_trading_competition}
                  component={AsyncKNCTradingCompetition}
                />

                <Route
                  strict
                  exact
                  path={routes.enj_trading_competition}
                  component={AsyncENJTradingCompetition}
                />
                <Route
                  strict
                  exact
                  path={routes.deto_trading_competition}
                  component={AsyncDetoTradingCompetition}
                />
                {!IS_INDIAN_EXCHANGE && (
                  <Route
                    strict
                    exact
                    path={routes.futures_trading_competition}
                    component={AsyncFuturesTradingCompetition}
                  />
                )}

                <Route
                  strict
                  exact
                  path={`${routes.default}:contractType/markets`}
                  render={_props => {
                    // tag('palette.location', _props.match.path);
                    return <AsyncMarkets {..._props} />;
                  }}
                />

                {/* <Route
                  strict
                  exact
                  path={`${routes.default}:contractType/markets/`}
                  render={_props => {
                    tag('palette.location', _props.match.path);
                    return {
                    tag('palette.location', _props.match.path);
                    return <AsyncMarkets {..._props} />;
                  };
                  }}
                /> */}

                {!IS_INDIAN_EXCHANGE && (
                  <Route
                    path={routes.portfolio}
                    render={() => <Redirect exact to={routes.account.portfolioMargin} />}
                  />
                )}

                <Route
                  path={routes.customLeaderBoard}
                  render={props => (
                    <AsyncOptionsLeaderBoardPage
                      {...props}
                      screen={LEADERBOARD_SCREENS.MAIN}
                    />
                  )}
                />
                {[
                  `${routes.irs.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.futures.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.spreads.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.options.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.move_options.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.turbo.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.spot.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.options_chain.trade}/:assetSymbol?/:productSymbol?`,
                  `${routes.optionsCombos.trade}/:assetSymbol?/:productSymbol?`,
                ].map((path, index) =>
                  isTradingViewFullScreen ? (
                    <Route
                      exact
                      // eslint-disable-next-line react/no-array-index-key
                      key={index + 2000}
                      path={path}
                      render={_props => {
                        // tag('palette.location', _props.match.path);
                        return <AsyncTradingViewChart {..._props} />;
                      }}
                    />
                  ) : (
                    <Route
                      path={path}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index + 10}
                      render={_props => {
                        // console.log('Debug: tag', _props.match.path);
                        // tag('palette.location', _props.match.path);
                        return <AsyncTrade {..._props} />;
                      }}
                    />
                  )
                )}

                <Route
                  exact
                  path={routes.embedChart}
                  render={_props => <AsyncTradingViewChartNewTab {..._props} />}
                />

                <Route
                  exact
                  path={`${routes.expired_charts}/:symbol`}
                  component={AsyncExpiredProductCharts}
                />

                {/* {ENABLE_OPTIONS && ( */}
                <Route exact path={routes.easy_options.trade} component={AsyncOptions} />

                <Route
                  exact
                  path={routes.account.withdrawal_confirmation}
                  component={AsyncWithdrawalConfirmation}
                />

                <Route
                  exact
                  path={routes.universalSearchMobile}
                  component={AsyncUniversalSearchMobile}
                />

                <PrivateRoute
                  isloggedin={isloggedin}
                  path={routes.account.default}
                  component={AsyncAccount}
                />

                <Route
                  exact
                  path={routes.optionsAnalyticsDashboard.default}
                  component={AsyncOptionsAnalyticsDashboard}
                />
                {!IS_INDIAN_EXCHANGE && (
                  <Route
                    path={routes.optionsAnalyticsDashboard.default}
                    component={AsyncOptionsAnalyticsDashboard}
                  />
                )}

                {/* <Route path={routes.contracts} component={AsyncContracts} /> */}
                <Route path={routes.references} component={AsyncReferences} />
                <Route exact path={routes.api} component={AsyncApi} />
                <Route exact path={routes.login} component={AsyncLogin} />
                <Route exact path={routes.linkAccount} component={AsyncLinkAccount} />
                <Route exact path={routes.disable2fa} component={AsyncDisable2FA} />
                <Route exact path={routes.disable2fa} component={AsyncDisable2FA} />
                <Route
                  exact
                  path={`${routes.signup}/:referral?`}
                  component={AsyncSignup}
                />
                <Route exact path={routes.verifyRegister} component={AsyncSignupVerify} />
                <Route
                  exact
                  path={routes.forgotPassword}
                  render={() => <AsyncForgotPassword forgotPassword={forgotPassword} />}
                />
                <Route
                  exact
                  path={routes.resetPassword}
                  render={({ location }) => {
                    const params = qs.parse(location.search, {
                      ignoreQueryPrefix: true,
                    });
                    return (
                      <AsyncResetPassword
                        token={params.token}
                        resetPassword={resetPassword}
                        forgotPassword={forgotPassword}
                      />
                    );
                  }}
                />

                <Route
                  strict
                  exact
                  path={routes.expired_futures}
                  render={() => (
                    <Redirect exact to={routes.expired_futures_and_options} />
                  )}
                />
                <Route
                  strict
                  exact
                  path={routes.expired_futures_and_options}
                  component={AsyncExpiredFuturesPage}
                />
                {/* Don't render on testnet */}
                {!isTestNet() && (
                  <Route exact path={routes.referral} component={AsyncReferral} />
                )}

                <PrivateRoute
                  exact
                  isloggedin={isloggedin}
                  path={routes.bell_alerts}
                  component={AsyncBellAlerts}
                />

                <PrivateRoute
                  exact
                  isloggedin={isloggedin}
                  path={routes.offers}
                  component={AsyncOffers}
                />

                <PrivateRoute
                  exact
                  isloggedin={isloggedin}
                  path={routes.tradeHistory}
                  component={AsyncTradeHistoryPage}
                />

                <Route exact path={routes.logo} render={() => <Logos />} />

                <Route
                  exact
                  path={routes.verifyEmailWithLink}
                  render={() => <EmailVerificationWithLink />}
                />

                {/** Mobile Only Route */}
                {whenBelowMd && (
                  <Route
                    strict
                    exact
                    path={routes.basketOrder.trade}
                    component={MobileBasketOrder}
                  />
                )}

                {/** Mobile Only Route */}
                {whenBelowMd && (
                  <Route
                    exact
                    path={routes.convert}
                    render={({ history }) => (
                      <>
                        <MobileMenuBackButton
                          data-testid="mobile-back-button"
                          text={t('trading:currencyConversion.title')}
                          onBackButtonClick={() => {
                            history.goBack();
                            closeConvertPopup();
                          }}
                          showCloseIcon
                          onCrossIconClick={() => {
                            history.goBack();
                            closeConvertPopup();
                          }}
                        />
                        <CurrencyConverter coin={coin_selected || 'USDT'} />
                      </>
                    )}
                  />
                )}

                {/* Mobile Only Route */}
                {whenBelowMd && (
                  <Route
                    strict
                    exact
                    path={routes.riskViewAll}
                    component={AsyncMobileRiskViewAll}
                  />
                )}

                {/** Devnet Only Route */}
                {isDevnet && (
                  <Route exact path={routes.iconList} render={() => <IconsList />} />
                )}

                {/** Ignore Trading View Charting Library route */}
                <Route path={routes.chartingLibrary} />

                <PrivateRoute
                  exact
                  isloggedin={isloggedin}
                  path={`${routes.balanceCoin}/:coin`}
                  component={BalanceWalletCoin}
                />

                {/** No Match Route */}
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Route
                render={({ location }) => {
                  return (
                    <Render
                      when={
                        showComponentOnSelectedRoute(SHOW_MOBILE_FOOTER)(location) &&
                        !activeInputFocusStatus
                      }
                    >
                      <MediaQuery query={device.down.md}>
                        <AsyncAppDrawer />
                      </MediaQuery>
                    </Render>
                  );
                }}
              />
              {/* Todo : footer never comes inside main . App needs to maintain sematic UI . */}
              <Route
                render={({ location }) => {
                  // TODO: create a whitelist location to render the app footer
                  const showOnLargeScreen = showComponentOnSelectedRoute(
                    FOOTER_PATH_LARGE_SCREENS_PATHS
                  )(location);

                  return (
                    <Render when={!window.isRNWebView}>
                      <footer>
                        <MediaQuery query={device.up.md}>
                          <Render when={showOnLargeScreen}>
                            <AppFooter />
                          </Render>
                        </MediaQuery>
                      </footer>
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showConnectionStatus = showComponentOnSelectedRoute(
                    whenBelowMd ? SHOW_CONNECTION_STRIP_MOBILE : SHOW_CONNECTION_STRIP
                  )(location);

                  return (
                    <Render when={showConnectionStatus}>
                      <ConnectionStatusStrip
                        isInternetConnected={isInternetConnected}
                        isSocketReconnecting={isSocketReconnecting}
                        isloggedin={isloggedin}
                        offlineTime={offlineTime}
                        socketDisconnectTime={socketDisconnectTime}
                      />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showModalOnSelectedRoute =
                    showComponentOnSelectedRoute(SHOW_MAINTENANCE_NOTIF)(location);

                  return (
                    <Render when={show_reduce_only_popup && showModalOnSelectedRoute}>
                      <KycReduceOnlyModal />
                    </Render>
                  );
                }}
              />
              <Route
                render={({ location }) => {
                  const showRefreshModal =
                    showComponentOnSelectedRoute(SHOW_MAINTENANCE_NOTIF)(location);

                  return (
                    <Render when={is_kyc_refresh_required && showRefreshModal}>
                      <KycRefreshModal />
                    </Render>
                  );
                }}
              />
              <Render when={shouldShowKycUpgradeWarning(user)}>
                <KycUpgradeModal />
              </Render>
              <Render when={shouldShowStateUpdateModal(user)}>
                <StateOfResidenceModal />
              </Render>
            </main>
          </ErrorBoundary>
        </AppWrapper>
      </HelmetProvider>
    </Suspense>)
  );
};

// export { app };
const AppWithCookies = withCookies(App);
export default AppWithCookies;
