export const isBracketOrderEnable = selected_product => {
  return selected_product &&
    selected_product.ui_config.show_bracket_orders === undefined
    ? true
    : false;
};

export const isReduceOnlyEnable = selected_product => {
  return selected_product &&
    selected_product.contract_type !== 'spot' &&
    selected_product.ui_config.show_bracket_orders === false
    ? true
    : false;
};
