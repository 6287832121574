import styled from 'styled-components';

export const ChunkLoadErrorDesktopStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: ${props => props.theme.primaryBackground};

  .error-img-wrapper {
    width: 385px;
    // margin-bottom: 2.5rem;
    .error-img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .subTitle {
    height: 20px;
    margin: 23px 96.5px 39px;
    // -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${props => props.theme.secondaryText};
  }

  .title {
    height: 35px;
    margin: 0px 0 20px;
    font-size: 24px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${props => props.theme.primaryText};
  }
  button {
    width: 256px;
    height: 56px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 2px;
  }
  .instructions {
    margin-top: 43px;
    background: ${props => props.theme.secondaryBackground};
    padding: 25px 54px;
    a {
      color: ${props => props.theme.primaryTheme};
      margin-left: 5px;
      margin-right: 5px;
      svg {
        margin-top: -4px;
      }
    }
    .info {
      margin-bottom: 25px;
      svg {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        margin-top: -3px;
      }
    }

    .iosIcon {
      svg {
        margin-top: -6px;
      }
    }
  }
`;

export const ChunkLoadErrorMobileStyle = styled.div`
  background: ${props => props.theme.primaryBackground};
  height: 100%;
  display: flex;
  flex-direction: column;

  .section-top {
    padding: 40px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-img-wrapper {
    background: ${props => props.theme.secondaryBackground};
    border-radius: 20px;
    padding: 30px;
    height: 100px;
    width: 100px;
  }

  .subTitle {
    margin-top: 13px;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${props => props.theme.secondaryText};
    margin-bottom: 18px;
  }

  .title {
    font-weight: 600;
    margin-top: 36px;
    font-size: 18px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${props => props.theme.primaryText};
  }

  button {
    width: 250px;
    height: 42px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 2px;
    margin-bottom: 30px;
  }

  .instructions {
    background: ${props => props.theme.secondaryBackground};
    padding: 17px 25px 40px 25px;
    flex-grow: 1;
    .title {
      text-align: center;
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 17px;
      margin-top: 0px;
    }
    a {
      color: ${props => props.theme.primaryTheme};
      margin-left: 5px;
      margin-right: 5px;
      svg {
        margin-top: -4px;
      }
    }
    .device {
      .name {
        color: ${props => props.theme.secondaryText};
        margin-bottom: 15px;
        font-size: 12.1px;
      }
      .direction {
        font-size: 12.1px;
        margin-bottom: 15px;
        svg {
          margin-right: 8px;
          margin-top: -2px;
          height: 9px;
          path {
            stroke: ${props => props.theme.primaryText};
          }
        }
      }
    }
  }
  .footer {
    font-size: 12.1px;
  }
`;
