import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledFloatingLabel = styled.div`
  * {
    font-family: inherit;
  }

  margin-top: 35px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 2em;
    border-bottom: 1px solid ${props => props.theme.secondaryText};
  }

  input,
  label {
    padding: 5px 0px;
    margin: 0;
    border: 0;
  }

  input {
    border: none;
    outline: none;
    font-size: 1em;
    &::placeholder {
      color: #9b9b9b;
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    }
  }

  label {
    position: absolute;
    color: #9b9b9b;
    bottom: 0;
    transition: all linear 0.2s;
    transform-origin: left top;
    font-size: 1em;
    cursor: text;
    pointer-events: none;
  }

  &.active {
    input {
      &::placeholder {
        opacity: 1;
      }
    }
  }
`;

const StyledInput = styled.input`
  &:-webkit-autofill {
    caret-color: ${props => props.theme.primaryText};
    box-shadow: 0 -2px 0 30px ${props => props.theme.primaryBackground}; inset;
    -webkit-box-shadow: 0 -2px 0 30px ${props =>
      props.theme.primaryBackground} inset;
    -webkit-text-fill-color: ${props => props.theme.primaryText} !important;
  }

  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.primaryText} !important;
  }
`;

export default class FloatingLabelInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.value && props.value.length > 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (
        (this.props.value && this.props.value !== '') ||
        (this.props.refs && this.props.refs.current.value !== '')
      ) {
        this.setState({
          active: true,
        });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      className,
      fontSize,
      label,
      inBlur,
      onChange,
      inFocus,
      type,
      refs,
    } = this.props;
    const { active } = this.state;

    return (
      <StyledFloatingLabel
        className={classNames('react-floating-label-input', {
          active,
          [className]: !!className,
        })}>
        <div
          className="container"
          style={{
            fontSize: fontSize ? fontSize : 'inherit',
            height: `2em`,
          }}>
          <label
            style={{
              transform: active
                ? `translate3d(0, -70%, 0) scale(0.70)`
                : 'none',
            }}>
            {label}
          </label>
          <StyledInput
            onBlur={event => {
              this.setState({ active: event.target.value.length !== 0 });
              if (inBlur) {
                inBlur(event);
              }
            }}
            onChange={event => {
              this.setState({ active: true });
              if (onChange) {
                onChange(event);
              }
            }}
            onFocus={event => {
              this.setState({ active: true });
              if (inFocus) {
                inFocus(event);
              }
            }}
            type={type || 'text'}
            ref={refs}
            {...this.props}
          />
        </div>
      </StyledFloatingLabel>
    );
  }
}
