/* eslint-disable no-underscore-dangle */

import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ZodFormattedError } from 'zod';

import { getValidIFSCCodes } from 'actions/account';
import { getWithdrawalsPendingCount } from 'actions/user';
import { CenterLoader } from 'components/loader';
import Render from 'components/render';
import { BANK_ACCOUNT_DEFAULT_LABEL } from 'constants/constants';
import { CURRENCY_STYLE } from 'constants/currency';
import { LOCALES, TIME_IN_MS } from 'constants/dateTime';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import routes from 'constants/routes';
import { formatFiat } from 'helpers/currency';
import { devError } from 'helpers/logger';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { omit } from 'helpers/ramda';
import { useAppDispatch, useToggle, useWindowSize } from 'hooks';
import useBankDetails, {
  ADD_BANK_CHECK_STATUS_INTERVAL,
  BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL,
  AddBankAccount as AddBankAccountSchema,
  type AddBankAccountFields,
  type AddBankAccountData,
} from 'hooks/componentHooks/useBankDetails';
import useApi, { STATUS } from 'hooks/useApi';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import i18n from 'i18n/config';
import {
  CircledPlus,
  DeleteFilled,
  InfoFilled,
  RupeeGoldCoin,
  TickRoundFilled,
} from 'styles/assets/icons';
import { ADD_BANK_CUSTODIAN_STATUS } from 'types/Account';
import { MaterialConfirmDialog } from 'UIKit';
import Box from 'UIKit/Box';
import DeltaAdaptivePanel from 'UIKit/DeltaAdaptivePanel';
import DeltaButton from 'UIKit/DeltaButton';
import DeltaHelperText from 'UIKit/DeltaHelperText/DeltaHelperText';
import { DeltaInputWrapper } from 'UIKit/DeltaInput';
import DeltaOTPVerification, { type Codes } from 'UIKit/DeltaOTPVerification';
import NumberInput from 'UIKit/NumberInput';
// import { PopoverTooltip } from 'UIKit/PopoverMaterial';

import { getMappedErrorsForIdentityVerification } from './BankDetails.helpers';
import { BANK_ICON_MAP } from './BankDetails.logo';
import styles from './bankDetails.module.scss';
import {
  DEFAULT_DEPOSIT_AMOUNT,
  type AddNewBankAccountPanel as AddNewBankAccountPanelProps,
  type AddBankAccount as AddBankAccountProps,
  type NoBankAccountsPlaceholder as NoBankAccountsPlaceholderProps,
  type BankIcon as BankIconProps,
  type PanelIcon as PanelIconProps,
  type StatusPanel as StatusPanelProps,
  type AddBankAccountVerifyingPanel as AddBankAccountVerifyingPanelProps,
  type AddBankAccountAckPanel as AddBankAccountAckPanelProps,
  type AddNewBankAccountOTPVerification as AddNewBankAccountOTPVerificationProps,
  type AccountDetail as AccountDetailProps,
  WithdrawalPendingAPIResponse,
  BankDetailsResponse,
} from './BankDetails.types';

const INFO_TEXTS = [
  {
    id: 'shouldBelongToYou',
    text: i18n.t('account:addBank.helpers.shouldBelongToYou'),
  },
  {
    id: 'nameShouldMatchPAN',
    text: i18n.t('account:addBank.helpers.nameShouldMatchPAN'),
  },
  {
    id: 'manualVerificationDoneByUs',
    text: i18n.t('account:addBank.helpers.manualVerificationDoneByUs'),
  },
  {
    id: 'unsupportedBanks',
    text: (
      <Trans
        i18nKey="account:addBank.helpers.unsupportedBanks"
        components={{ style: <span className={styles.unsupportedBanks} data-palette="INFO_TEXTS" /> }}
      />
    ),
  },
];

const STATIC_TEXTS = {
  INVALID_IFSC: i18n.t('errors:custom.invalid_ifsc_given'),
  UNSUPPORTED_BANK_ACCOUNT: i18n.t('errors:custom.unsupported_bank_account'),
};

const BankIcon = ({ bankName, size, ...props }: BankIconProps) => {
  const sanitizedName = bankName ? bankName.toLowerCase().replaceAll(' ', '') : '';

  const Logo = BANK_ICON_MAP[sanitizedName]?.[size];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return Logo && <Logo {...props} />;
};

const InfoTextsList = () => {
  return (
    <Box
      as="ul"
      className={styles.noBankAccountsPlaceholderAddNewAccountHints}
      flexDir="column"
      gap={16}
      alignItems="start"
      justifyContent="start"
      fontSize="sm2"
      textColor="secondary"
    >
      {INFO_TEXTS.map(item => (
        <Box key={item.id} as="li">
          {item.text}
        </Box>
      ))}
    </Box>
  );
};

const NoBankAccountsPlaceholder = ({
  onAddButtonClick,
  showHelperTexts = true,
}: NoBankAccountsPlaceholderProps) => {
  const { t } = useTranslation();

  return (
    <Box flexDir="column" className={styles.noBankAccountsPlaceholderRoot}>
      <DeltaButton
        data-testid="add-bank-account-btn"
        variant="special-secondary-t5"
        // @ts-ignore
        icon={<CircledPlus color="var(--primaryTheme)" />}
        onClick={onAddButtonClick}
      >
        {t('account:addBank.titles.addBankAccount')}
      </DeltaButton>

      <Render when={showHelperTexts}>
        <InfoTextsList />
      </Render>
    </Box>
  );
};

const PanelIcon = ({ type = 'success', icon }: PanelIconProps) => {
  return (
    <Box
      flexPosition="flex-center-align"
      className={cn(styles.panelIcon, {
        [styles.success]: type === 'success',
        [styles.error]: type === 'error',
      })}
    >
      {icon}
    </Box>
  );
};

const StatusPanel = ({ title = null, onClose, children }: StatusPanelProps) => {
  const { isMobile } = useWindowSize();

  return (
    (<DeltaAdaptivePanel
      open
      showHeader
      showFooter={false}
      fullHeight={false}
      onClose={onClose}
      title={isMobile && title ? title : ''}
      accessoryTitleRight={isMobile ? null : <span data-palette="StatusPanel" />}
      panelRootClassName={styles.addNewBankAccountSubPanel}
    >
      <Box
        flexPosition="flex-center-column"
        className={styles.addNewBankAccountAckPanel}
        autoMargin="both"
      >
        <Box renderWhen={Boolean(!isMobile && title)} as="h4" fontSize="md1">
          {title}
        </Box>

        {children}
      </Box>
    </DeltaAdaptivePanel>)
  );
};

const AddBankAccountVerifyingPanel = ({ onClose }: AddBankAccountVerifyingPanelProps) => {
  const { t } = useTranslation();

  const [countdown, setCountdown] = useState(BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL);
  const depositedAmount = useRef(
    formatFiat(CURRENCY_STYLE.INDIAN, DEFAULT_DEPOSIT_AMOUNT, LOCALES.INDIA, {
      minimumFractionDigits: 0,
    })
  );

  const waitUntilTimerRef = useRef<ReturnType<typeof setInterval>>();
  const redirectTimerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    waitUntilTimerRef.current = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        }

        if (redirectTimerRef.current) {
          clearInterval(waitUntilTimerRef.current);

          return prevCountdown;
        }

        redirectTimerRef.current = setTimeout(() => {
          onClose();
        }, ADD_BANK_CHECK_STATUS_INTERVAL * TIME_IN_MS.ONE_SECOND);

        return prevCountdown;
      });

      return () => {
        if (waitUntilTimerRef.current) clearInterval(waitUntilTimerRef.current);

        if (redirectTimerRef.current) clearTimeout(redirectTimerRef.current);
      };
    }, TIME_IN_MS.ONE_SECOND);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (<StatusPanel
      title={t('account:addBank.titles.bankAccountVerification')}
      onClose={onClose}
    >
      <Box
        flexPosition="flex-center-column"
        className={styles.addNewBankAccountAckPanelBody}
      >
        <Box as="p" fontSize="sm4" textColor="secondary">
          {t('account:addBank.helpers.weAreDepositingAmount', {
            depositingAmount: depositedAmount.current,
          })}
        </Box>

        <RupeeGoldCoin />

        <Box as="p" fontSize="sm4" textColor="secondary">
          <Render
            when={countdown > 0}
            else={
              <>
                {t('account:addBank.helpers.delayInProcessing')}
                <br data-palette="AddBankAccountVerifyingPanel" />
                {t('account:addBank.helpers.refetchingListIn', {
                  time: ADD_BANK_CHECK_STATUS_INTERVAL,
                })}
              </>
            }
          >
            {t('account:addBank.helpers.processingRequest', {
              countdown,
            })}
          </Render>
        </Box>
      </Box>
    </StatusPanel>)
  );
};

const AddBankAccountAckPanel = ({
  bankName = null,
  error = null,
  onClose,
  onResetAddBankAccountFlow,
  status,
}: AddBankAccountAckPanelProps) => {
  const { t } = useTranslation(['errors', 'account', 'headerFooter']);
  const history = useHistory();

  // trigger mixpanel event when an error is detected during penny drop verification
  useEffect(() => {
    if (status === ADD_BANK_CUSTODIAN_STATUS.FAILED) {
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.ADD_BANK_ACCOUNT.ADD_BANK_FAILED, {
        failure_reason: error,
      });
    }
    // trigger mixpanel event when there's a name mismatch error during bank account verification
    if (status === ADD_BANK_CUSTODIAN_STATUS.PENDING) {
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.ADD_BANK_ACCOUNT.ADD_BANK_NAME_MISMATCH, {
        failure_reason: error,
      });
    }
  }, [error, status]);

  const handleClick = e => {
    e.stopPropagation();
    history.push(routes.account.deposit);
  };

  switch (status) {
    case undefined:
    case ADD_BANK_CUSTODIAN_STATUS.INITIATED:
    case ADD_BANK_CUSTODIAN_STATUS.IN_PROGRESS:
    case ADD_BANK_CUSTODIAN_STATUS.HOLDING:
    case ADD_BANK_CUSTODIAN_STATUS.PENDING: {
      return <AddBankAccountVerifyingPanel onClose={onClose} />;
    }

    case ADD_BANK_CUSTODIAN_STATUS.FAILED: {
      return (
        <StatusPanel
          title={t('account:addBank.titles.bankAccountVerificationFailed')}
          onClose={onClose}
        >
          <Box
            flexPosition="flex-center-column"
            className={styles.addNewBankAccountAckPanelBody}
          >
            {/* @ts-ignore */}
            <PanelIcon type="error" icon={<InfoFilled color="var(--negative)" />} />

            <Box as="p" fontSize="sm4" textColor="primary">
              {/* @ts-ignore */}
              {t(`errors:custom.${error}`)}
            </Box>

            <DeltaButton
              data-testid=""
              variant="link-t3"
              onClick={onResetAddBankAccountFlow}
            >
              {t('account:addBank.titles.tryAnotherBank')}
            </DeltaButton>
          </Box>
        </StatusPanel>
      );
    }

    case ADD_BANK_CUSTODIAN_STATUS.COMPLETED: {
      return (
        <StatusPanel onClose={onClose}>
          <Box flexPosition="flex-center-column" gap={16} style={{ marginTop: '40px' }}>
            <PanelIcon
              type="success"
              // @ts-ignore
              icon={<TickRoundFilled width={46} height={46} color="var(--positive)" />}
            />

            <Box renderWhen={Boolean(bankName)} style={{ width: '50%' }}>
              <BankIcon bankName={bankName} size="large" />
            </Box>

            <Box as="p" fontSize="md1" textColor="primary">
              {t('account:addBank.helpers.accountAddedSuccessfully')}
            </Box>
            <Box as="p" fontSize="sm2" textColor="secondary">
              <DeltaButton
                data-testid="AddBankAccountSuccess-AddFundsButton"
                onClick={handleClick}
                variant="primary-t4"
              >
                {t('headerFooter:header.wallet.addFunds')}
              </DeltaButton>
            </Box>
          </Box>
        </StatusPanel>
      );
    }

    default: {
      devError(
        false,
        'BankDetails:AddBankAccountAckPanel',
        'Unknown add bank account acknowledgement status'
      );

      return null;
    }
  }
};

const AddNewBankAccountPanel = ({
  error,
  isLoading,
  onConfirm,
}: AddNewBankAccountPanelProps) => {
  const { t } = useTranslation(['account', 'errors']);
  const { isMobile } = useWindowSize();
  const { whenBelowMd } = useCustomMediaQuery();
  const appDispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<AddBankAccountData>({
    accountNumber: '',
    ifscCode: '',
    label: BANK_ACCOUNT_DEFAULT_LABEL,
  });
  const [formErrors, setFormErrors] = useState<ZodFormattedError<AddBankAccountData>>();

  const { data: bankDetailsList = [], isLoading: isBankDetailsLoading } = useApi<
    undefined,
    BankDetailsResponse[]
  >({
    requestOnMount: true,
    requestFn: () => {
      return appDispatch(getValidIFSCCodes());
    },
  });

  const onChangeByField = (field: AddBankAccountFields, value: string) => {
    if (formErrors && formErrors[field]) {
      const newFormErrors = omit([field], formErrors);
      setFormErrors(newFormErrors);
    }
    setFormValues(prev => ({ ...prev, [field]: value }));
  };

  // trigger mixpanel event to capture errors while adding bank account.
  useEffect(() => {
    if (error) {
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.ADD_BANK_ACCOUNT.ADD_BANK_FAILED, {
        failure_reason: error,
      });
    }
  }, [error]);

  const onSubmit = () => {
    const result = AddBankAccountSchema(t).safeParse(formValues);

    if (!result.success) {
      setFormErrors(result.error.format());

      return;
    }

    const bankData =
      bankDetailsList?.filter(
        bank =>
          bank.ifsc_code.toLowerCase() ===
          result.data.ifscCode.substring(0, 4).toLowerCase()
      ) || [];

    if (!bankData.length) {
      setFormErrors({
        ifscCode: {
          _errors: [STATIC_TEXTS.INVALID_IFSC],
        },
      } as ZodFormattedError<{ ifscCode: string }>);
      return;
    }

    if (!bankData[0]?.is_bank_addition_allowed) {
      setFormErrors({
        ifscCode: {
          _errors: [STATIC_TEXTS.UNSUPPORTED_BANK_ACCOUNT],
        },
      } as ZodFormattedError<{ ifscCode: string }>);
      return;
    }

    setFormErrors(undefined);
    onConfirm(result.data);

    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.ADD_BANK_ACCOUNT.ADD_ACCOUNT);
  };

  if (isBankDetailsLoading) return <CenterLoader />;

  return (
    <Box className={styles.addNewBankAccountContainer}>
      <Box
        data-testid="add-new-bank-account-form"
        as="form"
        flexDir="column"
        style={{ gap: '30px' }}
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          onSubmit();
        }}
      >
        <Box renderWhen={!isMobile} fontSize="md1">
          {t('account:addBank.titles.bankAccountDetails')}
        </Box>

        <input
          data-testid="form-submit-on-input-enter-hack"
          hidden
          type="submit"
          tabIndex={-1}
        />

        <DeltaInputWrapper
          id="account-number"
          label={t('account:accountNumber')}
          error={formErrors?.accountNumber?._errors?.[0]}
          control={
            <NumberInput
              data-testid="account-number-input"
              name="account-number"
              inputRef={null}
              type="text"
              value={formValues?.accountNumber ?? ''}
              step="any"
              autoComplete="off"
              numberType="natural"
              onChange={({ currentTarget }: KeyboardEvent<HTMLInputElement>) => {
                onChangeByField('accountNumber', currentTarget.value);
              }}
              className={cn({ inputAutofillFixSecondaryBg: !isMobile })}
            />
          }
        />

        {/* eslint-disable-next-line test-selectors/onChange */}
        <DeltaInputWrapper
          id="ifsc-code"
          controlRootClassName={styles.ifscCodeControl}
          label={t('account:ifscCode')}
          value={formValues?.ifscCode ?? ''}
          error={formErrors?.ifscCode?._errors?.[0]}
          onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
            onChangeByField('ifscCode', currentTarget.value);
          }}
        />

        {/* eslint-disable-next-line test-selectors/onChange */}
        {/* <DeltaInputWrapper
          id="account-label"
          label={
            <PopoverTooltip>
              <PopoverTooltip.Anchor>
                <Box
                  as="label"
                  htmlFor="account-label"
                  data-testid="account-label-input-label"
                  fontSize="sm2"
                  textColor="secondary"
                >
                  {t('account:label')}
                </Box>
              </PopoverTooltip.Anchor>

              <PopoverTooltip.Content>
                {t('account:addBank.helpers.addBankAccountLabelTooltip')}
              </PopoverTooltip.Content>
            </PopoverTooltip>
          }
          value={formValues?.label ?? ''}
          error={formErrors?.label?._errors?.[0]}
          onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
            onChangeByField('label', currentTarget.value);
          }}
        /> */}

        <Box flexDir="column" gap={8}>
          <Render when={Boolean(error)}>
            <DeltaHelperText
              isError
              id="add-bank-account-error"
              /* @ts-ignore */
              content={t(`errors:withdrawalBank.${error}`) as string}
            />
          </Render>

          <DeltaButton
            data-testid="add-new-bank-account-form-submit"
            variant="primary-t2"
            loading={isLoading}
            onClick={onSubmit}
          >
            {t('account:addBank.titles.addBankAccount')}
          </DeltaButton>
        </Box>
        <Box
          flexDir="column"
          renderWhen={whenBelowMd}
          className={styles.addNewAccHints}
          gap={8}
        >
          <InfoTextsList />
        </Box>
      </Box>
    </Box>
  );
};

const AddNewBankAccountOTPVerification = ({
  isLoading,
  errors,
  onClose,
  onResendOtp,
  onConfirm,
}: AddNewBankAccountOTPVerificationProps) => {
  const { t } = useTranslation();

  const mappedErrors = getMappedErrorsForIdentityVerification(t, errors);
  return (
    <DeltaAdaptivePanel
      open
      showHeader
      showFooter={false}
      fullHeight={false}
      onClose={onClose}
      title={t('account:securityVerification')}
      panelRootClassName={styles.addNewBankAccountSubPanel}
    >
      <DeltaOTPVerification
        variant="panel"
        isLoading={isLoading}
        errors={mappedErrors}
        onResendOtp={onResendOtp}
        onConfirm={codes => onConfirm(codes)}
      />
    </DeltaAdaptivePanel>
  );
};

const AddBankAccount = ({
  sendOtp,
  resendOtp,
  verifyOtp,
  onClose,
  addBankStatus,
}: AddBankAccountProps) => {
  const { isScreenMd } = useWindowSize();

  const onResetAddBankAccountFlow = () => {
    sendOtp.reset();
    resendOtp.reset();
    verifyOtp.reset();
  };

  const onAckPanelClose = () => {
    onResetAddBankAccountFlow();
    onClose({ shouldRefetchList: true });
  };

  const showEnterAccountDetailsScreen = isScreenMd || sendOtp.status !== STATUS.SUCCESS;
  const showOtpVerificationScreen =
    sendOtp.status === STATUS.SUCCESS && verifyOtp.status !== STATUS.SUCCESS;
  const showAddBankWaitingScreen = verifyOtp.status === STATUS.SUCCESS;

  const handleConfirm = (codes: Codes) => {
    verifyOtp
      .requestor({
        ...codes,
        uuid: sendOtp.data?.uuid ?? '',
      })
      .then(response => {
        // Track the event only when OTP verification is successful
        if (response[0] === null) {
          trackMixpanelEvent(
            MIXPANEL_EVENT_NAMES.ADD_BANK_ACCOUNT.ADD_BANK_EMAIL_VERIFICATION_COMPLETED
          );
        }
      });
  };

  return (
    <Box className={styles.rightPanelRoot}>
      <Render when={showEnterAccountDetailsScreen}>
        <AddNewBankAccountPanel
          isLoading={sendOtp.isLoading}
          error={sendOtp?.error?.code ?? null}
          onConfirm={addBankAccountData => {
            return sendOtp.requestor(addBankAccountData);
          }}
        />
      </Render>

      <Render when={showOtpVerificationScreen}>
        <AddNewBankAccountOTPVerification
          isLoading={verifyOtp.isLoading}
          onClose={sendOtp.reset}
          errors={verifyOtp.error ?? resendOtp.error?.error}
          onResendOtp={() => {
            resendOtp.requestor(sendOtp.data?.uuid ?? '');
          }}
          onConfirm={handleConfirm}
        />
      </Render>

      <Render when={showAddBankWaitingScreen}>
        <AddBankAccountAckPanel
          onClose={onAckPanelClose}
          onResetAddBankAccountFlow={onResetAddBankAccountFlow}
          status={addBankStatus?.status}
          bankName={addBankStatus?.bank_name}
          error={addBankStatus?.error_code}
        />
      </Render>
    </Box>
  );
};

const AccountDetail = ({
  isDeleting = false,
  detail,
  onClose,
  onDeleteAccount,
}: AccountDetailProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const appDispatch = useAppDispatch();
  const [shouldConfirmDelete, toggleShouldConfirmDelete] = useToggle(false);

  if (!detail) return null;

  const onConfirmDelete = () => {
    onDeleteAccount(detail.user_bank_detail_id);
    trackMixpanelEvent(
      MIXPANEL_EVENT_NAMES.DELETE_BANK_ACCOUNT.DELETE_BANK_ACCOUNT_CONFIRMATION
    );
  };

  const handleDeleteModal = ({ result }: WithdrawalPendingAPIResponse) => {
    if (result?.length <= 0) {
      toggleShouldConfirmDelete();
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.DELETE_BANK_ACCOUNT.DELETE_BANK_ACCOUNT);
    }
  };

  const handleDeleteButtonClick = () => {
    appDispatch(getWithdrawalsPendingCount()).then(handleDeleteModal);
  };

  return (<>
    <DeltaAdaptivePanel
      variant="legacy"
      open
      showHeader
      showFooter={false}
      fullHeight={false}
      onClose={() => onClose()}
      headerClassName={styles.accountDetailHeader}
      title={<BankIcon bankName={detail.bank_name} size="large" />}
      titleClassName={styles.accountDetailTitle}
      accessoryTitleRight={isMobile ? null : <span data-palette="AccountDetail" />}
      panelRootClassName={styles.rightPanelRoot}
    >
      <Box flexPosition="flex-column" className={styles.accountDetailRoot}>
        <Box flexPosition="flex-row-spread" gap={8}>
          <Box as="h5" fontSize="sm4" textColor="secondary">
            {t('account:accountNumber')}
          </Box>
          <Box as="span" fontSize="sm4" textColor="primary">
            {detail.account_number.slice(-8)}
          </Box>
        </Box>
        <Box flexPosition="flex-row-spread" gap={8}>
          <Box as="h5" fontSize="sm4" textColor="secondary">
            {t('account:ifscCode')}
          </Box>
          <Box as="span" fontSize="sm4" textColor="primary">
            {detail?.ifsc_code}
          </Box>
        </Box>
        {/* <Box flexPosition="flex-row-spread" gap={8}>
          <Box as="h5" fontSize="sm4" textColor="secondary">
            {t('account:label')}
          </Box>
          <Box as="span" fontSize="sm4" className={styles.accountLabel}>
            {detail.customized_bank_name}
          </Box>
        </Box> */}

        <DeltaButton
          data-testid=""
          variant="special-secondary-t2"
          className={styles.triggerDeleteConfirm}
          onClick={handleDeleteButtonClick}
        >
          {t('account:addBank.titles.deleteAccount')}
        </DeltaButton>
      </Box>
    </DeltaAdaptivePanel>
    <MaterialConfirmDialog
      variant="legacy"
      title=""
      fullWidth={isMobile}
      showFooter={false}
      open={shouldConfirmDelete}
      onClose={toggleShouldConfirmDelete}
    >
      <Box
        flexPosition="flex-center-column"
        className={styles.accoundDeleteConfirmation}
      >
        <PanelIcon
          type="error"
          // @ts-ignore
          icon={<DeleteFilled width={46} height={46} color="var(--negative)" />}
        />

        <Box as="p" fontSize={isMobile ? 'sm2' : 'sm4'} textColor="primary">
          {t('account:addBank.helpers.confirmToDeleteBankAccount')}
        </Box>

        <DeltaButton
          data-testid=""
          loading={isDeleting}
          variant="primary-t1"
          className={styles.confirmDelete}
          onClick={onConfirmDelete}
        >
          {t('account:confirm')}
        </DeltaButton>
      </Box>
    </MaterialConfirmDialog>
  </>);
};

const BankDetailsLoader = () => {
  useBankDetails();
  return null;
};

export {
  NoBankAccountsPlaceholder,
  AddBankAccount,
  BankIcon,
  AccountDetail,
  BankDetailsLoader,
};
