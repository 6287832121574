import React, { useEffect, useRef } from 'react';

import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import routes from 'constants/routes';
import { isTestNet } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import VerificationInProgressIcon from 'styles/assets/icons/VerificationInProgressIcon';
import { SetTimeOut } from 'types/utils';
import { Box } from 'UIKit';

import styles from './emailVerificationWithLink.module.scss';

type VerificationInProgressProps = {
  email: string;
};

const VerificationInProgress = ({ email }: VerificationInProgressProps) => {
  const history = useHistory();
  const { t } = useTranslation(['auth']);
  const timerRef = useRef<SetTimeOut>();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      history.push({
        pathname: routes.webAppHome,
        state: {
          showRegisterSuccessModal: !isMobileOnly && !isTestNet(), // RegisterSuccessModal not shown if TestNet.
        },
      });
      trackMixpanelEvent('Email Verification Completed', {
        email_verification_time: new Date().toString(),
        page_name: 'Verify Link',
        email,
      });
    }, 5000);

    return () => clearTimeout(timerRef.current);
  }, [history, email]);

  return (
    <Box className={styles.verificationInProgress}>
      <VerificationInProgressIcon />
      <Box className={styles.heading}>{t('auth:verificationInProgress')}</Box>
      <Box className={styles.subHeading}>{t('auth:redirectToMarketsPage')}</Box>
    </Box>
  );
};

export default VerificationInProgress;
