// import { withStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
import React, { FunctionComponent, ReactText } from 'react';

import { useDispatch } from 'react-redux';

import { CrossIconSymbol } from 'styles/assets/icons';

import styles from './index.module.scss';

interface IHighlighterProps {
  dataId: string; // highlight popup unique Id
  dataVersion: number; // reflect new title data added to this highlight popup
  title: ReactText; // content of popup
  // testNet?: boolean;
  // prod?: boolean;
  // show?: boolean;
  isOpen: boolean;
  className?: string;
  toggleHighLigherAction?: (flag: boolean) => void;
}

// const StyledTooltip = withStyles(theme => ({
//   popper: {
//     opacity: 1,
//   },
//   tooltip: {
//     padding: '5px 5px',
//     backgroundColor: '#009aca',
//     fontSize: '11px',
//     fontFamily: 'Muli SemiBold',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     marginTop: '5px',
//     marginRight: '10px',
//   },
// }))(Tooltip);

export const Highlighter: FunctionComponent<IHighlighterProps> = ({
  dataId,
  dataVersion,
  title,
  isOpen,
  toggleHighLigherAction,
  className,
  // children,
}) => {
  // const localStorageValue = localStorage.getItem(`${dataId}${dataVersion}`);

  // if (localStorage.getItem(`${dataId}${dataVersion - 1}`)) {
  //   localStorage.removeItem(`${dataId}${dataVersion - 1}`);
  // }

  // const [openState, setOpenState] = useState(isOpen);

  const dispatch = useDispatch();

  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(toggleHighLigherAction?.(false));
    localStorage.setItem(`${dataId}${dataVersion}`, 'false');
  };

  return isOpen ? (
    // <StyledTooltip title={content} open={true} placement={`bottom-start`}>
    //   <>{children}</>
    // </StyledTooltip>
    (<div
      className={`${styles[`placard-highlighter`]} ${isOpen ? styles.show : ''} ${
        className || ''
      }`}
      data-palette="Highlighter">
      <span className={styles.title}>{title}</span>
      <CrossIconSymbol
        data-testid="close-placard"
        onClick={onClose}
        color="var(--white)"
        style={{ cursor: 'pointer' }}
      />
    </div>)
  ) : null;
};
