import { useState } from 'react';

import {
  getBankAccounts,
  sendOtpToAddBankAccount as sendOtpToAddBankAccountAction,
  resendOtpToAddBankAccount as resendOtpToAddBankAccountAction,
  verifyOtpToAddBankAccount as verifyOtpToAddBankAccountAction,
  deleteBankAccount as deleteBankAccountAction,
  checkStatusOfAddedBankAccount,
} from 'actions/account';
import { bankAccountAdded } from 'actions/user';
import { TIME_IN_MS } from 'constants/dateTime';
import { runCallbackWithInterval } from 'helpers/scheduler';
import useApi from 'hooks/useApi';
import { useAppDispatch } from 'storeHooks';
import { ADD_BANK_CUSTODIAN_STATUS, BankDetailsResponseCodes } from 'types/Account';
import { ErrorWithCode } from 'types/utils';

import {
  ADD_BANK_CHECK_STATUS_INTERVAL,
  BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL,
  type AddBankCheckStatusResponse,
  type GetBankList,
  type BankAccount,
  type RequestId,
  type SendOTPError,
  type SendOTPResponse,
  type AddBankAccountSubmissibleData as SendOtpParams,
  type UserBankDetailId,
  type VerifyOtpParams,
  type VerifyOtpResponse,
  type VerifyOtpUuid,
} from './useBankDetails.types';

function useBankDetails() {
  const appDispatch = useAppDispatch();
  const [addBankStatus, setAddBankStatus] = useState<null | AddBankCheckStatusResponse>(
    null
  );

  const bankList = useApi<undefined, BankAccount[], any, GetBankList>({
    requestOnMount: true,
    requestFn: () => appDispatch(getBankAccounts()),
    onMutateData: response => {
      const allBankDetailsList = response?.bank_details ?? [];

      const bankAccountList = allBankDetailsList.filter(
        account => account.custodian_status !== ADD_BANK_CUSTODIAN_STATUS.FAILED
      );

      const bankAccountsSuccessfullyAdded = allBankDetailsList.filter(
        account => account.custodian_status === ADD_BANK_CUSTODIAN_STATUS.COMPLETED
      );

      appDispatch(
        bankAccountAdded({
          isBankAccountAdded: Boolean(bankAccountsSuccessfullyAdded?.length),
        })
      );
      return bankAccountList;
    },
  });

  const sendOtpToAddBankAccount = useApi<SendOtpParams, SendOTPResponse, SendOTPError>({
    requestFn: sendOtpParams => {
      return appDispatch(sendOtpToAddBankAccountAction(sendOtpParams));
    },
  });

  const resendOtpToAddBankAccount = useApi<VerifyOtpUuid>({
    requestFn: verifyOtpUuid => {
      return appDispatch(resendOtpToAddBankAccountAction(verifyOtpUuid));
    },
  });

  const addBankAccountCheckStatus = useApi<RequestId, AddBankCheckStatusResponse>({
    requestFn: requestId => {
      return appDispatch(checkStatusOfAddedBankAccount(requestId));
    },
    onSuccess: response => {
      setAddBankStatus(response);

      if (response?.status === ADD_BANK_CUSTODIAN_STATUS.COMPLETED) {
        appDispatch(bankAccountAdded({ isBankAccountAdded: true }));
        setTimeout(() => {
          setAddBankStatus(response);
        }, 3 * TIME_IN_MS.ONE_SECOND);
      }
    },
    onSettled: () => addBankAccountCheckStatus.reset(),
  });

  const verifyOtpToAddBankAccount = useApi<
    VerifyOtpParams,
    VerifyOtpResponse,
    ErrorWithCode
  >({
    requestFn: verifyOtpParams => {
      return appDispatch(verifyOtpToAddBankAccountAction(verifyOtpParams));
    },
    onSuccess: verifyOtpResponse => {
      if (verifyOtpResponse.request_id) {
        runCallbackWithInterval<
          ReturnType<(typeof addBankAccountCheckStatus)['requestor']>
        >(
          () => addBankAccountCheckStatus.requestor(verifyOtpResponse.request_id),
          ADD_BANK_CHECK_STATUS_INTERVAL,
          BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL,
          true,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, response]) => {
            return (
              response?.status === ADD_BANK_CUSTODIAN_STATUS.COMPLETED ||
              response?.status === ADD_BANK_CUSTODIAN_STATUS.FAILED
            );
          }
        );
      }
    },
  });

  const deleteBankAccount = useApi<UserBankDetailId, BankDetailsResponseCodes>({
    requestFn: accountId => {
      return appDispatch(deleteBankAccountAction(accountId));
    },
    onSuccess: () => {
      return bankList.requestor();
    },
  });

  return {
    bankList,
    sendOtpToAddBankAccount,
    resendOtpToAddBankAccount,
    verifyOtpToAddBankAccount,
    deleteBankAccount,
    addBankStatus,
  };
}

export default useBankDetails;

type UseBankDetails = ReturnType<typeof useBankDetails>;

export type { UseBankDetails };
