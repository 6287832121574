import React, { SVGProps } from 'react';

export const EmptyBasketIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    (<svg
      width={145}
      height={145}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-palette="EmptyBasketIcon">
      <path
        d="M119.365 56.666s-16.47 11.856-1.751 42.079c13.072 26.841-22.883 34.449-37.633 30.053-19.108-5.694-27.91-31.394-43.559-25.035-15.65 6.359-26.984-30.415-4.06-41.326C60.878 48.866 44.492 37.79 48.06 26.161c2.56-8.346 28.558-19.118 40.435-2.812 9.995 13.72 15.933 9.733 21.223 8.542 7.63-1.717 22.546 12.5 9.647 24.776Z"
        fill="var(--basketEmptyIconFill1)"
      />
      <path
        d="m119.562 67.253-.113.702a2.234 2.234 0 0 1-2.56 1.852l-.051-.008a2.235 2.235 0 0 0-2.411 1.331l-15.843 37.261a5.234 5.234 0 0 1-5.648 3.124l-44.184-7.099a5.217 5.217 0 0 1-4.395-4.881l-2.194-39.953a2.234 2.234 0 0 0-1.877-2.083 2.235 2.235 0 0 1-1.851-2.56l.113-.702a2.235 2.235 0 0 1 2.56-1.852l18.717 3.007a1.511 1.511 0 0 0 1.501-.661L74.398 34.84a1.638 1.638 0 0 1 2.264-.476 1.642 1.642 0 0 1 .474 2.274L65.614 54.166a1.51 1.51 0 0 0 1.022 2.32l24.91 4.003a1.511 1.511 0 0 0 1.698-1.884l-5.46-20.256a1.651 1.651 0 0 1 1.162-2.011 1.637 1.637 0 0 1 2 1.16l6.183 22.985a1.51 1.51 0 0 0 1.219 1.099l19.363 3.11a2.241 2.241 0 0 1 1.456.898 2.235 2.235 0 0 1 .395 1.663Z"
        fill="var(--basketEmptyIconFill2)"
      />
      <path
        d="m60.914 61.78-4.879-.784a.073.073 0 0 0-.083.06l-5.815 36.192c-.006.04.02.077.06.083l4.88.784c.039.007.076-.02.082-.06l5.816-36.192a.072.072 0 0 0-.06-.083ZM101.305 68.154l-4.88-.784a.073.073 0 0 0-.082.06l-5.815 36.193a.073.073 0 0 0 .06.083l4.879.784a.073.073 0 0 0 .083-.06l5.815-36.193a.072.072 0 0 0-.06-.083ZM70.962 63.405l-4.877-.785a.072.072 0 0 0-.083.06l-5.829 36.18c-.006.039.02.076.06.082l4.878.786c.04.007.077-.02.083-.06l5.828-36.18a.073.073 0 0 0-.06-.083ZM81.119 64.91l-4.88-.783a.072.072 0 0 0-.083.06l-5.815 36.192c-.006.04.02.077.06.083l4.88.784c.04.006.076-.02.083-.06l5.815-36.192a.072.072 0 0 0-.06-.083ZM91.212 66.533l-4.88-.784a.073.073 0 0 0-.082.06L80.434 102a.073.073 0 0 0 .06.083l4.88.784a.073.073 0 0 0 .083-.06l5.815-36.192a.072.072 0 0 0-.06-.083Z"
        fill="var(--basketEmptyIconFill3)"
      />
    </svg>)
  );
};

export const SquareAddIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      {...props}
      data-palette="SquareAddIcon">
      <rect width="21" height="21" fill="var(--primaryTheme)" rx="2" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.187 10.547h-9.28M10.547 5.906v9.281"
      />
    </svg>)
  );
};

export const TrashIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    (<svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-palette="TrashIcon">
      <path
        d="M1.75 3.5h10.5M4.667 3.5V2.333a1.167 1.167 0 0 1 1.167-1.166h2.333a1.167 1.167 0 0 1 1.167 1.166V3.5m1.75 0v8.167a1.167 1.167 0 0 1-1.167 1.166H4.084a1.167 1.167 0 0 1-1.167-1.166V3.5h8.167ZM5.833 6.417v3.5M8.167 6.417v3.5"
        stroke="var(--negative)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>)
  );
};

export const ReloadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      fill="none"
      viewBox="0 0 9 9"
      {...props}
      data-palette="ReloadIcon">
      <path
        fill="var(--primaryTheme)"
        fillRule="evenodd"
        d="M6.883 1.278a3.903 3.903 0 00-4.678.659l-.92.873v-.835a.5.5 0 10-1 0v2.079a.5.5 0 00.5.5h2.08a.5.5 0 100-1h-.912l.946-.897.01-.01a2.903 2.903 0 013.486-.496c.556.31.998.795 1.259 1.383.26.587.325 1.245.183 1.873a2.972 2.972 0 01-.966 1.606 2.906 2.906 0 01-3.514.205.5.5 0 00-.559.83 3.906 3.906 0 004.722-.274 3.972 3.972 0 001.293-2.147 3.998 3.998 0 00-.245-2.499 3.953 3.953 0 00-1.685-1.85z"
        clipRule="evenodd"
      />
    </svg>)
  );
};
