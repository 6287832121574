/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable test-selectors/onChange */
import React, { useCallback } from 'react';

import styled from 'styled-components';

import { NUMBER_TYPES } from 'constants/constants';

const StyledInput = styled.input`
  width: 100%;
  height: 32px;
  background-color: ${props => props.theme.inputBackground};
  font-size: 12.1px;
  line-height: 15px;
  border: none;
  padding: 5px;
  text-align: right;

  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }

  &.error {
    border: 1px solid ${props => props.theme.negative};
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  } /* FF 4-18 */
  &:focus::-moz-placeholder {
    color: transparent;
  } /* FF 19+ */
  &:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

StyledInput.displayName = 'StyledNumberInput';

const NumberInput = ({
  value,
  onChange,
  numberType,
  placeholder = '',
  inputMode = 'decimal',
  negativeValueAllowed = false,
  inputRef,
  addLeadingZeroForDecimal = false,
  'data-testid': testId = 'number-input',
  ...restProps
}) => {
  const handleChange = useCallback(
    // eslint-disable-next-line consistent-return
    event => {
      const caret = event.target.selectionStart;
      const element = event.target;

      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      // when user entering decimal add zero before decimal
      if (addLeadingZeroForDecimal) {
        if (
          event.currentTarget.value.charAt(0) === '.' &&
          event.currentTarget.value.length === 1
        ) {
          event.currentTarget.value = `0${event.currentTarget.value}`;
          // Find the position of the decimal point to update the cursor
          const decimalIndex = event.currentTarget.value.indexOf('.');
          if (decimalIndex !== -1) {
            const newPosition = decimalIndex + 1;
            window.requestAnimationFrame(() => {
              element.setSelectionRange(newPosition, newPosition);
            });
          }
        }
      }

      if (event.currentTarget.value.includes(' ')) {
        // remove spaces even when value with space is copy-pasted
        // eslint-disable-next-line no-param-reassign
        event.currentTarget.value = event.currentTarget.value.replace(/\s/g, '');
      }

      const val = event.target.value;

      if (val.length) {
        if (numberType === NUMBER_TYPES.decimal) {
          if (Number.isNaN(Number(val))) {
            // if the string is not a valid number
            if (
              val[val.length - 1] !== ',' &&
              val[val.length - 1] !== '-' &&
              val[val.length - 1] !== '.'
            ) {
              // if the last character is not ',' '.' '-'
              return false;
            }

            if (val[val.length - 1] === '.') {
              if (!negativeValueAllowed) {
                if (val.length > 1) {
                  // only allowed case is when val is '.'
                  return false;
                }
              } else if (val.length > 1 && val !== '-.') {
                // only allowed case is when val is '-.'
                return false;
              }
            }

            if (val[val.length - 1] === ',' && val.indexOf('.') > -1) {
              // if the last character is ',' but string already contains '.'
              return false;
            }

            if (val[val.length - 1] === '-') {
              if (negativeValueAllowed) {
                if (val.length > 1) {
                  // '-' should be the first character if negative numbers are alllowed
                  return false;
                }
              } else {
                return false;
              }
            }
          }

          if (!negativeValueAllowed && val < 0) {
            return false;
          }
        } else if (numberType === NUMBER_TYPES.natural) {
          const regexPatt = /^[0-9]+$/;

          if (!regexPatt.test(val)) {
            return false;
          }
        }
      }

      onChange(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberType, onChange]
  );

  return (
    <StyledInput
      data-testid={testId}
      type="text"
      value={value}
      inputMode={inputMode}
      onChange={handleChange}
      ref={inputRef}
      placeholder={placeholder || ''}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

export default NumberInput;
