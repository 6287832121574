/* eslint-disable react/prop-types */
import { memo } from 'react';

import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import Volume24Hr from 'components/markets/24hr_volume';
import { LastPriceChangeValue } from 'components/markets/helpers';
import tableStyles from 'components/markets/table.module.scss';

import styles from './styles.module.scss';

export const LastPriceChange = memo(() => {
  const { data, showCallOptions, showPutOptions, cellClassName, boxClassName } =
    useOptionsChainData();

  return (<>
    {data.map(props => {
      const isCallOptionEmpty = !props?.callOptions;
      const isPutOptionEmpty = !props?.putOptions;
      const { backgroundClass } = props;

      return (
        (<div
          className={`${styles.optionDetailBox} ${cellClassName}`}
          key={props?.strike_price}
          data-palette="LastPriceChange">
          {showCallOptions && (
            <div
              className={`${boxClassName} ${backgroundClass}`}
              data-palette="LastPriceChange">
              {!isCallOptionEmpty ? (
                <LastPriceChangeValue
                  styles={tableStyles}
                  symbol={props?.callOptions?.symbol}
                  underlying_asset={props?.callOptions?.underlying_asset}
                  product={props?.callOptions}
                  extraClass={styles.changeBox}
                />
              ) : (
                '-'
              )}
            </div>
          )}
          {showPutOptions && (
            <div
              className={`${boxClassName} ${backgroundClass}`}
              data-palette="LastPriceChange">
              {!isPutOptionEmpty ? (
                <LastPriceChangeValue
                  styles={tableStyles}
                  symbol={props?.putOptions?.symbol}
                  underlying_asset={props?.putOptions?.underlying_asset}
                  product={props?.putOptions}
                  extraClass={styles.changeBox}
                />
              ) : (
                '-'
              )}
            </div>
          )}
        </div>)
      );
    })}
  </>);
});

export const Volume = memo(() => {
  const { data, showCallOptions, showPutOptions, cellClassName, boxClassName } =
    useOptionsChainData();
  return (<>
    {data.map(props => {
      const isCallOptionEmpty = !props.callOptions;
      const isPutOptionEmpty = !props.putOptions;
      const { backgroundClass } = props;

      return (
        (<div
          className={`${styles.optionDetailBox} ${cellClassName}`}
          key={props.strike_price}
          data-palette="Volume">
          {showCallOptions && (
            <div className={`${boxClassName} ${backgroundClass}`} data-palette="Volume">
              {!isCallOptionEmpty ? (
                <Volume24Hr
                  symbol={props?.callOptions?.symbol}
                  underlying_asset={props?.callOptions?.underlying_asset}
                  product={props?.callOptions}
                  showUSDSymbol
                />
              ) : (
                '-'
              )}
            </div>
          )}
          {showPutOptions && (
            <div className={`${boxClassName} ${backgroundClass}`} data-palette="Volume">
              {!isPutOptionEmpty ? (
                <Volume24Hr
                  symbol={props?.putOptions?.symbol}
                  underlying_asset={props.putOptions?.underlying_asset}
                  product={props?.putOptions}
                  showUSDSymbol
                />
              ) : (
                '-'
              )}
            </div>
          )}
        </div>)
      );
    })}
  </>);
});
