/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { isNan, numberPrettified } from 'helpers';
import { getThetaValue } from 'helpers/greeksCalculatorUSDT';
import { thetaPriceSelectorById } from 'selectors';
import { type OpenPosition } from 'types/IOpenPosition';

import useOpenPositionHelpers from '../useOpenPositionHelpers';

function usePositionsThetaSummation(positions: OpenPosition[]) {
  const { getNotionalValue } = useOpenPositionHelpers();

  const getCombinedThetaValues = positions.reduce((result, position) => {
    const isLong = (position?.size ?? 0) > 0;

    const { value: notionalSize, symbol } = getNotionalValue({
      entry_price: position.entry_price,
      size: position.size,
      product: position.product,
    });

    const price = thetaPriceSelectorById(String(position?.product_id));
    const thetaContractValue = isNan(Number(price)) ? 0.0 : Number(price);
    const thetaInUSDT = getThetaValue({
      thetaContractValue,
      symbol,
      notional: Number(notionalSize),
      longOrShort: isLong ? 'long' : 'short',
    });

    result += Number(thetaInUSDT || 0);

    return result;
  }, 0);

  return isNan(getCombinedThetaValues)
    ? '-'
    : Number(getCombinedThetaValues) === 0
    ? '0.00'
    : numberPrettified(getCombinedThetaValues);
}

export default usePositionsThetaSummation;
