import React from 'react';

import { compose } from 'recompose';

import { ConditionallyRenderPopoverBase } from 'components/popover/popover_helpers';

const Tab = ({
  id,
  label,
  popoverOptions,
  activeTab,
  onClick,
  toggleIconById,
  getIconMapValueById,
  isDisabled,
  testId,
  tabRef,
}) => {
  const active = activeTab?.toString?.() === id;
  const showIcon = getIconMapValueById(id);

  const set = val => () => {
    toggleIconById(id, val);
  };

  return (
    (<div
      className={`tab  ${id}-tab ${active ? 'active' : ''} ${
        isDisabled ? 'disabled-tabs' : ''
      }`}
      data-testid={testId || `${id}-tab`}
      onClick={() => onClick({ id, label, tabRef })}
      onMouseEnter={set(true)}
      onMouseLeave={set(false)}
      onKeyDown={null}
      role="button"
      tabIndex={-1}
      ref={tabRef}
      data-palette="Tab">
      {label}
      <ConditionallyRenderPopoverBase
        isActive={active}
        showIcon={showIcon}
        popoverOptions={popoverOptions}
      />
    </div>)
  );
};

const enhance = compose(React.memo);

export default enhance(Tab);
