/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/named */

import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import {
  error as errorNotifications,
  success as successNotifications,
} from 'react-notification-system-redux';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import ACCOUNT_ACTION_TYPES, {
  KycImportActionType,
  SignalTradingActionType,
} from 'actionTypes/account';
import { SAVE_CHART } from 'actionTypes/other';
import {
  ASSET_SYMBOL,
  ERROR_CODES,
  MARGIN_TYPES,
  VANILLA_SETTLING_ASSET,
} from 'constants/constants';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import {
  bracketOrderFailureReason,
  changeLeverageFailureReason,
  changeMarginFailureReason,
  // batchOrdersCancellationReason,
  orderCancellationReason,
} from 'errorMessages/orders';
import { userProfileFailureReason, userSignupFailureReason } from 'errorMessages/user';
import { getWalletErrorMessage } from 'errorMessages/wallets';
import {
  getMobileOperatingSystem,
  isFutures,
  isOptions,
  isUsdStableCoin,
  parseErrorMsg,
} from 'helpers';
import { getOrderValue, round } from 'helpers/formulas';
import * as InAppReview from 'helpers/inAppReview';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import MoengageLib from 'helpers/moengage';
import { find, lensPath, view } from 'helpers/ramda';
import i18n from 'i18n/config';
import { ContractType, NotionalType } from 'types/IProducts';
import store from 'variableStore/store';

import BASKET_ORDERS from '../actionTypes/basketorders';
import ORDER_BOOK, { ORDER_BOOK_STALE } from '../actionTypes/orderbook';
import TRADE from '../actionTypes/trade';
import USER, { RATE_LIMIT_EXCEEDED, USER_SIGNUP_FAIL } from '../actionTypes/user';
import WALLET from '../actionTypes/wallet';
import {
  capitalize,
  cropAfterDecimals,
  genericErrorLens,
  getProductSymbol,
  // getErrorMessage,
  getStopOrderType,
  isKycRefreshExpired,
  isKycRequiredDeadlineCrossed,
} from '../helpers/utils';
import {
  assetsListSelector,
  convertToUSDT,
  getProductByID,
  selectAppReviewFromTrackingInfo,
  selectedProductSelector,
  spotPriceSelectorBySymbol,
} from '../selectors';
import {
  updateAllTickers,
  // updatePriceIndexWithProducts,
  updateTicker,
} from '../variableStore/actions';
import {
  checkboxesSelected,
  getLeverageValue,
  getOrderFormat,
  getOrderType,
  isBracketApplied,
} from './helper';
// https://ramdajs.com/docs/#lensPath

export const errorLens = lensPath(['error', 'response', 'body', 'error']);

const bodyLens = data => view(lensPath(['error', 'response', 'body']), data);

const DEFAULT_ERROR_MESSAGE = i18n.t('errors:defaultMessage');
// const errorParamsLens = R.lensPath([
//   'error',
//   'response',
//   'body',
//   'errors',
//   '0',
//   'param',
// ]);

// const errorMsgLens = R.lensPath([
//   'error',
//   'response',
//   'body',
//   'errors',
//   '0',
//   'msg',
// ]);

export const getProductByIDHelper = (state, product_id) =>
  getProductByID(state, {
    filterProductId: product_id,
  });

const formatPlaceOrderSuccessMessage = (order, product) => {
  const { limit_price, order_type, size, side, quote_size } = order;
  const {
    symbol,
    contract_type,
    underlying_asset: { symbol: underlyingSymbol },
    quoting_asset: { symbol: quotingSymbol },
  } = product || {};

  const limitSymbol = order_type === 'limit_order' ? quotingSymbol : '';
  const orderType = order_type === 'limit_order' ? limit_price : 'market';

  switch (contract_type) {
    case 'spot': {
      return `${capitalize(side)} ${
        size ||
        `${quote_size} ${quotingSymbol} ${i18n.t('common:worth')} ${i18n.t('common:of')}`
      } ${underlyingSymbol} ${i18n.t('common:at')} ${orderType} ${limitSymbol}.`;
    }
    default: {
      return `${capitalize(side)} ${size} ${i18n.t(
        'alerts:contractsOf'
      )} ${symbol} ${i18n.t('common:at')} ${orderType} ${limitSymbol}.`;
    }
  }
};

const orderLoadingPhase =
  ({ payload: order }) =>
  (dispatch, getState) => {
    const product = getProductByIDHelper(getState(), order?.product_id);
    const message = formatPlaceOrderSuccessMessage(order, product);

    dispatch(
      successNotifications({
        title: i18n.t('alerts:orderSubmitted'),
        message,
        position: 'bl',
      })
    );
  };

const LocalizedAlert = ({ localeKey }) => {
  const { t } = useTranslation(['alerts']);

  return t(`alerts:${localeKey}`);
};

// const LocalizedError = ({ rootKey, localeKey, data }) => {
//   const { t } = useTranslation(['errors']);
//   // We need to handle this as we are having 2 different error codes for the same message, hence appending a string to identify
//   if (localeKey === 'cant_switch_with_open_orders_or_positions') {
//     localeKey += '_preferences';
//   }
//   return t(`errors:${rootKey}.${localeKey}`);
// };

const PortfolioMarginLocalizedErrors = ({ localeKey, data }) => {
  const { t } = useTranslation(['errors']);
  let newLocaleKey = localeKey;
  // We need to handle this as we are having 2 different error codes for the same message, hence appending a string to identify
  if (newLocaleKey === 'cant_switch_with_open_orders_or_positions') {
    newLocaleKey += '_preferences';
  }

  if (newLocaleKey === 'insufficient_balance') {
    const error = view(errorLens, data);
    return t(`errors:portfolio_margin.${newLocaleKey}`, {
      portfolio_enable_balance_threshold:
        error?.context?.portfolio_enable_balance_threshold,
      assetSymbol: ASSET_SYMBOL,
    });
  }
  if (newLocaleKey === 'account_limit_reached') {
    if (data.marginMode === MARGIN_TYPES.PORTFOLIO) {
      newLocaleKey += '_pf';
    }
    return t(`errors:portfolio_margin.${newLocaleKey}`, {
      limit: data.limit,
      pf_limit: data.limit,
      marginMode: data.marginMode,
      pfCoin: data.assetSymbol,
    });
  }
  return t(`errors:portfolio_margin.${newLocaleKey}`, { assetSymbol: ASSET_SYMBOL });
};

const positionType = {
  OPEN: 'open',
  NEW: 'new',
};
// fix eslint no-shadow
const bracketCancelMixpanel = (posType, data, state) => {
  if (data?.result?.result) {
    const order = data.result.result;
    const { product_symbol, side, product_id } = order;
    const product = getProductByIDHelper(state, product_id);
    const source = data.mixpanelPayload?.chart ? 'chart' : 'table';
    trackMixpanelEvent('Bracket Order Cancelled - Trade - Table', {
      product_name: product_symbol,
      product_type: product.contract_type,
      transaction_type: side,
      position_type: posType,
      source,
    });
  }
};

// const clearTimer = timer => {
//   if (timer) {
//     clearTimeout(timer);
//   }
// };

// const loginApiLoadingLogs = ({ payload }) => () => {
//   loginTimer = setTimeout(() => {
//     return addLogsInSentryAndMixpanel(
//       { email: payload.email },
//       'Login API Failure'
//     );
//   }, 10000);
// };

const alerts = {
  [TRADE.ORDER.LOAD]: orderLoadingPhase,
  [TRADE.CLOSE_POSITION.LOAD]: orderLoadingPhase,

  [TRADE.ORDER_CANCEL.SUCCESS]: (data, state) => {
    if (data?.result?.result) {
      const order = data.result.result;
      const { bracket_order, product_symbol, side, product_id, order_type } = order;
      const product = getProductByIDHelper(state, product_id);
      const orderType = capitalize(getOrderFormat(bracket_order, order_type));
      const source = data.mixpanelPayload?.chart ? 'chart' : 'table';

      trackMixpanelEvent(`${orderType} Cancelled - Trade - Table`, {
        source,
        product_name: product_symbol,
        product_type: product.contract_type,
        transaction_type: side,
      });
    }
    return successNotifications({
      title: <LocalizedAlert localeKey="orderCancelled" />,
      message: <LocalizedAlert localeKey="orderCancelledMsg" />,
      position: 'bl',
    });
  },

  [TRADE.CLOSE_POSITION.SUCCESS]: (data, state) => dispatch => {
    try {
      if (data?.result?.result && data?.mixpanelPayload) {
        const appReview = selectAppReviewFromTrackingInfo(state) || {};
        const { order_type, size, unfilled_size, average_fill_price, side, meta_data } =
          data.result.result;
        const { pnl: realizedPnl } = meta_data;
        const { totalPositions, chart, precision, orderProps } = data.mixpanelPayload;
        const share = `${Math.round(Math.abs(size / totalPositions) * 100)}%`;
        const isChart = chart ? 'chart' : 'table';

        const { contract_type, contract_value, notional_type, settlingSymbol } =
          orderProps;

        const orderValue = getOrderValue(
          notional_type,
          contract_value,
          average_fill_price,
          size - unfilled_size,
          contract_type
        );
        const orderValueInUsdt = orderValue * convertToUSDT(settlingSymbol);
        const realizedPnlInUsdt = contract_type?.includes('options')
          ? realizedPnl
          : realizedPnl * convertToUSDT(settlingSymbol);

        if (Number(realizedPnl) > 0) {
          if (InAppReview.shouldShowAfterFirstProfitableTrade(appReview)) {
            InAppReview.triggerInAppReview({
              eventName: InAppReview.IN_APP_REVIEW_KEYS.FIRST_PROFITABLE_TRADE,
              dispatch,
            });
          }
        }

        trackMixpanelEvent('Position Closed - Trade - Table', {
          type: order_type,
          quantity: size,
          position_type: side === 'buy' ? 'long' : 'short',
          share_of_total_position: share,
          source: isChart,
          order_value_usdt: round(orderValueInUsdt, precision),
          realized_pnl_usdt: round(realizedPnlInUsdt, precision),
        });
      }
    } catch {}
  },

  [ORDER_BOOK.CHANGE_ORDER_LEVERAGE.SUCCESS]: (data, state) => {
    const productId = data.result.result.product_id;
    const filterProduct = state.trade.products[productId];

    return successNotifications({
      title: <LocalizedAlert localeKey="orderLeverage" />,
      message: `${i18n.t('alerts:orderLeverageMsg', {
        productSymbol: filterProduct?.symbol,
        leverage: data.result.result.leverage,
      })}x`,
      position: 'bl',
    });
  },

  [WALLET.CHANGE_MARGIN.SUCCESS]: (data, state) => {
    const { delta_margin } = data.payload;
    const productObj = getProductByIDHelper(state, data.payload.product_id);
    return successNotifications({
      title: <LocalizedAlert localeKey="posMarginChanged" />,
      message:
        Number(delta_margin) > 0
          ? i18n.t('alerts:posMarginChangedMsgAdd', {
              margin: Math.abs(delta_margin),
              symbol: productObj.settling_asset.symbol,
              prodSymbol: productObj.symbol,
            })
          : i18n.t('alerts:posMarginChangedMsgRemove', {
              margin: Math.abs(delta_margin),
              symbol: productObj.settling_asset.symbol,
              prodSymbol: productObj.symbol,
            }),
      // message: `${Math.abs(delta_margin)} ${
      //   productObj.settling_asset.symbol
      // } was ${Number(delta_margin) > 0 ? 'added to' : 'removed from'} your ${
      //   productObj.symbol
      // } position`,
      position: 'bl',
    });
  },

  // [WALLET.CHANGE_MARGIN.SUCCESS]: data =>
  //   successNotifications({
  //     title: 'Position Margin Changed',
  //     message: data.result.result.message,
  //     position: 'bl',
  //   }),

  [TRADE.CLOSE_POSITION.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.payload.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="closePosFailed" />,
      message:
        orderCancellationReason(data.payload, productObj, error, 'placed') ||
        i18n.t('alerts:closePosFailedMsg'),
      position: 'bl',
    });
  },

  // [TRADE.CLOSE_ALL_POSITION.SUCCESS]: data => {
  //   if (data?.result?.result) {
  //     trackMixpanelEvent('Close All Positions - Mobile', {
  //       type: 'all',
  //       source: 'mobile',
  //     });
  //   }
  // },

  [TRADE.CLOSE_ALL_POSITION.FAIL]: () => {
    return errorNotifications({
      title: <LocalizedAlert localeKey="closeAllPosFailed" />,
      message: i18n.t('alerts:closePosFailedMsg'),
      position: 'bl',
    });
  },

  [TRADE.CLOSE_POSITION_BASKET.SUCCESS]: data => {
    if (data?.result?.result) {
      trackMixpanelEvent('Basket Order Close Position', {
        type: 'close_portfolio_basket',
      });
    }
  },

  [TRADE.CLOSE_POSITION_BASKET.FAIL]: () => {
    return errorNotifications({
      title: <LocalizedAlert localeKey="closeAllPosFailed" />,
      message: i18n.t('alerts:closePosFailedMsg'),
      position: 'bl',
    });
  },

  [ORDER_BOOK.CHANGE_ORDER_LEVERAGE.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.payload.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="requestFailed" />,
      message:
        changeLeverageFailureReason(productObj, error) ||
        i18n.t('alerts:requestFailedMsg'),
      position: 'bl',
    });
  },

  [WALLET.CHANGE_MARGIN.FAIL]: data => {
    const error = view(errorLens, data);
    return errorNotifications({
      title:
        error.code === 'insufficient_margin' ? (
          <LocalizedAlert localeKey="insufficientMarginTitle" />
        ) : (
          <LocalizedAlert localeKey="requestFailed" />
        ),
      message: changeMarginFailureReason(error) || i18n.t('alerts:requestFailedMsg'),
      position: 'bl',
    });
  },

  [TRADE.ORDER.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const { user = {} } = state;
    const userCountry = user.country || '';
    const isKycExpiredUser = isKycRefreshExpired(user);
    const isKycRequired = isKycRequiredDeadlineCrossed(user);

    trackMixpanelEvent('Place Order Failed - Trade - Ordering', {
      error_message: error ? error.code : 'error code didnt received',
    });
    const productObj = getProductByIDHelper(state, data.payload.product_id);

    if (isKycRequired && error && error.code === 'trading_blocked') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="orderSubmissionFailed" />,
        message: i18n.t('errors:orders.non_kyc_expired'),
        position: 'bl',
      });
    }

    if (isKycExpiredUser && error && error.code === 'trading_blocked') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="orderSubmissionFailed" />,
        message: i18n.t('errors:orders.kyc_refresh_expired'),
        position: 'bl',
      });
    }

    if (error?.code === 'spot_trading_blocked' && userCountry.toLowerCase() === 'india') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="tradingBlocked" />,
        message: i18n.t('errors:orders.spotTradingBlockedForSpecificCountry'),
        position: 'bl',
      });
    }

    if (
      error?.code === 'spot_trading_blocked' ||
      error?.code === 'derivatives_trading_blocked'
    ) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="tradingBlocked" />,
        message: i18n.t('errors:orders.noTradingPrivileges'),
        position: 'bl',
      });
    }

    if (
      error &&
      error.code !== 'close_position_insufficient_margin' &&
      error.code !== 'spot_blocked_country' &&
      error.code !== 'liquidation_risk_limits_breached'
    ) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="orderSubmissionFailed" />,
        message: orderCancellationReason(
          data.payload,
          productObj,
          error,
          'placed',
          data?.mixpanelPayload?.marginType
        ),
        position: 'bl',
      });
    }

    if (error?.code === 'spot_blocked_country') {
      return errorNotifications({
        title: i18n.t('errors:orders.spot_blocked_title'),
        message:
          userCountry.toLowerCase() === 'singapore'
            ? i18n.t('errors:orders.singapore_spot_blocked')
            : i18n.t('errors:orders.spot_blocked_country'),
        position: 'bl',
      });
    }

    if (error?.code === 'liquidation_risk_limits_breached') {
      const { contract_type } = productObj;
      let errorMsg = '';
      if (isOptions(contract_type)) {
        errorMsg = i18n.t('errors:orders.liquidation_risk_breached_options');
      } else if (isFutures(contract_type)) {
        errorMsg = i18n.t('errors:orders.liquidation_risk_breached_futures');
      } else {
        errorMsg = i18n.t('errors:orders.liquidation_risk_breached');
      }

      return errorNotifications({
        title: i18n.t('errors:orders.order_rejected'),
        message: errorMsg,
        position: 'bl',
      });
    }

    return errorNotifications({
      title: <LocalizedAlert localeKey="orderSubmissionFailed" />,
      message: DEFAULT_ERROR_MESSAGE,
      position: 'bl',
    });
  },

  [BASKET_ORDERS.PLACE_ORDER.SUCCESS]: (data, state) => {
    try {
      const {
        buyOrders,
        sellOrders,
        marketOrders,
        limitOrders,
        underlyingCoin,
        numberOfContracts,
        marginMode,
      } = data?.mixpanelPayload || {};
      const ordersArray = data?.result?.result;
      const res = ordersArray.reduce(
        (acc, order) => {
          const { result } = order;
          const { size } = result;
          const product = getProductByIDHelper(state, result.product_id);
          const orderValue = Number(product?.contract_value) * Number(size);
          const spotPrice = spotPriceSelectorBySymbol(product?.spot_index?.symbol);
          const amountUsdt = Number(orderValue) * Number(spotPrice);
          return {
            orderValue: acc.orderValue + Number(orderValue),
            amountUsdt: acc.amountUsdt + Number(amountUsdt),
          };
        },
        {
          orderValue: 0,
          amountUsdt: 0,
        }
      );
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.BASKET_ORDER.PLACE_ORDER, {
        basket_order: true,
        buyOrders,
        sellOrders,
        marketOrders,
        limitOrders,
        underlyingCoin,
        numberOfContracts,
        marginMode,
        orderValue: res.orderValue,
        amountUsdt: cropAfterDecimals(res.amountUsdt, 2),
      });
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setExtras(error);
        Sentry.captureException(error);
      });
    }
    return successNotifications({
      title: <LocalizedAlert localeKey="basketOrderSuccess" />,
      message: ' ',
      position: 'bl',
    });
  },

  [TRADE.ORDER.SUCCESS]: (data, state) => {
    try {
      const { user } = state;
      // We are sending product in mixpanel payload when the basket order is placed with
      // isolated and cross margin.
      const product = data.mixpanelPayload.product || state.trade.selected_product;
      const productType = product.contract_type;
      const notional = data.mixpanelPayload?.notional;
      const orderType = getOrderType(data.result.result);
      const precision = product.underlying_asset?.minimum_precision;
      const bracketApplied = isBracketApplied(data.result.result) ? 'yes' : 'no';
      let orderValueInUSDT = 0;
      switch (productType) {
        case ContractType.Futures:
        case ContractType.PerpetualFutures:
          // in case settling asset is BTC
          // notional = 0.01 ETH
          // price of quoting asset in $ =
          // price of quoting asset in $ * price * size * cv
          // if (
          //   !isUsdStableCoin(
          //     state.trade.selected_product?.settling_asset.symbol &&
          //       state.trade.selected_product?.notional_type !== NotionalType.Inverse
          //   )
          // ) {
          //   const spotPrice = walletSpotPriceBySymbol(
          //     `.DE${
          //       state.trade.selected_product.quoting_asset.symbol === 'BTC'
          //         ? 'XBT'
          //         : state.trade.selected_product.quoting_asset.symbol
          //     }USDT`
          //   );
          //   orderValueInUSDT = isEmpty(spotPrice)
          //     ? 0
          //     : Number(notional) * Number(spotPrice);
          // }
          // all inverse contract has USD as contract unit currency hence 1cont = 1 usd. no need to convert
          if (product.notional_type === NotionalType.Inverse) {
            orderValueInUSDT = Number(notional);
          } else {
            const usdtPriceOfQuotingAsset = isUsdStableCoin(product.quoting_asset.symbol)
              ? 1
              : spotPriceSelectorBySymbol(
                  `.DE${
                    product.quoting_asset.symbol === 'BTC'
                      ? 'XBT'
                      : product.quoting_asset.symbol
                  }${VANILLA_SETTLING_ASSET}`
                );
            orderValueInUSDT = Number(data.mixpanelPayload.price)
              ? Number(notional) *
                Number(data.mixpanelPayload.price) *
                Number(usdtPriceOfQuotingAsset)
              : 0;
          }
          break;
        case ContractType.CallOptions:
        case ContractType.PutOptions:
        case ContractType.MoveOptions:
          // eslint-disable-next-line no-lone-blocks
          {
            const optionsSpotPrice = isUsdStableCoin(product.contract_unit_currency)
              ? 1
              : spotPriceSelectorBySymbol(
                  `.DE${
                    product.contract_unit_currency === 'BTC'
                      ? 'XBT'
                      : product.contract_unit_currency
                  }${VANILLA_SETTLING_ASSET}`
                );
            orderValueInUSDT = Number(optionsSpotPrice) * Number(notional);
          }
          break;
        case ContractType.Spot:
          orderValueInUSDT = 0;
          break;
        default:
          orderValueInUSDT = 0;
          break;
      }

      const price = data.mixpanelPayload?.price || '';
      const quantity = data.mixpanelPayload?.quantity;
      const noLeverage = isOptions(productType) && data.payload.side === 'buy';

      const amountUsdt =
        (orderType === 'limit' || orderType === 'market') && productType !== 'spot'
          ? `${round(orderValueInUSDT, precision)}`
          : '';
      const analyticsData = {
        product_name: data.result.result.product_symbol,
        product_type: productType,
        transaction_type: data.payload.side,
        bracket_applied: bracketApplied,
        leverage: noLeverage
          ? undefined
          : getLeverageValue(data.payload.product_id, state.orderbook.order_margins),
        order_type: orderType,
        flag: data.payload.time_in_force,
        checkboxes_selected: checkboxesSelected(data.payload),
        // order_value: orderValue ? `${round(orderValue, precision)} ${assetSymbol}` : '',
        country: user?.country,
        amount_usdt: amountUsdt,
        price: `${price}`,
        quantity: `${quantity}`,
        source: data?.mixpanelPayload?.source,
        margin_mode: user?.margin_mode,
      };
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.ORDER_PLACEMENT.PLACE_ORDER, analyticsData);
      // console.log('Order Placed - Trade - Ordering', analyticsData);
      MoengageLib.triggerEvent('Order Placed', analyticsData);
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setExtras(error);
        Sentry.captureException(error);
      });
    }
  },

  [TRADE.ORDER_CANCEL.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.params.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="orderCancelFail" />,
      message: orderCancellationReason(
        data.params,
        productObj,
        error,
        'deleted',
        data?.mixpanelPayload?.marginType
      ),
      position: 'bl',
    });
  },

  [TRADE.WITHDRAWAL_DATA.FAIL]: (data, state) => {
    const body = bodyLens(data);
    const errorStatus = view(lensPath(['error', 'status']), data);

    if (errorStatus === 500) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="withdrawalError" />,
        message: <LocalizedAlert localeKey="issueProcessingRequest" />,
        position: 'bl',
      });
    }
    if (body.error.code === 'forfeit_and_withdraw') {
      const { context } = body.error;
      body.error.context = {
        limit: state.trade.withdrawalLimits.max_crypto_withdrawal_without_forfeiting,
        ...(context && typeof context === 'object' ? context : {}),
      };
    }
    if (
      body.error.code !== 'invalid_mfa_code' &&
      body.error.code !== 'invalid_otp' &&
      body.error.code !== 'verify_withdrawal_blocked'
    ) {
      if (body.error && body.error.code) {
        return errorNotifications({
          title: <LocalizedAlert localeKey="withdrawalError" />,
          message: getWalletErrorMessage(
            body.error.code,
            body.error.context || { payload: data.payload }
          ),
          position: 'bl',
        });
      }
      return errorNotifications({
        title: <LocalizedAlert localeKey="withdrawalError" />,
        message: <LocalizedAlert localeKey="contactDelta" />,
        position: 'bl',
      });
    }
  },

  // eslint-disable-next-line consistent-return
  [TRADE.GET_WITHDRAWAL_OTP.FAIL]: data => {
    const body = bodyLens(data);
    if (body.error.code === 'verify_withdrawal_blocked') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="withdrawalError" />,
        message: getWalletErrorMessage(body.error.code, body.error.context),
        position: 'bl',
      });
    }
  },

  [RATE_LIMIT_EXCEEDED]: () =>
    errorNotifications({
      title: <LocalizedAlert localeKey="rateLimitExceeded" />,
      message: <LocalizedAlert localeKey="rateLimitExceededMsg" />,
      position: 'bl',
    }),

  [USER.CHANGE_PASSWORD.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="passChangeSuccess" />,
      message: <LocalizedAlert localeKey="passChangeSuccessMsg" />,
      position: 'bl',
    }),

  [USER.CREATE_WITHDRAWAL_ADDRESS.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="withdrawalAddCreated" />,
      message: <LocalizedAlert localeKey="withdrawalAddCreatedMsg" />,
      position: 'bl',
    }),

  [USER.DELETE_WITHDRAWAL_ADDRESS.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="withdrawalAddDeleted" />,
      message: <LocalizedAlert localeKey="withdrawalAddDeletedMsg" />,
      position: 'bl',
    }),

  [USER.DELETE_WITHDRAWAL_ADDRESS.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="withdrawalAddressDeleteFail" />,
      message: getWalletErrorMessage(body.error.code, body.error.context),
      position: 'bl',
    });
  },

  [WALLET.WITHDRAWAL_RESEND_CONFIRMATION.SUCCESS]: () => {
    return successNotifications({
      title: <LocalizedAlert localeKey="withdrawalUpdate" />,
      message: <LocalizedAlert localeKey="emailSuccess" />,
      position: 'bl',
    });
  },

  [WALLET.WITHDRAWAL_RESEND_CONFIRMATION.FAIL]: () => {
    return errorNotifications({
      title: <LocalizedAlert localeKey="invalidWithdrawal" />,
      message: <LocalizedAlert localeKey="invalidWithdrawalMsg" />,
      position: 'bl',
    });
  },

  [USER.RESET_PASSWORD.FAIL]: data => {
    const body = bodyLens(data);
    if (
      body.error.code !== 'invalid_otp' &&
      body.error.code !== 'reset_password_blocked' &&
      body.error.code !== ERROR_CODES.BCRYPT_HASH_LIMIT
    ) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="passResetFail" />,
        message: userProfileFailureReason(body.error),
        position: 'bl',
      });
    }
  },

  [USER.UPDATE_PROFILE.SUCCESS]: ({ showAlert = true }) => {
    if (showAlert) {
      return successNotifications({
        title: <LocalizedAlert localeKey="updateProfileSuccess" />,
        message: <LocalizedAlert localeKey="updateProfileSuccessMsg" />,
        position: 'bl',
      });
    }
  },
  [USER.UPDATE_PROFILE.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title:
        body.error.code === 'region_is_updated' ? (
          <LocalizedAlert localeKey="regionAlreadyUpdated" />
        ) : (
          <LocalizedAlert localeKey="updateProfileFailed" />
        ),
      message: userProfileFailureReason(body.error),
      position: 'bl',
    });
  },

  [USER.LOGINMFA.FAIL]: data => {
    const body = bodyLens(data);
    if (
      body.error.code !== 'invalid_mfa_code' &&
      body.error.code !== 'invalid_login_otp'
    ) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="invalid2fa" />,
        message: userProfileFailureReason(bodyLens(data).error),
        position: 'bl',
      });
    }
  },

  [USER.LOGIN.FAIL]: data => {
    const body = bodyLens(data);
    if (body && body.error.code === 'invalid_captcha') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="loginFailure" />,
        message: userProfileFailureReason(body.error),
        position: 'bl',
      });
    }
  },

  [USER.ENABLE_MFA.SUCCESS]: data =>
    successNotifications({
      title: <LocalizedAlert localeKey="2faEnabled" />,
      message: data?.result?.message || <LocalizedAlert localeKey="2faEnabled" />,
      position: 'bl',
    }),

  [USER.RESET_MFA.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="resetMfaSuccess" />,
      message: <LocalizedAlert localeKey="resetMfaSuccessMsg" />,
      position: 'bl',
    }),

  [USER_SIGNUP_FAIL]: data => {
    const { title, message } = userSignupFailureReason(
      data.payload || DEFAULT_ERROR_MESSAGE
    );
    if (title && message) {
      return errorNotifications({
        title,
        message,
        position: 'bl',
      });
    }
  },

  [USER.LOGOUT_FROM_ALL_DEVICES.FAIL]: data =>
    errorNotifications({
      title: <LocalizedAlert localeKey="logoutActive" />,
      message: userProfileFailureReason(
        (bodyLens(data) && bodyLens(data).error) || DEFAULT_ERROR_MESSAGE
      ),
      position: 'bl',
    }),

  [USER.LOGOUT_FROM_ALL_DEVICES.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="logoutActive" />,
      message: <LocalizedAlert localeKey="logoutActiveMsg" />,
      position: 'bl',
    }),

  [USER.DISABLE_MFA.FAIL]: data =>
    errorNotifications({
      title: <LocalizedAlert localeKey="2fa" />,
      message: userProfileFailureReason(
        (bodyLens(data) && bodyLens(data).error) || DEFAULT_ERROR_MESSAGE
      ),
      position: 'bl',
    }),

  [USER.DISABLE_MFA.SUCCESS]: data =>
    successNotifications({
      title: <LocalizedAlert localeKey="2faDisabled" />,
      message: data?.result?.message || <LocalizedAlert localeKey="2faDisabled" />,
      position: 'bl',
    }),

  [WALLET.CONVERT_CURRENCY.SUCCESS]: (data, state) => {
    const assetsList = assetsListSelector(state);
    const { to, from } = data.payload;
    const to_asset = find(x => x.symbol === to, assetsList);
    const from_asset = find(x => x.symbol === from, assetsList);
    const { to_amount, from_amount } = data.result.result;

    return successNotifications({
      title: <LocalizedAlert localeKey="currencyConvertSuccess" />,
      message: `${i18n.t('common:sold')} ${parseFloat(from_amount).toFixed(
        from_asset.minimum_precision
      )} ${from.toUpperCase()} ${i18n.t('common:for')} ${parseFloat(to_amount).toFixed(
        to_asset.minimum_precision
      )} ${to.toUpperCase()}`,
      position: 'bl',
    });
  },

  [WALLET.CONVERT_CURRENCY.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="currencyConvertFail" />,
      message: getWalletErrorMessage(body.error.code, body.error.context),
      position: 'bl',
    });
  },

  [TRADE.CANCEL_WITHDRAWAL.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="withdrawalCancelFail" />,
      message: getWalletErrorMessage(body.error.code, body.error.context),
      position: 'bl',
    });
  },

  [TRADE.WITHDRAWAL_TRADING_CREDITS_FORFEIT.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="tradCreditsNotForfeited" />,
      message: getWalletErrorMessage(body.error.code, body.error.context),
      position: 'bl',
    });
  },

  [TRADE.WITHDRAWAL_TRADING_CREDITS_FORFEIT.SUCCESS]: data => {
    const { trading_credits } = data.result.result;
    return successNotifications({
      title: <LocalizedAlert localeKey="tradCreditsForfeited" />,
      message: i18n.t('alerts:tradCreditsForfeitedMsg', {
        amount: Number(trading_credits).toFixed(8),
        assetSymbol: ASSET_SYMBOL,
      }),
      position: 'bl',
    });
  },

  [USER.CREATE_API_KEY.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="apiKeyCredited" />,
      message: <LocalizedAlert localeKey="apiKeyCreditedMsg" />,
      position: 'bl',
    }),

  // [USER.CREATE_API_KEY.FAIL]: (data) =>
  //   errorNotifications({
  //     title: 'API key creation failed',
  //     message: generateAPIFailureReason(bodyLens(data).error),
  //     position: 'bl',
  //   }),

  [USER.DELETE_API_KEY.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="apiKeyDeleted" />,
      message: <LocalizedAlert localeKey="apiKeyDeletedMsg" />,
      position: 'bl',
    }),

  [USER.DELETE_API_KEY.FAIL]: () =>
    errorNotifications({
      title: <LocalizedAlert localeKey="apiKeyDeleteFailed" />,
      message: <LocalizedAlert localeKey="apiKeyDeleteFailedMsg" />,
      position: 'bl',
    }),

  [USER.APPLY_PROMO.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="promoApplied" />,
      message: <LocalizedAlert localeKey="promoAppliedMsg" />,
      position: 'bl',
    }),

  [USER.APPLY_PROMO.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="promoApplied" />,
      message: body.error.message,
      position: 'bl',
    });
  },

  [TRADE.SHARE_CARD.FAIL]: () =>
    errorNotifications({
      title: <LocalizedAlert localeKey="pnlCreateFail" />,
      message: <LocalizedAlert localeKey="pnlCreateFailMsg" />,
      position: 'bl',
    }),

  [TRADE.WITHDRAWAL_DATA.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="withdrawalInitiated" />,
      message: <LocalizedAlert localeKey="withdrawalInitiatedMsg" />,
      position: 'bl',
    }),

  [TRADE.CANCEL_WITHDRAWAL.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="withdrawalCancelled" />,
      message: <LocalizedAlert localeKey="withdrawalCancelledMsg" />,
      position: 'bl',
    }),

  // only one message need to be shown after updating preference.
  [TRADE.UPDATE_TRADE_PREFERENCE.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="updatePreferenceTitle" />,
      message: <LocalizedAlert localeKey="updatePreferenceMessage" />,
      position: 'bl',
    }),

  [TRADE.UPDATE_TRADE_PREFERENCE.FAIL]: data =>
    errorNotifications({
      title: <LocalizedAlert localeKey="preferenceUpdateFail" />,
      message: userProfileFailureReason(bodyLens(data).error),
      position: 'bl',
    }),

  [TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION.FAIL]: (data, state) => {
    const errorObj = bodyLens(data).error || {};
    const errorCode = errorObj.code || '';
    // Since error message from BE is same for enable/disable portfolio, so we need to check locally
    const portfolioValue = localStorage.getItem('portfolioEnabled');
    const isPortfolioDisabled = portfolioValue === 'undefined';

    if (data?.params?.showChangeNotif) {
      return errorNotifications({
        title: <PortfolioMarginLocalizedErrors localeKey="portfolioChangeFailTitle" />,
        message: <PortfolioMarginLocalizedErrors localeKey="portfolioChangeFailMsg" />,
        position: 'bl',
      });
    }
    let dataClone = { ...data };
    if (errorCode === 'account_limit_reached') {
      const selectedProduct = selectedProductSelector(state);
      dataClone = {
        ...dataClone,
        assetSymbol: selectedProduct?.underlying_asset?.symbol,
        marginMode: data.params.margin_mode,
        limit: errorObj?.context?.limit,
      };
    }
    const getMessage = () => {
      if (errorCode === 'portfolio_margin_halted') {
        return {
          header: 'portfolio_margin_halted_header',
          body: 'portfolio_margin_halted_body',
        };
      }
      if (
        errorCode === 'cant_switch_with_open_orders_or_positions' &&
        isPortfolioDisabled
      ) {
        return {
          header: 'portfolio_enable_not_allowed_header',
          body: 'portfolio_enable_not_allowed_body',
        };
      }
      if (
        errorCode === 'cant_switch_with_open_orders_or_positions' &&
        !isPortfolioDisabled
      ) {
        return {
          header: 'portfolio_disable_not_allowed_header',
          body: 'portfolio_disable_not_allowed_body',
        };
      }
      if (errorCode === '' && !isPortfolioDisabled) {
        return {
          header: 'portfolio_disable_not_allowed_header',
          body: 'portfolio_disable_not_allowed_body',
        };
      }
      return {
        header: 'portfolio_failed',
        body: errorCode,
      };
    };

    return errorNotifications({
      title: <PortfolioMarginLocalizedErrors localeKey={getMessage().header} />,
      message: (
        <PortfolioMarginLocalizedErrors localeKey={getMessage().body} data={dataClone} />
      ),
      position: 'bl',
    });
  },

  [USER.UPDATE_PREFERENCES.SUCCESS]: data => {
    if (!data.payload.disable_notification) {
      return successNotifications({
        title: <LocalizedAlert localeKey="updatePreferenceTitle" />,
        message: <LocalizedAlert localeKey="updatePreferenceMessage" />,
        position: 'bl',
      });
    }
  },

  [TRADE.BRACKET_ORDER.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="bracketOrder" />,
      message: <LocalizedAlert localeKey="bracketOrderCreated" />,
      position: 'bl',
    }),

  [TRADE.BRACKET_ORDER.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.payload.product_id);

    return errorNotifications({
      title: <LocalizedAlert localeKey="bracketOrderFail" />,
      message: bracketOrderFailureReason(productObj, error),
      position: 'bl',
    });
  },

  [TRADE.PLACE_BRACKET_ORDER_POSITION.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.payload.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="bracketOrderFail" />,
      message: bracketOrderFailureReason(productObj, error),
      position: 'bl',
    });
  },

  [TRADE.EDIT_BRACKET_ORDER.SUCCESS]: (data, state) => {
    const order = data.result.result;
    const orderType = getStopOrderType(order);
    const product = getProductByIDHelper(state, order.product_id);
    const productSymbol = getProductSymbol(product);
    const { contract_type, tick_size } = product;
    const { side, bracket_order, order_type, limit_price } = order;
    const source = data.mixpanelPayload?.chart ? 'chart' : 'table';
    // i18n.t(alerts:)
    const priceBands = store.tickerData?.priceBands;
    const upperLimit = priceBands[order.product_id]?.upper_limit;

    const limitPriceFromParams = data.params.limit_price;
    const isLimitPriceUpdated = limitPriceFromParams === limit_price;

    let message = i18n.t('alerts:orderUpdateSuccess');
    trackMixpanelEvent('Order Edited (Successful) - Trade - Table', {
      product_name: productSymbol,
      product_type: contract_type,
      transaction_type: side,
      order_type: getOrderFormat(bracket_order, order_type),
      source,
    });

    if (limitPriceFromParams && !isLimitPriceUpdated) {
      if (Number(limitPriceFromParams) > Number(upperLimit)) {
        message = i18n.t('alerts:limitPriceAboveMax');
      }

      if (Number(limitPriceFromParams) < Number(tick_size)) {
        message = i18n.t('alerts:limitPriceNotUpdated', {
          minLimitPrice: tick_size,
        });
      }
    }

    if (product.contract_type === 'spot') {
      if (orderType === 'Stop Limit') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size || order.quote_size} ${
          order.size ? '' : product.quoting_asset.symbol + i18n.t('common:worth')
        } ${i18n.t('common:of')} ${product.underlying_asset.symbol} ${i18n.t(
          'common:at'
        )} ${order.side === 'sell' ? '<=' : '>='} ${order.stop_price}`;
      }

      if (orderType === 'Take Profit Limit') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size || order.quote_size} ${
          order.size ? '' : product.quoting_asset.symbol + i18n.t('common:worth')
        } ${i18n.t('common:of')} ${product.underlying_asset.symbol} ${i18n.t(
          'common:at'
        )} ${order.side === 'sell' ? '>=' : '<='} ${order.stop_price}`;
      }

      if (orderType === 'Stop Market') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size || order.quote_size} ${
          order.size ? '' : product.quoting_asset.symbol + i18n.t('common:worth')
        } ${i18n.t('common:of')} ${product.underlying_asset.symbol}. ${i18n.t(
          'common:triggerPrice'
        )} ${order.side === 'sell' ? '<=' : '>='} ${order.stop_price}`;
      }

      if (orderType === 'Take Profit Market') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size || order.quote_size} ${
          order.size ? '' : product.quoting_asset.symbol + i18n.t('common:worth')
        } ${i18n.t('common:of')} ${product.underlying_asset.symbol}. ${i18n.t(
          'common:triggerPrice'
        )} ${order.side === 'sell' ? '>=' : '<='} ${order.stop_price}`;
      }
    } else {
      if (orderType === 'Stop Limit') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size} ${
          Number(order.size) > 1 ? i18n.t('common:contracts') : i18n.t('common:contract')
        } ${i18n.t('common:of')} ${productSymbol} ${i18n.t('common:at')} ${
          order.side === 'sell' ? '<=' : '>='
        } ${order.stop_price} ${product.quoting_asset.symbol}`;
      }

      if (orderType === 'Take Profit Limit') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size} ${
          Number(order.size) > 1 ? i18n.t('common:contracts') : i18n.t('common:contract')
        } ${i18n.t('common:of')} ${productSymbol} ${i18n.t('common:at')} ${
          order.side === 'sell' ? '>=' : '<='
        } ${order.stop_price} ${product.quoting_asset.symbol}`;
      }

      if (orderType === 'Stop Market') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size} ${
          Number(order.size) > 1 ? i18n.t('common:contracts') : i18n.t('common:contract')
        } ${i18n.t('common:of')} ${productSymbol}. ${i18n.t('common:triggerPrice')} ${
          order.side === 'sell' ? '<=' : '>='
        } ${order.stop_price} ${product.quoting_asset.symbol}`;
      }

      if (orderType === 'Take Profit Market') {
        message = `${
          order.side === 'buy' ? i18n.t('common:buy') : i18n.t('common:sell')
        } ${order.size} ${
          Number(order.size) > 1 ? i18n.t('common:contracts') : i18n.t('common:contract')
        } ${i18n.t('common:of')} ${productSymbol}. ${i18n.t('common:triggerPrice')} ${
          order.side === 'sell' ? '>=' : '<='
        } ${order.stop_price} ${product.quoting_asset.symbol}`;
      }
    }

    return successNotifications({
      title: <LocalizedAlert localeKey="orderUpdated" />,
      message,
      position: 'bl',
    });
  },

  [TRADE.EDIT_BRACKET_ORDER.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.params.product_id);
    const source = data.mixpanelPayload?.chart ? 'chart' : 'table';
    trackMixpanelEvent('Order Edited (Failed) - Trade - Table', {
      product_name: productObj.symbol,
      product_type: productObj.contract_type,
      reason: error.code,
      source,
    });
    return errorNotifications({
      title: <LocalizedAlert localeKey="editOrderFailed" />,
      message: orderCancellationReason(
        data.params,
        productObj,
        error,
        'edited',
        data?.mixpanelPayload?.marginType
      ),
      // message: bracketOrderFailureReason(productObj, error , ),
      position: 'bl',
    });
  },

  [TRADE.CANCEL_BRACKET_ORDER.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="bracketOrder" />,
      message: <LocalizedAlert localeKey="bracketOrderCancelled" />,
      position: 'bl',
    }),

  [TRADE.CANCEL_BRACKET_ORDER_POSITION.SUCCESS]: (data, state) => {
    bracketCancelMixpanel(positionType.OPEN, data, state);
  },
  [TRADE.UPDATE_BRACKET_ORDER_FROM_OPEN_ORDER.SUCCESS]: (data, state) => {
    if (!isBracketApplied(data.params)) {
      bracketCancelMixpanel(positionType.NEW, data, state);
    }
    return successNotifications({
      title: <LocalizedAlert localeKey="bracketOrder" />,
      message: <LocalizedAlert localeKey="bracketOrderUpdated" />,
      position: 'bl',
    });
  },
  [TRADE.CANCEL_BRACKET_ORDER.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.params.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="cancelOrderFail" />,
      // message: orderCancellationReason(
      //   data.params,
      //   productObj,
      //   error,
      //   'deleted'
      // ),
      message: bracketOrderFailureReason(productObj, error),
      position: 'bl',
    });
  },
  [TRADE.UPDATE_BRACKET_ORDER_FROM_OPEN_ORDER.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    const productObj = getProductByIDHelper(state, data.params.product_id);
    return errorNotifications({
      title: <LocalizedAlert localeKey="bracketOrderFail" />,
      // message: orderCancellationReason(
      //   data.params,
      //   productObj,
      //   error,
      //   'edited'
      // ),
      message: bracketOrderFailureReason(productObj, error),
      position: 'bl',
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [USER.GET_ACCOUNT_TOKEN.FAIL]: (data, state) => {
    const error = view(errorLens, data);
    return errorNotifications({
      title: <LocalizedAlert localeKey={error.code} />,
      message: '',
      position: 'bl',
    });
  },

  [TRADE.CANCEL_ALL_ORDER.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="cancelOrderSuccess" />,
      message: <LocalizedAlert localeKey="cancelOrderSuccess" />,
      position: 'bl',
    }),
  [USER.WITHDRAWAL_PENDING_COUNT.SUCCESS]: data => {
    if (data.result.result.length > 0) {
      return errorNotifications({
        title: <LocalizedAlert localeKey="" />,
        message: <LocalizedAlert localeKey="withdrawalPendingCount" />,
        position: 'bl',
      });
    }
  },
  [TRADE.CANCEL_ALL_ORDER.FAIL]: data => {
    const body = bodyLens(data);
    return errorNotifications({
      title: <LocalizedAlert localeKey="cancelAllOrderFail" />,
      message: genericErrorLens(body),
      position: 'bl',
    });
  },

  [TRADE.POSITION_AUTO_TOPUP.SUCCESS]: (data, state) => {
    const { result } = data.result;
    const contractPosition = state.trade.products[result.product_id].symbol;
    const topUpStatus = result.auto_topup ? 'enabled' : 'disabled';

    return successNotifications({
      title: i18n.t(`alerts:autoMarginTopUp`, {
        topUpStatus,
        contractPosition,
      }),
      message: (
        <LocalizedAlert
          localeKey={
            topUpStatus === 'enabled'
              ? 'autoMarginTopUpMsg'
              : 'autoMarginTopUpDisabledMsg'
          }
        />
      ),
      position: 'bl',
    });
  },
  [TRADE.TICKER.SUCCESS]:
    ({ result }) =>
    () => {
      if (result?.result?.length > 0) {
        updateAllTickers(result.result);
      } else {
        updateTicker(result.result);
      }
    },
  [TRADE.ALL_TICKERS.SUCCESS]:
    ({ result }) =>
    () =>
      updateAllTickers(result.result || []),
  [SAVE_CHART]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="chart" />,
      message: <LocalizedAlert localeKey="chartSaved" />,
      position: 'bl',
    }),
  [USER.TRANSFER_FUNDS_SUB_ACCOUNTS.SUCCESS]: data => {
    const { asset_symbol, amount, transferrer_user, transferee_user } = data.payload;

    return successNotifications({
      title: i18n.t('alerts:transferFundSuccess', {
        asset_symbol,
        amount,
        transferrer_user,
        transferee_user,
      }),
      message: ' ',
      position: 'bl',
    });
  },
  [USER.CREATE_SUB_ACCOUNT.SUCCESS]: () =>
    successNotifications({
      title: <LocalizedAlert localeKey="subAccountCreated" />,
      message: ' ',
      position: 'bl',
    }),
  // [BASKET_ORDERS.PLACE_ORDER.SUCCESS]: data =>
  //   successNotifications({
  //     title: <LocalizedAlert localeKey="basketOrderSuccess" />,
  //     message: ' ',
  //     position: 'bl',
  //   }),
  [USER.LOGIN_EMAIL_VERIFICATION.FAIL]: data => {
    const error = view(errorLens, data);
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.LOGIN_EMAIL_VERIFICATION.FAIL, {
      error_code: error.code,
      os: getMobileOperatingSystem(),
    });
  },

  [ORDER_BOOK_STALE]: () =>
    errorNotifications({
      title: <LocalizedAlert localeKey="orderbookStaleTitle" />,
      message: <LocalizedAlert localeKey="orderbookStaleMessage" />,
      position: 'bl',
    }),
  [USER.GET_KYC_VENDOR_LINK.FAIL]: data => {
    const errorCode = parseErrorMsg(data?.error)?.error?.code || '';
    if (errorCode !== 'cannot_update_kyc_status') {
      return errorNotifications({
        title: <LocalizedAlert localeKey="error" />,
        message: <LocalizedAlert localeKey="genericErrorMessage" />,
        position: 'bl',
      });
    }
  },
  [USER.GET_USER_PROFILE.SUCCESS]: (data, state) => dispatch => {
    try {
      const profile = data.result.result;
      const appReview = selectAppReviewFromTrackingInfo(state) || {};
      const {
        deposit_info: depositInfo,
        login_count: loginCount,
        last_30_days_trading_volume: monthlyTradingVolume,
      } = profile;

      if (
        InAppReview.shouldShowForLoginCountAndVolume(appReview, {
          loginCount,
          volume: Number(monthlyTradingVolume),
        })
      ) {
        InAppReview.triggerInAppReview({
          eventName: InAppReview.IN_APP_REVIEW_KEYS.LOGIN_AND_VOLUME,
          dispatch,
        });
        return;
      }

      const depositsCount = depositInfo.length;

      if (InAppReview.shouldShowForDepositsCount(appReview, depositsCount)) {
        InAppReview.triggerInAppReview({
          eventName: InAppReview.getDepositsCountKey(depositsCount),
          dispatch,
        });
      }
    } catch (error) {}
  },
  [ACCOUNT_ACTION_TYPES[KycImportActionType.IMPORT_KYC_FROM_GLOBAL].FAIL]: data => {
    const error = view(errorLens, data);

    const getMessage = () => {
      if (error?.code === 'import_blocked_due_to_country') {
        return i18n.t('alerts:kycConcent.import_blocked_due_to_country');
      }

      if (error?.code === 'kyc_not_approved') {
        return i18n.t('alerts:kycConcent.kyc_not_approved');
      }

      if (error?.code === 'missing_document') {
        return i18n.t('alerts:kycConcent.missing_document');
      }

      return null;
    };

    if (getMessage()) {
      return errorNotifications({
        title: i18n.t('alerts:kycConcent.import_failed'),
        message: getMessage(),
        position: 'bl',
      });
    }
  },
  [ACCOUNT_ACTION_TYPES[KycImportActionType.IMPORT_KYC_FROM_GLOBAL].SUCCESS]: () =>
    successNotifications({
      title: i18n.t('alerts:kycConcent.import_success'),
      message: ' ',
      position: 'bl',
    }),
  [ACCOUNT_ACTION_TYPES[SignalTradingActionType.DELETE_WEBHOOK].FAIL]: () => {
    return errorNotifications({
      title: i18n.t('errors:utils.somethingWentWrong'),
      message: i18n.t('errors:utils.generic_with_contact_support'),
      position: 'bl',
    });
  },
};

const TYPES = Object.keys(alerts);
const alertsEpic = (action$, state$) =>
  action$.pipe(
    ofType(...TYPES),
    map(action => alerts[action.type](action, state$.value))
  );

export default alertsEpic;
