import { useCallback } from 'react';

import { calculateOpenPositionValue } from 'components/open_positions/helpers';
import {
  getIrsUnPnl,
  getUnrealisedPnl,
  getIrsRoe,
  getROE,
  round,
  round_by_tick_size,
  getPremium,
  getIrsPremium,
  getPayOff,
  getIrsPayoff,
  getEffectiveLeverage,
  getNotional,
} from 'helpers/formulas';
import { isNil } from 'helpers/ramda';
import {
  cropAfterDecimals,
  convertExponentialToDecimal,
  handleExponentialAndCropDecimals,
  addZeroesUntilCorrectPrecision,
} from 'helpers/utils';
import { OpenPosition } from 'types/IOpenPosition';
import { ContractType } from 'types/IProducts';

const useOpenPositionHelpers = () => {
  const getUnrealizedPnlAndRoe = useCallback(
    ({
      currentItemProps,
      markPrice,
      spotPrice,
    }: {
      currentItemProps: OpenPosition;
      markPrice: number | string;
      spotPrice: number;
    }): {
      roe: string | number;
      unrealizedPnl: string;
      symbol: string;
      preciseUnrealisedPnL: string | number;
    } => {
      // ** Incase of Options, send last traded price as markPrice

      const {
        product,
        entry_price,
        size,
        margin,
        realized_pnl,
        realized_funding,
        created_at,
      } = currentItemProps;

      const markPriceValue = Number(markPrice);

      if (isNil(markPrice) || isNaN(markPriceValue)) {
        return {
          roe: '-',
          unrealizedPnl: '-',
          symbol: '',
          preciseUnrealisedPnL: '-',
        };
      }
      const position = {
        realized_funding,
        realized_pnl,
        size,
        margin,
      };

      const unrealizedPnl =
        product?.contract_type === ContractType.InterestRateSwaps
          ? getIrsUnPnl(
              product,
              spotPrice,
              markPriceValue,
              position,
              entry_price,
              created_at
            )
          : getUnrealisedPnl(
              product?.notional_type,
              product?.contract_value,
              entry_price,
              markPriceValue,
              size,
              product?.contract_type
            );

      const { symbol } = product.settling_asset;
      const roundedUnrealizedPnL = cropAfterDecimals(
        convertExponentialToDecimal(unrealizedPnl),
        product.settling_asset.minimum_precision
      );

      const preciseUnrealisedPnL = cropAfterDecimals(
        convertExponentialToDecimal(unrealizedPnl),
        product.settling_asset.precision
      );

      const textValue = `${
        isNaN(roundedUnrealizedPnL) || !isFinite(roundedUnrealizedPnL)
          ? '-'
          : // : Math.abs(roundedUnrealizedPnL) === 0
            // ? '0.00'
            roundedUnrealizedPnL
      }`;

      const roe =
        product.contract_type === ContractType.InterestRateSwaps
          ? round(getIrsRoe(position, unrealizedPnl), 2)
          : round(getROE(unrealizedPnl, margin, product, entry_price, size), 2);

      return {
        unrealizedPnl: textValue,
        roe,
        symbol,
        preciseUnrealisedPnL,
      };
    },
    []
  );

  const getEntryPriceOrRate = useCallback((entry_price, tick_size) => {
    return addZeroesUntilCorrectPrecision(
      round_by_tick_size(entry_price, tick_size),
      tick_size
    );
  }, []);

  const getRealizedPnl = useCallback(
    ({
      currentItemProps,
      spotPrice,
    }: {
      currentItemProps: OpenPosition;
      spotPrice: number;
    }): string => {
      const { product, size, entry_price, realized_pnl, created_at, realized_funding } =
        currentItemProps;

      const value = (() => {
        switch (product.contract_type) {
          case ContractType.CallOptions:
          case ContractType.PutOptions:
          case ContractType.MoveOptions:
          case ContractType.TurboPutOptions:
          case ContractType.TurboCallOptions:
            const move_premium = getPremium(product.contract_value, size, entry_price);
            return handleExponentialAndCropDecimals(
              Number(realized_pnl) - Number(move_premium),
              product.settling_asset.minimum_precision
            );
          case ContractType.InterestRateSwaps:
            const irs_premium = getIrsPremium(
              size,
              entry_price,
              spotPrice,
              product,
              created_at
            );
            return Number(
              Number(realized_pnl) - Number(irs_premium) + Number(realized_funding)
            ).toFixed(product.settling_asset.minimum_precision);
          default:
            return handleExponentialAndCropDecimals(
              Number(realized_pnl) + Number(realized_funding),
              product.settling_asset.minimum_precision
            );
        }
      })();

      return `${value} ${product.settling_asset.symbol}`;
    },
    []
  );

  const getPremiumValue = useCallback(
    ({ contractValue, size, entryPrice, minimumPrecision, symbol }) => {
      return `${cropAfterDecimals(
        getPremium(contractValue, size, entryPrice),
        minimumPrecision
      )} ${symbol}`;
    },
    []
  );

  const getIrsPremiumValue = useCallback(
    ({
      product,
      spotPrice,
      size,
      entryPrice,
      createdAt,
    }: {
      product: OpenPosition['product'];
      spotPrice: number;
      size: OpenPosition['size'];
      entryPrice: OpenPosition['entry_price'];
      createdAt: OpenPosition['created_at'];
    }) => {
      return `${cropAfterDecimals(
        getIrsPremium(size, entryPrice, spotPrice, product, createdAt),
        product.settling_asset.minimum_precision
      )} ${product.settling_asset.symbol}`;
    },
    []
  );

  const getPayoffValue = useCallback(
    ({
      markPrice,
      size,
      contractValue,
      minPrecision,
      symbol,
    }: {
      markPrice: number;
      size: OpenPosition['size'];
      contractValue: OpenPosition['product']['contract_value'];
      minPrecision: OpenPosition['product']['settling_asset']['minimum_precision'];
      symbol: OpenPosition['product']['settling_asset']['symbol'];
    }): string => {
      if (isNil(markPrice)) {
        return '-';
      }
      return `${cropAfterDecimals(
        getPayOff(contractValue, size, markPrice),
        minPrecision
      )} ${symbol}`;
    },
    []
  );

  const getIrsPayoffValue = useCallback(
    ({
      markPrice,
      spotPrice,
      size,
      product,
    }: {
      markPrice: number;
      spotPrice: number;
      product: OpenPosition['product'];
      size: OpenPosition['size'];
    }): string => {
      if (isNil(markPrice)) {
        return '-';
      }
      return `${cropAfterDecimals(
        getIrsPayoff(size, markPrice, product, spotPrice),
        product.settling_asset.minimum_precision
      )} ${product.settling_asset.symbol}`;
    },
    []
  );

  const getLeverageValue = useCallback(
    ({
      markPrice,
      spotPrice,
      product,
      size,
      realizedFunding,
      realizedPnl,
      margin,
      entryPrice,
      createdAt,
    }: {
      markPrice: number;
      spotPrice: number;
      product: OpenPosition['product'];
      size: OpenPosition['size'];
      realizedFunding: OpenPosition['realized_funding'];
      realizedPnl: OpenPosition['realized_pnl'];
      margin: OpenPosition['margin'];
      entryPrice: OpenPosition['entry_price'];
      createdAt: OpenPosition['created_at'];
    }): string => {
      const position = {
        realized_funding: realizedFunding,
        realized_pnl: realizedPnl,
        size,
        margin,
      };

      return `${getEffectiveLeverage(
        product,
        entryPrice,
        markPrice,
        spotPrice,
        position,
        createdAt
      )}x`;
    },
    []
  );

  const getStopLossValue = useCallback(
    ({
      markPrice,
      stop_loss_order,
      tick_size,
    }: {
      stop_loss_order: OpenPosition['bracket_orders']['stop_loss_order'];
      markPrice: number;
      tick_size: OpenPosition['product']['tick_size'];
    }): string | number => {
      if (stop_loss_order.trail_amount) {
        return round_by_tick_size(
          Number(stop_loss_order.trail_amount) + Number(markPrice),
          tick_size
        );
      }
      return stop_loss_order.limit_price || stop_loss_order.stop_price || '-';
    },
    []
  );

  const getNotionalValue = useCallback(
    ({
      product,
      entry_price,
      size,
    }: {
      product: OpenPosition['product'];
      entry_price: OpenPosition['entry_price'];
      size: OpenPosition['size'];
    }): {
      value: string;
      symbol: string;
      untrimmedValue: number;
    } => {
      return calculateOpenPositionValue({
        product,
        entry_price,
        size,
      });
    },
    []
  );

  const getRealizedCashflow = useCallback(
    ({
      realized_cashflow,
      minimum_precision,
      symbol,
    }: {
      realized_cashflow: OpenPosition['realized_cashflow'];
      minimum_precision: OpenPosition['product']['settling_asset']['minimum_precision'];
      symbol: OpenPosition['product']['settling_asset']['symbol'];
    }) => {
      if (!realized_cashflow) {
        return {
          value: '-',
          symbol: '',
        };
      }

      const formattedValue = cropAfterDecimals(realized_cashflow, minimum_precision);

      return {
        value: formattedValue,
        symbol,
      };
    },
    []
  );

  const getAssignedMargin = useCallback(
    ({
      margin,
      minimum_precision,
      symbol,
    }: {
      margin: OpenPosition['margin'];
      minimum_precision: OpenPosition['product']['settling_asset']['minimum_precision'];
      symbol: OpenPosition['product']['settling_asset']['symbol'];
    }) => {
      const formattedValue = Number(round(margin, minimum_precision));
      if (!formattedValue || isNaN(formattedValue)) {
        return {
          value: '-',
          symbol: '',
        };
      }
      return {
        value: formattedValue,
        symbol,
      };
    },
    []
  );

  const getNotionalInUnderlying = useCallback(
    (
      contract_value: OpenPosition['product']['contract_value'],
      size: OpenPosition['size']
    ) => {
      return Number(contract_value) * Number(size);
    },
    []
  );

  const getNotionalInSettling = useCallback(
    (
      notional_type: OpenPosition['product']['notional_type'],
      contract_value: OpenPosition['product']['contract_value'],
      size: OpenPosition['size'],
      price: number
    ) => {
      return getNotional(notional_type, contract_value, size, price);
    },
    []
  );

  const isIrsType = (contract_type: ContractType) =>
    contract_type === ContractType.InterestRateSwaps;
  const isSpreadsType = (contract_type: ContractType) =>
    contract_type === ContractType.Spreads;
  const isFuturesType = (contract_type: ContractType) =>
    contract_type === ContractType.Futures ||
    contract_type === ContractType.PerpetualFutures;
  const isPerpetualFutureType = (contract_type: ContractType) =>
    contract_type === ContractType.PerpetualFutures;
  const isSpotType = (contract_type: ContractType) => contract_type === ContractType.Spot;

  return {
    getUnrealizedPnlAndRoe,
    getEntryPriceOrRate,
    getRealizedPnl,
    getPremiumValue,
    getIrsPremiumValue,
    getPayoffValue,
    getIrsPayoffValue,
    getLeverageValue,
    isIrsType,
    isSpreadsType,
    isFuturesType,
    isSpotType,
    isPerpetualFutureType,
    getStopLossValue,
    getNotionalValue,
    getRealizedCashflow,
    getAssignedMargin,
    getNotionalInUnderlying,
    getNotionalInSettling,
  };
};

export default useOpenPositionHelpers;
