export const getChange = (open, close, contract_type) => {
  if (open && close) {
    let change;
    if (contract_type === 'interest_rate_swaps') {
      change = (close - open).toFixed(2);
    } else {
      change = (((close - open) * 100.0) / open).toFixed(2);
    }
    return {
      price_movement: change > 0 ? 'up' : change < 0 ? 'down' : 'none',
      price_percentage_change: `${change}%`,
      change_value: change,
    };
  } else {
    return {
      price_movement: 'none',
      price_percentage_change: '-',
      change_value: 0,
    };
  }
};

export const calculatePercentage = (ltp, spot) =>
  (((ltp - spot) / spot) * 100).toFixed(2);

export const calculatePremium = (ltp, spot) => {
  const pct = calculatePercentage(ltp, spot);
  return {
    percent: isNaN(pct) || !isFinite(pct) ? '-' : `${pct}%`,
    movement: pct > 0 ? 'up' : pct < 0 ? 'down' : 'none',
  };
};

export const calculateFunding = pct => {
  return {
    percent: isNaN(pct) || !isFinite(pct) ? '-' : `${pct}%`,
    movement: pct > 0 ? 'up' : pct < 0 ? 'down' : 'none',
  };
};

export const disableScrolling = () => {
  document.body.classList.add('overflowYHidden');
};

export const enableScrolling = () => {
  document.body.classList.remove('overflowYHidden');
};
