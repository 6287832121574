import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import { FormInput } from 'UIKit';

export const HeaderDropdownWrapper = styled.div`
  display: flex;
  height: 100%;

  .top-bar {
    display: flex;
    background-color: ${props => props.theme.primaryBackground};

    @media ${device.down.md} {
      background-color: ${props => props.theme.primaryBackground};
      border-bottom: solid 0.8px ${props => props.theme.separator};
    }
  }

  .header-dropdown {
    background: transparent;
    position: relative;
    outline: none;
    padding: 16px 0px;
    z-index: 8;
    @media ${device.down.xs} {
      font-size: 12.1px;
      padding: 10px 0 10px 0;
    }
  }

  .product-tooltip {
    display: flex;
    align-items: center;
    border-left: 0.5px solid ${props => props.theme.separator};
    padding-left: 5px;

    @media ${device.up.sm} and ${device.down.md} {
      padding-left: 25px;
      margin-top: -5px;
    }

    @media ${device.up.md} {
      padding-left: 7px;
    }

    @media ${device.up.xxl} {
      padding-left: 15px;
    }

    @media ${device.down.xs} {
      display: none;
    }

    & .popover-base {
      width: 18px;
      height: 25px;
    }
  }

  .product-name {
    padding-right: 1px;
    font-size: 16px;
    text-transform: uppercase;

    .spot-icon {
      margin-left: 5px;
    }

    @media ${device.up.md} {
      font-size: 14px;
    }

    @media ${device.down.xl} {
      font-size: 12.1px;
    }

    @media ${device.down.xs} {
      padding-right: 15px;
      padding-left: 10px;
      font-size: 10px;
    }
  }

  .down {
    color: ${props => props.theme.negative}cc;
  }

  .up {
    color: ${props => props.theme.positive}cc;
  }

  .change-icon {
    margin: 0px 0px 0px 5px;
  }

  .product-info {
    display: flex;
    justify-content: space-around;
    margin-right: 0 !important;
    cursor: pointer;
    align-items: center;
    padding-left: 18px;

    @media ${device.down.xs} {
      padding-left: 10px;
      width: 100%;
    }

    .product-name {
      font-size: 14px;
      align-self: center;

      @media ${device.down.xs} {
        font-size: 12px;
      }
    }

    .drop-icon {
      align-self: center;
    }
  }

  .icons g {
    fill: ${props => props.theme.primaryText} !important;
  }

  .info-icon {
    margin-top: -5px;
  }

  .drop-icon {
    margin-top: -4px;

    @media ${device.down.md} {
      margin-top: -1px;
    }
  }

  .drop-icon rect {
    fill: ${props => props.theme.primaryTheme};
  }
`;

export const HeaderDropdownOptionsWrapper = styled.div`
  box-shadow: 0 4px 12px 0 ${props => props.theme.boxShadow}33;
  margin: 0px -9px;
  z-index: 11;
  background: ${props => props.theme.primaryBackground};
  position: absolute;
  left: -12vw;
  top: 45px;
  width: 700px;
  height: 580px;

  .options {
    padding-left: 10px;
  }

  @media (width: 768px) {
    height: 600px;
  }

  @media (min-width: 1920px) {
    left: -8vw;
  }

  @media (width: 1024px) {
    top: 62px;
  }

  @media ${device.down.md} and (min-width: 768px) {
    left: -16vw;
  }

  @media ${device.down.sm} {
    width: 100vw;
    height: 65vh;
    left: -16vw;
    .options {
      font-size: 12.1px;
    }

    && .options-header {
      font-size: 12.1px;
    }
  }

  .options-header {
    padding: 10px 0px;
    font-family: var(--fontRegular);
    font-size: 14px;
    color: ${props => props.theme.primaryText};
  }

  .tab-header {
    width: 50%;
    padding-left: 10px;

    @media ${device.down.xs} {
      width: 80%;
    }

    .tab.active {
      border-bottom: 2px solid ${props => props.theme.primaryTheme};
    }
  }

  .coins {
    margin-top: -2px;
  }

  .product-title {
    margin-bottom: 6px;
    margin-top: 20px;

    &:nth-child(1) {
      margin-top: 5px !important;
    }
  }

  .halted-effect {
    opacity: 0.3;
  }

  .favorite-selected-icon {
    margin-bottom: 2px;

    @media ${device.down.md} {
      margin-bottom: 0;
      width: 18px;
      height: 16px;
    }
  }

  .favorite-icon {
    margin-bottom: 3px;

    @media ${device.down.md} {
      margin-bottom: 0;
      width: 18px;
      height: 16px;
    }
  }
`;

export const AssetDropdownWrapper = styled.div`
  width: 42px;
  height: 19px;
  border-radius: 2px;
  border: solid 0.6px #414c57;
  cursor: pointer;
  position: relative;
  display: inline-block;
  left: 20px;
  top: 5px;
  background-color: rgba(48, 61, 78, 0.3);
  .dropdown-span {
    position: static;
    &:hover {
      .dropdown-icon {
        path {
          &:nth-child(2) {
            fill: ${props => props.theme.primaryTheme};
          }
        }
      }
    }
    .dropdown-title {
      font-size: 9px;
      line-height: 1.78;
      color: ${props => props.theme.secondaryText};
      padding: 0 22px 2px 4px;
    }
    .dropdown-icon {
      position: absolute;
      left: 28px;
      top: -2px;
      transform: rotate(180deg);
      width: 10px;
    }
  }
  .dropdown-container {
    width: 68px;
    height: 85px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    background-color: ${props => props.theme.primaryBackground};
    display: none;
    position: absolute;
    top: 25px;
    left: -30px;
    z-index: 30;
    border-radius: 2px;
    border: solid 0.6px #414c57;
    background-color: #1f2a35;
    .dropdown-item {
      width: 66px;
      height: 28px;
      font-size: 10px;
      color: ${props => props.theme.secondaryText};
      text-align: left;
      padding: 8px 5px 0 13px;
      &:nth-child(even) {
        background-color: #131c26;
      }
    }
    .active {
      color: ${props => props.theme.primaryTheme};
    }
    .even {
      background-color: ${props => props.theme.primaryBackground};
    }
    &.show {
      display: block;
    }
  }
`;

export const SearchWrapper = styled.div`
  padding: 12px 8px 5px 20px;
  width: 100%;
  background-color: ${props => props.theme.primaryBackground};
  display: flex;
  @media ${device.down.md} {
    padding: 0 10px;
  }

  .cross-icon {
    position: absolute;
    top: 42px;
    left: 308px;
    height: 30px;
    width: 40px;
    cursor: pointer;
    padding-left: 10px;

    @media ${device.down.md} {
      top: 48px;
      left: unset;
      right: 6px;
    }
  }
}
`;

export const HeaderInput = styled(FormInput)`
  width: 320px;
  height: 30px;
  border-radius: 2px;
  border: solid 0.6px #363e47;
  background-color: #101b24;
  text-align: left;
  font-size: 11px;
  padding: 6px 0 6px 28px;

  @media ${device.down.md} {
    width: 100%;
    height: 34px;
    margin-top: 10px;
    font-size: 12.1px;
  }
`;
export const IconWrapper = styled.div`
  position: absolute;
  height: 26px;
  width: 32px;
  display: flex;
  padding-top: 4px;
  svg {
    margin: auto;
    width: 12px;
    height: 12px;
  }

  @media ${device.down.md} {
    top: 96px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const NoProductFound = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 370px;
  line-height: 1.33;
  color: ${props => props.theme.secondaryText};
  font-size: 12.1px;

  @media ${device.down.sm} {
    width: 100%;
    height: 300px;
  }
`;
