const CURRENCY_STYLE = {
  INDIAN: 'INR',
  USD: 'USD',
} as const;

const SETTLING_ASSET = {
  BTC: 'BTC',
  ETH: 'ETH',
} as const;

const INR_AMOUNTS = {
  '1K': 1000,
  '1L': 100000,
  '1CR': 10000000,
} as const;

export { CURRENCY_STYLE, SETTLING_ASSET, INR_AMOUNTS };
