import { CURRENCY_TYPE } from 'constants/enums';

/**   @description function to add currency symbol to value */
const formatFiat = (
  currency: string = 'INR',
  amount: number | string = '0',
  locale: string = 'en',
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  }).format(Number(amount));
};

/**
 * Formats in terms of K,L,C
 *
 * @example
 * ```
 *  80000 -> ₹80K
 * ```
 */
const prettifyINR = (amount: number | string, options = {}) => {
  return Intl.NumberFormat('en-IN', {
    notation: 'compact',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: CURRENCY_TYPE.INR,
    ...options,
  })
    .format(Number(amount))
    .replace('T', 'K');
};

const prettifyUSD = (amount: number | string, options = {}) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: CURRENCY_TYPE.USD,
    ...options,
  }).format(Number(amount));
};

export { formatFiat, prettifyINR, prettifyUSD };
