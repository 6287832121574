/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { memo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { optionChainChartNavigation } from 'actions/optionChain';
import { TAB_CHANGE } from 'actionTypes/appDrawer';
import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import {
  BestBidValue,
  BidIVValue,
  optionTypeProp,
} from 'components/options_chain/helpers';
import OiIndicator from 'components/options_chain/OiIndicator';
import HaltedLabel from 'components/productHeader/HaltedLabel';
import { CHART_ORDERBOOK_SECTION_TABS } from 'components/trade/mobile/ChartOrderbookTab/ChartOrderbookTab.constants';
import { URL_SEARCH_PARAMS_KEYS } from 'constants/urlSearchParams';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { getProductUrlByContractType, isEmpty } from 'helpers/utils';
import { useWindowSize } from 'hooks';
import { useRouterSearchParams } from 'hooks/useRouterSearchParams';
import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import { basketOrderSwitchSelector } from 'selectors';

import styles from './styles.module.scss';

const Cell = ({ children }) => {
  const { cellClassName } = useOptionsChainData();
  return (
    <div
      className={`${styles.optionDetailBox} ${cellClassName}`}
      data-palette="Cell">{children}</div>
  );
};

const RenderCell = ({
  withIV = false,
  Component,
  valueClassName,
  onClose,
  IVComponent,
  isOiCell = false,
  isFavCel = false,
  showUnderlyingSymbol = false,
  emptySpace = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const searchParams = useRouterSearchParams();
  const { data, boxClassName, showCallOptions, showPutOptions, onColumnClick } =
    useOptionsChainData();
  const isBasketOrdersView = useSelector(basketOrderSwitchSelector);

  const handleContractClick = useCallback(
    (row, optionType) => {
      if (row && row[optionType]) {
        const url = getProductUrlByContractType(
          'options',
          row.underlying_asset_symbol,
          row[optionType].symbol
        );
        trackMixpanelEvent('Product Trading View Initiated - Trade', {
          source: 'all markets',
          product_name: row[optionType].symbol,
          product_type: 'options',
        });

        const currentURL = window.location.href;

        if (isMobile && currentURL.includes('/app/options_chain/markets')) {
          dispatch({
            // Switch to trade tab (for mobile).
            type: TAB_CHANGE,
            payload: APP_DRAWER_TAB_ID_MAPPING.TRADE,
          });
        }

        dispatch(optionChainChartNavigation(row[optionType]?.symbol));
        onClose?.();

        const orderTab =
          searchParams.get(URL_SEARCH_PARAMS_KEYS.CHART_ORDERBOOK_MOBILE.SECTION_TABS) ||
          CHART_ORDERBOOK_SECTION_TABS.CHART;

        if (!isMobile) {
          history.push(url);
        } else {
          history.push(`${url}?chart_orderbook_tab=${orderTab}`);
        }
      }
    },
    [dispatch, history, isMobile, onClose, searchParams]
  );

  const handleColumnClick = (optionsData, contractType) => {
    return isBasketOrdersView
      ? onColumnClick(optionsData[contractType])
      : handleContractClick(optionsData, contractType);
  };

  return (<>
    {data.map(props => {
      const isCallOptionEmpty = isEmpty(props?.callOptions);
      const isPutOptionEmpty = isEmpty(props?.putOptions);
      const callOptionsTradingStatus = !isCallOptionEmpty
        ? props?.callOptions?.trading_status
        : '';
      const putOptionsTradingStatus = !isPutOptionEmpty
        ? props?.putOptions.trading_status
        : '';

      // console.log('props', props);
      const productIdCall = props?.callOptions?.id || props?.callOptions?.product?.id;
      const productIdPut = props?.putOptions?.id || props?.putOptions?.product?.id;
      const { strike_price: strikePrice, backgroundClass } = props;

      return (
        (<Cell key={strikePrice}>
          <div
            style={showCallOptions ? null : { display: 'none' }}
            data-testid={`goto-${productIdCall}`}
            onClick={() => handleColumnClick(props, optionTypeProp.call)}
            className={`${boxClassName} relative ${backgroundClass}`}
          >
            {!isCallOptionEmpty && callOptionsTradingStatus === 'operational' ? (
              <>
                <div className={valueClassName} data-palette="RenderCell">
                  {isOiCell ? (
                    <OiIndicator
                      optionType={optionTypeProp.call}
                      productId={productIdCall}
                      expiry={props?.callOptions?.settlement_time}
                    >
                      <Component {...props?.callOptions} />
                    </OiIndicator>
                  ) : (
                    <Component
                      {...(!isFavCel
                        ? {
                            ...props?.callOptions,
                            showUnderlyingSymbol,
                          }
                        : {
                            ...{
                              callSymbol: props.callOptions.symbol,
                              callOptionId: productIdCall,
                              isVisible: true,
                              callClassName: styles.callFavorite,
                              activeClassName: styles.active,
                              addFallbackWhenEmpty: false,
                            },
                          })}
                    />
                  )}
                </div>
                {withIV && (
                  <div className={styles.ivValue} data-palette="RenderCell">
                    <IVComponent {...props?.callOptions} />
                  </div>
                )}
              </>
            ) : !isCallOptionEmpty &&
              callOptionsTradingStatus !== 'operational' &&
              Component === BestBidValue ? (
              <div className={styles['halted-label-text']} data-palette="RenderCell">
                <HaltedLabel selectedProduct={props?.callOptions} />
              </div>
            ) : isOiCell ? (
              <OiIndicator
                optionType={optionTypeProp.call}
                productId={productIdCall}
                expiry={props?.callOptions?.settlement_time}
              >
                -
              </OiIndicator>
            ) : isFavCel || emptySpace ? (
              ''
            ) : (
              '-'
            )}
          </div>
          <div
            style={showPutOptions ? null : { display: 'none' }}
            data-testid={`goto-${productIdPut}`}
            onClick={() => handleColumnClick(props, optionTypeProp.put)}
            className={`${boxClassName} relative ${backgroundClass}`}
          >
            {!isPutOptionEmpty && putOptionsTradingStatus === 'operational' ? (
              <>
                <div className={valueClassName} data-palette="RenderCell">
                  {isOiCell ? (
                    <OiIndicator
                      optionType={optionTypeProp.put}
                      productId={productIdPut}
                      expiry={props?.putOptions?.settlement_time}
                    >
                      <Component {...props.putOptions} />
                    </OiIndicator>
                  ) : (
                    <Component
                      {...(!isFavCel
                        ? {
                            ...props.putOptions,
                            showUnderlyingSymbol,
                          }
                        : {
                            ...{
                              putSymbol: props.putOptions.symbol,
                              putOptionId: productIdPut,
                              isVisible: true,
                              putClassName: styles.putFavorite,
                              activeClassName: styles.active,
                              addFallbackWhenEmpty: false,
                            },
                          })}
                    />
                  )}
                </div>
                {withIV && (
                  <div className={styles.ivValue} data-palette="RenderCell">
                    <IVComponent {...props?.putOptions} />
                  </div>
                )}
              </>
            ) : !isPutOptionEmpty &&
              putOptionsTradingStatus !== 'operational' &&
              IVComponent === BidIVValue ? (
              <div className={styles['halted-label-text']} data-palette="RenderCell">
                <HaltedLabel selectedProduct={props?.putOptions} />
              </div>
            ) : isOiCell ? (
              <OiIndicator
                optionType={optionTypeProp.put}
                productId={productIdPut}
                expiry={props?.putOptions?.settlement_time}
              >
                -
              </OiIndicator>
            ) : isFavCel || emptySpace ? (
              ''
            ) : (
              '-'
            )}
          </div>
        </Cell>)
      );
    })}
  </>);
};

RenderCell.propTypes = {
  withIV: PropTypes.bool,
  Component: PropTypes.elementType,
  valueClassName: PropTypes.string,
  onClose: PropTypes.func,
  IVComponent: PropTypes.elementType,
};

export default memo(RenderCell);
