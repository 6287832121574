/* eslint-disable import/no-unresolved */
import React from 'react';

import cn from 'classnames';
import { pure } from 'recompose';

import Render from 'components/render';
import subscribe from 'HOC/subscribe';
import { lastPriceChangeSelector } from 'selectors/tickerSelectors';

import styles from '../mobileBasketOrders.module.scss';

interface LastPriceChangeProps {
  symbol: string;
}

const LastPriceChangeComponent = pure(
  subscribe((props: LastPriceChangeProps) => {
    const { symbol } = props;
    const change24h: number = lastPriceChangeSelector({ symbol });

    return (
      <>
        <Render when={change24h}>
          <span
            className={cn(
              {
                [styles.positive]: change24h >= 0,
                [styles.negative]: change24h < 0,
              },
              styles.change24h
            )}
          >
            {`(${change24h}%)`}
          </span>
        </Render>
        <Render when={!change24h}>
          <span className={styles.change24h}>-</span>
        </Render>
      </>
    );
  })
);

export default LastPriceChangeComponent;
