/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable test-selectors/onChange */
/* eslint-disable react/prop-types */

import { useMemo, memo } from 'react';

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { TIME_FORMATS } from 'constants/timeformats';
import {
  dateFormat,
  endOf,
  timeNow,
  add,
  toUTC,
  getUTCOffset,
  subtract,
} from 'helpers/day';
import { dd_mm_hh, toSlug } from 'helpers/utils';
import { DropDownArrowUpIcon } from 'styles/assets/icons';
import { Space } from 'UIKit';

import styles from './index.module.scss';

const ListDropdown = ({ items, onChange, value, className }) => {
  const { t } = useTranslation(['trading']);
  const calculateExpiryDuration = expDate => {
    const endOfExpDay = endOf(toUTC(expDate), 'day');
    const offset = getUTCOffset();
    let endTime = add(endOfExpDay, 12, 'hours');
    if (offset <= 0) endTime = subtract(endOfExpDay, 12, 'hours');
    const expiryDuration = dd_mm_hh(toUTC(timeNow()), endTime);
    return expiryDuration;
  };

  const dateFormatter = date => {
    // return moment(date).format(TIME_FORMATS.DD_MMM_YY);
    return dateFormat(date, TIME_FORMATS.DD_MMM_YY);
  };

  const itemsWithExpiry = useMemo(
    () =>
      items.map(item => ({
        value: item,
        label: `${dateFormatter(item)} (${calculateExpiryDuration(item)})`,
        timeToExpiry: calculateExpiryDuration(item),
        formattedExpiry: dateFormatter(item),
      })),
    [items]
  );

  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton
          data-testid="expiry-list-dropdown"
          className={cx(styles.expListDropdown, 'naked', {
            [styles[className]]: className,
          })}
        >
          {dateFormatter(value)}{' '}
          <DropDownArrowUpIcon className={cx(styles.chevron, { [styles.open]: open })} />
        </MenuButton>
      )}
    >
      <MenuItem className={styles.expListPlaceholder}>{t('trading:expiryDate')}</MenuItem>
      {itemsWithExpiry?.map(item => (
        <MenuItem
          onClick={() => onChange(item)}
          className={cx(styles.expListItem, {
            [styles.active]: value === item.value,
          })}
          data-testid={toSlug(`exp-date-${item.value}`)}
          key={item.value}
        >
          <Space>
            <span>{item.formattedExpiry}</span>
            <time className={cx({ [styles.time]: value !== item.value })}>
              ({item.timeToExpiry})
            </time>
          </Space>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default memo(ListDropdown);
