import type { Dayjs } from 'dayjs';

enum LEADERBOARD_SCREENS {
  HOME = 'home',
  MAIN = 'main',
}

interface OptionsLeaderboardProps {
  screen?: LEADERBOARD_SCREENS;
  partnerCode?: string;
  product: string;
}

enum LEADERBOARD_EVENT_STATES {
  PRE = 'pre',
  ACTIVE = 'active',
  POST = 'post',
  NON_ACTIVE = 'post_result',
}

export { LEADERBOARD_EVENT_STATES, LEADERBOARD_SCREENS };

interface GetLeaderBoardEventState {
  ({
    endDate,
    resultEndDate,
    startDate,
    infoStartDate,
  }: {
    startDate: Dayjs;
    resultEndDate: Dayjs;
    endDate: Dayjs;
    infoStartDate?: Dayjs;
  }): LEADERBOARD_EVENT_STATES;
}

export type { OptionsLeaderboardProps, GetLeaderBoardEventState };
