/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/named */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/browser';
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY, from, interval, merge, of, Subject, timer } from 'rxjs';
import {
  buffer,
  catchError,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  skipUntil,
  // startWith,
  switchMap,
  takeUntil,
  share,
  tap,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';

import { l2PriceClubbingUpdate } from 'actions/l2Orderbook';
import { getOrderLeverage } from 'actions/orderbook';
// import { noOp } from 'actions/other';
import { getSettings } from 'actions/settings';
import { get24HrTicker, getProductList, getSpotIndices } from 'actions/trade';
import { getKycStatus, isAuthenticated } from 'actions/user';
import { getBalances } from 'actions/wallet';
import { LoginViaBiometricsActionTypes, LoginViaQrActionTypes } from 'actionTypes/auth';
import {
  SUBSCRIBE_BASKET_ORDER_ORDERBOOK,
  UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK,
} from 'actionTypes/basketorders';
import { L2OrderbookActionTypes } from 'actionTypes/l2Orderbook';
import { TAB_INACTIVE, TAB_REACTIVE } from 'actionTypes/other';
import {
  AUTHENTICATE_SOCKET,
  // AUTHENTICATE_SOCKET,
  CHECK_HEARTBEAT,
  CONNECT_SELECTED_PRODUCT_CHANNEL,
  CONNECT_SOCKET,
  DISABLE_HEARTBEAT,
  DISCONNECT_SOCKET,
  ENABLE_HEARTBEAT,
  SOCKET_CONNECTED,
  SUBSCRIBE_CONTRACT_ALL_RECENT_TRADES,
  // SUBSCRIBE_OPTION_CHAIN_MARK,
  // SUBSCRIBE_SELECTED_PRODUCT_DATA,
  // SUBSCRIBE_MARK,
  SUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  // RECONNECT_SOCKET,
  SUBSCRIBE_FUNDING,
  SUBSCRIBE_L2_ORDERBOOK,
  // UNSUBSCRIBE_PRIVATE,
  // SUBSCRIBE_ORDERS,
  // SUBSCRIBE_ORDERS,
  SUBSCRIBE_OHLC_CANDEL,
  SUBSCRIBE_ORDERS,
  // SUBSCRIBE_TRADING_NOTIFICATIONS,
  SUBSCRIBE_PUBLIC_CHANNELS,
  // SUBSCRIBE_MARK,
  SUBSCRIBE_RECENT_TRADE,
  SUBSCRIBE_SPOT,
  SUBSCRIBE_TICKER,
  // ACTIVATE_REST_MODE,
  SUBSCRIBE_TRADING_NOTIFICATIONS,
  UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_CONTRACT_ALL_RECENT_TRADES,
  UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  UNSUBSCRIBE_FUNDING,
  UNSUBSCRIBE_L2_ORDERBOOK,
  UNSUBSCRIBE_OB_RT,
  UNSUBSCRIBE_OHLC_CANDEL,
  UNSUBSCRIBE_PRIVATE,
  UNSUBSCRIBE_TICKER,
} from 'actionTypes/socket';
import { getSubscriptionValue } from 'components/chart/helper';
import {
  ENABLED_WALLETS,
  ASSET_SYMBOL,
  IS_INDIAN_EXCHANGE,
  SOCKET_CONNECTION_TIMEOUT,
  SOCKET_RECONNECTION_DELAY,
  SOCKET_URL,
  TAB_INACTIVE_DELAY,
} from 'constants/constants';
import { STORAGE_KEYS } from 'constants/enums';
import { isEmpty } from 'helpers';
import {
  pipe,
  of as Rof,
  flatten,
  forEach,
  map as Rmap,
  values,
  // isEmpty,
  contains,
  prop,
} from 'helpers/ramda';
import { isNotEmpty, isTruthy } from 'ramdax';
import { socketActiveSelector } from 'selectors/socketSelectors';
import { ContractType } from 'types/IProducts';
import { updateTradingViewData } from 'variableStore/actions/tradingview';

import {
  authenticateSocket,
  checkHeartbeat,
  // deactivateRestMode,
  connectSelectedProductChannels,
  // subscribeSpot,
  // subscribeTickersub
  // unsubscribeAll,
  // subscribeTradingNotifications,
  // subscribeOrders,
  // subscribeOHLCCandle,
  connectSocket,
  // reconnectSocketTabReactive,
  // reconnectSocket,
  disconnectSocket,
  enableHeartbeat,
  // activateRestMode,
  socketConnected,
  subscribeFunding,
  subscribeL2Orderbook,
  // unsubscribeOHLCCandle,
  subscribeOrders,
  subscribePublicChannels,
  subscribeRecentTrade,
  subscribeSelectedProductMarkData,
  subscribeSelecteProductSpot,
  subscribeSpot,
  // subscribeTicker,
  subscribeTradingNotifications,
  // subscribeOHLCCandle,
  subscribeWalletSpot,
  unsubscribeAll,
  unsubscribeFunding,
  unsubscribeMark,
  unsubscribeObRt,
  unsubscribeSelecteProductSpot,
} from '../actions/socket';
import USER, { UNAUTHORIZED } from '../actionTypes/user';
import { getComputedOrderbookFromSocket } from '../reduxInWorker/computeOrderbook';
import {
  allOpenPositionsSelector,
  basketOrderSwitchSelector,
  heartbeatTSSelector,
  previousSelectedProductSelector,
  selectedProductSelector,
  selectedProductState,
} from '../selectors';
import {
  getRecentTrades,
  setRecentTradeLoader,
  updateAllTickers,
  updateBasketOrderOrderbook,
  updateEasyOptionsOrderbook,
  updateHeartbeatTS,
  updateIrRate,
  updateOHLC,
  updateRecentTrades,
  updateSelectedProductFundingData,
} from '../variableStore/actions';
import { notificationsActionsMap } from './notifications';
import { orderActionsMap } from './orderActions';

// let isTabInactive = false;
let onOpenSubject = new Subject();
let onCloseSubject = new Subject();

const createWebSocketSubject = () => {
  onOpenSubject = new Subject();
  onCloseSubject = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  wsSubject = webSocket({
    url: SOCKET_URL,
    openObserver: onOpenSubject,
    closeObserver: onCloseSubject,
  });
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return wsSubject;
};

// eslint-disable-next-line no-var
export var wsSubject = createWebSocketSubject();

export const connectEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECT_SOCKET),
    filter(() => {
      const socketActive = socketActiveSelector(state$.value);
      return socketActive === false || socketActive === null; // try connection only if socket connection is inactive
    }),
    switchMap(() =>
      createWebSocketSubject().pipe(
        map(() => { }), // connects to socket and map messages
        catchError(e => {
          console.error('DEBUG , error in socket connection', e, e.type);
          return EMPTY;
          // cannot disconnect socket on errors here . Handling is done by heartbeart or ping pong
          // return of({ type: DISCONNECT_SOCKET, payload: { retry: true } });
        })
      )
    )
  );

export const connectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECT_SOCKET),
    filter(() => {
      const socketActive = socketActiveSelector(state$.value);
      return socketActive === false || socketActive === null;
    }),
    switchMap(() =>
      onOpenSubject.pipe(
        map(() => {
          return { type: SOCKET_CONNECTED, payload: true };
        })
      )
    ),
    catchError(err => {
      console.error('DEBUG disconnection epic', err);
      return EMPTY;
    })
  );

export const verifySocketConnectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECT_SOCKET),
    switchMap(() => {
      return timer(SOCKET_RECONNECTION_DELAY).pipe(
        map(() => {
          const socketActive = socketActiveSelector(state$.value);
          if (socketActive) {
            return () => { };
          }
          return disconnectSocket({ retry: true });
        }),
        takeUntil(action$.ofType(DISCONNECT_SOCKET, ENABLE_HEARTBEAT))
      );
    })
  );

// export const disconnectedEpic = (action$, state$) =>
//   state$.pipe(
//     map(state => socketActiveSelector(state)),
//     distinctUntilChanged(), //will run only when value changes from true to false or vice versa
//     mergeMap(value => {
//       console.log('DEBUG disconnectedEpic socket', value);
//       switch (value) {
//         case true:
//           return of(deactivateRestMode());
//         case false:
//           return of(activateRestMode());
//         default:
//           return EMPTY;
//       }
//     })
//   );

// can monitor closeSubject for disconnections if required
// export const closeSubjectEpic = action$ =>
//   action$.pipe(
//     ofType(CONNECT_SOCKET),
//     switchMap(action =>
//       onCloseSubject.pipe(
//         map(() => {
//           console.log("DEBUG socket connected , listening to closeSubject")
//           return EMPTY;
//         }),
//         catchError(err => {
//           console.log('DEBUG closeSubjectEpic epic', err);
//           return EMPTY;
//         })
//       )
//     )
//   );

export const closeWebsocketConnectionEpic = action$ =>
  action$.pipe(
    ofType(DISCONNECT_SOCKET),
    mergeMap(action => {
      onCloseSubject.complete();
      wsSubject.complete();
      if (action.payload.retry === true) {
        return of(socketConnected(false), unsubscribeAll(), connectSocket());
      }
      return of(socketConnected(false), unsubscribeAll());
    }),
    catchError(err => {
      console.error('DEBUG close socket connection', err);
      return EMPTY;
    })
  );

export const initialiseSocketSubscriptionEpic = (action$, state$) =>
  action$.pipe(
    ofType(SOCKET_CONNECTED),
    map(() => socketActiveSelector(state$.value)),
    distinctUntilChanged(),
    filter(value => value === true),
    mergeMap(() => {
      return of(
        enableHeartbeat(),
        subscribePublicChannels(),
        connectSelectedProductChannels(),
        authenticateSocket(),
        checkHeartbeat()
      );
    }),
    catchError(err => {
      console.error('DEBUG', err);
      return EMPTY;
    })
  );

// this epic looks exactly like selectedProductEpic but this only runs on reconnection and selectedProductEpic runs on contract switching
// cant mix both as distinctUntilChanged will not work in case connectSelectedProductEpic
export const connectSelectedProductEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECT_SELECTED_PRODUCT_CHANNEL),
    map(() => selectedProductState(state$.value)),
    filter(isTruthy),
    mergeMap(product => {
      const { symbol, contract_type, id } = product;

      const actions = [
        unsubscribeObRt(),
        // updateOrderbook(),
        setRecentTradeLoader(),
        // subscribeL2Orderbook(symbol),
        l2PriceClubbingUpdate({
          selectedPriceClubbingValue: product?.ui_config?.price_clubbing_values?.[0],
          selectedPriceClubbingValueIndex: 0,
        }),
        subscribeRecentTrade(symbol),
      ];

      if (isAuthenticated(state$.value.user)) {
        // basket order check while subscribing orderbook .
        const isBasketView = basketOrderSwitchSelector(state$.value);
        switch (contract_type) {
          case ContractType.PerpetualFutures:
          case ContractType.Futures:
          case ContractType.MoveOptions:
            actions.push(subscribeL2Orderbook(symbol));
            break;
          case ContractType.CallOptions:
          case ContractType.PutOptions: {
            // checking basket order view because we do not want to subscribe more than 1 orderbook.
            const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
            if (mediaQueryList.matches || !isBasketView) {
              // no need to check for basket order view in mobile
              actions.push(subscribeL2Orderbook(symbol));
            }
            break;
          }
          default:
            actions.push(subscribeL2Orderbook(symbol));
            break;
        }
      } else {
        actions.push(subscribeL2Orderbook(symbol));
      }

      if (contract_type === ContractType.InterestRateSwaps) {
        actions.push(subscribeSpot());
      }

      const previousSelectedProduct = previousSelectedProductSelector(state$.value);
      const enabledSpotWalletIndices = enabledWalletSpotSymbols();

      const allPositions = allOpenPositionsSelector(state$.value);
      const hasPosition = allPositions.reduce(
        (acc, position) =>
          position.product.symbol === previousSelectedProduct.symbol
            ? acc || true
            : acc || false,
        false
      );

      if (
        previousSelectedProduct?.spot_index?.symbol &&
        !enabledSpotWalletIndices.includes(
          previousSelectedProduct?.spot_index?.symbol && !hasPosition
        )
      ) {
        actions.push(
          unsubscribeSelecteProductSpot(previousSelectedProduct?.spot_index?.symbol)
        );
      }

      if (isNotEmpty(previousSelectedProduct) && !hasPosition) {
        actions.push(unsubscribeMark());
      }

      if (contract_type !== 'spot') {
        if (isAuthenticated(state$.value.user)) {
          actions.push(getOrderLeverage(id));
        }

        actions.push(
          unsubscribeFunding(),
          subscribeFunding(symbol),
          subscribeSelectedProductMarkData(symbol),
          subscribeSelecteProductSpot(product.spot_index.symbol)
        );
      }

      return from([...actions]).pipe(catchError(err => console.log('DEBUG:\n', err)));
    })
  );

// Subscribe to heartbeat. If no message is recieved within 35 seconds, reconnect.
const subscribeHeartbeatChannel = () => {
  return wsSubject.multiplex(
    () => ({
      type: 'enable_heartbeat',
    }),
    () => ({
      type: 'disable_heartbeat',
    }),
    message => true
  );
};

export const subscribeHeartbeatEpic = (action$, state$) =>
  action$.pipe(
    ofType(ENABLE_HEARTBEAT),
    mergeMap(() =>
      subscribeHeartbeatChannel().pipe(
        takeUntil(action$.ofType(DISABLE_HEARTBEAT, UNSUBSCRIBE_ALL)),
        catchError((err, caught) => {
          console.error('HEARTBEAT', err);
          return EMPTY;
        })
      )
    ),
    map(updateHeartbeatTS),
    catchError(err => {
      console.error('DEBUG enable heartbeat :', err);
      // logToSentry(err);
      return EMPTY;
    })
  );

// Heartbeat check.
export const heartbeatCheck = (action$, state$) =>
  action$.pipe(
    ofType(CHECK_HEARTBEAT),
    delay(SOCKET_RECONNECTION_DELAY),
    map(() => heartbeatTSSelector()),
    map(hb => {
      return new Date().getTime() - hb <= SOCKET_CONNECTION_TIMEOUT;
    }),
    mergeMap(isOnTime => {
      if (!isOnTime && socketActiveSelector(state$.value)) {
        return of(
          socketConnected(false),
          unsubscribeAll(),
          disconnectSocket({ retry: true })
        );
      }
      return of(checkHeartbeat());
    })
  );

const getPublicChannelList = () => {
  return [
    {
      name: 'v2/product_updates',
    },
    {
      name: 'announcements',
    },
  ];
};

const publicChannel = () =>
  wsSubject.multiplex(
    () => ({
      type: 'subscribe',
      payload: {
        channels: getPublicChannelList(),
      },
    }),
    () => ({
      type: 'unsubscribe',
      payload: {
        channels: getPublicChannelList(),
      },
    }),
    message => message.type === 'v2/product_updates' || message.type === 'announcements'
  );

export const publicChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_PUBLIC_CHANNELS),
    mergeMap(() =>
      publicChannel(state$.value).pipe(
        catchError(err => {
          console.error('Public', err);
          return EMPTY;
        }),
        takeUntil(action$.ofType(UNSUBSCRIBE_ALL))
      )
    ),
    map(message => dispatch => {
      // returning redux-thunk action
      const postActions = notificationsActionsMap[message.type];
      if (typeof postActions === 'function') {
        // postActions might return an array, hence flattening it later.
        pipe(
          () => postActions(message, state$.value),
          Rof, // [action1] || [[action2, action3]]
          flatten, // [action1, action2, action3]
          forEach(dispatch)
        )();
      }
    }),
    catchError(err => {
      // logToSentry(err);
      return EMPTY;
    })
  );

const tickerChannel = symbols =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'v2/ticker', symbols),
    () => payloadGenerator('unsubscribe', 'v2/ticker', symbols),
    message => message.type === 'v2/ticker'
  );

export const subscribeTickerChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_TICKER),
    // map(() => productSymbolSelector(state$.value)),
    mergeMap(action => {
      const { payload } = action;
      return tickerChannel(payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_TICKER, UNSUBSCRIBE_ALL)),
        buffer(intervalEvents),
        catchError(err => {
          console.log('Ticker', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    map(updateAllTickers),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    }),
    ignoreElements()
  );
// const subscribeToSubject = sub =>
//   sub.subscribe(
//     msg => {
//       reduxStore.dispatch(updateHeartbeatTS());
//       !isSocketConnected && reduxStore.dispatch(socketConnected());
//       isSocketConnected = true;
//     }, // Called whenever there is a message from the server.
//     err => {
//       isSocketConnected = false;
//       // console.log("DEBUG: close sokcet error ", err);
//       // !isTabInactive && reduxStore.dispatch(reconnectSocket());
//     }, // Called if at any point WebSocket API signals some kind of error.
//     () => {
//       // console.log("DEBUG: close socket success ");
//       isSocketConnected = false;
//       // console.log("DEBUG: socket connection was closed", new Date(), !isTabInactive ? 'reconnect from here' : 'not connecting');
//       // !isTabInactive && reduxStore.dispatch(reconnectSocket());
//     } // Called when connection is closed (for whatever reason).
//   );

// subscribeToSubject(wsSubject);

export const productSymbolSelector = state => {
  const { products } = state.trade;
  return Rmap(product => product.symbol, values(products));
};

const getIRIndices = state => {
  const { products } = state.trade;
  const irsProducts = values(products).filter(
    product => product.contract_type === 'interest_rate_swaps'
  );
  return Rmap(product => product.product_specs.floating_ir_index, irsProducts);
};

// const PerpetualContractsSymbolSelector = state => {
//   const products = R.values(state.trade.products);

//   return R.pipe(
//     filterPerpetualFutures,
//     R.pluck('symbol')
//   )(products);
// };

// const filterPerpetualFutures = products =>
//   R.filter(product => product.contract_type === 'perpetual_futures', products);

const intervalEvents = interval(1000);

const payloadGenerator = (type, name, symbols) => {
  return {
    type,
    payload: {
      channels: [
        {
          name,
          symbols,
        },
      ],
    },
  };
};

const orderbookChannel = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'l2_orderbook', [symbol]),
    () => payloadGenerator('unsubscribe', 'l2_orderbook', [symbol]),
    message => message.type === 'l2_orderbook' && message.symbol === symbol
  );

// https://docs.delta.exchange/?shell#ohlc-candles
// Subscription payload sample { "name": "candlestick_1m", "symbols": ["BTCUSD_P"] }
/*
This channel provides ohlc updated for given time resolution Subscribe to ohlc_candle channel for updates

Subscription payload sample { "name": "candlestick_1m", "symbols": ["BTCUSD_P"] }

name = "candlestick_" + resolution resolutions = ["1m","3m","5m","15m","30m","1h","2h","4h","6h","12h","1d","1w","2w","30d"] symbols= product symbol

Sample response { "candle_start_time": 1596015240000000, "close": 9223, "high": 9228, "low": 9220, "open": 9221, "resolution": "1m", "symbol": "BTCUSD_P", "timestamp": 1596015289339699, "type": "candlestick_1m", "volume": 1.2 }

 */
const ohlcCandleChannelSubscribe = (symbol, type) => {
  // symbol: "BTCUSDT"
  // type: "candlestick_1w"
  let typeValue;

  if (type) {
    if (type.indexOf('H') > -1) {
      typeValue = type.replace(/H/g, 'h');
    } else if (type.indexOf('W') > -1) {
      typeValue = type.replace(/W/g, 'w');
    } else if (type.indexOf('D') > -1) {
      typeValue = type.replace(/D/g, 'd');
    } else {
      typeValue = type;
    }
  }
  const subscribeType = `candlestick_${typeValue}`;

  return wsSubject.multiplex(
    () => payloadGenerator('subscribe', subscribeType, [symbol]),
    () => {},
    message => message.type === subscribeType
  );
};

const currentTradingViewResolution = localStorage.getItem(
  STORAGE_KEYS.TRADINGVIEW_ACTIVE_RESOLUTION
);

const unsubscribeOHLCCandle = (symbol, type) => {
  const typeValue = type.replace(/H/g, 'h');
  const subscribeType = `candlestick_${typeValue}`;
  return wsSubject.multiplex(
    () => payloadGenerator('unsubscribe', subscribeType, [symbol]),
    () => {},
    message => message.type === subscribeType
  );
};

export const unsubscribeOhlcCandleEpic = (action$, state$) =>
  action$.pipe(
    ofType(UNSUBSCRIBE_OHLC_CANDEL),
    mergeMap(action => {
      const {
        payload: { subscribeUID },
      } = action;
      const [symbol, resolution] = [...new Set(subscribeUID.split('_#_'))]; // subscribeUID format is symbol_#_symbol_#_resolution
      const subscriptionValue = getSubscriptionValue(resolution);
      return unsubscribeOHLCCandle(symbol, subscriptionValue).pipe(
        catchError(_ => EMPTY)
      );
    })
  );

export const subscribeOHLCEpic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_OHLC_CANDEL),
    mergeMap(action => {
      const currentResolution = action.payload.resolution;
      // ? JSON.parse(actio)
      // : { value: 60 };
      const resolution = getSubscriptionValue(currentResolution);

      const { symbol } = action.payload;
      const type = resolution;
      return ohlcCandleChannelSubscribe(symbol, type).pipe(catchError(_ => EMPTY));
    }),
    map(updateTradingViewData),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

// export const unsubscribeOHLCEpic = action$ =>
//   action$.pipe(
//     ofType(UNSUBSCRIBE_OHLC_CANDEL),
//     mergeMap(action => {
//       const { symbol, type } = action.payload;
//       return unsubscribeOHLCCandle(symbol, type).pipe(catchError(_ => EMPTY));
//     })
//   );

export const subscribeBasketOrderOrderbookEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_BASKET_ORDER_ORDERBOOK),
    mergeMap(action => {
      return orderbookChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.log('Basket_Orderbook', err);
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    map(updateBasketOrderOrderbook),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

// const postSocketAuthActions = () => [subscribeTradingNotifications(),subscribeOrders()]
// const l2updatesChannel = symbol =>
//   wsSubject.multiplex(
//     () => payloadGenerator('subscribe', 'l2_updates', [symbol]),
//     () => payloadGenerator('unsubscribe', 'l2_updates', [symbol]),
//     message => message.type === 'l2_updates' && message.symbol === symbol
//   );

// // sharing public socket with auth
// export const subscribeL2UpdatesEpic = (action$, state$) =>
//   action$.pipe(
//     ofType(SUBSCRIBE_L2_ORDERBOOK),
//     mergeMap(action => {
//       return l2updatesChannel(action.payload).pipe(
//         takeUntil(
//           action$.ofType(UNSUBSCRIBE_OB_RT, UNSUBSCRIBE_L2_ORDERBOOK, UNSUBSCRIBE_ALL)
//         ),
//         catchError(err => {
//           console.log('L2_Updates', err);
//           logToSentry(err);
//           return EMPTY;
//         })
//       );
//     }),
//     map(message => dispatch => {
//       const actionTypeFun = {
//         snapshot: l2snapshot,
//         update: l2update,
//         error: l2SnapshotError,
//       };
//       pipe(
//         () => actionTypeFun[message.action](message, state$.value),
//         Rof, // [action1] || [[action2, action3]]
//         flatten, // [action1, action2, action3]
//         forEach(dispatch)
//       )();
//     }),
//     catchError(err => {
//       logToSentry(err);
//       return EMPTY;
//     })
//   );

export const subscribeEasyOptionsL2OrderbookEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_EASY_OPTIONS_ORDERBOOK),
    mergeMap(action => {
      return orderbookChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.log('L2_Orderbook', err);
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    filter(data => {
      return !isEmpty(data.sell);
    }),
    distinctUntilKeyChanged('last_updated_at'),
    map(updateEasyOptionsOrderbook),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

const recentTradeChannel = symbols =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'all_trades', symbols),
    () => payloadGenerator('unsubscribe', 'all_trades', symbols),
    message => message.type === 'all_trades' || message.type === 'all_trades_snapshot'
  );

export const subscribeRecentTradeEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_RECENT_TRADE),
    mergeMap(action => {
      return recentTradeChannel([action.payload]).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_OB_RT, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.log('Recent', err);
          return EMPTY;
        })
      );
    }),
    map(message => {
      const selectedProduct = selectedProductSelector(state$.value);
      if (message.type === 'all_trades_snapshot') {
        return getRecentTrades(message, selectedProduct?.symbol);
      }
      return updateRecentTrades(message, selectedProduct?.symbol);
    }),
    // map(message => dispatch => {
    //   const actionTypeFun = {
    //     all_trades: updateRecentTrades,
    //     all_trades_snapshot: getRecentTrades,
    //   };
    //   pipe(
    //     () => actionTypeFun[message.type](message, state$.value),
    //     Rof, // [action1] || [[action2, action3]]
    //     flatten, // [action1, action2, action3]
    //     forEach(dispatch)
    //   )();
    // }),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

export const subscribeContractAllRecentTradesEpic = (action$, state$) => {
  return action$.pipe(
    skipUntil(action$.ofType(SOCKET_CONNECTED)),
    filter(() => socketActiveSelector(state$.value)),
    ofType(SUBSCRIBE_CONTRACT_ALL_RECENT_TRADES),
    switchMap(action => {
      const { payload } = action;
      if (
        typeof payload !== 'object' ||
        !Array.isArray(payload.symbols) ||
        !payload.listener
      ) {
        return EMPTY;
      }
      const { symbols, listener } = payload;
      return recentTradeChannel(symbols).pipe(
        takeUntil(
          action$.pipe(ofType(UNSUBSCRIBE_ALL, UNSUBSCRIBE_CONTRACT_ALL_RECENT_TRADES))
        ),
        map(message => {
          if ('next' in listener && typeof listener.next === 'function') {
            listener.next(message);
          } else if (typeof listener === 'function') {
            listener(message);
          }
        })
      );
    }),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

const iRchannel = symbols =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'interest_rate_indices', symbols),
    () => payloadGenerator('unsubscribe', 'interest_rate_indices', symbols),
    message => message.type === 'interest_rate_indices'
  );

export const subscribeIrIndicesChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_SPOT),
    map(() => getIRIndices(state$.value)),
    mergeMap(symbols =>
      iRchannel(symbols).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.log('IR', err);
          return EMPTY;
          // if (!isTabInactive) {
          //   return caught;
          // }
        })
      )
    ),
    filter(isNotEmpty),
    map(updateIrRate),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    }),
    ignoreElements()
  );

const fundingChannel = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'funding_rate', [symbol]),
    () => payloadGenerator('unsubscribe', 'funding_rate', [symbol]),
    message => message.type === 'funding_rate'
  );

export const subscribeFundingChannelEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_FUNDING),
    mergeMap(action =>
      fundingChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_FUNDING, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.log('Funding', err);
          return EMPTY;
        })
      )
    ),
    filter(data => !isEmpty(data)),
    map(updateSelectedProductFundingData),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    }),
    ignoreElements()
  );

const authChannel = token =>
  wsSubject.multiplex(
    () => ({
      type: 'authv2',
      payload: {
        token,
      },
    }),
    () => ({
      type: 'unauth',
    }),
    message => message.message === 'Authenticated'
  );

export const tokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      LoginViaQrActionTypes.SET_AUTHORIZED_USER,
      LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER,
      USER.LOGIN.SUCCESS,
      AUTHENTICATE_SOCKET,
      USER.LOGINMFA.SUCCESS,
      USER.LOGIN_EMAIL_VERIFICATION.SUCCESS
    ),
    map(() => {
      return prop('user', state$.value);
    }),
    pluck('token'),
    filter(token => !!token),
    mergeMap(token => {
      return authChannel(token).pipe(
        takeUntil(
          action$.ofType(
            USER.LOGOUT.SUCCESS,
            UNAUTHORIZED,
            UNSUBSCRIBE_PRIVATE,
            UNSUBSCRIBE_ALL
          )
        ),
        catchError(err => {
          console.error('AUTH', err);
          return EMPTY;
        })
      );
    }),
    mergeMap(payload => {
      if (payload.type === 'success' && payload.message === 'Authenticated') {
        return of(
          subscribeOrders(),
          subscribeTradingNotifications(),
          subscribeWalletSpot()
        );
      }
      return EMPTY; // () => {}
    }),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

const tradingNotificationsTypes = ['pnl', 'self_trade', 'auto_topup', 'user_trades'];

const tradingNotificationsChannels = symbols => [
  {
    name: 'trading_notifications',
    symbols,
  },
  {
    name: 'user_trades',
    symbols,
  },
];

const subscribeTradeNotification = () =>
  wsSubject.multiplex(
    () => ({
      type: 'subscribe',
      payload: {
        channels: tradingNotificationsChannels(['all']),
      },
    }),
    () => ({}),
    message => contains(message.type, tradingNotificationsTypes)
  );

export const subscribeTradingNotificationsEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_TRADING_NOTIFICATIONS),
    mergeMap(() => {
      return subscribeTradeNotification().pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_PRIVATE, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.error('TRADE NOTIFICATIONS', err);
          return EMPTY;
        })
      );
    }),
    map(message => dispatch => {
      // returning redux-thunk action
      const postActions = notificationsActionsMap[message.type];
      if (typeof postActions === 'function') {
        // postActions might return an array, hence flattening it later.
        pipe(
          () => postActions(message, state$.value),
          Rof, // [action1] || [[action2, action3]]
          flatten, // [action1, action2, action3]
          forEach(dispatch)
        )();
      }
    }),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

const orderChannelNameList = [
  'positions',
  'margins',
  'orders',
  'portfolio_margins',
  'cross_margin',
  'multi_collateral',
  'user_product',
];

const privateDataChannelList = () => {
  return [
    {
      name: 'positions',
      symbols: ['all'],
    },
    {
      name: 'orders',
      symbols: ['all'],
    },
    {
      name: 'margins',
    },
    {
      name: 'portfolio_margins',
    },
    {
      name: 'cross_margin',
    },
    {
      name: 'multi_collateral',
    },
    {
      name: 'user_product',
    },
  ];
};

const privateChannels = () =>
  wsSubject.multiplex(
    () => ({
      type: 'subscribe',
      payload: {
        channels: privateDataChannelList(),
      },
    }),
    () => ({
      type: 'unsubscribe',
      payload: {
        channels: privateDataChannelList(),
      },
    }),
    message => contains(message.type, orderChannelNameList)
  );

export const subscribeOrdersEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_ORDERS),
    mergeMap(() => {
      return privateChannels().pipe(
        takeUntil(
          action$.ofType(
            USER.LOGOUT.SUCCESS,
            UNAUTHORIZED,
            UNSUBSCRIBE_PRIVATE,
            UNSUBSCRIBE_ALL
          )
        ),
        catchError(_ => EMPTY)
      );
    }),
    map(message => dispatch => {
      // Ideally it's supposed to go to catchError block. Not sure what the issue is. So, had to use try catch here
      try {
        const postActions = orderActionsMap[message.type];
        if (typeof postActions === 'function') {
          // postActions might return an array, hence flattening it later.
          pipe(
            () => postActions(message, state$.value),
            Rof, // [action1] || [[action2, action3]]
            flatten, // [action1, action2, action3]
            forEach(dispatch)
          )();
        }
      } catch (err) {
        logToSentry(err);
      }
    })
  );

export const disconnectSocketOnTabInactive = (action$, state$) =>
  action$.pipe(
    ofType(TAB_INACTIVE),
    switchMap(() => {
      return timer(TAB_INACTIVE_DELAY).pipe(
        map(() => {
          return disconnectSocket({ retry: false });
        }),
        takeUntil(action$.ofType(DISCONNECT_SOCKET, TAB_REACTIVE))
      );
    })
  );

export const reconnectSocketOnTabReactive = (action$, state$) =>
  action$.pipe(
    ofType(TAB_REACTIVE),
    map(() => socketActiveSelector(state$.value)),
    filter(val => {
      return val === false || val === null;
    }), // if socket is connected no need to do anything
    mergeMap(val => {
      return isAuthenticated(state$.value.user)
        ? of(
          getProductList(),
          getSpotIndices(),
          getSettings(),
          getBalances(),
          getKycStatus(),
          connectSocket()
        )
        : of(getProductList(), getSettings(), connectSocket(), getSpotIndices());
    })
  );

export const logToSentry = err =>
  Sentry.withScope(scope => {
    scope.setExtras(err);
    Sentry.captureException(err);
  });

// L2 orderbook
const l2OrderbookChannel = symbol =>
  wsSubject
    .multiplex(
      () => payloadGenerator('subscribe', 'l2_orderbook', [symbol]),
      () => {
        activeSubscriptions.clear();
        return payloadGenerator('unsubscribe', 'l2_orderbook', [symbol]);
      },
      message => message.type === 'l2_orderbook' && message.symbol === symbol
    )
    .pipe(
      share(), // Share the subscription
      catchError(err => {
        console.error('coming in Error in l2OrderbookChannel:', err);
        return EMPTY;
      })
    );
const activeSubscriptions = new Set();

const unsubscribeL2Orderbook = (action$, state$) =>
  action$.pipe(
    ofType(UNSUBSCRIBE_L2_ORDERBOOK),
    filter(() => activeSubscriptions.size > 0),
    map(() => {
      activeSubscriptions.clear();
    })
  );

export const subscribeL2OrderbookEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_L2_ORDERBOOK),
    mergeMap(action => {
      const symbol = action.payload;

      // Check if already subscribed
      if (activeSubscriptions.has(symbol)) {
        return EMPTY;
      }

      // Mark as subscribed
      activeSubscriptions.add(symbol);

      return l2OrderbookChannel(symbol).pipe(
        takeUntil(
          action$.ofType(UNSUBSCRIBE_OB_RT, UNSUBSCRIBE_L2_ORDERBOOK, UNSUBSCRIBE_ALL)
        )
      );
    }),
    map(message => getComputedOrderbookFromSocket(message, state$)),
    map(payload => ({ type: L2OrderbookActionTypes.SNAPSHOT, payload })),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );

export const enabledWalletSpotSymbols = () =>
  ENABLED_WALLETS.filter(symbol => symbol !== ASSET_SYMBOL).map(symbol =>
    symbol === 'BTC'
      ? IS_INDIAN_EXCHANGE
        ? '.DEXBTUSD'
        : '.DEXBTUSDT'
      : IS_INDIAN_EXCHANGE
        ? `.DE${symbol}USD`
        : `.DE${symbol}USDT`
  );

export default combineEpics(
  connectEpic,
  connectedEpic,
  verifySocketConnectionEpic,
  // disconnectedEpic,
  // closeSubjectEpic,
  closeWebsocketConnectionEpic,
  initialiseSocketSubscriptionEpic,
  connectSelectedProductEpic,
  subscribeHeartbeatEpic,
  heartbeatCheck,
  publicChannelEpic,
  subscribeTickerChannelEpic,
  subscribeOHLCEpic,
  unsubscribeOhlcCandleEpic,
  // subscribeL2UpdatesEpic,
  subscribeRecentTradeEpic,
  subscribeIrIndicesChannelEpic,
  subscribeFundingChannelEpic,
  tokenEpic,
  subscribeTradingNotificationsEpic,
  subscribeOrdersEpic,
  disconnectSocketOnTabInactive,
  reconnectSocketOnTabReactive,
  subscribeEasyOptionsL2OrderbookEpic,
  subscribeBasketOrderOrderbookEpic,
  subscribeContractAllRecentTradesEpic,
  subscribeL2OrderbookEpic,
  unsubscribeL2Orderbook
);
