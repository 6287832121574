import { createSelector } from 'reselect';

import { getUnixTimestamp } from 'helpers/day';
import { prop } from 'helpers/ramda';
import IState from 'types/Istore';

import { productsListSelector } from './tradeSelectors';

export const optionsChainSelector = (state: IState) => state.optionsChain;

export const optionsChainTogglePriceSelector = createSelector(
  [optionsChainSelector],
  prop('optionChainPriceToggle')
);

export const optionsChainSelectedDateSelector = createSelector(
  [optionsChainSelector],
  prop('expiryDate')
);

export const optionsChainSelectedAssetTypeSelector = createSelector(
  [optionsChainSelector],
  prop('assetSymbol')
);

export const optionChainVisualisationSelector = createSelector(
  [optionsChainSelector],
  prop('optionChainVisualisation')
);

export const optionChainTradeSelectiveColumns = createSelector(
  [optionsChainSelector],
  prop('optionTradePageSelectedColumns')
);

export const optionsChainColumnsSelector = createSelector(
  [optionsChainSelector],
  prop('columns')
);

export const optionsChainSortSelector = createSelector(
  [optionsChainSelector],
  prop('isAscending')
);

export const optionsChainMaxCallOi = createSelector(
  [optionsChainSelector],
  prop('maxCallOi')
);

export const optionsChainMaxPutOi = createSelector(
  [optionsChainSelector],
  prop('maxCallOi')
);

export const optionsChainClosestStrike = createSelector(
  [optionsChainSelector],
  prop('closestStrike')
);

export const optionsChainProductListSelector = createSelector(
  [
    optionsChainSelectedDateSelector,
    optionsChainSelectedAssetTypeSelector,
    productsListSelector,
  ],
  (expiryDate, assetSymbol, productsList) => {
    const filteredProducts = productsList.filter(product => {
      return (
        getUnixTimestamp(product.settlement_time) === Number(expiryDate) &&
        product.underlying_asset.symbol === assetSymbol
      );
    });
    // console.log(
    //   'DEBUG optionsChainProductListSelector',
    //   expiryDate,
    //   filteredProducts,
    // );
    return filteredProducts;
  }
);
