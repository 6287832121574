/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, memo, useRef, useEffect } from 'react';

import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import BuySellButtonsAndInputRow from 'components/basketOrders/mobileBasketOrders/mobileProductSelectionScreen/buySellButtonsAndInputRow';
import Volume24Hr, { RenderVolume } from 'components/markets/24hr_volume';
import {
  FundName,
  FundReturns,
  LastPriceChangeValue,
  MarkPriceValue,
} from 'components/markets/helpers';
import NoContracts from 'components/markets/no_contracts';
import HaltedLabel from 'components/productHeader/HaltedLabel';
import Render from 'components/render';
import { isTestnet, LANDING_PAGE_URL } from 'constants/constants';
import { device } from 'helpers/screenSizes';
import {
  noop,
  roundByNumberValue,
  tradingHaltedReason,
  // getFavoritesToTop,
} from 'helpers/utils';
import { useScrollIntoView } from 'hooks';
import { basketOrderSwitchSelector } from 'selectors';
import { ContractType } from 'types/IProducts';
import { Box } from 'UIKit';

import { LastPrice, SettlementCurrency, Contract } from './dropdownTable/column-helper';
import styles from './index.module.scss';

const defaultRects = {
  top: 0,
  height: 0,
  left: 0,
  bottom: 0,
  width: 0,
};
function getRelativeOffsetY(element) {
  // Get overlay rects
  const overlayRects = element?.getBoundingClientRect();
  const parentElement = document.getElementById('product-header-container');
  const { y } = overlayRects || defaultRects;
  const parentAbsoluteTop = parentElement?.getBoundingClientRect()?.y || 0;
  const relativeTop = y - parentAbsoluteTop;
  return relativeTop;
}

export const TradingHaltedReason = props => {
  const { product, isFund } = props;
  if (isFund) return null;
  const isTradingHalted = product.trading_status !== 'operational';

  if (!isTradingHalted) return null;

  let haltReason = '';
  if (product.trading_status === 'disrupted_cancel_only') {
    const reason = tradingHaltedReason(product);
    haltReason = `Trading Halted (${reason}) - Cancel Only Mode`;
  } else {
    haltReason = 'Auction in Progress - Post Only Mode';
  }

  return (
    <div
      className={cn('auction-row', styles['auction-wrapper'])}
      data-palette="TradingHaltedReason">{haltReason}</div>
  );
};

const DesktopRow = props => {
  const { product } = props;
  return (<>
    <div className={styles.data} data-palette="DesktopRow">
      <LastPrice product={product} />
    </div>
    <div className={styles.data} data-palette="DesktopRow">
      <LastPriceChangeValue styles={styles} product={product} />
    </div>
    <div className={styles.data} data-palette="DesktopRow">
      <Volume24Hr product={product} showUSDSymbol />
    </div>
  </>);
};

const MobileRow = props => {
  const { product, isFund, showVolume } = props;
  return (<>
    <div className={styles.data} data-palette="MobileRow">
      {!isFund && (
        <div className={styles['price-volume-wrapper']} data-palette="MobileRow">
          {product?.contract_type === ContractType.OptionsCombos ? (
            <MarkPriceValue product={product} />
          ) : (
            <LastPrice product={product} />
          )}
          <Render when={showVolume}>
            <RenderVolume
              isMobile={false}
              product={product}
              showUSDSymbol
              className={styles['hide-dash']}
            />
          </Render>
        </div>
      )}
      {isFund && (
        <FundReturns
          returnsValue={
            product.stats['1d']
              ? roundByNumberValue(product.stats['1d'].returns * 100)
              : ''
          }
        />
      )}
    </div>
    <div className={styles.data} data-palette="MobileRow">
      {!isFund && (
        <LastPriceChangeValue isFund={isFund} styles={styles} product={product} />
      )}
      {isFund && (
        <FundReturns
          returnsValue={
            product.stats.Inf ? roundByNumberValue(product.stats.Inf.returns * 100) : ''
          }
        />
      )}
    </div>
  </>);
};

// eslint-disable-next-line func-names
const RowWrapper = memo(function (props) {
  const {
    product,
    handleFavClick,
    favorites,
    onRowClick,
    showFavIcon,
    isFund,
    rowClassName,
    isBasketOrderRowActive,
    isBasketOrdersView,
  } = props;

  const { trading_status: tradingStatus } = product;
  /**
   * Only is desktop view.
   * Whenever user hover's upon a row on mouseeneter we calculate the position for external link.
   *
   * x will be depend upon width of the dropdown. Current width is 520px.
   * So on mouseenter we assign 516px. So it is bit inside and user can hover while being within the row.
   *
   * y is being calculated based on current row position with respect to the screen.
   *
   */
  const [externalLinkPoints, setExternalLinkPoints] = useState({ x: 0, y: 0 });

  const rowElement = useRef();

  useEffect(() => {
    const row = rowElement.current;

    const onEnter = event => {
      let y = getRelativeOffsetY(event.target);
      y += 10;
      setExternalLinkPoints({ x: 604, y });
    };

    const onLeave = () => {
      setExternalLinkPoints({ x: 0, y: 0 });
    };

    row.addEventListener('mouseenter', onEnter);
    row.addEventListener('mouseleave', onLeave);
    return () => {
      row.removeEventListener('mouseenter', onEnter);
      row.removeEventListener('mouseleave', onLeave);
    };
  }, []);

  return (
    (<div
      data-testid={`MobileMarketsTable-${product.symbol}-${product.contract_type}`}
      ref={rowElement}
      key={product.id}
      className={cn(`${styles['dropdown-table-data']} naked relative`, {
        [styles['auction-mode-row']]:
          product.trading_status !== 'operational' &&
          product.trading_status !== 'disrupted_cancel_only' &&
          !isFund,
        [styles['cancel-mode-row']]:
          product.trading_status !== 'operational' &&
          product.trading_status === 'disrupted_cancel_only' &&
          !isFund,
        [styles['fund-row']]: isFund,
        [styles[rowClassName]]: rowClassName,
      })}
      onClick={() => onRowClick(product)}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
      data-palette="RowWrapper">
      <div className={styles.data}>
        {!isFund && (
          <Contract
            product={product}
            favorites={favorites}
            handleFavClick={handleFavClick}
            showFavIcon={showFavIcon}
          />
        )}
        {isFund && <FundName fund={product} />}
      </div>
      <MediaQuery query={device.up.md}>
        {tradingStatus !== 'operational' && !isFund ? (
          <div className={styles.halted} data-palette="RowWrapper">
            <HaltedLabel
              selectedProduct={product}
              showDuration={false}
              classname={styles.haltedLabel}
            />
          </div>
        ) : (
          <DesktopRow ref={rowElement} product={product} />
        )}
        <Render when={externalLinkPoints.x || externalLinkPoints.y}>
          <div
            // className={styles[`data`]}
            style={{
              position: 'fixed',
              left: externalLinkPoints.x,
              top: externalLinkPoints.y,
              marginLeft: 0,
              width: 'unset',
            }}
          >
            <SettlementCurrency product={product} />
          </div>
        </Render>
      </MediaQuery>
      <MediaQuery query={device.down.md}>
        {tradingStatus !== 'operational' && !isFund ? (
          <div className={styles.halted} data-palette="RowWrapper">
            <HaltedLabel
              selectedProduct={product}
              showDuration={false}
              classname={styles.haltedLabel}
            />
          </div>
        ) : (
          <MobileRow isFund={isFund} product={product} showVolume={!isBasketOrdersView} />
        )}
      </MediaQuery>
      {/* <TradingHaltedReason isFund={isFund} product={product} /> */}
      {isBasketOrderRowActive && (
        <Box absolute noInset className={styles.buySellContainer}>
          <BuySellButtonsAndInputRow product={product} />
        </Box>
      )}
    </div>)
  );
});

const ProductsList = props => {
  const {
    products,
    onProductRowClick,
    favorites,
    handleFavClick,
    showFavIcon = true,
    showFunds,
    rowClassName,
  } = props;
  const { t } = useTranslation(['markets']);

  const isBasketOrdersView = useSelector(state => basketOrderSwitchSelector(state));

  const [showBasketOrderBuySellRow, setShowBasketOrderBuySellRow] = useState({
    productId: '',
    show: false,
  });

  // scroll to start of basket order row
  useScrollIntoView('basket-order-input-row', showBasketOrderBuySellRow);

  const onRowClick = product => {
    const isRowActive =
      showBasketOrderBuySellRow.productId === product?.id &&
      showBasketOrderBuySellRow.show;

    isBasketOrdersView && isMobile
      ? setShowBasketOrderBuySellRow({
          productId: product.id,
          show: !isRowActive,
        })
      : onProductRowClick(product);
  };

  if (showFunds && isTestnet) {
    return (
      (<div className={styles.noContractsInWatchlist} data-palette="ProductsList">
        <NoContracts />
        <div className={styles.roboStrategiesLink}>
          <Trans i18nKey="markets:marketsTable.noProductsInTestnet">
            <a
              href={`${LANDING_PAGE_URL}/invest/robo-strategies`}
              data-testid="invest-page-link"
            >{` `}</a>
          </Trans>
        </div>
      </div>)
    );
  }

  if (products.length === 0) {
    return <NoContracts searchTerm={t('markets:marketsTable.noProducts')} />;
  }

  return products.map((product, rowIndex) => {
    const isBasketOrderRowActive =
      showBasketOrderBuySellRow.productId === product?.id &&
      showBasketOrderBuySellRow.show;

    return (
      <RowWrapper
        rowIndex={rowIndex}
        key={product.id}
        product={product}
        handleFavClick={handleFavClick}
        favorites={favorites}
        showFavIcon={showFavIcon}
        isFund={showFunds}
        rowClassName={rowClassName}
        isBasketOrdersView={isBasketOrdersView}
        onRowClick={onRowClick}
        isBasketOrderRowActive={isBasketOrderRowActive}
      />
    );
  });
};

const shouldComponentUpdate = (_, newProps) => {
  // console.log(newProps.isOpen)
  if (newProps.isOpen) {
    return false;
  }
  return true;
};

export default memo(ProductsList, shouldComponentUpdate);
