/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable default-param-last */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */

// import * as Sentry from '@sentry/browser';
import base32Encode from 'base32-encode';
import memoize from 'fast-memoize';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';
import { matchPath } from 'react-router';

import { postMessageToMobileApp } from 'components/app/helpers';
import {
  APPLE_DEVICES,
  COUNTRIES_WITH_SINGLE_VERIFICATION_FLOW,
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
  EMPTY_COLUMN_PLACEHOLDER,
  HEADERS,
  IS_INDIAN_EXCHANGE,
  IS_RN_WEBVIEW,
  KYC_UPGRADE_TRADING_BLOCKED_DATE,
  LOCAL_STORAGE_KYC_POPUP_KEY,
  LOCAL_STORAGE_PAGINATION_KEY,
  NON_KYC_CHECK_DATE,
  NON_KYC_EXPIRY_DATE,
  NUMBER_QUANTITY,
  NUMBER_TYPES,
  PAGINATION_PERSIST_STATE_TABLES,
  REGEX_PATTERN_EMAIL,
  SECURITY_UPDATION_CHECK_DATE,
  TIMER_DIVIDER,
  TWENTY_FOUR_HOURS,
  USER_AUTH_PATH,
  VANILLA_SETTLING_ASSET,
} from 'constants/constants';
import { POST_MESSAGE_TO_MOBILE_EVENTS, STORAGE_KEYS } from 'constants/enums';
import routes from 'constants/routes';
import { TIME_FORMATS } from 'constants/timeformats';
// import * as mixPanel from 'helpers/mixpanel-init';
import {
  T,
  __,
  always,
  any,
  both,
  complement,
  concat,
  cond,
  contains,
  defaultTo,
  divide,
  eqProps,
  equals,
  find,
  groupBy,
  gte,
  includes,
  init,
  isNil,
  last,
  length,
  lensPath,
  or,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  reduce,
  sort,
  sortBy,
  split,
  tail,
  toPairs,
  toString,
  uniqWith,
  values,
  view,
} from 'helpers/ramda';
import i18n from 'i18n/config';
import { isTruthy } from 'ramdax';
import { LoggedUserType } from 'types/IUser';

import { round_by_tick_size } from './assetUtils';
import {
  dateFormat,
  getDateTime,
  getDiff,
  getDuration,
  getDurationAsDays,
  getDurationAsHours,
  getDurationAsMinutes,
  getFromNow,
  getNativeDate,
  getParsableFormat,
  getTimestampInMS,
  getUnixTimestamp,
  isDateSame,
  timeNow,
  toLocal,
  toUTC,
} from './day';
import { isUserIndian, isUserRegisteredBeforeKYCDate } from './user';

// const { validate: uuidValidate, version: uuidVersion, v4: uuidv4 } = require('uuid');
// import { isReduceOnlyEnable } from './bracketOrder/bracketOrder';

// import { getUSDVolume } from 'components/markets/helpers';

let timer = null;

const isNan = num => {
  return Number.isNaN(Number(num));
};

const clearTimer = () => {
  if (timer != null) {
    clearTimeout(timer);
  }
};

export function preLoginFavoriteClick(e, setOpen, delay = 1500) {
  e.preventDefault();
  e.stopPropagation();
  setOpen(true);

  timer = setTimeout(() => {
    setOpen(false);
    clearTimer();
  }, delay);
}

export const getFavoritesToTop = (products, favoritesIds) => {
  if (products.length > 0 && favoritesIds.length > 0) {
    return sort(
      (elem1, elem2) =>
        (favoritesIds.includes(elem2.id) ? elem2.id : 0) -
        (favoritesIds.includes(elem1.id) ? elem1.id : 0),
      products
    );
  }
  return products;
};

export function js_yyyy_mm_dd_hh_mm_ss(date) {
  if (date) {
    const leftPad = val => (val.toString().length === 1 ? `0${val}` : val);
    const year = date.getFullYear();
    const month = leftPad(date.getMonth() + 1);
    const day = leftPad(date.getDate());
    const hour = leftPad(date.getHours());
    const minute = leftPad(date.getMinutes());
    const second = leftPad(date.getSeconds());
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  return date;
}

export function getTimeHMS(date) {
  if (date) {
    const leftPad = val => (val.toString().length === 1 ? `0${val}` : val);
    const hour = leftPad(date.getHours());
    const minute = leftPad(date.getMinutes());
    const second = leftPad(date.getSeconds());
    return `${hour}:${minute}:${second}`;
  }
  return date;
}

export function capitalize(str) {
  return str.replace(/(?:^|\s)\S/g, a => {
    return a.toUpperCase();
  });
}

export function toTitleCase(string) {
  return string.replace(/\w\S*/g, text => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
}

export function validateEmail(email) {
  const re = REGEX_PATTERN_EMAIL;
  return re.test(email);
}

export function onlyLettersAndNumbers(str) {
  return /^[A-Za-z0-9]*$/.test(str);
}

// export const addLogsToSentry = (userObj, title) => {
//   Sentry.withScope(scope => {
//     scope.setUser(userObj);
//     Sentry.captureMessage(title);
//   });
// };

// export const addLogsInSentryAndMixpanel = (userObj, title) => {
//   mixPanel.trackMixpanelEvent(title, userObj);
//   addLogsToSentry(userObj, title);
// };

export const assetSymbolText = symbol => {
  switch (symbol) {
    case 'USDT':
      return 'USDT (ERC-20)';
    case 'ETH':
      return 'ETH (ERC-20)';
    default:
      return symbol;
  }
};

export function validatePassword(password) {
  // eslint-disable-next-line prefer-regex-literals
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?)(+=._-])(?=.*[0-9])(?=.{6,})'
  );
  return strongRegex.test(password);
}

export const copyToClipboard = secretInfo => {
  navigator.clipboard.writeText(secretInfo);
};

export const resetTimeErrMsg = resetTime => {
  return i18n.t('errors:utils.resetTime', { count: resetTime });
};

export const getSpotSymbolFromProduct = product => product.spot_index.symbol;

/**
 * Checks if number is in exponential format (eg: 1e-8 for 0.00000001).
 * If it does not, original number is returned.
 * If it does it converts it to string representation of that number
 * which forces it to format 0.00000001
 */
export function convertExponentialToDecimal(exponentialNumber) {
  const data = String(exponentialNumber).split(/[eE]/);
  // sanity check - is it exponential number
  if (data.length === 1) {
    return data[0];
  }

  let z = '';
  const sign = exponentialNumber < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0';
    // eslint-disable-next-line
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0';
  return str + z;

  // can't use toFixed because of poor handling with decimal values hence kept commented
  // const str = exponentialNumber.toString();
  // if (str.indexOf('e') !== -1) {
  //   const exponent = parseInt(str.split('-')[1], 10);
  //   // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
  //   // it will still return the exponential representation
  //   // So I have to use .toFixed()
  //   return exponentialNumber.toFixed(exponent);
  // } else {
  //   return exponentialNumber;
  // }
}

// Todo: duplicate need to remove
export function calcPrecision(data) {
  const convertedData = convertExponentialToDecimal(data);
  const [, afterDecimal] = convertedData.toString().split('.');
  return afterDecimal ? length(afterDecimal) : 0;
}

const paramObj = {
  nick_name: 'Username',
};

export function getErrorMessage(body) {
  if (body.message) {
    return body.message;
  }
  if (body.errors && body.errors.constructor === Array) {
    return body.errors
      .map(error => {
        const param = paramObj[error.param];
        return `${param} ${error.msg}`;
      })
      .join('\n');
  }
  if (body.errors && body.errors.constructor === Object) {
    const keys = Object.keys(body.errors).map(key =>
      key === 'nick_name' ? 'Username' : key
    );
    const message = Object.values(body.errors);
    return `${keys[0]} ${message[0]}`;
  }
  if (body.error && body.error.constructor === Object) {
    return toPairs(body.error)
      .map(([, value]) => `${value}`)
      .join('\n');
  }
  return i18n.t('errors:utils.somethingWentWrong');
}

export const badSchemaErrorLens = lensPath([
  'error',
  'context',
  'schema_errors',
  0,
  'message',
]);

const arrayErrorMessageLens = lensPath(['errors', 0, 'msg']);

export const humanisedMessage = message => {
  if (message === 'Invalid Value') {
    return i18n.t('errors:utils.pleaseRefresh');
  }
  return message.includes('big') ? message.replace('big', '') : message;
};

export const genericErrorLens = body => {
  if (isNil(body)) {
    return `${i18n.t('errors:utils.pleaseRefresh')}`;
  }
  if (body.message && body.message.constructor === String) {
    return humanisedMessage(body.message);
  }
  if (body.errors && body.errors.constructor === Array) {
    const msg = view(arrayErrorMessageLens, body);
    return humanisedMessage(msg);
  }
  let message;
  switch (body.error.code) {
    case 'bad_schema':
      message = humanisedMessage(view(badSchemaErrorLens, body));
      return message;
    case 'not_found':
      return `${i18n.t('errors:utils.pleaseRefresh')} ${i18n.t(
        'errors:utils.contactSupport'
      )}`;
    case 'internal_server_error':
      return `${i18n.t('errors:utils.serverError')} ${i18n.t(
        'errors:utils.contactSupport'
      )}`;
    default:
      break;
  }
  return '';
};

// api check function to call the next api
// const shouldFetchNextPage = (page, data) => {
//   return (
//     page * data.pageSize >= data.apiPageSize * data.currentPage - data.pageSize
//   );
// };

// api call function
export const fetchApiData = (page, value, apiFunction) => {
  const params = {
    page_size: value.apiPageSize,
    backgroundFetch: true,
  };

  let newPage;
  if (value.after) {
    params.after = value.after;
    newPage = value.currentPage + 1;
  } else {
    params.before = value.before;
    newPage = value.currentPage - 1;
  }
  params.page_num = newPage;
  params.start_time = value.start_time;
  params.end_time = value.end_time;
  apiFunction(params);
  // if (value.after && shouldFetchNextPage(newPage, value)) {
  // } else{
  //   apiFunction(params);
  // }
};

// Returns if a table belongs to transaction logs.
export const isTransactionLogTable = value =>
  any(equals(value), [
    PAGINATION_PERSIST_STATE_TABLES.TL_ASSET_HISTORY,
    PAGINATION_PERSIST_STATE_TABLES.TL_ORDER_HISTORY,
    PAGINATION_PERSIST_STATE_TABLES.TL_TRADE_HISTORY,
    PAGINATION_PERSIST_STATE_TABLES.TL_TRANSFER_FUND_HISTORY,
  ]);

export const getRowsPerPage = table => {
  let paginationObj = localStorage.getItem(LOCAL_STORAGE_PAGINATION_KEY);
  if (paginationObj) {
    paginationObj = JSON.parse(paginationObj);
    if (paginationObj[table] && paginationObj[table].rowsPerPage) {
      return paginationObj[table].rowsPerPage;
    }
  }
  return isTransactionLogTable(table) ? 15 : DEFAULT_PAGINATION_ROWS_PER_PAGE;
};

export const checkInputValue = inputValue => {
  const value = inputValue?.replace?.(',', '.');
  return value === '-' || value === '' || value === '.' || !isNan(value);
};

export const checkValue = value => {
  if (value) {
    return value.toString();
  }
  return '-';
};

export const isTestNet = () =>
  window.location.host.split('.')[0].toLowerCase() === 'testnet';

export const checkEnvironmentMessageCondition = isloggedin => !isTestNet() && !isloggedin;

export const isUserAuthPage = () =>
  USER_AUTH_PATH.includes(window.location.pathname.replace('/app', ''));

// eslint-disable-next-line @typescript-eslint/naming-convention
export const dd_mm_hh = (startDate, endDate) => {
  const diff = getDiff(getDateTime(endDate), getDateTime(startDate), 'milliseconds');
  const days = parseInt(getDurationAsDays(diff), 10);
  const hours = parseInt(getDurationAsHours(diff), 10) - days * 24;
  const minutes =
    parseInt(getDurationAsMinutes(diff), 10) - (days * 24 * 60 + hours * 60);
  return `${days}d:${hours}h:${minutes}m`;
};

export const defaultObj = defaultTo({});

export const compareValuesAsString = (key1, key2) =>
  equals(toString(key1), toString(key2));

export const getInt = value => parseInt(value, 10);

export const isNegative = number => Math.sign(parseFloat(number)) === -1;

export const localeNumber = (number, options = {}) => {
  return number.toLocaleString(undefined, options);
};

export const getVanillaStopOrderType = (stop_order_type, trail_amount, order_type) => {
  if (equals(stop_order_type, 'stop_loss_order') && !isNil(trail_amount)) {
    return i18n.t('errors:utils.trailingStop');
  }
  if (equals(stop_order_type, 'stop_loss_order')) {
    return order_type === 'limit_order'
      ? i18n.t('errors:utils.stopLimit')
      : i18n.t('errors:utils.stopMarket');
  }
  if (equals(stop_order_type, 'take_profit_order')) {
    return order_type === 'limit_order'
      ? i18n.t('errors:utils.takeProfitLimit')
      : i18n.t('errors:utils.takeProfitMarket');
  }
  return order_type === 'limit_order'
    ? i18n.t('errors:utils.limit')
    : i18n.t('errors:utils.market');
};

export const getStopOrderType = order => {
  const { stop_order_type, order_type, trail_amount } = order;

  if (order.bracket_order) {
    if (stop_order_type === 'take_profit_order') {
      return i18n.t('errors:utils.bracketTp');
    }

    if (trail_amount) {
      return i18n.t('errors:utils.bracketSlTrail');
    }

    return i18n.t('errors:utils.bracketSl');
  }

  return getVanillaStopOrderType(stop_order_type, trail_amount, order_type);
};

export const getFundingChartSymbol = concat('FUNDING:');

export const isMarketsPage = () => contains('markets', window.location.pathname);

export const isTradePage = () => contains('trade', window.location.pathname);

export const isBasketOrdersMobilePage = () =>
  contains('basketOrder', window.location.pathname);

export const isOptionsPage = () =>
  contains(routes.easy_options.default, window.location.pathname);

export const appendSuffix = suffix => value =>
  Number(value)
    ? `${convertExponentialToDecimal(Number(value))}${suffix || ''}`
    : EMPTY_COLUMN_PLACEHOLDER;

export const appendNothing = appendSuffix('');
export const appendPercent = appendSuffix('%');

export const getUniqListByKey = key => list => {
  const fn = (value, value2) => eqProps(key, value, value2);
  return uniqWith(fn)(list);
};

export function calcPages(obj) {
  return Math.ceil(obj[HEADERS.TOTAL] / obj[HEADERS.PAGE_SIZE]);
}

export const isAbsoluteUrl = url => {
  const regex = /^https?:\/\/|^\/\//i;
  return regex.test(url);
};

export const getRandomString = () => {
  return Math.random().toString(36).substring(7);
};

export const isFloat = n => Number(n) === n && n % 1 !== 0;

export const isThousand = gte(__, NUMBER_QUANTITY.THOUSAND);
export const isTenThousand = gte(__, NUMBER_QUANTITY.TENTHOUSAND);
export const isMillion = gte(__, NUMBER_QUANTITY.MILLION);
export const isLac = gte(__, NUMBER_QUANTITY.LAC);

export const addUnit = unit => number => `${number}${unit}`;

const getValueWithUnit = (quantiy, unit) => pipe(divide(__, quantiy), addUnit(unit));

export const getValueByQuantity = mobile =>
  cond([
    [isMillion, getValueWithUnit(NUMBER_QUANTITY.MILLION, 'M')],
    [both(complement(mobile), isLac), getValueWithUnit(NUMBER_QUANTITY.THOUSAND, 'K')],
    [isTenThousand, getValueWithUnit(NUMBER_QUANTITY.THOUSAND, 'K')],
    [both(mobile, isThousand), getValueWithUnit(NUMBER_QUANTITY.THOUSAND, 'K')],
    [T, addUnit(' ')],
  ]);

export const getFractionDigitsByWallet = cond([
  [equals('USD'), always(1)],
  [equals('BTC'), always(2)],
  [equals('USDC'), always(1)],
  [T, always(0)],
]);

export const getFormattedVolume = isMobileView => value => {
  // const [num, currency] = value.split(' ');
  // const number = getUSDVolume(parseFloat(num), currency);
  const number = value;

  const mobile = always(isMobileView);
  const valueWithUnit = getValueByQuantity(mobile)(number);

  const unit = last(valueWithUnit);
  const formattedNumber = pipe(init, parseFloat)(valueWithUnit);

  const fractionDigits = getFractionDigitsByWallet('USD');

  const valueWithFractionDigits =
    fractionDigits > 0 && isFloat(formattedNumber)
      ? formattedNumber.toFixed(fractionDigits)
      : formattedNumber;

  return `${valueWithFractionDigits}${unit}`;
};

export const getUnformattedVolume = value => {
  // const [num, currency] = value.split(' ');
  // const number = getUSDVolume(parseFloat(num), currency);
  const number = value;

  const fractionDigits = getFractionDigitsByWallet('USD');

  const valueWithFractionDigits =
    fractionDigits > 0 && isFloat(number) ? number.toFixed(fractionDigits) : 0;
  return valueWithFractionDigits;
};

const toFixed = memoize(
  precision =>
    (function(number) {
      if (or(!number, equals(number, 'NaN'))) {
        return null;
      } // API returns "NaN" sometimes
      const num = parseFloat(number);
      return num.toFixed(precision);
    })
);

const roundByTickSize = tick_size =>
  memoize(number => {
    if (or(!number, equals(number, 'NaN'))) {
      return null;
    } // API returns "NaN" sometimes
    return parseFloat(round_by_tick_size(number, tick_size));
  });

const roundByNumberValue = number => {
  let result;
  const value = Math.abs(number);

  if (value >= 1000) {
    result = Math.round(value);
  } else if (value >= 100 && value < 1000) {
    result = value.toFixed(1);
  } else if (value >= 0 && value < 100) {
    result = value.toFixed(2);
  }

  return number < 0 ? `-${result}` : result;
};

export const getPercentage = memoize((value1, value2) => {
  if (or(!value1, !value2)) {
    return null;
  }
  const cal = ((value1 - value2) * 100) / value2;
  return roundByNumberValue(cal) || null;
});

export const getChange = memoize((value1, value2) => {
  if (or(!value1, !value2)) {
    return null;
  }
  const cal = value1 - value2;
  return roundByNumberValue(cal) || null;
});

export const isContractPerpetual = memoize(equals('perpetual_futures'));

export const getExpiryDateShort = memoize((settlement_time, contract_type) => {
  if (isContractPerpetual(contract_type)) {
    return EMPTY_COLUMN_PLACEHOLDER;
  }

  const settlementTime = getDateTime(settlement_time);
  const fromNow = getFromNow(settlementTime);
  return fromNow.replace(/ago|in/g, '');
});

export const getExpiryDateLong = memoize((settlement_time, contract_type) => {
  if (isContractPerpetual(contract_type)) {
    return EMPTY_COLUMN_PLACEHOLDER;
  }

  const now = toUTC(timeNow());

  const end = toUTC(getDateTime(settlement_time));
  const duration = getDiff(end, now, 'milliseconds');

  if (duration < 86400 * 1000) {
    return dateFormat(toUTC(duration), TIME_FORMATS.HHhmmmsss);
  }
  return dd_mm_hh(now, end);
});

/**
 * If hours || mins are 1, returns 'hr' / 'min' respectively (formatted).
 * Otherwise returns 'hrs' / 'mins' (formatted)
 * Used by getFeeCreditExpiryDate()
 * @param hours
 * @param minutes
 * @returns {string}
 */
const humanizeDuration = (hours, minutes) => {
  let hh = 'hrs';
  let mm = 'mins';

  if (hours === 1 || minutes === 1) {
    if (hours === 1) {
      hh = 'hr';
    }
    if (minutes === 1) {
      mm = 'min';
    }
  }

  return `${hours} ${hh} ${minutes} ${mm}`;
};

/**
 * Trading fee credits expire at 12 am UTC.
 * @param utc
 * @param expiry
 * @returns {string} returns the time diff (format: xx hrs yy mins) from now to 12 am UTC.
 */
export const getFeeCreditExpiryDate = (
  utc = toUTC(timeNow()),
  expiry = toUTC(timeNow())
) => {
  const totalMinsToExpiry = getDiff(getDateTime(expiry), getDateTime(utc), 'minutes');
  const hrsToExpiry = Math.floor(totalMinsToExpiry / 60);
  const minsToExpiry = totalMinsToExpiry % 60;

  return humanizeDuration(hrsToExpiry, minsToExpiry);
};

/**
 * Accepts order type (possible values = limit_order / market_order) and returns a formatted string
 * @param order_type
 */
export const formatOrderType = order_type => {
  if (!order_type) return;

  const order = order_type.split('_');
  // eslint-disable-next-line consistent-return
  return i18n.t(`trading:${order[0]}`) || capitalize(order[0]);
};

export const isBlackListedPath = blacklistPath => pathname =>
  // eslint-disable-next-line @typescript-eslint/no-shadow
  any(path => isTruthy(matchPath(pathname, path)), blacklistPath);

export const isBlackListedTab = blacklistTab => activeTab =>
  any(tab => equals(activeTab, tab), blacklistTab);

export const streamBlobToRNWebView = (filename, data) => {
  postMessageToMobileApp('BLOB_DOWNLOAD_REQUEST', {
    data,
    filename,
  });
};

export const downloadData = (filename, contentType) => data => {
  if (window.isRNWebView) {
    streamBlobToRNWebView(filename, data);
  } else {
    const anchorTag = document.createElement('a');
    const blob = new Blob([data], { type: contentType });

    anchorTag.href = window.URL.createObjectURL(blob);
    anchorTag.download = filename;

    anchorTag.click();
  }
};

/**
 * @param {string} url
 * @param {string} fileName
 * @param {string} mimeType
 */
export const downloadFromUrl = (url, fileName, mimeType) => {
  if (IS_RN_WEBVIEW) {
    postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.FILE_DOWNLOAD_REQUEST, {
      url,
      fileName,
      mimeType,
    });
    return;
  }

  const link = document.createElement('a');

  link.href = url;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const scrollElementIntoView = (element, parentElement, extraTop = 0) => {
  const y = (element ? element.offsetTop : 0) - parentElement.clientHeight / 2 + extraTop;
  parentElement.scrollTo({
    top: y,
    left: 0,
    behavior: 'auto',
  });

  return y;
};

export const scrollToBottom = htmlElement => {
  const element = htmlElement;
  const { scrollHeight } = element || {};
  element.scrollTop = scrollHeight;
};

export const scrollToTop = (element = window) => {
  element.scrollTo({ top: 0 });
};

export const isMobileOrTablet = () => isTablet || isMobile || isIPad13;

// eslint-disable-next-line no-promise-executor-return
export const delay = ms => new Promise(res => setTimeout(res, ms));

export const getExpiryType = symbol => {
  const symbolComponents = split('-');
  const type = tail(symbolComponents(symbol))[1];
  switch (type) {
    case 'D':
      return 'Daily';

    case 'W':
      return 'Weekly';

    case 'M':
      return 'Monthly';

    case 'Y':
      return 'Yearly';

    default:
      return '';
  }
};
// Bitcoin Mar 2020 Futures
// Bitcoin Perpetual Swap
// Quanto Bitcoin Perpetual Swap
export const makeDescription = (
  name,
  underlying_asset,
  settlement_time,
  contract_type,
  is_quanto,
  description,
  symbol,
  short_description
) => {
  switch (contract_type) {
    case 'futures':
      return `${name} ${dateFormat(
        getDateTime(settlement_time?.substring(0, 7)),
        TIME_FORMATS.MMM_YYYY
      )} Futures`;

    case 'perpetual_futures':
      return `${name} ${is_quanto ? 'Quanto' : ''} Perpetual`;

    default:
      return short_description || description;
  }
};

export const getContractTypeFromUrl = () => {
  const url = window.location.pathname;
  if (url.includes('futures')) return 'futures';
  if (url.includes('interest_rate_swaps')) return 'interest_rate_swaps';
  if (url.includes('move_options')) return 'move_options';
  if (url.includes('options_combos')) return 'options_combos';
  if (url.includes('options_chain')) return 'options_chain';
  return null;
};

export const replaceNaNWithDash = figure => (isNan(figure) ? '-' : figure);

export const settleTimeInSeconds = (settlement_time, inputStartDate) => {
  const startDate = isNil(inputStartDate) ? toUTC(timeNow()) : inputStartDate;
  const endDate = toUTC(getDateTime(settlement_time));
  const duration = getDiff(endDate, getDateTime(startDate), 'milliseconds');
  return duration / 1000;
};

export const optionsTypes = {
  MOVE: 'move_options',
  CALL: 'call_options',
  PUT: 'put_options',
  TURBO_PUT: 'turbo_put_options',
  TURBO_CALL: 'turbo_call_options',
  OPTIONS_COMBOS: 'options_combos',
};

export const isMoveOptions = contractType => equals('move_options', contractType);
export const isOptionCombos = contractType => equals('options_combos', contractType);
export const isCallPutOptions = contractType =>
  equals('call_options', contractType) || equals('put_options', contractType);

export const isOptions = contractType =>
  equals('move_options', contractType) ||
  equals('call_options', contractType) ||
  equals('put_options', contractType) ||
  equals('options_combos', contractType);

export const isTurbo = contractType =>
  equals('turbo_call_options', contractType) || equals('turbo_put_options', contractType);
export const isFutures = (contractType = '') => contains('futures', contractType);

const getDefaultPrecision = product => {
  const {
    contract_type,
    settling_asset,
    notional_type,
    underlying_asset,
    quoting_asset,
  } = product;

  if (isOptions(contract_type)) return settling_asset?.minimum_precision;
  if (notional_type === 'inverse') {
    if (underlying_asset.symbol === 'ETH') return underlying_asset?.minimum_precision;
    return underlying_asset?.precision;
  }
  return quoting_asset?.minimum_precision;
};

export const getBoolean = val => {
  return val === 1 || val === true || val === 'true';
};

export const getContractType = contractType => {
  switch (contractType) {
    case 'futures':
    case 'perpetual_futures':
      return 'futures';
    case 'call_options':
    case 'put_options':
      return 'options';
    default:
      return contractType;
  }
};

export const contractsObject = () => {
  return {
    all: { data: [] },
    futures: { data: [] },
    interest_rate_swaps: { data: [] },
    spreads: { data: [] },
    options: { data: [] },
    turbo_options: { data: [] },
    spot: { data: [] },
    options_combos: { data: [] },
    move_options: { data: [] },
  };
};

export const sortOnCreatedAtDesc = (a, b) =>
  new Date(b.created_at) - new Date(a.created_at);

export const getProductUrlByContractType = (contractType, currency, symbol) => {
  if (contractType === 'put_options' || contractType === 'call_options') {
    return `${
      isMobile ? routes.options.trade : routes.options_chain.trade
    }/${currency}/${symbol}`;
  }
  if (contractType === 'move_options') {
    return `${routes.move_options.trade}/${currency}/${symbol}`;
  }
  if (isFutures(contractType)) {
    return `${routes.futures.trade}/${currency}/${symbol}`;
  }
  if (isTurbo(contractType)) {
    return `${routes.turbo.trade}/${currency}/${symbol}`;
  }
  return `${routes.default}${contractType}/trade/${currency}/${symbol}`;
};

export const filterProductByContractType = (products, contractType) => {
  return products.filter(product => {
    if (contractType === 'options') {
      return isOptions(product.contract_type) && product.state === 'live';
    }
    return product.contract_type.includes(contractType) && product.state === 'live';
  });
};

export const searchConditions = (product, value) => {
  const productDescription = product.short_description ? product.short_description : '';
  return (
    product.symbol.toLowerCase().includes(value.toLowerCase()) ||
    (product.name && product.name.toLowerCase().includes(value.toLowerCase())) ||
    product.contract_type.toLowerCase().includes(value.toLowerCase()) ||
    productDescription.toLowerCase().includes(value.toLowerCase())
  );
};

export const getSizePercentValue = (size, percent) =>
  Math.round(Math.abs((size * percent) / 100));

export const UTCtoLocalTime = time =>
  getDateTime(getNativeDate(toUTC(getDateTime(time))));

export const UTCUnixtoLocalTime = time => getDateTime(getNativeDate(getDateTime(time)));

export const selectedOptionChainProductList = products => {
  const callAndPutOptions = products.filter(
    product =>
      (product.contract_type === 'put_options' ||
        product.contract_type === 'call_options') &&
      product.state === 'live'
  );

  const byStrikePrice = groupBy(productItem => {
    const strike_Price = round_by_tick_size(
      productItem.strike_price,
      productItem.tick_size
    );
    return `${strike_Price}===${productItem.settlement_time}`;
  });

  const strikeData = byStrikePrice(callAndPutOptions);
  let tableData = [];

  const generateCallOptions = (item, symbol) => {
    const record = strikeData[item.toString()].filter(
      items =>
        items.contract_type === 'call_options' && items.underlying_asset.symbol === symbol
    );
    if (record.length > 0) {
      return record[0];
    }
    return null;
  };
  const generatePutOptions = (item, symbol) => {
    const record = strikeData[item.toString()].filter(
      items =>
        items.contract_type === 'put_options' && items.underlying_asset.symbol === symbol
    );
    if (record.length > 0) {
      return record[0];
    }
    return null;
  };
  const underlyingAssetSymbolList = {};

  Object.keys(strikeData).forEach(item => {
    // two symbols can have same strike price
    let symbols = new Set();
    strikeData[item].forEach(d => {
      if (d?.underlying_asset?.symbol) {
        symbols.add(d.underlying_asset.symbol);
      }
    });
    symbols = Array.from(symbols);
    symbols.forEach(symbol => {
      const symbolData = strikeData[item].find(d => d.underlying_asset.symbol === symbol);
      const underlying_asset_symbol = symbolData.underlying_asset.symbol;
      const expiryTime = path(['settlement_time'], symbolData);
      underlyingAssetSymbolList[underlying_asset_symbol] = {
        priority: symbolData.underlying_asset.sort_priority,
        symbol: underlying_asset_symbol,
      };
      const row = {
        strike_price: item.split('===')[0],
        putOptions: generatePutOptions(item, symbol),
        callOptions: generateCallOptions(item, symbol),
        underlying_asset_symbol,
        expiryTime: getUnixTimestamp(getDateTime(expiryTime)),
        expiryDate: dateFormat(toUTC(getDateTime(expiryTime)), TIME_FORMATS.DD_MMM_YYYY),
      };
      tableData.push(row);
    });
  });

  if (tableData.length) {
    tableData = tableData.sort((a, b) => {
      return parseFloat(a.strike_price) - parseFloat(b.strike_price);
    });
  }
  const expiryAndAssetList = {};
  const settlementTimeandAssetList = {};
  const assetList = Object.values(underlyingAssetSymbolList);

  assetList
    .sort((item1, item2) => item1.priority - item2.priority)
    .forEach(item => {
      const updatedExpiry = {};

      tableData.forEach(row => {
        if (row.underlying_asset_symbol === item.symbol) {
          updatedExpiry[row.expiryTime] = item;
        }
      });

      const associateDates = Object.keys(updatedExpiry);
      if (associateDates.length) {
        expiryAndAssetList[item.symbol] = Object.keys(updatedExpiry)
          .sort()
          .map(date =>
            dateFormat(toUTC(getDateTime(1000 * Number(date))), TIME_FORMATS.DD_MMM_YYYY)
          );
        settlementTimeandAssetList[item.symbol] = associateDates
          .sort()
          .reduce((acc, date) => {
            return {
              ...acc,
              [dateFormat(
                toUTC(getDateTime(1000 * Number(date))),
                TIME_FORMATS.DD_MMM_YYYY
              )]: date,
            };
          }, {});
      }
    });

  return {
    tableData,
    expiryTimesDropDownList: expiryAndAssetList,
    underlyingAssetSymbolList: Object.keys(expiryAndAssetList) || [],
    settlementTimeandAssetList,
  };
};

export const getFilteredDataForOptionChains = (
  tableData,
  selectedExpiryDate,
  selectedUnderlyingAsset,
  selectedType = null
) => {
  const filterRow = (item, selectedFilterType = selectedType) => {
    // For Mobile view Filter
    if (selectedFilterType) {
      const productType = selectedFilterType === 'call' ? 'callOptions' : 'putOptions';
      if (selectedExpiryDate) {
        return (
          item.underlying_asset_symbol === selectedUnderlyingAsset &&
          item.expiryDate === selectedExpiryDate &&
          item[productType]
        );
      }
      return (
        item.underlying_asset_symbol === selectedUnderlyingAsset && item[productType]
      );
    }
    // For Desktop Filter
    if (selectedExpiryDate) {
      return (
        item.underlying_asset_symbol === selectedUnderlyingAsset &&
        item.expiryDate === selectedExpiryDate
      );
    }
    return item.underlying_asset_symbol === selectedUnderlyingAsset;
  };
  if (selectedUnderlyingAsset || selectedExpiryDate) {
    const data = tableData.filter(item => filterRow(item, selectedType));
    return data;
  }
  return [];
};

export const getDefaultAssetAndExpiryDate = (
  expiryTimesDropDownList,
  underlyingAssetSymbolList
) => {
  // let defaultSelectedAsset = 'BTC';
  let defaultSelectedAsset = IS_INDIAN_EXCHANGE ? underlyingAssetSymbolList[0] : 'BTC';
  let defaultExpiryDate = expiryTimesDropDownList?.[defaultSelectedAsset]?.[0];
  const asset = window.location.pathname.split('/');
  if (underlyingAssetSymbolList.includes(asset[4])) {
    defaultSelectedAsset = asset[4];
    const selectedDate = getParsableFormat(asset[5].split('-')[3]);
    defaultExpiryDate = dateFormat(getDateTime(selectedDate), TIME_FORMATS.DD_MMM_YYYY);
  }
  return {
    defaultSelectedAsset,
    defaultExpiryDate,
  };
};

const getSymbolForSpotContract = (underlyingSymbol, quotingSymbol) =>
  `${underlyingSymbol}/${quotingSymbol}`;

const getProductSymbol = product => {
  const { symbol, contract_type, underlying_asset, quoting_asset } = product || {};
  if (contract_type === 'spot') {
    return getSymbolForSpotContract(underlying_asset?.symbol, quoting_asset?.symbol);
  }
  return symbol;
  // return IS_INDIAN_EXCHANGE ? `${underlying_asset?.symbol}USD` : symbol;
};

const getBalanceBySymbol = (symbol, balanceObj) => {
  return prop(symbol, balanceObj) || {};
};

const truncAfterDecimal = (value, afterDecimal) => {
  if (value) {
    let num = value.toString();
    if (num.indexOf('.') > 0) {
      num = num.slice(0, num.indexOf('.') + afterDecimal);
    }
    return Number(num);
  }
  return value;
};

const addZeroesUntilCorrectPrecision = (num, precision) => {
  if (!precision) return num;
  let str = num.toString();
  let decimalPos = str.indexOf('.');
  if (decimalPos === -1) {
    str += '.';
  }

  decimalPos = str.indexOf('.');
  while (str.length - decimalPos - 1 < precision) {
    str += '0';
  }

  return str;
};

// eslint-disable-next-line default-param-last
const cropAfterDecimals = (num = 0, digits) => {
  if (!digits) {
    return num;
  }
  if (num) {
    const numS = num.toString();
    const decPos = numS.indexOf('.');
    const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
    const trimmedResult = numS.substr(0, substrLength);
    const finalResult = isNan(trimmedResult) ? 0 : trimmedResult;
    return finalResult;
  }
  return num;
};

export const tradingHaltedReason = (product, convertToLocale) => {
  const { disruption_reason } = product;

  if (disruption_reason && disruption_reason === 'Ongoing Maintenance') {
    return convertToLocale ? convertToLocale('maintenance') : 'maintenance';
  }
  return convertToLocale ? convertToLocale('spotUnavailability') : 'spot unavailability';
};

export const comparator = (val1, val2) => {
  if (Number(val1) < Number(val2)) {
    return -1;
  }
  if (Number(val1) > Number(val2)) {
    return 1;
  }
  return 0;
};

export const comparatorWithNull = (val1, val2, descending = false) => {
  if (val1 === null && val2 !== null) {
    return descending ? -1 : 1;
  }

  if (val1 !== null && val2 === null) {
    return descending ? 1 : -1;
  }

  if (val1 === null && val2 === null) {
    return -1;
  }

  return Number(val1) - Number(val2);
};

export const discountCalculator = (discount, originalValue) => {
  const discountVal = originalValue * discount;
  const afterDiscountVal = originalValue - discountVal;
  return afterDiscountVal;
};

export const hideEmailAddress = email => {
  const firstpart = email.substring(0, email.lastIndexOf('@'));
  const secondpart = email.substring(email.lastIndexOf('@') + 1);
  return `${firstpart.substring(0, 2)}***@***.${secondpart.substring(
    secondpart.lastIndexOf('.') + 1
  )}`;
};

export const hidePhoneNumber = phoneNumber => {
  const maskedNumber = String(phoneNumber);
  const totalDigits = maskedNumber.length;
  const stars = new Array(totalDigits - 4).fill('*').join('');
  return `${maskedNumber.substring(0, 2)}${stars}${maskedNumber.substring(
    totalDigits - 2
  )}`;
};

export const isKycRefreshExpiryDateCrossed = kyc_expiry_date => {
  const currentTime = getUnixTimestamp(timeNow());
  const expiryTime = getUnixTimestamp(getDateTime(kyc_expiry_date));
  return currentTime > expiryTime;
};

export const isKycRefreshExpired = userData => {
  const { is_kyc_done, is_kyc_refresh_required, kyc_expiry_date } = userData;
  const isExpiryDateCrossed = isKycRefreshExpiryDateCrossed(kyc_expiry_date);

  const isKycRefreshExp = !is_kyc_done && is_kyc_refresh_required && isExpiryDateCrossed;

  return isKycRefreshExp;
};

export const isKycRequiredDeadlineCrossed = userData => {
  const { is_kyc_done, is_kyc_refresh_required, profile, country } = userData;

  const isUnverifiedUser = !is_kyc_refresh_required && !is_kyc_done;
  const isUserRegisteredBefore26April =
    getDiff(
      getDateTime(profile?.registration_date),
      getDateTime(NON_KYC_CHECK_DATE),
      'milliseconds'
    ) < 0;
  const isExpiryDateCrossed = isKycRefreshExpiryDateCrossed(NON_KYC_EXPIRY_DATE);

  const isKycRequiredUser =
    country === 'India' && isUnverifiedUser && isUserRegisteredBefore26April;

  const isReduceOnlyEnable = isKycRequiredUser && isExpiryDateCrossed;

  return isReduceOnlyEnable;
};

/**
 * @typedef   {Record<string,import("types/IWallet").IBalance>} SortBalancesParam
 * @param {SortBalancesParam} balances
 * */
export const sortBalancesBySortPriority = balances =>
  sortBy(({ asset }) => asset.sort_priority, values(balances));

export const sortArrayOfObj = (arr, sort_key) =>
  sortBy(obj => obj[sort_key], values(arr || []));

export const getOpenPosition = (openPositions, productId) => {
  const openPosition = openPositions.find(position => position.product.id === productId);
  return openPosition;
};

export const negativeBalanceCheck = (value, precision) => {
  if (Number(value) < 0) {
    return 0;
  }
  return cropAfterDecimals(value, precision);
};

export const assetPrecision = ({ symbol, minimum_precision, precision }) =>
  symbol === 'BTC' ? precision : minimum_precision;

export const getFormattedInputValue = (value, fallbackValue) => {
  const mapObj = {
    ',': '.',
  };
  const formattedVal = checkInputValue(value)
    ? value?.replace?.(/,/gi, matched => mapObj[matched])
    : fallbackValue;

  return formattedVal;
};

// export const lastPriceChangeCalculator = product => {
//   const { contract_type } = product;
//   return contract_type === CONTRACT_TYPE.IRS
//     ? lastPriceChangeIRSSelector(product)
//     : lastPriceChangeSelector(product);
// };

// export const sortByLastPrice = direction => (firstProduct, secondProduct) => {
//   const firstPrice = lastPriceSelector(firstProduct) || 0;
//   const secondPrice = lastPriceSelector(secondProduct) || 0;

//   return direction === 'asc' ? firstPrice - secondPrice : secondPrice - firstPrice;
// };

// export const sortProductsByVolume = direction => (firstProduct, secondProduct) => {
//   const firstVolume = turnoverStateBySymbolSelector(firstProduct.symbol) || 0;
//   const secondVolume = turnoverStateBySymbolSelector(secondProduct.symbol) || 0;
//   return direction === 'asc' ? secondVolume - firstVolume : firstVolume - secondVolume;
// };

// export const sortProductsByLastPriceChange =
//   direction => (firstProduct, secondProduct) => {
//     const firstPrice = lastPriceChangeCalculator(firstProduct) || 0;
//     const secondPrice = lastPriceChangeCalculator(secondProduct) || 0;
//     return direction === 'asc' ? secondPrice - firstPrice : firstPrice - secondPrice;
//   };

const sortFundsByStats = (direction, key) => (fund1, fund2) => {
  const val1 = fund1.stats[key].returns
    ? roundByNumberValue(fund1.stats[key].returns * 100)
    : 0;
  const val2 = fund2.stats[key].returns
    ? roundByNumberValue(fund2.stats[key].returns * 100)
    : 0;
  return direction === 'asc' ? val2 - val1 : val1 - val2;
};

// export const getTrendingProds = productsList => {
//   const futuresList = filter(
//     product => product.contract_type.includes('futures'),
//     productsList
//   );

//   const sortedFutures = [...futuresList].sort(sortProductsByLastPriceChange('asc'));

//   const trendingProdsList = [...sortedFutures.slice(0, 6)];

//   return trendingProdsList;
// };

const refreshPage = () => {
  localStorage.setItem(STORAGE_KEYS.PAGE_RELOADED, true);

  window.location.reload();
};

const handleNaNAndCropDecimals = (num, decimals = 2) =>
  isNan(num) ? '-' : cropAfterDecimals(num, decimals);

const handleExponentialAndCropDecimals = (num, decimals = 2) =>
  num.toString().includes('e')
    ? cropAfterDecimals(convertExponentialToDecimal(num), decimals)
    : cropAfterDecimals(num, decimals);

const isSpotContract = contractType => contractType === 'spot';
/**
 * https://stackoverflow.com/a/2901298/12445064
 *
 * Any number or numeric string as input.
 * It will separate the numbers with comma's as thousand separator.
 *
 * @example numberCommaSeparator(123456789.1234) => "123,456,789.1234"
 * @example numberCommaSeparator(12.12345678) => "12.12345678"
 * @example numberCommaSeparator(1234.12345678) => "1,234.12345678"
 * @example numberCommaSeparator(12345.123) => "12,345.123"
 * @example numberCommaSeparator(0.12345678) => "0.12345678"
 * @example numberCommaSeparator(12345678.12345678) => "12,345,678.12345678"
 * @example numberCommaSeparator(123456) => "123,456"
 * @example numberCommaSeparator(123456789) => "123,456,789"
 */

const numberCommaSeparator = num => {
  const parsedNumber = Number(num);
  if (isNan(Number(parsedNumber))) return '-';
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(parts[0]) === 0 && Number(parts[1]) === 0 ? '0' : parts.join('.');
};

// 0 needs to be displayed as 0.00;
// the above funtion has been used in many places hence don't want to modify that;
// customising it for PF page only; this can be removed later if we need the same convention every where;
const numberCommaSeparatorForPF = num => {
  const parsedNumber = Number(num);
  if (isNan(parsedNumber)) return '-';
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(parts[0]) === 0 && Number(parts[1]) === 0 ? '0.00' : parts.join('.');
};

/**
 * Any number or numeric string as input.
 * It will separate the numbers with comma's according to indian decimal system.
 *
 * @example indianNumberCommaSeparator(123456789.1234) => "12,34,56,789.1234"
 */
const indianNumberCommaSeparator = num => {
  let number = num.toString();
  if (Number.isNaN(Number(number))) return '-';

  let afterPoint = '';
  if (number.indexOf('.') > 0) {
    afterPoint = number.substring(number.indexOf('.'), number.length);
  }
  number = Math.floor(number);
  let lastThree = number.toString().substring(num.length - 3);
  const otherNumbers = number.toString().substring(0, num.length - 3);
  if (otherNumbers !== '') lastThree = `,${lastThree}`;
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
  return res;
};

const formatDuration = secondsVal => {
  const seconds = Number(secondsVal);
  if (seconds < 60) {
    return `${seconds}s`;
  }
  if (Number(seconds) < 3600) {
    const minutes = Math.floor(Number(seconds) / 60);
    const remains = Number(seconds) % 60;
    return `${minutes}m ${remains}s`;
  }
  const hours = Math.floor(Number(seconds) / 3600);
  const remains = Number(seconds) % 3600;
  const minutes = Math.floor(remains / 60);
  return `${hours}h ${minutes}m`;
};

const getDeviceType = () => {
  if (isMobile) {
    return 'Mobile';
  }
  if (isTablet || isIPad13) {
    return 'Tab';
  }
  return 'Desktop';
};

const sortStrategiesByFundStats = (direction, period) => (firstFund, secondFund) => {
  const defaultValue =
    direction === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;

  const firstStrategyReturns = firstFund.fundStats
    ? Number(firstFund.fundStats[period].returns)
    : defaultValue;

  const secondStrategyReturns = secondFund.fundStats
    ? Number(secondFund.fundStats[period].returns)
    : defaultValue;

  return direction === 'asc'
    ? firstStrategyReturns - secondStrategyReturns
    : secondStrategyReturns - firstStrategyReturns;
};

/* Removes zero from front and back */
function trailZero(num) {
  let number = num;
  while (number[0] === '0') {
    number = number.substr(1);
  }
  if (number.indexOf('.') !== -1) {
    while (number[number.length - 1] === '0') {
      number = number.substr(0, number.length - 1);
    }
  }
  if (number === '' || number === '.') {
    number = '0';
  } else if (number[number.length - 1] === '.') {
    number = number.substr(0, number.length - 1);
  }
  if (number[0] === '.') {
    number = `0${number}`;
  }
  return number;
}

function adjustDecimal(num, decimal) {
  let number = num;
  if (decimal === 0) return number;

  number =
    decimal >= number.length
      ? new Array(decimal - number.length + 1).join('0') + number
      : number;
  return `${number.substr(0, number.length - decimal)}.${number.substr(
    number.length - decimal,
    decimal
  )}`;
}

/**
 * Handles decimal multiplication
 * @example decimalMultiplication(10000 , 0.0003) = 3
 * refer : https://github.com/royNiladri/js-big-decimal/blob/master/src/multiply.ts
 */
function decimalMultiplication(num1, num2) {
  if (!num1 || !num2) {
    return 0;
  }
  let number1 = num1.toString();
  let number2 = num2.toString();

  /* Filter numbers */
  let negative = 0;
  if (number1[0] === '-') {
    negative += 1;
    number1 = number1.substr(1);
  }
  if (number2[0] === '-') {
    negative += 1;
    number2 = number2.substr(1);
  }
  number1 = trailZero(number1);
  number2 = trailZero(number2);
  let decimalLength1 = 0;
  let decimalLength2 = 0;

  if (number1.indexOf('.') !== -1) {
    decimalLength1 = number1.length - number1.indexOf('.') - 1;
  }

  if (number2.indexOf('.') !== -1) {
    decimalLength2 = number2.length - number2.indexOf('.') - 1;
  }
  const decimalLength = decimalLength1 + decimalLength2;
  number1 = trailZero(number1.replace('.', ''));
  number2 = trailZero(number2.replace('.', ''));

  if (number1.length < number2.length) {
    const temp = number1;
    number1 = number2;
    number2 = temp;
  }

  if (number2 === '0') {
    return '0';
  }

  /*
   * Core multiplication
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { length } = number2;
  let carry = 0;
  const positionVector = [];
  let currentPosition = length - 1;

  let result = '';
  for (let i = 0; i < length; i += 1) {
    positionVector[i] = number1.length - 1;
  }
  for (let i = 0; i < 2 * number1.length; i += 1) {
    let sum = 0;
    for (let j = number2.length - 1; j >= currentPosition && j >= 0; j -= 1) {
      if (positionVector[j] > -1 && positionVector[j] < number1.length) {
        sum += parseInt(number1[positionVector[j]] * parseInt(number2[j], 10), 10);
        positionVector[j] -= 1;
      }
    }
    sum += carry;
    carry = Math.floor(sum / 10);
    result = (sum % 10) + result;
    currentPosition -= 1;
  }
  /*
   * Formatting result
   */
  result = trailZero(adjustDecimal(result, decimalLength));
  if (negative === 1) {
    result = `-${result}`;
  }
  return result;
}

const camelCaseToSentence = camelCase => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  const sentence = result.charAt(0).toUpperCase() + result.slice(1);
  return sentence;
};

const sentenceToCamelCase = sentence => {
  return sentence
    .replace(/\s(.)/g, a => {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, b => {
      return b.toLowerCase();
    });
};

const snakeCaseToSentenceCase = str =>
  str.split('_').reduce((acc, word) => {
    const res = `${acc} ${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    return res;
  }, '');

// https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
function abbreviateLargeNumber(num, decimalPlaces = 2) {
  // 2 decimal places => 100, 3 => 1000, etc
  const decPlaces = 10 ** decimalPlaces;
  let number = num;

  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

/**
 *
 * @returns abbreviated number according to Indian decimal system
 * @example 1000 = 1.00K
 * @example 100000 = 1.00L
 * @example 10000000 = 1.00C
 * @deprecated - use prettifyINR from currency.ts
 */
function abbreviateNumberToIndianDecimalSystem(num, decimalPlaces = 2) {
  // 2 decimal places => 100, 3 => 1000, etc
  const decPlaces = 10 ** decimalPlaces;
  let number = num;

  // Enumerate number abbreviations
  const abbrev = ['K', 'L', 'C'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    // Convert array index to "1000", "100000", "10000000", etc
    const size = 10 ** ((i + 1) * 2 + 1);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

/**
 * @param {number} num
 * @param {number} [decimalPlaces]
 * @returns {string}
 */
function abbreviateLargeNumberIncludingNegative(num, decimalPlaces = 2) {
  return num < 0
    ? `-${abbreviateLargeNumber(Math.abs(num), decimalPlaces)}`
    : `${abbreviateLargeNumber(Math.abs(num), decimalPlaces)}`;
}

// const refreshCacheAndReload = async calledFrom => {
//   mixPanel.trackMixpanelEvent('Delete cache and reload', {});
//   console.info('DEBUG refreshCacheAndReload utils', calledFrom);
//   try {
//     if (caches) {
//       const names = await caches.keys();
//       await Promise.all(names.map(name => caches.delete(name)));
//       console.info('Cache cleared', calledFrom);
//     }
//   } catch (e) {
//     console.error('Failed to clear cache', calledFrom, e);
//     mixPanel.trackMixpanelEvent('Delete cache and reload', {});
//   } finally {
//     window.location.href = window.location.href.replace(/#.*$/, '');
//   }
//   // if (caches) {
//   //   const names = await caches.keys();
//   //   await Promise.all(names.map(name => caches.delete(name)));
//   // }
//   // // window.location.reload();
//   // // window.location = window.location.href+'?eraseCache=true';
//   // window.location.href = window.location.href.replace(/#.*$/, '');
// };
const getUserAccountNameById = (id, user) => {
  if (!user.main_account) {
    if (user.is_sub_account) {
      return user?.account_name || '';
    }
    return 'Main';
  }
  const existUser = find(account => account.id === id, user.sub_accounts);
  return existUser ? existUser.account_name : '';
};
const getLoggedUserType = user => {
  if (!user?.main_account) {
    return user?.is_sub_account
      ? LoggedUserType.SUB_ACCOUNT_LOGIN
      : LoggedUserType.MAIN_ACCOUNT_LOGIN;
  }
  return LoggedUserType.SWITCHED_SUB_ACCOUNT;
};
/**
 * @returns true if array/obj/string/number are empty/undefined/null
 */
const parseErrorMsg = err => pathOr({}, ['response', 'body'], err);

const convertUSDtoBTC = (value, btcusdSpotPrice) => {
  if (isNan(value) || isNan(btcusdSpotPrice)) {
    return 0;
  }
  return cropAfterDecimals(convertExponentialToDecimal(value / btcusdSpotPrice), 6);
};
const getUSDT = (asset, value, spotPriceFunc) => {
  let spotPrice = 0;
  switch (asset) {
    case 'BTC':
      spotPrice = Number(spotPriceFunc(IS_INDIAN_EXCHANGE ? '.DEXBTUSD' : '.DEXBTUSDT'));
      break;
    case 'USDT':
    case 'USD':
      spotPrice = 1;
      break;
    default:
      spotPrice = Number(
        spotPriceFunc(IS_INDIAN_EXCHANGE ? `.DE${asset}USD` : `.DE${asset}USDT`)
      );
      break;
  }
  return cropAfterDecimals(spotPrice * value || 0, 2);
};

/**
 * @returns true when string, object, array are empty as well as when null/undefined
 */
const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

const noop = () => {};

const times = num => {
  return [...Array(num).keys()];
};

const goChartingUserDataObj = user => {
  const { token, id, expires_at, email, userName } = user;
  const data = {
    'DELTA:access_token': token,
    'DELTA:user_id': id,
    iss: 'delta.exchange',
    provider: 'DELTA',
    iat: Math.round(new Date().getTime() / 1000),
    exp: expires_at,
    email,
    name: userName,
    sub: '', // sub kept empty intentionally
  };
  return data;
};

const unixTimeDiffInSeconds = (to_time, from_time) => {
  // If the from_time is not there, it will calculate from currentTime
  if (!to_time) {
    return null;
  }
  const fromTime =
    toUTC(getDateTime(from_time)) || getTimestampInMS(toUTC(getDateTime()));
  const toTime = toUTC(getDateTime(to_time));
  const diffInSeconds = getDiff(toTime, fromTime, 'seconds');
  return diffInSeconds;
};

const percentageForIMAndMM = (margin, balance, unrealisedCashFlow) => {
  const walletBalance = Number(balance);
  const mar = Number(margin);
  const ucf = unrealisedCashFlow ? Number(unrealisedCashFlow) : 0;
  const denominator = walletBalance + ucf;

  if (walletBalance > 0 && mar < 0) {
    return 0;
  }
  if (denominator === 0) {
    return null;
  }

  const percentage = (mar / denominator) * 100;

  if (percentage < 0) {
    return 0;
  }
  if (percentage < 1 && percentage > 0) {
    return cropAfterDecimals(percentage, 2);
  }
  return cropAfterDecimals(percentage, 1);
};

const getPercentageUpdated = (value1, value2, precision) => {
  if (Number(value1) < 0) {
    return 0;
  }
  const cal = (Number(value1) / Number(value2)) * 100 || 0;
  return cropAfterDecimals(cal, precision);
};

const windowWidth = typeof window !== 'undefined' && window.innerWidth;

const removeArrayItemByIndex = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

const addArrayItemByIndex = (arr, index, newItem) => {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)];
};

const getPasswordScore = (password, zxcvbn) => {
  return password.length !== 0 ? zxcvbn(password).score : null;
};

const checkPasswordLength = password => {
  return password.length >= 6 && password.length <= 50;
};
const repeat = (str, inputTimes) => new Array(inputTimes + 1).join(str);

const pad = (num, maxLength) => repeat('0', maxLength - num.toString().length) + num;

const returnZeroIfNegative = value => (Number(value) > 0 ? Number(value) : 0);

export const convertObjToArray = obj =>
  Object.entries(obj).map(arr => ({ ...arr[1], parent_obj_key: arr[0] }));

const formatTime = time =>
  `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2
  )}.${pad(time.getMilliseconds(), 3)}`;

const getDurationFromNow = date => {
  const now = toUTC(timeNow());
  const end = toUTC(getDateTime(date));
  const duration = getDuration(getDiff(end, now, 'milliseconds'));
  return duration;
};
const isValidIPV6 = ip => {
  const ipv6_regex =
    /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
  return ipv6_regex.test(ip);
};
const isValidIPV4 = ip => {
  const ipv4_regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4_regex.test(ip);
};
const isValidateIP = ip => {
  return isValidIPV6(ip) || isValidIPV4(ip);
};

const getUsdtSettledSpotIndex = asset =>
  `.DE${asset === 'BTC' ? 'XBT' : asset}${VANILLA_SETTLING_ASSET}`;

/**
 * @example
 * ```
 * const [profit, setProfit] = useState(0);
 * <input onChange={(v) => setFloat(v, setProfit)} value={profit} />
 * ```
 */
function setFloat(val, fun) {
  let v = val;
  if (v.length > 1 && v[0] === '0' && v[1] !== '.') v = v.slice(1);
  if (v.match(/\./g)?.length > 1) return;
  if (/\d|\./.test(v[v.length - 1])) {
    fun(v);
  }
}

/**
 * @summary Converts large numbers to prettified form
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * Usage: numberPrettified(1231231414) -> 1B
 */

const numberPrettified = (num, fractionMax = 2, fractionMin = 2) => {
  return Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: fractionMax,
    minimumFractionDigits: fractionMin,
  }).format(num);
};

const isPositive = number => {
  if (number > 0) {
    return true;
  }
  if (number < 0) {
    return false;
  }
  if (1 / number === Number.POSITIVE_INFINITY) {
    return true;
  }
  return false;
};

const isVisibleInViewport = element => {
  if (element === null) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * @description Add additional query parameter’s to the current location itself.
 * @param location - react-router location object.
 * @param queryParams - Object containing key-value pairs to be added as query parameters in URL.
 * @returns Updated location object with provided query params.
 */
const appendQueryParamsToRouterLocation = (location, queryParams = {}) => {
  const searchParams = new URLSearchParams(location.search);
  const { ...restProperties } = location;
  // eslint-disable-next-line no-restricted-syntax
  for (const [queryKey, queryValue] of Object.entries(queryParams)) {
    searchParams.set(queryKey, queryValue);
  }
  return { ...restProperties, search: `?${searchParams.toString()}` };
};

/**
 * @description To dispatch custom Google Tag Manager event’s.
 * It will only dispatch on prod env such as beta and production.
 */
const dispatchGTMEvent = (eventName, payload = {}) => {
  try {
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: eventName,
        ...(payload && typeof payload === 'object' ? payload : {}),
      });
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

/**
 * @description Creates an RFC version 4(random) UUID for identifying the current device.
 * It stores the generated ID in localStorage to avoid regenerating it.
 */
// const getDeviceId = () => {
//   let deviceId = localStorage.getItem('deviceId');

//   if (deviceId && uuidValidate(deviceId) && uuidVersion(deviceId) === 4) {
//     return deviceId;
//   }

//   deviceId = uuidv4();
//   localStorage.setItem('deviceId', deviceId);

//   return deviceId;
// };

const convertTimeToMilliseconds = seconds => seconds / TIMER_DIVIDER;

const getMomentTimeFormat = seconds =>
  dateFormat(
    toLocal(toUTC(getDateTime(seconds))),
    `${TIME_FORMATS.DD_MMM} ${TIME_FORMATS.HHmmA}`
  );

const compareDates = (startTime, endTime) => isDateSame(startTime, endTime, 'day');

const shouldKYCPopupAppear = () => {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  const lastClosedAt = localStorage.getItem(LOCAL_STORAGE_KYC_POPUP_KEY);
  if (!lastClosedAt) return true;

  const lastClosedTime = new Date(lastClosedAt);
  if (lastClosedTime instanceof Date && String(lastClosedTime) === 'Invalid Date') {
    localStorage.removeItem(LOCAL_STORAGE_KYC_POPUP_KEY);
    return true;
  }

  const diff = Date.now() - new Date(lastClosedTime).getTime();
  if (diff >= ONE_DAY_IN_MS) {
    localStorage.removeItem(LOCAL_STORAGE_KYC_POPUP_KEY);
    return true;
  }

  return false;
};

const isUserAtleastOneLevelVerified = user => {
  const {
    // phone_verification_status: phoneVerificationStatus,
    proof_of_identity_status: proofOfIdentityStatus,
    proof_of_address_status: proofOfAddressStatus,
  } = user;
  let userIsAtleastOneLevelVerified = false;

  // if (phoneVerificationStatus === 'verified') userIsAtleastOneLevelVerified = true; // level 1
  if (proofOfIdentityStatus === 'approved') userIsAtleastOneLevelVerified = true; // level 2
  if (proofOfAddressStatus === 'approved') userIsAtleastOneLevelVerified = true; // level 3

  return userIsAtleastOneLevelVerified;
};

const isUserPhoneVerifiedBeforeLevelOneInvalidation = user => {
  const { phone_verification_status, phone_verified_on } = user;
  const isUserLevelOneVerified = phone_verification_status === 'verified';

  if (!isUserLevelOneVerified) return false;
  if (isUserLevelOneVerified && phone_verified_on === null) return true;

  const userPhoneVerificationDate = getDateTime(phone_verified_on);
  const kycLevelOneInvalidationDate = getDateTime(new Date('2023-01-06T12:30:00.000Z'));

  return (
    getDiff(userPhoneVerificationDate, kycLevelOneInvalidationDate, 'milliseconds') < 0
  );
};

const isUserNonIndianAndDormant = user => {
  const { is_kyc_refresh_required: isKycRefreshRequired, is_kyc_done: isKYCDone } = user;
  let hasUserCompletedKYC = Boolean(isKYCDone);
  if (isKycRefreshRequired) hasUserCompletedKYC = false;

  return (
    !hasUserCompletedKYC && isUserRegisteredBeforeKYCDate(user) && !isUserIndian(user)
  );
};

const isUserNonIndianAndReduceOnly = user => {
  return (
    // Number(totalBalance)
    (!isUserIndian(user) &&
    isUserRegisteredBeforeKYCDate(user) && !isUserAtleastOneLevelVerified(user))
  );
};

const isUserVerified = user => {
  const {
    is_kyc_done: isKycDone,
    is_kyc_refresh_required: isKycRefreshRequired,
    kyc_expiry_date: kycExpiryDate,
  } = user;
  const currentTime = getUnixTimestamp(timeNow());
  const expiryTime = getUnixTimestamp(getDateTime(kycExpiryDate));
  const isExpiryDateCrossed = currentTime > expiryTime;

  let isVerified = false;

  if (isKycDone) {
    isVerified = true;
  } else if (isKycRefreshRequired) {
    isVerified = !isExpiryDateCrossed;
  }

  return isVerified;
};

const isUserOnlyLevelOneVerified = user => {
  const {
    phone_verification_status: phoneVerificationStatus,
    proof_of_identity_status: proofOfIdentityStatus,
    proof_of_address_status: proofOfAddressStatus,
  } = user;

  const levelOneVerified = phoneVerificationStatus === 'verified';
  const levelTwoVerified = proofOfIdentityStatus === 'approved';
  const levelThreeVerified = proofOfAddressStatus === 'approved';

  return levelOneVerified && !levelTwoVerified && !levelThreeVerified;
};

const kycUpgradePopupLastOpenedOneDayAgo = lastClosedAt => {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  if (!lastClosedAt) return true;

  const lastClosedTime = new Date(lastClosedAt);
  if (lastClosedTime instanceof Date && String(lastClosedTime) === 'Invalid Date') {
    return true;
  }

  const diff = Date.now() - new Date(lastClosedTime).getTime();
  if (diff >= ONE_DAY_IN_MS) return true;

  return false;
};

/**
 * @summary Determines if user should see KYC Upgrade Warning.
 * @description Level 1 KYC a.k.a Basic Level was removed from the platform on kycLevelOneInvalidationDate = 6th Jan 2023.
 * All users who had done only Basic Level Verification before kycLevelOneInvalidationDate still have trading privileges.
 * These users will lose trading privileges on 31st March 2023, so they need to be shown sufficient warning urging them to upgrade their KYC.
 */
const shouldShowKycUpgradeWarning = user =>
  !includes(user.country, COUNTRIES_WITH_SINGLE_VERIFICATION_FLOW) &&
  isUserOnlyLevelOneVerified(user) &&
  isUserPhoneVerifiedBeforeLevelOneInvalidation(user);

/**
 * @summary Determines if user should see KYC Upgrade Warning - Countdown Strip.
 * @description Countdown Strip starts showing two days before 31st March 2023 for the users who should see KYC Upgrade Warning.
 */
const shouldShowKycUpgradeStrip = (user, other) => {
  const { showKycUpgradeStrip } = other;

  const TWO_DAYS_IN_SECONDS = 48 * 60 * 60;

  const timeLeftToTradingBlocked = unixTimeDiffInSeconds(
    KYC_UPGRADE_TRADING_BLOCKED_DATE
  );
  const timeHasComeToShowStrip =
    timeLeftToTradingBlocked > 0 && timeLeftToTradingBlocked <= TWO_DAYS_IN_SECONDS;

  return (
    shouldShowKycUpgradeWarning(user) && showKycUpgradeStrip && timeHasComeToShowStrip
  );
};

const isDepositDisabled = user => {
  let depositDisabled;
  if (user.is_kyc_refresh_required) {
    depositDisabled = !isUserVerified(user);
  }

  if (isUserIndian(user)) {
    depositDisabled = !isUserVerified(user);
  } else {
    depositDisabled = !isUserAtleastOneLevelVerified(user);
  }
  return depositDisabled;
};

const getWithdrawalDisabledStatus = (user, totalBalance) => {
  /**
   * Verified User: (In Context of Withdrawal)
   * 1. Indian = (is_kyc_done = true) OR (is_kyc_refresh_required = true)
   * 2. Non-Indian = (isUserAtleastOneLevelVerified) OR (is_kyc_refresh_required = true)
   *
   * Withdrawal Disabled:
   * 1. Indian User: If Un-verified
   * 2. Non-Indian User: If Un-verified AND (TotalBalance = 0 OR AvailableLimit = 0)
   */
  let isWithdrawalDisabled;
  const isIndianUser = isUserIndian(user);
  const { is_kyc_done, is_kyc_refresh_required, availableLimit } = user;

  if (isIndianUser) {
    const isUserVerified = is_kyc_done || is_kyc_refresh_required;
    isWithdrawalDisabled = !isUserVerified;
  } else {
    const isUserVerified = isUserAtleastOneLevelVerified(user) || is_kyc_refresh_required;
    isWithdrawalDisabled =
      !isUserVerified && (Number(totalBalance) === 0 || Number(availableLimit) === 0);
  }

  return isWithdrawalDisabled;
};

const isConvertBlocked = ({
  isVerified,
  isKycRefreshRequired,
  country,
  isUserSpotTradingDisabled,
}) => {
  /** Convert feature is Blocked for following cases:
   * 1. Spot Trading is Disabled
   * 2. User Country is Singapore
   * 3. User is Unverified AND not marked for Kyc Refresh
   */
  if (isUserSpotTradingDisabled) {
    return true;
  }

  if (country === 'Singapore') {
    return true;
  }

  if (!isVerified && !isKycRefreshRequired) {
    return true;
  }

  return false;
};

const refreshKycPostDeadlineDetails = (user, totalBalance) => {
  const isWithdrawalDisabled = getWithdrawalDisabledStatus(user, totalBalance);

  return [
    {
      id: 'Trading Privileges',
      value: 'Reduce only mode',
    },
    {
      id: 'Deposits',
      value: 'Disabled',
    },
    {
      id: 'Withdrawals',
      value: isWithdrawalDisabled ? 'Disbaled' : 'Enabled',
      showAmount: !isWithdrawalDisabled,
    },
  ];
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {"ios" | "android" | "windows" | "unknown"}
 */
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};

const isMobileDeviceOnly = () => {
  const os = getMobileOperatingSystem();
  return os !== 'unknown';
};

const isAndroidOrIOSDevice = () => {
  const os = getMobileOperatingSystem();
  return os === 'android' || os === 'ios';
};

const isAndroidDevice = () => {
  const os = getMobileOperatingSystem();
  return os === 'android';
};

const isIOSDevice = () => {
  const os = getMobileOperatingSystem();
  return os === 'ios';
};

// const replaceArrayOfObjects = (currentArray, newArray, uniqueField = 'id') => {

//   const newArr = currentArray.map(obj => newArray.find(o => o[uniqueField] === obj[uniqueField]) || obj);
//   console.log("DEBUG: new arry ", newArr);
//   return newArr;
// }
const replaceArrayOfObjects = (currentArray, newArray, uniqueField = 'id') =>
  currentArray.map(obj => newArray.find(o => o[uniqueField] === obj[uniqueField]) || obj);

const reduceArrayOfObjectsByKey = (data, key) =>
  reduce((a, b) => Number(a) + Number(b), 0, pluck(key)(data));

const isSafari = () => {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
  );
};

// roundingMode 0 = round up
// roundingMode 1 = round down
const roundToNearest = (numberToRound, roundValue, roundingMode = 0) => {
  const integerValue = Math.round(numberToRound / roundValue);
  if (roundingMode === 1) return roundValue * Math.floor(integerValue);
  return roundValue * Math.ceil(integerValue);
};

const getOrderPlacingBracketOrderVisibility = () => {
  return (
    JSON.parse(
      localStorage.getItem(STORAGE_KEYS.ORDER_PLACING_BRACKET_ORDER_VISIBILITY)
    ) ?? false
  );
};

const persistRoot = localStorage.getItem(STORAGE_KEYS.PERSIST_ROOT);

const localUserObject = JSON.parse(persistRoot)?.user;

/**
 * Finds max value in an array. Use when array is large. Else Math.max(...arr) is good enough
 */
const maxValueInArray = items => {
  if (!Array.isArray(items) || !items?.length) {
    return '';
  }
  return items.reduce((a, b) => {
    return Math.max(a, b);
  });
};

/**
 * Given an array of elements, it returns an array with previous and next elements given the count.
 *
 * @example
 * ```js
 *  const arr = [1,2,3,4,5,6,7,8];
 *
 *  const itemsByCount = getPrevAndNextFromMidArray(arr, 2)
 *
 *  @returns [3,4,5,6,7] i.e 2 items before and after 5(5 being the middle element)
 *
 * ```
 */
const getPrevAndNextFromMidArray = (array, noOfItems) => {
  if (!Array.isArray(array)) {
    return [];
  }

  const midIndex = Math.floor(array.length / 2);

  const arrayFirstHalf = array.slice(0, midIndex);
  const arraySecondHalf = array.slice(midIndex);

  const itemsByCountSecondHalf = arraySecondHalf.slice(0, noOfItems + 1);
  const itemsByCountFirstHalf = arrayFirstHalf.slice(noOfItems);

  const combinedArray = [...itemsByCountFirstHalf, ...itemsByCountSecondHalf];

  return combinedArray;
};

/**
 * Return a slugified copy of a string.
 *
 * @param {string} str The string to be slugified
 * @return {string} The slugified string.
 *
 * @example
 * ```js
 * toSlug('Delta Exchange') -> delta-exchange
 * ```
 */
function toSlug(str) {
  let s = str;
  if (!s) {
    return '';
  }
  s = s.toLowerCase().trim();
  s = s
    .replace(/ & /g, ' and ')
    .replace(/[ ]+/g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^a-z0-9-]+/g, '');
  return s;
}

/**
 * Returns length of decimal digits
 *
 * @example
 * ```js
 * afterDecimalLength(1) -> 0
 * afterDecimalLength(1.0) -> 0
 * afterDecimalLength(1.123) -> 3
 * ```
 */
function afterDecimalLength(num) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const numStr = String(num);

  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
}

const convertProductSettlementTimeToMilliseconds = pipe(
  getDateTime,
  toUTC,
  time => dateFormat(time, TIME_FORMATS.DD_MMM_YYYY),
  getTimestampInMS
);

const convertFromINRtoUSD = (amount, conversionRate) =>
  (amount / conversionRate).toFixed(2);

const convertFromUSDtoINR = (amount, conversionRate) =>
  String(Math.floor(Number(amount) * Number(conversionRate) * 100) / 100);

/**
 * @description Generates string containing stars of specified length.
 */
const getMaskedContent = contentLength => '*'.repeat(contentLength);

const addBaseURL = path => `/app${path}`;

/*
 * Update Password Check Date - 24 March 2023
 * show update password popup, if the user password_updated_at is before check date or
 * if password_updated_at is null then it means the user can be either new(registered after check date) or old(registered before check date),
 * in this case check if registration data is before our check date.
 */
// const showResetPasswordPopup = user => {
//   const updatePasswordCheckDate = getDateTime(new Date('2023-03-24T12:00:00.000Z'));
//   const passwordUpdatedAt = user?.password_updated_at
//     ? getDateTime(user?.password_updated_at)
//     : null;
//   const registrationDate = user?.profile?.registration_date
//     ? getDateTime(user?.profile?.registration_date)
//     : null;

//   if (getLoggedUserType(user) === LoggedUserType.SWITCHED_SUB_ACCOUNT) return false;

//   if (!passwordUpdatedAt || !registrationDate) return false;

//   if (registrationDate) {
//     const isOldUser =
//       getDiff(registrationDate, updatePasswordCheckDate, 'milliseconds') < 0;
//     return isOldUser;
//   }

//   const shouldShowPopup =
//     getDiff(passwordUpdatedAt, updatePasswordCheckDate, 'milliseconds') < 0;
//   return shouldShowPopup;
// };

/**
 * @description Takes in any number and converts it into a string with `+/-` at start and `%` at end.
 * Also handles precision for the number.
 * @param {number} amount - Any valid number.
 * @param {number} precision - Number of decimals.
 * @example
 * ```typescript
 * getFormattedChangeAmount(-123.456789) => `-123.45%`
 * getFormattedChangeAmount(123.456789) => `+123.45%`
 * ```
 */
const getFormattedChangeAmount = (amount, precision = 2) => {
  const preciseAndWithoutExponent = handleExponentialAndCropDecimals(
    String(amount),
    precision
  );
  const parsedNumber = Number(preciseAndWithoutExponent);
  const absoluteNumber = Math.abs(parsedNumber);
  const isPositiveAmount = parsedNumber >= 0;
  const formattedText = isPositiveAmount ? `+${absoluteNumber}%` : `-${absoluteNumber}%`;
  return formattedText;
};

const scientificToDecimalNotation = num =>
  num.toLocaleString('en-US', {
    useGrouping: false,
    maximumFractionDigits: 20,
  });

/**
 * Reset2fa check date - 24 March 2023
 * @param {boolean} isloggedin
 * @param {import('types/IUser').IUserKernel} user
 * @returns {boolean}
 * true
 * - if user is logged in and
 * - if user is old user (registered before 24 March 2023) and
 * - if user has enabled 2fa and
 * - if user has not updated 2fa after 24 March 2023 and
 * - if user has updated password
 * */
// const showResetMFaPopup = (isloggedin, user) => {
//   const update2faCheckDate = getDateTime(new Date('2023-03-24T12:00:00.000Z'));
//   const mfaUpdatedAt = user?.mfa_updated_at ? getDateTime(user?.mfa_updated_at) : null;
//   const registrationDate = user?.profile?.registration_date
//     ? getDateTime(user?.profile?.registration_date)
//     : null;
//   const isMfaEnabled = user?.isMfaEnabled;

//   if (isTestNet()) return false;

//   if (!isloggedin) return false;

//   if (showResetPasswordPopup(user) && user?.showPasswordChangePopup) return false;

//   if (!isMfaEnabled) return false;

//   console.log('DEBUG', {
//     user,
//     mfaUpdatedAt,
//     registrationDate,
//     shouldShowPopup: getDiff(mfaUpdatedAt, update2faCheckDate, 'milliseconds') < 0,
//     isOldUser: getDiff(registrationDate, update2faCheckDate, 'milliseconds') < 0,
//     showPasswordChangePopup: user?.showPasswordChangePopup,
//     showResetPasswordPopup: showResetPasswordPopup(user),
//     show2faResetPopup: user?.show2faResetPopup,
//     kycdone: user?.is_kyc_done,
//   });

//   if (registrationDate) {
//     const isOldUser = getDiff(registrationDate, update2faCheckDate, 'milliseconds') < 0;
//     return isOldUser;
//   }

//   const shouldShowPopup = getDiff(mfaUpdatedAt, update2faCheckDate, 'milliseconds') < 0;
//   return (
//     user?.is_kyc_done && user?.isMfaEnabled && user?.show2faResetPopup && shouldShowPopup
//   );
// };

const isOutdatedSecurity = date =>
  date
    ? getDiff(
        getDateTime(date),
        getDateTime(SECURITY_UPDATION_CHECK_DATE),
        'milliseconds'
      ) < 0
    : false;

const isAppleDevice = () => {
  const userAgentStr = window.navigator.userAgent;

  for (let index = 0; index < APPLE_DEVICES.length; index++) {
    if (userAgentStr.indexOf(APPLE_DEVICES[index]) >= 0) return true;
  }

  return false;
};

const isUserSpotTradingBlocked = userPermissions => userPermissions?.spot === 'disabled';

const isUserBankWithdrawalPermissionDisabled = userPermissions =>
  /**
   * (is_bank_withdrawal_allowed: false) AND (fiat_withdrawal: 'disabled') both represent the same thing.
   * is_bank_withdrawal_allowed will be removed in future.
   * NOTE: Both keys are undefined when Bank withdrawal permission is enabled.
   */
  userPermissions?.is_bank_withdrawal_allowed === false ||
  userPermissions?.fiat_withdrawal === 'disabled';

const isUserWithdrawalPermissionDisabled = user => {
  const { permissions } = user;
  return permissions?.withdrawal === 'disabled';
};

/**
 * @example
 * ```
 * const params = {
     param1: 'value1',
     param2: 'value2',
   };
   const url = constructQueryString('https://example.com/api', params)
 * ```
 */
function constructQueryString(url, params) {
  if (!url) {
    return '';
  }
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return `${url}?${queryString}`;
}

function readValueFromStorage(key) {
  if (typeof window === 'undefined') {
    return undefined;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
  }
}

const isOptionsCombosSymbol = symbol => {
  if (typeof symbol !== 'string') {
    return false;
  }

  return symbol?.startsWith('CS-') || symbol?.startsWith('PS-');
};

const isOptionsChainSymbol = symbol => {
  if (typeof symbol !== 'string') {
    return false;
  }

  return symbol?.startsWith('C-') || symbol?.startsWith('P-');
};

/** @param {string} [vendorName] */
const getVendorDisplayName = vendorName => {
  switch (vendorName) {
    case 'onmeta':
      return 'Onmeta';
    case 'simplex':
      return 'Simplex';
    case 'neo_fi':
      return 'Neofi';
    case 'alpyne':
      return 'Alpyne';
    case 'mudrex':
      return 'Mudrex';
    default:
      return vendorName;
  }
};

const addZeroBeforeDecimal = value => {
  if (value?.startsWith?.('.')) {
    return `0${value}`;
  }
  return value;
};

const removeSpaces = value => value.replace(/\s/g, '');

const isValidValue = (val, numberType, negativeValueAllowed) => {
  if (numberType === NUMBER_TYPES.decimal) {
    if (Number.isNaN(parseFloat(val)) && val !== '.' && val !== '-.' && val !== ',')
      return false;
    if (val.includes('.') && val[val.length - 1] === ',') return false;
    if (val[0] === '-' && !negativeValueAllowed) return false;
    return !(val[0] !== '-' && parseFloat(val) < 0 && !negativeValueAllowed);
  }
  if (numberType === NUMBER_TYPES.natural) {
    return /^[0-9]+$/.test(val);
  }
  return true;
};

const downloadFileFromLink = (link, fileName = 'downloaded-file') => {
  const a = document.createElement('a');
  a.href = link;

  // Extract filename from the S3 link or provide a default filename
  const filename = link.split('/').pop() || fileName;

  a.download = filename;
  a.click();
};

const validatePhoneNumber = phoneNumber => {
  const regexToCheckNumbers = /^[0-9]{7,16}$/;

  return regexToCheckNumbers.test(phoneNumber);
};

const validateIndianPhoneNumber = phoneNumber => /^[6789]\d{9}$/.test(phoneNumber);

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  return keys1.every(key => keys2.includes(key) && deepEqual(obj1[key], obj2[key]));
};

/**
 The `otpauth` URL scheme is used to transfer the secret key to the authenticator app.
 The URL contains the following components:
 - 'totp': Indicates that the type of OTP being used is TOTP.
 - `issuer`: The name of the provider or service the account is associated with.
 - `accountName`: The name or identifier of the user's account.
 - `secret`: The secret key used to generate the OTPs.
 - `issuer` (as a parameter): Helps the authenticator app to organize and display entries.
 */
const generateQRCodeSecretUrl = ({ secret, accountName, issuer }) => {
  return `otpauth://totp/${issuer}:${accountName}?secret=${secret}&issuer=${issuer}`;
};

const generateSecretCode = () => {
  const randomBuffer = window.crypto.getRandomValues(new Uint8Array(10));
  const secretCode = base32Encode(randomBuffer, 'RFC4648', { padding: false });
  return secretCode;
};

const pluralize = ({
  count,
  noun,
  exclusions = [1],
  suffix = 's',
  isCountShown = true,
}) => `${isCountShown ? count : ''} ${noun}${!exclusions.includes(count) ? suffix : ''}`;

/** 
 -The below function retrives the timestamp from local storage when the state update modal was lat populated.
 -In the if condition we check the time difference between current time and time extracted from local storage.
 -If the time difference is greater than 24 hours than we intend to populate the state modal.
 - We return userHasSeenModalInLast24Hours and use it in shouldShowStateUpdateModal function.
 */
const hasUserSeenStateUpdateModalIn24Hours = () => {
  const modalLastSeenTimeInLocalStorage = localStorage.getItem(
    STORAGE_KEYS.STATE_OF_RESIDENCE_MODAL_CLOSED_TIME
  );
  let userHasSeenModalInLast24Hours = false;

  if (modalLastSeenTimeInLocalStorage) {
    const currentTime = timeNow();
    const timeDiff = getDiff(
      currentTime,
      getDateTime(Number(modalLastSeenTimeInLocalStorage)),
      'seconds'
    );
    userHasSeenModalInLast24Hours = Number(timeDiff) < TWENTY_FOUR_HOURS;
  }

  return userHasSeenModalInLast24Hours;
};

// The below function evaluates whether to show the show the state update modal or not.
const shouldShowStateUpdateModal = user => {
  return (
    IS_INDIAN_EXCHANGE &&
    user.is_kyc_done &&
    user.region === null &&
    !user.is_sub_account &&
    !hasUserSeenStateUpdateModalIn24Hours()
  );
};
/**
 * * EXPORT ALL FUNCTIONS BELOW
 */

export {
  abbreviateLargeNumber,
  abbreviateLargeNumberIncludingNegative,
  abbreviateNumberToIndianDecimalSystem,
  addArrayItemByIndex,
  addBaseURL,
  addZeroBeforeDecimal,
  addZeroesUntilCorrectPrecision,
  adjustDecimal,
  afterDecimalLength,
  appendQueryParamsToRouterLocation,
  camelCaseToSentence,
  checkPasswordLength,
  compareDates,
  constructQueryString,
  convertFromINRtoUSD,
  convertFromUSDtoINR,
  convertProductSettlementTimeToMilliseconds,
  convertTimeToMilliseconds,
  convertUSDtoBTC,
  cropAfterDecimals,
  decimalMultiplication,
  deepEqual,
  dispatchGTMEvent,
  downloadFileFromLink,
  formatDuration,
  formatTime,
  generateQRCodeSecretUrl,
  generateSecretCode,
  getBalanceBySymbol,
  getDefaultPrecision,
  // getDeviceId,
  getDeviceType,
  getDurationFromNow,
  getFormattedChangeAmount,
  getLoggedUserType,
  getMaskedContent,
  getMobileOperatingSystem,
  getMomentTimeFormat,
  getOrderPlacingBracketOrderVisibility,
  getPasswordScore,
  getPercentageUpdated,
  getPrevAndNextFromMidArray,
  getProductSymbol,
  getSymbolForSpotContract,
  getUSDT,
  getUsdtSettledSpotIndex,
  getUserAccountNameById,
  getVendorDisplayName,
  getWithdrawalDisabledStatus,
  goChartingUserDataObj,
  handleExponentialAndCropDecimals,
  handleNaNAndCropDecimals,
  indianNumberCommaSeparator,
  isAndroidDevice,
  isAndroidOrIOSDevice,
  isAppleDevice,
  isConvertBlocked,
  isDepositDisabled,
  isEmpty,
  isIOSDevice,
  isMobileDeviceOnly,
  isNan,
  isOptionsChainSymbol,
  isOptionsCombosSymbol,
  // showResetPasswordPopup,
  // showResetMFaPopup,
  isOutdatedSecurity,
  isPositive,
  isSafari,
  isSpotContract,
  isUserAtleastOneLevelVerified,
  isUserBankWithdrawalPermissionDisabled,
  isUserNonIndianAndDormant,
  isUserNonIndianAndReduceOnly,
  isUserOnlyLevelOneVerified,
  isUserPhoneVerifiedBeforeLevelOneInvalidation,
  isUserSpotTradingBlocked,
  isUserVerified,
  isUserWithdrawalPermissionDisabled,
  isValidIPV4,
  isValidIPV6,
  isValidValue,
  isValidateIP,
  isVisibleInViewport,
  kycUpgradePopupLastOpenedOneDayAgo,
  localUserObject,
  maxValueInArray,
  noop,
  numberCommaSeparator,
  numberCommaSeparatorForPF,
  numberPrettified,
  parseErrorMsg,
  percentageForIMAndMM,
  persistRoot,
  pluralize,
  readValueFromStorage,
  reduceArrayOfObjectsByKey,
  refreshKycPostDeadlineDetails,
  refreshPage,
  removeArrayItemByIndex,
  removeSpaces,
  replaceArrayOfObjects,
  returnZeroIfNegative,
  roundByNumberValue,
  roundByTickSize,
  roundToNearest,
  scientificToDecimalNotation,
  sentenceToCamelCase,
  setFloat,
  shouldKYCPopupAppear,
  shouldShowKycUpgradeStrip,
  shouldShowKycUpgradeWarning,
  shouldShowStateUpdateModal,
  snakeCaseToSentenceCase,
  sortFundsByStats,
  sortStrategiesByFundStats,
  times,
  toFixed,
  toSlug,
  trailZero,
  truncAfterDecimal,
  unixTimeDiffInSeconds,
  validateIndianPhoneNumber,
  validatePhoneNumber,
  windowWidth
};

