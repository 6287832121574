import { useState } from 'react';

import { useMenuState } from '@szhsin/react-menu';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getKycStatus, updateProfile } from 'actions/user';
import { indianStatesAndUnionTerritories } from 'components/KycShareConcent/indianStatesList';
import useKycShareConcent from 'components/KycShareConcent/useKycShareConcent';
import { STORAGE_KEYS } from 'constants/enums';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import { isEmpty, parseErrorMsg } from 'helpers';
import { dayjs } from 'helpers/day';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { addLogsInSentryAndMixpanel } from 'helpers/sentry';
import { useAppSelector, useLocalStorage, useWindowSize } from 'hooks';
import { userSelector } from 'selectors';
import { ArrowDown } from 'styles/assets/icons';
import { Box, MaterialConfirmDialog } from 'UIKit';
import FilterDropDown from 'UIKit/FilterDropDown';
import type { Option } from 'UIKit/FilterDropDown/FilterDropDown';

import styles from './stateOfResidenceModal.module.scss';

const StateOfResidenceModal = () => {
  const { t } = useTranslation(['account', 'common', 'errors']);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { stateOfResidence, setStateOfResidence } = useKycShareConcent();
  const [menuState, toggleMenu] = useMenuState();
  const [isOpen, setIsOpen] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setStateOfResidenceModalCloseTime] = useLocalStorage(
    STORAGE_KEYS.STATE_OF_RESIDENCE_MODAL_CLOSED_TIME,
    ''
  );
  const user = useAppSelector(userSelector);
  const { id, email } = user;

  const onStateOptionClicked = (state: string) => {
    setStateOfResidence(state);
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STATE_POPUP_STATE_SELECTED, {
      state,
    });
  };

  const options: Option[] = indianStatesAndUnionTerritories.map(state => {
    return {
      label: state,
      id: state,
      onClick: () => onStateOptionClicked(state),
      checked: stateOfResidence === state,
      dataTestId: state,
    };
  });

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleClose = () => {
    setIsOpen(false);
    const currentTime = dayjs().valueOf(); // Get current timestamp in milliseconds
    setStateOfResidenceModalCloseTime(currentTime);
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STATE_POPUP_CLOSED);
  };

  const onSubmit = async () => {
    if (stateOfResidence) {
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STATE_POPUP_STATE_SUBMITTED);
      try {
        setLoading(true);
        const updateProfileCall = await dispatch(
          updateProfile({ user_id: id, region: stateOfResidence })
        );
        if (updateProfileCall.success) {
          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STATE_POPUP_STATE_UPDATED, {
            state: stateOfResidence,
          });
          await dispatch(getKycStatus());
        }
        setIsOpen(false);
      } catch (profileUpdateError) {
        const errorObj = parseErrorMsg(profileUpdateError);
        const errorCode = errorObj?.error?.code ?? 'defaultMessage';
        setErrorMessage(errorCode);
        addLogsInSentryAndMixpanel(
          {
            errorCode,
            id,
            email,
          },
          MIXPANEL_EVENT_NAMES.STATE_POPUP_UPDATE_FAILED
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <MaterialConfirmDialog
      showCrossIcon
      open={isOpen}
      onClose={handleClose}
      variant="v2"
      fullWidth={isMobile}
      disableConfirmButton={!stateOfResidence || loading || errorMessage}
      headerClassName={styles.modalHeader}
      containerClassName={styles.modalContainer}
      footerClassName={styles.modalFooter}
      title={t('account:stateOfResidence')}
      titleClassName={styles.modalTitleClassName}
      confirmButtonText={t('common:submit')}
      confirmClassName={styles.agreeButton}
      contentClassName="disable-select"
      onConfirm={onSubmit}
    >
      <Box className={styles.subHeading}>{t('account:selectstateOfResidence')}</Box>
      <Box className={styles.dropDownContainer}>
        <Box className={styles.dropDownLabel}>
          {t('account:kycConcent.stateOfResidence')}
        </Box>

        <FilterDropDown
          menuState={menuState}
          toggleMenu={toggleMenu}
          options={options}
          label={stateOfResidence || ''}
          testId="state-dropdown"
          closeOnSelect
          className={styles.stateDropdownContainer}
          styleOverrideClasses={{
            button: cn(styles.button, {
              [styles.buttonErrorHighlighted]: errorMessage,
            }),
            labelSection: cn({
              [styles.labelSectionWhenResidence]: stateOfResidence,
              [styles.labelSectionWhenNoResidence]: !stateOfResidence,
            }),
            label: styles.label,
            dropDown: styles.dropDown,
          }}
          autoPosition
        />
      </Box>

      <Box as="p" renderWhen={!isEmpty(errorMessage)} className={styles.errorMessage}>
        {t(`errors:custom.${errorMessage}`)}
      </Box>

      <Box
        fontSize="sm2"
        textColor="secondary"
        className={styles.infoText}
        data-testid="R9agU_pIKKf9XHVzf6rYi"
        onClick={toggleInfo}
      >
        {t('account:whyThisInfo')}

        <ArrowDown
          className={cn({
            [styles.arrowUp]: showInfo,
            [styles.arrowDown]: !showInfo,
          })}
        />
      </Box>

      {showInfo && (
        <Box className={styles.infoElobrativeText}>
          {t('account:whyThisElobrativeInfo')}
        </Box>
      )}
    </MaterialConfirmDialog>
  );
};

export default StateOfResidenceModal;
