/* eslint-disable no-nested-ternary */
import React, { ReactNode, useCallback, memo, MouseEvent } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { isAuthenticated } from 'actions/user';
import { noop } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { useAppSelector, useFavorite } from 'hooks';
import { userSelector } from 'selectors';
import { FavoriteSelected } from 'styles/assets/icons';
import { IconButton, Box } from 'UIKit';

interface FavoriteOptionProps {
  children: ReactNode;
  callOptionId?: number;
  putOptionId?: number;
  putSymbol: string;
  callSymbol: string;
  putClassName: string;
  callClassName: string;
  activeClassName: string;
  isVisible: boolean;
  /**
   * Adds a blank div component to avoid alignment issue
   */
  addFallbackWhenEmpty: boolean;
}

const FavoriteOption = ({
  children,
  callOptionId,
  putOptionId,
  callSymbol,
  putSymbol,
  putClassName,
  callClassName,
  activeClassName,
  isVisible = false,
  addFallbackWhenEmpty = true,
}: FavoriteOptionProps) => {
  const user = useAppSelector(userSelector);
  const isLoggedIn = isAuthenticated(user);

  const { t } = useTranslation(['trading']);
  const { favoritesIds, toggleFavorite, loading } = useFavorite();

  const isCallOptionInFavorites = callOptionId && favoritesIds.includes(callOptionId);
  const isPutOptionInFavorites = putOptionId && favoritesIds.includes(putOptionId);

  const handleFavorite = useCallback(
    (id: number, symbol: string) => (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoggedIn || loading) {
        return;
      }

      const isExisting = favoritesIds.includes(id);

      const tracker = () => {
        trackMixpanelEvent(`Favorite ${isExisting ? 'UnMarked' : 'Marked'} - Trade`, {
          source: 'options-table',
          product_name: symbol,
        });
      };

      toggleFavorite(id, tracker);
    },
    [isLoggedIn, toggleFavorite, favoritesIds, loading]
  );

  return (
    <Box flexPosition="flex-center-align" gap={4}>
      <IconButton
        title={
          !callSymbol
            ? ''
            : isCallOptionInFavorites
            ? t('trading:removeSymbolFromFavorite', { symbol: callSymbol })
            : t('trading:addSymbolToFavorite', { symbol: callSymbol })
        }
        icon={
          isCallOptionInFavorites ? (
            <FavoriteSelected
              className={cx('active', {
                [activeClassName]: activeClassName,
                visible: isVisible,
              })}
            />
          ) : (
            <FavoriteSelected
              className={cx({
                visible: isVisible,
              })}
            />
          )
        }
        data-testid={`${callSymbol}-${
          isCallOptionInFavorites ? 'starIconInActive' : 'starIconNotInActive'
        }`}
        className={cx('call-favorite', {
          [callClassName]: callClassName,
          loading: loading && callOptionId,
        })}
        onClick={
          typeof callOptionId !== 'undefined'
            ? handleFavorite(callOptionId, callSymbol)
            : noop
        }
        onKeyDown={noop}
        renderWhen={Boolean(isLoggedIn && callOptionId)}
      />
      <Box
        renderWhen={isLoggedIn && addFallbackWhenEmpty && !callOptionId}
        style={{ width: 13, height: 17 }}
      />
      {children}
      <Box
        renderWhen={isLoggedIn && addFallbackWhenEmpty && !putOptionId}
        style={{ width: 13, height: 17 }}
      />
      <IconButton
        title={
          !putSymbol
            ? ''
            : isPutOptionInFavorites
            ? t('trading:removeSymbolFromFavorite', { symbol: putSymbol })
            : t('trading:addSymbolToFavorite', { symbol: putSymbol })
        }
        icon={
          isPutOptionInFavorites ? (
            <FavoriteSelected
              className={cx('active', {
                [activeClassName]: activeClassName,
                visible: isVisible,
              })}
            />
          ) : (
            <FavoriteSelected
              className={cx({
                visible: isVisible,
              })}
            />
          )
        }
        data-testid={`${putSymbol}-${
          isPutOptionInFavorites ? 'starIconInActive' : 'starIconNotInActive'
        }`}
        className={cx('put-favorite', {
          [putClassName]: putClassName,
          loading: loading && putOptionId,
        })}
        onClick={
          typeof putOptionId !== 'undefined'
            ? handleFavorite(putOptionId, callSymbol)
            : noop
        }
        onKeyDown={noop}
        renderWhen={Boolean(isLoggedIn && putOptionId)}
      />
    </Box>
  );
};

export default memo(FavoriteOption);
