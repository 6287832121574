/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { CONTRACT_TYPE } from 'constants/constants';
import { always, filter, map, pipe, prop, sort, uniq } from 'helpers/ramda';
import {
  convertProductSettlementTimeToMilliseconds,
  getChange,
  getPercentage,
  isCallPutOptions,
  isEmpty,
  isOptions,
  roundByTickSize,
} from 'helpers/utils';
import { Product } from 'types/IProducts';
import { ProductById } from 'types/ITrade';
import { ITicker, ITickerData, tickerKeyType } from 'types/IVariableStore';
import { wrapDynamicSelector, wrapSelector } from 'variableStore/helpers';
import store from 'variableStore/store';

import { oiSelectorById } from './priceSelectors';
import { productsSelector, selectedProductSelector } from './tradeSelectors';

export const tickerDataSelector = (): ITickerData => store.tickerData.tickerObj;

export const tickerDataSelectorById = (id: string) =>
  wrapDynamicSelector(
    [tickerDataSelector, productsSelector],
    // eslint-disable-next-line consistent-return
    (tickerData: ITickerData, products) => {
      if (!isEmpty(products) && !isEmpty(id)) {
        const { symbol } = products?.[id] ?? {};
        return tickerData[symbol];
      }
    }
  );

export const selectedProductTickerSelector = wrapSelector(
  [selectedProductSelector, tickerDataSelector],
  (selected_product: Product, tickerData: ITickerData) => {
    if (selected_product !== null && tickerData) {
      const { symbol } = selected_product;
      return tickerData[symbol!];
    }
    return null;
  }
);

export const selectedProductTickerSelectorByKey = (key: tickerKeyType) =>
  wrapDynamicSelector([selectedProductTickerSelector], (tickerData: ITickerData) => {
    const ticker = tickerData || {};
    return ticker[key];
  });

export const closeStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.close || '';
  });
// tickerKeySelector(symbol, 'close');

export const openStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.open || '';
  });

export const highStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.high || '';
  });

// tickerKeySelector(symbol, 'high');

export const lowStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.low || '';
  });
// tickerKeySelector(symbol, 'low');

export const turnoverStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.turnover_usd || '';
  });

// tickerKeySelector(symbol, 'turnover');

export const volumeStateBySymbolSelector = (symbol: string) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol];
    return obj?.volume || '';
  });

// tickerKeySelector(symbol, 'volume');

export const lastPriceSelector = ({ symbol, tick_size }) =>
  wrapDynamicSelector(
    [always(closeStateBySymbolSelector(symbol))],
    roundByTickSize(tick_size)
  );

export const lastPriceChangeSelector = ({ symbol }) =>
  wrapDynamicSelector(
    [
      always(closeStateBySymbolSelector(symbol)),
      always(openStateBySymbolSelector(symbol)),
    ],
    (close: number, open: number) => getPercentage(close, open)
  );

export const lastPriceChangeIRSSelector = ({ symbol }) =>
  wrapDynamicSelector(
    [
      always(closeStateBySymbolSelector(symbol)),
      always(openStateBySymbolSelector(symbol)),
    ],
    (close: number, open: number) => getChange(close, open)
  );

export const highSelector = ({ symbol, tick_size }) =>
  wrapDynamicSelector(
    [always(highStateBySymbolSelector(symbol))],
    roundByTickSize(tick_size)
  );

export const lowSelector = ({ symbol, tick_size }) =>
  wrapDynamicSelector(
    [always(lowStateBySymbolSelector(symbol))],
    roundByTickSize(tick_size)
  );

export const openPriceSelector = ({ symbol, tick_size }) =>
  wrapDynamicSelector(
    [always(openStateBySymbolSelector(symbol))],
    roundByTickSize(tick_size)
  );

/**
 * @description selector that return's tickers of contracts which pass the supplied predicate function.
 * It takes products from redux store and tickers from easy state then filters those tickers.
 * @param contractTypePredicate - predicate function that takes contract_type field and returns a boolean value.
 * @example
 * ```ts
 * const allSpotContractTickers = selectFilteredTickersByContractType(
 *  contractType => contractType === 'spot'
 * );
 * ```
 */
const selectFilteredTickersByContractType = (
  predicate: (contractType: string) => boolean
) => {
  const finalSelector = wrapDynamicSelector(
    [productsSelector, tickerDataSelector],
    (products: ProductById, tickers: ITickerData) => {
      const allTickers = Object.values(tickers);

      return allTickers.filter(({ product_id }) => {
        const currentProduct = product_id ? products[product_id] : null;
        if (!currentProduct) {
          return false;
        }
        return (
          currentProduct &&
          predicate(String(currentProduct.contract_type)) &&
          currentProduct.state === 'live'
        );
      });
    }
  ) as ITicker[];

  return finalSelector;
};

const selectAllOptionsChainContractsTickers = () =>
  selectFilteredTickersByContractType(isCallPutOptions);

/**
 * @description selector that returns all unique expiry's for options contracts for provided asset symbol in sorted order.
 */
const selectOptionsContractsExpiryList = createSelector(
  [productsSelector, (state, assetSymbol: string) => assetSymbol],
  (products, assetSymbol) => {
    const allProducts = Object.values(products) as Product[];

    const transformationPipeline = pipe(
      filter(
        (product: Product) =>
          isOptions(product?.contract_type) &&
          product.underlying_asset?.symbol === assetSymbol
      ),
      map(({ settlement_time }) =>
        convertProductSettlementTimeToMilliseconds(settlement_time!)
      ),
      uniq,
      sort((a, b) => a - b)
    );

    return transformationPipeline(allProducts);
  }
);

const totalOptionsOpenInterestSelector = () =>
  wrapDynamicSelector(
    [selectAllOptionsChainContractsTickers],
    (allOptionsTicker: ITicker[]) =>
      allOptionsTicker.reduce((accumulated, current) => {
        const productId = String(current.product_id);
        return Number(oiSelectorById(productId)) + accumulated;
      }, 0)
  ) as number;

const tickerDataSelectorBySymbol = ({ symbol }: { symbol: string }) =>
  wrapDynamicSelector([tickerDataSelector], (tickersBySymbol: ITickerData) => {
    const obj: ITicker = tickersBySymbol[symbol] ?? {};
    return obj;
  });

// created here to avaoid circular dependency
export const lastPriceChangeCalculator = product => {
  const { contract_type } = product;
  return contract_type === CONTRACT_TYPE.IRS
    ? lastPriceChangeIRSSelector(product)
    : lastPriceChangeSelector(product);
};

export const sortByLastPrice = direction => (firstProduct, secondProduct) => {
  const firstPrice = lastPriceSelector(firstProduct) || 0;
  const secondPrice = lastPriceSelector(secondProduct) || 0;

  return direction === 'asc' ? firstPrice - secondPrice : secondPrice - firstPrice;
};

export const sortProductsByVolume = direction => (firstProduct, secondProduct) => {
  const firstVolume = turnoverStateBySymbolSelector(firstProduct.symbol) || 0;
  const secondVolume = turnoverStateBySymbolSelector(secondProduct.symbol) || 0;
  return direction === 'asc' ? secondVolume - firstVolume : firstVolume - secondVolume;
};

export const sortProductsByLastPriceChange =
  direction => (firstProduct, secondProduct) => {
    const firstPriceChange = lastPriceChangeCalculator(firstProduct) || 0;
    const secondPriceChange = lastPriceChangeCalculator(secondProduct) || 0;
    return direction === 'asc'
      ? Number(secondPriceChange) - Number(firstPriceChange)
      : Number(firstPriceChange) - Number(secondPriceChange);
  };

export const getTrendingProds = productsList => {
  const futuresList = filter(
    product => product.contract_type.includes('futures'),
    productsList
  );

  const sortedFutures = [...futuresList].sort(sortProductsByLastPriceChange('asc'));

  const trendingProdsList = [...sortedFutures.slice(0, 6)];

  return trendingProdsList;
};

export const fundingRateSelector = ({ id }) => {
  const fundingRate = Number(
    wrapDynamicSelector([always(tickerDataSelectorById(id))], prop('funding_rate'))
  );
  return fundingRate ? fundingRate.toFixed(4) : 0;
};

export {
  selectAllOptionsChainContractsTickers,
  selectFilteredTickersByContractType,
  selectOptionsContractsExpiryList,
  tickerDataSelectorBySymbol,
  totalOptionsOpenInterestSelector,
};
