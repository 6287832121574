import React from 'react';

import styled from 'styled-components';

const SwitchSection = styled.div`
  margin: 0 auto;

  .switch_box {
    line-height: 0;
  }

  .input_wrapper {
    position: relative;
    cursor: pointer;
    width: fit-content;
  }

  .input_wrapper input[type='checkbox'] {
    width: 29px;
    height: 15px;
    cursor: pointer;
    appearance: none;
    background-color: var(--toggleSwitchOffBackground);
    border-radius: 25px;
    position: relative;
    outline: 0;
    transition: all 0.2s;
  }

  .input_wrapper input[type='checkbox']:after {
    position: absolute;
    content: '';
    top: 1.5px;
    left: 1px;
    width: 12px;
    height: 12px;
    background: var(--primaryCtaText);
    border-radius: 50%;
    transition: all 0.35s;
  }

  .alwaysDisable {
    opacity: 0.2;
  }

  /* Checked State */
  .input_wrapper input[type='checkbox']:checked {
    background: ${props => props.theme.positive};
  }

  .input_wrapper input[type='checkbox']:checked:after {
    left: calc(100% - 12px);
  }

  .input_wrapper input[type='checkbox']:checked + .is_checked {
    -webkit-transform: translateX(0) translateY(-30%) scale(1);
    transform: translateX(0) translateY(-30%) scale(1);
  }

  .input_wrapper input[type='checkbox']:checked ~ .is_unchecked {
    -webkit-transform: translateX(-190%) translateY(-30%) scale(0);
    transform: translateX(-190%) translateY(-30%) scale(0);
  }

  input[type='checkbox'] {
    &.disabled {
      background: var(--separator);
      cursor: not-allowed;

      &:after {
        background: #ccc;
      }
    }
  }
`;

const AntToggle = ({ checked, onChange, name, disabled = false }) => {
  return (
    <SwitchSection>
      <div className="switch_box box_4">
        <div className="input_wrapper">
          <input
            data-testid={name}
            type="checkbox"
            className={`switch_4 ${disabled ? `disabled` : ``}`}
            checked={checked}
            onChange={onChange}
            name={name}
            disabled={disabled}
            value={checked}
          />
        </div>
      </div>
    </SwitchSection>
  );
};

export default AntToggle;
