import { isMobile } from 'react-device-detect';

import { TIME_FORMATS } from 'constants/timeformats';
import { isNil, map } from 'helpers/ramda';
import i18n from 'i18n/config';

import { dateFormat } from './day';
import { capitalize, sentenceToCamelCase } from './utils';

export const shouldFetchNextTransactionPage = ({
  page,
  totalCount,
  pageSize,
  pageNumber,
}) => {
  return page * totalCount >= pageSize * pageNumber - totalCount;
};

export const getTransactionType = prop => {
  const { transaction_type: transactionType, asset_symbol: assetSymbol } = prop;

  if (isNil(transactionType)) {
    return '-';
  }

  const transactionTypeLabel = {
    perpetual_futures_funding: i18n.t('account:transactionCols.floatingRatePayment'),
    interest_rate_swaps: i18n.t('account:transactionCols.floatingRatePayment'),
    pnl: i18n.t(`account:transactionCols.cashflow`),
    commission: `${i18n.t(`account:transactionCols.tradingFee`)} (${assetSymbol})`,
    trading_fee_credits_paid: `${i18n.t(`account:transactionCols.tradingFee`)} (${i18n.t(
      `account:transactionCols.deltaCash`
    )})`,
    liquidation_fee: `${i18n.t(
      `account:transactionCols.liquidationFee`
    )} (${assetSymbol})`,
    trading_fee_credits_paid_liquidation_fee: `${i18n.t(
      `account:transactionCols.liquidationFee`
    )} (${i18n.t(`account:transactionCols.deltaCash`)})`,
  };

  if (transactionType in transactionTypeLabel) {
    return transactionTypeLabel[transactionType];
  }

  let label = sentenceToCamelCase(map(capitalize, transactionType.split('_')).join(' '));

  if (label === 'tradingCreditsPaid' || label === 'tradingCreditsForfeited') {
    label += isMobile ? 'Mobile' : 'Desktop';
  }

  return i18n.t(`account:transactionCols.${label}`);
};

export const getTransactionTime = value => {
  return dateFormat(value, `${TIME_FORMATS.MMM_DD_YYYY} ${TIME_FORMATS.h_mm_ss_A}`);
};
