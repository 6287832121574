import React, {
  memo,
  ReactNode,
  ReactText,
  MouseEvent,
  forwardRef,
  type ForwardedRef,
} from 'react';

import cx from 'classnames';

import styles from './tabButton.module.scss';

type TabClassKey = 'active' | 'root';

interface TabButtonProps {
  title: ReactText | ReactNode;
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  active: boolean;
  loading?: boolean;
  testId?: string;
  classes?: Partial<Record<TabClassKey, string>>;
  isParentPrimaryBackground?: boolean;
  'data-testid': string;
}

const TabButton = forwardRef(
  (
    {
      title,
      className,
      onClick,
      disabled,
      active,
      loading,
      testId,
      classes,
      isParentPrimaryBackground = false,
      'data-testid': tid,
    }: TabButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      onClick?.(event);
    };

    return (
      (<button
        ref={ref}
        role="tab"
        type="button"
        aria-selected={active ? 'true' : 'false'}
        tabIndex={0}
        onClick={handleClick}
        disabled={disabled}
        data-testid={tid || testId || 'tab-button'}
        className={cx(styles.tabBtn, {
          [styles.active]: active,
          [classes?.active!]: active,
          [styles.parentPrimaryBg]: isParentPrimaryBackground,
          [className!]: className,
          [styles.loading]: loading,
        })}
        data-palette="TabButton">
        {title}
      </button>)
    );
  }
);

export type { TabButtonProps };
export default memo(TabButton);
