import { createAction as actionCreator } from 'deox';

const createAction =
  (type: string | null | Record<string, any>) =>
  (payload: any = {}) => {
    // @ts-ignore
    return actionCreator(type, () => ({ payload }));
  };

export default createAction;
