import { useEffect, useRef } from 'react';

import { getKycStatus, updateProfile } from 'actions/user';
import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import { dispatchGTMEvent } from 'helpers/utils';
import {
  getUserId,
  selectUsdAssetAvailableBalance,
  selectUserTrackingInfo,
} from 'selectors';
import { userDepositsSelector } from 'selectors/otherSelectors';
import { selectSignupReferralCode } from 'selectors/user';
import { useAppDispatch, useAppSelector } from 'storeHooks';

/**
 * @description This key is maintained in profile tracking info as a flag.
 * Its used for determining whether user was already tracked previously.
 * If its true then user should not be tracked again for ftd.
 */
const FTD_KEY_IN_TRACKING_INFO = 'is_ftd_gtm_tracked';
const GTM_FTD_SUCCESS_EVENT_NAME = `delta_ftd_successful`;

const DEPOSIT_INFO_TRACKING_START_DATE = IS_INDIAN_EXCHANGE
  ? new Date(2024, 3, 12)
  : new Date(2022, 9, 19);

/**
 * @description Track first time deposit for global exchange.
 * It attaches ftd=yes in the url search params for google tag manager trigger and tracking.
 * It checks deposit_info in profile, whether it contains more than 1 deposit after a certain date.
 * If thats the case then updates backend profile flag for keeping a track that ftd is already tracked for this user.
 * No further tracking should happen for this user.
 */
const useTrackFirstTimeDepositFromDepositInfo = () => {
  const userId = useAppSelector(getUserId);
  const depositInfo = useAppSelector(userDepositsSelector);
  const signupReferralCode = useAppSelector(selectSignupReferralCode);
  const trackingInfo = useAppSelector(selectUserTrackingInfo);
  const appDispatch = useAppDispatch();
  const isValidTrackingInfo = trackingInfo && typeof trackingInfo === 'object';

  /**
   * Using this variable to keep a track whether a update request was already sent to backend.
   * So no more additional requests will be made during FTD GTM tracking effect.
   */
  const isFTDTrackingRequestSent = useRef(false);

  /**
   * On backend we are storing `is_ftd_gtm_tracked` whenever users first deposit is found after certain date for Google Tag Manager.
   * So this tracking is added to URL and stored on backend so we don't track it further for the same user.
   */
  const isFirstTimeDepositGTMTracked =
    isValidTrackingInfo && FTD_KEY_IN_TRACKING_INFO in trackingInfo
      ? Boolean(trackingInfo[FTD_KEY_IN_TRACKING_INFO])
      : false;

  const isEligbileForTracking = Boolean(
    !isFirstTimeDepositGTMTracked &&
      !isFTDTrackingRequestSent.current &&
      Array.isArray(depositInfo) &&
      depositInfo.length > 0
  );

  useEffect(() => {
    async function trackFirstTimeDepositGTM() {
      try {
        // Deposits are not sorted from backend. So sorted it in ascending based on created_at time field.
        const allSortedDeposits =
          depositInfo?.slice().sort((firstDeposit, secondDeposit) => {
            const { created_at: firstCreatedAt } = firstDeposit;
            const { created_at: secondCreatedAt } = secondDeposit;
            return (
              new Date(firstCreatedAt).valueOf() - new Date(secondCreatedAt).valueOf()
            );
          }) || [];

        // Only track first time deposits after this date, irrespective of signup date.
        const startTrackingFromDate = DEPOSIT_INFO_TRACKING_START_DATE.valueOf();
        const firstDeposit = allSortedDeposits[0];
        const isFirstDepositAfterTrackingDate =
          firstDeposit && firstDeposit.created_at
            ? startTrackingFromDate < new Date(firstDeposit.created_at).valueOf()
            : false;

        if (isFirstDepositAfterTrackingDate) {
          isFTDTrackingRequestSent.current = true;
          await appDispatch(updateProfile({ [FTD_KEY_IN_TRACKING_INFO]: true }));
          dispatchGTMEvent(GTM_FTD_SUCCESS_EVENT_NAME, {
            userId,
            referralCode: signupReferralCode,
          });
          await appDispatch(getKycStatus());
        }
      } catch {}
    }

    /**
     *  - First deposit should be after startTrackingDate
     *  - In backend `is_ftd_gtm_tracked` in tracking_info should not be present or should be false.
     *  - If a request was already made to backend to update then do not make additional requests on dependencies changes.
     *  - There should be atleast 1 deposit.
     */
    if (isEligbileForTracking) {
      trackFirstTimeDepositGTM();
    }
  }, [isEligbileForTracking, depositInfo, appDispatch, userId, signupReferralCode]);
};

/**
 * @description Track first time deposit for indian exchange.
 * It attaches ftd=yes in the url search params for google tag manager trigger and tracking
 * It checks if user usd balances is greater than 0, then deposit has happened.
 * As that's the only way to increase balance ( Confirmed with prakash jeswani )
 */
const useTrackFirstTimeDepositFromUSDBalance = () => {
  const userId = useAppSelector(getUserId);
  const trackingInfo = useAppSelector(selectUserTrackingInfo);
  const appDispatch = useAppDispatch();
  const usdAvailableBalance = useAppSelector(selectUsdAssetAvailableBalance);
  const isValidTrackingInfo = trackingInfo && typeof trackingInfo === 'object';

  /**
   * Using this variable to keep a track whether a update request was already sent to backend.
   * So no more additional requests will be made during FTD GTM tracking effect.
   */
  const isFTDTrackingRequestSent = useRef(false);

  /**
   * On backend we are storing `is_ftd_gtm_tracked` whenever users first deposit is found after certain date for Google Tag Manager.
   * So this tracking is added to URL and stored on backend so we don't track it further for the same user.
   */
  const isFirstTimeDepositGTMTracked =
    isValidTrackingInfo && FTD_KEY_IN_TRACKING_INFO in trackingInfo
      ? Boolean(trackingInfo[FTD_KEY_IN_TRACKING_INFO])
      : false;

  const isEligbileForTracking = Boolean(
    !isFirstTimeDepositGTMTracked &&
      !isFTDTrackingRequestSent.current &&
      usdAvailableBalance &&
      Number(usdAvailableBalance) > 0
  );

  useEffect(() => {
    async function trackFirstTimeDepositGTM() {
      try {
        isFTDTrackingRequestSent.current = true;
        // If all condition passed we add ?ftd=yes in URL Params for GTM Tracking.
        await appDispatch(updateProfile({ [FTD_KEY_IN_TRACKING_INFO]: true }));
        dispatchGTMEvent(GTM_FTD_SUCCESS_EVENT_NAME, { userId });
        await appDispatch(getKycStatus());
      } catch {}
    }

    /**
     *  - First deposit should be after startTrackingDate
     *  - In backend `is_ftd_gtm_tracked` in tracking_info should not be present or should be false.
     *  - If a request was already made to backend to update then do not make additional requests on dependencies changes.
     */
    if (isEligbileForTracking) {
      trackFirstTimeDepositGTM();
    }
  }, [isEligbileForTracking, appDispatch, userId]);
};

export {
  useTrackFirstTimeDepositFromDepositInfo,
  useTrackFirstTimeDepositFromUSDBalance,
};
