import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { getBalanceEquity } from 'components/holdings_balance/helpers';
import { ASSET_SYMBOL } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { noop } from 'helpers';
import subscribe from 'HOC/subscribe';
import useTradeTabMobile from 'hooks/useTradeTabMobile';
import {
  // balanceBySymbolSelector,
  portfolioEnableMinBalanceSelector,
  selectedProductSelector,
  userMarginModeSelector,
  allOpenPositionsSelector,
  portfolioMarginSelector,
  openPositionMarkPriceSelectorById,
  enableWalletsSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import { CloseIcon, YellowInfoIcon } from 'styles/assets/icons';
import { ContractType } from 'types/IProducts';

import styles from './index.module.scss';

const AccountReduceOnlyModeWarning = () => {
  const { t } = useTranslation(['trading']);
  const selectedProduct = useAppSelector(state => selectedProductSelector(state));
  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  // const allBalances = useAppSelector(balanceBySymbolSelector);
  const pfThreshold = useAppSelector(portfolioEnableMinBalanceSelector);
  const allOpenPositions = useAppSelector(allOpenPositionsSelector);
  const portfolioMarginData = useAppSelector(portfolioMarginSelector);
  const balance = useAppSelector(enableWalletsSelector);
  const isTradeTabMobile = useTradeTabMobile();

  const { positions_upl: positionsUpl, asset_id: portfolioEnabledAsset } =
    portfolioMarginData?.aggregate || {};
  const getMarkPrice = productId => openPositionMarkPriceSelectorById(productId);
  // const { available_balance: availableBalance } = getBalanceBySymbol('USDT', allBalances);
  const [isCloseAccountReduce, setCloseAccountReduce] = useState(false);
  // const { pfThreshold, setCloseAccountReduce } = props;

  const equity = balance?.USDT
    ? getBalanceEquity(
        balance?.USDT,
        allOpenPositions,
        getMarkPrice,
        positionsUpl,
        portfolioEnabledAsset!
      )
    : 0;

  const showBanner =
    selectedProduct?.contract_type !== ContractType.Spot &&
    marginMode === MARGIN_MODE.PORTFOLIO &&
    Number(equity) <= Number(pfThreshold) &&
    !isCloseAccountReduce;

  useEffect(() => {
    setCloseAccountReduce(false);
  }, [selectedProduct?.id]);

  return showBanner ? (
    <div
      className={cn(styles.container, {
        [styles.inTradeTabMobile_container]: isTradeTabMobile,
      })}
      data-palette="AccountReduceOnlyModeWarning">
      <div className={styles.title}>
        <div>
          {/* @ts-ignore */}
          <YellowInfoIcon />
          <span>{t('trading:account_reduce_only')}</span>
        </div>
        <span
          data-testid="closeAccountReduceWarning"
          role="button"
          tabIndex={0}
          onKeyDown={noop}
          onClick={() => setCloseAccountReduce(true)}
          className={cn({ [styles.inTradeTabMobile_closeIcon]: isTradeTabMobile })}
        >
          {/* @ts-ignore */}
          <CloseIcon className={styles.close} />
        </span>
      </div>
      <div className={styles.desc}>
        {t('trading:account_reduce_only_desc', {
          pfThreshold,
          assetSymbol: ASSET_SYMBOL,
        })}
      </div>
    </div>
  ) : null;
};

export default subscribe(AccountReduceOnlyModeWarning);
