import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { COUNTRIES_WITH_SINGLE_VERIFICATION_FLOW } from 'constants/constants';
import {
  isKycRefreshExpired,
  isKycRequiredDeadlineCrossed,
  isUserIndian,
  isUserNonIndianAndReduceOnly,
} from 'helpers';
import { getDateTime, getDiff } from 'helpers/day';
import { useTotalAccountEquity } from 'hooks';
import { userSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';
import IUser from 'types/IUser';

type KYCVerificationType = {
  isVerified: boolean;
  label: string;
  verificationStatus: string;
};

interface KYCVerificationInterface {
  isReduceOnlyEnable: boolean;
  isUserIndian: boolean;
  isKycExpired: boolean;
  isKycRequired: boolean;
  isVerified: boolean;
  verificationStatus: string;
  isOldUser: boolean;
  isUserNonIndianAndOld: boolean;
  kycLabel: string;
  isPhoneVerified: boolean;
  isOnlyPhoneVerified: boolean;
  isKycRefreshRequired: boolean;
  isKycProvisioned: boolean;
  isUserOldAndUnverified: boolean;
  country: string;
}

/* Users registered before this date are considered as OLD Users  */
const KYC_UPDATE_DATE = '2023-01-06T12:30:00.000Z';

const KYC_LABELS = {
  BASIC: 'basic',
  VERIFIED: 'verified',
  VERIFIED_PLUS: 'verified_plus',
  UNVERIFIED: 'unverified',
};

const useKYC = (): KYCVerificationInterface => {
  const { t } = useTranslation(['account']);
  const user: IUser = useAppSelector(userSelector);
  // const totalBalance = useTotalAccountEquity();

  const isKycExpired = isKycRefreshExpired(user);
  const isKycRequired = isKycRequiredDeadlineCrossed(user);
  const isUserReduceOnlyMode = isUserNonIndianAndReduceOnly(user);
  const isIndian = isUserIndian(user);
  const {
    country,
    is_kyc_done: isKycDone,
    is_kyc_provisioned: isKycProvisioned,
    is_kyc_refresh_required: isKycRefreshRequired,
    phone_verification_status: phoneVerificationStatus,
    proof_of_identity_status: proofOfIdentityStatus,
    proof_of_address_status: proofOfAddressStatus,
  } = user;
  const isReduceOnlyEnable = isUserReduceOnlyMode || isKycExpired || isKycRequired;
  const isPhoneVerified = phoneVerificationStatus === 'verified';

  // functions
  const isOldUser = (): boolean => {
    const kycUpdateDate = new Date(KYC_UPDATE_DATE);
    const userRegistrationDate = getDateTime(user?.profile?.registration_date);
    const isUserRegisteredAfterKYCUpdateDate =
      getDiff(userRegistrationDate, getDateTime(kycUpdateDate), 'milliseconds') > 0;

    /* If user registered after KYC_UPDATE_DATE, then the user is new user */
    if (isUserRegisteredAfterKYCUpdateDate) return false;

    /*
      If user registered before KYC_UPDATE_DATE and
      1. phone number not verified till now, then its a new user
      2. phone number is verified after KYC_UPDATE_DATE, then its a new user
      3. phone number is verified before KYC_UPDATE_DATE, then its a new user
    */
    if (!isPhoneVerified) return false;
    const isPhoneVerifiedAfterKYCUpdateDate =
      getDiff(
        getDateTime(user?.phone_verified_on),
        getDateTime(kycUpdateDate),
        'milliseconds'
      ) > 0;

    return !isPhoneVerifiedAfterKYCUpdateDate;
  };

  const isOld = isOldUser();
  const isUserNonIndianAndOld = !isIndian && isOld;
  const isUserNonIndianAndNew = !isIndian && !isOld;

  const isOnlyPhoneVerified = useMemo((): boolean => {
    return (
      isPhoneVerified &&
      proofOfIdentityStatus !== 'approved' &&
      proofOfAddressStatus !== 'approved'
    );
  }, [proofOfAddressStatus, proofOfIdentityStatus, isPhoneVerified]);

  const getKYCStatus = (): KYCVerificationType => {
    const status: KYCVerificationType = {
      isVerified: false,
      label: KYC_LABELS.UNVERIFIED,
      verificationStatus: t('account:profile.unverified'),
    };

    if (COUNTRIES_WITH_SINGLE_VERIFICATION_FLOW.includes(country!)) {
      if (isKycDone) {
        status.isVerified = true;
        status.label = KYC_LABELS.VERIFIED_PLUS;
        status.verificationStatus = t('account:profile.kycLevelNames.verifiedPlus');
      }
    } else if (isUserNonIndianAndOld) {
      if (isOnlyPhoneVerified) {
        status.isVerified = true;
        status.label = KYC_LABELS.BASIC;
        status.verificationStatus = t('account:profile.kycLevelNames.basic');
      }

      if (proofOfIdentityStatus === 'approved' && proofOfAddressStatus !== 'approved') {
        status.isVerified = true;
        status.label = KYC_LABELS.VERIFIED;
        status.verificationStatus = t('account:profile.verified');
      }

      if (proofOfAddressStatus === 'approved') {
        status.isVerified = true;
        status.label = KYC_LABELS.VERIFIED_PLUS;
        status.verificationStatus = t('account:profile.kycLevelNames.verifiedPlus');
      }
    } else if (isUserNonIndianAndNew) {
      if (proofOfIdentityStatus === 'approved' && proofOfAddressStatus !== 'approved') {
        status.isVerified = true;
        status.label = KYC_LABELS.VERIFIED;
        status.verificationStatus = t('account:profile.kycLevelNames.verified');
      }

      if (proofOfAddressStatus === 'approved') {
        status.isVerified = true;
        status.label = KYC_LABELS.VERIFIED_PLUS;
        status.verificationStatus = t('account:profile.kycLevelNames.verifiedPlus');
      }
    } else {
      status.isVerified = false;
      status.label = KYC_LABELS.UNVERIFIED;
      status.verificationStatus = t('account:profile.unverified');
    }

    return status;
  };

  const { isVerified, verificationStatus, label: kycLabel } = getKYCStatus();

  return {
    isReduceOnlyEnable,
    isUserIndian: isIndian,
    isKycExpired,
    isKycRequired,
    isVerified,
    verificationStatus,
    isOldUser: isOld,
    isUserOldAndUnverified: isOld && !isVerified,
    isUserNonIndianAndOld,
    kycLabel,
    isPhoneVerified,
    isOnlyPhoneVerified,
    isKycRefreshRequired: isKycRefreshRequired!,
    isKycProvisioned: isKycProvisioned!,
    country: country!,
  };
};

export type { KYCVerificationInterface };
export { KYC_LABELS, KYC_UPDATE_DATE };
export default useKYC;
