/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { deleteProductFromBasket, editOrdersFromBasket } from 'actions/basketOrders';
import { calculateDefaultContractValue } from 'components/placeorder/partials/quantity/helper';
import Render from 'components/render';
import { ORDER_TYPE } from 'constants/enums';
import { noop } from 'helpers';
import {
  basketActiveUnderlyingAssetSelector,
  productsSelector,
  showBasketErrorSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import { SquareEditIcon } from 'styles/assets/icons';
import { OrdersObject } from 'types/basketOrders';
import { Box } from 'UIKit';

import { ValidationIconComponent } from '../helpers';
import { TrashIcon } from '../icons';
import MobileQuantityInputBox from '../mobileBasketOrders/mobileQuantityInputBox/quantityInput';
import BasketOrdersOrderbook from './basketOrdersOrderbook';
import styles from './orders.module.scss';
import PriceInput from './priceInput';

interface OrderbookObject {
  product_id: string | number;
  showOrderbook: boolean;
}
interface Props {
  order: OrdersObject;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  handleRowClick: any;
  currentOrderBook: OrderbookObject;
  setCurrentOrderBook: React.Dispatch<React.SetStateAction<OrderbookObject>>;
}

const MobileOrderPlacementRow = ({
  order,
  // responseCheck,
  setShow,
  show,
  handleRowClick,
  currentOrderBook,
  setCurrentOrderBook,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['basketOrders', 'trading']);
  const showError = useAppSelector(showBasketErrorSelector);
  const products = useAppSelector(state => productsSelector(state));
  const activeAssetSymbol = useAppSelector(basketActiveUnderlyingAssetSelector);

  const {
    size,
    side,
    symbol,
    product_id: productId,
    order_type: orderType,
    limit_price: limitPrice,
  } = order;

  const product = products[productId];

  const {
    contract_value: contractValue,
    notional_type: notionalType,
    underlying_asset: underlyingAsset,
    quoting_asset: quotingAsset,
  } = product;

  const quantityValue = calculateDefaultContractValue(
    size,
    contractValue,
    notionalType,
    quotingAsset,
    underlyingAsset
  );

  const showOrderBookRow =
    currentOrderBook.product_id === productId && currentOrderBook.showOrderbook;
  const showPriceError = showError && orderType === 'limit_order' && !limitPrice;
  const showQuantityError = showError && Number(size) <= 0;

  const handleBuySellClick = (orderData: OrdersObject, side: string) => {
    const newOrders = { ...orderData, side };
    dispatch(editOrdersFromBasket(newOrders));
  };

  const handleEditClick = (event: React.ChangeEvent<{}>) => {
    handleRowClick(event, order);
  };

  const handleDoneClick = () => {
    setCurrentOrderBook({
      product_id: productId,
      showOrderbook: false,
    });
  };

  const handleDeleteClick = (orderData: OrdersObject) => {
    dispatch(deleteProductFromBasket(orderData));
  };

  useEffect(() => {
    if (orderType === ORDER_TYPE.LIMIT && !limitPrice) {
      dispatch(
        editOrdersFromBasket({
          ...order,
          order_type: ORDER_TYPE.MARKET,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <Box className={styles['order-row']}>
      <Box className={styles.buySellColumn}>
        {side === 'buy' && (
          <button
            className={styles.buyButton}
            onClick={() => handleBuySellClick(order, 'sell')}
            type="button"
            data-testid="mobile-order-placement-buy-btn"
            data-palette="MobileOrderPlacementRow">
            B
          </button>
        )}
        {side === 'sell' && (
          <button
            className={styles.sellButton}
            onClick={() => handleBuySellClick(order, 'buy')}
            type="button"
            data-testid="mobile-basket-orders-order-placement-sell-btn"
            data-palette="MobileOrderPlacementRow">
            S
          </button>
        )}
        <div
          className={cn(styles.contractsColumn, {
            // [styles.error]: responseCheck && !responseCheck.success,
            [styles.showFull]: showOrderBookRow,
          })}
        >
          {symbol}
        </div>
        {/* {responseCheck && (
          <ValidationIconComponent
            isResponseSuccess={responseCheck?.success}
            errorText={responseCheck?.error}
            setShow={setShow}
            show={show}
          />
        )} */}
      </Box>

      <Box
        className={cn(styles.priceColumn, {
          [styles.hide]: showOrderBookRow,
        })}
      >
        <span
          data-testid="mobile-basketorders-orderbook-price"
          id="price"
          className={cn(styles.text, {
            [styles.error]: showPriceError,
          })}
          role="tab"
          tabIndex={0}
          onKeyDown={noop}
        >
          {!showOrderBookRow &&
            (orderType === ORDER_TYPE.LIMIT ? limitPrice || '-' : t('trading:market'))}
        </span>
        {!showOrderBookRow && showPriceError && (
          <ValidationIconComponent
            isResponseSuccess={false}
            errorText={t('basketOrders:priceEmptyError')}
            setShow={setShow}
            show={show}
          />
        )}
      </Box>
      <Box
        className={cn(styles.quantityColumn, {
          [styles.hide]: showOrderBookRow,
        })}
      >
        <span
          data-testid="mobile-basketorders-orderbook-quantity"
          id="quantity"
          className={cn(styles.text, {
            [styles.error]: showQuantityError,
          })}
          role="tab"
          tabIndex={0}
          onKeyDown={noop}
        >
          {!showOrderBookRow && (quantityValue || '-')}
        </span>

        <Render when={showQuantityError}>
          <ValidationIconComponent
            isResponseSuccess={false}
            errorText={t('basketOrders:qtyEmptyError')}
            setShow={setShow}
            show={show}
          />
        </Render>
      </Box>
      <Box renderWhen={showOrderBookRow}>
        <div
          data-testid="mobile-basket-orders-order-placement-done-btn"
          className={styles.doneButton}
          onClick={handleDoneClick}
          onKeyPress={noop}
          tabIndex={0}
          role="button"
        >
          {' '}
          {t('basketOrders:done')}{' '}
        </div>
      </Box>
      <Box renderWhen={!showOrderBookRow}>
        <div className={styles.iconsColumn}>
          <div
            data-testid="mobile-basket-orders-order-placement-edit-btn"
            className={styles.editColumn}
            onClick={e => handleEditClick(e)}
            onKeyPress={noop}
            tabIndex={0}
            role="button"
          >
            <SquareEditIcon />
          </div>
          <div
            className={styles.deleteColumn}
            data-testid="mobile-basket-orders-order-placement-delete-btn"
            onClick={() => handleDeleteClick(order)}
            onKeyPress={noop}
            tabIndex={0}
            role="button"
          >
            <TrashIcon />
          </div>
        </div>
      </Box>
    </Box>
    <Render when={showOrderBookRow}>
      <div className={styles.orderBookRow}>
        <div className={styles.quantityInputContainer}>
          <PriceInput order={order} asset={activeAssetSymbol} />
          <MobileQuantityInputBox orderData={order} className="orderPlacementInput" />
        </div>
        <BasketOrdersOrderbook
          orderProductId={productId}
          currentOrderbook={currentOrderBook}
          setcurrentOrderBook={setCurrentOrderBook}
        />
      </div>
    </Render>
  </>);
};

export default MobileOrderPlacementRow;
