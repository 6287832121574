import React, { useRef, useState, memo } from 'react';

import cx from 'classnames';
import NotificationSystem from 'react-notification-system';

import Editable from 'components/holdings/editable';
import PortalToBody from 'components/portal';
import { convertExponentialToDecimal } from 'helpers';
import { EditableCellCrossIcon, EditableCellTickIcon } from 'styles/assets/icons';
import { IconButton } from 'UIKit';
import InputBase from 'UIKit/InputBase/InputBase';

import Box from '../Box';
import { EditableCellInterface } from './interfaces';
import styles from './tableCell.module.scss';

const EditableCell = ({
  children,
  inputProps,
  displayValueColored,
  positiveColor,
  wrapperClass,
  displayValue,
  editable,
  tooltipPlacement,
  tooltipText,
  onOk,
  onCancel,
}: EditableCellInterface) => {
  const [editMode, changeEditMode] = useState(false);
  const inputRef: any = useRef();
  const notificationRef: any = useRef();
  let width = 3;
  if (inputRef?.current?.value) {
    width = String(inputRef?.current?.value).length + 3;
  } else if (displayValue) {
    width = String(displayValue).length + 3;
  }
  return (
    <>
      {(onOk || onCancel) && (
        <PortalToBody>
          <NotificationSystem ref={notificationRef} />
        </PortalToBody>
      )}
      {editMode && (
        <Box
          className={cx(styles.cell, 'cell flex-set', {
            [String(wrapperClass)]: wrapperClass,
          })}
        >
          <div
            className={cx(
              'flex-set flex--no-wrap flex--content-center flex--align-items-center',
              styles.editableContainer
            )}
          >
            <IconButton
              data-testid="Ts6EFyFpKEV_EnTXmHjgZ"
              className={cx(
                'flex-set justify-content-center align-items-center pointer',
                styles.inputButton,
                styles.negativeButton
              )}
              onClick={() => {
                if (onCancel) {
                  onCancel(inputRef?.current?.value, notificationRef.current);
                }
                changeEditMode(false);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  if (onCancel) {
                    onCancel(inputRef?.current?.value, notificationRef.current);
                  }
                  changeEditMode(false);
                }
                if (e.key === 'Escape') {
                  changeEditMode(false);
                }
              }}
              icon={
                <EditableCellCrossIcon
                  className=""
                  style={{}}
                  width={8}
                  height={6}
                  fill="none"
                />
              }
            />
            <div
              style={{
                marginRight: 6,
                width: `${width}ch`,
              }}
            >
              <InputBase
                inputRef={inputRef}
                defaultValue={
                  !Number.isNaN(Number(displayValue))
                    ? convertExponentialToDecimal(Number(displayValue))
                    : displayValue
                }
                autoFocus
                tabIndex={-1}
                type={
                  inputProps?.type ||
                  (!Number.isNaN(Number(displayValue)) ? 'number' : 'text')
                }
                className={cx(
                  styles.editableInput,
                  {
                    [styles.white]: !displayValueColored,
                    [styles.positive]: displayValueColored && positiveColor,
                    [styles.negative]: displayValueColored && !positiveColor,
                  },
                  inputProps?.className
                )}
                inputProps={{
                  className: cx({
                    [styles.white]: !displayValueColored,
                    [styles.positive]: displayValueColored && positiveColor,
                    [styles.negative]: displayValueColored && !positiveColor,
                  }),
                }}
              />
            </div>
            <div className="flex-set justify-content-center align-items-center flex--no-wrap">
              <IconButton
                data-testid="h07CmXqqrKiN4WviELHnw"
                className={cx(
                  'flex-set justify-content-center align-items-center pointer',
                  styles.inputButton,
                  styles.positiveButton
                )}
                onClick={() => {
                  if (onOk) {
                    onOk(inputRef?.current?.value, notificationRef.current);
                  }
                  changeEditMode(false);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    if (onOk) {
                      onOk(inputRef?.current?.value, notificationRef.current);
                    }
                    changeEditMode(false);
                  }
                  if (e.key === 'Escape') {
                    changeEditMode(false);
                  }
                }}
                icon={
                  <EditableCellTickIcon
                    className=""
                    style={{}}
                    width={8}
                    height={6}
                    fill="none"
                  />
                }
              />
            </div>
          </div>
        </Box>
      )}
      {!editMode && editable && (
        <Box
          data-testid="Ok9x8xv_x2QZ9VFSHhKWo"
          as="div"
          tabIndex={-1}
          onClick={() => changeEditMode(true)}
          onKeyDown={(e: { preventDefault: () => void; key: string }) => {
            if (e.key === 'Enter') {
              changeEditMode(true);
            }
          }}
          className={styles.focusable}
        >
          <Editable
            shouldDisplayTooltip
            tooltipPlacement={tooltipPlacement}
            tooltipText={tooltipText}
          >
            {children || ''}
          </Editable>
        </Box>
      )}
      {!editMode && !editable && children}
    </>
  );
};

export default memo(EditableCell);
