/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';

import { getOrderLeverage } from 'actions/orderbook';
import { placeOrder } from 'actions/trade';
import { ORDER_TYPE, MARGIN_MODE } from 'constants/enums';
import {
  markPriceSelectorById,
  productsListSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { OrderResponse, OrdersObject } from 'types/basketOrders';

interface ResponseInterface {
  [key: number]: {
    result?: OrderResponse | null;
    success: boolean;
    error?: string | null;
  };
}

interface PlaceIsolatedBasketOrdersProps {
  orders: OrdersObject[];
  toggleloader?: Dispatch<SetStateAction<void>>;
  callback?: (res: ResponseInterface) => void;
}

const usePlaceIsolatedBasketOrders = ({
  orders,
  toggleloader,
  callback,
}: PlaceIsolatedBasketOrdersProps) => {
  const dispatch = useAppDispatch();
  const productList = useAppSelector(productsListSelector);
  const marginMode = useAppSelector(userMarginModeSelector);
  // const [showLoader, toggleShowLoader] = useToggle(false);

  const onPlaceOrderClick = () => {
    toggleloader?.();
    const ordersPromiseMap: Array<Promise<void>> = orders.map(order => {
      const { side, size, limit_price, order_type, time_in_force, product_id } = order;
      const payload = {
        side,
        size,
        limit_price,
        order_type,
        time_in_force,
        product_id,
        post_only: false,
        reduce_only: false,
      };
      const product = productList.find(item => item.id === product_id);
      const markPrice = markPriceSelectorById(product_id);
      const price = order_type === ORDER_TYPE.LIMIT ? limit_price : markPrice;
      const mixpanelData = {
        orderType: order_type,
        quantity: `${size}`,
        price,
        product,
        notional: Number(size) * Number(product?.contract_value),
        source:
          marginMode === MARGIN_MODE.ISOLATED
            ? 'basket_order_isolated'
            : 'basket_order_cross',
      };
      return dispatch(placeOrder(payload, mixpanelData));
    });
    const promiseCall = async () => {
      try {
        const result = await Promise.allSettled(ordersPromiseMap);
        const resObj: ResponseInterface = {};
        orders.forEach((item, i) => {
          dispatch(getOrderLeverage(item.product_id));
          resObj[item.product_id] = {
            success: result[i].status === 'fulfilled',
            result: result[i].value ? result[i]?.value?.result : null,
            error: result[i].reason
              ? result[i].reason?.response?.body?.error?.code ?? 'something went wrong'
              : null,
          };
        });
        callback?.(resObj);
      } catch (error) {
        console.error('DEBUG error', error);
      } finally {
        toggleloader?.();
      }
    };
    promiseCall();
  };

  return {
    onPlaceOrderClick,
  };
};

export default usePlaceIsolatedBasketOrders;
