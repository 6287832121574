/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';

import cx from 'classnames';

import { AngleUp, AngleDown } from 'styles/assets/icons';

import classes from './toggleCollapseIcon.module.scss';

interface ToggleIconProps {
  onClick?: (event: React.MouseEvent) => void;
  isExpanded: boolean;
  customExpandIcon?: ReactNode;
  customCollapseIcon?: ReactNode;
  /**
   * @deprecated use 'data-testid'
   */
  testID?: string;
  'data-testid': string;
  containerClassName?: string;
}

const ToggleExpandCollapseIcon = ({
  onClick,
  isExpanded,
  testID,
  containerClassName,
  customExpandIcon,
  customCollapseIcon,
  'data-testid': tid,
}: ToggleIconProps) => {
  const expandedIconToDisplay = customExpandIcon || <AngleUp className={classes.icon} />;
  const collapsedIconToDisplay = customCollapseIcon || (
    <AngleDown className={classes.icon} />
  );
  return (
    (<span
      className={cx(classes.wrapper, {
        [classes.expanded]: isExpanded,
        [classes.collapsed]: !isExpanded,
        [containerClassName || '']: containerClassName,
      })}
      role="button"
      aria-hidden="true"
      data-testid={tid || testID}
      onClick={onClick}
      data-palette="ToggleExpandCollapseIcon">
      {isExpanded ? expandedIconToDisplay : collapsedIconToDisplay}
    </span>)
  );
};

export default ToggleExpandCollapseIcon;
