import { createRef } from 'react';

import * as Sentry from '@sentry/browser';
import jwtDecode from 'jwt-decode';
// import loadable from '@loadable/component';
import ReactGA from 'react-ga4';

import {
  oAuthLoginSuccess,
  signupClicked,
  userSignupFail,
  userSignupSuccess,
} from 'actions/user';
import { postMessageToMobileApp } from 'components/app/helpers';
import { OAUTH, USER_PROFILE } from 'constants/api';
import {
  API_URL,
  ERROR_CODES,
  IS_RN_WEBVIEW,
  SIGNUP_ERROR_CODES,
} from 'constants/constants';
import {
  OAUTH_PROVIDERS,
  POST_MESSAGE_TO_MOBILE_EVENTS,
  STORAGE_KEYS,
} from 'constants/enums';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import routes from 'constants/routes';
import { isEmpty, isTestNet, validateEmail as validateEmailPattern } from 'helpers';
import * as mixPanel from 'helpers/mixpanel-init';
import { contains, isEmpty as isEmptyRamda, omit } from 'helpers/ramda';
import { addLogsInSentryAndMixpanel, addLogsToSentry } from 'helpers/sentry';
import i18n from 'i18n/config';

import { redirectToEmailVerification } from '../Common/helpers';
import actions from './actions';
import { countryNotValid, DEFAULT_REFERRAL_CODE } from './constants';
import {
  AlreadyRegisteredMessage,
  AlreadyRegisteredUnverifiedMessage,
  CountryNotServedMessage,
} from './partials/Messages';

// regex for referral code (alpha numeric with max length 20)
export const referralRegex = /^[a-zA-Z0-9]{0,20}$/;

const ONE_UPPERCASE_LETTER_PATTERN = /[A-Z]/;
const ONE_LOWERCASE_LETTER_PATTERN = /[a-z]/;
const ONE_SPECIAL_CHARACTER_PATTERN = /\W|_/;
const ONE_NUMBER_PATTERN = /\d/;

// Create a filter for your search feature.
// Will filter by "includes" and then sort by "startsWith"
const createSearchFilter = terms => filterTerm => {
  const lowerCaseFilterTerm = filterTerm.toLowerCase();
  return terms
    .filter(v => v.toLowerCase().includes(lowerCaseFilterTerm))
    .sort((a, b) => {
      const aStarts = a.toLowerCase().startsWith(lowerCaseFilterTerm);
      const bStarts = b.toLowerCase().startsWith(lowerCaseFilterTerm);
      if (aStarts && bStarts) return a.localeCompare(b);
      if (aStarts && !bStarts) return -1;
      if (!aStarts && bStarts) return 1;
      return a.localeCompare(b);
    });
};

export const getFilteredCountries = countryList =>
  createSearchFilter(countryList.map(country => country.label));

export const allPwdCriteriaSatisfied = (password = '') => {
  let satisfied = true;

  if (password.length < 6) satisfied = false;
  if (!ONE_UPPERCASE_LETTER_PATTERN.test(password)) satisfied = false;
  if (!ONE_LOWERCASE_LETTER_PATTERN.test(password)) satisfied = false;
  if (!ONE_SPECIAL_CHARACTER_PATTERN.test(password)) satisfied = false;
  if (!ONE_NUMBER_PATTERN.test(password)) satisfied = false;

  return satisfied;
};

export const canSubmit = state => {
  // Conditions to Enable Submit.
  const fieldsNotEmpty = state.country.value && state.email.value && state.password.value;

  const noError =
    !state.country.error &&
    !state.email.error &&
    !state.password.error &&
    !state.country.error;

  const enableSubmit = fieldsNotEmpty && noError;

  const failureConditions = {};

  if (state.email.value === '') {
    failureConditions.empty_email = true;
  } else if (state.email.error) {
    failureConditions.invalid_email = true;
  }

  if (state.password.value === '') {
    failureConditions.empty_password = true;
  } else if (state.password.score < 2) {
    failureConditions.invalid_password = true;
  }

  if (state.country.value === '') {
    failureConditions.empty_country = true;
  }

  if (!isEmptyRamda(failureConditions)) {
    mixPanel.trackMixpanelEvent(
      MIXPANEL_EVENT_NAMES.SIGN_UP.BUTTON_CLICKED_WITH_MISSING_FIELDS,
      {
        ...failureConditions,
        country: state.country.value,
        user_email: state.email.value,
        referral_code: state.referral.value,
        password_score: state.password.score,
        page_name: 'Sign-up',
        type: 'email',
      }
    );
  }

  return enableSubmit;
};

export const getPasswordValidation = (password, email, zxcvbn, options) => {
  const validation = {
    error: false,
    errorMessage: '',
    score: zxcvbn(password).score,
    errorCode: '',
  };

  // Validation only on Submit Press.
  if (options?.submit) {
    if (isEmpty(password)) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.password_empty');
      return validation;
    }
    if (options?.errorCode) {
      switch (options.errorCode) {
        case ERROR_CODES.BCRYPT_HASH_LIMIT:
          validation.error = true;
          validation.errorMessage = i18n.t(
            `errors:custom.${ERROR_CODES.BCRYPT_HASH_LIMIT}`
          );
          break;
        default:
          break;
      }
      return validation;
    }
  }

  // Live Validation.
  if (isEmpty(password)) {
    return validation;
  }

  let passwordIsAllowed = false;
  if (password.length !== 0 && email.length !== 0) {
    passwordIsAllowed = email !== password;
  } else {
    passwordIsAllowed = true;
  }

  if (passwordIsAllowed) {
    if (password.length > 50) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.pass_max_length');
      validation.errorCode = options?.submit ? '' : 'length';
    } else if (validation.score < 2) {
      validation.error = true;
      validation.errorCode = 'password_weak';
      validation.errorMessage = i18n.t('auth:increasePasswordStrength');
      if (allPwdCriteriaSatisfied(password)) {
        validation.error = false;
        validation.errorCode = '';
        validation.errorMessage = '';
        validation.score = 2;
      }
    }
  } else {
    validation.error = true;
    validation.errorMessage = i18n.t('errors:custom.pass_email_diff');
    validation.errorCode = options?.submit ? '' : 'email_pass_same';
  }

  return validation;
};

export const checkPasswordConditions = (password = '') => {
  const status = {
    minCharacters: false,
    uppercaseLetter: false,
    lowercaseLetter: false,
    specialCharacter: false,
    number: false,
  };

  if (password.length >= 6) status.minCharacters = true;
  if (ONE_UPPERCASE_LETTER_PATTERN.test(password)) status.uppercaseLetter = true;
  if (ONE_LOWERCASE_LETTER_PATTERN.test(password)) status.lowercaseLetter = true;
  if (ONE_SPECIAL_CHARACTER_PATTERN.test(password)) status.specialCharacter = true;
  if (ONE_NUMBER_PATTERN.test(password)) status.number = true;

  return status;
};

export const getCountryValidation = (country, options) => {
  const validation = {
    error: false,
    errorMessage: null,
    isNotServed: false,
    errorCode: '',
  };

  // Validation on Submit Press.
  if (options?.submit) {
    if (isEmpty(country)) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.country_empty');
      return validation;
    }
    if (options.errorCode) {
      switch (options.errorCode) {
        case 'invalid_country': {
          validation.error = true;
          validation.errorMessage = i18n.t('errors:user.invalid_country');
          break;
        }
        default:
          break;
      }
      validation.errorCode = options.errorCode;
    }
    return validation;
  }

  // Live Validation.
  validation.isNotServed = contains({ value: country }, countryNotValid) && !isTestNet();
  if (validation.isNotServed) {
    validation.error = true;
    validation.errorMessage = <CountryNotServedMessage />;
  }

  return validation;
};

export const getEmailValidation = (email, options) => {
  const validation = {
    error: false,
    errorMessage: '',
    errorCode: '',
  };

  const emailIsEmpty = isEmpty(email);
  const emailFormatIsInValid = !validateEmailPattern(email) && !isEmpty(email);

  // Validation on Submit Press.
  if (options?.submit) {
    if (emailIsEmpty) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.email_empty');
    } else if (emailFormatIsInValid) {
      validation.error = true;
      validation.errorMessage = i18n.t('errors:custom.emailAddressInvalid');
    } else if (options.errorCode) {
      switch (options.errorCode) {
        case 'already_registered': {
          validation.error = true;
          validation.errorMessage = <AlreadyRegisteredMessage />;
          break;
        }
        case 'already_registered_verification_pending': {
          validation.error = true;
          validation.errorMessage = <AlreadyRegisteredUnverifiedMessage email={email} />;
          break;
        }
        case 'invalid_email_format': {
          validation.error = true;
          validation.errorMessage = i18n.t('errors:user.invalid_email_format');
          break;
        }
        case 'blocked_domain': {
          validation.error = true;
          validation.errorMessage = i18n.t('errors:user.blocked_domain');
          break;
        }
        default:
          break;
      }
      validation.errorCode = options.errorCode;
    }
    return validation;
  }

  // Live Validation.
  if (emailFormatIsInValid) {
    validation.error = true;
  }

  return validation;
};

export const getDefaultError = options => {
  let defaultErrorMessage = '';
  if (options.errorCode) {
    switch (options.errorCode) {
      case 'bad_schema':
        defaultErrorMessage = options.schemaErrorMessage
          ? options.schemaErrorMessage
          : '';
        break;
      case 'api_blocked':
        defaultErrorMessage = i18n.t('auth:processBlocked');
        break;

      default:
        break;
    }
  }
  return defaultErrorMessage;
};

function getTrackingInfo() {
  const tracking = {};

  // Get GA object
  const gaObj = ReactGA.ga();

  if (!gaObj || typeof gaObj.getAll !== 'function' || gaObj.getAll().length < 1) {
    return tracking;
  }

  const ga = gaObj.getAll()[0];
  if (ga) {
    tracking.ga_cid = ga.get('clientId');
    tracking.referrer = ga.get('referrer');
  }
  return tracking;
}

export const setFieldFocus = ref => {
  ref?.current?.focus();
};

export const getStoredUtmParams = () => {
  const utmParams = JSON.parse(localStorage.getItem(STORAGE_KEYS.UTM_PARAMETERS) || '{}');

  const { utm_source: utmSource, utm_medium: utmMedium } = utmParams;
  return {
    utmMedium,
    utmSource,
  };
};

export const signupHandler = params => {
  const { state, dispatch, appDispatch, recaptchaRef, history, validator } = params;

  const { utmMedium, utmSource } = getStoredUtmParams();

  const email = state.email.value;
  const password = state.password.value;
  const country = state.country.value;
  const enteredRefCode = state.referral.value;
  // If referral code is empty or invalid then use the default one.
  const referralCode =
    enteredRefCode.length === 0 || state.referral.error
      ? DEFAULT_REFERRAL_CODE
      : enteredRefCode;

  appDispatch(
    signupClicked({
      email,
      country,
    })
  );

  recaptchaRef.current
    .executeAsync()
    .then(token => {
      // body for fetch request.
      const bodyData = {
        email,
        password,
        country,
        signup_referral_code: referralCode,
        recaptcha_response: token,
        tracking_info: getTrackingInfo(),
      };

      // Ref for 20 secs timer.
      const apiBlockedTimer = createRef(null);

      // try/catch block
      try {
        mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.BUTTON_CLICKED, {
          Country: country,
          $email: email,
          referral_code: referralCode,
          page_name: 'Sign-up',
          type: 'email',
          ...(utmSource && { utm_source: utmSource }),
          ...(utmMedium && { utm_source: utmSource }),
        });

        if (typeof token !== 'string') {
          mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.CAPTCHA_ERROR, {
            Country: country,
            $email: email,
            referral_code: referralCode,
            recaptcha_response: token,
            page_name: 'Sign-up',
            type: 'email',
          });

          dispatch(actions.recaptchaError());
        }

        const request = new Request(`${API_URL}${USER_PROFILE.REGISTER_SIGNUP}`, {
          method: 'POST',
          body: JSON.stringify(bodyData),
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        });

        // hide loader, send event to sentry and mixpanel after 10 secs
        // this will get triggered if fetch call didnt work
        apiBlockedTimer.current = setTimeout(() => {
          dispatch(actions.apiBlocked());
          const commonTrackingData = {
            email,
            country,
            page_name: 'Sign-up',
            type: 'email',
          };
          mixPanel.trackMixpanelEvent('Signup API Timeout', commonTrackingData);
          addLogsToSentry(
            { ...omit(['password'], bodyData), ...commonTrackingData },
            'Signup API Timeout'
          );
        }, 20000);

        fetch(request)
          .then(res => res.json())
          .then(res => {
            if (res.success) {
              const { id, pre_2fa_token } = res.result;

              appDispatch(
                userSignupSuccess({
                  email,
                  pre_2fa_token,
                })
              );
              dispatch(actions.registerSuccess());
              mixPanel.identifyUser(id);
              mixPanel.setUserProperty({
                registration_time: new Date().toString(),
                Country: country,
                $email: email,
                referral_code: referralCode,
              });
              mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.SUCCESS, {
                registration_time: new Date().toString(),
                Country: country,
                $email: email,
                referral_code: referralCode,
                page_name: 'Sign-up',
                type: 'email',
              });
              postMessageToMobileApp('APPSFLYER_SIGNUP', {
                user_id: id,
                country,
                referralcode: referralCode,
              });
              postMessageToMobileApp('SIGNUP_NOTIFICATION_SUCCESS', {});
              redirectToEmailVerification(email, history);
            } else if (res.error) {
              appDispatch(userSignupFail(res.error));
              recaptchaRef.current.reset();

              const errorObj = res.error;
              const { code, context = {} } = errorObj;
              const errorMsg = context.schema_errors ? context.schema_errors[0] : {};

              mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.FAIL, {
                page_name: 'Sign-up',
                registration_time: new Date().toString(),
                Country: country,
                $email: email,
                error_code: code,
                error_message: errorMsg.message || '',
                type: 'email',
              });

              if (!SIGNUP_ERROR_CODES.includes(code)) {
                addLogsToSentry(
                  {
                    email,
                    requestPayload: omit(['password'], bodyData),
                    error: JSON.stringify(errorObj),
                    page_name: 'Sign-up',
                    type: 'email',
                  },
                  'Signup Unhandled Error'
                );
              }

              dispatch(actions.registerFailure(code, errorMsg.message || '', validator));
            }
          })
          .catch(error => {
            recaptchaRef.current.reset();
            Sentry.withScope(scope => {
              scope.setUser({
                email,
                error:
                  error?.message === 'Failed to fetch'
                    ? 'Failed to fetch'
                    : JSON.stringify(error),
                requestPayload: omit(['password'], bodyData),
              });
              scope.setExtras(error);
              Sentry.captureMessage('Signup Unhandled Error');
              Sentry.captureException(error);
            });

            dispatch(actions.setDefaultError(i18n.t('errors:user.defaultMessage')));
            dispatch(actions.setLoader(false));

            mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.API_CATCH_BLOCK, {
              $email: email,
              error: error.toString(),
              type: 'email',
            });
          })
          .finally(() => {
            clearTimeout(apiBlockedTimer.current);
          });
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setUser({
            email,
            error:
              error?.message === 'Failed to fetch'
                ? 'Failed to fetch'
                : JSON.stringify(error),
          });
          scope.setExtras(error);
          Sentry.captureMessage('Signup Unhandled Error');
          Sentry.captureException(error);
        });

        mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.DEFAULT_CATCH_BLOCK, {
          $email: email,
          error: error.toString(),
          type: 'email',
        });
      }
    })
    .catch(() => {
      mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.CAPTCHA_ERROR, {
        Country: country,
        $email: email,
        referral_code: referralCode,
        page_name: 'Sign-up',
        type: 'email',
      });
      dispatch(actions.recaptchaError());
    });
};

const oAuthSignup = ({
  bodyData,
  userEmail,
  recaptchaRef,
  history,
  dispatch,
  appDispatch,
  setOAuthLoginState,
  provider,
  redirectURI,
  country,
  referralCode,
}) => {
  const body = { ...bodyData };
  if (provider === OAUTH_PROVIDERS.APPLE) {
    body.redirect_uri = redirectURI;
  }

  const request = new Request(OAUTH.REGISTER, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  fetch(request)
    .then(res => res.json())
    .then(res => {
      const { error, success, result } = res;
      if (!success) {
        const { code: errorCode, jwt_token: jwtToken } = error;

        recaptchaRef?.current?.reset();

        const payload = {
          error: true,
          errorMessage: i18n.t(`errors:user.${errorCode}`),
          errorCode,
        };

        if (errorCode === ERROR_CODES.INVALID_CAPTCHA) {
          dispatch(actions.recaptchaError());
        }

        if (errorCode === ERROR_CODES.LOGIN_BLOCKED) {
          payload.errorMessage = i18n.t(`errors:custom.${errorCode}`);
        }

        if (errorCode === ERROR_CODES.OAUTH_NOT_ACTIVE) {
          localStorage.setItem(STORAGE_KEYS.LINK_OAUTH_EMAIL, JSON.stringify(userEmail));
          localStorage.setItem(
            STORAGE_KEYS.LINK_OAUTH_JWT_TOKEN,
            JSON.stringify(jwtToken)
          );
          localStorage.setItem(
            STORAGE_KEYS.LINK_OAUTH_PROVIDER,
            JSON.stringify(provider)
          );
          history.push(routes.linkAccount);
        }

        if (
          ![
            ERROR_CODES.LOGIN_BLOCKED,
            ERROR_CODES.OAUTH_NOT_ACTIVE,
            ERROR_CODES.INVALID_CAPTCHA,
          ].includes(errorCode)
        ) {
          addLogsToSentry(
            { requestPayload: body, error: JSON.stringify(error), email: userEmail },
            'OAuth Signup Unhandled Error'
          );
        }

        dispatch(actions.oAuthSignUpError(payload));
      } else if (
        result.pre_2fa_token &&
        (result.code === 'mfa_code' || result.code === 'phone_otp')
      ) {
        dispatch(
          actions.signupPre2FASuccess({
            pre2faToken: result.pre_2fa_token,
            code: result.code,
            email: userEmail,
            phoneNumber: result.phone_number,
            phoneNumberAdded: result.phone_number_added,
            phoneOTPRequired: result.phone_otp_required,
          })
        );
      } else {
        const isSubAccount = res?.result.is_sub_account;
        mixPanel.identifyUser(res.result.id);

        if (!result?.new_registration) {
          mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.SUCCESS, {
            page_name: 'Sign-up',
            is_loggedin: true,
            type: provider,
            $email: res.result.email,
          });
        } else {
          mixPanel.setUserProperty({
            registration_date: new Date().toString(),
            Country: country,
            $email: res.result.email,
            type: provider,
          });

          mixPanel.trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_UP.SUCCESS, {
            registration_time: new Date().toString(),
            Country: country,
            referral_code: referralCode,
            page_name: 'Sign-up',
            type: provider,
            $email: res.result.email,
          });

          mixPanel.trackMixpanelEvent(
            MIXPANEL_EVENT_NAMES.SIGN_UP.EMAIL_VERIFICATION_COMPLETED,
            {
              email_verification_time: new Date().toString(),
              page_name: 'Sign-up',
              type: provider,
              is_sub_account: isSubAccount,
              $email: res.result.email,
            }
          );
        }
        appDispatch(oAuthLoginSuccess(res));
      }
    })
    .catch(error => {
      Sentry.withScope(scope => {
        scope.setUser({
          email: userEmail,
          type: provider,
          error:
            error?.message === 'Failed to fetch'
              ? 'Failed to fetch'
              : JSON.stringify(error),
          requestPayload: body,
        });
        scope.setExtras(error);
        Sentry.captureMessage('OAuth Signup Unhandled Error');
        Sentry.captureException(error);
      });
      dispatch(
        actions.oAuthSignUpError({
          error: true,
          errorMessage: i18n.t(`errors:defaultMessage`),
          errorCode: 'defaultMessage',
        })
      );
    })
    .finally(() => {
      recaptchaRef?.current?.reset();
      if (IS_RN_WEBVIEW) {
        postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.OAUTH_LOGOUT, {
          provider: OAUTH_PROVIDERS.GOOGLE,
        });
        setOAuthLoginState();
      }
    });
};

export const oAuthSignupHandler = params => {
  const {
    state,
    dispatch,
    appDispatch,
    recaptchaRef,
    accessToken,
    setOAuthLoginState,
    history,
    provider,
    redirectURI,
    idToken,
  } = params;

  const country = state.country.value;
  const enteredRefCode = state.referral.value;
  // If referral code is empty or invalid then use the default one.
  const referralCode =
    enteredRefCode.length === 0 || state.referral.error
      ? DEFAULT_REFERRAL_CODE
      : enteredRefCode;

  if (recaptchaRef?.current && recaptchaRef?.current?.executeAsync) {
    recaptchaRef.current
      .executeAsync()
      .then(token => {
        if (typeof token !== 'string') throw new Error(ERROR_CODES.INVALID_CAPTCHA);

        dispatch(actions.setOAuthBtnLoader(true));

        const bodyData = {
          oauth_token: accessToken,
          country,
          signup_referral_code: referralCode,
          recaptcha_response: token,
          tracking_info: getTrackingInfo(),
          oauth: provider,
        };

        if (provider === OAUTH_PROVIDERS.GOOGLE) {
          fetch(`${OAUTH.GOOGLE_USER_INFO}${accessToken}`)
            .then(response => response.json())
            .then(response => {
              const userEmail = response.email;
              oAuthSignup({
                bodyData,
                userEmail,
                recaptchaRef,
                history,
                dispatch,
                appDispatch,
                setOAuthLoginState,
                provider,
                country,
                referralCode,
              });
            })
            .catch(() => {
              recaptchaRef?.current?.reset();
              setOAuthLoginState();
              addLogsInSentryAndMixpanel(
                {
                  Country: country,
                  referral_code: referralCode,
                  page_name: 'Sign-up',
                  type: state.oAuth.provider,
                },
                MIXPANEL_EVENT_NAMES.SIGN_UP.GOOGLE_ACCESS_TOKEN_VALIDATION_FAILED
              );
              dispatch(
                actions.oAuthSignUpError({
                  error: true,
                  errorMessage: i18n.t(`errors:defaultMessage`),
                  errorCode: 'defaultMessage',
                })
              );
            });
        } else if (provider === OAUTH_PROVIDERS.APPLE) {
          const decoded = jwtDecode(idToken);
          const userEmail = decoded.email;
          oAuthSignup({
            bodyData,
            userEmail,
            recaptchaRef,
            history,
            dispatch,
            appDispatch,
            setOAuthLoginState,
            provider,
            redirectURI,
            country,
            referralCode,
          });
        } else {
          dispatch(
            actions.oAuthSignUpError({
              error: true,
              errorMessage: i18n.t(`errors:defaultMessage`),
              errorCode: 'defaultMessage',
            })
          );
        }
      })
      .catch(() => {
        addLogsInSentryAndMixpanel(
          {
            Country: country,
            referral_code: referralCode,
            page_name: 'Sign-up',
            type: state.oAuth.provider,
          },
          MIXPANEL_EVENT_NAMES.SIGN_UP.CAPTCHA_ERROR
        );
        setOAuthLoginState();
        dispatch(actions.recaptchaError());
        recaptchaRef?.current?.reset();
      });
  } else {
    recaptchaRef?.current?.reset();
    addLogsInSentryAndMixpanel(
      {
        Country: country,
        referral_code: referralCode,
        page_name: 'Sign-up',
        type: state.oAuth.provider,
      },
      'Recaptcha ref is missing'
    );
    setOAuthLoginState();
    dispatch(
      actions.oAuthSignUpError({
        error: true,
        errorMessage: `${i18n.t(`errors:defaultMessage`)}`,
        errorCode: 'defaultMessage',
      })
    );
  }
};
