import { ASSET_SYMBOL } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import {
  assetPrecision,
  getBalanceBySymbol,
  isOptions,
  negativeBalanceCheck,
} from 'helpers';
import {
  balanceBySymbolSelector,
  multiCollateralSelector,
  tradingCreditsSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import { ContractType, Product } from 'types/IProducts';

/**
 * Hooks to fetch product balance for selected product
 * @param product Product
 * @param isBuyAction Boolean
 * @returns balance
 * @deprecated moved to selector at src/selectors/placeOrderSelector.ts
 */
const useSelectedProductBalance = (product: Product, isBuyAction: Boolean) => {
  const {
    contract_type: contractType,
    quoting_asset: { symbol: quotingSymbol },
    underlying_asset: { symbol: underlyingSymbol },
  } = product;
  const allBalances = useAppSelector(balanceBySymbolSelector);
  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  const tradingCredits = useAppSelector(tradingCreditsSelector);

  // const crossMarginData = crossMarginChannelSelector();
  const multiCollateralData = multiCollateralSelector();

  let balanceSymbol =
    // eslint-disable-next-line camelcase, no-nested-ternary
    contractType === 'spot'
      ? isBuyAction
        ? quotingSymbol
        : underlyingSymbol
      : product.settling_asset.symbol;

  if (marginMode === MARGIN_MODE.CROSS && contractType !== 'spot') {
    balanceSymbol = ASSET_SYMBOL;
  }
  const productBalance = getBalanceBySymbol(balanceSymbol, allBalances);
  const precision = productBalance && assetPrecision(productBalance.asset || {});
  let balance = productBalance?.available_balance;
  if (marginMode === MARGIN_MODE.CROSS) {
    if (contractType !== 'spot') {
      if (isOptions(contractType) && isBuyAction) {
        balance = multiCollateralData?.available_margin_long_options;
      } else {
        balance = multiCollateralData?.available_margin_usdt;
      }
    } else if (productBalance?.updated_at < multiCollateralData.updated_at) {
      const wallet = multiCollateralData?.wallets?.filter(
        obj => obj.asset_symbol === balanceSymbol
      );
      if (wallet.length > 0) {
        balance = wallet[0].available_balance;
      }
    }
  }
  /**
   * User should not be allowed to purchase through spot market using trading credits.
   * So deducting trading credits from balance.
   */
  if (contractType === ContractType.Spot && isBuyAction) {
    const assetId = productBalance?.asset?.id;
    const tradingCreditsForAsset =
      assetId && assetId in tradingCredits ? tradingCredits[assetId].amount : 0;

    balance = String(Number(balance) - Number(tradingCreditsForAsset));
  }

  const roundedBalance = negativeBalanceCheck(balance, precision);
  return {
    balance,
    roundedBalance,
    balanceSymbol,
  };
};

export default useSelectedProductBalance;
