import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { action as toggleMenu } from 'redux-burger-menu';

import { toggleTheme } from 'actions/theme';
import * as UserActions from 'actions/user';
import { closePopup, openPopup } from 'actions/wallet';
import App from 'components/app';
import loaderCompose from 'composers/loader';
import {
  coinSelectedSelector,
  isCurrencyPopupOpenSelector,
  selectedProductIdSelector,
  selectedProductState,
  showPopupSelector,
  walletPopupTypeSelector,
} from 'selectors';
import {
  isOnlineSelector,
  isSocketReconnecting,
  offlineTimeSelector,
  socketDisconnectTimeSelector,
  contractTypeSelector,
} from 'selectors/tradeSelectors';
import IState, { ICustomDispatch } from 'types/Istore';

const mapStateToProps = (state: IState) => ({
  isloggedin: UserActions.isAuthenticated(state.user),
  notifications: state.notifications,
  user: state.user,
  is_currency_popup_open: isCurrencyPopupOpenSelector(state),
  // burgerMenuIsOpen: state.burgerMenu.isOpen,
  showPopup: showPopupSelector(state),
  popupType: walletPopupTypeSelector(state),
  showLoader: selectedProductIdSelector(state) === null,
  selectedProduct: selectedProductState(state),
  location: state.router.location,
  coin_selected: coinSelectedSelector(state),
  settings: state.settings,
  isInternetConnected: isOnlineSelector(state),
  isSocketReconnecting: isSocketReconnecting(state),
  selectedContractType: contractTypeSelector(state),
  offlineTime: offlineTimeSelector(state),
  socketDisconnectTime: socketDisconnectTimeSelector(state),
});

const mapDispatchToProps = (dispatch: ICustomDispatch<IState>) =>
  bindActionCreators(
    {
      ...UserActions,
      toggleTheme,
      toggleMenu,
      openPopup,
      closePopup,
    },
    dispatch
  );

const showLoader = props => {
  return props.settings === null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(loaderCompose(showLoader, App));
