/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  changeBasketUnderlyingAsset,
  toggleMobileBasketOrderAssetDropdown,
} from 'actions/basketOrders';
import Modal from 'components/modal';
import Render from 'components/render';
import { noop } from 'helpers';
import {
  basketActiveUnderlyingAssetSelector,
  isMobileAssetDropdownOpenSelector,
} from 'selectors';
import { lastPriceChangeSelector } from 'selectors/tickerSelectors';
import { OptionChainsProductList } from 'selectors/tradeSelectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import {
  ChevronLeft,
  CircularTick,
  YellowInfoIcon,
  ArrowDownThin,
} from 'styles/assets/icons';
import { Box } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from '../mobileBasketOrders.module.scss';
import LastPriceChangeComponent from './lastPriceChange24h';
import MobileBasketOrderSpotpriceComponent from './spotPriceComponent';

const MobileAssetDropdown = () => {
  const { t } = useTranslation(['basketOrders']);
  const dispatch = useAppDispatch();

  const productList = useAppSelector(OptionChainsProductList);
  const activeUnderlyingAsset = useAppSelector(basketActiveUnderlyingAssetSelector);
  const isDropdownOpen = useAppSelector(isMobileAssetDropdownOpenSelector);

  const [activeAsset, setAsset] = useState('');

  useEffect(() => {
    setAsset(activeUnderlyingAsset);
  }, [activeUnderlyingAsset]);

  const change24h: any = Number(
    lastPriceChangeSelector({ symbol: `${activeUnderlyingAsset}_USDT` })
  );
  const { underlyingAssetSymbolList } = productList;

  const handleOnClick = () => {
    dispatch(toggleMobileBasketOrderAssetDropdown(true));
  };

  const closeDropdown = () => {
    dispatch(toggleMobileBasketOrderAssetDropdown(false));
    setAsset(activeUnderlyingAsset);
  };

  const onAssetChange = (assetSymbol: React.SetStateAction<string>) => {
    setAsset(assetSymbol);
  };

  const handleDone = () => {
    dispatch(changeBasketUnderlyingAsset(activeAsset));
    closeDropdown();
  };

  return (
    (<Box className={styles.assetDropdownContainer}>
      <Box
        data-testid="active-underlying-asset"
        onClick={handleOnClick}
        role="button"
        tabIndex={0}
        onKeyPress={noop}
      >
        <span className={styles.underlyingData}>
          {activeUnderlyingAsset}
          <MobileBasketOrderSpotpriceComponent
            className={styles.spotPrice}
            assetSymbol={activeUnderlyingAsset}
          />
          <span
            className={cn(
              {
                [styles.positive]: change24h >= 0,
                [styles.negative]: change24h < 0,
              },
              styles.change24h
            )}
          >
            {`(${change24h.toFixed(2)}%)`}
          </span>
        </span>
        <ArrowDownThin className={styles.downArrow} />
      </Box>
      <Render when={isDropdownOpen}>
        <Modal
          isOpen={isDropdownOpen}
          onRequestClose={closeDropdown}
          shouldCloseOnEsc={false}
          className={styles.underlyingAssetContainer}
          classOverlayName={styles.underlyingAssetOverlay}
        >
          <div className={styles.underlyingPopupContainer}>
            <div className={styles.header}>
              <span
                className={styles.backButton}
                onClick={() => closeDropdown()}
                data-testid="asset-dropdown-header-back-button"
                role="button"
                tabIndex={0}
                onKeyPress={noop}
              >
                <ChevronLeft />
              </span>
              <span className={styles.headerText}>
                {' '}
                {t('basketOrders:selectUnderlying')}{' '}
              </span>
            </div>
            {underlyingAssetSymbolList.map(asset => {
              const isActiveAsset = activeAsset.toLowerCase() === asset.toLowerCase();
              return (
                (<div
                  data-testid="basket-underlying-asset-row"
                  onClick={() => onAssetChange(asset)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={noop}
                  className={cn(styles.underlyingAssetRow, {
                    [styles.activeRow]: isActiveAsset,
                  })}
                  key={`basket-orders-underlying-asset-${asset}`}
                  data-palette="MobileAssetDropdown">
                  {isActiveAsset && (
                    <span className={styles.tickIcon} data-palette="MobileAssetDropdown">
                      {' '}
                      <CircularTick />{' '}
                    </span>
                  )}
                  <span className={styles.assetSymbol}> {asset} </span>
                  <span>
                    <MobileBasketOrderSpotpriceComponent assetSymbol={asset} />
                  </span>
                  <span
                    className={cn(
                      {
                        [styles.positive]: change24h >= 0,
                        [styles.negative]: change24h < 0,
                      },
                      styles.change24h
                    )}
                  >
                    <LastPriceChangeComponent symbol={`${asset}_USDT`} />
                  </span>
                </div>)
              );
            })}
            <div className={styles.footer}>
              <div className={styles.infoBanner}>
                <div className={styles.head}>
                  <YellowInfoIcon />
                  <span> {t('basketOrders:basketUnderlying')} </span>
                </div>
                <div className={styles.content}>
                  {' '}
                  {t('basketOrders:basketOrderInfoText')}
                </div>
              </div>
              <div>
                <DeltaButton
                  onClick={handleDone}
                  variant="primary-t2"
                  className={styles.doneButton}
                  data-testid="mobile-basket-orders-done-btn"
                  testId="mobile-basket-orders-done-btn"
                >
                  {t('basketOrders:done')}
                </DeltaButton>
              </div>
            </div>
          </div>
        </Modal>
      </Render>
    </Box>)
  );
};

export default MobileAssetDropdown;
