/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { getAccountToken, switchToMainAccount } from 'actions/user';
import { ASSET_SYMBOL, IS_INDIAN_EXCHANGE } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
import { convertObjToArray, noop, sortArrayOfObj } from 'helpers/utils';
import { useWindowSize } from 'hooks';
import i18n from 'i18n/config';
import {
  portfolioEnableMinBalanceSelector,
  portfolioMarginParamsSelector,
  userSelector,
} from 'selectors';
import {
  CircleWarningRed,
  SmallInfo,
  TickIcon,
  TickRoundFilled,
} from 'styles/assets/icons';
import { Button } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from '../leverage.module.scss';
import {
  ErrorWrapper,
  MarginModeInfoWrapper,
  ChangeAccountWrapper,
  ButtonFooter,
} from './styles';

const ErrorMsg = props => {
  const { error_code, additionalContext = {} } = props;

  const { t } = useTranslation(['errors']);

  const {
    // selectedCoin,
    // prevSelectedCoin,
    portfolioEnableMinBalance,
    accountLimits = {},
    marginMode,
  } = additionalContext;

  return (
    <ErrorWrapper>
      <div className="d-flex">
        <div className="col-md-3 ps-3 pt-3 pb-3 col-3">
          <CircleWarningRed />
        </div>
        <div className="col-md-21 pe-3 pt-3 pb-3 col-20">
          {error_code === 'cant_switch_with_open_orders_or_positions'
            ? t(
                `errors:portfolio_margin.cant_switch_with_open_orders_or_positions_selected`
              )
            : error_code === 'same_margin_mode' && marginMode === 'isolated'
            ? t(`errors:portfolio_margin.isolated_same_margin_mode`)
            : t(`errors:portfolio_margin.${error_code}`, {
                portfolio_enable_balance_threshold: portfolioEnableMinBalance,
                limit: accountLimits[marginMode],
                marginMode,
              })}
        </div>
      </div>
    </ErrorWrapper>
  );
};

ErrorMsg.propTypes = {
  error_code: PropTypes.string.isRequired,
  additionalContext: PropTypes.shape({}),
};

const AssetSelection = props => {
  const { setCoinPortfolio, subAccountPFIndex } = props;
  const portfolioMarginParams = useSelector(state =>
    portfolioMarginParamsSelector(state)
  );

  const assets = sortArrayOfObj(
    convertObjToArray(portfolioMarginParams?.enabled_portfolios),
    'sort_priority'
  );

  return (
    (<div className="asset-selection" data-palette="AssetSelection">
      <div className="d-flex list">
        {assets.map(obj => {
          return (
            (<div
              data-testid="CNfy23ri5IqzI7rJP36Ki"
              key={obj.id}
              role="button"
              tabIndex={0}
              onKeyDown={noop}
              className={`child ${
                subAccountPFIndex === obj.parent_obj_key ? 'active' : ''
              }`}
              onClick={() => setCoinPortfolio(obj.parent_obj_key)}
              data-palette="AssetSelection">
              {obj.underlying_asset}
            </div>)
          );
        })}
      </div>
    </div>)
  );
};

AssetSelection.propTypes = {
  setCoinPortfolio: PropTypes.func.isRequired,
  // selectedCoinPortoflio: PropTypes.string,
  // subAccountMarginMode: PropTypes.string,
  subAccountPFIndex: PropTypes.string,
};
/**
 * @deprecated
 */
const CrossMarginRequestAccess = () => {
  const { t } = useTranslation(['common']);
  const [showMsg, setShowMsg] = useState(false);
  return (
    (<div
      className={styles[`cross-request-access`]}
      data-palette="CrossMarginRequestAccess">
      <div className="flex-set justify-content-end">
        <Button
          data-testid="request-access"
          className={styles[`submit-btn`]}
          primary
          disabled={showMsg}
          onClick={() => setShowMsg(true)}
        >
          {t('common:requestAccess')}
        </Button>
      </div>
      {showMsg && (
        <div
          className={cn('d-flex', styles[`request-warning`])}
          data-palette="CrossMarginRequestAccess">
          <SmallInfo />
          <div className="d-flex flex-column">
            <div className={styles.label}>{t('common:requestAccess')}</div>
            <div
              className={styles.desc}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('common:requestAccessCrossMsg') }}
            />
          </div>
        </div>
      )}
    </div>)
  );
};

const WarningMessage = props => {
  const { title, text } = props;
  return (
    (<div className={styles.warning} data-palette="WarningMessage">
      <div className={cn('d-flex', styles.heading)}>
        <SmallInfo />
        <div className={styles.text}>{title}</div>
      </div>
      <div className={styles.desc}>{text}</div>
    </div>)
  );
};

WarningMessage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ActionButtons = props => {
  const {
    submit,
    onCancel,
    disableSubmit,
    loading,
    // hasChanged,
    className,
    marginType,
    // currentMarginMode,
    confirmEnabled,
    ctaText,
    makeApiCall,
  } = props;
  // const { t } = useTranslation(['common']);
  const { isMobile } = useWindowSize();
  const isSubmitButtonDisable = (() => {
    if (marginType === MARGIN_MODE.CROSS) {
      return !confirmEnabled;
    }
    return disableSubmit || false;
  })();

  return (
    (<div
      className={`flex-set justify-content-end ${className || ''}`}
      data-palette="ActionButtons">
      {makeApiCall ? (
        <DeltaButton
          // className={cn('me-3', styles[`submit-btn`], 'submit-btn')}
          data-testid="margin-mode-switch-button"
          // className={!isMobile ? 'submit-btn' : ''}
          text={ctaText}
          onClick={submit}
          loading={loading}
          disabled={isSubmitButtonDisable}
          fullWidth
          maxWidth={!isMobile ? 250 : null}
        />
      ) : (
        <DeltaButton
          data-testid="margin-mode-continue-button"
          // className={cn('submit-btn', styles[`submit-btn`])}
          text={ctaText}
          onClick={onCancel}
          fullWidth
          maxWidth={!isMobile ? 250 : null}
          disabled={disableSubmit}
        />
      )}
    </div>)
  );
};

ActionButtons.propTypes = {
  submit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  // hasChanged: PropTypes.bool,
  marginType: PropTypes.string,
  // currentMarginMode: PropTypes.string
  confirmEnabled: PropTypes.bool,
  ctaText: PropTypes.string,
  makeApiCall: PropTypes.bool,
};

const MARGIN_MODE_INFO_INDIAN_EXCHANGE = i18n.t(
  'trading:leverageSection.marginModeInfoDataIndianExchange',
  {
    returnObjects: true,
  }
);

const MarginModeInfo = props => {
  const { t } = useTranslation(['trading']);
  const { margin_mode, showTitle } = props;
  const minEquity = useSelector(portfolioEnableMinBalanceSelector);
  const marginModeInfoData = IS_INDIAN_EXCHANGE
    ? MARGIN_MODE_INFO_INDIAN_EXCHANGE
    : t('trading:leverageSection.marginModeInfoData', {
        returnObjects: true,
        minEquity,
        assetSymbol: ASSET_SYMBOL,
      });
  return (
    (<MarginModeInfoWrapper>
      {showTitle && (
        <div className="title capitalise" data-palette="MarginModeInfo">
          {' '}
          {margin_mode} {t('trading:leverageSection.marginBenefits')}
        </div>
      )}
      {marginModeInfoData[margin_mode]?.map(obj => {
        return (
          (<section key={obj.id} data-palette="MarginModeInfo">
            <div className="head">{obj.title}</div>
            {obj.info?.map(pointer => {
              return (
                (<div className="info" key={`info-${obj.id}`} data-palette="MarginModeInfo">
                  <TickIcon />
                  <span>{pointer}</span>
                </div>)
              );
            })}
          </section>)
        );
      })}
    </MarginModeInfoWrapper>)
  );
};

MarginModeInfo.propTypes = {
  margin_mode: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

const ChangeAccount = props => {
  const dispatch = useDispatch();

  const { onCancel, handleCloseModal } = props;
  const { t } = useTranslation(['common']);
  const user = useSelector(userSelector);
  const mainUser = user.main_account || user;
  const subAccounts = [mainUser, ...user.sub_accounts];
  const isTablet = useMediaQuery({ query: device.down.md });

  const [hasChanged, setHasChanged] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    account_name: user.account_name,
    id: user.id,
    index: -1,
  });

  const changeSelectedAccount = (obj, index) => {
    const { account_name, id } = obj;
    setHasChanged(true);
    setSelectedAccount({
      account_name,
      id,
      index,
    });
  };

  const submit = () => {
    trackMixpanelEvent('Account Switched - All Pages - Subaccounts', {
      source: 'Add Margin Pop-up',
      source_account_name: user.account_name,
      destination_account_name: selectedAccount.account_name,
    });
    if (selectedAccount.index === 0) {
      dispatch(switchToMainAccount());
      if (isTablet) {
        window.location.reload();
        handleCloseModal();
      }
    } else {
      dispatch(
        getAccountToken({
          sub_account_user_id: selectedAccount.id,
        })
      ).then(res => {
        if (res.success && isTablet) {
          window.location.reload();
          handleCloseModal();
        }
      });
    }
  };

  const ctaText = () => {
    if (hasChanged) {
      return t('common:confirm');
    }
    return t('common:ok');
  };

  return (
    (<ChangeAccountWrapper>
      <div className="tile-wrapper">
        {subAccounts.map((acc, index) => {
          const isActive = selectedAccount.id === acc.id;
          return (
            (<div
              data-testid="changeAccountModal"
              className={`tile ${isActive ? 'active' : ''}`}
              onClick={() => changeSelectedAccount(acc, index)}
              role="button"
              tabIndex={0}
              onKeyDown={noop}
              key={acc.id}
              data-palette="ChangeAccount">
              <div className="d-flex justify-content-between">
                <div className="highlight acc-name">{acc.account_name}</div>
                <div
                  className={`${user.id === acc.id ? 'active-svg' : ''} tick-round-svg`}
                >
                  <TickRoundFilled />
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div>{t('common:user_id')}</div>
                <div className="highlight">{acc.id}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div>{t('common:email')}</div>
                <div className="highlight">{acc.email}</div>
              </div>
            </div>)
          );
        })}
      </div>
      <ButtonFooter>
        <ActionButtons
          t={t}
          onCancel={onCancel}
          submit={submit}
          // className="change-account-submit-btn"
          makeApiCall={hasChanged}
          ctaText={ctaText()}
        />
      </ButtonFooter>
    </ChangeAccountWrapper>)
  );
};

ChangeAccount.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export {
  AssetSelection,
  WarningMessage,
  ActionButtons,
  MarginModeInfo,
  ChangeAccount,
  ErrorMsg,
  CrossMarginRequestAccess,
};
