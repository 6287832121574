/* eslint-disable import/no-unresolved */
import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import { editOrdersFromBasket } from 'actions/basketOrders';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { showBasketErrorSelector, userMarginModeSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { OrdersObject } from 'types/basketOrders';
import NumberInput from 'UIKit/NumberInput';

import styles from './orders.module.scss';

interface Props {
  order: OrdersObject;
  asset: string;
}

const PriceInput = ({ order, asset }: Props) => {
  const dispatch = useAppDispatch();
  const showError = useAppSelector(showBasketErrorSelector);
  const marginMode = useAppSelector(state => userMarginModeSelector(state));

  const [showHighlight, setShowHighlight] = useState<boolean>(false);

  const handleOrderTypeClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (order: OrdersObject) => {
      const newOrder = { ...order };
      const { symbol, side } = newOrder;
      if (order.order_type === 'limit_order') {
        newOrder.order_type = 'market_order';
        newOrder.limit_price = 0;
      } else {
        newOrder.order_type = 'limit_order';
      }
      trackMixpanelEvent('Basket Order - Limit/Market Switch Clicked', {
        underlying_coin: asset,
        margin_type: marginMode,
        contract_name: symbol,
        side,
      });
      dispatch(editOrdersFromBasket(newOrder));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.order_type]
  );

  const handlePriceChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const price = event.target.value;
    const value = price.replace(',', '.');
    dispatch(editOrdersFromBasket({ ...order, limit_price: value }));
    event.stopPropagation();
  };

  const handleInputFocus = () => {
    if (order.order_type === 'limit_order') {
      setShowHighlight(true);
    } else {
      setShowHighlight(false);
    }
  };

  const handleInputFocusBlur = () => {
    setShowHighlight(false);
  };

  return (
    (<div
      className={cn(styles['price-input'], {
        [styles['red-border']]:
          showError && order.order_type === 'limit_order' && !order.limit_price,
        [styles.highlight]: showHighlight,
      })}
      data-palette="PriceInput">
      <button
        className={`${styles['order-type']} ${styles[`${order.order_type}`]}`}
        onClick={() => handleOrderTypeClick(order)}
        data-testid="basket-orders-order-type-button"
        type="button"
      >
        {order.order_type === 'limit_order' ? 'L' : 'M'}
      </button>
      {/* eslint-disable-next-line test-selectors/onChange */}
      <NumberInput
        value={order.limit_price || ''}
        onChange={handlePriceChange}
        autoComplete="off"
        className={styles['price-input-field']}
        name="orderPrice"
        inputMode="decimal"
        numberType="decimal"
        placeholder={order.order_type === 'limit_order' ? 'Limit Price' : 'Market Price'}
        disabled={order.order_type === 'market_order'}
        data-testid="basket-orders-price-input-box"
        onFocus={handleInputFocus}
        onBlur={handleInputFocusBlur}
      />
    </div>)
  );
};

export default PriceInput;
