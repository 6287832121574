import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { setShowConvertBlockedPopup } from 'actions/other';
import routes from 'constants/routes';
import useKYC from 'hooks/useKYC';
import {
  isUserSpotTradingDisabledSelector,
  showConvertBlockedPopupSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { MaterialConfirmDialog } from 'UIKit';

import styles from './convertBlockedPopup.module.scss';

const ConvertBlockedPopup = () => {
  const { t } = useTranslation(['headerFooter', 'errors']);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { isVerified, isKycRefreshRequired, country } = useKYC();
  const isUserSpotTradingDisabled = useAppSelector(isUserSpotTradingDisabledSelector);
  const showConvertBlockedPopup = useAppSelector(showConvertBlockedPopupSelector);

  const closeConvertPopup = () => dispatch(setShowConvertBlockedPopup(false));

  const goToIdentityVerification = () => {
    closeConvertPopup();
    history.push(routes.account.identityVerification);
  };

  const cases = {
    spotBlocked: {
      description: t('errors:user.spot_blocked_country_message'),
      ctaText: t('headerFooter:okay'),
      onCtaClick: closeConvertPopup,
    },
    unallowedCountry: {
      description: t('headerFooter:facilityNotAvailable'),
      ctaText: t('headerFooter:okay'),
      onCtaClick: closeConvertPopup,
    },
    unverified: {
      description: t('headerFooter:completeIdentityVerification'),
      ctaText: t('headerFooter:header.profile.getVerified'),
      onCtaClick: goToIdentityVerification,
    },
  };

  const convertBlockedCase = (() => {
    if (isUserSpotTradingDisabled) {
      return cases.spotBlocked;
    }

    if (country === 'Singapore') {
      return cases.unallowedCountry;
    }

    if (!isVerified && !isKycRefreshRequired) {
      return cases.unverified;
    }

    return cases.spotBlocked;
  })();

  return (
    <MaterialConfirmDialog
      open={showConvertBlockedPopup}
      onClose={closeConvertPopup}
      onConfirm={convertBlockedCase.onCtaClick}
      title={t('headerFooter:serviceUnavailable')}
      confirmButtonText={convertBlockedCase.ctaText}
      titleClassName={styles.title}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      containerClassName={styles.container}
      cancelClassName={styles.cancelButton}
      confirmClassName={styles.confirmButton}
    >
      <p>{convertBlockedCase.description}</p>
    </MaterialConfirmDialog>
  );
};

export default ConvertBlockedPopup;
