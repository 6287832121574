/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
/* eslint-disable camelcase */

import { useState, useCallback, useMemo } from 'react';

import { closeAllPositions, closePositionBasketOrder } from 'actions/trade';
import { SIDE, ORDER_TYPE, MARGIN_MODE } from 'constants/enums';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import useToggle from 'hooks/useToggle';
import { userMarginModeSelector, userSelector } from 'selectors';
import { allOpenPositionsSelector } from 'selectors/holdingsSelectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import type { OpenPosition, BasketOrderPayload } from 'types/IOpenPosition';

interface SelectedIsolatedOrderPayload {
  user_id: number;
  close_all_portfolio: boolean;
  isolated_product_ids: number[];
}

const useClosePositions = (
  selectedPositionsIds: number[],
  disabled: boolean = false,
  resetSelectedPositions?: () => void,
  initiatorPage = 'PositionsPage-Account',
  onCloseSelectedPositionsCallback?: () => void
) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => userSelector(state));
  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  const userId = user.id;
  // console.log('userId', userId);
  const openPositions: OpenPosition[] = useAppSelector(allOpenPositionsSelector);

  const [closeAllLoader, toggleCloseAllLoader] = useToggle(false);
  const [confirmPopup, toggleConfirmPopup] = useToggle(false);
  const [closeAllBtnLoader, toggleCloseAllBtnLoader] = useToggle(false);

  const [closeIsolated, setCloseIsolated] = useState(true);
  const [closePortfolio, setClosePortfolio] = useState(true);

  const selectedPositionsFiltered = useCallback((): OpenPosition[] => {
    const items = [];
    selectedPositionsIds.forEach(id => {
      const position = openPositions.find((position: any) => position.product_id === id);
      if (position) {
        items.push(position);
      }

      // if (position && position.margin_mode === mode) {
      //   items.push(position);
      // }
    });
    return items;
  }, [selectedPositionsIds, openPositions]);

  const allPositionsFiltered = useCallback(
    (mode: MARGIN_MODE): OpenPosition[] => {
      return openPositions.filter(position => position.margin_mode === mode);
    },
    [openPositions]
  );

  const allIsolatedPositions = allPositionsFiltered(MARGIN_MODE.ISOLATED);
  const allCrossPositions = allPositionsFiltered(MARGIN_MODE.CROSS);
  const allPortfolioPositions = allPositionsFiltered(MARGIN_MODE.PORTFOLIO);

  const selectedIsolatedPositions = selectedPositionsFiltered();
  const selectedPortfolioPositions = selectedPositionsFiltered();
  const selectedCrossPositions = selectedPositionsFiltered();

  const selectedPortfolioLength = selectedPortfolioPositions.length;

  const isAllIsolatedSelected =
    allIsolatedPositions.length === selectedIsolatedPositions.length;
  const isAllCrossSelected = allCrossPositions.length === selectedCrossPositions.length;
  const isAllPortfolioSelected = allPortfolioPositions.length === selectedPortfolioLength;

  const isAllPositionsSelected =
    isAllIsolatedSelected && isAllPortfolioSelected && isAllCrossSelected;

  /*
   * we cant send orders with different index symbols under one payload. eg: order with btc as underlying cant be sent with index symbol DEETHUSDT
   * so, creating an object with different indexes and their respective orders
   * basketOrderPayloadBasedOnSpotIndex = {.DEXBTUSDT: [{}], .DEETHUSDT: [{}]}
   */
  const basketOrderPayloadBasedOnSpotIndex = useMemo(() => {
    return selectedPositionsFiltered().reduce((acc: {}, position: OpenPosition) => {
      const indexSymbol = position.product.spot_index?.symbol!;
      const orderPayload = {
        product_id: position.product_id,
        size: Math.abs(position?.size as number),
        side: position?.size! > 0 ? SIDE.SELL : SIDE.BUY,
        cancel_orders_accepted: 'true',
        order_type: ORDER_TYPE.MARKET,
        reduce_only: true,
      };
      if (acc[indexSymbol]) {
        acc[indexSymbol].push(orderPayload);
      } else {
        acc[indexSymbol] = [orderPayload];
      }
      return acc;
    }, {});
  }, [selectedPositionsFiltered]);

  // const basketOrderPayload: BasketOrderPayload = useMemo(() => {
  //   return {
  //     index_symbol: assetEnabled,
  //     orders: selectedPositionsFiltered().map(position => {
  //       return {
  //         product_id: position.product_id,
  //         size: Math.abs(position?.size),
  //         side: position?.size > 0 ? SIDE.SELL : SIDE.BUY,
  //         cancel_orders_accepted: 'true',
  //         order_type: ORDER_TYPE.MARKET,
  //         reduce_only: true,
  //       };
  //     }),
  //   };
  // }, [assetEnabled, selectedPositionsFiltered]);

  // const isolatedOrderPayload: SelectedIsolatedOrderPayload = useMemo(() => {
  //   // const closePortfolioFlag =
  //   //   (selectedPortfolioLength === 0 || callBothApi) ? false : !isAllPositionsSelected;

  //   return {
  //     user_id: userId,
  //     close_all_portfolio: false,
  //     isolated_product_ids: selectedIsolatedPositions.map(
  //       position => position.product_id
  //     ),
  //   };
  // }, [userId, selectedIsolatedPositions]);

  const handleCloseAllPositions = useCallback(async () => {
    if (disabled) {
      return;
    }
    toggleCloseAllLoader();
    try {
      const payload = {
        close_all_portfolio: marginMode === MARGIN_MODE.PORTFOLIO,
        close_all_isolated: marginMode === MARGIN_MODE.ISOLATED,
        close_all_cross: marginMode === MARGIN_MODE.CROSS,
      };
      await dispatch(closeAllPositions(payload));
      trackMixpanelEvent(`Close All Positions Clicked - ${initiatorPage}`);
    } catch {
    } finally {
      toggleCloseAllLoader();
      toggleConfirmPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, closePortfolio, closeIsolated, disabled]);

  const handleCloseSelectedPositions = useCallback(async () => {
    try {
      toggleCloseAllBtnLoader();
      if (marginMode === MARGIN_MODE.PORTFOLIO) {
        /*
         * revert this after backend is fixed. dispatching actions separately for now.

          // Object.keys(basketOrderPayloadBasedOnSpotIndex).forEach(positionItem => {
          //   dispatch(
          //     closePositionBasketOrder({
          //       index_symbol: positionItem,
          //       orders: basketOrderPayloadBasedOnSpotIndex[positionItem],
          //     })
          //   );
          // });
        */

        const availableSymbols = Object.keys(basketOrderPayloadBasedOnSpotIndex);
        dispatch(
          closePositionBasketOrder({
            index_symbol: availableSymbols[0],
            orders: basketOrderPayloadBasedOnSpotIndex[availableSymbols[0]],
          })
        ).then(res => {
          if (res.success && availableSymbols.length > 1) {
            dispatch(
              closePositionBasketOrder({
                index_symbol: availableSymbols[1],
                orders: basketOrderPayloadBasedOnSpotIndex[availableSymbols[1]],
              })
            );
          }
        });
      } else {
        const payload = {
          product_ids: selectedPositionsIds,
        };
        dispatch(closeAllPositions(payload)).then(res => {
          if (res.success) {
            onCloseSelectedPositionsCallback?.();
          }
        });
      }

      // const promiseMap: Array<Promise<void>> = [
      //   !isEmpty(selectedIsolatedPositions) &&
      //     dispatch(closeAllPositions(isolatedOrderPayload)),
      //   !isEmpty(selectedPortfolioPositions) &&
      //     dispatch(closePositionBasketOrder(basketOrderPayload)),
      // ].filter(Boolean);

      // await Promise.allSettled(promiseMap);

      resetSelectedPositions?.();
    } catch {
    } finally {
      toggleCloseAllBtnLoader();
      toggleConfirmPopup();
    }
    trackMixpanelEvent(`Close Selected Positions - ${initiatorPage}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketOrderPayloadBasedOnSpotIndex, selectedPositionsIds]);

  const closePositionsHandler = () => {
    if (disabled) {
      return;
    }
    // Removing this as close_all api need not be called!
    // if (Boolean(isAllPositionsSelected)) {
    //   toggleConfirmPopup();
    //   return;
    // }
    toggleConfirmPopup();
    // handleCloseSelectedPositions();
  };

  return {
    closeAllLoader,
    confirmPopup,
    toggleConfirmPopup,
    setCloseIsolated,
    closeIsolated,
    setClosePortfolio,
    closePortfolio,
    handleCloseAllPositions,
    closePositionsHandler,
    closeAllBtnLoader,
    isAllPositionsSelected,
    handleCloseSelectedPositions,
  };
};

export type { BasketOrderPayload, SelectedIsolatedOrderPayload };
export default useClosePositions;
