/* eslint-disable camelcase */

import React, { memo, useEffect, useRef } from 'react';

import cx from 'classnames';
import i18next from 'i18next';
import { noop } from 'rxjs';

import BuySellButtonsAndInputRow from 'components/basketOrders/mobileBasketOrders/mobileProductSelectionScreen/buySellButtonsAndInputRow';
import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import { STRIKE_PRICE_TICK_SIZE, UNDERLYING_STRIKE_PRECISION } from 'constants/constants';
import { addZeroesUntilCorrectPrecision, getFilteredDataForOptionChains } from 'helpers';
import { round_by_tick_size } from 'helpers/assetUtils';
import { getNearestStrikePriceForOptionChain } from 'helpers/optionsChain';
import { isBasketOrdersMobilePage, isMarketsPage } from 'helpers/utils';
import { useDidUpdateEffect, useScrollIntoView } from 'hooks';
import { SortArrow } from 'styles/assets/icons';
import { Space } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './styles.module.scss';

const strikeLabel = i18next.t('markets:optionsTable.strike');

const StrikeColumn = () => {
  const {
    isAllOptionSelected,
    showCallOptions,
    cellClassName,
    onColumnClick,
    data,
    isAscending,
    toggleSortOrder,
    usableExpiryDate: defaultExpiryDate,
    selectedAsset: defaultSelectedAsset,
    showBasketOrderBuySellRow,
  } = useOptionsChainData();
  const nearestStrikeRef = useRef<HTMLElement>();
  const didScroll = useRef(false);

  useDidUpdateEffect(() => {
    // Avoids scrolling when sort toggled
    didScroll.current = true;
  }, [isAscending]);

  const checkData =
    data &&
    defaultSelectedAsset &&
    defaultExpiryDate &&
    getFilteredDataForOptionChains(
      data,
      defaultExpiryDate,
      defaultSelectedAsset,
      showCallOptions ? 'call' : 'put'
    );

  const row = checkData && getNearestStrikePriceForOptionChain(checkData);

  useScrollIntoView('basket-order-input-row', showBasketOrderBuySellRow);

  useEffect(() => {
    if (isBasketOrdersMobilePage()) {
      didScroll.current = false;
      return;
    }
    if (checkData && nearestStrikeRef?.current && !didScroll?.current) {
      if (isAllOptionSelected && isMarketsPage()) {
        didScroll.current = false;
      } else {
        requestAnimationFrame(() => {
          nearestStrikeRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          didScroll.current = true;
        });
      }
    }
    return () => {
      didScroll.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearestStrikeRef?.current, checkData]);

  return (
    (<div
      className={`${styles.column} ${styles.strikeColumn}`}
      data-palette="StrikeColumn">
      <DeltaButton
        data-current-sort={isAscending ? 'sort-asc' : 'sort-desc'}
        useBasicButton
        fullWidth
        onClick={toggleSortOrder}
        data-testid="toggle-strike-sort"
        className={cx('naked', styles.header, {
          [styles.asc]: isAscending,
          [styles.desc]: !isAscending,
        })}
      >
        <Space size="xs" className="justify-content-center">
          {strikeLabel}
          <SortArrow />
        </Space>
      </DeltaButton>
      {data.map(({ strike_price, callOptions, putOptions }) => {
        const atm = Number(strike_price) === Number(row?.strike_price);
        const activeOptionsData = showCallOptions ? callOptions : putOptions;
        const isBasketOrderRowActive =
          showBasketOrderBuySellRow.productId === activeOptionsData?.id &&
          showBasketOrderBuySellRow.show;
        return (
          (<React.Fragment key={strike_price} data-palette="StrikeColumn">
            <div
              {...(atm ? { ref: nearestStrikeRef } : {})}
              className={`${cellClassName} ${
                isAllOptionSelected
                  ? styles.bothOptionsStrikeCell
                  : styles.singleOptionsStrikeCell
              }`}
              // ref={atm ? nearestStrikeRef : undefined}
              data-selected={atm}
              onClick={() => onColumnClick(activeOptionsData)}
              data-testid={`options-strike-price-col-${strike_price}`}
              tabIndex={0}
              onKeyDown={noop}
              role="button"
            >
              {addZeroesUntilCorrectPrecision(
                round_by_tick_size(
                  strike_price,
                  STRIKE_PRICE_TICK_SIZE[defaultSelectedAsset] || 0.01
                ),
                UNDERLYING_STRIKE_PRECISION[defaultSelectedAsset] || 0
              )}
            </div>
            {isBasketOrderRowActive && (
              <BuySellButtonsAndInputRow isOptions product={activeOptionsData} />
            )}
          </React.Fragment>)
        );
      })}
    </div>)
  );
};

export default memo(StrikeColumn);
