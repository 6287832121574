import { useTrackFirstTimeDepositFromDepositInfo } from 'hooks/analytics/useTrackFirstTimeDeposit';
import { useUserTracking } from 'hooks/analytics/useUserTracking';

const AnalyticsTrackingGlobal = () => {
  useUserTracking();
  useTrackFirstTimeDepositFromDepositInfo();

  return null;
};

export { AnalyticsTrackingGlobal };
