import type { Dictionary } from 'ramda';
import { createSelector } from 'reselect';

// eslint-disable-next-line import/named
import { computeBalances, computeBalancesBySymbol } from 'helpers/formulas';
import {
  contains,
  filter,
  forEach,
  indexBy,
  isEmpty,
  map,
  mergeAll,
  path,
  pathOr,
  pipe,
  prop,
  reduce,
  sortBy,
  values,
} from 'helpers/ramda';
import type { Asset, AssetBySymbol } from 'types/IAsset';
import type IState from 'types/Istore';
import type {
  ICrossMargin,
  IMultiCollateral,
  IPortfolioMargin,
} from 'types/IVariableStore';
import type { IBalance } from 'types/IWallet';
import store from 'variableStore/store';

import { ENABLED_WALLETS } from '../constants';
import { selectedProductState } from './tradeSelectors';

const walletSelector = (state: IState) => state.wallet;

// export const balanceSelector = (state: IState) => state.wallet.balances;
export const rawBalanceSelector = createSelector(
  [walletSelector],
  wallet => wallet.raw_balances
);

export const rawInvestBalanceSelector = createSelector(
  [walletSelector],
  wallet => wallet.raw_invest_balances
);

export const showPopupSelector = createSelector(
  [walletSelector],
  wallet => wallet.show_popup
);
export const walletPopupTypeSelector = createSelector(
  [walletSelector],
  wallet => wallet.popup_type
);

// export const balanceSelector = (state: IState) => state.wallet.balances;
export const portfolioMarginSelector = (): IPortfolioMargin => store.portfolio_margin;
export const multiCollateralSelector = (): IMultiCollateral => store.multiCollateral;
export const availableMarginCrossSelector = (): number => store.available_margin_cross;
export const crossMarginChannelSelector = (): ICrossMargin => store.crossMarginChannel;
// export const balanceBySymbolSelector = (state: IState) =>
//   state.wallet.balancesBySymbol;
export const addressSelector = createSelector([walletSelector], wallet => wallet.address);
export const roboTradingEquitySelector = createSelector(
  [walletSelector],
  wallet => wallet.robo_trading_equity
);

export const pendingDepositsSelector = (state: IState) => state.wallet.pendingDeposits;

export const fiatDepositsSelector = (state: IState) => state.wallet.fiatDeposits;

export const conversionRateSelector = (state: IState) => state.wallet.conversionRate;
export const isConvertingSelector = (state: IState) => state.wallet.isConverting;
export const isCurrencyPopupOpenSelector = createSelector(
  [walletSelector],
  wallet => wallet.is_currency_popup_open
);
export const convertedDataSelector = (state: IState) => state.wallet.convertedData;
export const conversionConfigSelector = (state: IState) => state.wallet.conversionConfig;
export const coinSelectedSelector = createSelector(
  [walletSelector],
  wallet => wallet.coin_selected
);

const assetSelector = (state: IState) => state.trade.assets;
// const selectedProductSettlingAsset = (state: IState) =>
//   state.trade.selected_product.settling_asset;

export const yieldInvestStrategiesSelector = createSelector(
  [rawInvestBalanceSelector],
  balances =>
    balances && filter(balance => balance.fund_type === 'covered_options', balances)
);

export const stakingInvestStrategiesSelector = createSelector(
  [rawInvestBalanceSelector],
  balances =>
    balances && filter(balance => balance.fund_type === 'staking_pool', balances)
);

export const roboInvestStrategiesSelector = createSelector(
  [rawInvestBalanceSelector],
  balances =>
    balances &&
    filter(
      balance =>
        balance.fund_type !== 'covered_options' && balance.fund_type !== 'staking_pool',
      balances
    )
);

// @ts-ignore
export const assetBySymbolSelector: AssetBySymbol = createSelector(
  [assetSelector],
  assets => indexBy(prop('symbol'), values(assets))
);

const balancesWithAssets = createSelector(
  [assetSelector, rawBalanceSelector],
  (assets, balances) => {
    if (!isEmpty(assets) && !isEmpty(balances)) {
      const balanceWithAsset = forEach(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (obj: any) => (obj.asset = assets[obj.asset_id]),
        balances
      );
      return balanceWithAsset;
    }
    return [];
  }
);

export const balanceSelector = createSelector([balancesWithAssets], balances => {
  return computeBalances(balances);
});

export const balanceBySymbolSelector = createSelector([balancesWithAssets], balances => {
  return computeBalancesBySymbol(balances);
});

export const balanceState = createSelector(balanceSelector, balances => {
  return filter(balance => contains(balance?.asset.symbol, ENABLED_WALLETS), balances);
});

export const enableWalletsSelector = createSelector(
  balanceBySymbolSelector,
  (balances: Dictionary<IBalance>) => {
    return filter<IBalance, IBalance>(balance =>
      contains(balance.asset.symbol, ENABLED_WALLETS)
    )(balances);
  }
);

export const withDrawalEnabledWalletsSelectors = createSelector(
  balanceBySymbolSelector,
  balances => {
    const isWithdrawalEnabled = balance =>
      contains(balance.asset.symbol, ENABLED_WALLETS);
    return filter(isWithdrawalEnabled, balances);
  }
);

const depositEnabledNetworksSelectors = createSelector(
  assetSelector,
  pipe(
    values,
    reduce<Asset, Record<string, Asset['networks']>>((accumulated, currentAsset) => {
      const { symbol, networks } = currentAsset;

      /* Filter out the disabled assets */
      if (!contains(symbol, ENABLED_WALLETS)) {
        return accumulated;
      }

      /* Filter out the disabled networks from the current asset */
      const depositEnabledNetworks = filter(
        network => network.deposit_status === 'enabled',
        networks
      );

      return {
        ...accumulated,
        [symbol]: depositEnabledNetworks,
      };
    }, {})
  )
);

// Returns wallet ids [2,13]
export const walletIdListSelector = createSelector(balanceState, balanceObj => {
  const wallets = values(balanceObj);
  return wallets.map(path(['asset', 'id']));
});

//  returns asset ids with symbol
export const assetSymbolIdSelector = createSelector(balanceState, balanceObj => {
  const wallets = values(balanceObj);

  return mergeAll(map(wallet => ({ [wallet.asset.symbol]: wallet.asset.id }), wallets));
});

export const totalBalanceState = createSelector(
  [balanceSelector, selectedProductState, assetSymbolIdSelector],
  (balances, selectedProduct, assetIDBySymbol) => {
    if (selectedProduct) {
      const { contract_type: contractType } = selectedProduct;
      const id =
        contractType === 'spot'
          ? selectedProduct.underlying_asset.id
          : selectedProduct.settling_asset.id;
      const balance = pathOr('-', [id, 'balance'], balances);
      return `${balance}`;
    }
    const id = assetIDBySymbol.BTC;
    const balance = pathOr('-', [id, 'balance'], balances);
    return `${balance}`;
  }
);

export const availableBalanceState = createSelector(
  [balanceSelector, selectedProductState, assetSymbolIdSelector],
  (balances, selectedProduct, assetIDBySymbol) => {
    if (selectedProduct) {
      const { contract_type: contractType } = selectedProduct;
      const id =
        contractType === 'spot'
          ? selectedProduct.underlying_asset.id
          : selectedProduct.settling_asset.id;
      const balance = pathOr('-', [id, 'available_balance'], balances);
      return `${balance}`;
    }
    const id = assetIDBySymbol.BTC;
    const balance = pathOr('-', [id, 'available_balance'], balances);
    return `${balance}`;
  }
);

export const deltaCashSelector = createSelector([balanceBySymbolSelector], balances => {
  const feeCreditsUSDT = balances?.USDT?.trading_fee_credit_precise;
  return feeCreditsUSDT;
});

const deltaCashSelectorUsd = createSelector([balanceBySymbolSelector], balances => {
  const feeCreditsUSDT = balances?.USD?.trading_fee_credit_precise;
  return feeCreditsUSDT;
});

/**
 * Select available_balance from USD balances, this asset is available for Indian exchange.
 */
const selectUsdAssetAvailableBalance = createSelector(
  [balanceBySymbolSelector],
  balances => {
    return balances?.USD?.available_balance ?? null;
  }
);
const sortedEnabledWalletSelectors = createSelector([enableWalletsSelector], balances => {
  return sortBy(({ asset }) => Number(asset?.sort_priority), values(balances));
});

export {
  deltaCashSelectorUsd,
  depositEnabledNetworksSelectors,
  selectUsdAssetAvailableBalance,
  sortedEnabledWalletSelectors
};

