import React, { ReactNode } from 'react';

import cn from 'classnames';

import Render from 'components/render';
import { CloseIcon, SmallRectangle } from 'styles/assets/icons';
import { IconButton } from 'UIKit';

import styles from './infoStrip.module.scss';

const InfoStrip = ({
  show,
  close,
  children,
  isClosable = true,
  wrapperClassName,
}: {
  show: boolean;
  close: () => void;
  children: ReactNode;
  isClosable?: boolean;
  wrapperClassName?: string;
}) => {
  return (
    <Render when={show}>
      <div className={cn(styles.infoStrip, { [wrapperClassName]: wrapperClassName })}>
        <SmallRectangle className={styles.rectangleIcon} />
        {children}
        <Render when={isClosable}>
          <IconButton
            icon={<CloseIcon className={styles.closeIcon} />}
            onClick={close}
            onKeyDown={close}
            data-testid="infoStripCloseButton"
            className={styles.closeButton}
          />
        </Render>
      </div>
    </Render>
  );
};

export default InfoStrip;
