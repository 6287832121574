import { useState } from 'react';

import { reduce } from 'helpers/ramda';

import useMediaQuery from './useMediaQuery';

// A custom hook to maintain a unique ids map.
// Added for the below functionality
// On desktop => When an element is hovered, show icon for the current one
// and hide other element's icons
const usePopoverBaseIcons = list => {
  const initialState = reduce(
    (acc, cur) => {
      acc[cur] = false;
      return acc;
    },
    {},
    list
  );

  const [iconsToShow, toggleIcons] = useState(initialState);

  const { whenBelowMd } = useMediaQuery();

  const toggleIconById = (id, value) => {
    if (whenBelowMd) {
      return;
    }
    toggleIcons({
      ...initialState,
      [id]: value,
    });
  };

  const resetMap = () => toggleIcons(initialState);

  const getIconMapValueById = id => !!iconsToShow[id];

  return {
    iconsToShow,
    toggleIconById,
    resetMap,
    getIconMapValueById,
  };
};

export default usePopoverBaseIcons;
