import { memo } from 'react';

import cx from 'classnames';

import { RedXIcon, GreenTickIcon } from 'styles/assets/icons';
import IconButton from 'UIKit/IconButton';

import classes from './editToggleBtns.module.scss';

interface EditToggleButtonsProps {
  hide?: boolean;
  btnText: string;
  className?: string;
  isEditMode?: boolean;
  wrapperClass?: string;
  iconBtnClass?: string;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void>;
  onEditClick?: () => void;
  loading?: boolean;
}

/**
 * @summary Shows button with Cross and Tick icons when toggled
 *
 * @typedef {object} EditToggleButtonsProps
 * @param {string} btnText - The text to display on the button
 */
const EditToggleButtons = ({
  hide,
  btnText,
  wrapperClass,
  className,
  iconBtnClass,
  isEditMode,
  loading,
  onEditClick,
  onCancel,
  onConfirm,
}: EditToggleButtonsProps) => {
  if (hide) {
    return null;
  }

  return (
    (<div
      className={cx({
        [wrapperClass]: wrapperClass,
        [classes.edit]: isEditMode,
        [classes.loading]: loading,
      })}
      data-palette="EditToggleButtons">
      {isEditMode ? (
        <>
          <IconButton
            icon={<RedXIcon />}
            onClick={onCancel}
            className={cx(classes.cancel, {
              [`${iconBtnClass}`]: typeof iconBtnClass !== 'undefined',
            })}
          />
          <IconButton
            icon={<GreenTickIcon />}
            onClick={onConfirm}
            className={cx(classes.confirm, {
              [`${iconBtnClass}`]: typeof iconBtnClass !== 'undefined',
            })}
          />
        </>
      ) : (
        <button
          onClick={onEditClick}
          className={cx(classes.editBtn, { [className]: className })}
          data-palette="EditToggleButtons">
          {btnText}
        </button>
      )}
    </div>)
  );
};

export default memo(EditToggleButtons);
