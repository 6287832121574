import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { close2faResetModal } from 'actions/user';
import Render from 'components/render';
import { STORAGE_KEYS } from 'constants/enums';
import { noop } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
  useLockedBody,
  useWindowSize,
  useTwoFa,
} from 'hooks';
import { allOpenPositionsSelector, userSelector } from 'selectors';
import TwoFAResetIcon from 'styles/assets/icons/TwoFAResetIcon';
import { MaterialConfirmDialog } from 'UIKit';

import BackupKey from './BackupKey';
import { reset2faSteps } from './constants';
import GenerateQR from './GenerateQR';
import OTPVerification from './OTPVerification';
import styles from './reset2fa.module.scss';
// import ResetMfaSuccessScreen from './ResetMfaSuccessScreen';

const Reset2FA = () => {
  const { t } = useTranslation(['auth']);
  const { isMobile } = useWindowSize();
  useLockedBody(true);
  const appDispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { isScreenMd } = useWindowSize();
  const [step, setStep] = useState(reset2faSteps.INITIAL);
  const { data } = useTwoFa();

  const openPositions = useAppSelector(allOpenPositionsSelector);
  const isPositionsEmpty = openPositions.length === 0;

  const [, setReset2faPopupShown] = useLocalStorage(
    STORAGE_KEYS.RESET_2FA_POPUP_SHOWN_KEY,
    true
  );

  const closeModal = () => {
    appDispatch(close2faResetModal());
    setStep(reset2faSteps.CLOSED);
    trackMixpanelEvent('Reset 2FA modal closed', {
      userId: user?.id,
      step,
    });

    // Set the flag in localStorage to indicate that the pop-up has been shown
    if (!isPositionsEmpty) {
      setReset2faPopupShown(true);
    }
  };

  const handleFirstStepConfirm = () => {
    setStep(reset2faSteps.SCAN_QR);
    trackMixpanelEvent('Reset 2FA modal confirmed clicked', {
      userId: user?.id,
    });
  };

  useEffect(() => {
    trackMixpanelEvent('Reset 2FA modal opened', {
      userId: user?.id,
    });
  }, [user?.id]);

  return (
    <>
      <MaterialConfirmDialog
        // title={t('auth:reset2faPopups.step1.title')}
        open={step === reset2faSteps.INITIAL && step !== reset2faSteps.CLOSED}
        onClose={isPositionsEmpty ? noop : closeModal}
        // onCancel={closeModal}
        // eslint-disable-next-line no-unneeded-ternary
        showCrossIcon={isPositionsEmpty ? false : true}
        onConfirm={handleFirstStepConfirm}
        showCancel={false}
        confirmButtonText={t('auth:reset2faPopups.reset2fa')}
        // cancelButtonText={t('auth:reset2faPopups.remindLater')}
        transition={isScreenMd}
        titleClassName={styles.title}
        headerClassName={styles.header}
        bodyClassName={cn(styles.body, styles.step1)}
        footerClassName={styles.footer}
        containerClassName={styles.container}
        // cancelClassName={styles.cancelButton}
        confirmClassName={styles.confirmButtonFirstStep}
        showHeader
        fullWidth={isMobile}
        disableScrollLock
      >
        <TwoFAResetIcon />
        <p className={styles.step1Title}>{t('auth:reset2faPopups.step1.title')}</p>
        <p className={styles.step1Description}>
          {t('auth:reset2faPopups.step1.description')}
        </p>
      </MaterialConfirmDialog>

      <Render when={step === reset2faSteps.SCAN_QR && step !== reset2faSteps.CLOSED}>
        <GenerateQR
          isOpen={step === reset2faSteps.SCAN_QR && step !== reset2faSteps.CLOSED}
          data={data}
          close={closeModal}
          next={() => setStep(reset2faSteps.BACKUP_KEY)}
        />
      </Render>

      <Render when={step === reset2faSteps.BACKUP_KEY && step !== reset2faSteps.CLOSED}>
        <BackupKey
          isOpen={step === reset2faSteps.BACKUP_KEY && step !== reset2faSteps.CLOSED}
          closeModal={closeModal}
          data={data}
          confirmClick={() => setStep(reset2faSteps.RESET_2FA)}
        />
      </Render>

      <Render when={step === reset2faSteps.RESET_2FA && step !== reset2faSteps.CLOSED}>
        <OTPVerification
          isOpen={step === reset2faSteps.RESET_2FA && step !== reset2faSteps.CLOSED}
          closeModal={closeModal}
          data={data}
        />
      </Render>

      {/* <Render when={step === reset2faSteps.SUCCESS && step !== reset2faSteps.CLOSED}>
        <ResetMfaSuccessScreen
          isOpen={step === reset2faSteps.SUCCESS && step !== reset2faSteps.CLOSED}
        />
      </Render> */}
    </>
  );
};

export default Reset2FA;
