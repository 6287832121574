import React, { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { setShowKycUpgradeStrip } from 'actions/other';
import InfoStrip from 'components/InfoStrip/InfoStrip';
import { KYC_UPGRADE_TRADING_BLOCKED_DATE } from 'constants/constants';
import routes from 'constants/routes';
import { unixTimeDiffInSeconds } from 'helpers';
import { formatSecondsToHMS } from 'helpers/day';
import { useWindowSize } from 'hooks';
import { activeTabSelector, otherSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './kycUpgradeCountdownStrip.module.scss';

const TWO_DAYS_IN_SECONDS = 48 * 60 * 60;
/* Mobile Tabs on which strip is shown. (Similar to Maintenance Banner Mobile) */
const mobileShowTabs = ['tabpositions', 'tabtrade', 'markets'];
/* Mobile Tabs on which strip is sticky postioned. (Similar to Maintenance Banner Mobile) */
const mobileStickyTabs = ['tabtrade', 'tabpositions'];

const KycUpgradeCountdownStrip = () => {
  const { showKycUpgradeStrip } = useAppSelector(otherSelector);
  /* Time Left until trading is blocked (in Seconds) */
  const timeLeftToTradingBlocked = unixTimeDiffInSeconds(
    KYC_UPGRADE_TRADING_BLOCKED_DATE
  );
  const [countdownTimer, setCountdownTimer] = useState<number>(timeLeftToTradingBlocked);
  const formattedCountdownTimer = formatSecondsToHMS(countdownTimer);
  const countdownRef = useRef<number>(0);
  /**
   * Keeping countDown value in a ref so that onMount useEffect is not run for each render.
   */
  countdownRef.current = countdownTimer;

  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const { t } = useTranslation(['account']);
  const dispatch = useAppDispatch();

  const closeStrip = useCallback(() => {
    dispatch(setShowKycUpgradeStrip(false));
  }, [dispatch]);

  /**
   * onMount the countDown is started if there are 2 days or less left to trading blocked.
   */
  useEffect(() => {
    if (countdownRef.current <= TWO_DAYS_IN_SECONDS && countdownRef.current > 0) {
      timerRef.current = setInterval(() => {
        if (countdownRef.current <= 0) {
          clearInterval(timerRef.current);
          closeStrip();
        } else {
          setCountdownTimer(state => state - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [closeStrip]);

  /* UI Config */
  const { isScreenMd } = useWindowSize();
  const drawerTab = useAppSelector(activeTabSelector);
  const showOnMobileTab = mobileShowTabs.includes(drawerTab);
  const isStickyTab = isScreenMd && mobileStickyTabs.includes(drawerTab);
  const showStrip = isScreenMd
    ? showKycUpgradeStrip && showOnMobileTab
    : showKycUpgradeStrip;

  return (
    <InfoStrip
      show={showStrip}
      close={closeStrip}
      wrapperClassName={cn(styles.kycUpgradeStrip, {
        [styles.positionSticky]: isStickyTab,
      })}
    >
      <p className={styles.title}>
        {t('account:profile.kycRefreshDeadlineStrip.body')}{' '}
        <span>{formattedCountdownTimer}</span>
      </p>

      <DeltaButton
        variant="link-t1"
        className={styles.upgradeLink}
        LinkComponent={Link}
        to={routes.account.identityVerification}
        data-testid="identityVerificationLink"
        onClick={closeStrip}
      >
        {t('account:profile.kycUpgradeStrip.description')}
      </DeltaButton>
    </InfoStrip>
  );
};

export default KycUpgradeCountdownStrip;
