/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import React, { useEffect, useMemo, useState } from 'react';

import { updateUserPreference } from 'actions/user';
import {
  calculateDefaultContractValue,
  decimalHandle,
  getPrimaryCurrency,
  getQuantityOfContracts,
  getSecondaryCurrency,
} from 'components/placeorder/partials/quantity/helper';
import { ASSET_SYMBOL } from 'constants/constants';
import {
  cropAfterDecimals,
  getDefaultPrecision,
  getFormattedInputValue,
  isEmpty,
  isOptions,
} from 'helpers';
// import { selectedProductIdSelector } from 'selectors';
// import { useAppDispatch, useAppSelector } from 'storeHooks';
import {
  markPriceSelectorById,
  markPriceState,
  selectedProductSelector,
  spotPriceSelectorBySymbol,
  spotPriceState,
  userPreferencesSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { Asset } from 'types/IAsset';
import { ContractType, NotionalType, Product } from 'types/IProducts';
import { Preferences, QuantityDropdownPreference } from 'types/IUser';

enum QuantityDropDownType {
  QUOTING = 'quoting_asset',
  SETTLING = 'settling_asset',
  UNDERLYING = 'underlying_asset',
  CONT = 'cont',
}

const getDefaultCurrency = (
  secondaryCurrency: string,
  primaryCurrency: string,
  quantityDropdownPrefrence: QuantityDropdownPreference,
  contract_type: ContractType,
  notional_type: NotionalType
) => {
  // dropdown can either be primary or secondary or cont
  if (notional_type === NotionalType.Inverse) {
    if (quantityDropdownPrefrence.inverse) {
      switch (quantityDropdownPrefrence.inverse) {
        case QuantityDropDownType.QUOTING:
          return primaryCurrency;
        case QuantityDropDownType.UNDERLYING:
          return secondaryCurrency;
        case QuantityDropDownType.CONT:
          return 'Cont';
        default:
          return primaryCurrency;
      }
    }
  }

  if (notional_type === NotionalType.Vanilla) {
    if (secondaryCurrency === ASSET_SYMBOL) {
      if (quantityDropdownPrefrence?.vanilla?.usdt_settled) {
        switch (quantityDropdownPrefrence?.vanilla?.usdt_settled) {
          case QuantityDropDownType.SETTLING:
            return secondaryCurrency;
          case QuantityDropDownType.UNDERLYING:
            return primaryCurrency;
          case QuantityDropDownType.CONT:
            return 'Cont';
          default:
            return primaryCurrency;
        }
      } else {
        return primaryCurrency;
      }
    }

    if (quantityDropdownPrefrence?.vanilla?.non_usdt_settled) {
      switch (quantityDropdownPrefrence?.vanilla?.non_usdt_settled) {
        case QuantityDropDownType.SETTLING:
          return secondaryCurrency;
        case QuantityDropDownType.UNDERLYING:
          return primaryCurrency;
        case QuantityDropDownType.CONT:
          return 'Cont';
        default:
          return primaryCurrency;
      }
    } else {
      return primaryCurrency;
    }
  }

  return primaryCurrency;
};

interface useQuantityProps {
  product: Product;
  // price: string | null;
  prefilledQuantity: number | null;
  callback?: (arg0: number) => void;
}
/**
 * Quantity hook to get quantity number in underlying , settling and in contract terms.
 * Valid for only derivatives futures and options only.
 *
 * @param {Product} product - product for which quantity nos. has to be computed
 * @param {price} price - price to be used for conversion from primary to secondary asset . spotPrice for options , limitPrice or impactPrice or markPrice for other product.
 * @param {prefilledQuantity} prefilledQuantity - prefilled quantity that might be present from global or parent state.
 * @param {callback} callback - function to change quantity in global or parent state.
 */
const useQuantity = ({
  product,
  // price,
  prefilledQuantity,
  callback,
}: useQuantityProps) => {
  const selectedProduct = useAppSelector(selectedProductSelector);
  const markPrice =
    selectedProduct?.id === product.id
      ? markPriceState()
      : markPriceSelectorById(product.id);

  const spotPrice =
    selectedProduct?.id === product.id
      ? spotPriceState()
      : spotPriceSelectorBySymbol(product.spot_index?.symbol);
  const dispatch = useAppDispatch();
  // const selectedProductId = useAppSelector(state => selectedProductIdSelector(state));

  const price = useMemo(() => {
    if (isOptions(product.contract_type)) {
      return spotPrice;
    }
    return markPrice;
  }, [markPrice, product.contract_type, spotPrice]);
  const {
    contract_type,
    notional_type,
    quoting_asset,
    underlying_asset,
    settling_asset,
    contract_unit_currency,
    contract_value,
  } = product || {};

  const defaultPrecision = getDefaultPrecision(product);

  const primaryCurrency = getPrimaryCurrency(
    contract_type as ContractType,
    notional_type as NotionalType,
    underlying_asset as Asset,
    quoting_asset as Asset,
    contract_unit_currency as string
  );
  const secondaryCurrency = getSecondaryCurrency(
    contract_type as ContractType,
    notional_type as NotionalType,
    underlying_asset as Asset,
    quoting_asset as Asset,
    settling_asset as Asset
  );

  const userPrefrences = useAppSelector(state =>
    userPreferencesSelector(state)
  ) as Preferences;
  const quantityDropdownPrefrence = userPrefrences?.quantity_dropdown_preference
    ? { ...userPrefrences.quantity_dropdown_preference }
    : {
        inverse: null,
        vanilla: {
          usdt_settled: null,
          non_usdt_settled: null,
        },
      };
  const defaultCurrencyByPrefrence = getDefaultCurrency(
    secondaryCurrency,
    primaryCurrency,
    quantityDropdownPrefrence,
    contract_type,
    notional_type
  );

  const [isQuantityEditable, setIsQuantityInputEditable] = useState(false);
  const [quantityCurrency, setQuantityCurrency] = useState<string>(
    () => defaultCurrencyByPrefrence
  );
  const [quantity, setQuantity] = useState<string | number>('');
  const currencyDropdownOption = [primaryCurrency, secondaryCurrency, 'Cont'];

  const setCalculatedQuantity = (asset: string) => {
    const defaultContractValue = calculateDefaultContractValue(
      prefilledQuantity,
      Number(contract_value),
      notional_type,
      quoting_asset,
      underlying_asset
    );
    if (asset === 'Cont') {
      setQuantity(Number(prefilledQuantity));
    }
    // value changed from secondaryCurrency to PrimaryCurrency
    if (asset === primaryCurrency) {
      setQuantity(defaultContractValue);
    }

    // value changed from primaryCurrency to secondaryCurrency
    if (asset === secondaryCurrency) {
      setQuantity(() => {
        let quantityInSecondary;
        if (notional_type === NotionalType.Inverse) {
          quantityInSecondary = Number(defaultContractValue) / Number(price);
        } else {
          quantityInSecondary = Number(defaultContractValue) * Number(price);
        }
        return Number(cropAfterDecimals(quantityInSecondary, defaultPrecision));
      });
    }
  };

  // to-do : how to simplify this ?
  const setQuantityCurrencyInPrefrences = (asset: string) => {
    if (notional_type === NotionalType.Inverse) {
      const value = (() => {
        switch (asset) {
          case primaryCurrency:
            return QuantityDropDownType.QUOTING;
          case secondaryCurrency:
            return QuantityDropDownType.UNDERLYING;
          case 'Cont':
            return QuantityDropDownType.CONT;
          default:
            return null;
        }
      })();
      dispatch(
        updateUserPreference(
          {
            preferences: {
              quantity_dropdown_preference: {
                inverse: value,
                vanilla: {
                  usdt_settled:
                    userPrefrences?.quantity_dropdown_preference?.vanilla?.usdt_settled ||
                    null,
                  non_usdt_settled:
                    userPrefrences?.quantity_dropdown_preference?.vanilla
                      ?.non_usdt_settled || null,
                },
              },
            },
          },
          { disable_notification: true }
        )
      );
    }

    if (notional_type === NotionalType.Vanilla) {
      const value = (() => {
        switch (asset) {
          case primaryCurrency:
            return QuantityDropDownType.UNDERLYING;
          case secondaryCurrency:
            return QuantityDropDownType.SETTLING;
          case 'Cont':
            return QuantityDropDownType.CONT;
          default:
            return null;
        }
      })();

      if (settling_asset?.symbol === ASSET_SYMBOL) {
        dispatch(
          updateUserPreference(
            {
              preferences: {
                quantity_dropdown_preference: {
                  inverse: userPrefrences?.quantity_dropdown_preference?.inverse || null,
                  vanilla: {
                    usdt_settled: value,
                    non_usdt_settled:
                      userPrefrences?.quantity_dropdown_preference?.vanilla
                        ?.non_usdt_settled || null,
                  },
                },
              },
            },
            { disable_notification: true }
          )
        );
      }

      if (settling_asset?.symbol !== ASSET_SYMBOL) {
        dispatch(
          updateUserPreference(
            {
              preferences: {
                quantity_dropdown_preference: {
                  inverse: userPrefrences?.quantity_dropdown_preference?.inverse || null,
                  vanilla: {
                    usdt_settled:
                      userPrefrences?.quantity_dropdown_preference?.vanilla
                        ?.usdt_settled || null,
                    non_usdt_settled: value,
                  },
                },
              },
            },
            { disable_notification: true }
          )
        );
      }
    }
  };

  const onDropdownChange = ({ value }: { value: string }) => {
    setQuantityCurrency(value);
    setQuantityCurrencyInPrefrences(value);
    if (quantity) {
      setCalculatedQuantity(value);
    }
  };

  const onQuantityChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = getFormattedInputValue(event.target.value);

    let quantityInContracts;
    if (quantityCurrency !== 'Cont') {
      setQuantity(value);
      quantityInContracts = getQuantityOfContracts({
        selectedCurrency: quantityCurrency,
        primaryCurrency,
        secondaryCurrency,
        price,
        optionsPrice: price,
        contractValue: contract_value,
        inputedQuantity: decimalHandle(value),
        contractType: contract_type,
        notionalType: notional_type,
      });
    } else {
      const intValue = parseInt(String(Number(decimalHandle(value))), 10);
      setQuantity(intValue);
      quantityInContracts = intValue;
    }
    callback?.(quantityInContracts);
  };

  useEffect(() => {
    if (!isEmpty(product)) {
      setQuantityCurrency(defaultCurrencyByPrefrence);
      setCalculatedQuantity(defaultCurrencyByPrefrence);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useMemo(() => {
    // console.log("DEBUG useMemo",isQuantityEditable,prefilledQuantity)
    if (!isQuantityEditable) {
      setCalculatedQuantity(quantityCurrency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Number(prefilledQuantity), isQuantityEditable]);

  return {
    quantity,
    quantityCurrency,
    setIsQuantityInputEditable,
    currencyDropdownOption,
    onDropdownChange,
    onQuantityChange,
  };
};

export default useQuantity;
