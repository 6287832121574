import React, { useCallback, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import OTPInput from 'components/OTPVerification';
import Render from 'components/render';
import { OTP_LENGTH } from 'constants/constants';
import { useWindowSize } from 'hooks';
import Box from 'UIKit/Box';
import DeltaButton from 'UIKit/DeltaButton/DeltaButton';
import { DeltaInputWrapper } from 'UIKit/DeltaInput';
import MaterialConfirmDialog from 'UIKit/MaterialConfirmDialog';

import styles from './deltaOTPVerification.module.scss';
import {
  CODE_TYPE,
  OTP_VERIFICATION_VARIANT,
  ModalWrapper as ModalWrapperProps,
  OTPVerification as OTPVerificationProps,
  DeltaOTPVerification as DeltaOTPVerificationProps,
  CodeType,
  Codes,
} from './DeltaOTPVerification.types';
import SpamCheck from 'components/common/SpamCheck/SpamCheck';

/**
 * `ModalWrapper` component. This is a wrapper for `MaterialConfirmDialog` component.
 *
 * @component
 * @param {object} props - Properties passed to component
 * @param {ReactNode} props.children - The content of the dialog
 * @param {boolean} [props.isOpen=false] - Whether the modal is open
 * @param {boolean} [props.isLoading=false] - Whether the modal is in a loading state
 * @param {boolean} props.canSubmit - If submit button should be enabled or not
 * @param {Function} props.onConfirm - Handler for onConfirm event
 * @param {Function} props.onClose - Handler for onClose event
 *
 * @example
 *  <ModalWrapper isOpen={true} isLoading={false} canSubmit={true} onConfirm={handleConfirm} onClose={handleClose}>
 *    <p>Modal Content</p>
 *  </ModalWrapper>
 */
const ModalWrapper = ({
  children,
  isOpen = false,
  isLoading = false,
  canSubmit,
  onConfirm,
  onClose,
  fullHeight,
  showError = false,
  error = '',
}: ModalWrapperProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  return (
    <MaterialConfirmDialog
      variant="v2"
      showFooter
      loading={isLoading}
      open={isOpen}
      onClose={onClose}
      fullHeight={fullHeight ?? isMobile}
      fullWidth={isMobile}
      marginTopInherit
      transition={false}
      title={t('auth:securityVerification')}
      confirmButtonText={t('common:submit')}
      disableConfirmButton={!canSubmit}
      onConfirm={onConfirm}
      showError={showError}
      error={error}
    >
      {children}
    </MaterialConfirmDialog>
  );
};

/**
 * `OTPVerification` component. This is a wrapper for OTP verification process.
 *
 * @component
 * @param {object} props - Properties passed to component
 * @param {boolean} [props.isLoading=false] - Whether the OTP verification is in a loading state
 * @param {boolean} [props.canSubmit=false] - If submit button should be enabled or not
 * @param {object} props.emailCodeConfig - Configuration for the email verification
 * @param {boolean} [props.showMfa=false] - If MFA should be shown
 * @param {object} props.mfaCodeConfig - Configuration for the MFA verification
 * @param {object} [props.errors=null] - Errors for the OTP Verification process
 * @param {Function} props.onCodeChange - Handler for onCodeChange event
 * @param {Function} props.onResendOtp - Handler for onResendOtp event
 * @param {Function} props.onConfirm - Handler for onConfirm event
 *
 * @example
 *  <OTPVerification
 *    isLoading={false}
 *    canSubmit={true}
 *    emailCodeConfig={emailConfig}
 *    showMfa={true}
 *    mfaCodeConfig={mfaConfig}
 *    onCodeChange={handleCodeChange}
 *    onResendOtp={handleResend}
 *    onConfirm={handleConfirm} />
 */
const OTPVerification = ({
  isLoading = false,
  canSubmit = false,
  emailCodeConfig,
  showMfa = false,
  mfaCodeConfig,
  errors = null,
  canResendEmailCode = false,

  onCodeChange,
  onResendOtp,
  onConfirm,
}: OTPVerificationProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { t } = useTranslation();

  return (
    <Box flexDir="column" className={styles.modalContentContainer}>
      <DeltaInputWrapper
        id="email-verification-code"
        label={t('account:emailCode')}
        showHelperTextIcon={false}
        error={errors?.emailCode}
        recommendation={emailCodeConfig?.recommendation}
        controlRootClassName={styles.verificationOtpControlRoot}
        control={
          <OTPInput
            autoFocus
            isNumberInput
            hideSendCode={canResendEmailCode}
            length={OTP_LENGTH}
            onChangeOTP={otp => onCodeChange(otp, CODE_TYPE.EMAIL)}
            ref={recaptchaRef}
            className={styles.otpInputContainer}
            inputClassName={`${styles.verificationOtpInput} ${
              Boolean(errors?.emailCode) && styles.erroredVerificationOtpInput
            }`}
            handleSubmit={onResendOtp}
            resendCodeText={t('common:resendCode')}
            resendCodeClassName={cx(styles.resendOtp, 'updatePswVerificationResendOtp')}
          />
        }
      />

      <Render when={showMfa}>
        <DeltaInputWrapper
          id="2fa-code"
          label={t('auth:2faCode')}
          showHelperTextIcon={false}
          error={errors?.mfaCode}
          recommendation={mfaCodeConfig?.recommendation}
          controlRootClassName={styles.verificationOtpControlRoot}
          footerClassName={styles.otpInputContainerFooter}
          control={
            <OTPInput
              isNumberInput
              hideSendCode
              length={OTP_LENGTH}
              className={styles.otpInputContainer}
              inputClassName={`${styles.verificationOtpInput} ${
                Boolean(errors?.mfaCode) && styles.erroredVerificationOtpInput
              }`}
              onChangeOTP={otp => onCodeChange(otp, CODE_TYPE.MFA)}
            />
          }
        />
      </Render>
      <Render when={Boolean(onConfirm)}>
        <DeltaButton
          data-testid="submit-otp-btn"
          variant="primary-t1"
          disabled={!canSubmit}
          loading={isLoading}
          onClick={onConfirm}
        >
          {t('common:submitOtp')}
        </DeltaButton>
      </Render>
      <div className="spam-check-container">
        <SpamCheck width={345} />
      </div>
    </Box>
  );
};

/**
 * `DeltaOTPVerification` component. This is a higher order component wrapping both OTPVerification and ModalWrapper
 *
 * @component
 * @param {object} props - Properties passed to component
 * @param {string} [props.variant=OTP_VERIFICATION_VARIANT.PANEL] - The variant of OTP verification
 * @param {boolean} [props.showMfa=false] - If MFA should be shown
 * @param {boolean} [props.isLoading=false] - Whether the OTP verification is in a loading state
 * @param {Function} props.onConfirm - Handler for onConfirm event
 * @param {Function} props.onResendOtp - Handler for onResendOtp event
 * @param {boolean} props.isOpen - If the Modal is open
 * @param {Function} [props.onClose] - Handler for onClose event
 * @param {object} [props.errors=null] - Errors for the OTP Verification process
 * @param {object} props.emailCodeConfig - Configuration for the email verification
 * @param {object} props.mfaCodeConfig - Configuration for the MFA verification
 *
 * @example
 *  <DeltaOTPVerification
 *    variant={OTP_VERIFICATION_VARIANT.MODAL}
 *    showMfa={true}
 *    isLoading={false}
 *    onConfirm={handleConfirm}
 *    onResendOtp={handleResend}
 *    isOpen={true}
 *    onClose={handleClose}
 *    emailCodeConfig={emailConfig}
 *    mfaCodeConfig={mfaConfig} />
 */
const DeltaOTPVerification = ({
  variant = OTP_VERIFICATION_VARIANT.PANEL,
  showMfa = false,
  isLoading = false,
  onConfirm,
  onResendOtp,
  isOpen,
  onClose = () => {},
  errors = null,
  canResendEmailCode = false,

  emailCodeConfig,
  mfaCodeConfig,
  fullHeight,
}: DeltaOTPVerificationProps) => {
  const [codes, setCodes] = useState<Codes>({
    emailCode: '',
  });
  const onCodeChange = useCallback((code: string, type: CodeType) => {
    setCodes(prevCodes => ({
      ...prevCodes,
      [type]: code,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    onConfirm(codes);
  }, [codes, onConfirm]);

  const canSubmit = useMemo(() => {
    const isEmailCodeValid = (codes?.emailCode ?? '')?.length === OTP_LENGTH;

    if (!showMfa) return isEmailCodeValid;

    return isEmailCodeValid && (codes?.mfaCode ?? '')?.length === OTP_LENGTH;
  }, [codes?.emailCode, codes?.mfaCode, showMfa]);

  if (variant === OTP_VERIFICATION_VARIANT.PANEL) {
    return (
      <OTPVerification
        showMfa={showMfa}
        onCodeChange={onCodeChange}
        canSubmit={canSubmit}
        errors={errors}
        canResendEmailCode={canResendEmailCode}
        onResendOtp={onResendOtp}
        onConfirm={onSubmit}
        emailCodeConfig={emailCodeConfig}
        mfaCodeConfig={mfaCodeConfig}
      />
    );
  }

  if (variant === OTP_VERIFICATION_VARIANT.MODAL) {
    return (
      <ModalWrapper
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onSubmit}
        canSubmit={canSubmit}
        fullHeight={fullHeight}
        showError={Boolean(errors?.custom)}
        error={errors?.custom}
      >
        <OTPVerification
          showMfa={showMfa}
          errors={errors}
          canResendEmailCode={canResendEmailCode}
          onCodeChange={onCodeChange}
          onResendOtp={onResendOtp}
          emailCodeConfig={emailCodeConfig}
          mfaCodeConfig={mfaCodeConfig}
        />
      </ModalWrapper>
    );
  }

  return null;
};

export default DeltaOTPVerification;
