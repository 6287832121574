import GENERAL_DATA, { GENERAL_DATA_ACTION_KEYS } from 'actionTypes/generalData';
import OTHERS, {
  SAVE_CHART,
  TAB_INACTIVE,
  TAB_REACTIVE,
  UPDATE_TABLE_FILTER,
  OPEN_ADD_ADDRESSMNGT_MODAL,
  SOCKET_DISCONNECTED_ON_PAGE_INACTIVE,
  DISPATCH_HOLDINGS_API,
  DISPATCH_OPTION_LEADERBOARD_API,
  TOGGLE_MOBILE_HEADER_DROPDOWN,
  SHOW_KYC_UPGRADE_STRIP,
  SHOW_CONVERT_BLOCKED_POPUP,
  FETCH_LEADERBOARD_EVENT_DETAILS,
  SHOW_OTP_SUCCESS_POPUP,
  SET_COUNTRY_FROM_IP,
} from 'actionTypes/other';
import {
  LEADERBOARD_API,
  LEADERBOARD_EVENT_DETAILS_API,
  USER_PROFILE,
} from 'constants/api';
import createAction from 'helpers/createAction';
import { OptionLeaderBoardArgs } from 'types/actions/other';

export const updateTableFilter = createAction(UPDATE_TABLE_FILTER);
export const tabReactive = createAction(TAB_REACTIVE); // when tab goes from inactive to active
export const socketDisconnectedOnPageInactive = createAction(
  SOCKET_DISCONNECTED_ON_PAGE_INACTIVE
); // when sockets are disconnected due to long inactivity
export const tabInactive = createAction(TAB_INACTIVE); // when tab goes from active to inactive
export const noOp = createAction('NOOP');

export const saveChart = createAction(SAVE_CHART);

export const updateChartOnChangeResolution = createAction(TAB_INACTIVE); // when tab goes from active to inactive
export const openAddressManagementModal = createAction(OPEN_ADD_ADDRESSMNGT_MODAL);
export const dispatchHoldingApi = createAction(DISPATCH_HOLDINGS_API);

export const getOptionLeaderBoard = ({
  backendRankSort,
  resolution,
  userId,
  partnerCode,
  product,
}: OptionLeaderBoardArgs) => {
  return {
    type: DISPATCH_OPTION_LEADERBOARD_API,
    promise: ({ request }: { request: any }) => {
      return request.get(LEADERBOARD_API, {
        params: {
          resolution,
          userId,
          sort: backendRankSort,
          referral_code: partnerCode,
          leaderboard_type: product,
        },
      });
    },
  };
};

export const toggleMobileHeaderDropdown = createAction(TOGGLE_MOBILE_HEADER_DROPDOWN);

export const setShowKycUpgradeStrip = createAction(SHOW_KYC_UPGRADE_STRIP);

const setShowConvertBlockedPopup = createAction(SHOW_CONVERT_BLOCKED_POPUP);
const setShowOtpSuccessPopup = createAction(SHOW_OTP_SUCCESS_POPUP);
const setCountryFromIP = createAction(SET_COUNTRY_FROM_IP);

const fetchLeaderBoardEventDetails = (referralCode?: string) => {
  return {
    types: FETCH_LEADERBOARD_EVENT_DETAILS,
    promise: ({ request }) =>
      request.get(LEADERBOARD_EVENT_DETAILS_API, {
        params: {
          referral_code: referralCode,
          is_leaderboard_referral: Boolean(referralCode),
        },
      }),
  };
};

export function getGeneralIndiaData(result) {
  return {
    type: GENERAL_DATA[GENERAL_DATA_ACTION_KEYS.GENERAL_DATA].SUCCESS,
    payload: result,
  };
}

function fetchCountryFromIP() {
  return {
    types: OTHERS.COUNTRY_FROM_IP,
    promise: ({ request }) => request.post(USER_PROFILE.IP_DETAILS),
  };
}

export {
  setShowConvertBlockedPopup,
  fetchLeaderBoardEventDetails,
  setShowOtpSuccessPopup,
  setCountryFromIP,
  fetchCountryFromIP,
};
