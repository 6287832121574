import routes from 'constants/routes';

// ROUTES where info strip is displayed when no specific route comes from server.
const DEFAULT_INFORMATION_STIP_ROUTES = [
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.move_options.trade,
  routes.spot.trade,
  routes.optionsCombos.trade,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.optionsCombos.markets,
  routes.expired_futures_and_options,
  routes.options_chain.markets,
  routes.favorites.markets,
  routes.portfolio,
  routes.customLeaderBoard,
  routes.easy_options.trade,
  routes.bell_alerts,
  routes.optionsAnalyticsDashboard.default,
  ...Object.values(routes.homepage),
  routes.account.deposit,
  routes.account.buy_crypto,
  routes.account.positions,
  routes.account.accountAnalytics,
  routes.account.balances,
  routes.account.withdrawal,
  routes.account.withdrawalBank,
  routes.account.portfolioMargin,
  routes.account.identityVerification,
  routes.account.profile,
  routes.account.subAccounts,
];

export { DEFAULT_INFORMATION_STIP_ROUTES };
