/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';

import { changeBasketOrdersScreen } from 'actions/basketOrders';
import { OptionChainProvider } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import { BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
import { useWindowSize } from 'hooks';
import {
  basketActiveUnderlyingAssetSelector,
  basketOrderCurrentScreenSelector,
  basketOrderOrdersSelector,
  productsSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { IOrdersObject, OrdersObject } from 'types/basketOrders';
import { Product } from 'types/IProducts';

import AddContracts from '../AddContractsScreen';
import MobileProductSelectionScreen from '../mobileBasketOrders/mobileProductSelectionScreen';
import OrdersPlacementScreen from '../OrdersPlacementScreen';
import ProductsSelectionScreen from '../ProductsSelectionScreen';

interface BasketOrderScreenProps {
  product: Product | null;
}

interface CurrentScreenProps {
  asset: string;
  currentScreenView: string;
  isMobile: boolean;
}

const RenderCurrentScreen = ({
  asset,
  currentScreenView,
  isMobile,
}: CurrentScreenProps) => {
  switch (currentScreenView) {
    case BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW:
      return isMobile ? (
        <OptionChainProvider>
          {' '}
          <MobileProductSelectionScreen />{' '}
        </OptionChainProvider>
      ) : (
        <ProductsSelectionScreen />
      );
    case BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW:
      return <OrdersPlacementScreen asset={asset} />;
    default:
      return <AddContracts asset={asset} />;
  }
};

const BasketOrderScreen = ({ product }: BasketOrderScreenProps) => {
  const dispatch = useAppDispatch();

  const currentScreenView = useAppSelector(state =>
    basketOrderCurrentScreenSelector(state)
  );
  const products = useAppSelector(state => productsSelector(state));
  const ordersInBasket: IOrdersObject = useAppSelector(state =>
    basketOrderOrdersSelector(state)
  );
  const mobileActiveAssetSymbol = useAppSelector(basketActiveUnderlyingAssetSelector);

  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  const { isMobile } = useWindowSize();
  const { underlying_asset } = product || {};
  const selectedAssetSymbol = isMobile
    ? mobileActiveAssetSymbol
    : underlying_asset?.symbol;

  // hook to change screen view if margin mode or product changes depending on orders in basket
  useEffect(() => {
    const orders: OrdersObject[] = Object.values(ordersInBasket);
    if (!isMobile) {
      const liveOrdersInbasket = orders.filter(
        order => products[order.product_id] && order.asset === selectedAssetSymbol
      );
      if (liveOrdersInbasket.length === 0) {
        dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.DEFAULT));
      } else if (currentScreenView === BASKET_ORDERS_SCREEN_VIEW.DEFAULT) {
        dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersInBasket, marginMode, product]);

  return (
    <RenderCurrentScreen
      asset={selectedAssetSymbol as string}
      currentScreenView={currentScreenView}
      isMobile={isMobile}
    />
  );
  // if (marginMode === MARGIN_MODE.ISOLATED || marginMode === MARGIN_MODE.CROSS) {
  //   return (
  //     <RenderCurrentScreen
  //       asset={selectedAssetSymbol as string}
  //       currentScreenView={currentScreenView}
  //       isMobile={isMobile}
  //     />
  //   );
  // }

  // if (marginMode === MARGIN_MODE.PORTFOLIO) {
  //   const enabledPortfolios = tradePreferences.enabled_portfolios || {};
  //   const [portfolioAssetEnabled] = Object.keys(enabledPortfolios);

  //   const mobileSpotIndexSymbol = getSpotIndexSymbol(mobileActiveAssetSymbol);
  //   const activeAssetSymbol = isMobile
  //     ? mobileSpotIndexSymbol
  //     : product?.spot_index?.symbol;
  //   const isCurrentAssetPortfolioMarginEnabled =
  //     portfolioAssetEnabled === activeAssetSymbol;
  //   const portfolioAsset = enabledPortfolios?.[portfolioAssetEnabled]?.underlying_asset;
  //   const buttonAssetSymbol =
  //     mobileActiveAssetSymbol === portfolioAsset
  //       ? portfolioAsset
  //       : mobileActiveAssetSymbol;

  //   if (isCurrentAssetPortfolioMarginEnabled) {
  //     return (
  //       <RenderCurrentScreen
  //         asset={selectedAssetSymbol as string}
  //         currentScreenView={currentScreenView}
  //         isMobile={isMobile}
  //       />
  //     );
  //   }
  //   const text = t('basketOrders:changePortfolioMarginText', {
  //     asset: product?.underlying_asset?.symbol,
  //   });
  //   const buttonText = t('basketOrders:changePortfolioMargin', {
  //     asset: isMobile ? buttonAssetSymbol : product?.underlying_asset?.symbol,
  //   });
  //   return (
  //     <EnablePorfolioMarginScreen
  //       text={text}
  //       buttonText={buttonText}
  //       portfolioMarginModeDisabled
  //       asset={portfolioAsset}
  //     />
  //   );
  // }

  // return null;
};

export default BasketOrderScreen;
