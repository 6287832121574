/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PopoverBase } from 'components/popover';
import Popover from 'containers/Popover';
import { isNil } from 'helpers/ramda';
// import { EditNewIcon, AddIcon, CleanIcon } from 'components/customicons';
import { EditNewIcon, CleanIcon, AddIcon } from 'styles/assets/icons';

export const HoldingBracketOrderDesktop = props => {
  const {
    isNew,
    takeProfitTriggerPrice,
    takeProfitLimitPrice,
    stopLossTriggerPrice,
    stopLossLimitPrice,
    stopTrailAmount,
    disableAddBracketOrder,
  } = props;

  const { t } = useTranslation(['trading']);
  return (<>
    <div
      className={classnames('take-profit-button', {
        disable: isNew,
      })}
      data-palette="HoldingBracketOrderDesktop">
      <Popover
        className="tooltip-message-bracket-holdings"
        body={
          <div data-palette="HoldingBracketOrderDesktop">
            <div className="first-row d-flex">
              <div className="col-24">{t('trading:takeProfitOrder')}:</div>
            </div>
            <div className="second-row d-flex">
              <div className="col-12 trigger-price">{t('trading:triggerPrice')}</div>
              <div className="col-12 limit-price">{t('trading:limitPrice')}</div>
            </div>
            <div className="third-row d-flex">
              <div className="col-12 trigger-price">
                {isNil(takeProfitTriggerPrice) ? '-' : takeProfitTriggerPrice}
              </div>
              <div className="col-12 limit-price">
                {isNil(takeProfitLimitPrice) ? '-' : takeProfitLimitPrice}
              </div>
            </div>
          </div>
        }
      >
        {thisProps => <PopoverBase {...thisProps} />}
        <span>TP</span>
      </Popover>
    </div>
    {!isNil(stopTrailAmount) && (
      <div
        className={classnames('action-button', {
          disable: isNew,
        })}
        data-palette="HoldingBracketOrderDesktop">
        <Popover
          className="tooltip-message-bracket-holdings"
          body={
            <div data-palette="HoldingBracketOrderDesktop">
              <div className="first-row d-flex">
                <div className="col-24">{t('trading:trailStopOrder')}:</div>
              </div>
              <div className="second-row d-flex">
                <div className="col-24 trigger-price">{t('trading:trailAmount')}</div>
              </div>

              <div className="third-row d-flex">
                <div className="col-24 trigger-price">
                  {isNil(stopTrailAmount) ? '-' : stopTrailAmount}
                </div>
              </div>
            </div>
          }
        >
          {thisProps => <PopoverBase {...thisProps} />}
          <span>SL</span>
        </Popover>
      </div>
    )}
    {isNil(stopTrailAmount) && (
      <div
        className={classnames('action-button', {
          disable: isNew,
        })}
        data-palette="HoldingBracketOrderDesktop">
        <Popover
          className="tooltip-message-bracket-holdings"
          body={
            <div data-palette="HoldingBracketOrderDesktop">
              <div className="first-row d-flex">
                <div className="col-24">{t('trading:stopLossOrder')}:</div>
              </div>
              <div className="second-row d-flex">
                <div className="col-12 trigger-price">{t('trading:triggerPrice')}</div>
                <div className="col-12 limit-price">{t('trading:limitPrice')}</div>
              </div>

              <div className="third-row d-flex">
                <div className="col-12 trigger-price">
                  {isNil(stopLossTriggerPrice) || stopTrailAmount
                    ? '-'
                    : stopLossTriggerPrice}
                </div>
                <div className="col-12 limit-price">
                  {isNil(stopLossLimitPrice) || stopTrailAmount
                    ? '-'
                    : stopLossLimitPrice}
                </div>{' '}
              </div>
            </div>
          }
        >
          {thisProps => <PopoverBase {...thisProps} />}
          <span>SL</span>
        </Popover>
      </div>
    )}
    {isNew && (
      <div
        data-testid="IfbNjNMzerXSBrStD1_sU"
        aria-hidden="true"
        className={classnames('action-button icon', {
          'add-icon': isNew,
          'disable-add': disableAddBracketOrder,
        })}
        onClick={() => {
          if (!disableAddBracketOrder) {
            props.onClickAction();
          }
        }}
        data-palette="HoldingBracketOrderDesktop">
        <AddIcon fillColor="var(--primaryTheme)" />
      </div>
    )}
    {!isNew && (
      <div data-palette="HoldingBracketOrderDesktop">
        <div
          data-testid="MUGIOgAdpPPruEe0mSaoA"
          aria-hidden="true"
          className={classnames('action-button icon', {
            'add-icon': isNew,
          })}
          onClick={() => {
            props.onClickAction();
          }}
        >
          <EditNewIcon />
        </div>
        <div
          data-testid="Mua3Pc2dbE3zrHbBJ9IKs"
          aria-hidden="true"
          className="action-button icon remove-bracket"
          onClick={() => {
            props.removeBracketOrder();
          }}
        >
          <span>
            <CleanIcon className="clean-icon-svg" />
          </span>
        </div>
      </div>
    )}
  </>);
};
