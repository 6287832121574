/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React from 'react';

import {
  editOrdersFromBasket,
  unsubscribeBasketOrderOrderbook,
} from 'actions/basketOrders';
import {
  DeltaValue,
  GammaValue,
  ThetaValue,
  VeggaValue,
} from 'components/options_chain/helpers';
import {
  GetBuyOrderEmptyRow,
  GetSellOrderEmptyRows,
} from 'components/orderbook/mobileOrderbookOverlay/helper';
import { isFutures, noop } from 'helpers';
import { getSizeInUnderlyingTerms } from 'helpers/formulas';
import { device } from 'helpers/screenSizes';
import subscribe from 'HOC/subscribe';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import {
  basketBuyOrderBookSelector,
  basketOrderOrdersSelector,
  basketSellOrderBookSelector,
  productsSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { CrossIcon } from 'styles/assets/icons';

// @ts-ignore

import styles from './orders.module.scss';

interface Props {
  // orderProductId: number;
  currentOrderbook: { product_id: number; showOrderbook: boolean };
  setcurrentOrderBook: ({ product_id: number, showOrderbook: boolean }) => void;
}

const BasketOrdersOrderbook = subscribe(
  ({ currentOrderbook: { product_id: productId }, setcurrentOrderBook }: Props) => {
    const { t } = useTranslation(['trading', 'basketOrders']);
    const ordersInBasket = useAppSelector(state => basketOrderOrdersSelector(state));
    const products = useAppSelector(state => productsSelector(state));
    const dispatch = useAppDispatch();

    // @ts-ignore
    const buyOrderbook: Array<{
      level: string;
      price: string;
      size: string | number;
    }> = basketBuyOrderBookSelector();

    // @ts-ignore
    const sellOrderbook: Array<{
      level: string;
      price: string;
      size: string | number;
    }> = basketSellOrderBookSelector();

    const product = products[productId];

    const onCloseClick = () => {
      dispatch(unsubscribeBasketOrderOrderbook());
      setcurrentOrderBook({
        product_id: 0,
        showOrderbook: false,
      });
    };

    const onPriceClick = ({ currentTarget }: React.MouseEvent) => {
      const value = currentTarget.textContent;
      if (value) {
        const order = ordersInBasket[productId];
        if (order.order_type === 'limit_order') {
          dispatch(editOrdersFromBasket({ ...order, limit_price: Number(value) }));
        }
      }
    };

    const onSizeClick = ({ currentTarget }: React.MouseEvent) => {
      const value = currentTarget.textContent;
      if (value) {
        const order = ordersInBasket[productId];
        /* @ts-ignore */
        // eslint-disable-next-line no-unsafe-optional-chaining
        const quantityInContract = Number(value) / product?.contract_value;
        dispatch(
          /* @ts-ignore */
          editOrdersFromBasket({
            ...order,
            size: parseInt(String(quantityInContract), 10),
          })
        );
      }
    };

    const sizeTitle = `${t('trading:size')} (${product?.underlying_asset?.symbol})`;

    return (
      (<div
        className={styles['basket-orderbook-container']}
        data-palette="BasketOrdersOrderbook">
        <div className={styles['orderbook-container']}>
          <MediaQuery query={device.up.md}>
            <div className={styles['title-header']}>{t('basketOrders:orderbook')}</div>
            <div
              data-testid="basket-orderbook-cross-icon"
              className={styles.close}
              onClick={onCloseClick}
              role="button"
              onKeyPress={noop}
              tabIndex={0}>
              <CrossIcon />
            </div>
          </MediaQuery>
          <div className={styles.header}>
            <span className={styles['header-span']}>{sizeTitle}</span>
            <span className={styles['price-header']}>{t('trading:price')}</span>
            <span className={styles['header-sell-span']}>{sizeTitle}</span>
          </div>
          <div className={styles.table}>
            <div className={styles['column-one']}>
              {buyOrderbook.map((level, index) => {
                if (index < 5) {
                  const sizeInUnderlying = getSizeInUnderlyingTerms({
                    contract_value: product?.contract_value,
                    size: level.size,
                    contract_type: product?.contract_type,
                    minimum_precision: product?.underlying_asset.minimum_precision,
                  });
                  return (
                    (<div
                      key={`${level?.price}buy`}
                      className={styles['buy-data']}
                      data-palette="BasketOrdersOrderbook">
                      <span
                        data-testid="buy-size"
                        className={styles['buy-size']}
                        onClick={onSizeClick}
                        role="button"
                        onKeyPress={noop}
                        tabIndex={0}>
                        {sizeInUnderlying}
                      </span>
                      <span
                        data-testid="buy-price"
                        className={styles['buy-price']}
                        onClick={onPriceClick}
                        role="button"
                        onKeyPress={noop}
                        tabIndex={0}>
                        {level.price}
                      </span>
                    </div>)
                  );
                }
                return null;
              })}
              <GetBuyOrderEmptyRow
                buyLength={buyOrderbook.length}
                rowClassName={styles['buy-data']}
              />
            </div>
            <div className={styles['column-two']}>
              {sellOrderbook.map((level, index) => {
                if (index < 5) {
                  const sizeInUnderlying = getSizeInUnderlyingTerms({
                    contract_value: product?.contract_value,
                    size: level.size,
                    contract_type: product?.contract_type,
                    minimum_precision: product?.underlying_asset.minimum_precision,
                  });
                  return (
                    (<div
                      key={`${level.price}sell`}
                      className={styles['buy-data']}
                      data-palette="BasketOrdersOrderbook">
                      <span
                        data-testid="sell-price"
                        className={styles['sell-price']}
                        onClick={onPriceClick}
                        role="button"
                        onKeyPress={noop}
                        tabIndex={0}>
                        {level.price}
                      </span>
                      <span
                        data-testid="sell-size"
                        onClick={onSizeClick}
                        className={styles['sell-size']}
                        role="button"
                        onKeyPress={noop}
                        tabIndex={0}>
                        {sizeInUnderlying}
                      </span>
                    </div>)
                  );
                }
                return null;
              })}
              <GetSellOrderEmptyRows
                sellLength={sellOrderbook.length}
                rowClassName={styles['buy-data']}
              />
            </div>
          </div>
        </div>
        {/* Greeks */}
        <div className={styles['greeks-container']}>
          <MediaQuery query={device.up.md}>
            <div className={styles['greeks-title']}>Greeks</div>
          </MediaQuery>
          <div className={styles['greeks-table']}>
            <div className={styles['greeks-column']}>
              <div className={styles['greeks-label']}>Delta</div>
              <div className={styles['greek-value']}>
                {/* Delta is always 1 for futures and doesnt comes from backend */}
                {isFutures(product?.contract_type) ? 1 : <DeltaValue {...product} />}
              </div>
            </div>
            <div className={styles['greeks-column']}>
              <div className={styles['greeks-label']}>Gamma</div>
              <div className={styles['greek-value']}>
                <GammaValue {...product} />
              </div>
            </div>
            <div className={styles['greeks-column']}>
              <div className={styles['greeks-label']}>Theta</div>
              <div className={styles['greek-value']}>
                <ThetaValue {...product} />
              </div>
            </div>
            <div className={styles['greeks-column']}>
              <div className={styles['greeks-label']}>Vega</div>
              <div className={styles['greek-value']}>
                <VeggaValue {...product} />
              </div>
            </div>
          </div>
        </div>
      </div>)
    );
  }
);

export default BasketOrdersOrderbook;
