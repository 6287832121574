import styled from 'styled-components';

const Button = styled.button`
  background: ${props => props.theme.primaryTheme};
  color: ${props => props.theme.primaryText};
  border: 0px;
  font-size: 14px !important;
  padding: 6px;
  margin: 0px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  height: 40px;
  width: 100%;
  &.mobile-close-button {
    color: #ffffff;
  }
  :focus {
    outline: none;
  }
  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const HoldingsActionButton = styled(Button)`
  margin: 0;
  padding: 5px 10px;
  height: 20px;
  border: 1px solid ${props => props.theme.primaryTheme};
  :hover {
    color: ${props => props.theme.primaryButtonHover};
    background: transparent;
  }
`;

export const BuyButton = styled(Button)`
  background: ${props => props.theme.positive};
`;

export const SellButton = styled(Button)`
  background: ${props => props.theme.negative};
`;

export default Button;
