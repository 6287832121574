/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import cx from 'classnames';

import { isEmpty } from 'helpers/utils';

import classes from './space.module.scss';

const toElementsArray = (list: ReactNode) => {
  if (!list) {
    return [];
  }
  return Array.isArray(list) ? list : [list];
};

const verticalClasses: {
  [key: string]: string;
} = {
  xxs: 'v-xxs',
  xs: 'v-xs',
  sm: 'v-sm',
  md: 'v-md',
  lg: 'v-lg',
  xl: 'v-xl',
  xxl: 'v-xxl',
};
const horizontalClasses: {
  [key: string]: string;
} = {
  xxs: 'h-xxs',
  xs: 'h-xs',
  sm: 'h-sm',
  md: 'h-md',
  lg: 'h-lg',
  xl: 'h-xl',
  xxl: 'h-xxl',
};

const getClassName = (dir: string, size: string): string =>
  classes[dir === 'horizontal' ? horizontalClasses[size] : verticalClasses[size]];

const isLastChild = (
  childNodes: ReactNode[],
  child: ReactNode,
  itemsLength: number
): boolean => childNodes.lastIndexOf(child) === itemsLength - 1;

interface SpaceProps {
  children: ReactNode;
  className?: string | undefined;
  itemClassName?: string | undefined;
  direction?: 'horizontal' | 'vertical';
  wrap?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

/**
 * @summary
 * Component sets spacing between child components, horizontal or vertical
 *
 * @example
 * ```tsx
 *    <Space direction="vertical" size="md">
 *      <h3>Component 1</h3>
 *      <h3>Component 2</h3>
 *    </Space>
 * ```
 */
const Space = ({
  direction = 'horizontal',
  className,
  itemClassName,
  size = 'sm',
  wrap = false,
  children,
}: SpaceProps) => {
  const childNodes = toElementsArray(children);
  const itemsLength = childNodes.length;
  const isHorizontal = direction === 'horizontal';
  const isVertical = direction === 'vertical';

  if (itemsLength === 0) {
    return null;
  }

  return (
    (<div
      className={cx(classes.space, {
        'flex-row d-flex': isHorizontal,
        'flex-column d-flex': isVertical,
        'flex-wrap': wrap,
        [`${className}`]: className,
      })}
      data-palette="Space">
      {(childNodes || []).map(
        (child, i) =>
          !isEmpty(child) && (
            <div
              className={cx(getClassName(direction, size), {
                [`${classes['last-h']}`]:
                  isLastChild(childNodes, child, itemsLength) && isHorizontal,
                [`${classes['last-v']}`]:
                  (isLastChild(childNodes, child, itemsLength) && isVertical) ||
                  (itemsLength === 1 && isVertical),
                [`${classes.emptyChild}`]: isEmpty(child),
                [`${itemClassName}`]: itemClassName,
              })}
              key={i}
              item-index={childNodes.lastIndexOf(child)}
              data-palette="Space">
              {child}
            </div>
          )
      )}
    </div>)
  );
};

export type { SpaceProps };
export default Space;
