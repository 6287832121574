import { useRef, ReactNode } from 'react';

import { createPortal } from 'react-dom';

type ModalProps = {
  children: ReactNode;
};

const PortalToBody = ({ children }: ModalProps) => {
  // create div element only once using ref
  const elRef = useRef<HTMLBodyElement | null>(null);
  if (!elRef.current) elRef.current = document.getElementsByTagName('body')[0];

  return createPortal(children, elRef.current);
};

export default PortalToBody;
