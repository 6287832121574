import BASKET_ORDERS, {
  ADD_PRODUCT_TO_BASKET,
  CHANGE_BASKET_ORDERS_SCREEN,
  DELETE_PRODUCT_FROM_BASKET,
  TOGGLE_BASKET_ORDERS_VIEW,
  EDIT_ORDER_FROM_BASKET,
  DELETE_ALL_ORDERS_FROM_BASKET,
  SUBSCRIBE_BASKET_ORDER_ORDERBOOK,
  UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK,
  SHOW_BASKET_ERROR,
  DELETE_BASKET_RESULT,
  CHANGE_BASKET_ORDERS_CONTRACT_TYPE,
  CHANGE_BASKET_ORDERS_UNDERLYING_ASSET,
  TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN,
} from 'actionTypes/basketorders';
import { BASKET_ORDERS_API } from 'constants/api';
import createAction from 'helpers/createAction';
import { OrdersObject } from 'types/basketOrders';

import { authAction } from './user';

const toggleBasketOrdersView = createAction(TOGGLE_BASKET_ORDERS_VIEW);

const changeBasketOrdersScreen = createAction(CHANGE_BASKET_ORDERS_SCREEN);

export const changeBasketOrderContractType = createAction(
  CHANGE_BASKET_ORDERS_CONTRACT_TYPE
);

const addProductToBasket = createAction(ADD_PRODUCT_TO_BASKET);
const deleteProductFromBasket = createAction(DELETE_PRODUCT_FROM_BASKET);
const editOrdersFromBasket = createAction(EDIT_ORDER_FROM_BASKET);
const showBasketError = createAction(SHOW_BASKET_ERROR);
const changeBasketUnderlyingAsset = createAction(CHANGE_BASKET_ORDERS_UNDERLYING_ASSET);
const toggleMobileBasketOrderAssetDropdown = createAction(
  TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN
);
const deleteAllOrdersFromBasket = createAction(DELETE_ALL_ORDERS_FROM_BASKET);
const deleteBasketResult = createAction(DELETE_BASKET_RESULT);

const subscribeBasketOrderOrderbook = createAction(SUBSCRIBE_BASKET_ORDER_ORDERBOOK);
const unsubscribeBasketOrderOrderbook = createAction(UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK);

const estimateBasketMargin = (payload: {
  index_symbol: string;
  orders: OrdersObject[];
}) => {
  return authAction({
    types: BASKET_ORDERS.ESTIMATE_MARGIN,
    promise: ({ request }) =>
      request.post(BASKET_ORDERS_API.ESTIMATE_MARGIN, {
        data: payload,
      }),
  });
};

const placeBasketOrder = (
  payload: {
    index_symbol: string;
    orders: OrdersObject[];
  },
  mixpanelData = {}
) => {
  return authAction({
    types: BASKET_ORDERS.PLACE_ORDER,
    mixpanelPayload: mixpanelData,
    promise: ({ request }) =>
      request.post(BASKET_ORDERS_API.PLACE_ORDER, {
        data: payload,
      }),
  });
};

export {
  toggleBasketOrdersView,
  changeBasketOrdersScreen,
  addProductToBasket,
  deleteProductFromBasket,
  editOrdersFromBasket,
  showBasketError,
  deleteAllOrdersFromBasket,
  deleteBasketResult,
  subscribeBasketOrderOrderbook,
  unsubscribeBasketOrderOrderbook,
  estimateBasketMargin,
  placeBasketOrder,
  changeBasketUnderlyingAsset,
  toggleMobileBasketOrderAssetDropdown,
};
