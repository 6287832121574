/* * NOTE:
As we are no longer using "react-snap",
using `loadable` component instead of `prerenderedLazy`
// import React from 'react';
// import RouteLoader, { LazyLoad } from 'components/route_loader/route_loader';
// import ModuleLoader from 'components/route_loader/module_loader';
// import { CenterLoader } from 'components/loader';
// import prerenderedLazy from './PrerenderedLazy';

// Using prerenderedLazy to use pre-rendered html until dynamic js is ready to be injected
// This is done to improve SEO as pages do not exist on SPAs and are injected dynamically
// "react-snap" combined with "react-prerendered-component", creates snapshots of pages and adds separate HTMLs so that google is able to crawl the website
 */

import loadable from '@loadable/component';

export const AsyncTrade = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncTrade", webpackPrefetch: true */ 'containers/trade')
  // )
);
export const AsyncLogin = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncLogin" */ 'components/Auth/Login')
  // )
);
export const AsyncLinkAccount = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "LinkAccount" */ 'components/Auth/LinkAccount')
  // )
);
export const AsyncDisable2FA = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncDisable2FA" */ 'components/Auth/RequestDisable2FA')
  // )
);
export const AsyncUniversalSearchMobile = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncUniversalSearchMobile" */ 'components/primaryHeader/partials/universalSearch'
    )
  // )
);
export const AsyncSignup = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncSignup" */ 'components/Auth/Signup')
  // )
);

export const AsyncAccount = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncAccount" */ 'components/account/account')
  // )
);
export const AsyncTradingView = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncTradingView", webpackPrefetch: true */ 'components/chart/TradingViewContainer'
    )
  // )
);
export const AsyncTradingViewChartNewTab = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncTradingViewChartNewTab", webpackPrefetch: true */ 'components/chart/TradingChartContainerNewTab'
    )
  // )
);
export const AsyncTradingViewChart = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncTradingView" */ 'components/chart/charts_new')
  // )
);
export const AsyncOffers = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncOffers" */ 'containers/offers')
  // )
);
export const AsyncOptionsLeaderBoard = loadable(() =>
  import(
    /* webpackChunkName: "AsyncOptionsLeaderBoard" */ 'components/OptionsLeaderBoard/OptionLeaderBoard'
  )
);
export const AsyncWithdrawalConfirmation = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncWithdrawalConfirmation" */ 'components/withdrawal_confirmation'
    )
  // )
);
export const AsyncReferences = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncReferences" */ 'components/references')
  // )
);
export const AsyncApi = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncApi" */ 'components/api')
  // )
);
export const AsyncSignupVerify = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncSignupVerify" */ 'components/Auth/SignupVerification'
    )
  // )
);
export const AsyncForgotPassword = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncForgotPassword" */ 'components/Auth/ForgotPassword')
  // )
);
export const AsyncResetPassword = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncResetPassword" */ 'components/Auth/ResetPassword')
  // )
);

export const AsyncReferral = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncReferral" */ 'containers/referral')
  // )
);
export const AsyncOptions = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncOptions" */ 'components/easyOptions/EasyOptions')
  // )
);
export const AsyncMarkets = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncMarkets" */ 'containers/markets')
  // )
);
export const AsyncPortfolio = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncPortfolio" */ 'components/portfolio')
  // )
);
export const AsyncTicker = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncTicker" */ 'components/ticker')
  // )
);
export const AsyncAppDrawer = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncAppDrawer" */ 'components/appDrawer')
  // )
);
export const AsyncTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncTradingCompetition" */ 'components/tradingCompetition'
    )
  // )
);
export const AsyncLendTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncLendTradingCompetition" */ 'components/lendTradingCompetition'
    )
  // )
);
export const AsyncXEMTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncXEMTradingCompetition" */ 'components/xem-trading-competition'
    )
  // )
);
export const AsyncTamilTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncTamilTradingCompetition" */ 'components/tamil-trading-competition'
    )
  // )
);
export const AsyncExpiredFuturesPage = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncExpiredFuturesPage" */ 'containers/expiredProducts')
  // )
);
export const AsyncTomoTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncTomoTradingCompetition" */ 'components/tomoTradingCompetition'
    )
  // )
);
export const AsyncKNCTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncKNCTradingCompetition" */ 'components/KNCTradingCompetition'
    )
  // )
);
export const AsyncENJTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncENJTradingCompetition" */ 'components/ENJTradingCompetition'
    )
  // )
);
export const AsyncDetoTradingCompetition = loadable(
  () =>
    // componentLoader(() =>
    import(
      /* webpackChunkName: "AsyncDetoTradingCompetition" */ 'components/deto-spot-competition'
    )
  // )
);
export const AsyncBellAlerts = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncBellAlerts" */ 'components/bellAlerts')
  // )
);

export const AsyncFuturesTradingCompetition = loadable(() =>
  import('components/Competition/FuturesTradingCompetition')
);

export const AsyncOptionsAnalyticsDashboard = loadable(() =>
  import(
    /* webpackChunkName: 'OptionsAnalyticsDashboard' */ 'containers/OptionsAnalyticsDashboard'
  )
);

export const AsyncUpdatePassword = loadable(
  () =>
    // componentLoader(() =>
    import(/* webpackChunkName: "AsyncUpdatePassword" */ 'components/Auth/UpdatePassword')
  // )
);

export const IconsList = loadable(() =>
  import(/* webpackChunkName: "IconsList" */ 'components/IconList/IconList')
);

export const AsyncOptionsLeaderBoardPage = loadable(() =>
  import(
    /* webpackChunkName: "AsyncOptionLeaderBoardPage" */ 'components/OptionsLeaderBoard/OptionLeaderBoardPage'
  )
);

export const AsyncHomePage = loadable(() =>
  import(/* webpackChunkName: "AsyncHomePage" */ 'components/Homepage')
);

export const AsyncTradeHistoryPage = loadable(() =>
  import(/* webpackChunkName: "AsyncTradeHistoryPage" */ 'components/TradeHistory')
);

export const AsyncMobileRiskViewAll = loadable(() =>
  import(
    /* webpackChunkName: "AsyncMobileRiskViewAll" */ 'components/MobileHolding/components/MobileRiskViewAll'
  )
);

export const AsyncExpiredProductCharts = loadable(() =>
  import(
    /* webpackChunkName: "AsyncExpiredProductCharts" */ 'components/expiredFutures/expiredProductChart'
  )
);

// export const AsyncTrade = RouteLoader({
//   route: LazyLoad(() =>
//     import(/* webpackChunkName: "Trade" */ 'containers/trade')
//   ),
//   loading: () => (
//     <div style={{ flex: 1 }}>
//       <CenterLoader />
//     </div>
//   ),
// });

// export const AsyncLogin = RouteLoader(
//   LazyLoad(() => import(/* webpackChunkName: "Login" */ 'containers/login'))
// );

// export const AsyncSignup = RouteLoader(
//   LazyLoad(() => import(/* webpackChunkName: "Signup" */ 'containers/signup'))
// );

// export const AsyncContracts = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "Contracts" */ 'containers/contracts')
//   )
// );

// export const AsyncAccount = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "Account" */ 'containers/account/account')
//   )
// );

// export const AsyncTradingView = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "TradingView" */ 'components/chart/tradingView')
//   )
// );

// export const AsyncOffers = RouteLoader(
//   LazyLoad(() => import('containers/offers'))
// );

// export const AsyncWithdrawalConfirmation = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "WithdrawalConfirmation" */ 'components/withdrawal_confirmation'
//     )
//   )
// );

// export const AsyncReferences = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "References" */ 'components/references')
//   )
// );

// export const AsyncApi = RouteLoader(
//   LazyLoad(() => import(/* webpackChunkName: "Api" */ 'components/api'))
// );

// export const AsyncSignupVerify = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "SignupVerify" */ 'containers/signup_verify')
//   )
// );

// export const AsyncForgotPassword = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "ForgotPassword" */ 'components/forgot_password'
//     )
//   )
// );

// export const AsyncResetPassword = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "ResetPassword" */ 'components/reset_password')
//   )
// );

// export const AsyncReferral = RouteLoader(
//   LazyLoad(() =>
//     import(/* webpackChunkName: "Referral" */ 'containers/referral')
//   )
// );

// export const AsyncOptions = RouteLoader(
//   LazyLoad(() => import(/* webpackChunkName: "Options" */ 'containers/options'))
// );

// export const AsyncMarkets = RouteLoader({
//   route: LazyLoad(() =>
//     import(/* webpackChunkName: "Markets" */ 'containers/markets')
//   ),
//   loading: () => (
//     <div style={{ flex: 1 }}>
//       <CenterLoader />
//     </div>
//   ),
// });

// export const AsyncSignupSuccessfull = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "SignupSuccessful" */ 'containers/signup_successful'
//     )
//   )
// );

// export const AsyncTicker = ModuleLoader(
//   LazyLoad(() =>
//     import(/*webpackChunkName : "AppTicker" */ 'components/ticker')
//   )
// );

// export const AsyncAppDrawer = ModuleLoader(
//   LazyLoad(() =>
//     import(/*webpackChunkName : "AppDrawer" */ 'components/appDrawer')
//   )
// );

// export const AsyncTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "crypto_trading_league" */ 'components/tradingCompetition'
//     )
//   )
// );

// export const AsyncLendTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "lend_trading_competition" */ 'components/lendTradingCompetition'
//     )
//   )
// );

// export const AsyncXEMTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "lend_trading_competition" */ 'components/xem-trading-competition'
//     )
//   )
// );

// export const AsyncTamilTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "lend_trading_competition" */ 'components/tamil-trading-competition'
//     )
//   )
// );

// export const AsyncExpiredFuturesPage = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "expired_futures_page" */ 'containers/expiredProducts'
//     )
//   )
// );

// export const AsyncTomoTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "tomo_trading_competition" */ 'components/tomoTradingCompetition'
//     )
//   )
// );

// export const AsyncKNCTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "knc_trading_competition" */ 'components/KNCTradingCompetition'
//     )
//   )
// );

// export const AsyncENJTradingCompetition = RouteLoader(
//   LazyLoad(() =>
//     import(
//       /* webpackChunkName: "enj_trading_competition" */ 'components/ENJTradingCompetition'
//     )
//   )
// );

export const AppFooter = loadable(() =>
  import(
    /* webpackChunkName: "AppFooter" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/appFooter'
  )
);

export const ConnectionStatusStrip = loadable(() =>
  import(
    /* webpackChunkName: "ConnectionStatusStrip" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/ConnectionStatusStrip'
  )
);

export const DeltaIndiaStrip = loadable(() =>
  import(
    /* webpackChunkName: "DeltaIndiaStrip" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/DeltaIndiaStrip/DeltaIndiaStrip'
  )
);

export const PrimaryHeader = loadable(() =>
  import(
    /* webpackChunkName: "PrimaryHeader" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/primaryHeader'
  )
);

export const PrimaryHeaderMobile = loadable(() =>
  import(
    /* webpackChunkName: "PrimaryHeaderMobile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/primaryHeaderMobile'
  )
);

export const MobileSideMenu = loadable(() =>
  import(
    /* webpackChunkName: "MobileSideMenu" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/primaryHeaderMobile/partials/mobileSideMenu'
  )
);

export const MaintenanceBanner = loadable(() =>
  import(
    /* webpackChunkName: "MaintenanceBanner" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/maintenance/notification'
  )
);

export const MobileQuickLinks = loadable(() =>
  import(
    /* webpackChunkName: "MobileQuickLinks" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/markets/partials/quickLinks'
  )
);

export const NotFound = loadable(() =>
  import(
    /* webpackChunkName: "NotFound" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/notFound'
  )
);

export const KycModal = loadable(() =>
  import(
    /* webpackChunkName: "KycModal" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/kycModal/kycModal'
  )
);

export const KycRefreshDeadlineStrip = loadable(() =>
  import(
    /* webpackChunkName: "KycRefreshDeadlineStrip" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/identity_verification/kycRefreshDeadlineStrip'
  )
);

export const ResetPasswordPopup = loadable(() =>
  import(
    /* webpackChunkName: "ResetPasswordPopup" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */
    'components/resetPasswordPopup'
  )
);

export const KycReduceOnlyModal = loadable(() =>
  import(
    /* webpackChunkName: "KycReduceOnlyModal" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/KycReduceOnlyModal'
  )
);

export const KycRefreshModal = loadable(() =>
  import(
    /* webpackChunkName: "KycRefreshModal" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/kycRefreshModal'
  )
);

export const CurrencyConverter = loadable(() =>
  import(
    /* webpackChunkName: "CurrencyConverter" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'containers/currency_converter'
  )
);

export const Maintenance = loadable(() =>
  import(
    /* webpackChunkName: "Maintenance " */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/maintenance'
  )
);

export const AccountHeader = loadable(() =>
  import(
    /* webpackChunkName: "AccountHeader " */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/Header/account_header'
  )
);

export const TradingViewPrimaryHeader = loadable(() =>
  import(
    /* webpackChunkName: "TradingViewPrimaryHeader " */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/primaryHeader/partials/tradingviewHeader'
  )
);

export const LiquidationRiskBanner = loadable(() =>
  import(
    /* webpackChunkName: "LiquidationRiskBanner " */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ 'components/portfolio/liquidation_risk_banner'
  )
);

export const KycShareConsentPopup = loadable(() =>
  import(
    /* webpackChunkName: "KycShareConsentPopup" */ /* webpackMode: "lazy" */ 'components/KycShareConcent/KycShareConcent'
  )
);
