import styled from 'styled-components';

// import PropTypes from 'PropTypes';
import { NoFavIcon } from 'styles/assets/icons';

const Wrapper = styled.div`
  background-color: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.secondaryText};
  height: 300px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  pointer-events: unset;

  .text {
    margin-top: 10px;
  }
`;

const NoContracts = ({ searchTerm }) => {
  return (
    (<Wrapper className="markets-no-data">
      <div className="icon">
        <NoFavIcon />
      </div>
      {searchTerm && <div className="text" data-palette="NoContracts">{searchTerm} </div>}
    </Wrapper>)
  );
};

export default NoContracts;
