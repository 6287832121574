/* eslint-disable import/no-unresolved */

// import { filter } from 'ramda';

import DataTable from 'components/Header/partials/headerDropdown/OptionsChain/DataTable';
import OptionsType from 'components/Header/partials/headerDropdown/OptionsChain/OptionsType';
import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import ProductsList from 'components/Header/partials/headerDropdown/ProductsList';
import TableHeaders from 'components/Header/partials/headerDropdown/TableHeaders';
import ListDropdown from 'components/markets/partials/OptionsChain/ListDropdown';
import { sortDates } from 'components/options_chain/OptionsChartDropdown/utils';
import { ASSET_SYMBOL } from 'constants/constants';
// import { TIME_FORMATS } from 'constants/timeformats';
import { isEmpty, isFutures } from 'helpers';
// import { dateFormat } from 'helpers/day';
import { filter } from 'helpers/ramda';
import {
  basketActiveContractTypeSelector,
  basketActiveUnderlyingAssetSelector,
  basketOrderOrdersSelector,
  OptionChainsProductList,
  productsSelector,
  // selectedProductSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
// import { Product } from 'types/IProducts';
import { ProductById } from 'types/ITrade';
import { Box } from 'UIKit';

import styles from './productSelectionScreen.module.scss';

const MobileProductSelectionScreen = () => {
  const { expiryTimesDropDownList } = useAppSelector(OptionChainsProductList);
  // const selectedProduct: Product | null = useAppSelector(selectedProductSelector);
  const activeContractType = useAppSelector(basketActiveContractTypeSelector);
  const products: ProductById = useAppSelector(productsSelector);
  const orders = useAppSelector(basketOrderOrdersSelector);
  const activeAssetSymbol = useAppSelector(basketActiveUnderlyingAssetSelector);
  const { usableExpiryDate, handleExpiryDateChange } = useOptionsChainData();

  const filteredOrders = Object.values(orders).filter(
    order => order?.asset === activeAssetSymbol && order?.side
  );

  // const isSelectedProductOptionsChain =
  //   selectedProduct &&
  //   (selectedProduct.contract_type === 'call_options' ||
  //     selectedProduct.contract_type === 'put_options');

  const futuresData = filter(product => isFutures(product?.contract_type), products);
  const activeAssetFuturesData = filter(
    product =>
      product?.underlying_asset?.symbol === activeAssetSymbol &&
      product?.trading_status === 'operational' &&
      product?.settling_asset?.symbol === ASSET_SYMBOL,
    futuresData
  );
  const futuresList = Object.values(activeAssetFuturesData);

  // const selectedAssetExpiryList =
  //   expiryTimesDropDownList[activeAssetSymbol || underlyingAssetSymbolList[0]] || [];

  // const [selectedExpiryDate, setSelectedExpiryDate] = useState(
  //   isSelectedProductOptionsChain
  //     ? dateFormat(selectedProduct?.settlement_time, TIME_FORMATS.DD_MMM_YYYY)
  //     : selectedAssetExpiryList[0] || ''
  // );

  // const handleExpiryDateChange = useCallback(expiryDate => {
  //   setSelectedExpiryDate(expiryDate.value);
  // }, []);

  return (
    (<Box className={styles.mobileProductsScreen}>
      {activeContractType.toLowerCase() === 'options' ? (
        <>
          <div
            className={styles.optionsFilterContainer}
            data-palette="MobileProductSelectionScreen">
            <div className={styles.optionsAssets}>
              <ListDropdown
                onChange={handleExpiryDateChange}
                value={usableExpiryDate}
                items={sortDates(expiryTimesDropDownList[activeAssetSymbol])}
                data-testid="mobile-basket-options-expiry-dropdown"
                className="basketOrderDropdown"
              />
            </div>
            <div className={styles.optionsAssets}>
              <OptionsType
                data-testid="mobile-basket-options-type"
                className="basketOrdersOptionsType"
              />
            </div>
          </div>
          <DataTable
            onClose={() => {}}
            extraClassName={
              !isEmpty(filteredOrders)
                ? 'mobile-basket-orders-options-table'
                : 'mobile-basket-orders-options-table-full-screen'
            }
          />
        </>
      ) : (
        <div
          className={styles.futuresTable}
          data-palette="MobileProductSelectionScreen">
          <TableHeaders
            className="basket-orders-futures-header"
            noData={!futuresList.length}
            showFunds={false}
            sortBy={{}}
          />
          <ProductsList
            onProductRowClick={() => {}}
            products={futuresList}
            favorites={[]}
            showFavIcon={false}
            showFunds={false}
            handleFavClick={() => {}}
            rowClassName="basket-orders-futures-row"
          />
        </div>
      )}
    </Box>)
  );
};

export default MobileProductSelectionScreen;
