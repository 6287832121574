import { values } from 'helpers/ramda';

const decorateBalance = balances =>
  values(balances).map(balanceObj => {
    const {
      asset,
      position_margin,
      order_margin,
      available_balance,
      trading_fee_credit,
      balance,
      commission,
      interest_credit,
      unvested_amount,
    } = balanceObj;

    return {
      id: asset.symbol,
      asset_id: asset.id,
      coin: asset.symbol,
      balance,
      position_margin,
      order_margin,
      available_balance,
      trading_fee_credit,
      commission,
      asset,
      interest_credit,
      unvested_amount,
    };
  });

export default decorateBalance;
