/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React from 'react';

import { LastPriceChangeValue, LastPriceValue } from 'components/markets/helpers';
import { STRIKE_PRICE_TICK_SIZE } from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import { dateFormat, getDateTime } from 'helpers/day';
import { round_by_tick_size } from 'helpers/formulas';
import { comparator, getFilteredDataForOptionChains } from 'helpers/utils';
import i18n from 'i18n/config';
import { lastPriceChangeSelector, lastPriceSelector } from 'selectors/tickerSelectors';
import { SortArrow } from 'styles/assets/icons';

import styles from './index.module.scss';

const sortChange = (symbol1, symbol2) => {
  const val1 = lastPriceChangeSelector({ symbol: symbol1 });
  const val2 = lastPriceChangeSelector({ symbol: symbol2 });
  return comparator(val1, val2);
};

const sortPrice = (prod1, prod2) => {
  const val1 = lastPriceSelector({
    symbol: prod1.symbol,
    tick_size: prod1.tick_size,
  });
  const val2 = lastPriceSelector({
    symbol: prod2.symbol,
    tick_size: prod2.tick_size,
  });
  return comparator(val1, val2);
};

const sortExpiryDate = (val1, val2) => {
  return new Date(val1) - new Date(val2);
};

export const formatDataInTableFormat = (data, searchVal = '') => {
  const result = [];
  data.forEach(({ putOptions, callOptions, ...d }, id) => {
    if (putOptions || callOptions) {
      if (
        putOptions &&
        (putOptions.symbol.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.expiryDate.toLowerCase().includes(searchVal.toLowerCase()) ||
          'put'.includes(searchVal.toLowerCase()))
      ) {
        result.push({
          ...d,
          index: `p-${id}`,
          type: `P-${putOptions.underlying_asset.symbol}`,
          product: putOptions,
          symbol: putOptions.symbol,
        });
      }
      if (
        callOptions &&
        (callOptions.symbol.toLowerCase().includes(searchVal.toLowerCase()) ||
          d.expiryDate.toLowerCase().includes(searchVal.toLowerCase()) ||
          'call'.includes(searchVal.toLowerCase()))
      ) {
        result.push({
          ...d,
          index: `c-${id}`,
          type: `C-${callOptions.underlying_asset.symbol}`,
          product: callOptions,
          symbol: callOptions.symbol,
        });
      }
    }
  });
  return result;
};

export const getTableData = (tableData, selectedExpiryDate, selectedAsset, searchVal) => {
  const data = getFilteredDataForOptionChains(
    tableData,
    selectedExpiryDate === 'All Expiries' ? null : selectedExpiryDate,
    selectedAsset === 'All Assets' ? null : selectedAsset
  );
  return formatDataInTableFormat(data, searchVal);
};

export const getTableColumns = () => {
  return [
    {
      id: 'Type',
      headerClassName: `text-start ${styles.typeHeader}`,
      Header: (
        <span data-palette="getTableColumns">
          <span className="me-1">{i18n.t('trading:optionChainDropdownCols.type')}</span>
          <SortArrow className="dropdown-icon" />
        </span>
      ),
      accessor: 'type',
      sortable: true,
      resizable: false,
      maxWidth: 80,
      className: 'flex-set align-items-center type-col',
    },
    {
      id: 'Strike',
      headerClassName: styles.popoverTableHeader,
      Header: (
        <span data-palette="getTableColumns">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.strikes')}
          </span>
          <SortArrow className="dropdown-icon" />
        </span>
      ),
      accessor: 'strike_price',
      Cell: props => {
        const asset = props.original.product.underlying_asset.symbol;
        return `${round_by_tick_size(
          props.original.strike_price,
          STRIKE_PRICE_TICK_SIZE[asset]
        )}`;
      },
      sortMethod: (a, b) => {
        return Number(a) - Number(b);
      },
      sortable: true,
      resizable: false,
      maxWidth: 80,
      className: 'flex--content-end flex-set align-items-center popoverTableCol',
    },
    {
      id: 'expiry_date',
      headerClassName: styles.popoverTableHeader,
      Header: (
        <span data-palette="getTableColumns">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.expiryDate')}
          </span>
          <SortArrow className="dropdown-icon" />
        </span>
      ),
      accessor: 'expiryDate',
      Cell: props => {
        return `${dateFormat(
          getDateTime(props.original.expiryDate),
          TIME_FORMATS.DD_MMM
        )}`;
      },
      sortable: true,
      resizable: false,
      // maxWidth: 90,
      className: 'flex--content-end flex-set align-items-center popoverTableCol',
      sortMethod: sortExpiryDate,
    },
    {
      id: 'Last Price',
      headerClassName: styles.popoverTableHeader,
      Header: (
        <span data-palette="getTableColumns">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.lastPrice')}
          </span>
          <SortArrow className="dropdown-icon" />
        </span>
      ),
      Cell: props => {
        return <LastPriceValue product={props.original.product} className="faded-text" />;
      },
      sortMethod: (a, b) => {
        return sortPrice(JSON.parse(a), JSON.parse(b));
      },
      accessor: d => {
        return JSON.stringify({
          symbol: d.product.symbol,
          tick_size: d.product.tick_size,
        });
      },
      sortable: true,
      resizable: false,
      // maxWidth: 80,
      className: 'flex--content-end flex-set align-items-center popoverTableCol',
    },
    {
      id: '24h Change',
      headerClassName: styles.changeHeader,
      Header: (
        <span data-palette="getTableColumns">
          <span className="me-1">{i18n.t('trading:optionChainDropdownCols.change')}</span>
          <SortArrow className="dropdown-icon" />
        </span>
      ),
      Cell: props => {
        return (
          <LastPriceChangeValue
            styles={styles}
            product={props.original.product}
            extraClass={styles.changeBox}
          />
        );
      },
      sortMethod: (a, b) => {
        return sortChange(a, b);
      },
      accessor: d => `${d.product.symbol}`,
      sortable: true,
      resizable: false,
      className: 'flex--content-end flex-set align-items-center change-col',
    },
  ];
};

export const sortDates = dates => {
  if (Array.isArray(dates)) {
    const result = [...dates];
    result.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    return result;
  }
  return [];
};
