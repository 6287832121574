import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Render from 'components/render';
import StripPortal, { STRIPS_ORDER } from 'components/StripPortal';
import { SINGAPORE_TRADING_REVOKED_DATE } from 'constants/constants';
import { noop } from 'helpers';
import useKYC from 'hooks/useKYC';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import { CloseIcon, SmallRectangle } from 'styles/assets/icons';
import { Box, IconButton } from 'UIKit';

import styles from './tradingRevokedStrip.module.scss';

const TradingRevokedStrip = () => {
  const { t } = useTranslation(['trading']);
  const { whenBelowMd } = useCustomMediaQuery();
  const [show, setShow] = useState(true);
  const { isVerified } = useKYC();

  const isTradingBlocked =
    new Date().valueOf() > SINGAPORE_TRADING_REVOKED_DATE.valueOf();

  const close = () => {
    setShow(false);
  };

  if (isVerified) return null;

  return (
    <StripPortal isSticky={whenBelowMd}>
      <Box
        renderWhen={show}
        className={styles.strip}
        style={{
          order: STRIPS_ORDER.TRADING_REVOKED_STRIP,
        }}
      >
        <div className={styles.content}>
          <SmallRectangle className={styles.yellowPillIcon} />
          <p>
            <Render
              when={isTradingBlocked}
              else={t('trading:tradingWillBeRevokedIndividuals', {
                country: 'Singapore',
                date: '17th Jul’23',
              })}
            >
              {t('trading:tradingRevokedIndividuals', {
                country: 'Singapore',
                date: '17th Jul’23',
              })}
            </Render>
          </p>
        </div>

        <IconButton
          icon={<CloseIcon />}
          onClick={close}
          onKeyDown={noop}
          data-testid="strip-close-button"
          className={styles.closeButton}
        />
      </Box>
    </StripPortal>
  );
};

export default TradingRevokedStrip;
