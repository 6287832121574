import { tabInactive, tabReactive } from 'actions/other';
import useMount from 'hooks/useMount';
import { useAppDispatch } from 'storeHooks';

const usePageVisibility = () => {
  const dispatch = useAppDispatch();

  // convert to useCallback
  const handleVisibilityChange = () => {
    if (document.hidden) {
      dispatch(
        tabInactive({
          inactiveTime: new Date().getTime(),
        })
      );
    } else {
      dispatch(
        tabReactive({
          reactiveTime: new Date().getTime(),
        })
      );
    }
  };

  // Listen for visibility change once the component mounts/unmounts and update the store.
  useMount(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  });
};

export default usePageVisibility;
