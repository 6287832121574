/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */

import React, { useState, useEffect, ChangeEvent, useRef } from 'react';

import cn from 'classnames';

import { addProductToBasket, editOrdersFromBasket } from 'actions/basketOrders';
import { NUMBER_TYPES } from 'constants/constants';
import { ORDER_TYPE } from 'constants/enums';
import { noop } from 'helpers';
import { productsSelector, showBasketErrorSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { OrdersObject } from 'types/basketOrders';
import { Product } from 'types/IProducts';
import NumberInput from 'UIKit/NumberInput';

import styles from './quantity.module.scss';

interface MobileQuantityInputBoxProps {
  orderData: OrdersObject;
  product?: Product;
  className?: string;
  inputError?: string;
  setInputError?: (arg: string | null) => {};
}

const MobileQuantityInputBox = ({
  orderData,
  product,
  className = '',
  inputError,
  setInputError,
}: MobileQuantityInputBoxProps) => {
  const dispatch = useAppDispatch();
  const quantityInputRef = useRef<HTMLInputElement>(null);

  const products = useAppSelector(productsSelector);
  const showError = useAppSelector(showBasketErrorSelector);

  const [quantity, setQuantity] = useState<Number | string>('');
  const [showHighlight, setShowHighlight] = useState<boolean>(false);

  const selectedProduct = product || products[orderData?.product_id];

  const {
    underlying_asset: underlyingAsset,
    contract_value: contractValue,
    id,
    contract_type: contractType,
    contract_unit_currency: contractUnitCurrency,
    symbol,
  } = selectedProduct;

  const onQuantityChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setQuantity(value);

    if (setInputError && value.length) {
      setInputError(null);
    }

    if (orderData) {
      dispatch(editOrdersFromBasket({ ...orderData, size: value }));
    } else {
      const payload = {
        product_id: id,
        side: '',
        symbol,
        asset: underlyingAsset?.symbol,
        order_type: ORDER_TYPE.LIMIT,
        limit_price: 0,
        size: value,
        contract_type: contractType,
      };
      dispatch(addProductToBasket(payload));
    }
  };

  const handleQuantityClick = () => {
    quantityInputRef?.current?.focus();
  };

  const handleInputFocus = () => {
    setShowHighlight(true);
  };

  const handleInputFocusBlur = () => {
    setShowHighlight(false);
  };

  useEffect(() => {
    setQuantity(Number(orderData?.size || ''));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  return (
    (<div
      data-testid="mobile-basket-orders-quantity-input"
      className={cn(styles.mobileBasketOrdersQuantityInput, {
        [styles.error]: showError && Number(orderData.size) <= 0,
        [styles[className]]: className,
        [styles.error]: inputError,
        [styles.highlight]: showHighlight,
      })}
      onClick={handleQuantityClick}
      onKeyDown={noop}
      role="textbox"
      tabIndex={0}
      data-palette="MobileQuantityInputBox">
      <NumberInput
        data-testid="mobile-basket-quantity-input"
        value={quantity || ''}
        onChange={onQuantityChange}
        autocomplete="off"
        inputMode="decimal"
        numberType={NUMBER_TYPES.natural}
        className={styles.contracts}
        inputRef={quantityInputRef}
        onFocus={handleInputFocus}
        onBlur={handleInputFocusBlur}
        autoFocus
      />
      <div className={cn(styles.placeholder)}>
        <span>{`x${contractValue} ${contractUnitCurrency}`}</span>
      </div>
    </div>)
  );
};

export default MobileQuantityInputBox;
