/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import { NewRedirectIcon } from 'components/customicons/holdings';
import { LANDING_PAGE_URL } from 'constants/constants';
import { refreshCacheAndReload } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
// import PropTypes from 'PropTypes';
import {
  ArrowRight,
  ChunkLoadErrorDesktopRefreshIcon,
  ChunkLoadErrorMobileRefreshIcon,
  IosIcon,
  WindowsIcon,
} from 'styles/assets/icons';
import { Button } from 'UIKit';

import {
  ChunkLoadErrorDesktopStyle,
  ChunkLoadErrorMobileStyle,
} from './chunkload_error_style';

const BLOG_LINK_TO_CLEAR_CACHE = `${LANDING_PAGE_URL}/blog/support/how-to-clear-cache-for-different-devices-and-browsers/`;

const ChunkLoadErrorPage = props => {
  const { t } = useTranslation(['errorBoundary']);
  const {
    title = t('deUpdated'),
    subTitle = t('refreshForLatest'),
    btnText = t('btnText'),
    windows = t('windows'),
    mac = t('mac'),
    instructions_title = t('instructions_title'),
    more_details_step_one = t('more_details_step_one'),
    more_details_step_two = t('more_details_step_two'),
    link = t('link'),
    andriod = t('andriod'),
    ios = t('ios'),
    andriod_step_one = t('andriod_step_one'),
    andriod_step_two = t('andriod_step_two'),
    ios_step_one = t('ios_step_one'),
    ios_step_two = t('ios_step_two'),
    ios_step_three = t('ios_step_three'),
  } = props;

  const reload = async () => {
    try {
      await refreshCacheAndReload('ChunkLoadErrorPage');
    } catch {
      // Error already handled in refreshCacheAndReload
    }
  };

  return (
    <>
      <MediaQuery query={device.up.md}>
        <ChunkLoadErrorDesktopStyle>
          <div className="error-img-wrapper">
            <ChunkLoadErrorDesktopRefreshIcon />
          </div>
          <div className="updated-text">
            <p className="title">{title}</p>
            <p className="subTitle"> {subTitle}</p>
          </div>
          <Button data-testid="70sx6f8v83uCvNmo-ni-N" onClick={reload}>
            {btnText}
          </Button>
          <div className="instructions">
            <div className="info">{instructions_title}</div>
            <div className="info">
              <WindowsIcon />
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: windows }} />
            </div>
            <div className="info iosIcon">
              <IosIcon />
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: mac }} />
            </div>
            <div>
              {more_details_step_one}
              <a
                href={BLOG_LINK_TO_CLEAR_CACHE}
                data-testid="clear-cache-link"
                aria-hidden="true"
                rel="noreferrer"
                target="_blank"
              >
                {' '}
                {link} <NewRedirectIcon />
              </a>
              {more_details_step_two}
            </div>
          </div>
        </ChunkLoadErrorDesktopStyle>
      </MediaQuery>
      <MediaQuery query={device.down.md}>
        <ChunkLoadErrorMobileStyle>
          <div className="section-top">
            <div className="error-img-wrapper">
              <ChunkLoadErrorMobileRefreshIcon />
            </div>
            <div className="updated-text">
              <p className="title">{title}</p>
              <p className="subTitle"> {subTitle}</p>
            </div>
            <Button data-testid="hFUmlvB0wsws9mPVQncgC" onClick={reload}>
              {btnText}
            </Button>
          </div>
          <div className="instructions">
            <div className="title">{instructions_title}</div>
            <div className="device">
              <div className="name">{andriod}: </div>
              <div className="direction">
                <ArrowRight />
                {andriod_step_one}
              </div>
              <div className="direction">
                <ArrowRight />
                {andriod_step_two}
              </div>
            </div>
            <div className="device">
              <div className="name">{ios}: </div>
              <div className="direction">
                <ArrowRight />
                {ios_step_one}
              </div>
              <div className="direction">
                <ArrowRight />
                {ios_step_two}
              </div>
              <div className="direction">
                <ArrowRight />
                {ios_step_three}
              </div>
            </div>
            <div className="footer">
              {more_details_step_one}
              <a
                href={BLOG_LINK_TO_CLEAR_CACHE}
                data-testid="link-to-clear-cache"
                aria-hidden="true"
                rel="noreferrer"
                target="_blank"
              >
                {' '}
                {link} <NewRedirectIcon />
              </a>
              {more_details_step_two}
            </div>
          </div>
        </ChunkLoadErrorMobileStyle>
      </MediaQuery>
    </>
  );
};

// ChunkLoadErrorPage.defaultProps = {
//   onClick: () => {
//     refreshCacheAndReload();
//   },
// };

// ChunkLoadErrorPage.propTypes = {
//   title: PropTypes.string,
//   // eslint-disable-next-line react/no-unused-prop-types
//   onClick: PropTypes.func,
// };

export default ChunkLoadErrorPage;
