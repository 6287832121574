/* eslint-disable import/no-unresolved */
/* eslint-disable test-selectors/onClick */
/* eslint-disable test-selectors/onChange */
/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ClickOutside from 'components/click-outside';
// import { ArrowDown } from 'components/customicons/common';
// import { FaChevronLeft, FaChevronRight } from 'components/icons';
import Render from 'components/render';
import {
  TABLE_DATA_FETCH_COUNT,
  // DEFAULT_PAGINATION_ROWS_PER_PAGE,
  NUMBER_TYPES,
} from 'constants/constants';
import { localeNumber, isNegative, getInt } from 'helpers/utils';
// import PropTypes from 'PropTypes';
import { AngleRightIcon, AngleLeft, ArrowDown } from 'styles/assets/icons';
import NumberInput from 'UIKit/NumberInput';

import { StyledTablePagination, Options, OptionItem, Divider } from './table_styles';

const TablePagination = props => {
  const { t } = useTranslation(['common']);
  const {
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    count,
    options,
    shouldHideRange,
    originalData,
    fetchNextData,
    fetchPrevData,
    currentRangeData,
    onPageChange,
    onRowsPerPageChange,
    frontendPagination,
    // eslint-disable-next-line react/prop-types
    alwaysShowPagination,
    className,
  } = props;

  const [showOptions, toggleOptions] = useState(false);

  const onSelectIconClick = () => toggleOptions(!showOptions);
  const [rowsPerPageInput, setRowsPerPageInput] = useState(rowsPerPage);

  useEffect(() => {
    setRowsPerPageInput(rowsPerPage);
  }, [rowsPerPage]);

  const changeRowsPerPage = value => {
    setRowsPerPage?.(value);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    persistRowsPerPage(value);
    setPage?.(0); // reset page
  };

  const persistRowsPerPage = value => {
    onRowsPerPageChange(value); // call rows per page function (used to persist state)
    onPageChange(0); // call on page change function (used to persist state)
  };

  const onItemClick = value => () => {
    changeRowsPerPage(value);
    setRowsPerPageInput(value);
    persistRowsPerPage(value);
    toggleOptions(false);
  };

  const invalidInput = value => {
    const valueNo = getInt(value);
    const greaterThanLimit = valueNo > TABLE_DATA_FETCH_COUNT;
    const isZero = valueNo === 0;
    return isNegative(value) || greaterThanLimit || isZero;
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;
    if (invalidInput(value)) {
      return;
    }
    if (value !== '') {
      changeRowsPerPage(getInt(value) || value); // NaN || '5' => '5'
    }
    setRowsPerPageInput(getInt(value) || value);
  };

  const handleInputBlur = () => {
    setRowsPerPageInput(rowsPerPage);
    persistRowsPerPage(rowsPerPage);
  };

  // const addPerPage = k => k + rowsPerPage;

  const from = count === 0 ? 0 : page * rowsPerPage + 1;
  const to = Math.min(count, (page + 1) * rowsPerPage);

  // Checks whether to fetch the next range of data
  // const shouldFetchNextData = () => {
  //   const nextData = [...originalData].slice(
  //     addPerPage(from) - 1,
  //     addPerPage(to)
  //   );
  //   console.log("DEBUG: fetch next data ", addPerPage(from)-1, addPerPage(to), nextData, originalData);
  //   return nextData.length !== rowsPerPage;
  // };

  // Checks if next range of data is fetched
  const isNextDataFetched = () => !!currentRangeData.length;

  const changePage = changeBy => {
    const nextPage = page + changeBy;
    setPage?.(nextPage);
    onPageChange(nextPage);
  };

  // const onPrevClick = () => changePage(-1);
  const onPrevClick = () => {
    if (!frontendPagination) {
      const apiPageSize = TABLE_DATA_FETCH_COUNT;
      fetchPrevData({ page, rowsPerPage, apiPageSize });
    }
    changePage(-1);
  };

  const onNextClick = () => {
    if (!frontendPagination) {
      const apiPageSize = TABLE_DATA_FETCH_COUNT;
      fetchNextData({ page, rowsPerPage, apiPageSize });
    }
    changePage(1);
  };

  const disabled = {
    prev: page === 0, // when page == 0
    next: page >= Math.ceil(count / rowsPerPage) - 1 || !isNextDataFetched(), // when page is greater than data && next set of data is not fetched
  };

  // Return if count or originalData is empty/zero.
  // count < 5 = Product requirement
  const shouldHideControls = () => {
    const noCount = !count;
    const noData = !originalData.length;

    // const itemsLessThen5 = count < DEFAULT_PAGINATION_ROWS_PER_PAGE;
    //= ===== We have hard coded to 5 as the default pagination has been reset to 15 but in the pagination drop-down we will display 5 & 10
    const itemsLessThen5 = count < 5;
    return noCount || noData || itemsLessThen5;
  };

  if (!alwaysShowPagination && shouldHideControls()) {
    return null;
  }

  return (
    <StyledTablePagination
      className={classNames('pagination', {
        [className]: className,
      })}
    >
      <div className="left">
        <div className="label">{t('common:pagination.itemPerPage')}</div>
        <div className="inputs" data-testid={`Rows-per-page-${rowsPerPageInput}`}>
          <NumberInput
            className="input-text"
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            value={rowsPerPageInput}
            numberType={NUMBER_TYPES.natural}
          />
          <ArrowDown className="input-icon" onClick={onSelectIconClick} />
          <Render when={showOptions}>
            <ClickOutside onOutsideClick={() => toggleOptions(false)}>
              <Options className="pagination-options">
                {options.map(option => (
                  <OptionItem
                    data-testid={`${rowsPerPage}-table-input`}
                    key={option}
                    className={classNames('pagination-option-item', {
                      'option-item-active': rowsPerPage === option,
                    })}
                    onClick={onItemClick(option)}
                    isSelected={rowsPerPage === option}
                  >
                    {option}
                  </OptionItem>
                ))}
              </Options>
            </ClickOutside>
          </Render>
        </div>
      </div>

      <Divider className="divider" shouldHideRange={shouldHideRange} />

      <div className="right">
        <Render when={!shouldHideRange}>
          <div className="range">
            <span className="from-to">
              {from} - {to}
            </span>{' '}
            <span className="count">
              {' '}
              {t('common:pagination.of')} {localeNumber(count)}
            </span>
          </div>
        </Render>
        <div className="nav">
          <IconButton
            testID="previous-page"
            aria-label="previous page"
            component="span"
            classes={{
              root: 'icon-btn-root',
              disabled: 'icon-btn-disabled',
            }}
            disabled={disabled.prev}
            onClick={onPrevClick}
          >
            {/* <FaChevronLeft className="nav-icon" /> */}
            <AngleLeft />
          </IconButton>
          <IconButton
            testID="next-page"
            aria-label="Next page"
            component="span"
            classes={{
              root: 'icon-btn-root',
              disabled: 'icon-btn-disabled',
            }}
            disabled={disabled.next}
            onClick={onNextClick}
          >
            {/* <FaChevronRight className="nav-icon" /> */}

            <AngleRightIcon />
          </IconButton>
        </div>
      </div>
    </StyledTablePagination>
  );
};

TablePagination.defaultProps = {
  fetchNextData: () => null,
  fetchPrevData: () => null,
  onPageChange: () => null,
  onRowsPerPageChange: () => null,
};

// TablePagination.propTypes = {
//   rowsPerPage: PropTypes.stringOrNumber.isRequired,
//   setRowsPerPage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   setPage: PropTypes.func.isRequired,
//   count: PropTypes.number.isRequired,
//   options: PropTypes.arrayOf(PropTypes.number).isRequired,
//   shouldHideRange: PropTypes.bool.isRequired,
//   fetchNextData: PropTypes.func,
//   fetchPrevData: PropTypes.func,
//   currentRangeData: PropTypes.array.isRequired,
//   originalData: PropTypes.array.isRequired,
//   onPageChange: PropTypes.func,
//   onRowsPerPageChange: PropTypes.func,
//   frontendPagination: PropTypes.bool,
// };

export default React.memo(TablePagination);
