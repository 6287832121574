/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import routes from 'constants/routes';
import { device } from 'helpers/screenSizes';
import { AngleRight } from 'styles/assets/icons';

const Wrapper = styled.div`
  font-size: 12.1px;
  font-family: var(--fontRegular);
  line-height: 1.64;
  letter-spacing: 0.6px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  .link {
    &:hover {
      a {
        color: ${props => props.theme.primaryTheme} !important;
      }
    }
  }
  a {
    color: ${props => props.theme.secondaryText} !important;
    cursor: pointer;
  }
  .disabled {
    color: ${props => props.theme.primaryText};
    font-family: var(--fontRegular);
    cursor: not-allowed;
  }
  @media ${device.down.md} {
    display: ${props => (props.hideInMobile ? 'none' : 'flex')};
  }
`;

const BreadCrumb = props => {
  return (
    (<Wrapper hideInMobile={props.hideInMobile}>
      <div className={`d-flex breadcrumb ${props.className}`}>
        {props.links.map((obj, idx) => {
          return idx !== props.links.length - 1 ? (
            <div key={idx} className="link" data-palette="BreadCrumb">
              <Link to={`${routes.default}${obj.url}`}>{obj.label}</Link>
              <AngleRight />
            </div>
          ) : (
            <div key={idx} data-palette="BreadCrumb">{obj.label}</div>
          );
        })}
      </div>
    </Wrapper>)
  );
};

export default BreadCrumb;
