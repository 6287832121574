import { createSelector } from 'reselect';

import { optionsChainProductListSelector } from './optionChainSeletor';
import { oiCallValueSelector, oiPutValueSelector } from './priceSelectors';

const selectedCallOptionsChainOiListSelector = createSelector(
  [optionsChainProductListSelector, oiCallValueSelector],
  (productList, oiValue) => {
    const oiList = productList.map(product => Number(oiValue[product?.id]));
    return oiList;
  }
);

const selectedPutOptionsChainOiListSelector = createSelector(
  [optionsChainProductListSelector, oiPutValueSelector],
  (productList, oiValue) => {
    const oiList = productList.map(product => Number(oiValue[product?.id]));
    return oiList;
  }
);

export const maxCallOiSelector = createSelector(
  [selectedCallOptionsChainOiListSelector],
  oiList => {
    const oiListWithoutNull = oiList.filter(oi => !Number.isNaN(oi) && oi !== null);
    return Math.max(...oiListWithoutNull);
  }
);

export const maxPutOiSelector = createSelector(
  [selectedPutOptionsChainOiListSelector],
  oiList => {
    const oiListWithoutNull = oiList.filter(oi => !Number.isNaN(oi) && oi !== null);
    return Math.max(...oiListWithoutNull);
  }
);
