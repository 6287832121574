/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import { CrossIconSymbol, TickIcon } from 'styles/assets/icons';

// tslint:disable-next-line: no-import-side-effect
import styles from './index.module.scss';

interface IOutlineButtonProps {
  buttonType: 'success' | 'failure';
  onClick: () => void;
}

const buttonContent = (hover: boolean, buttonType: string) => (
  <>
    {buttonType === 'success' ? (
      <>
        <TickIcon
          color="#3a7d5c"
          className={`${styles['outline-button-svg']} ${styles['base-icon']}`}
        />
        <TickIcon
          color="#ffffff"
          className={`${styles['outline-button-svg']} ${styles['hover-icon']}`}
        />
      </>
    ) : (
      <>
        <CrossIconSymbol
          color="#d75750"
          className={`${styles['outline-button-svg']} ${styles['base-icon']}`}
        />
        <CrossIconSymbol
          color="#ffffff"
          className={`${styles['outline-button-svg']} ${styles['hover-icon']}`}
        />
      </>
    )}
  </>
);

export const OutlineButton: React.FC<IOutlineButtonProps> = props => {
  const [hover, setHover] = useState(false);

  return (
    <Button
      data-testid="C8v2owD8dVwBqUwXSYWvl"
      variant="outlined"
      className={`${styles['outline-button']} ${
        styles[`outline-button-${props.buttonType}`]
      }`}
      onClick={props.onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {buttonContent(hover, props.buttonType)}
    </Button>
  );
};
