import Styled from 'styled-components';
import { device } from '../helpers/screenSizes';

const Pagetext = Styled.div`
    color: ${props => props.theme.primaryText};
    font-size: 16px;
    font-family : var(--fontRegular);
    line-height: 1.33;   
    
    @media ${device.down.md} {
        font-size: 14px;
        line-height: 1.43;
    }
`;

export default Pagetext;
