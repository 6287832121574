/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import mixpanel from 'mixpanel-browser';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';

import { postMessageToMobileApp } from 'components/app/helpers';
import { IS_INDIAN_EXCHANGE, IS_RN_WEBVIEW, isProd } from 'constants/constants';
import { userSelector } from 'selectors/otherSelectors';
import StoreProxy from 'storeProxy';

const getDeviceType = () => {
  if (isMobile) {
    return 'Mobile';
  }
  if (isTablet || isIPad13) {
    return 'Tab';
  }
  return 'Desktop';
};
const shouldUserBeTracked = () => {
  const doNotTrackCampaigns = {
    ev_dbo_500: true,
  };

  if (window && window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    const currentCampaignURL = searchParams.get('utm_campaign');

    if (currentCampaignURL in doNotTrackCampaigns) {
      mixpanel.opt_out_tracking({
        delete_user: true,
      });
    }
  }
};

mixpanel.init(process.env.REACT_APP_MIX_PANEL_ID, {
  debug: !isProd,
  ignore_dnt: true,
  loaded: () => {
    shouldUserBeTracked();
  },
});

export const getURLQueryParams = () => {
  const urlQuery = window.location.search;
  const utmParams = {};
  const queryParams = urlQuery ? urlQuery.substring(1).split('&') : [];

  queryParams.forEach(item => {
    item = item.split('=');
    utmParams[item[0]] = item[1];
  });
  return utmParams;
};

export const identifyUser = user_id => {
  if (mixpanel.has_opted_out_tracking()) {
    mixpanel.opt_in_tracking();
  }

  postMessageToMobileApp('APPSFLYER_SET_CUSTOMER_USER_ID', {
    id: user_id,
  });

  mixpanel.identify(user_id);
};

export const reset = () => mixpanel.reset();

export const setUserProperty = userProps => {
  mixpanel.people.set(userProps);
  // identify must be called along with every instance of people.set
};

export const trackMixpanelEvent = (title, params = {}) => {
  const user = StoreProxy.selector(userSelector);
  let isUserLoggedIn = false;
  let userProperties = {};

  if (user?.id) {
    isUserLoggedIn = true;
    userProperties = {
      user_id: user.id,
      email: user.email,
      country: user.country,
    };
  }
  const finalParams = {
    is_mobile_app: Boolean(IS_RN_WEBVIEW),
    is_loggedin: isUserLoggedIn,
    project: IS_INDIAN_EXCHANGE ? 'Delta Exchange India' : 'Delta Exchange Global',
    ...userProperties, // This spreads the user properties if the user is logged in.
  };
  // Efficiently adding params to finalParams
  Object.keys(params).forEach(key => {
    finalParams[key] = params[key];
  });
  setTimeout(() => {
    mixpanel.track(title, finalParams);
  }, 0);
};

export const trackMixpanelEventPageView = params => {
  const urlParams = getURLQueryParams();
  mixpanel.track('Page Viewed', {
    ...params,
    utm_campaign: urlParams.utm_campaign,
    utm_source: urlParams.utm_source,
    device: getDeviceType(),
  });
};

export const refreshCacheAndReload = async calledFrom => {
  trackMixpanelEvent('Delete cache and reload', {});
  console.info('DEBUG refreshCacheAndReload utils', calledFrom);
  try {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
      console.info('Cache cleared', calledFrom);
    }
  } catch (e) {
    console.error('Failed to clear cache', calledFrom, e);
    trackMixpanelEvent('Delete cache and reload', {});
  } finally {
    window.location.href = window.location.href.replace(/#.*$/, '');
  }
  // if (caches) {
  //   const names = await caches.keys();
  //   await Promise.all(names.map(name => caches.delete(name)));
  // }
  // // window.location.reload();
  // // window.location = window.location.href+'?eraseCache=true';
  // window.location.href = window.location.href.replace(/#.*$/, '');
};
