/* eslint-disable test-selectors/onChange */
/* eslint-disable react/prop-types */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';

import { useSelector } from 'react-redux';

import {
  optionChainToggleExpiryDate,
  updateOptionChainAssetSymbol,
} from 'actions/optionChain';
import { sortDates } from 'components/options_chain/OptionsChartDropdown/utils';
import { VANILLA_SETTLING_ASSET } from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import { sortBy } from 'helpers';
import { dateFormat, getUnixTimestamp } from 'helpers/day';
import { getFilteredDataForOptionChains } from 'helpers/utils';
import { useAppSelector, useToggle } from 'hooks';
import {
  basketOrderSwitchSelector,
  basketSelectedUnderlyingAssetSelector,
  spotPriceSelectorBySymbol,
} from 'selectors';
import {
  OptionChainsProductList,
  selectedProductSelector,
} from 'selectors/tradeSelectors';
import { useAppDispatch } from 'storeHooks';

import type { OptionChainDataItem, OptionsControlsContextType } from './optionChainType';
import styles from './styles.module.scss';

const OptionsChainsContext = createContext<OptionsControlsContextType | null>(null);

export function useOptionsChainData() {
  const context = useContext(OptionsChainsContext);

  if (!context) {
    throw new Error(
      'OptionsChain compound components must be rendered within the OptionsControlsContext'
    );
  }
  return context;
}

export const OptionChainProvider = ({ children }) => {
  const [isPending, startTransition] = useTransition();
  const dispatch = useAppDispatch();
  const {
    tableData,
    expiryTimesDropDownList,
    underlyingAssetSymbolList,
    settlementTimeandAssetList,
  } = useSelector(OptionChainsProductList);
  const selectedProduct = useSelector(selectedProductSelector);
  const [isAscending, toggleSortOrder] = useToggle(true);
  const isBasketOrderView = useAppSelector(basketOrderSwitchSelector);
  const defaultSelectedAsset = useAppSelector(basketSelectedUnderlyingAssetSelector);
  const [showBasketOrderBuySellRow, setShowBasketOrderBuySellRow] = useState({
    productId: '',
    show: false,
  });

  const isSelectedProductOptionsChain =
    selectedProduct &&
    (selectedProduct.contract_type === 'call_options' ||
      selectedProduct.contract_type === 'put_options');

  const [selectedAsset, setSelectedAsset] = useState(defaultSelectedAsset);

  const expiryTimesSortedList: OptionChainDataItem[] = useMemo(() => {
    return sortDates(expiryTimesDropDownList[selectedAsset]);
  }, [expiryTimesDropDownList, selectedAsset]);

  const [selectedExpiryDate, setSelectedExpiryDate] = useState(
    isSelectedProductOptionsChain
      ? dateFormat(selectedProduct.settlement_time!, TIME_FORMATS.DD_MMM_YYYY)
      : expiryTimesDropDownList[underlyingAssetSymbolList[0]][0]
  );
  // Expiry date to be used based on Asset selected.
  const [usableExpiryDate, setUsableExpiryDate] = useState(selectedExpiryDate);

  const [selectedOptionsType, selectSelectedOptionsType] = useState('all');

  const setSelectedOptionsType = useCallback(type => {
    startTransition(() => {
      selectSelectedOptionsType(type);
    });
  }, []);

  const handleAssetChange = useCallback(
    asset => {
      const assetExpiryDatesList = expiryTimesDropDownList[asset];
      const selectedDateIsPresent = assetExpiryDatesList.includes(selectedExpiryDate);
      startTransition(() => {
        setSelectedAsset(asset);
        if (selectedDateIsPresent) {
          setUsableExpiryDate(selectedExpiryDate);
        } else {
          /* else first date from the list is used */
          setUsableExpiryDate(assetExpiryDatesList[0]);
        }
      });
      dispatch(updateOptionChainAssetSymbol(asset));

      if (selectedDateIsPresent) {
        dispatch(
          optionChainToggleExpiryDate(
            settlementTimeandAssetList[asset][selectedExpiryDate]
          )
        );
      } else {
        /* else first date from the list is used */
        dispatch(
          optionChainToggleExpiryDate(Object.values(settlementTimeandAssetList[asset])[0])
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedExpiryDate, expiryTimesDropDownList]
  );

  const handleExpiryDateChange = useCallback(expiryDate => {
    startTransition(() => {
      setSelectedExpiryDate(expiryDate.value);
      setUsableExpiryDate(expiryDate.value);
    });
    const unixFormatDateList = settlementTimeandAssetList[selectedAsset];
    dispatch(optionChainToggleExpiryDate(unixFormatDateList[expiryDate.value]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onColumnClick = useCallback(
    product => {
      if (product && isBasketOrderView) {
        const isRowActive =
          showBasketOrderBuySellRow.productId === product?.id &&
          showBasketOrderBuySellRow.show;

        setShowBasketOrderBuySellRow({
          productId: product?.id,
          show: !isRowActive,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBasketOrderBuySellRow, isBasketOrderView]
  );

  useEffect(() => {
    if (
      !expiryTimesDropDownList[selectedAsset].find(item => item === selectedExpiryDate)
    ) {
      setSelectedExpiryDate(
        isSelectedProductOptionsChain
          ? dateFormat(selectedProduct.settlement_time!, TIME_FORMATS.DD_MMM_YYYY)
          : expiryTimesDropDownList[selectedAsset][0]
      );
      dispatch(
        optionChainToggleExpiryDate(
          isSelectedProductOptionsChain
            ? getUnixTimestamp(selectedProduct.settlement_time!)
            : Object.values(settlementTimeandAssetList[selectedAsset])[0]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimesDropDownList]);

  useEffect(() => {
    const unixFormatDateList = settlementTimeandAssetList[selectedAsset];
    setUsableExpiryDate(selectedExpiryDate);
    dispatch(optionChainToggleExpiryDate(unixFormatDateList[selectedExpiryDate]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExpiryDate]);

  const getBackgroundClass = useCallback(
    strikePrice => {
      if (selectedOptionsType === 'all') {
        return '';
      }
      const symbol = selectedAsset === 'BTC' ? 'XBT' : selectedAsset;
      const spotPrice = spotPriceSelectorBySymbol(
        `.DE${symbol}${VANILLA_SETTLING_ASSET}`
      );

      if (selectedOptionsType === 'Call') {
        if (Number(strikePrice) < Number(spotPrice)) {
          return `${styles.optionsShadedBackground} m-indicator`;
        }
      }

      if (selectedOptionsType === 'Put') {
        if (Number(strikePrice) > Number(spotPrice)) {
          return `${styles.optionsShadedBackground} m-indicator`;
        }
      }

      return '';
    },
    [selectedOptionsType, selectedAsset]
  );

  const data = useMemo(() => {
    if (tableData) {
      const result = getFilteredDataForOptionChains(
        tableData,
        usableExpiryDate,
        selectedAsset
      );
      const sortedData = sortBy(
        result,
        item => Number(item.strike_price),
        isAscending ? 'ascending' : 'descending'
      );
      return sortedData.map(item => ({
        ...item,
        backgroundClass: getBackgroundClass(item.strike_price),
      })) as OptionChainDataItem[];
    }
    return [];
  }, [tableData, usableExpiryDate, selectedAsset, isAscending, getBackgroundClass]);

  const contextValue = useMemo(() => {
    const isAllOptionSelected = selectedOptionsType === 'all';
    const showCallOptions = isAllOptionSelected || selectedOptionsType === 'Call';
    const showPutOptions = isAllOptionSelected || selectedOptionsType === 'Put';
    const boxClassName = isAllOptionSelected ? styles.halfBox : styles.fullBox;
    const cellClassName = isAllOptionSelected
      ? styles.bothOptionsCell
      : styles.singleOptionsCell;

    return {
      data,
      isPending,
      expiryTimesSortedList,
      isAscending,
      selectedOptionsType,
      setSelectedOptionsType,
      usableExpiryDate,
      selectedAsset,
      isAllOptionSelected,
      showCallOptions,
      showPutOptions,
      boxClassName,
      cellClassName,
      toggleSortOrder,
      handleExpiryDateChange,
      handleAssetChange,
      underlyingAssetSymbolList,
      onColumnClick,
      showBasketOrderBuySellRow,
    };
  }, [
    isPending,
    selectedOptionsType,
    setSelectedOptionsType,
    data,
    expiryTimesSortedList,
    isAscending,
    usableExpiryDate,
    selectedAsset,
    toggleSortOrder,
    handleExpiryDateChange,
    handleAssetChange,
    underlyingAssetSymbolList,
    onColumnClick,
    showBasketOrderBuySellRow,
  ]);

  return (
    (<OptionsChainsContext.Provider value={contextValue} data-palette="OptionChainProvider">
      {children}
    </OptionsChainsContext.Provider>)
  );
};
