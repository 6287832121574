import React, { useRef, useState } from 'react';

import { useResizeObserver } from 'hooks';

const PortalContainers = () => {
  const stickyStripsContainerRef = useRef<HTMLDivElement>(null);
  const [stickyStripsContainerHeight, setStickyStripsContainerHeight] = useState(0);

  useResizeObserver(stickyStripsContainerRef, () =>
    setStickyStripsContainerHeight(stickyStripsContainerRef?.current?.clientHeight || 0)
  );

  return (<>
    <div
      ref={stickyStripsContainerRef}
      className="stickyStripsContainer"
      id="stickyStripsContainer"
      data-palette="PortalContainers" />
    <div
      className="nonStickyStripsContainer"
      id="nonStickyStripsContainer"
      data-palette="PortalContainers" />
    <div
      className="stickyHeadersContainer"
      id="stickyHeadersContainer"
      style={{
        top: stickyStripsContainerHeight,
      }}
      data-palette="PortalContainers" />
  </>);
};

export default PortalContainers;
