/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import { memo, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';
import TabButton from 'UIKit/TabButton';

import styles from './styles.module.scss';

const optionsTypeList = ['all', 'Call', 'Put'];

const OptionsType = ({ className }) => {
  const { t } = useTranslation(['markets']);
  const { isPending, selectedOptionsType, setSelectedOptionsType } =
    useOptionsChainData();
  const typeRef = useRef(selectedOptionsType);
  const isPendingActive = type => isPending && typeRef.current === type;

  const onChange = type => {
    typeRef.current = type;
    setSelectedOptionsType(type);
  };

  return (
    <>
      {optionsTypeList.map(type => {
        const isActive = isPending ? isPendingActive(type) : selectedOptionsType === type;

        return (
          <TabButton
            data-testid={`option-type-${type}`}
            title={type === 'all' ? t(`markets:optionsTable.${type}`) : type}
            onClick={onChange.bind(null, type)}
            key={type}
            // loading={isPendingActive(type)}
            active={isActive}
            className={cn(styles.optionsTypeTab, {
              [styles[className]]: className,
            })}
            isParentPrimaryBackground
          />
        );
      })}
    </>
  );
};

export default memo(OptionsType);
