// import useThrottledEffect from 'hooks/useThrottledEffect';
import {
  l2BestAsksSelector,
  l2BestAsksSizeSelector,
  l2BestBidsSelector,
  l2BestBidsSizeSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';

interface L2BestValueProps {
  size?: boolean;
  price?: boolean;
  bid?: boolean;
  ask?: boolean;
}
/**
 * Used for best bid/ask price and size from L2 updates
 * @example const bestBidPrice = useL2BestValue({ bid: true, price: true });
 */
const useL2BestValue = ({ price, bid, ask }: L2BestValueProps) => {
  const bestBid = useAppSelector(l2BestBidsSelector);
  const bestAsk = useAppSelector(l2BestAsksSelector);
  const bestBidSize = useAppSelector(l2BestBidsSizeSelector);
  const bestAskSize = useAppSelector(l2BestAsksSizeSelector);

  if (bid) {
    return price ? bestBid : bestBidSize;
  }
  if (ask) {
    return price ? bestAsk : bestAskSize;
  }

  return '';
};

export default useL2BestValue;
