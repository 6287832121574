/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';

import memoize from 'fast-memoize';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import { showBasketError } from 'actions/basketOrders';
import { getOrderLeverage } from 'actions/orderbook';
import SpinnerLoader from 'components/spinnerLoader';
import { ASSET_SYMBOL } from 'constants/constants';
import { SIDE, ORDER_TYPE } from 'constants/enums';
// eslint-disable-next-line import/named
import { calculateExtraOrderMargin } from 'helpers/formulas';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { filter, map, pick, pipe, reduce, sort } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import {
  useAssetBalance,
  useFirstMountState,
  usePlaceIsolatedBasketOrders,
  useToggle,
} from 'hooks';
// eslint-disable-next-line import/named
import {
  allOpenOrdersSelector,
  allOpenPositionsSelector,
  markPriceSelectorById,
  productsSelector,
  selectedProductSelector,
  spotPriceSelectorBySymbol,
  userSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { OrdersObject } from 'types/basketOrders';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './orders.module.scss';
import { ReloadIcon } from '../icons';

interface IsolatedBasketPlaceOrderSectionProps {
  orders: OrdersObject[];
  showLoader: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setIsolatedApiResponse: Dispatch<SetStateAction<{}>>;
  toggleShowIsolatedLoader: Dispatch<SetStateAction<void>>;
}

const IsolatedBasketPlaceOrderSection = ({
  orders,
  showLoader,
  setShow,
  setIsolatedApiResponse,
  toggleShowIsolatedLoader,
}: IsolatedBasketPlaceOrderSectionProps) => {
  const [margin, setMargin] = useState<string | number>(0);
  const isFirstMount = useFirstMountState();
  const [marginloading, togglemarginloading] = useToggle(false);
  const { t } = useTranslation(['basketOrders', 'trading']);
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(state => selectedProductSelector(state));
  const allOpenPositions = useAppSelector(state => allOpenPositionsSelector(state));
  const allOpenOrders = useAppSelector(state => allOpenOrdersSelector(state));
  const products = useAppSelector(state => productsSelector(state));

  const selectedAsset = !isMobile
    ? selectedProduct?.settling_asset?.symbol
    : ASSET_SYMBOL;
  const { roundedAvailBalance } = useAssetBalance(selectedAsset);
  const { margin_mode: marginMode } = useAppSelector(userSelector);
  const { onPlaceOrderClick } = usePlaceIsolatedBasketOrders({
    orders,
    callback: setIsolatedApiResponse,
    toggleloader: toggleShowIsolatedLoader,
  });

  // markprice
  const price = (product_id: number) => {
    const markPrice = markPriceSelectorById(String(product_id));
    return markPrice;
  };

  // spotprice
  const getSpotPrice = spotIndex => {
    const spotPrice = spotPriceSelectorBySymbol(spotIndex);
    return spotPrice;
  };

  // position
  const getPosition = (product_id: number) => {
    return allOpenPositions.filter(position => position.product_id === product_id);
  };

  // orders
  const getOrders = (product_id: number, side: SIDE) => {
    const productOpenOrdersBySide = pipe(
      filter(openOrder => openOrder.product_id === product_id && side === SIDE.BUY),
      map(pick(['unfilled_size', 'limit_price'])),
      sort((a, b) => {
        const x = Number(a.limit_price);
        const y = Number(b.limit_price);
        return y - x;
      })
    )(allOpenOrders);
    // console.log("DEBUG",orders);
    return productOpenOrdersBySide;
  };

  // fetch leverage
  const fetchAllLeverage = useCallback(() => {
    return Promise.all(orders.map(order => dispatch(getOrderLeverage(order.product_id))));
  }, [orders, dispatch]);

  useEffect(() => {
    if (isFirstMount) {
      fetchAllLeverage();
    }
  }, [fetchAllLeverage, orders, isFirstMount]);

  const checkError = () =>
    orders.reduce(
      (acc: any, order: any) => {
        if (order.order_type === ORDER_TYPE.LIMIT && !Number(order.limit_price)) {
          return { value: true };
        }
        if (!Number(order.size)) {
          return { value: true };
        }
        return acc;
      },
      { value: false }
    );

  const handleMarginClick = async () => {
    togglemarginloading();
    const validationError = checkError();
    if (validationError.value) {
      dispatch(showBasketError(validationError.value));
      togglemarginloading();
    } else {
      fetchAllLeverage().then(result => {
        const newOrderMargins = {};
        result?.forEach(o => {
          if (o?.result) {
            newOrderMargins[o?.result?.product_id] = o?.result;
          }
        });
        const calculateMargin = (acc, order) => {
          const memoizedMarginFn = memoize(calculateExtraOrderMargin);
          const productMargin = memoizedMarginFn({
            mark_price: price(order.product_id),
            product: products[order.product_id],
            position: getPosition(order.product_id) || 0,
            leverage: Number(newOrderMargins[order.product_id].leverage),
            order_margin: Number(newOrderMargins[order.product_id].order_margin),
            user_buy_orders: getOrders(order.product_id, SIDE.BUY),
            user_sell_orders: getOrders(order.product_id, SIDE.SELL),
            side: order.side,
            size: order.size,
            limit_price: Number(order.limit_price) || price(order.product_id),
            spot_price: getSpotPrice(products[order.product_id].spot_index?.symbol),
          });
          return acc + productMargin;
        };
        const totalMargin = reduce(calculateMargin, 0, orders);
        setMargin(Number(totalMargin).toFixed(2));
        trackMixpanelEvent('Basket Orders -Isolated/Cross Estimate Margin Click', {
          margin: Number(totalMargin).toFixed(2),
          marginMode,
        });
        togglemarginloading();
      });
    }
  };

  const handlePlaceOrderClick = () => {
    const validationError = checkError();
    if (validationError.value) {
      handleMarginClick();
      dispatch(showBasketError(validationError.value));
    } else {
      setShow(true);
      onPlaceOrderClick();
    }
  };

  return (
    (<div
      className={styles['margins-container']}
      data-palette="IsolatedBasketPlaceOrderSection">
      <div className={styles.margins}>
        <div className={styles['margin-row']}>
          <div className={styles['margin-label']}>
            {t('basketOrders:orderMargin')}{' '}
            <button
              type="button"
              data-testid="on-estimate-margin-click"
              className={styles.reload}
              onClick={() => {
                handleMarginClick();
              }}
              disabled={marginloading}
            >
              {marginloading ? <SpinnerLoader /> : <ReloadIcon />}
            </button>
          </div>
          <div className={styles['margin-value']}>
            ~ {margin} {selectedAsset}
          </div>
        </div>
        <div className={styles['margin-row']}>
          <div className={styles['margin-label']}>
            {t('basketOrders:availableMargin')}
          </div>
          <div className={styles['margin-value']}>
            {roundedAvailBalance} {selectedAsset}
          </div>
        </div>
        <MediaQuery query={device.down.md}>
          <div className={styles['margin-row']}>
            <div className={styles['margin-label']}>{t('basketOrders:marginMode')}</div>
            <div className={styles['margin-value']}>
              {t(`portfolioMargin:${marginMode}`)}
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className={styles.buttonArea}>
        <DeltaButton
          data-testid="placeorder-button"
          className={styles['placeorder-button']}
          onClick={() => handlePlaceOrderClick()}
          testId="placeorder-button"
          loading={showLoader}
          text={t('trading:placeOrder')}
        />
      </div>
    </div>)
  );
};

export default IsolatedBasketPlaceOrderSection;
