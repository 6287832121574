import React, { useState } from 'react';

import classnames from 'classnames';
import styled from 'styled-components';

import { device } from '../helpers/screenSizes';

const ToggleWrapper = styled.section`
  .switch {
    display: inline-block;
    height: 10px;
    position: relative;
    width: 40px;
    margin-top: 8px;

    @media ${device.down.sm} {
      height: 8px;
      width: 25px;
      margin-top: 7px;
    }
  }

  .switch input {
    display: none;
  }

  .slider {
    background-color: ${props => props.theme.positive};
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider.active {
    background-color: ${props => props.theme.negative};
  }
  .slider:before {
    background-color: ${props => props.theme.primaryText};
    bottom: -4px;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    transition: 0.4s;
    width: 20px;

    @media ${device.down.sm} {
      width: 16px;
      height: 16px;
      bottom: -3px;
      left: -6px;
    }
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .toggleText {
    color: ${props => props.theme.secondaryText};
    cursor: pointer;
    font-size: 14px;

    @media ${device.down.sm} {
      font-size: 12.1px;
    }
  }

  .selectText {
    color: ${props => props.theme.secondaryText};
  }
`;

const ToggleButton = ({ leftText, rightText, onClick }) => {
  const [isOnState, setIsOnState] = useState(false);

  const onToggleClick = () => {
    setIsOnState(!isOnState);
    onClick();
  };

  return (
    <ToggleWrapper className="d-flex">
      <div
        data-testid="ToggleleftText"
        className={classnames('pe-3 toggleText', { selectText: !isOnState })}
        onClick={onToggleClick}
      >
        {leftText}
      </div>
      <label className="switch" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          checked={isOnState}
          onChange={onToggleClick}
        />
        <div className={classnames('slider round', { active: isOnState })} />
      </label>
      <div
        data-testid="ToggleRightText"
        className={classnames('ps-3 toggleText', { selectText: isOnState })}
        onClick={onToggleClick}
      >
        {rightText}
      </div>
    </ToggleWrapper>
  );
};

export default ToggleButton;
