import React from 'react';

import { useRouteMatch } from 'react-router-dom';

type Logo = 'full-dark' | 'full-light' | 'half-dark' | 'half-light';

interface RouteParams {
  type: Logo;
}

const getLogoUrl = (logo: Logo) => {
  if (logo === 'full-dark') {
    return 'delta-logo-full-dark.svg';
  }
  if (logo === 'full-light') {
    return 'delta-logo-full-light.svg';
  }
  if (logo === 'half-dark') {
    return 'delta-logo-half-dark.svg';
  }
  if (logo === 'half-light') {
    return 'delta-logo-half-light.svg';
  }
  return 'delta-logo-full-dark.svg';
};

const Logos = () => {
  const route = useRouteMatch<RouteParams>();

  const { type } = route?.params || { type: 'full-dark' };

  const logoSrc = getLogoUrl(type);

  return (
    (<div data-palette="Logos">
      <img src={`${process.env.PUBLIC_URL}/logo/${logoSrc}`} alt={logoSrc} />
    </div>)
  );
};

export default Logos;
