const greeksPrecision: {
  [symbol: string]: {
    theta: number;
    vega: number;
    gamma: number;
  };
} = {
  BTC: {
    theta: 2,
    vega: 2,
    gamma: 6,
  },
  ETH: {
    theta: 2,
    vega: 2,
    gamma: 5,
  },
  XRP: {
    theta: 5,
    vega: 5,
    gamma: 2,
  },
  SOL: {
    theta: 4,
    vega: 4,
    gamma: 4,
  },
  AVAX: {
    theta: 4,
    vega: 4,
    gamma: 4,
  },
  MATIC: {
    theta: 5,
    vega: 5,
    gamma: 2,
  },
  BNB: {
    theta: 3,
    vega: 3,
    gamma: 4,
  },
  LINK: {
    theta: 3,
    vega: 3,
    gamma: 3,
  },
  USDT: {
    theta: 2,
    vega: 2,
    gamma: 2,
  },
};

export default greeksPrecision;
