/* eslint-disable no-nested-ternary */

import { numberPrettified, isNan } from 'helpers';
import { getDeltaValue } from 'helpers/greeksCalculatorUSDT';
import useOpenPositionHelpers from 'hooks/componentHooks/useOpenPositionHelpers';
import {
  deltaPriceSelectorById,
  selectedProductIdSelector,
  spotPriceSelectorBySymbol,
  spotPriceState,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import { type OpenPosition } from 'types/IOpenPosition';

function usePositionsDeltaSummation(positions: OpenPosition[]) {
  const { getNotionalValue, isFuturesType } = useOpenPositionHelpers();
  const selectedProductId = useAppSelector(state => selectedProductIdSelector(state));
  const selectedProdSpotPrice = spotPriceState();

  const getCombinedDeltaValues = positions?.reduce((result, position) => {
    const { value: notionalSize } = getNotionalValue({
      entry_price: position?.entry_price,
      size: position?.size,
      product: position?.product,
    });

    const isLong = typeof position?.size !== 'undefined' && position?.size > 0;

    const isFutures =
      typeof position.product.contract_type !== 'undefined' &&
      isFuturesType(position.product.contract_type);

    const price = deltaPriceSelectorById(String(position?.product?.id));
    // eslint-disable-next-line no-nested-ternary
    const deltaContractValue = isFutures
      ? 1
      : Number.isNaN(Number(price))
      ? 0.0
      : Number(price);

    const spotPrice: number =
      selectedProductId === String(position?.product?.id)
        ? (selectedProdSpotPrice as number)
        : (spotPriceSelectorBySymbol(
            position?.product?.spot_index?.symbol as string
          ) as number);

    const deltaInUSDT = getDeltaValue({
      longOrShort: isLong ? 'long' : 'short',
      deltaContractValue,
      notionalInUSDT: Number(notionalSize),
      position,
      indexPrice: spotPrice,
    });

    // eslint-disable-next-line no-param-reassign
    result += Number(deltaInUSDT || 0);
    return result;
  }, 0);

  return isNan(getCombinedDeltaValues)
    ? '-'
    : Number(getCombinedDeltaValues) === 0
    ? '0.00'
    : numberPrettified(getCombinedDeltaValues);
}

export default usePositionsDeltaSummation;
