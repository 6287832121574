import React from 'react';

import { compose, pure } from 'recompose';

// import PropTypes from 'PropTypes';
import styles from './table.module.scss';

const Leverage = props => {
  const { product } = props;
  const { initial_margin } = product;
  const leverage = Math.trunc(100 / Number(initial_margin));
  return <span className={styles.leverage} data-palette="Leverage">{leverage}x</span>;
};

// Leverage.propTypes = {
//   product: PropTypes.marketProducts.isRequired,
// };

const enhance = compose(pure);

export default enhance(Leverage);
