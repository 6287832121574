import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { isOptions } from 'helpers';
import { OpenPosition } from 'types/IOpenPosition';
import { updatePositionAutoTopup } from 'actions/trade';
import useToggle from 'hooks/useToggle';

import useOpenPositionHelpers from './useOpenPositionHelpers';

interface AutoMarginTopUpParams {
  product: OpenPosition['product'];
  size: OpenPosition['size'];
}

const useAutoMarginTopUp = ({ product, size }: AutoMarginTopUpParams) => {
  const dispatch = useDispatch();
  const { isIrsType, isSpreadsType, isFuturesType } = useOpenPositionHelpers();

  const [loading, toggleLoading] = useToggle(false);

  const { contract_type } = product;

  const isIrs = isIrsType(contract_type);
  const isSpreads = isSpreadsType(contract_type);
  const isFutures = isFuturesType(contract_type);

  const isInvalid = isOptions(product.contract_type) && size > 0;

  const handleUpdatePositionAutoTopUp = useCallback(
    async (data: { auto_topup: boolean; product_id: number }) => {
      if (isInvalid) {
        return undefined;
      }
      try {
        toggleLoading();
        await dispatch(updatePositionAutoTopup(data));
      } catch {
      } finally {
        toggleLoading();
      }
    },
    [dispatch]
  );

  const showField =
    isIrs ||
    isSpreads ||
    isFutures ||
    (isOptions(product.contract_type) && size < 0);

  return {
    showField,
    loading,
    isInvalid,
    handleUpdatePositionAutoTopUp,
  };
};

export default useAutoMarginTopUp;
