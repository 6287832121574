/* eslint-disable test-selectors/onChange */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import styled from 'styled-components';

import { device } from '../helpers/screenSizes';

const DropDown = styled.div`
  padding-top: 16px;

  .kyc-country-dropdown {
    padding-top: 0;
    margin-top: 7px;
    &.verification-country-dropdown {
      margin-top: -7px !important;
      &.disable {
        .input {
          pointer-events: none;
          cursor: none;
          svg {
            display: none;
          }
        }
      }
      .paper {
        width: 470px;
      }
      .input {
        width: 470px;
        cursor: pointer;
        padding: 0;
      }
      .valueContainer {
        padding: 0 18px;
        @media screen and ${device.down.sm} {
          padding: 0;
        }
      }
      .paper div {
        max-height: 300px !important;
      }
      .placeholder {
        color: ${props => props.theme.primaryText} !important;
        font-family: var(--fontRegular);
        margin-left: 2px;
      }
      .singleValue {
        margin-left: 2px;
        margin-top: 4px;
      }
      @media screen and ${device.down.md} {
        &.mobile-dropdown {
          .input {
            width: 100% !important;
          }
        }
        .input {
          width: 100% !important;
          max-width: 100% !important;
          padding-left: 15px;
        }
        .paper {
          width: 100%;
          div {
            width: 100%;
          }
        }
      }
    }
    .input {
      height: 42px;
      width: 470px;
      border: 1px solid var(--inputBorderColor);
      background: var(--inputBackground);
      border-radius: var(--inputBorderRadius);
      font-family: var(--fontRegular);
      font-size: 14px;
      color: ${props => props.theme.secondaryText} !important;
      padding: 0 10px;
    }
    .underline-color {
      &:before,
      &:after {
        border-bottom: none !important;
      }
    }
    .valueContainer {
      padding: 0 10px;
      align-items: center;
      justify-content: flex-start;
      @media screen and ${device.down.sm} {
        padding: 0;
      }
    }
    .placeholder,
    .singleValue {
      margin-top: 2px;
      font-size: 14px;
    }
    .singleValue {
      margin-top: 9px;
    }
    .paper {
      background: var(--dropdownBackground);
      box-shadow: var(--dropdownBoxShadow);
      border-radius: var(--dropdownBorderRadius);
      margin-top: 3px;
      div {
        max-height: 170px;
        @media screen and ${device.down.sm} {
          width: 100%;
        }
      }
    }
    .option-text {
      font-family: var(--fontRegular);
      font-size: 14px;
      color: ${props => props.theme.primaryText};
      padding: 10px 20px;
    }
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      color: ${props => props.theme.primaryTheme} !important;
    }
  }
  .root {
    flexgrow: 1;
  }
  .input {
    display: flex;
    padding: 2px 0px;
    color: ${props => props.theme.secondaryText} !important;
    svg {
      cursor: pointer;
    }
  }
  .label-color {
    color: ${props => props.theme.secondaryText} !important;
  }

  .underline-color {
    &:before,
    &:after {
      border-bottom: 1px solid ${props => props.theme.secondaryText} !important;
    }
  }

  .valueContainer {
    display: flex;
    flexwrap: wrap;
    flex: 1;
    alignitems: center;
    overflow: hidden;
    color: ${props => props.theme.primaryText} !important;

    input {
      margin-top: 3px;
      /* @media screen and ${device.up.md} {
        margin-top: 5px;
      } */
    }
  }
  .singleValue {
    color: ${props => props.theme.primaryText} !important;
    font-size: 16px;
    margin-top: 10px;
  }
  .placeholder {
    position: absolute;
    color: ${props => props.theme.secondaryText} !important;
    font-size: 16px;
    margin-top: 10px;
  }
  .paper {
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.secondaryBackground};

    div {
      width: 100%;

      @media screen and ${device.down.sm} {
        width: 90%;
      }
    }
  }

  .option-text {
    &:hover {
      background-color: var(--dropdownItemHover) !important;
      color: ${props => props.theme.primaryTheme} !important;
    }
    white-space: normal;
    color: ${props => props.theme.primaryText} !important;
  }

  .css-bgvzuu-indicatorSeparator {
    display: none;
  }
`;

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} data-palette="inputComponent" />;
}

const Control = props => {
  return (
    <TextField
      fullWidth
      required
      InputLabelProps={{
        classes: {
          root: 'label-color',
          focused: 'label-color',
        },
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: 'input',
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
        classes: {
          root: 'label-color',
          focused: 'label-color',
          underline: 'underline-color',
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className="option-text"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  return (
    <Typography className="placeholder" {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography className="singleValue" {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return <div className="valueContainer" data-palette="ValueContainer">{props.children}</div>;
};

const Menu = props => {
  return (
    <Paper square className="paper" {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      single: null,
    };
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
    this.props.onSelect(value);
  };

  render() {
    const { classes, option, placeholder } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: 'var(--primaryText)',
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <DropDown className="root">
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={option}
            components={components}
            value={this.state.single}
            onChange={this.handleChange('single')}
            placeholder={placeholder || 'Select the option ..'}
            className={this.props.className || ''}
            isClearable
            {...this.props}
          />
        </NoSsr>
      </DropDown>
    );
  }
}

export default IntegrationReactSelect;
