import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { emailVerificationUsingLink } from 'actions/user';
// import routes from 'constants/routes';
import Render from 'components/render';
import { parseErrorMsg } from 'helpers';
import { Box } from 'UIKit';

import styles from './emailVerificationWithLink.module.scss';
import LinkExpiredText from './LinkExpiredText';
import { EmailVerificationPayloadObject } from './types';
import UserAlreadyVerified from './UserAlreadyVerified';
import VerificationInProgress from './VerificationInProgress';
import AuthHeader from '../Common/Header';

const EmailVerificationWithLink = () => {
  const [isLinkExpired, setLinkExpired] = useState(false);
  const [isVerificationInProgress, setVerificationInProgress] = useState(false);
  const [isUserAlreadyVerified, setUserAlreadyVerified] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const payload = {} as EmailVerificationPayloadObject;
  searchParams.forEach((value, key) => {
    payload[key] = value;
  });

  useEffect(() => {
    dispatch(emailVerificationUsingLink(payload))
      .then(() => {
        setVerificationInProgress(true);
      })
      .catch(err => {
        const errObj = parseErrorMsg(err);
        if (errObj.error.code === 'already_verified') {
          setUserAlreadyVerified(true);
          return;
        }
        if (!errObj.success && errObj.error.code === 'invalid_token') {
          setLinkExpired(true);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box className={styles.container}>
      <AuthHeader variant="signup" />
      <Render when={isLinkExpired}>
        <LinkExpiredText />
      </Render>
      <Render when={isVerificationInProgress}>
        <VerificationInProgress email={payload.email} />
      </Render>
      <Render when={isUserAlreadyVerified}>
        <UserAlreadyVerified />
      </Render>
    </Box>
  );
};

export default EmailVerificationWithLink;
