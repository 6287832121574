import { ACTION_TYPE, SignUpState } from './interface';
import commonActions from '../Common/action';
import { ActionKind, ErrorInfo, OAuthProvider } from '../Common/interface';

const actions = {
  setEmail: (value: string, validator: (password: string) => void) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetEmail,
      payload: value,
      validator,
    };
    return action;
  },

  setPassword: (value: string, validator: (password: string) => void) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetPassword,
      payload: value,
      validator,
    };
    return action;
  },

  setCountry: (value: string) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetCountry,
      payload: value,
    };
    return action;
  },

  setReferralCode: (value: string) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetReferralCode,
      payload: value,
    };
    return action;
  },

  beginPasswordValidation: () => {
    const action: ACTION_TYPE = {
      type: ActionKind.BeginPasswordValidation,
    };
    return action;
  },

  signupPre2FASuccess: result => {
    const action: ACTION_TYPE = {
      type: ActionKind.SignupPre2FASuccess,
      payload: result,
    };
    return action;
  },

  submitClick: (
    signup: (state: SignUpState) => void,
    validator: (password: string) => void
  ) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SubmitClick,
      payload: { signup },
      validator,
    };
    return action;
  },

  oAuthSignUpInit: (
    oAuthSignup: (state: SignUpState) => void,
    oAuthProvider: OAuthProvider
  ) => {
    const action: ACTION_TYPE = {
      type: ActionKind.OAuthSignUpInit,
      payload: { oAuthSignup, oAuthProvider },
    };
    return action;
  },

  oAuthSignUpError: (error: ErrorInfo) => {
    const action: ACTION_TYPE = {
      type: ActionKind.OAuthSignUpError,
      payload: { error },
    };
    return action;
  },

  apiBlocked: () => {
    const action: ACTION_TYPE = {
      type: ActionKind.ApiBlocked,
      payload: null,
    };
    return action;
  },

  registerSuccess: () => {
    const action: ACTION_TYPE = {
      type: ActionKind.RegisterSuccess,
    };
    return action;
  },

  registerFailure: (
    errorCode: string,
    schemaErrorMessage: string,
    validator: (password: string) => void
  ) => {
    const action: ACTION_TYPE = {
      type: ActionKind.RegisterFailure,
      payload: { errorCode, schemaErrorMessage },
      validator,
    };
    return action;
  },

  referralCodeIsValid: (discountPercentage: number) => {
    return {
      type: ActionKind.ReferralCodeIsValid,
      payload: discountPercentage,
    } as ACTION_TYPE;
  },

  referralCodeIsInValid: () => {
    return {
      type: ActionKind.ReferralCodeIsInValid,
    } as ACTION_TYPE;
  },

  setValidationIsLoading: (value: boolean) => {
    return {
      type: ActionKind.ReferralValidationIsLoading,
      payload: value,
    } as ACTION_TYPE;
  },

  reset: () => {
    const action: ACTION_TYPE = {
      type: ActionKind.ResetSignup,
    };
    return action;
  },

  ...commonActions,
};

export default actions;
