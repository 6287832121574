import styled from 'styled-components';

import { device } from 'helpers/screenSizes';

const PortfolioWrapper = styled.div`
  // padding: 24px;
  font-size: 0.75rem;
  overflow: scroll;
  max-height: 530px;
  color: ${props => props.theme.secondaryText};
  .error-container {
    margin-top: 20px;
    > div {
      margin-bottom: 0px;
    }
  }
  .margin-mode-info {
    padding: 0 24px 30px;
  }
  .portfolio-title {
    margin-top: 30px;
    margin-bottom: 15px;
    padding-left: 20px;
  }
  .section {
    padding-top: 0;
    margin-top: -4px;
  }
  .submit-btn {
    margin: 0px 20px 27px 0;
  }
  .not-eligible {
    text-align: center;
    background: ${props => props.theme.tertiaryBackground};
    border-radius: 2px;
    padding: 20px;
    margin: 20px;
    .error {
      color: ${props => props.theme.negative};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-top: -3px;
        margin-right: 7px;
      }
    }
    .desc {
      padding: 24px 0px 0;
      line-height: 30px;
      div {
        color: ${props => props.theme.secondaryText};
      }
    }
  }
  .portfolio-disabled {
    font-size: 0.875rem;
    justify-content: space-between;
  }
  .asset-selection,
  .mobile-dropdown {
    flex-wrap: wrap;
    gap: 13px;
    margin-left: 10px;
    .child {
      flex: 1 0 21%; /* explanation below */
      max-width: 70px;
      background-color: var(--separator);
      text-align: center;
      padding: 8px 13px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid transparent;
      &:hover,
      &.active {
        border: 0.8px solid ${props => props.theme.primaryTheme};
        color: ${props => props.theme.primaryText};
        font-weight: bold;
        //background-color: var(--primaryBackground);
      }
    }
    .desc {
      text-align: left;
      color: ${props => props.theme.highlight};
      margin-top: 20px;
      line-height: 20px;
      background: ${props => props.theme.separator};
      padding: 15px;
      margin-right: 20px;
      svg {
        margin-right: 10px;
        margin-top: -2px;
        height: 14px;
        width: 14px;
        path {
          fill: ${props => props.theme.highlight};
        }
      }
    }
  }
  .asset-selection {
    margin-left: 20px;
    .list {
      flex-wrap: wrap;
      gap: 13px;
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .change-asset-selection-wrapper {
    .header {
      margin-left: 5px;
      color: ${props => props.theme.secondaryText};
      .highlight {
        color: ${props => props.theme.primaryText};
        font-family: var(--fontRegular);
      }
      .left,
      .right {
        display: flex;
        align-items: center;
        // width: 50%;
        // color: #ffffff;
      }
      .left {
        .text {
          // margin-top: 17px;
          svg {
            margin-left: 95px;
            margin-right: 27px;
          }
        }
      }
      .right {
        .selected-coin {
          min-height: 36px;
          cursor: pointer;
          padding: 10px 14px;
          background: ${props => props.theme.secondaryBackground};
          margin: 7px 0;
          width: 98px;
          margin-left: 27px;
          svg {
            float: right;
            transform: rotate(90deg);
            &.active {
              path:nth-child(2) {
                fill: ${props => props.theme.primaryTheme};
              }
              transform: rotate(-90deg);
            }
          }
          &.selection-open {
            svg {
              transform: rotate(270deg);
              path:last-child {
                fill: ${props => props.theme.primaryTheme};
              }
            }
          }
        }
      }
    }
    .change-asset-selection {
      gap: 0;
      flex-direction: row-reverse;
      margin-left: 0;
      .child {
        padding: 15px 26px;
        border: none;
        min-width: 79px;
        &:hover,
        &.selected {
          // background: #19232d;
          color: ${props => props.theme.primaryTheme};
        }
        .active {
          color: ${props => props.theme.primaryTheme};
        }
      }
    }
    .desc {
      margin-top: 25px;
      color: ${props => props.theme.secondaryText};
      padding: 0 13px;
      line-height: 20px;
      ul {
        line-height: 20px;
        margin-left: 17px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media ${device.down.md} {
    padding: 0;
    max-height: 600px;
    &.asset-selection-open {
      padding-top: 0;
    }
    .portfolio-mode-button {
      width: 95vw;
      height: 80px;
      padding-top: 20px;
      margin: 0 10px 0 10px;
      position: absolute;
      bottom: 0;
      background: var(--primaryBackground);
      button.submit-btn {
        width: 95vw !important;
        margin: 0 10px !important;
        height: 42px;
      }
    }
    .to-title {
      display: flex !important;
      margin-top: 42px;
      margin-bottom: 13px;
    }
    .mobile-dropdown {
      display: flex !important;
      margin-left: 0;
      .child {
        padding: 11px 26px;
        border-radius: 2px;
        &.selected {
          border: 1px solid ${props => props.theme.primaryTheme};
          color: ${props => props.theme.primaryTheme};
        }
      }
    }
    .asset-selection {
      gap: 14px;
      .child {
        max-width: 76px;
        padding: 11px 13px;
      }
      .desc {
        margin-top: 26px;
        padding: 10px 16px;
        line-height: 20px;
      }
    }
    .change-asset-selection-wrapper {
      .header {
        display: block !important;
      }
      .right {
        display: none !important;
      }
      .left {
        display: block !important;
        .text {
          margin-top: 12px;
          span {
            padding: 11px 26px;
            background: ${props => props.theme.secondaryBackground};
            border: 1px solid ${props => props.theme.primaryTheme};
            border-radius: 2px;
            color: ${props => props.theme.primaryTheme};
          }
        }
        svg {
          display: none;
        }
      }
    }
    .action-btns {
      width: 100%;
      margin-top: 32px !important;
    }
  }
`;

const ErrorWrapper = styled.div`
  background: ${props => props.theme.separator};
  margin: 0 20px 40px;
  padding: 0;
  color: ${props => props.theme.negative};
  line-height: 20px;
  svg {
    height: 16px;
    width: 16px;
    // margin-top: -4px;
  }
`;

const MarginModeInfoWrapper = styled.div`
  // margin-top: 10px;
  font-size: 0.75rem;
  font-family: var(--fontRegular);
  @media ${device.down.md} {
    margin-top: 0;
  }
  .capitalise {
    text-transform: capitalize;
  }
  .title {
    border-bottom: 1px solid ${props => props.theme.separator};
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    // margin-top: -12px;
    padding-bottom: 10px;
  }
  section {
    padding-top: 20px;
    @media ${device.down.md} {
      padding-top: 30px;
    }
    .head {
      color: ${props => props.theme.secondaryText};
      margin-bottom: 15px;
    }
    .info {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      color: ${props => props.theme.primaryText};
      svg {
        width: 16px;
        height: 10px;
        margin-right: 12px;
        g {
          g {
            fill: ${props => props.theme.positive};
          }
        }
      }
    }
  }
`;

const ChangeAccountWrapper = styled.div`
  color: ${props => props.theme.secondaryText};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 14px;
  .tile-wrapper {
    overflow: auto;
    height: 400px;
    .tile {
      margin: 11px 20px;
      background: ${props => props.theme.separator};
      padding: 15px;
      cursor: pointer;
      border: 1px solid ${props => props.theme.separator};
      svg {
        path {
          fill: ${props => props.theme.tertiaryBackground};
        }
      }
      &:hover {
        border: 1px solid ${props => props.theme.primaryTheme};
      }
      &.active {
        border: 1px solid ${props => props.theme.primaryTheme};
        background: var(--separator2);
        .tick-round-svg {
          path {
            fill: ${props => props.theme.primaryTheme};
          }
        }
      }
      &.active {
        .active-svg {
          path {
            fill: ${props => props.theme.primaryTheme};
          }
        }
      }
    }
    .highlight {
      color: ${props => props.theme.primaryText};
    }
    .acc-name {
      margin-bottom: 21px;
    }
  }
  .submit-btn {
    width: auto;
    // min-width: 162px;
    height: 36px;
    margin: 30px 20px 30px 0;
  }
  @media ${device.down.md} {
    .tile-wrapper {
      height: calc(100vh - 170px);
      margin-bottom: 80px;
    }
    .change-account-submit-btn {
      width: 100vw;
      height: 80px;
      padding-top: 20px;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      background: var(--primaryBackground);
      button.submit-btn {
        width: 95vw !important;
        margin: 0 10px !important;
        bottom: 15px;
      }
    }
  }
`;

const ButtonFooter = styled.div`
  padding: 0 20px 27px 0px;
  @media ${device.down.md} {
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100vw;
  }
`;

export {
  PortfolioWrapper,
  ErrorWrapper,
  MarginModeInfoWrapper,
  ChangeAccountWrapper,
  ButtonFooter,
};
