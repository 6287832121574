import { useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty, isNan, searchConditions } from 'helpers';
import { DateType, getDateTime } from 'helpers/day';
import useDebounce from 'hooks/useDebounce';
import { ContractType, Product } from 'types/IProducts';

interface SelectedStrikeGapInterface {
  all: boolean;
}

interface useOptionsCombosFilterStripProps {
  productList: Product[];
  setActiveProducts: (products: Product[]) => void;
  searchTerm: string;
}
const AllExpiry = 'All Expiry';

const useOptionsCombosFilterStrip = ({
  productList,
  setActiveProducts,
  searchTerm,
}: useOptionsCombosFilterStripProps) => {
  const underlyingAssets: Array<string> = productList.reduce(
    (acc: Array<string>, product: Product) => {
      if (
        product.contract_type === ContractType.OptionsCombos &&
        !acc.includes(product.underlying_asset?.symbol)
      ) {
        acc.push(product.underlying_asset?.symbol);
      }
      return acc;
    },
    []
  );

  const [selectedAsset, setSelectedAsset] = useState(underlyingAssets[0] || 'BTC');

  const expiryDates: Array<DateType> = useMemo(
    () =>
      productList
        .reduce(
          (acc: Array<DateType>, product: Product) => {
            if (
              product?.underlying_asset?.symbol === selectedAsset &&
              product?.settlement_time &&
              !acc.includes(product.settlement_time)
            ) {
              acc.push(product.settlement_time);
            }
            return acc;
          },
          [AllExpiry]
        )
        .sort((a: DateType, b: DateType) => {
          if (a === AllExpiry) return -1;
          if (b === AllExpiry) return 1;
          return getDateTime(a).diff(getDateTime(b));
        }),
    [productList, selectedAsset]
  );

  const [selectedComboType, setSelectedComboType] = useState('all');
  const [selectedFromStrike, setSelectedFromStrike] = useState<null | number>(null);
  const [selectedToStrike, setSelectedToStrike] = useState<null | number>(null);
  const [selectedExpiry, setSelectedExpiry] = useState<string | DateType>(AllExpiry);
  const [selectedStrikeGaps, setSelectedStrikeGaps] =
    useState<SelectedStrikeGapInterface>({
      all: true,
    });

  const debouncedFromStrike = useDebounce(selectedFromStrike, 500);
  const debouncedToStrike = useDebounce(selectedToStrike, 500);

  const updateSelectedStrikeGaps = useCallback(
    ({ selectedValue }) => {
      const newState = { ...selectedStrikeGaps };

      if (selectedValue === 'all') {
        Object.keys(newState).forEach(strikeGapKey => {
          newState[strikeGapKey] = strikeGapKey === 'all';
        });
      } else {
        newState[selectedValue] = !selectedStrikeGaps[selectedValue];
        // all should be true only if all other filter options are unchecked
        newState.all = Object.values(newState).every(value => !value);
      }

      setSelectedStrikeGaps(newState);
    },
    [selectedStrikeGaps]
  );

  const filterProductList = useCallback(() => {
    let filteredProductList: Product[] = productList;
    if (!isEmpty(searchTerm)) {
      filteredProductList = productList.filter(product =>
        searchConditions(product, searchTerm)
      );
    }

    if (selectedAsset !== 'all') {
      filteredProductList = filteredProductList.filter(
        product => product.underlying_asset?.symbol === selectedAsset
      );
    }

    if (selectedComboType !== 'all') {
      filteredProductList = filteredProductList.filter(
        product => product.product_specs?.combo_type === selectedComboType
      );
    }

    if (selectedExpiry !== AllExpiry) {
      filteredProductList = filteredProductList.filter(
        product => product.settlement_time === selectedExpiry
      );
    }

    // from strike input filter only
    if (debouncedFromStrike && !debouncedToStrike) {
      filteredProductList = filteredProductList.filter(
        product =>
          Number(product?.product_specs?.legs[0]?.strike_price) >=
            Number(debouncedFromStrike) &&
          Number(product?.product_specs?.legs[1]?.strike_price) >=
            Number(debouncedFromStrike)
      );
    }

    // to strike input filter only
    if (debouncedToStrike && !debouncedFromStrike) {
      filteredProductList = filteredProductList.filter(
        product =>
          (product?.product_specs?.legs[0]?.strike_price as unknown as number) <=
            Number(debouncedToStrike) &&
          (product?.product_specs?.legs[1]?.strike_price as unknown as number) <=
            Number(debouncedToStrike)
      );
    }

    // both from & to strike input fields act as a range between
    if (debouncedFromStrike && debouncedToStrike) {
      filteredProductList = filteredProductList.filter(product => {
        const legZeroStrike = Number(product?.product_specs?.legs?.[0]?.strike_price);
        const legOneStrike = Number(product?.product_specs?.legs?.[1]?.strike_price);

        const fromStrike = Number(debouncedFromStrike);
        const toStrike = Number(debouncedToStrike);

        const legZeroInRange =
          legZeroStrike >= Math.min(fromStrike, toStrike) &&
          legZeroStrike <= Math.max(fromStrike, toStrike);
        const legOneInRange =
          legOneStrike >= Math.min(fromStrike, toStrike) &&
          legOneStrike <= Math.max(fromStrike, toStrike);

        return legZeroInRange && legOneInRange;
      });
    }

    // strike gap filter, all should be unselected
    // filter absolute leg1 & leg2 values with dropdown filter options
    if (!selectedStrikeGaps.all) {
      filteredProductList = filteredProductList.filter(product => {
        const legPrices = product?.product_specs?.legs?.map(leg =>
          parseInt(leg?.strike_price ?? '0', 10)
        );

        if (!legPrices || legPrices.length < 2) {
          return false;
        }

        const validPrices = legPrices.filter(price => !isNan(price));

        const pricePairs = validPrices.flatMap((price1, i) =>
          validPrices.slice(i + 1).map(price2 => Math.abs(price1 - price2))
        );

        return pricePairs.every(priceDiff => selectedStrikeGaps[priceDiff] === true);
      });
    }

    setActiveProducts(filteredProductList);
  }, [
    searchTerm,
    selectedAsset,
    selectedComboType,
    selectedExpiry,
    debouncedFromStrike,
    debouncedToStrike,
    productList,
    setActiveProducts,
    selectedStrikeGaps,
  ]);

  useEffect(() => {
    filterProductList();
  }, [
    selectedAsset,
    selectedExpiry,
    searchTerm,
    selectedComboType,
    debouncedFromStrike,
    debouncedToStrike,
    filterProductList,
    selectedStrikeGaps,
  ]);

  return {
    underlyingAssets,
    selectedAsset,
    setSelectedAsset,
    expiryDates,
    selectedExpiry,
    selectedStrikeGaps,
    setSelectedExpiry,
    selectedComboType,
    setSelectedComboType,
    selectedFromStrike,
    setSelectedFromStrike,
    selectedToStrike,
    setSelectedToStrike,
    setSelectedStrikeGaps,
    updateSelectedStrikeGaps,
  };
};

export default useOptionsCombosFilterStrip;
