import React from 'react';

import classNames from 'classnames';

import { AVAILABLE_THEMES, IS_INDIAN_EXCHANGE } from 'constants/constants';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { useTheme } from 'hooks';
import { DarkThemeIcon, LightThemeIcon } from 'styles/assets/icons';

import styles from './styles.module.scss';

const ToggleThemeSwitch = ({ className }: { className?: string }) => {
  const { isLightTheme, isDarkTheme, setTheme } = useTheme();

  const lightClassName = classNames({
    [styles.active]: isLightTheme,
  });

  const darkClassName = classNames({
    [styles.active]: isDarkTheme,
  });

  return (
    (<div
      className={`${styles.themeSwitchContainer} ${className}`}
      data-palette="ToggleThemeSwitch">
      <div className={lightClassName}>
        <button
          type="button"
          data-testid="light-theme-btn"
          onClick={() => {
            trackMixpanelEvent('Switched to Light theme');
            setTheme(
              IS_INDIAN_EXCHANGE ? AVAILABLE_THEMES.INDIAN_LIGHT : AVAILABLE_THEMES.LIGHT
            );
          }}
        >
          <LightThemeIcon />
        </button>
      </div>
      <div className={darkClassName}>
        <button
          type="button"
          data-testid="dark-theme-btn"
          onClick={() => {
            trackMixpanelEvent('Switched to Dark theme');
            setTheme(
              IS_INDIAN_EXCHANGE ? AVAILABLE_THEMES.INDIAN_DARK : AVAILABLE_THEMES.DARK
            );
          }}
        >
          <DarkThemeIcon />
        </button>
      </div>
    </div>)
  );
};

export default ToggleThemeSwitch;
