/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { isNan, numberPrettified } from 'helpers';
import { getVegaValue } from 'helpers/greeksCalculatorUSDT';
import { vegaPriceSelectorById } from 'selectors';
import { type OpenPosition } from 'types/IOpenPosition';

import useOpenPositionHelpers from '../useOpenPositionHelpers';

function usePositionsVegaSummation(positions: OpenPosition[]) {
  const { getNotionalValue } = useOpenPositionHelpers();

  const getCombinedVegaValues = positions.reduce((result, position) => {
    const isLong = (position.size ?? 0) > 0;

    const { value: notionalSize, symbol } = getNotionalValue({
      entry_price: position.entry_price,
      size: position.size,
      product: position.product,
    });

    const price = vegaPriceSelectorById(String(position?.product_id));
    const vegaContractValue = isNan(Number(price)) ? 0.0 : Number(price);
    const vegaInUSDT = getVegaValue({
      vegaContractValue,
      notional: Number(notionalSize),
      symbol,
      longOrShort: isLong ? 'long' : 'short',
    });

    result += Number(vegaInUSDT || 0);

    return result;
  }, 0);

  return isNan(getCombinedVegaValues)
    ? '-'
    : Number(getCombinedVegaValues) === 0
    ? '0.00'
    : numberPrettified(getCombinedVegaValues);
}

export default usePositionsVegaSummation;
