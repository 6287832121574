import { LOCATION_CHANGE } from 'connected-react-router';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { EMPTY, merge, of, timer } from 'rxjs';
import {
  exhaustMap,
  filter,
  find,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';
import UaParser from 'ua-parser-js';

import {
  createLoginViaQrToken,
  getLoginViaQrTokenDetails,
  qrLoginAuthorizedByUser,
  setLoginViaQrTokenStatus,
  setUserAuthorizedByLoginViaBiometrics,
  setUserAuthorizedByLoginViaQr,
} from 'actions/auth';
import { getAccountToken } from 'actions/user';
import AUTH_ACTION_TYPES, {
  LoginViaBiometricsActionTypes,
  LoginViaQrActionTypes,
} from 'actionTypes/auth';
import { postMessageToMobileApp } from 'components/app/helpers';
import * as mixPanel from 'helpers/mixpanel-init';
import { equals, gt, has, hasPath } from 'helpers/ramda';
import { LoginViaQrTokenStatus, QrAuthStatus } from 'types/Auth';
import IState, { CustomAction } from 'types/Istore';

const MINUTE_IN_MS = 1000;
const POLLING_FOR_TOKEN_DETAILS_INTERVAL = MINUTE_IN_MS * 5;

const getDeviceName = (): string => {
  const parser = new UaParser();
  const { browser, os } = parser.getResult();

  return `${browser?.name} (${os?.name})`;
};

const shouldStopPollingEffects = (action$: CustomAction) => {
  return merge(
    action$.ofType(LoginViaQrActionTypes.LOGIN_AUTHORIZED),
    action$.ofType(LOCATION_CHANGE),
    2
  );
};

// #region Login Via QR
const openLoginViaQrMobileScannerEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(LoginViaQrActionTypes.OPEN_SCANNER),
    map(() => {
      mixPanel.trackMixpanelEvent('Scan icon clicked', {});

      return postMessageToMobileApp('OPEN_QR_LOGIN_SCANNER', {});
    })
  );

const createLoginViaQrTokenEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(LoginViaQrActionTypes.REFRESH_TOKEN),
    filter(({ payload }: CustomAction) => has('recaptcha', payload)),
    switchMap(({ payload }) => payload.recaptcha.executeAsync()),
    map((recaptchaToken: string) =>
      createLoginViaQrToken(getDeviceName(), recaptchaToken)
    )
  );

const startTokenExpiryCountdownEpic = (
  action$: CustomAction,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(AUTH_ACTION_TYPES[LoginViaQrActionTypes.CREATE_TOKEN]?.SUCCESS),
    filter(({ result }: CustomAction) => hasPath(['result', 'expires_at'], result)),
    switchMap(({ result }: CustomAction) => {
      const expiryTimeInMs = result.result.expires_at * MINUTE_IN_MS;

      return timer(0, MINUTE_IN_MS).pipe(
        find(() => gt(Date.now(), expiryTimeInMs)),
        takeUntil(shouldStopPollingEffects(action$)),
        takeWhile(() =>
          equals(state$.value.auth.login.viaQr.status, LoginViaQrTokenStatus.LOADED)
        ),
        map(() => setLoginViaQrTokenStatus(LoginViaQrTokenStatus.SHOULD_REFRESH))
      );
    })
  );

const startPollingForTokenDetailsEpic = (
  action$: CustomAction,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(AUTH_ACTION_TYPES[LoginViaQrActionTypes.CREATE_TOKEN]?.SUCCESS),
    filter(({ result }: CustomAction) => hasPath(['result', 'token'], result)),
    switchMap(({ result }: CustomAction) =>
      timer(0, POLLING_FOR_TOKEN_DETAILS_INTERVAL).pipe(
        map(() => getLoginViaQrTokenDetails(result.result.token)),
        takeUntil(shouldStopPollingEffects(action$)),
        takeWhile(() =>
          equals(state$.value.auth.login.viaQr.status, LoginViaQrTokenStatus.LOADED)
        )
      )
    )
  );

const checkTokenAuthorizationByUserEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(AUTH_ACTION_TYPES[LoginViaQrActionTypes.GET_TOKEN_DETAILS]?.SUCCESS),
    mergeMap((action: CustomAction) => {
      const { result } = action;

      if (equals(result.result.status, QrAuthStatus.APPROVED)) {
        const { user } = result.result;
        mixPanel.identifyUser(String(user.id));

        mixPanel.trackMixpanelEvent('Authorize clicked', {});

        return of(qrLoginAuthorizedByUser({ user }));
      }

      return EMPTY;
    })
  );

const setUserOnLoginViaQrAuthorizationEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(LoginViaQrActionTypes.LOGIN_AUTHORIZED),
    filter(({ payload }: CustomAction) => has('user', payload)),
    map(({ payload }: CustomAction) => {
      mixPanel.trackMixpanelEvent('Login is successful through app scan', {
        page_name: 'Sign-in',
        $email: payload.user.email,
        is_loggedin: true,
      });

      return setUserAuthorizedByLoginViaQr({ user: payload.user });
    })
  );
// #endregion

// #region Login Via Biometrics
const setUserOnBiometricsAuthorizationEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(LoginViaBiometricsActionTypes.LOGIN_AUTHORIZED),
    filter(({ payload }: CustomAction) => has('user', payload)),
    map(({ payload }: CustomAction) => {
      return setUserAuthorizedByLoginViaBiometrics({ user: payload.user });
    })
  );

const switchToSubAccountOnBiometricsAuthorizationEpic = (
  action$: CustomAction,
  state$
) => {
  return action$.pipe(
    ofType(LoginViaBiometricsActionTypes.SWITCH_TO_SUBACCOUNT),
    filter(({ payload }: CustomAction) => has('userId', payload)),
    exhaustMap(({ payload }: CustomAction) =>
      state$.pipe(
        filter(({ user }) => Boolean(user?.token)),
        take(1),
        map(() => getAccountToken({ sub_account_user_id: payload.userId }))
      )
    )
  );
};
// #endregion

export default combineEpics(
  // #region Login Via QR
  openLoginViaQrMobileScannerEpic,
  createLoginViaQrTokenEpic,
  startTokenExpiryCountdownEpic,
  startPollingForTokenDetailsEpic,
  checkTokenAuthorizationByUserEpic,
  setUserOnLoginViaQrAuthorizationEpic,
  // #endregion

  // #region Login Via Biometrics
  setUserOnBiometricsAuthorizationEpic,
  switchToSubAccountOnBiometricsAuthorizationEpic
  // #endregion
);
