/* eslint-disable react/no-array-index-key */
import { useRef, SVGProps, ReactNode, useCallback } from 'react';

import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import type { ControlledMenuProps, MenuState } from '@szhsin/react-menu';
import cn from 'classnames';

import { useLockedBody } from 'hooks';
import { ArrowDown } from 'styles/assets/icons';
import { MuiCheckBox } from 'UIKit';

import styles from './dropdown.module.scss';

export interface FilterDropDownProps {
  menuState: {
    state?: MenuState;
    endTransition: () => void;
  };
  toggleMenu: (isOpen?: boolean) => void;
  onCloseMenu?: () => void;
  label?: ReactNode;
  options: Option[];
  labelIcon?: SVGProps<SVGSVGElement> | ReactNode;
  selectionText?: string;
  closeOnSelect?: boolean;
  styleOverrideClasses?: Record<string, string>;
  showChevron?: boolean;
  children?: ReactNode;
  testId: string;
  titleTooltip?: string;
  autoPosition?: boolean;
  className?: string;
  btnClassName?: string;
  showActiveIcon?: boolean;
  overflow?: ControlledMenuProps['overflow'];
}

export interface Option {
  label?: ReactNode;
  checked?: boolean;
  id?: string | number;
  onClick?: () => void;
  checkbox?: boolean;
  optionType?: string;
  icon?: SVGProps<SVGSVGElement> | ReactNode;
  hide?: boolean;
  key?: string | number;
  dataTestId: string;
  className?: string;
}

const FilterDropDown = ({
  menuState,
  toggleMenu,
  onCloseMenu,
  label,
  options,
  selectionText,
  labelIcon,
  closeOnSelect,
  // Accepting various style overrides, this can be easily extended.
  styleOverrideClasses: soc = {},
  showChevron = true,
  children,
  testId,
  autoPosition,
  titleTooltip,
  overflow,
  className = '',
  btnClassName,
  showActiveIcon = true,
}: FilterDropDownProps) => {
  const anchorProps = useClick(menuState?.state, toggleMenu);
  const buttonRef = useRef(null);

  const isOpen = menuState?.state === 'open';

  useLockedBody(isOpen);

  const onMenuClose = useCallback(() => {
    if (typeof onCloseMenu === 'function') {
      onCloseMenu();
    } else {
      toggleMenu(false);
    }
  }, [onCloseMenu, toggleMenu]);

  return (
    (<div
      className={cn(styles.parent, {
        [className]: className,
      })}
      title={titleTooltip}
      data-palette="FilterDropDown">
      {/* Menu Button */}
      <button
        type="button"
        data-testid={`${testId || ''}`}
        ref={buttonRef}
        // onMouseDown={() => toggleMenu()}
        className={cn(styles.button, {
          [soc.button]: soc.button,
          [styles.active]: isOpen,
          [styles.noGap]: !showChevron,
          [btnClassName as string]: btnClassName,
        })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...anchorProps}
      >
        {/* Label Icon */}
        {labelIcon && (
          <div
            className={cn(styles.iconContainer, {
              [soc.labelIcon]: !isOpen && showActiveIcon,
            })}
            data-palette="FilterDropDown">
            {labelIcon}
          </div>
        )}
        {/* Label Section */}
        <div className={cn(styles.labelSection, soc.labelSection)}>
          {/* Label */}
          {label && (
            <span
              className={cn(styles.label, soc.label, { [soc.labelActive]: isOpen })}
              data-palette="FilterDropDown">
              {label}
              {selectionText && selectionText.length !== 0 ? ':' : ''}
            </span>
          )}
          {/* Selection Text */}
          {selectionText && (
            <span
              className={cn(styles.label, styles.selectionText, soc.selectionText)}
              data-palette="FilterDropDown">
              {selectionText}
            </span>
          )}
          {/* Arrow Up/Down */}
          {showChevron && (
            <ArrowDown
              className={cn({
                [styles.arrowUp]: isOpen,
                [soc.arrowUp]: isOpen && soc?.arrowUp,
                [styles.arrowDown]: !isOpen,
                [soc.arrowDown]: !isOpen && soc?.arrowDown,
              })}
            />
          )}
        </div>
      </button>
      {/* Dropdown */}
      <ControlledMenu
        anchorRef={buttonRef}
        onClose={onMenuClose}
        menuClassName={cn(styles.dropdown, soc.dropDown)}
        position={autoPosition ? 'auto' : 'initial'}
        gap={8}
        data-testid={`list-${testId}`}
        overflow={overflow}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...menuState}
      >
        {options &&
          options.map((option, index) => {
            if (option.hide) return null;
            if (option.optionType === 'header') {
              return (
                <MenuItem
                  className={cn(
                    styles.optionLabel,
                    styles.headerOption,
                    soc.headerOption
                  )}
                  key={`${option.id}-${index}`}
                >
                  {option.label}
                </MenuItem>
              );
            }
            return (
              /* Dropdown Option */
              (<MenuItem
                data-testid={`${testId}-${
                  option.dataTestId || option.dataTestId || option.label
                }-option`}
                className={cn(
                  styles.dropdownOption,
                  soc.dropdownOption,
                  option.className,
                  {
                    [styles.active]: option.checked,
                  }
                )}
                key={`${option.id}-${index}`}
                onClick={e => {
                  if (option.onClick) option.onClick();
                  // eslint-disable-next-line no-param-reassign
                  e.keepOpen = !closeOnSelect;
                }}
              >
                <>
                  {/* Option Icon */}
                  {option.icon && (
                    <div className={styles.iconContainer} data-palette="FilterDropDown">{option.icon}</div>
                  )}
                  {option.checkbox && (
                    /* Option CheckBox */
                    (<MuiCheckBox checked={option.checked} />)
                  )}
                  {/* Option Label */}
                  <div
                    className={cn(styles.label, styles.optionLabel, soc.optionLabel)}
                    data-palette="FilterDropDown">
                    {option.label}
                  </div>
                </>
              </MenuItem>)
            );
          })}
        {children}
      </ControlledMenu>
    </div>)
  );
};

export type ControlledFilterDropDownProps = Omit<
  FilterDropDownProps,
  // Omit state management props because we want to manage it in a wrapperComponent over FilterDropDown.
  'menuState' | 'toggleMenu' | 'closeMenu'
> & {
  // Optional Callbacks for when menu is toggled or closed.
  onToggleMenu?: () => void;
  onCloseMenu?: () => void;
};

export default FilterDropDown;
