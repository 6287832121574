import { updateUserPreference } from 'actions/user';
import { isUserLoggedInSelector, userPreferencesSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { IUserKernel } from 'types/IUser';

import useToggle from './useToggle';

type ToggleFavorite = (
  id: number,
  onMarked?: () => void,
  onUnmarked?: () => void
) => void;

interface UpdateUserPreferenceResponse {
  result: IUserKernel;
  success: Boolean;
}

function useFavorite() {
  const userPreferences = useAppSelector(userPreferencesSelector);
  const isLoggedIn = useAppSelector(isUserLoggedInSelector);
  const [loading, toggleLoading] = useToggle(false);

  const favoritesIds = userPreferences?.favorites ?? [];
  const dispatch = useAppDispatch();

  const toggleFavorite: ToggleFavorite = (id, onMarked, onUnmarked) => {
    if (isLoggedIn) {
      // before marking/unmarking favorite check it's current state.
      const isFavorite = favoritesIds.includes(id);

      const newFavoritesIds = isFavorite
        ? favoritesIds.filter(productId => productId !== id)
        : favoritesIds.concat(id);
      toggleLoading();
      // Update favorites in preferences
      dispatch(
        updateUserPreference(
          {
            preferences: {
              favorites: newFavoritesIds,
            },
          },
          { disable_notification: true }
        )
      ).then((response: UpdateUserPreferenceResponse) => {
        toggleLoading();
        if (response.success) {
          if (!isFavorite && typeof onMarked === 'function') {
            // Optional callback when favorite is marked successfully.
            onMarked();
          } else if (typeof onUnmarked === 'function') {
            // Optional callback when favorite is un-marked successfully.
            onUnmarked();
          }
        }
      });
    }
  };

  return {
    favoritesIds,
    toggleFavorite,
    loading,
  };
}

export type { ToggleFavorite };
export default useFavorite;
