import React, { ReactNode } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Render from 'components/render';
import { noop } from 'helpers/utils';
import { CrossIcon } from 'styles/assets/icons';
import { AllUnionMemberKeys } from 'types/utils';
import Box from 'UIKit/Box/Box';

import IconButton from '../IconButton';
import MaterialDialog from '../MaterialDialog';
import classes from './materialConfirmDialog.module.scss';

const VARIANT = {
  LEGACY: 'legacy',
  V2: 'v2',
} as const;

type Variant = AllUnionMemberKeys<typeof VARIANT>;

interface BaseProps {
  open: boolean;
  onClose: () => void;
  title: string | ReactNode;
  confirmText?: string;
  children?: ReactNode;
  accessoryTitleLeft?: ReactNode;
  accessoryTitleRight?: ReactNode;
  disableConfirmButton?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  darkOverlay?: boolean;
  minHeight?: number;
  maxWidth?: number;
  marginTopInherit?: boolean;
  transition?: boolean;
  showHeader?: boolean;
  headerTestId?: string;
  showCrossIcon?: boolean;
  testId?: string;
  confirmClassName?: string;
  headerClassName?: string;
  containerClassName?: string;
  titleClassName?: string;
  titleRightContent?: ReactNode;
  bodyClassName?: string;
  contentClassName?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmBtnTestId?: string;
  variant?: Variant;
  noHeaderBorder?: boolean;
  disableScrollLock?: boolean;
  closeIconClassName?: string;
}

interface MaterialConfirmDialogDisabledFooter extends BaseProps {
  showFooter: false;
  showCancel?: never;
  onCancel?: never;
  onConfirm?: never;
  footerClassName?: never;
  cancelClassName?: never;
  loading?: boolean;
  hideConfirmButton?: boolean;
  showError?: boolean;
  error?: string;
  errorClassName?: string;
}

interface MaterialConfirmDialogEnabledFooter extends BaseProps {
  showFooter: true;
  footerClassName?: string;
  onCancel?: () => void;
  showCancel?: boolean;
  onConfirm: () => Promise<void> | void;
  cancelClassName?: string;
  loading?: boolean;
  hideConfirmButton?: boolean;
  showError?: boolean;
  error?: string;
  errorClassName?: string;
}

type MaterialConfirmDialogProps =
  | MaterialConfirmDialogDisabledFooter
  | MaterialConfirmDialogEnabledFooter;

/**
 * @summary Dialog used to confirm actions
 *
 * @typedef {Object} MaterialConfirmDialogProps
 * @param {boolean} transition - enable slide from bottom animation (default: true) - used in mobile mode
 * @param {boolean} marginTopInherit - when true, dialog will position itself at the center of the screen
 * @param {ReactNode} accessoryTitleRight - Adds an element to right side of the dialog header
 * @param {ReactNode} accessoryTitleLeft - Adds an element to left side of the dialog header
 *
 */
const MaterialConfirmDialog = ({
  open,
  onClose,
  onCancel,
  title,
  confirmText,
  onConfirm,
  loading,
  disableConfirmButton,
  showCancel,
  fullWidth,
  fullHeight,
  minHeight,
  maxWidth,
  darkOverlay,
  accessoryTitleRight,
  accessoryTitleLeft,
  marginTopInherit,
  transition,
  showHeader,
  showCrossIcon,
  showFooter,
  testId,
  headerTestId='',
  confirmClassName,
  cancelClassName,
  containerClassName,
  headerClassName,
  titleClassName,
  titleRightContent,
  bodyClassName,
  footerClassName,
  contentClassName,
  confirmButtonText,
  cancelButtonText,
  children,
  confirmBtnTestId,
  hideConfirmButton,
  variant = VARIANT.LEGACY,
  noHeaderBorder = false,
  disableScrollLock = false,
  showError = false,
  error = '',
  errorClassName = '',
  closeIconClassName = '',
}: MaterialConfirmDialogProps) => {
  const { t } = useTranslation(['common']);

  return (
    (<MaterialDialog
      open={open}
      onClose={onClose}
      darkOverlay={darkOverlay}
      transition={transition}
      fullWidth={fullWidth}
      data-testid={testId || 'material-confirm-dialog'}
      marginTopInherit={marginTopInherit}
      contentClassName={cx(contentClassName, {
        [classes.v2]: variant === 'v2',
        [classes.fullHeight]: fullHeight,
      })}
      disableScrollLock={disableScrollLock}
    >
      <div
        className={cx(classes.container, {
          [classes.fullWidth]: fullWidth,
          [containerClassName as string]: containerClassName,
        })}
        data-testid={`${testId}-container`}
        style={{
          minHeight,
          maxWidth,
        }}
      >
        <Render when={showHeader}>
          <div
            data-testid={headerTestId}
            className={cx(classes.header, {
              [classes.noHeaderBorder]: noHeaderBorder,
              [headerClassName as string]: headerClassName,
            })}
          >
            {accessoryTitleLeft}

            <h2
              className={cx(classes.title, {
                [titleClassName as string]: titleClassName,
              })}
            >
              {title}
              {titleRightContent}
            </h2>

            {accessoryTitleRight || (
              <Render when={showCrossIcon}>
                <IconButton
                  className={cx(classes.closeIcon, {
                    [closeIconClassName as string]: closeIconClassName,
                  })}
                  data-testid="modal-close"
                  onKeyDown={noop}
                  onClick={onClose}
                  // @ts-ignore
                  icon={<CrossIcon />}
                  testID="modal-close"
                />
              </Render>
            )}
          </div>
        </Render>

        <div className={cx(classes.body, { [bodyClassName as string]: bodyClassName })}>
          {children || <p className={classes.confirmText} data-palette="MaterialConfirmDialog">{confirmText}</p>}
        </div>

        <Render when={showFooter}>
          <Render when={showError}>
            <Box
              className={cx(classes.error, {
                [errorClassName as string]: errorClassName,
              })}
            >
              {error}
            </Box>
          </Render>
          <div
            className={cx(classes.footer, {
              [footerClassName as string]: footerClassName,
            })}
          >
            <Render when={showCancel}>
              <button
                type="button"
                data-testid="modal-cancel"
                onClick={typeof onCancel === 'function' ? onCancel : onClose}
                className={cx(classes.cancelBtn, {
                  [cancelClassName as string]: cancelClassName,
                })}
              >
                {cancelButtonText || t('common:cancel')}
              </button>
            </Render>
            <Render when={!hideConfirmButton}>
              <button
                type="button"
                data-testid={confirmBtnTestId || 'modal-confirm'}
                disabled={disableConfirmButton}
                onClick={loading ? undefined : onConfirm}
                className={cx({
                  'w-100': !showCancel,
                  [confirmClassName as string]: confirmClassName,
                })}
              >
                <Render when={loading} else={confirmButtonText || t('common:confirm')}>
                  <CircularProgress size={18} color="inherit" />
                </Render>
              </button>
            </Render>
          </div>
        </Render>
      </div>
    </MaterialDialog>)
  );
};

MaterialConfirmDialog.defaultProps = {
  loading: false,
  showCancel: false,
  fullWidth: false,
  showHeader: true,
  showCrossIcon: true,
  showFooter: true,
};

export default MaterialConfirmDialog;
