import React, { SVGProps } from 'react';

const CircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="CircleIcon">
    <path
      d="M8 .5c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5C3.86 15.5.5 12.14.5 8 .5 3.86 3.86.5 8 .5ZM8 14c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6Zm2.652-9.713 1.06 1.061L8 9.06 6.94 8l3.712-3.713Z"
      fill="#F6A609"
    />
  </svg>
);

export default CircleIcon;
