import { useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { postMessageToMobileApp } from 'components/app/helpers';
import { refreshPage } from 'helpers';
import { isEmpty } from 'helpers/ramda';

import useFirstMountState from './useFirstMountState';
import useLocalStorage from './useLocalStorage';

interface ILocaleKeys {
  [key: string]: string;
}

// writing this way helps in autocompletion
function localeKeys<T extends ILocaleKeys>(arg: T): T {
  return arg;
}

const locales = localeKeys({
  EN: 'English',
  // ES: 'Español',
  // JA: '日本語',
  ZH: '简体中文',
  // AR: 'العربية',
  // NL: 'Netherlands',
  FR: 'Français',
  // RU: 'Русский',
  DE: 'Deutsch',
  IT: 'Italiano',
  KO: '한국어',
  PT: 'Português',
  TR: 'Türkçe',
  VI: 'Tiếng Việt',
});

const isLocaleUnavailable = (lang: keyof typeof locales) => {
  const flag = Object.keys(locales).filter(locale => locale === lang);
  return isEmpty(flag);
};

const useLanguageChange = (
  initOnMount = true
): {
  activeLanguage: keyof typeof locales;
  handleLanguageSwitch: (activeLocale: keyof typeof locales) => void;
  availableLocales: typeof locales;
} => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useLocalStorage<keyof typeof locales>(
    '@delta/locale',
    'EN'
  );
  const isFirstMount = useFirstMountState();

  function handleReload() {
    if (isFirstMount) return;
    refreshPage();
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && locale) {
      const lang = locale.toLowerCase();
      if (isLocaleUnavailable(locale)) {
        i18n.changeLanguage('en');
        document.documentElement.lang = 'en';
        setLocale('EN');
        handleReload();
      } else {
        i18n.changeLanguage(lang);
        document.documentElement.lang = lang;
        handleReload();
      }
    }
  }, [locale]);

  const handleLanguageSwitch = useCallback((activeLocale: keyof typeof locales) => {
    const checkLocale = isLocaleUnavailable(activeLocale) ? 'EN' : activeLocale;

    setLocale(checkLocale);
    postMessageToMobileApp('LOCALE_CHANGE', {
      locale: checkLocale,
    });

    if (!initOnMount) {
      if (isLocaleUnavailable(activeLocale)) {
        i18n.changeLanguage('en');
        document.documentElement.lang = 'en';
        handleReload();
      } else {
        i18n.changeLanguage(activeLocale.toLowerCase());
        document.documentElement.lang = activeLocale.toLowerCase();
        handleReload();
      }
    }
  }, []);

  return {
    activeLanguage: locale,
    handleLanguageSwitch,
    availableLocales: locales,
  };
};

export default useLanguageChange;
