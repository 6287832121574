import React, { useEffect, useState } from 'react';

import { BehaviorSubject, Subject } from 'rxjs';

// tvReadySubject is required when we navigate from
// trade -> markets -> trade page
// tradingView instance is destroyed created again and we need to know that it is
// ready again
// It is consumed by header and open position links
export const tvReadySubject = new Subject();

// Using this subject for mark Price and funding price tab.
// When the MarkPrice component is mounted again, we need the
// last tradingView instance to change the symbol and interval
export const tvReadyBehaviorSubject = new BehaviorSubject();

export function useTvObservable() {
  const [isTvReady, setTvReady] = useState(
    () => tvReadyBehaviorSubject.getValue() !== null
  );

  useEffect(() => {
    const subscription = tvReadySubject.subscribe(() => {
      setTvReady(true);
    });

    return function cleanUp() {
      subscription.unsubscribe();
    };
  }, []);

  return [isTvReady, setTvReady];
}

/* Usage: pass a function as a child to the component
  <TvReadyWrapper>
    {
      (ready) => ready? <Comp1/> : <Comp2/>
    }
  </TvReadyWrapper>
  It is consumed by component dependent(header dropdown and open position links) on tradingView ready state
*/
export const TvReadyWrapper = ({ children }) => {
  const [isTvReady] = useTvObservable();
  return <>{children(isTvReady)}</>;
};
