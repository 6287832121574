import React, { SVGProps } from 'react';

const INR = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="15"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-palette="INR">
    <path
      d="M3.88 14.28L0.0999985 7.68V6.8H0.539998C1.59333 6.8 2.40667 6.64667 2.98 6.34C3.55333 6.03333 3.88667 5.48 3.98 4.68H0.0999985V3.4H3.96C3.82667 2.65333 3.47333 2.11333 2.9 1.78C2.34 1.44667 1.55333 1.28 0.539998 1.28H0.0999985V0H8.38V1.28H4.6C5.24 1.8 5.62 2.50667 5.74 3.4H8.38V4.68H5.78C5.68667 5.66667 5.31333 6.42667 4.66 6.96C4.02 7.49333 3.16667 7.83333 2.1 7.98L5.92 14.28H3.88Z"
      fill="#101013"
    />
  </svg>
);

export default INR;
