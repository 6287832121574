import { useEffect, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { enableMFASendEmailCode } from 'actions/user';
import { useAppDispatch } from 'storeHooks';

/**
 * hook to handle the email OTP verification. Contains the logic to send the OTP, resend the OTP, and handle the OTP input. Use it with OTPInput(components/OTPVerification) component
 * @returns {object} - sendCode, recaptchaRef, resendOTP, onChangeEmailOTP, emailOTP
 */
const useEmailOTPVerification = () => {
  const appDispatch = useAppDispatch();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [emailOTP, setOTP] = useState<string | number | undefined>();

  const resendOTP = () => {
    recaptchaRef?.current?.execute();
  };

  const sendCode = () => {
    appDispatch(enableMFASendEmailCode());
  };

  const onChangeEmailOTP = (value: string | number | undefined) => {
    setOTP(value);
  };

  useEffect(() => {
    sendCode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sendCode, recaptchaRef, resendOTP, onChangeEmailOTP, emailOTP };
};

export default useEmailOTPVerification;
