/* eslint-disable camelcase */
import { divide } from 'helpers/ramda';
import { decimalMultiplication, isOptions } from 'helpers/utils';

export const calculateDefaultContractValue = (
  orderSize,
  contractValue,
  productType,
  quotingAsset,
  underlyingAsset
) => {
  if (!String(orderSize).length) {
    return '';
  }
  const precision =
    productType === 'inverse'
      ? quotingAsset.minimum_precision
      : underlyingAsset?.minimum_precision;

  return Number((Number(orderSize) * Number(contractValue)).toFixed(precision || 2));
};

/**
 * @returns underlying asset symbol for inverse , settling asset symbol for options and quoting asset symbol for non-inverse futures
 *
 * @example inverse ETHUSD => ETH
 * @example vanilla ETHUSDT => USDT
 * @example options C-BTC-5000-200230 => USDT
 *
 * @param {string} contractType futures | perpetual_futures | put_options | call_options
 * @param {string} productType inverse | quanto | vanilla
 * @param {import('types/IAsset').Asset} underlyingAsset underlying_asset of contract
 * @param {import('types/IAsset').Asset} quotingAsset quoting_asset of contract
 * @param {import('types/IAsset').Asset} settlingAsset settling_asset of contract
 */

export const getSecondaryCurrency = (
  contractType,
  productType,
  underlyingAsset,
  quotingAsset,
  settlingAsset
) => {
  if (contractType === 'futures' || contractType === 'perpetual_futures') {
    if (productType === 'inverse') {
      return underlyingAsset.symbol;
    }
    return quotingAsset.symbol;
  }
  if (isOptions(contractType)) {
    return settlingAsset?.symbol;
  }
  return settlingAsset?.symbol;
};

/**
 * @returns quoting asset symbol for inverse contracts and underlying asset symbol for non-inverse contracts
 *
 * @example inverse ETHUSD => USD
 * @example vanilla ETHUSDT => ETH
 * @example options C-BTC-5000-200230 => BTC
 *
 * @param {string} contractType futures | perpetual_futures | put_options | call_options
 * @param {string} productType inverse | quanto | vanilla
 * @param {import('types/IAsset').Asset} underlyingAsset underlying_asset of contract
 * @param {import('types/IAsset').Asset} quotingAsset quoting_asset of contract
 * @param {string} contract_unit_currency unit currency of contract
 */

export const getPrimaryCurrency = (
  contractType,
  productType,
  underlyingAsset,
  quotingAsset,
  contract_unit_currency
) => {
  if (contractType === 'interest_rate_swaps') {
    return contract_unit_currency;
  }
  if ((contractType === 'futures' || 'perpetual_futures') && productType === 'inverse') {
    return quotingAsset.symbol;
  }
  return underlyingAsset?.symbol;
};

/**
 *
 * @param {selectedCurrency} selectedCurrency current currency selected
 * @param {primaryCurrency} primaryCurrency mostly underlying currency
 * @param {secondaryCurrency} secondaryCurrency mostly settling currency
 * @param {price} price limit price or impact price in case of market order
 * @param {optionsPrice} optionsPrice spot price of underlying option spot symbol
 * @param {contractValue} contractValue contract value from products api
 * @param {inputedQuantity} inputedQuantity value entered in quantity input box
 * @param {contractType} contractType
 * @param {notionalType} notionalType
 * @returns quantity in no. of contracts terms
 */
export const getQuantityOfContracts = ({
  selectedCurrency,
  primaryCurrency,
  secondaryCurrency,
  price,
  optionsPrice,
  contractValue,
  inputedQuantity, // inputed quantity
  contractType,
  notionalType,
}) => {
  if (!String(inputedQuantity).length) {
    return 0;
  }
  if (selectedCurrency === primaryCurrency) {
    return parseInt(divide(inputedQuantity, contractValue), 10);
  }

  if (selectedCurrency === secondaryCurrency) {
    if (isOptions(contractType)) {
      return parseInt(
        divide(inputedQuantity, decimalMultiplication(contractValue, optionsPrice)),
        10
      );
    }
    if (notionalType === 'inverse') {
      return parseInt(
        divide(decimalMultiplication(inputedQuantity, price), contractValue),
        10
      );
    }
    return parseInt(
      divide(inputedQuantity, decimalMultiplication(contractValue, price)),
      10
    );
  }

  return 0;
};

export const decimalHandle = inputedQuantity => {
  switch (inputedQuantity.toString()) {
    case '.':
    case '0.':
    case '.0':
      return 0;
    default:
      return inputedQuantity;
  }
};
