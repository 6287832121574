/* eslint-disable no-underscore-dangle */

import semver from 'semver';

import { IS_INDIAN_EXCHANGE } from './constants';

declare global {
  interface Window {
    _RNAppPlatform: 'ios' | 'android' | 'windows' | 'macos' | 'web';
    _RNAppVersion: string;
  }
}

enum BIOMETRICS_AUTH_INTRODUCED_VERSIONS {
  ANDROID = '1.7.5',
  IOS = '1.6.6',
}

const OAUTH_INTRODUCED_VERSIONS = {
  ANDROID: IS_INDIAN_EXCHANGE ? '1.1.0' : '1.9.0',
  IOS: IS_INDIAN_EXCHANGE ? '1.2.0' : '1.9.0',
} as const;

const HYPERVERGE_SDK_INTRODUCED_VERSIONS = {
  ANDROID: IS_INDIAN_EXCHANGE ? '1.2.0' : '1.9.3',
} as const;

const SHUFTIPRO_SDK_INTRODUCED_VERSIONS = {
  ANDROID: '1.9.4',
} as const;

const SOCIAL_SHARING_CARD_INTRODUCED_VERSIONS = {
  android: '1.9.3',
  ios: '1.8.2',
} as const;

const DOWNLOAD_INVOICE_INTRODUCED_VERSION = {
  android: '1.9.4',
  ios: '1.8.3',
};

const IN_APP_REVIEW_INTRODUCED_VERSION = {
  android: IS_INDIAN_EXCHANGE ? '1.2.0' : '1.10.0',
};

/**
 * The platform that the mobile app is running on.
 * @enum {string} 'ios' | 'android' | 'windows' | 'macos' | 'web'
 */
const RN_APP_PLATFORM = window._RNAppPlatform;

/**
 * The version of the mobile app that is currently running.
 */
// const RN_APP_VERSION = window._RNAppVersion;
const RN_APP_VERSION = RN_APP_PLATFORM === 'ios' ? window._RNAppVersion : '1.7.5';

const RN_APP_VERSION_FOR_OAUTH = window._RNAppVersion;

// #region Biometrics Auth feature-flag "FEATURE_FLAG_BIOMETRICS_AUTH_ENABLED"
const DEFAULT_BIOMETRICS_AUTH_ENABLED_FLAG = false;

// Disable Google oauth by default
const DEFAULT_OAUTH_ENABLED_FLAG = false;

let isBiometricsAuthEnabled = DEFAULT_BIOMETRICS_AUTH_ENABLED_FLAG;
let isOAuthEnabled = DEFAULT_OAUTH_ENABLED_FLAG;

const biometricsAuthIntroducedVersionByPlatform =
  BIOMETRICS_AUTH_INTRODUCED_VERSIONS[RN_APP_PLATFORM?.toUpperCase()];

const oAuthIntroducedVersionByPlatform =
  OAUTH_INTRODUCED_VERSIONS[RN_APP_PLATFORM?.toUpperCase()];

if (biometricsAuthIntroducedVersionByPlatform) {
  isBiometricsAuthEnabled = semver.gte(
    RN_APP_VERSION,
    biometricsAuthIntroducedVersionByPlatform
  );
}

if (oAuthIntroducedVersionByPlatform) {
  isOAuthEnabled = semver.gte(RN_APP_VERSION_FOR_OAUTH, oAuthIntroducedVersionByPlatform);
}

/**
 * This feature-flag is used to enable/disable the biometrics auth feature.
 * It is disabled by default, but is enabled for version above the minimum
 * version that supports biometrics auth.
 *
 * @see BIOMETRICS_AUTH_INTRODUCED_VERSIONS for the minimum version that supports biometrics auth.
 */
const FEATURE_FLAG_BIOMETRICS_AUTH_ENABLED = isBiometricsAuthEnabled;

// ENABLE or DISABLE google oauth feature in this flag
const FEATURE_FLAG_OAUTH_ENABLED = isOAuthEnabled;
// #endregion

/**
 * @description Currently Hyperverge Mobile SDK is only working on Android platform.
 * Also it is only supported by minimum versions mentioned in variable `HYPERVERGE_SDK_INTRODUCED_VERSIONS`
 */
const FEATURE_FLAG_HYPERVERGE_SDK_SUPPORTED =
  RN_APP_PLATFORM === 'android' && window._RNAppVersion
    ? semver.gte(window._RNAppVersion, HYPERVERGE_SDK_INTRODUCED_VERSIONS.ANDROID)
    : false;

/**
 * @description Currently ShuftiPro Mobile SDK is only working on Android platform.
 * Also it is only supported by minimum versions mentioned in variable `SHUFTIPRO_SDK_INTRODUCED_VERSIONS`
 */
const FEATURE_FLAG_SHUFTIPRO_SDK_SUPPORTED =
  RN_APP_PLATFORM === 'android' && window._RNAppVersion
    ? semver.gte(window._RNAppVersion, SHUFTIPRO_SDK_INTRODUCED_VERSIONS.ANDROID)
    : false;

const FEATURE_FLAG_SOCIAL_SHARING_CARD_SUPPORTED = window._RNAppVersion
  ? semver.gte(
      window._RNAppVersion,
      SOCIAL_SHARING_CARD_INTRODUCED_VERSIONS[RN_APP_PLATFORM]
    )
  : false;

/**
 *  @description  flag used for checking download invoice feature is available or not,
 *  depending on `DOWNLOAD_INVOICE_INTRODUCED_VERSION`
 */
const FEATURE_FLAG_DOWNLOAD_INVOICE_ENABLED = window._RNAppVersion
  ? semver.gte(window._RNAppVersion, DOWNLOAD_INVOICE_INTRODUCED_VERSION[RN_APP_PLATFORM])
  : false;

const FEATURE_FLAG_IN_APP_REVIEW_SUPPORTED =
  RN_APP_PLATFORM === 'android' && window._RNAppVersion
    ? semver.gte(window._RNAppVersion, IN_APP_REVIEW_INTRODUCED_VERSION.android)
    : false;

export {
  RN_APP_PLATFORM,
  RN_APP_VERSION,
  RN_APP_VERSION_FOR_OAUTH,
  FEATURE_FLAG_BIOMETRICS_AUTH_ENABLED,
  FEATURE_FLAG_OAUTH_ENABLED,
  FEATURE_FLAG_HYPERVERGE_SDK_SUPPORTED,
  FEATURE_FLAG_SHUFTIPRO_SDK_SUPPORTED,
  FEATURE_FLAG_SOCIAL_SHARING_CARD_SUPPORTED,
  FEATURE_FLAG_DOWNLOAD_INVOICE_ENABLED,
  FEATURE_FLAG_IN_APP_REVIEW_SUPPORTED,
};
