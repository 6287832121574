import styled from 'styled-components';
import { device } from '../../helpers/screenSizes';

export const ReactTableStyle = styled.div`
  height: 100%;
  width: 100%;
  .rt-tbody .rt-tr:hover {
    background-color: var(--tableRowHover);
  }
  .auction-row:hover {
    background-color: initial !important;
  }
  .ReactTable {
    .tbody-cell {
      text-align: center;
      font-size: 12.1px;
      color: ${props => props.theme.primaryText};
    }

    .rt-thead [role='columnheader'] {
      outline: 0;
      font-family: var(--fontRegular);
      border-right: 0px;
    }

    .theader-cell {
      text-align: center;
      font-size: 12.1px;
      color: ${props => props.theme.primaryText};
    }
    .pagination-bottom {
      .-pagination {
        max-width: 400px;
        margin: 0 auto;
        color: ${props => props.theme.primaryText};
        .-pageJump input {
          border: 1px solid;
        }
        .-btn {
          color: ${props => props.theme.primaryText};
          background: ${props => props.theme.primaryTheme};
          &:hover {
            background: ${props => props.theme.primaryButtonHover};
          }
        }
      }
    }
    .-loading.-active {
      background: ${props => props.theme.primaryBackground};
      .-loading-inner {
        color: ${props => props.theme.primaryText};
      }
    }
    .rt-noData {
      background: transparent;
      color: ${props => props.theme.primaryText};
    }
  }
`;

export const StyledTablePagination = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.secondaryText};
  align-items: center;
  position: relative;

  .left {
    display: flex;
    align-items: center;

    & .label {
      font-size: 12.1px;
      margin-right: 10px;

      @media ${device.down.md} {
        font-size: 10px;
      }
    }

    .inputs {
      background-color: var(--inputBackground);
      border: 1px solid var(--inputBorderColor);
      border-radius: var(--inputBorderRadius);
    }

    .input-text {
      background-color: var(--inputBackground);
      width: 35px;
      color: ${props => props.theme.primaryText};
      text-align: center;
      font-size: 12.1px;

      @media ${device.down.md} {
        height: 26px;
      }

      &:focus {
        border: 1px solid ${props => props.theme.primaryButtonHover};
      }
    }

    .input-icon {
      width: 25px;
      position: relative;
      top: -2px;
      padding: 0 9px 0 6px;
      cursor: pointer;

      @media ${device.down.md} {
        padding: 0 10px 0 7px;
      }

      &:hover path {
        fill: ${props => props.theme.primaryButtonHover};
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 12.1px;

    @media ${device.down.md} {
      font-size: 10px;
    }

    font-size: 12.1px;
    color: ${props => props.theme.primaryText};
    font-family: var(--fontRegular);
  }

  .count {
    color: ${props => props.theme.secondaryText};
    margin-right: 10px;
  }

  .nav-icon {
    font-size: 14px;
    color: ${props => props.theme.secondaryText};
    padding: 0 7px;
  }

  .icon-btn-root {
    padding: 7px;

    @media ${device.down.md} {
      padding: 5px;
    }

    &:hover {
      background: ${props => props.theme.primaryBackground};
    }

    &:last-of-type {
      margin-left: 5px;
    }
  }

  .icon-btn-disabled .nav-icon {
    color: ${props => props.theme.secondaryText};
  }
`;

export const Divider = styled.div`
  width: 2px;
  background: ${props => props.theme.secondaryBackground};
  height: 31px;
  margin: 0 24px;
  margin-right: ${props => (props.shouldHideRange ? '14px' : '24px')};

  @media ${device.down.md} {
    height: 28px;
    margin: 0 18px;
  }
`;

export const Options = styled.div`
  position: absolute;
  width: 60px;
  max-height: 200px;
  border-radius: 1px;
  background-color: ${props => props.theme.secondaryBackground};
  /* bottom: 36px; */
  bottom: 20px;
  /* overflow-y: scroll; */
`;

export const OptionItem = styled.div`
  font-size: 14px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.primaryTheme : theme.secondaryText};
  background: ${props =>
    props.isSelected ? props.theme.tertiaryBackground : 'inherit'};
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.primaryButtonHover};
    background: ${props => props.theme.secondaryBackground};
  }

  @media ${device.down.md} {
    font-size: 12.1px;
  }
`;
