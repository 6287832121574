import { memo } from 'react';

import { useOptionsChainData } from 'components/Header/partials/headerDropdown/OptionsChain/useOptionsChainData';

import LongShortIndicator from './LongShortIndicator';
import styles from './styles.module.scss';

const OptionTypeColumn = () => {
  const {
    showCallOptions,
    showPutOptions,
    cellClassName,
    onColumnClick,
    boxClassName,
    data,
  } = useOptionsChainData();

  return data.map(
    ({ strike_price: strikePrice, callOptions, putOptions, backgroundClass }) => {
      const activeOptionsData = showCallOptions ? callOptions : putOptions;
      return (
        (<div
          className={cellClassName}
          key={strikePrice}
          onClick={() => onColumnClick(activeOptionsData)}
          data-testid="options-type-column"
          tabIndex={0}
          role="button"
          onKeyDown={() => {}}
          aria-label="option type"
          data-palette="OptionTypeColumn">
          {showCallOptions && (
            <div
              className={`pe-0 ${styles.rightAlignedHeader} ${styles.greenColor} ${boxClassName} ${backgroundClass}`}
              data-palette="OptionTypeColumn">
              <LongShortIndicator optionId={callOptions?.id || callOptions?.product?.id}>
                C
              </LongShortIndicator>
            </div>
          )}
          {showPutOptions && (
            <div
              className={`pe-0 ${styles.rightAlignedHeader} ${styles.redColor} ${boxClassName} ${backgroundClass}`}
              data-palette="OptionTypeColumn">
              <LongShortIndicator optionId={putOptions?.id || putOptions?.product?.id}>
                P
              </LongShortIndicator>
            </div>
          )}
        </div>)
      );
    }
  );
};

export default memo(OptionTypeColumn);
