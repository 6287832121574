/* eslint-disable import/order */
/* tslint:disable */
import React, { Suspense } from 'react';
// import * as Sentry from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations';
// import { hydrate, render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga4';
import { LastLocationProvider } from 'react-router-last-location';
import { PersistGate } from 'redux-persist/integration/react';
import Moengage from '@moengage/web-sdk';
import { GoogleOAuthProvider } from '@react-oauth/google';

import {
  init,
  events,
  markers,
  network,
  profiler,
  paint,
  vitals,
} from '@palette.dev/browser';

import store, { history, persistor } from './store';
import DeltaThemeProvider from './ThemeProvider';
import {
  SENTRY_DSN,
  PALETTE_CLIENT_KEY,
  GOOGLE_ANALYTICS,
  isProd,
  RELEASE_VERSION,
  ANDROID_VERSION,
  IOS_VERSION,
  IS_RN_WEBVIEW,
  IS_INDIAN_EXCHANGE,
  IS_CI,
  MOENGAGE_APP_ID,
  MOENGAGE_DEBUG_ENABLED,
} from './constants/constants';
import App from './containers/app';
import 'sanitize.css/sanitize.css';
import './styles/scss/index.scss';
import { GlobalStyles } from './index_styles';
import { pipe, path, test } from 'helpers/ramda';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { CookiesProvider } from 'react-cookie';
import crossBrowserListener from './utils/redux-persist';
import { CenterLoader } from './components/loader';
import { postMessageToMobileApp } from './components/app/helpers';
import { localUserObject } from 'helpers';
import BiometricProvider from 'hooks/componentHooks/useBiometrics/useBiometrics.Provider';
import OAuthLoginProvider from 'hooks/useOAuthLogin';
import { enableMapSet } from 'immer';

import './styles/root.scss';
import './i18n/config';

enableMapSet();

ReactGA.initialize(GOOGLE_ANALYTICS ?? '');


if (MOENGAGE_APP_ID) {
  // https://developers.moengage.com/hc/en-us/articles/360060713252-Web-SDK-Integration
  Moengage.initialize({
    app_id: MOENGAGE_APP_ID,
    debug_logs: MOENGAGE_DEBUG_ENABLED,
    enableSPA: true,
    cluster: 'DC_3',
    swPath: IS_CI ? '/sw.js' : '/app/sw.js',
  });
}

if (PALETTE_CLIENT_KEY) {
  init({
    key: PALETTE_CLIENT_KEY,
    // Collect click, network, performance events, and profiles
    plugins: [
      events(),
      markers(),
      network(),
      profiler(),
      paint({ componentPaint: true }),
      vitals(),
    ],
    // version: process.env.REACT_APP_VERSION,
  });

  profiler.on(
    [
      'paint.click',
      'paint.keydown',
      'paint.scroll',
      'paint.mousemove',
      'markers.measure',
      'events.load',
      'events.dcl',
    ],
    {
      sampleInterval: 1,
      maxBufferSize: 100_000,
    }
  );
}

const userContext = localUserObject?.id
  ? {
      id: localUserObject.id,
      username: localUserObject.userName,
      email: localUserObject.email,
      source: IS_RN_WEBVIEW ? 'ReactNative' : 'WebApp',
      userAuthStatus: 'logged-in',
    }
  : {
      source: IS_RN_WEBVIEW ? 'ReactNative' : 'WebApp',
      userAuthStatus: 'logged-out',
      environment: process.env.REACT_APP_ENV,
    };

// @ts-expect-error it should be available
const isStatus4xx = pipe(path(['originalException', 'status']), test(/^4\d{2}$/));

// These were the most noisy errors to the sentry and which are not much of useful for us. Hence, ignoring the logging of these errors
const ignoreExceptionToSentry = (exception: string) => {
  const ignoreErrorPatterns = [
    'Request has been terminated',
    'internal_server_error',
    'You provided an invalid object where a stream was expected',
    'Non-Error exception captured with keys: currentTarget, isTrusted',
    'Load failed',
    'Non-Error exception captured with keys: code, message', // signup fail case is being captured explicitly
    'Loading chunk',
    'Loading CSS chunk',
    "Cannot read properties of null (reading 'style')", // some error in the recaptcha
    "Failed to execute 'scrollTo' on 'Window'",
    'ResizeObserver loop completed with undelivered notifications.',
    'window.onMetaWidget is not a constructor',
  ];
  return ignoreErrorPatterns.filter(err => exception.indexOf(err) !== -1).length > 0;
};

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      // }),
      // new Sentry.Replay(),
      // Sentry.browserTracingIntegration(),
      // Sentry.browserProfilingIntegration(),
      extraErrorDataIntegration(),
      httpClientIntegration(),
      // ...(IS_INDIAN_EXCHANGE ? [Sentry.replayIntegration()] : []),
    ],
    release: RELEASE_VERSION,
    beforeSend(event, hint) {
      return isStatus4xx(hint) ||
        ignoreExceptionToSentry(hint?.originalException?.toString() || '')
        ? null
        : event;
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // https://docs.sentry.io/platforms/javascript/performance/#enable-tracing
    tracesSampleRate: 0.1,
    normalizeDepth: 5, // For redux
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
  });
  Sentry.setUser(userContext);
  // @ts-ignore
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
};
window.addEventListener('storage', crossBrowserListener(store, persistConfig));

const DeltaApp = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <BiometricProvider>
              <OAuthLoginProvider>
                <DeltaThemeProvider>
                  <LastLocationProvider watchOnlyPathname>
                    <GlobalStyles />
                    <Suspense fallback={<CenterLoader />}>
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}
                      >
                        <App />
                      </GoogleOAuthProvider>
                    </Suspense>
                  </LastLocationProvider>
                </DeltaThemeProvider>
              </OAuthLoginProvider>
            </BiometricProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  );
};

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('disable-react-error-overlay').then(() => {
    const iframe = document.querySelector('iframe');
    if (iframe) iframe.remove();
  });
}

postMessageToMobileApp('VERSION', {
  android: ANDROID_VERSION,
  ios: IOS_VERSION,
  title: 'App Update Available',
  body: 'Update your app for an improved experience',
  positiveButtonText: 'Update',
  NegativeButtonText: 'No, thanks',
  mandatoryUpdate: false,
});

const target = document.querySelector('#root');
const root = createRoot(target);
root.render(<DeltaApp />);
// if (target?.hasChildNodes()) {
//   console.log("DEBUG", "Hydrating the root")
//   hydrateRoot(target, <DeltaApp />);
// } else {
//   const root = createRoot(target);
//   root.render(<DeltaApp />);
// }

if (isProd) {
  import('./registerServiceWorker').then(module => module.default());
}
