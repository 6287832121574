import styled from 'styled-components';

import { device } from '../../helpers/screenSizes';

export const ReactTableStyle = styled.div`
  height: 100%;
  width: 100%;
  .ReactTable {
    .tbody-cell {
      text-align: center;
      font-size: 12.1px;
      color: ${props => props.theme.primaryText};
    }

    .rt-thead [role='columnheader'] {
      outline: 0;
      font-family: var(--fontRegular);
      border-right: 0px;
    }

    .theader-cell {
      text-align: center;
      font-size: 12.1px;
      color: ${props => props.theme.primaryText};
    }
    .pagination-bottom {
      .-pagination {
        max-width: 400px;
        margin: 0 auto;
        color: ${props => props.theme.primaryText};
        .-pageJump input {
          border: 1px solid;
        }
        .-btn {
          color: ${props => props.theme.primaryText};
          background: ${props => props.theme.primaryTheme};
          &:hover {
            background: ${props => props.theme.primaryButtonHover};
          }
        }
      }
    }
    .-loading.-active {
      background: ${props => props.theme.primaryBackground};
      .-loading-inner {
        color: ${props => props.theme.primaryText};
      }
    }
    .rt-noData {
      background: transparent;
      color: ${props => props.theme.primaryText};
    }
  }

  .ReactTable .rt-table {
    overflow-y: hidden;
    overflow-x: scroll; /* fallback */
    overflow-x: overlay;
  }

  &:hover .ReactTable .rt-table::-webkit-scrollbar {
    height: 5px;
  }

  &:hover .ReactTable .rt-table {
    scrollbar-width: var(--scrollBarWidthFirefox);
  }
  .ReactTable .rt-tbody .rt-tr-group div.rt-tr:hover {
    background-color: var(--tableRowHover);
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: none;
  }
`;

export const StyledTablePagination = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.secondaryText};
  align-items: center;
  position: relative;
  padding: 18px 0 10px 0;
  background-color: ${props => props.theme.primaryBackground};
  border-bottom: 1px solid ${props => props.theme.separator};
  &.pagination-asset-container {
    @media ${device.down.md} {
      position: fixed;
      bottom: 0;
      width: 100vw;
      margin: 0 7px;
      .left {
        .label {
          font-size: 12px !important;
        }
        .inputs {
          height: 32px;
          .input-text {
            height: 32px;
          }
        }
        .input-icon {
          padding: 0;
          height: 8px;
          top: 0;
        }
      }
      .pagination-options .pagination-option-item {
        height: 32px;
        text-align: center;
        color: var(--primaryText);
      }
      .right {
        font-size: 12px !important;
      }
      .count {
        margin-right: 15px;
      }
      .icon-btn-root {
        padding: 0px;
        width: 35px;
      }
    }
  }
  .left {
    display: flex;
    align-items: center;

    & .label {
      font-size: 12.1px;
      margin-right: 10px;

      @media ${device.down.md} {
        font-size: 10px;
      }
    }

    .inputs {
      background-color: var(--inputBackground);
      border: 1px solid var(--inputBorderColor);
      border-radius: var(--inputBorderRadius);
    }

    .input-text {
      background-color: var(--inputBackground);
      width: 35px;
      color: ${props => props.theme.primaryText};
      text-align: center;
      font-size: 12.1px;

      @media ${device.down.md} {
        height: 26px;
      }

      &:focus {
        border: 1px solid ${props => props.theme.primaryTheme};
      }
    }

    .input-icon {
      width: 25px;
      height: 25px;
      position: relative;
      top: -2px;
      padding: 0 9px 0 6px;
      cursor: pointer;

      @media ${device.down.md} {
        padding: 0 10px 0 7px;
      }

      &:hover path {
        fill: ${props => props.theme.primaryTheme};
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 12.1px;

    @media ${device.down.md} {
      font-size: 10px;
    }

    font-size: 12.1px;
    color: ${props => props.theme.primaryText};
    font-family: var(--fontRegular);
  }

  .count {
    color: ${props => props.theme.secondaryText};
    margin-right: 10px;
  }

  .nav-icon {
    font-size: 14px;
    color: ${props => props.theme.secondaryText};
    padding: 0 7px;
  }

  .icon-btn-root {
    padding: 7px;
    color: var(--secondaryText) !important;

    @media ${device.down.md} {
      padding: 5px;
    }

    &:hover {
      background: ${props => props.theme.secondaryBackground};
    }

    &:last-of-type {
      margin-left: 5px;
    }
    &.icon-btn-disabled {
      opacity: 0.7;
    }
  }

  .icon-btn-disabled .nav-icon {
    color: ${props => props.theme.secondaryText};
  }
`;

export const Divider = styled.div`
  width: 2px;
  background: ${props => props.theme.separator};
  height: 31px;
  margin: 0 24px;
  margin-right: ${props => (props.shouldHideRange ? '14px' : '24px')};

  @media ${device.down.md} {
    height: 28px;
    margin: 0 18px;
  }
`;

export const Options = styled.div`
  position: absolute;
  width: 60px;
  max-height: 200px;
  border-radius: 1px;
  background-color: ${props => props.theme.secondaryBackground};
  /* bottom: 36px; */
  bottom: 20px;
  /* overflow-y: scroll; */
`;

export const OptionItem = styled.div`
  font-size: 14px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.primaryButtonHover : theme.secondaryText};
  background: ${props => (props.isSelected ? props.theme.separator : 'inherit')};
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.primaryButtonHover};
    background: ${props => props.theme.separator};
  }

  @media ${device.down.md} {
    font-size: 12.1px;
  }
`;
