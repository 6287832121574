/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/onClickTooltip';
import TwoLabeledRangeSlider from 'components/rangeSlider/twoLabeledRangeSlider';
import { cropAfterDecimals, isOptions } from 'helpers';
import { spotLeverage } from 'helpers/formulas';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { useLeverage } from 'hooks';
import { Info } from 'styles/assets/icons';
import { Product } from 'types/IProducts';
import { SetTimeOut } from 'types/utils';
import { Box, Button } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './leverage.module.scss';
import { WarningMessage } from './marginTypes/helpers';
import MaxNotional from './MaxNotional';
import RangeSlider from '../rangeSlider';

interface LeverageSliderProps {
  side: '' | 'buy' | 'sell';
  onCancel: () => void;
  product: Product;
}

const LeverageSlider = ({ side, onCancel, product }: LeverageSliderProps) => {
  const { t } = useTranslation(['trading', 'portfolioMargin']);
  const timer = useRef<SetTimeOut>();

  const {
    leverage,
    maxLeverage,
    sliderValues: leverages,
    onLeverageChange,
    onInputChange,
    handleLeverageDecrement,
    handleLeverageIncrement,
    handleLeverageSubmit,
    enableSubmit,
    setEnabledSubmit,
  } = useLeverage({ product });

  const {
    contract_type: contractType,
    product_specs: productSpecs,
    settlement_time: settlementTime,
    launch_time: launchTime,
  } = product;

  const spotLeverages = leverages.reduce(
    (map, value) => ({
      ...map,
      [value]: spotLeverage(
        value,
        productSpecs?.floating_rate_max,
        productSpecs?.floating_rate_min,
        side,
        launchTime,
        settlementTime
      ),
    }),
    {}
  );
  const isOptionsBuyAction = isOptions(contractType) && side === 'buy';

  const toggleToSubmitBtn = (updaterFun, leverageValue = null) => {
    setEnabledSubmit(true);
    if (leverageValue) {
      updaterFun(leverageValue);
    } else {
      updaterFun();
    }
  };

  const onLeverageSubmit = () => {
    handleLeverageSubmit();
    const { symbol, contract_type: contractType } = product;
    trackMixpanelEvent('Leverage Change Accepted - Trade - Ordering', {
      product_name: symbol,
      product_type: contractType,
    });
    timer.current = setTimeout(() => {
      onCancel();
    }, 100);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current as SetTimeOut);
    };
  }, []);

  return (
    (<div className={styles[`isolated-content`]} data-palette="LeverageSlider">
      {/* eslint-disable-next-line no-nested-ternary */}
      {isOptionsBuyAction ? (
        <WarningMessage
          text={t('portfolioMargin:isolted_disabled')}
          title={t('portfolioMargin:isolted_disabled_title')}
        />
      ) : (
        <>
          <div className={styles.label} data-palette="LeverageSlider">{t('trading:leverage')}</div>
          <div
            className={cn(styles.inputSection, 'flex-set')}
            data-palette="LeverageSlider">
            <DeltaButton
              data-testid="leverageDecrementBtn"
              onClick={() => toggleToSubmitBtn(handleLeverageDecrement)}
              className={styles.incDecBtns}
            >
              -
            </DeltaButton>
            <input
              data-testid="leverageInputField"
              onChange={event => toggleToSubmitBtn(onInputChange, event)}
              value={cropAfterDecimals(leverage, 2)}
              type="text"
              inputMode="numeric"
            />
            <div className={cn(styles.valSuffix, 'flex-set align-items-center')}>x</div>
            <DeltaButton
              data-testid="leverageIncrementBtn"
              onClick={() => toggleToSubmitBtn(handleLeverageIncrement)}
              className={styles.incDecBtns}
            >
              +
            </DeltaButton>
          </div>

          <Box
            as="div"
            renderWhen={contractType === 'interest_rate_swaps'}
            className="mt-3"
          >
            <TwoLabeledRangeSlider
              data-testid="leverageSubmitBtn"
              onChange={value => toggleToSubmitBtn(onLeverageChange, value)}
              maxLeverage={maxLeverage}
              initialValue={Number(Math.floor(leverage))}
              leverages={leverages}
              spotLeverages={spotLeverages}
            />
          </Box>

          <Box
            as="div"
            renderWhen={contractType !== 'interest_rate_swaps'}
            className="mt-3"
          >
            <RangeSlider
              data-testid="leverageSlider"
              onChange={value => toggleToSubmitBtn(onLeverageChange, value)}
              maxLeverage={maxLeverage}
              initialValue={Number(Math.floor(leverage))}
              leverages={leverages}
            />
          </Box>
          <div
            className="mt-5 flex-set justify-content-between align-items-center"
            data-palette="LeverageSlider">
            <div className={styles.maxLeveragetext}>
              <Tooltip
                title={`${t('trading:maxPositionAt')} ${leverage}x`}
                body={t('trading:leverageSection.max_leverage_tooltip')}
              />
              {/* {t('trading:maxPositionAt')} {leverage}x */}
            </div>
            <MaxNotional leverage={leverage} selectedProduct={product} />
          </div>

          <div
            className={cn(styles[`warning-label`], 'flex-set')}
            data-palette="LeverageSlider">
            <div className={styles.warningTitle}>
              <Info />
              {t('trading:important')}
            </div>
            <div>
              {t('trading:leverageChangeInfo', {
                value: cropAfterDecimals(leverage, 2),
              })}
            </div>
          </div>
          <div className={styles.sliderFooter} data-palette="LeverageSlider">
            {enableSubmit ? (
              <Button data-testid="submitLeverageBtn" onClick={onLeverageSubmit}>
                {' '}
                {t('trading:leverageSection.confirm')}{' '}
              </Button>
            ) : (
              <Button data-testid="closeLeverageSectionIsolated" onClick={onCancel}>
                {' '}
                {t('trading:leverageSection.ok')}{' '}
              </Button>
            )}
          </div>
        </>
      )}
    </div>)
  );
};
export default LeverageSlider;
