import React from 'react';
import styles from './index.module.scss';

function ListItem({ item, extraClass, onClick }) {
  return (
    (<div
      onClick={onClick}
      className={`handcursor ${styles.item} ${extraClass}`}
      data-palette="ListItem">
      {item}
    </div>)
  );
}

export default ListItem;
