/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const Checked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="Checked">
    <rect width={18} height={18} rx={3} fill="var(--primaryTheme)" />
    <path
      d="m7.833 10.85 5.362-5.362.826.825L7.833 12.5 4.121 8.788l.825-.825 2.887 2.887Z"
      fill="var(--primaryCtaText)"
    />
  </svg>
);

export default Checked;
