/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/require-default-props */
import React from 'react';

import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';

import Modal from 'components/modal';
import { device } from 'helpers/screenSizes';
import TradingFeeCreditsDesktopBanner from 'styles/assets/fee_payback_desktop.jpg';
import TradingFeeCreditsMobileBanner from 'styles/assets/fee_payback_mobile.jpg';
import { PointerUp, PointerDown } from 'styles/assets/icons';
import TradingCreditsDesktopBanner from 'styles/assets/trading_credits_desktop.jpg';
import TradingCreditsMobileBanner from 'styles/assets/trading_credits_mobile.jpg';
import { Close } from 'UIKit';

const style = {
  overlay: {
    zIndex: 10,
    padding: 10,
  },
};

const GenericPopupStyle = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.primaryText};
  padding-bottom: 25px;

  @media ${device.down.sm} {
    width: 350px;
  }

  @media ${device.down.xs} {
    width: 300px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
    margin-right: -20px;
  }

  .view {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .view-details {
    color: ${props => props.theme.secondaryText};
    letter-spacing: 8px;
    width: 236px;
    margin-left: 20px;
    cursor: pointer;
    @media ${device.down.sm} {
      font-size: 12.1px;
      width: 200px;
      padding-top: 4px;
    }
  }

  .icons {
    margin-left: -10px;
    cursor: pointer;
  }

  ul {
    color: ${props => props.theme.secondaryText};
    font-size: 12.1px;
    font-style: italic;
    padding-right: 40px;
  }

  li {
    margin-top: 18px;
  }

  li a {
    color: ${props => props.theme.primaryTheme};
  }

  .Collapsible__contentOuter {
    overflow: inherit;
    padding-left: 50px;
  }

  .line {
    width: 236px;
    height: 1px;
    background-color: ${props => props.theme.secondaryText};
    margin-left: 20px;
  }
`;

// const DepositButton = styled(Button)`
//   letter-spacing: 8px;
//   border-radius: 0px;
//   width: 238px;
//   height: 64px;
//   margin-top: 32px;
//   font-size: 16px;
//   font-weight: bold;

//   @media ${device.down.sm} {
//     margin-top: 5px;
//     font-size: 12.1px;
//     width: 200px;
//     height: 50px;
//   }
// `;

const Banner = styled.div`
  width: 100%;
  height: 250px;
  background: url(${props => props.desktopImage}) no-repeat center / 100% auto;
  margin-top: -55px;

  @media ${device.down.sm} {
    height: 275px;
    background: url(${props => props.mobileImage}) no-repeat center / 100% auto;
    margin-top: -40px;
  }

  @media ${device.down.xs} {
    margin-top: -56px;
  }
`;

const CollapsibleComponent = ({ open }) => (
  <div className="view" data-palette="CollapsibleComponent">
    <div className="view-details">OFFER DETAILS</div>
    <div className="icons">{open === 'up' ? <PointerUp /> : <PointerDown />}</div>
  </div>
);

export default class GenericPopup extends React.Component {
  static propTypes = {
    closePopup: PropTypes.func.isRequired,
    showPopup: PropTypes.bool,
    popupType: PropTypes.string.isRequired,
  };

  onRequestClose = () => {
    this.props.closePopup();
  };

  render() {
    let bulletPoints;
    let desktopImage;
    let mobileImage;
    switch (this.props.popupType) {
      case 'trading_fee_credits':
        bulletPoints = (
          <ul data-palette="GenericPopup">
            <li>Deposit 0.5BTC or more and get 0.02BTC as bonus</li>
            <li>
              Bonus becomes withdrawable after you have paid trading fees equal to bonus
              amount
            </li>
            <li>Offer open only to customers that signed up on or before 28th Jan</li>
            <li>
              For full details, please see{' '}
              <a
                href="https://www.delta.exchange/offers"
                data-testid="offers-page-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                this page
              </a>
            </li>
          </ul>
        );
        desktopImage = TradingFeeCreditsDesktopBanner;
        mobileImage = TradingFeeCreditsMobileBanner;
        break;
      case 'trading_credits':
        bulletPoints = (
          <ul data-palette="GenericPopup">
            <li>
              Follow{' '}
              <a
                href="https://twitter.com/Delta_Exchange"
                data-testid="twitter-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                @Delta_Exchange
              </a>{' '}
              on Twitter and retweet (with comment) our pinned tweet.
            </li>
            <li>
              Submit URL to your retweet in this{' '}
              <a
                href="https://forms.gle/uppkeBSSoUW6e54c6"
                data-testid="google-form"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google form
              </a>
              .
            </li>
            <li>
              $10 Welcome Bonus will be credited after Customer Support confirmation.
            </li>
          </ul>
        );
        desktopImage = TradingCreditsDesktopBanner;
        mobileImage = TradingCreditsMobileBanner;
        break;
      default:
        bulletPoints = [];
        desktopImage = null;
        mobileImage = null;
    }

    return (
      <Modal
        isOpen={this.props.showPopup}
        onRequestClose={this.onRequestClose}
        style={style}
      >
        <GenericPopupStyle className="offer-popup">
          <Close data-testid="HhumDFtR6ImtJ4--MI_Y-" onClick={this.onRequestClose} />
          <Banner desktopImage={desktopImage} mobileImage={mobileImage} />
          <Collapsible
            open={false}
            trigger={<CollapsibleComponent open="down" />}
            triggerWhenOpen={<CollapsibleComponent open="up" />}
          >
            <div>{bulletPoints}</div>
          </Collapsible>
          <div className="view">
            <div className="line" />
          </div>
        </GenericPopupStyle>
      </Modal>
    );
  }
}
