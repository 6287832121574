/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  changeBasketOrdersScreen,
  deleteAllOrdersFromBasket,
  deleteProductFromBasket,
  editOrdersFromBasket,
} from 'actions/basketOrders';
import { TrashIcon } from 'components/basketOrders/icons';
import { calculateDefaultContractValue } from 'components/placeorder/partials/quantity/helper';
import Render from 'components/render';
import SpinnerLoader from 'components/spinnerLoader';
import { BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
import {
  basketActiveUnderlyingAssetSelector,
  basketOrderOrdersSelector,
  productsSelector,
  showBasketLoadingSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { CircularUpArrow } from 'styles/assets/icons';
import { OrdersObject } from 'types/basketOrders';
import { Box } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './productSelectionScreen.module.scss';

const MobileBasketOrdersOrderPopup = () => {
  const minSwipeDistance = 30;

  const { t } = useTranslation(['basketOrders']);
  const dispatch = useAppDispatch();

  const ordersInBasket = useAppSelector(basketOrderOrdersSelector);
  const showLoading = useAppSelector(showBasketLoadingSelector);
  const products = useAppSelector(productsSelector);
  const activeAssetSymbol = useAppSelector(basketActiveUnderlyingAssetSelector);

  const [touchStartPosition, setTouchStartPosition] = useState<Number | null>(null);
  const [touchEndPosition, setTouchEndPosition] = useState<Number | null>(null);
  const [showOrdersData, setShowOrdersData] = useState<boolean>(false);

  const filteredOrders = Object.values(ordersInBasket).filter(
    order => order?.asset === activeAssetSymbol && order.side !== ''
  );
  const liveOrdersInbasket = filteredOrders.filter(order => !!products[order.product_id]);

  const handleClearAllBtnClick = () => {
    dispatch(deleteAllOrdersFromBasket());
    dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW));
  };

  const handleDoneBtnClick = () => {
    dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0] || event.targetTouches[0];
    setTouchStartPosition(touch?.clientY);
    setTouchEndPosition(null);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0] || event.targetTouches[0];
    setTouchEndPosition(touch?.clientY);
  };

  const handleTouchEnd = () => {
    if (!touchStartPosition || !touchEndPosition) {
      return;
    }

    const distance = Math.abs(Number(touchStartPosition) - Number(touchEndPosition));
    const isUpwardSwipe = touchStartPosition > touchEndPosition;

    const showCompletePopup = isUpwardSwipe && distance > minSwipeDistance;
    setShowOrdersData(showCompletePopup);
  };

  const handleBuySellClick = (order: OrdersObject, side: string) => {
    const newOrders = { ...order, side };
    dispatch(editOrdersFromBasket(newOrders));
  };

  const handleDeleteClick = (order: OrdersObject) => {
    dispatch(deleteProductFromBasket(order));
  };

  return (
    (<div
      className={cn(styles.ordersPopup, {
        [styles.showOrders]: showOrdersData,
      })}
      onTouchStart={e => handleTouchStart(e)}
      onTouchEnd={handleTouchEnd}
      onTouchMove={e => handleTouchMove(e)}
      data-palette="MobileBasketOrdersOrderPopup">
      <span
        className={cn(styles.arrow, {
          [styles.rotate]: showOrdersData,
        })}
      >
        {/* arrow color is same for both the themes */}
        <CircularUpArrow bgFillColor="var(--separator2)" arrowBgColor="#5A606B" />
      </span>
      <Box renderWhen={showOrdersData} className={styles.ordersContent}>
        {liveOrdersInbasket.map(order => {
          if (order.side !== '') {
            const selectedProduct = products[order.product_id];
            const {
              contract_unit_currency: contractUnitCurrency,
              contract_value: contractValue,
              notional_type: notionalType,
              quoting_asset: quotingAsset,
              underlying_asset: underlyingAsset,
            } = selectedProduct;
            const priceValue = calculateDefaultContractValue(
              order.size,
              contractValue,
              notionalType,
              quotingAsset,
              underlyingAsset
            );
            return (
              (<div
                className={styles.orderRow}
                key={order.product_id}
                data-palette="MobileBasketOrdersOrderPopup">
                <div className={styles.buySellColumn}>
                  <Render when={order.side === 'buy'}>
                    <button
                      data-testid="mobile-basket-orders-popup-buy-button"
                      className={styles.buyButton}
                      onClick={() => handleBuySellClick(order, 'sell')}
                      type="button"
                    >
                      B
                    </button>
                  </Render>
                  <Render when={order.side === 'sell'}>
                    <button
                      data-testid="mobile-basket-orders-popup-sell-button"
                      className={styles.sellButton}
                      onClick={() => handleBuySellClick(order, 'buy')}
                      type="button"
                    >
                      S
                    </button>
                  </Render>
                  <div className={styles.contractColumn}>{order.symbol}</div>
                </div>
                <div className={styles.sizeColumn}>
                  <span className={styles.size}>{priceValue || '-'}</span>
                  <span className={styles.placeholder}>
                    {`x${contractValue} ${contractUnitCurrency}`}
                    <span className={styles.deleteColumn}>
                      {showLoading ? (
                        <SpinnerLoader />
                      ) : (
                        <button
                          className={styles.deletaButton}
                          onClick={() => handleDeleteClick(order)}
                          data-testid="mobile-basket-orders-popup-delete-btn"
                          type="button"
                          data-palette="MobileBasketOrdersOrderPopup">
                          <TrashIcon />
                        </button>
                      )}
                    </span>
                  </span>
                </div>
              </div>)
            );
          }
          return null;
        })}
      </Box>
      <div className={styles.ordersContainer}>
        <div className={styles.ordersAdded}>
          <span className={styles.ordersNumb}>{filteredOrders?.length}</span>
          <span>
            {`${
              filteredOrders?.length > 1
                ? t('basketOrders:contracts')
                : t('basketOrders:contract')
            } ${t('basketOrders:added')}`}
          </span>
        </div>
        <div className={styles.buttonsContainer}>
          <DeltaButton
            onClick={handleClearAllBtnClick}
            variant="secondary-t1"
            className={cn(styles.clearAllBtn, styles.ordersPopupBtn)}
            testId="mobile-basket-orders-clearall-btn"
            data-testid="mobile-basket-orders-clearall-btn"
            loading={showLoading}
          >
            {showLoading ? <SpinnerLoader /> : t('basketOrders:clearAll')}
          </DeltaButton>
          <DeltaButton
            onClick={handleDoneBtnClick}
            variant="primary-t5"
            className={cn(styles.doneBtn, styles.ordersPopupBtn)}
            testId="mobile-basket-orders-done-btn"
            data-testid="mobile-basket-orders-done-btn"
            text={t('basketOrders:done')}
          />
        </div>
      </div>
    </div>)
  );
};

export default MobileBasketOrdersOrderPopup;
