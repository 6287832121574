import { useEffect, RefObject } from 'react';

const useResizeObserver = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      callback?.();
    });

    if (ref?.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [callback, ref]);
};

export default useResizeObserver;
