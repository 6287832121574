/* eslint-disable import/no-unresolved */

import React, { MouseEventHandler } from 'react';

import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { isAuthenticated } from 'actions/user';
import { getSpotIndexSymbol } from 'components/basketOrders/mobileBasketOrders/mobileAssetDropdown/spotPriceComponent';
import { ORDER_SIDE } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { isMobileDeviceOnly, noop } from 'helpers';
import { useBasketBuySellButtonState, useAppSelector } from 'hooks';
import {
  basketActiveUnderlyingAssetSelector,
  basketOrderSwitchSelector,
  enabledPortfolioAssetsSelector,
  userSelector,
} from 'selectors';
import { TickIcon } from 'styles/assets/icons';
import { OrdersObject } from 'types/basketOrders';
import { Product } from 'types/IProducts';

import styles from './style.module.scss';

interface BuySellHoverButtonProps {
  product: Product;
  className?: string;
  isTableHoverButton: boolean;
  buyBtnClassName?: string;
  sellBtnClassName?: string;
  handleBtnClick?: (side: string) => void;
  order?: OrdersObject;
  onMouseOver?: MouseEventHandler<HTMLDivElement> | undefined;
}

const BuySellHoverButton = ({
  product,
  className,
  isTableHoverButton,
  buyBtnClassName,
  sellBtnClassName,
  handleBtnClick,
  order,
  onMouseOver,
}: BuySellHoverButtonProps) => {
  const { buyActive, sellActive, handleBuySellClick } = useBasketBuySellButtonState(
    product,
    isMobile || isMobileDeviceOnly() ? 'Mobile Basket Orders' : 'Options Chain'
  );

  const { t } = useTranslation(['common']);
  const user = useAppSelector(userSelector);
  const mobileActiveAssetSymbol = useAppSelector(basketActiveUnderlyingAssetSelector);
  const isBasketOrdersView = useAppSelector(basketOrderSwitchSelector);

  const isLoggedIn = isAuthenticated(user);
  const enabledPortfolios = useAppSelector(enabledPortfolioAssetsSelector);

  const showButtons = () => {
    if (isLoggedIn) {
      const marginMode = user.margin_mode;
      if (
        isBasketOrdersView &&
        (marginMode === MARGIN_MODE.ISOLATED || marginMode === MARGIN_MODE.CROSS)
      ) {
        return true;
      }

      if (marginMode === MARGIN_MODE.PORTFOLIO) {
        const mobileSpotIndexSymbol = getSpotIndexSymbol(mobileActiveAssetSymbol);
        const activeAssetSymbol = isMobile
          ? mobileSpotIndexSymbol
          : product?.spot_index?.symbol;
        const isCurrentAssetPortfolioMarginEnabled =
          !!enabledPortfolios[activeAssetSymbol];
        if (isBasketOrdersView && isCurrentAssetPortfolioMarginEnabled) {
          return true;
        }
        return false;
      }
    }

    return false;
  };

  const onButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    activeSide: string
  ) => {
    const side = activeSide === 'buy' ? ORDER_SIDE.BUY : ORDER_SIDE.SELL;
    if (isMobile || isMobileDeviceOnly()) {
      handleBtnClick?.(side);
      if (order?.size) {
        handleBuySellClick(side, isTableHoverButton);
      }
    } else if (!isMobile) {
      handleBuySellClick(side, isTableHoverButton);
    }
    event.stopPropagation();
  };

  return showButtons() ? (
    <div
      className={cn(styles.container, {
        [className]: className,
      })}
      onMouseOver={onMouseOver}
      onFocus={noop}
      data-palette="BuySellHoverButton">
      <button
        type="button"
        data-testid="buy-hover-button"
        className={cn(styles.buyButton, buyBtnClassName || '')}
        onClick={e => onButtonClick(e, 'buy')}
      >
        {buyActive ? <TickIcon /> : t('common:buy')}
      </button>
      <button
        type="button"
        data-testid="sell-hover-button"
        className={cn(styles.sellButton, sellBtnClassName || '')}
        onClick={e => onButtonClick(e, 'sell')}
      >
        {sellActive ? <TickIcon /> : t('common:sell')}
      </button>
    </div>
  ) : null;
};

export default BuySellHoverButton;
