/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { VANILLA_SETTLING_ASSET } from 'constants/constants';
import { convertBtcToUsd } from 'helpers/formulas';
import { isNil } from 'helpers/ramda';
import {
  cropAfterDecimals,
  numberCommaSeparator,
  addZeroesUntilCorrectPrecision,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
// import PropTypes from 'PropTypes';
import { walletSpotPriceBySymbol } from 'selectors/priceSelectors';
import TableCell from 'UIKit/TableCell';

const BalanceCell = props => {
  const { value, id, asset, isPortfolio, showApproxValue } = props;
  const precision = balanceAsset => {
    const symbol = balanceAsset?.symbol;
    const minimumPrecision = balanceAsset?.minimum_precision;
    const balanceAssetPrecision = balanceAsset?.precision;
    return symbol === 'BTC' ? balanceAssetPrecision : minimumPrecision;
  };
  const roundedValue = numberCommaSeparator(cropAfterDecimals(value, precision(asset)));
  if (isNil(roundedValue)) return null;
  let spotPrice = 0;
  switch (id) {
    case 'BTC':
      spotPrice = Number(walletSpotPriceBySymbol(`.DEXBT${VANILLA_SETTLING_ASSET}`));
      break;
    case 'USDT':
      spotPrice = 1;
      break;
    default:
      spotPrice = Number(walletSpotPriceBySymbol(`.DE${id}${VANILLA_SETTLING_ASSET}`));
      break;
  }

  const usdNumber = convertBtcToUsd(value, spotPrice);
  const formattedNumber = numberCommaSeparator(usdNumber);

  return (
    <TableCell
      displayValue={
        Number(value) !== 0
          ? roundedValue
          : showApproxValue
          ? `~${addZeroesUntilCorrectPrecision(0, precision(asset))}`
          : addZeroesUntilCorrectPrecision(0, precision(asset))
      }
      displayOffsetValue={
        Number(roundedValue) !== 0 && id !== VANILLA_SETTLING_ASSET && !isPortfolio
          ? `~$${formattedNumber}`
          : null
      }
      title={props?.htmlTitle}
    />
  );
};

BalanceCell.defaultProps = {
  value: 0,
};

// BalanceCell.propTypes = {
//   value: PropTypes.string,
//   id: PropTypes.oneOf(Object.values(ENABLED_WALLETS)).isRequired,
//   htmlTitle: PropTypes.string,
// };

export default subscribe(BalanceCell);
