/* eslint-disable @typescript-eslint/naming-convention */
import GridLayout from 'react-grid-layout';

import type { Asset } from './IAsset';
import type { Product } from './IProducts';

export type Price = number;
export type ProductId = string;
export type ProductSymbol = string;
export type Side = 'buy' | 'sell';
export type Precision = number;

export interface ISpotIndex {
  symbol: ProductSymbol;
}
/* eslint-disable @typescript-eslint/naming-convention */
export enum TradePanelTypes {
  Option = 'option',
  Basket = 'Basket',
  OptionChart = 'optionChart',
  Chart = 'chart',
  OptionWithBasket = 'OptionWithBasket',
  OptionChartWithBasket = 'OptionChart',
}
export type TradingStatus =
  | 'operational'
  | 'disrupted_cancel_only'
  | 'disrupted_post_only';

export type PriceClubbingValues = number[];

export interface IProductSpecs {
  floating_ir_label: any;
  rate_exchange_interval: number;
  fixed_ir_index: string;
  floating_ir_index: string;
  floating_rate_max: string;
  floating_rate_min: string;
  funding_log_interval: number;
}

export interface IUiConfig {
  price_clubbing_values: PriceClubbingValues;
}

export type LastTradePrice = string;
export type LastTradePriceMovement = 'up' | 'down';

export interface ILastTrade {
  product_id?: number;
  price: LastTradePrice;
  price_movement: LastTradePriceMovement;
  has_changed: boolean;
  datetime?: any;
  timestamp: number;
}

export interface ILastTrades {
  [extraProps: string]: ILastTrade;
}

export interface IBook {
  depth?: string;
  limit_price?: string;
  price?: string | number;
  size?: number;
}

export interface IOrderbook {
  sell: IBook[];
  buy: IBook[];
  recent_trades: ILastTrade[];
  auction_equilibrium_price: Price | null;
  lastSequenceNumber: number;
}

export interface IOrderbooks {
  [extraProps: string]: IOrderbook;
}

export interface IPagination {
  'x-pagination-total': number;
  'x-pagination-page-size': number;
  'x-pagination-page-num': number;
}

export interface IChartHistory {
  [anyProp: string]: {
    [anyProp: string]: {
      s: string;
      t: number[];
      o: number[];
      h: number[];
      l: number[];
      c: number[];
      v: number[];
    };
  };
}

export interface ISparkLineData {
  [anyProp: string]: Readonly<Array<[number, number]>>;
}

export interface ISparkLines {
  [anyProp: string]: Array<{
    time: number;
    value: number;
  }>;
}
export interface WithdrawalLimits {
  bonus?: unknown;
  blocked_amount?: string;
  max_crypto_withdrawal_without_forfeiting?: string;
  max_crypto_withdrawal_after_forfeiting?: string;
  max_withdrawal_after_forfeiting?: string;
  max_withdrawal_without_forfeiting?: string;
  deto_withdrawal_fee_discount_enabled?: boolean;
}
export interface IProductObj {
  product: Product;
}

export interface ProductById {
  [pId: number]: Product;
}

export interface IOpenOrder extends IProductObj {
  side: Side;
}

export interface IOpenPosition extends IProductObj {
  entry_price: string;
  size: string;
  margin: string;
  roe?: any;
}

// tslint:disable-next-line:no-empty-interface
export interface IOpenStopOrder extends IProductObj {}

export interface FiatAsset {
  asset_symbol?: string;
  custodian_asset_symbol?: string;
  custodian_network?: string;
  fiat_deposit_custodian?: string;
  network?: string;
  payment_tag?: string | null;
  sort_priority?: number | null;
  asset_network?: {
    chain_id: number | null;
    token_address?: string | null;
  };
  delta_wallet_address?: string;
  is_subaccount_allowed?: boolean;
  base_deposit_fee?: number;
  variable_deposit_fee?: number;
  min_deposit_amount?: number;
  max_deposit_amount?: number;
}

export interface ITradeLayout {
  resetScreenLayout: boolean;
  showRecentTradePanel: boolean;
  tradingViewVisibleConfigure: {
    [key: string]: {
      charting: boolean;
      optionChain: boolean;
      orderBook: boolean;
      recentTrade: boolean;
      orderPlace: boolean;
      positions: boolean;
      basketOrders: boolean;
    };
  };
  gridLayout?: {
    [key: string]: {
      lg: GridLayout.Layout[];
      md: GridLayout.Layout[];
    };
  };
  isChanged: boolean;
  holdingPanelHeight?: number;
}
interface FundSpecs {
  daily_fund_rewards: string;
  reward_vesting_cliff?: string;
  staking_apy?: number;
  reward_vesting_duration?: number;
  total_fund_holding_limit?: number;
  user_holding_limit: number;
}

export interface GeneralFunds {
  asset: Asset;
  current_nav: string;
  daily_withdrawal_limit: string;
  description: string;
  fund_specs: FundSpecs;
  fund_type: string;
  id: number;
  launch_time: Date;
  management_fee: string;
  max_gross_leverage: string;
  min_transaction_size: string;
  performance_fee: string;
  short_description: string;
  state: string;
  symbol: string;
  total_investors: number;
  total_rewards: string;
  total_units: string;
  withdrawal_cutoff_time: string;
  withdrawal_time: string;
}

export interface SpotIndices {
  config?: Config | null;
  constituent_exchanges?: ConstituentExchange[] | null;
  constituent_indices?: ConstituentIndices | null;
  description?: string;
  health_interval?: number;
  id?: number;
  impact_size?: string;
  index_type?: string;
  is_composite?: boolean;
  price_method?: string;
  quoting_asset_id?: number;
  symbol?: string;
  tick_size?: string;
  underlying_asset_id?: number;
}

export interface Config {
  impact_size?: ImpactSize;
  quoting_asset?: string;
  service_id?: number;
  underlying_asset?: string;
}

export interface ImpactSize {
  max_impact_size?: number;
  min_impact_size?: number;
  step_value?: number;
}

export interface ConstituentExchange {
  exchange?: string;
  health_interval?: number;
  health_priority?: number;
  weight?: number;
}

export interface ConstituentIndices {
  expression?: string;
  indices?: Indices;
}

export interface Indices {
  e1?: string;
  e2?: string;
}
export default interface ITrade {
  assets: Record<number, Asset>;
  depositAssets: Record<number, Asset>;
  products: Product[];
  roboStrategies: object[];
  yieldStrategies: object[];
  stakingPool: object[];
  fundsList: object[];
  selected_asset: Record<string, any> | null;
  selected_product: Product | null;
  loadingOrderbook: boolean;
  orderbooks: IOrderbooks;
  lastTrades: ILastTrades;
  // open_positions: object;
  // loadingOpenPositions: boolean;
  pendingWithdrawals: object[];
  withdrawalFailed: boolean;
  withdrawalFailedMsg: string;
  pages: number;
  isOnline: boolean;
  offlineTime: number | null;
  isSocketReconnecting: boolean;
  socketDisconnectTime: number | null;
  chartHistory: IChartHistory;
  productsLoaded: boolean;
  sparklines: ISparkLines;
  product_names: string;
  withdrawalFailedStatus?: string;
  withdrawalLimits: WithdrawalLimits;
  tradingCredits: Record<string, { amount: number }>;
  selectedContractType: string;
  stats: Stats;
  fiatCurrenciesList: object[];
  fiatDepositAssetsList: FiatAsset[];
  previousSelectedProductId: number | null;
  showPortfolioHighlighter: boolean;
  spot_indices: { [key: string]: SpotIndices };
}
export interface Stats {
  futures?: VolumeObj;
  options?: VolumeObj;
  last_30_days_volume?: number;
  last_7_days_volume?: number;
  total_daily_volume_7d?: TotalDailyVolume7D[];
  total_volume?: number;
}

export interface VolumeObj {
  last_24h_volume?: number;
  last_7_days_volume?: number;
}

export interface TotalDailyVolume7D {
  time?: Date;
  turnover_usd?: number;
}

export interface TradePreferences {
  default_auto_topup?: boolean;
  deto_for_commission?: boolean;
  interest_credit?: boolean;
  email_preferences?: PreferencesData;
  notification_preferences?: PreferencesData;
  // enabled_portfolios?: null;
  is_data_set?: boolean;
  vip_level?: number;
  vip_discount_factor?: string;
  price_alert_assets: string[];
}

export interface PreferencesData {
  adl?: boolean;
  liquidation?: boolean;
  margin_topup?: boolean;
  order_cancel?: boolean;
  order_fill?: boolean;
  stop_order_trigger?: boolean;
}
export interface LastTrade {
  buyer_role: 'maker' | 'taker';
  datetime: Date;
  has_changed: boolean;
  price: string;
  price_movement: 'up' | 'down';
  seller_role: 'maker' | 'taker';
  size: number;
  timestamp: number;
}

export interface GetUsdtRatesData {
  data: {
    asset_amount: string;
    asset_symbol: string;
    type: string;
  };
}
