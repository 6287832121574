import styled from 'styled-components';
import MarketsTable from 'components/marketsTable';
import { device } from 'helpers/screenSizes';

const UITable = styled(MarketsTable)`
  .rthfc-th-fixed-left {
    position: -webkit-sticky;
    position: sticky !important;
    z-index: 1;
    background-color: ${props => props.theme.secondaryBackground};
  }
  .rthfc-td-fixed-left {
    position: -webkit-sticky;
    position: sticky !important;
    z-index: 1;
  }

  color: ${props => props.theme.primaryText};
  border: none !important;
  font-size: 12.1px;

  .rt-table {
    min-height: 280px;
  }

  &.ReactTable .rt-th {
    overflow: unset;
  }

  &.ReactTable .rt-table {
    overflow: hidden;
  }

  .rt-thead {
    color: ${props => props.theme.primaryText};
    background-color: ${props => props.theme.secondaryBackground};
    border-bottom: 1px solid ${props => props.theme.separator};

    .rt-th.-sort-desc {
      box-shadow: none !important;
    }

    .rt-th.-sort-asc {
      box-shadow: none !important;
    }

    .rt-th {
      font-family: var(--fontRegular) !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      border-right: 0px !important;
    }

    .rt-tr {
      text-align: left !important;
      padding-left: 24px;

      @media ${device.down.sm} {
        padding-left: 2px;
      }
    }
  }

  .-loading.-active {
    background: ${props => props.theme.primaryBackground} !important;
  }

  .rt-tbody {
    color: ${props => props.theme.secondaryText};

    .rt-tr-group {
      flex: none;
      border-bottom: 1px solid ${props => props.theme.separator};
    }

    .rt-tr {
      flex: none;
      padding-left: 24px;
      @media ${device.down.sm} {
        padding-left: 2px;
      }
    }

    .rt-td {
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: center;
      padding: 10px 5px;
      overflow: hidden;
      border-right: 0px !important;

      .profit,
      .loss {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        justify-content: center;
      }

      @media ${device.down.xs} {
        font-size: 10px;
      }
    }
  }

  .pagination-bottom {
    background: ${props => props.theme.primaryBackground};
    font-size: 12.1px;
    margin-top: 20px;

    @media ${device.down.sm} {
      margin-top: 20px;
    }

    .-pagination {
      box-shadow: none;
      border: none;
      padding: 0;

      .-pageInfo {
        margin: 2px 10px;

        input {
          padding: 3px 7px;
          background: ${props => props.theme.primaryBackground};
        }
      }

      .-previous,
      .-next {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default UITable;
