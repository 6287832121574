import { useCallback, useEffect, useRef, useState } from 'react';

import { parseErrorMsg } from 'helpers/utils';
import { AllUnionMemberKeys, IsUndefined } from 'types/utils';

const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

type Status = AllUnionMemberKeys<typeof STATUS>;

type DefaultApiResponse = {
  result?: any;
  error?: any;
};

type RequestFn<RequestArgs = undefined> = (
  params: IsUndefined<RequestArgs>
) => Promise<DefaultApiResponse>;

interface UseApiOptions<RequestArgs = any, Data = any, Errored = any, Response = any> {
  requestOnMount?: boolean;
  requestFn: RequestFn<RequestArgs>;
  shouldPickResult?: boolean;
  onMutateData?: (response: Response) => Data;
  onError?: (error: Errored) => void;
  onSuccess?: (data: Data) => void;
  onSettled?: (data: Data | null, error: Errored | null) => void;
}

interface UseApiResult<RequestArgs = undefined, Data = any, Errored = any> {
  data: Data | null;
  error: Errored | null;
  isLoading: boolean;
  status: Status;
  requestor: (params?: RequestArgs) => Promise<[Errored | null, Data | null]>;
  reset: () => void;
}

function parseError<Exception>(exception: Exception | any) {
  switch (exception?.status) {
    case 404: {
      return 'NOT_FOUND';
    }

    default: {
      return parseErrorMsg(exception);
    }
  }
}

function useApi<RequestArgs = any, Data = any, Errored = any, Response = any>(
  options: UseApiOptions<RequestArgs, Data, Errored, Response>
): UseApiResult<RequestArgs, Data, Errored> {
  const {
    requestOnMount = false,
    shouldPickResult = true,
    requestFn,
    onError,
    onSuccess,
    onMutateData,
    onSettled,
  } = options;

  const requestFnRef = useRef<RequestFn<RequestArgs>>(requestFn);
  const ignoreResponseRef = useRef(false);

  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState<Errored | null>(null);
  const [status, setStatus] = useState<Status>(STATUS.IDLE);

  const requestorFn = useCallback(
    async (params = undefined): Promise<[Errored | null, Data | null]> => {
      setStatus(STATUS.LOADING);
      let dataState = null;
      let errorState = null;

      try {
        const response = (await requestFnRef.current(params)) as DefaultApiResponse;

        if (response?.error) {
          throw response.error;
        }

        if (ignoreResponseRef.current) {
          return [null, null];
        }

        let newData = shouldPickResult ? response?.result ?? response : response;

        if (onMutateData) {
          newData = onMutateData(newData);
        }

        setData(newData);
        dataState = newData;
        setStatus(STATUS.SUCCESS);
        onSuccess?.(newData);

        return [null, newData];
      } catch (caughtError: Error | Errored | any) {
        const parsedError = parseError<Error | Errored>(caughtError);

        setError(parsedError?.error ?? parsedError);
        errorState = parsedError?.error ?? parsedError;
        setStatus(STATUS.ERROR);
        onError?.(parsedError?.error ?? parsedError);

        return [parsedError?.error ?? parsedError, null];
      } finally {
        onSettled?.(dataState, errorState);
      }
    },
    [onError, onMutateData, onSettled, onSuccess, shouldPickResult]
  );

  const reset = useCallback(() => {
    setData(null);
    setError(null);
    setStatus(STATUS.IDLE);
  }, []);

  useEffect(() => {
    if (requestOnMount) {
      requestorFn(undefined);

      return () => {
        ignoreResponseRef.current = true;
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestOnMount]);

  return {
    data,
    error,
    status,
    isLoading: status === STATUS.LOADING,

    requestor: requestorFn,
    reset,
  };
}

type UseApi<RequestArgs = any, Data = any, Errored = any, Response = any> = ReturnType<
  typeof useApi<RequestArgs, Data, Errored, Response>
>;

export type { Status, UseApi, UseApiResult };

export { STATUS };

export default useApi;
