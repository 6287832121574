import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: ${props => props.theme.secondaryBackground};
  z-index: 2;
`;

export default Overlay;
