/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

import BalanceCoin from 'components/balance/coin';
import { TradeFeeCreditPopover } from 'components/trade_fee_credits/';
import { pathOr } from 'helpers/ramda';
import { getTransactionType, getTransactionTime } from 'helpers/transactions';
import { assetPrecision, negativeBalanceCheck } from 'helpers/utils';

import BalanceCell from '../holdings_balance/balanceCell';
import { BalanceActions } from '../holdings_balance/helpers';

// import { VestingBalancePopover } from 'components/Header/partials/vestingBalancePopover';

const LocalizedLabel = ({ prop, localeKey }) => {
  const { t } = useTranslation(['account']);
  const str = `account:${prop}.${localeKey}`;
  return t(str);
};

export const getTransactionsColumns = (wallet, symbolData) => [
  {
    id: 'time',
    Header: <LocalizedLabel prop="transactionColumns" localeKey="time" />,
    accessor: 'created_at',
    Cell: ({ value }) => getTransactionTime(value),
    sortable: false,
    filterable: false,
  },
  {
    id: 'type',
    Header: <LocalizedLabel prop="transactionColumns" localeKey="type" />,
    accessor: getTransactionType,
    sortable: false,
    filterable: false,
  },
  {
    id: 'Amount',
    Header: `${(<LocalizedLabel prop="transactionColumns" localeKey="Amount" />)} ${symbolData ? `(${symbolData})` : ''
      }`,
    accessor: prop => <BalanceCell value={prop.amount} {...prop} />,
    sortable: false,
    filterable: false,
  },
  {
    id: 'contract',
    Header: <LocalizedLabel prop="transactionColumns" localeKey="contract" />,
    accessor: prop => pathOr(null, ['product', 'symbol'], prop),
    sortable: false,
    filterable: false,
  },
  {
    id: 'wallet_balance',
    Header: `${(
      <LocalizedLabel prop="transactionColumns" localeKey="walletBalance" />
    )}  ${symbolData ? `(${symbolData})` : ''}`,
    accessor: prop => <BalanceCell value={prop.balance} {...prop} />,
    sortable: false,
    filterable: false,
  },
];

export const getWalletColumns = (
  setCurrencyConverter,
  getCoinTransactions,
  disableFeeCredit
) => [
    {
      id: 'coin',
      Header: <LocalizedLabel prop="walletColumns" localeKey="coin" />,
      accessor: prop => <BalanceCoin {...prop} />,
      width: 80,
    },
    {
      id: 'balance',
      Header: <LocalizedLabel prop="walletColumns" localeKey="walletBalance" />,
      accessor: prop => (
        <div
          {...(prop.coin === 'BTC' ? { title: prop.balance } : {})}
          data-palette="getWalletColumns">
          <BalanceCell value={prop.balance} {...prop} />
        </div>
      ),
    },
    {
      id: 'position_margin',
      Header: <LocalizedLabel prop="walletColumns" localeKey="positionMargin" />,
      accessor: prop => (
        <div
          {...(prop.coin === 'BTC' ? { title: prop.position_margin } : {})}
          data-palette="getWalletColumns">
          <BalanceCell value={prop.position_margin} {...prop} />
        </div>
      ),
    },
    {
      id: 'order_margin',
      Header: <LocalizedLabel prop="walletColumns" localeKey="orderMargin" />,
      accessor: prop => (
        <div
          {...(prop.coin === 'BTC' ? { title: prop.order_margin } : {})}
          data-palette="getWalletColumns">
          <BalanceCell value={prop.order_margin} {...prop} />
        </div>
      ),
    },
    // {
    //   id: 'interest_earned',
    //   Header: <LocalizedLabel prop="walletColumns" localeKey="interestEarned"  />,
    //   accessor: prop => (
    //     <div {...(prop.coin === 'BTC' ? { title: prop.interest_credit } : {})}>
    //       <BalanceCell value={prop.interest_credit} {...prop} />
    //     </div>
    //   ),
    // },
    {
      id: 'available_balance',
      Header: <LocalizedLabel prop="walletColumns" localeKey="availableBal" />,
      accessor: prop => {
        const availableBalance = negativeBalanceCheck(
          prop.available_balance,
          assetPrecision(prop.asset)
        );
        return (
          (<div title={prop.available_balance} data-palette="getWalletColumns">
            <BalanceCell value={availableBalance} {...prop} />
          </div>)
        );
      },
    },
    {
      id: 'unvested_amount',
      Header: <LocalizedLabel prop="walletColumns" localeKey="unvestedAmount" />,
      accessor: prop => (
        <div data-palette="getWalletColumns">
          <BalanceCell value={prop.unvested_amount} {...prop} />
        </div>
      ),
    },
    {
      id: 'fee_credits',
      show: disableFeeCredit,
      Header: () => (
        <>
          <LocalizedLabel prop="walletColumns" localeKey="feeCredit" />
          <TradeFeeCreditPopover />
        </>
      ),
      accessor: prop => (
        <div
          {...(prop.coin === 'BTC' ? { title: prop.trading_fee_credit } : {})}
          data-palette="getWalletColumns">
          <BalanceCell value={prop.trading_fee_credit} {...prop} />
        </div>
      ),
    },
    {
      id: 'action',
      Header: <LocalizedLabel prop="walletColumns" localeKey="action" />,
      accessor: prop => {
        const networks = (prop.asset && prop.asset.networks) || [];
        return (
          <BalanceActions
            isDepositStatusEnabled={networks[0]?.deposit_status === 'enabled'}
            isWithdrawStatusEnabled={networks[0]?.withdrawal_status === 'enabled'}
            from="account-balance-page"
            coin={prop.coin}
            setCurrencyConverter={setCurrencyConverter}
          />
        );
      },
      headerClassName: 'text-center',
      sortable: false,
      filterable: false,
      minWidth: 250,
    },
  ];
