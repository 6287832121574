import React from 'react';
import styled from 'styled-components';

const CloseStyle = styled.div`
  .circle {
    background: transparent;
    padding: 5px;
    height: 40px;
    width: 40px;
    transform: rotate(45deg);
    border-radius: 50%;
    border: 2px solid ${props => props.theme.separator};
    cursor: pointer;
  }

  .vertical {
    position: absolute;
    top: 6px;
    right: 17px;
    height: 22px;
    border-right: 2px solid ${props => props.theme.separator};
  }

  .horizontal {
    position: absolute;
    top: 16px;
    right: 7px;
    width: 22px;
    border-top: 2px solid ${props => props.theme.separator};
  }
`;

const Close = ({ onClick, className }) => (
  <CloseStyle onClick={onClick} className={'close ' + className}>
    <div className="circle">
      <div className="vertical" />
      <div className="horizontal" />
    </div>
  </CloseStyle>
);

export default Close;
