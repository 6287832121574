import { useRef } from 'react';

import { QR_CODE_ISSUER } from 'constants/constants';
import { generateQRCodeSecretUrl, generateSecretCode } from 'helpers/utils';
import { userSelector } from 'selectors/otherSelectors';
import { useAppSelector } from 'storeHooks';

/**
 * React Hook to get the QR url and google secret for two factor authentication using google authenticator
 *
 * */

const useTwoFa = () => {
  const user = useAppSelector(userSelector);
  const secret = generateSecretCode();
  const qrUrl = generateQRCodeSecretUrl({
    secret,
    accountName: user?.email,
    issuer: QR_CODE_ISSUER,
  });

  const data = useRef({
    google_secret: secret,
    qr_url: qrUrl,
  });

  return {
    data: data.current,
  };
};

export default useTwoFa;
