/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { dateFormat } from 'components/Header/partials/tickerUnit';
import { equals } from 'helpers/ramda';
import CircleIcon from 'styles/assets/icons/CircleIcon';
import WarningIconSymbol from 'styles/assets/icons/WarningIconSymbol';

import styles from './index.module.scss';

const HaltedLabel = props => {
  const { selectedProduct, showDuration = true, classname = '' } = props;
  const haltedLabel =
    selectedProduct && equals(selectedProduct.trading_status, 'disrupted_cancel_only')
      ? 'Cancel'
      : 'Post';

  const auctionFinishTime = selectedProduct?.auction_finish_time;
  const [duration, setduration] = useState(
    auctionFinishTime ? dateFormat(auctionFinishTime) : '-'
  );

  useEffect(() => {
    const setTime = setInterval(() => {
      const formatDate = dateFormat(auctionFinishTime);
      if (formatDate === '00h:00m:00s') {
        setduration(formatDate);
        clearInterval(setTime);
      }
      setduration(formatDate);
    }, 1000);

    return () => clearInterval(setTime);
  }, [auctionFinishTime]);

  if (haltedLabel === 'Post') {
    return (
      (<div
        className={`${styles['halted-label']} ${classname}`}
        data-palette="HaltedLabel">
        {/* <ForwardIcon className={styles['forward-icon']} /> */}
        <CircleIcon />
        <div className={styles['auction-text']}>
          Auction in Progress - {haltedLabel} Only Mode
        </div>
        {showDuration && <div className={styles['auction-time']} data-palette="HaltedLabel">{duration}</div>}
      </div>)
    );
  }
  return (
    (<div
      className={`${styles['halted-label']}  ${styles['cancel-mode']} ${classname}`}
      data-palette="HaltedLabel">
      <WarningIconSymbol />
      <div className={styles['halted-text']}>
        Trading Halted - {haltedLabel} Only Mode
      </div>
      {/* {showDuration && (
          <div className={styles['auction-time']}>{duration}</div>
        )} */}
    </div>)
  );
};

export default HaltedLabel;
