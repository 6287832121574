import { useRef, memo, ReactNode } from 'react';

import useVisibleElm from 'components/Header/partials/headerDropdown/OptionsChain/useVisibleElement';

import styles from './styles.module.scss';

const Column = ({
  colClass = '',
  labelClass = '',
  label,
  children,
  renderWhen = true,
}: {
  colClass?: string;
  labelClass?: string;
  label: string;
  children: ReactNode;
  renderWhen: boolean;
}) => {
  const internalRef = useRef(null);
  const isVisible = useVisibleElm({
    ref: internalRef,
    rootMargin: '0px 0px 80px 0px',
    debugLabel: label,
  });

  if (!renderWhen) {
    return null;
  }

  return (<>
    {/*
      We are drawing a line in left of column and observing it using intersection observer
    */}
    <div
      ref={internalRef}
      style={{ top: 0, bottom: 0, width: '1px' }}
      data-palette="Column" />
    <div className={`${styles.column} ${colClass}`} data-palette="Column">
      <div className={`${styles.header} ${styles.rightAlignedHeader} ${labelClass}`}>
        {label}
      </div>
      {isVisible ? children : null}
    </div>
  </>);
};

export default memo(Column);
