import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import styles from './index.module.scss';

function NavTooltip({ children, arrow, placement, classes, title, ...rest }) {
  return (
    <Tooltip
      placement={placement}
      arrow={arrow}
      title={title}
      classes={{
        tooltipPlacementRight: styles.tooltipPlacementRight,
        tooltip: styles.tooltip,
        ...classes,
      }}
      {...rest}>
      {children}
    </Tooltip>
  );
}

NavTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object,
  arrow: PropTypes.bool,
};

NavTooltip.defaultProps = {
  title: '',
  placement: 'right',
  classes: {},
  arrow: true,
};

export default NavTooltip;
