/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import {
  changeBasketOrdersScreen,
  deleteAllOrdersFromBasket,
  deleteBasketResult,
  deleteProductFromBasket,
  editOrdersFromBasket,
  showBasketError,
  subscribeBasketOrderOrderbook,
  unsubscribeBasketOrderOrderbook,
} from 'actions/basketOrders';
import AccountReduceOnlyModeWarning from 'components/placeorder/partials/OrderContainer/accountReduceOnlyWarning';
// import OrderSectionPopoverContent from 'components/popover/OrderSectionPopoverContent';
import Render from 'components/render';
import { RenderForGlobalExchange } from 'components/render/RenderByExchangeType';
import SpinnerLoader from 'components/spinnerLoader';
import { BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
// import { useHistory } from 'react-router';
import { MARGIN_MODE, SIDE } from 'constants/enums';
import { isOptions } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { isEmpty, values } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import { useToggle } from 'hooks';
import {
  basketActiveUnderlyingAssetSelector,
  basketOrderCurrentScreenSelector,
  basketOrderOrdersSelector,
  basketSuccessResponseSelector,
  productsSelector,
  // selectedProductSelector,
  showBasketLoadingSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
// import { toogleOptionChainVisualisation } from 'actions/optionChain';
// import { CircularExclamationWarning, CircularTick } from 'styles/assets/icons';
import { IOrdersObject, OrdersObject } from 'types/basketOrders';
// import { AutoDisappearingComponent, PopoverWithCustomIcon } from 'UIKit';

import { ValidationIconComponent } from '../helpers';
import { SquareAddIcon, TrashIcon } from '../icons';
import BasketLeverageButton from './basketLeverageButton';
import BasketOrdersOrderbook from './basketOrdersOrderbook';
import EstimateBasketMarginAndPlaceorder from './estimateBasketMarginAndPlaceorder';
import IsolatedBasketPlaceOrderSection from './isolatedBasketPlaceOrderSection';
import MobileOrderPlacementRow from './mobileOrderPlacementRow';
import styles from './orders.module.scss';
import PriceInput from './priceInput';
import BasketOrderQuantity from './quantity';
// import { getProductUrlByContractType, isOptions } from 'helpers';

interface Props {
  asset: string;
}

type side = 'buy' | 'sell';

const OrdersPlacementScreen = ({ asset }: Props) => {
  const dispatch = useAppDispatch();
  // const history = useHistory();
  const { t } = useTranslation(['basketOrders', 'trading']);

  const [show, setShow] = useState(true);
  const [currentOrderBook, setcurrentOrderBook] = useState({
    product_id: 0,
    showOrderbook: false,
  });
  const marginMode = useAppSelector(state => userMarginModeSelector(state));
  const products = useAppSelector(state => productsSelector(state));
  const ordersInBasket: IOrdersObject = useAppSelector(state =>
    basketOrderOrdersSelector(state)
  );
  const activeUnderlyingAsset = useAppSelector(basketActiveUnderlyingAssetSelector);
  const currentScreenView = useAppSelector(basketOrderCurrentScreenSelector);
  const showLoading = useAppSelector(state => showBasketLoadingSelector(state));
  const [showIsolatedLoader, toggleShowIsolatedLoader] = useToggle(false);
  const newOrderResponse = useAppSelector(state => basketSuccessResponseSelector(state));
  const [isolatedApiResponse, setIsolatedApiResponse] = useState({});
  const liveOrdersInbasket = Object.values(ordersInBasket).filter(
    order => products[order.product_id] && order.asset === asset
  );

  // product expiry handling
  useMemo(() => {
    dispatch(showBasketError(false));
    if (values(ordersInBasket).filter(order => order.asset === asset).length) {
      values(ordersInBasket).forEach(order => {
        if (values(products).length && !products[order.product_id]) {
          dispatch(deleteProductFromBasket(ordersInBasket[order.product_id]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, ordersInBasket, asset]);

  useEffect(() => {
    return () => {
      dispatch(showBasketError(false));
      dispatch(deleteBasketResult());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !liveOrdersInbasket.length &&
      isMobile &&
      currentScreenView !== BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW
    ) {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersInBasket]);

  const handleDeleteClick = (order: OrdersObject) => {
    dispatch(deleteProductFromBasket(order));
    trackMixpanelEvent('Basket Order - Delete Icon Clicked');
  };

  const handleRemoveClick = () => {
    if (marginMode === MARGIN_MODE.PORTFOLIO) {
      dispatch(deleteAllOrdersFromBasket());
    } else {
      liveOrdersInbasket.forEach(order => {
        dispatch(deleteProductFromBasket(ordersInBasket[order.product_id]));
      });
    }

    dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.DEFAULT));
  };

  const handleAddButtonClick = () => {
    trackMixpanelEvent('Basket Order - Add Contracts Clicked', {
      underlying_coin: asset,
      margin_type: marginMode,
    });
    dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW));
  };

  const handleBuySellClick = (order: OrdersObject, side: side) => {
    const newOrders = { ...order, side };
    const { order_type: orderType, symbol } = newOrders;
    trackMixpanelEvent('Basket Order - Buy/Sell Switch Clicked', {
      underlying_coin: asset,
      margin_type: marginMode,
      contract_name: symbol,
      orderType,
    });
    dispatch(editOrdersFromBasket(newOrders));
  };

  const handleRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    order: OrdersObject
  ) => {
    const showOrderBookRow =
      currentOrderBook.product_id === order.product_id && currentOrderBook.showOrderbook;

    if (!showOrderBookRow) {
      // dispatch(unsubscribeL2Orderbook())
      dispatch(unsubscribeBasketOrderOrderbook());
      dispatch(subscribeBasketOrderOrderbook(order.symbol));
      e.stopPropagation();
      setcurrentOrderBook({
        product_id: order.product_id,
        showOrderbook: true,
      });
    }
  };

  // const onContractClick = (e, { product_id }: OrdersObject) => {
  //   if (isOptions(products[product_id.toString()]?.contract_type)) {
  //     const { contract_type, underlying_asset, symbol } = products[
  //       product_id.toString()
  //     ];
  //     const link = getProductUrlByContractType(
  //       contract_type,
  //       underlying_asset.symbol,
  //       symbol
  //     );
  //     dispatch(
  //       toogleOptionChainVisualisation(OPTIONS_CHAIN_VISUALISATIONS.CHART)
  //     );
  //     history.push(link);
  //   }
  // };

  const showLeverageButton = (order: OrdersObject) => {
    if (marginMode === MARGIN_MODE.ISOLATED) {
      const product = products[order.product_id];
      const { contract_type } = product || {};
      if (isOptions(contract_type)) {
        return order?.side === SIDE.SELL;
      }
      return true;
    }

    return false;
  };

  const responseCheck =
    marginMode === MARGIN_MODE.ISOLATED ? isolatedApiResponse : newOrderResponse;

  const showButtonLoader =
    (marginMode === MARGIN_MODE.ISOLATED && showIsolatedLoader) || showLoading;

  return (
    (<div
      className={styles['orders-container']}
      data-palette="OrdersPlacementScreen">
      <div className={styles['add-remove-contracts']}>
        <button
          type="button"
          data-testid="handle-add-button"
          className={styles['add-contracts']}
          onClick={handleAddButtonClick}
        >
          <SquareAddIcon /> {t('basketOrders:addContractButtonText', { asset })}
        </button>
        {!isEmpty(ordersInBasket) && !isMobile && (
          <button
            data-testid="remove-all"
            type="button"
            className={styles['remove-all']}
            onClick={() => handleRemoveClick()}
            disabled={showButtonLoader}
            data-palette="OrdersPlacementScreen">
            {showButtonLoader ? <SpinnerLoader /> : t('basketOrders:clearAll')}
          </button>
        )}
      </div>
      <div className={styles['header-tab']}>
        <MediaQuery query={device.up.md}>
          <div className={styles['buy-sell-column']}>B/S</div>
        </MediaQuery>
        <div className={styles['contracts-column']}>{t('trading:contract')}</div>
        <div className={styles['price-column']}>{t('trading:price')}</div>
        <div className={styles['quantity-column']}>
          {isMobile ? `Qty. (${activeUnderlyingAsset})` : 'Qty'}
        </div>
        <div className={styles['delete-column']} />
      </div>
      <div className={styles['orders-list']}>
        <RenderForGlobalExchange>
          <AccountReduceOnlyModeWarning />
        </RenderForGlobalExchange>
        {liveOrdersInbasket.map(order => {
          return (order.side && (<>
            <MediaQuery query={device.up.md}>
              <>
                <div className={styles['order-row']} data-palette="OrdersPlacementScreen">
                  <div className={styles['buy-sell-column']}>
                    {order.side === 'buy' ? (
                      <button
                        type="button"
                        data-testid="buy-button"
                        className={styles['buy-button']}
                        onClick={() => handleBuySellClick(order, 'sell')}
                        data-palette="OrdersPlacementScreen">
                        B
                      </button>
                    ) : (
                      <button
                        type="button"
                        data-testid="sell-button"
                        className={styles['sell-button']}
                        onClick={() => handleBuySellClick(order, 'buy')}
                        data-palette="OrdersPlacementScreen">
                        S
                      </button>
                    )}
                  </div>
                  <div
                    className={styles['contracts-column']}
                    // onClick={e => onContractClick(e, order)}
                  >
                    <div className={styles['contract-name']}>
                      <span
                        className={`truncate ${styles['product-name']}`}
                        style={{
                          maxWidth: showLeverageButton(order) ? '110px' : '138px',
                        }}
                        title={order.symbol}
                      >
                        {order.symbol}
                      </span>
                      <Render when={showLeverageButton(order)}>
                        <BasketLeverageButton order={order} />
                      </Render>

                      {responseCheck[order.product_id] && (
                        <ValidationIconComponent
                          isResponseSuccess={responseCheck[order.product_id]?.success}
                          errorText={responseCheck[order.product_id]?.error}
                          setShow={setShow}
                          show={show}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    data-testid="price-column"
                    aria-hidden="true"
                    className={styles['price-column']}
                    onClick={e => handleRowClick(e, order)}
                  >
                    <PriceInput order={order} asset={asset} />
                  </div>
                  <div
                    data-testid="quantity-column"
                    aria-hidden="true"
                    className={styles['quantity-column']}
                    onClick={e => handleRowClick(e, order)}
                  >
                    <BasketOrderQuantity orders={order} />
                  </div>
                  <div className={styles.delete}>
                    {showButtonLoader ? (
                      <SpinnerLoader />
                    ) : (
                      <button
                        type="button"
                        data-testid="delete-button"
                        className={styles['delete-button']}
                        onClick={() => handleDeleteClick(order)}
                        data-palette="OrdersPlacementScreen">
                        <TrashIcon />
                      </button>
                    )}
                  </div>
                </div>
                {currentOrderBook.product_id === order.product_id &&
                  currentOrderBook.showOrderbook && (
                    <BasketOrdersOrderbook
                      orderProductId={order.product_id}
                      currentOrderbook={currentOrderBook}
                      setcurrentOrderBook={setcurrentOrderBook}
                    />
                  )}
              </>
            </MediaQuery>
            <MediaQuery query={device.down.md}>
              <MobileOrderPlacementRow
                order={order}
                responseCheck={responseCheck[order.product_id]}
                setShow={setShow}
                show={show}
                handleRowClick={handleRowClick}
                currentOrderBook={currentOrderBook}
                setCurrentOrderBook={setcurrentOrderBook}
              />
            </MediaQuery>
          </>));
        })}
      </div>
      {(marginMode === MARGIN_MODE.ISOLATED || marginMode === MARGIN_MODE.CROSS) && (
        <IsolatedBasketPlaceOrderSection
          orders={liveOrdersInbasket}
          setShow={setShow}
          setIsolatedApiResponse={setIsolatedApiResponse}
          toggleShowIsolatedLoader={toggleShowIsolatedLoader}
          showLoader={showIsolatedLoader}
        />
      )}
      {!isEmpty(ordersInBasket) && marginMode === MARGIN_MODE.PORTFOLIO && (
        <EstimateBasketMarginAndPlaceorder asset={asset} setShow={setShow} />
      )}
    </div>)
  );
};

export default OrdersPlacementScreen;
