/* eslint-disable react/no-unknown-property */
import React from 'react';

import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

import { isNil } from 'helpers/ramda';

import iconstyle from './index.module.scss';

const PopoverWithCustomIcon = ({
  iconComponent,
  hoveredIconComponent,
  popoverComponent,
  transformOrigin,
  anchorOrigin,
  styles,
  parentDivStyle,
  trackEvent,
  iconClassName,
  dataTestId,
  isTopLevelContainer = true,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const containerRef = React.useRef();

  const handleClick = event => {
    if (trackEvent) {
      ReactGA.event({
        category: 'User',
        action: 'fee_offer',
      });
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const iconStyle = open ? iconstyle.active : '';

  return (
    (<div
      style={parentDivStyle}
      ref={containerRef}
      data-palette="PopoverWithCustomIcon">
      {isNil(hoveredIconComponent) ? (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          data-testid={dataTestId || 'simple-popover'}
          aria-describedby="simple-popover"
          variant="contained"
          onClick={handleClick}
          className={`${iconstyle.icon} ${iconStyle} ${
            open && iconClassName ? `${iconClassName}-active` : ''
          }`}
          data-palette="PopoverWithCustomIcon">
          {iconComponent}
        </div>
      ) : (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          data-testid={dataTestId || 'simple-popover'}
          aria-describedby="simple-popover"
          variant="contained"
          onClick={handleClick}
          data-palette="PopoverWithCustomIcon">
          {open ? hoveredIconComponent : iconComponent}
        </div>
      )}
      <Popover
        id="simple-popover"
        open={open}
        container={!isTopLevelContainer && containerRef.current}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            marginBottom: '10px',
          },
        }}
        style={styles}
        data-testid={dataTestId || 'popover-with-custom-icon'}
        className={styles && styles['popover-class']}
      >
        {popoverComponent}
      </Popover>
    </div>)
  );
};

PopoverWithCustomIcon.propTypes = {
  iconComponent: PropTypes.element.isRequired,
  hoveredIconComponent: PropTypes.elementType,
  popoverComponent: PropTypes.element.isRequired,
  transformOrigin: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  anchorOrigin: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  parentDivStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  trackEvent: PropTypes.bool,
  iconClassName: PropTypes.string,
  dataTestId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isTopLevelContainer: PropTypes.bool,
};

export default PopoverWithCustomIcon;
