/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import { Eye, EyeSlash } from 'styles/assets/icons';

const InputWrapper = styled.div`
  label {
    color: ${props => props.theme.secondaryText};
  }
  input {
    color: ${props => props.theme.primaryText};

    &:focus {
      outline: none !important;
    }
    &-webkit-autofill {
      background-color: ${props => props.theme.negative} !important;
    }
  }

  .eye {
    width: 14px;
    // height: 14px;
    margin: 3px 25px 7px 0px;
    cursor: pointer;
    color:${props => props.theme.secondaryText};
  }

  .label-color {
    color: ${props => props.theme.secondaryText} !important;
  }

  .underline-color {
    &:before,
    &:after {
      border-bottom: 1px solid ${props => props.theme.secondaryText} !important;
    }
  }

  .errorUnderLine-color {
    &:before,
    &:after {
      border-bottom: 1px solid ${props => props.theme.negative} !important;
    }
  }

  .icon-position {
    position: relative;
    left: 175px;
    top: -31px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition-delay: 9999s;
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  }
}
`;

// Jumping cursor fix.
const Input = React.forwardRef(({ value = '', ...restProps }, ref) => {
  const inputRef = React.useRef(value);
  let inputEl = ref;

  if (!inputEl) {
    inputEl = inputRef;
  }

  React.useEffect(() => {
    inputEl.current.value = value;
  }, [inputEl, value]);
  return (
    <TextField
      {...restProps}
      inputProps={{ inputMode: restProps.inputMode }}
      defaultValue={value}
      value={value}
      ref={inputEl}
    />
  );
});

export const Textfield = props => {
  const { InputProps, ...restProps } = props;
  const ErrorStatus = props.error;
  return (
    <InputWrapper>
      <Input
        margin="dense"
        fullWidth
        InputProps={{
          classes: {
            root: 'label-color',
            focused: 'label-color',
            underline: ErrorStatus ? 'errorUnderLine-color' : 'underline-color',
          },
          ...InputProps,
        }}
        InputLabelProps={{
          classes: {
            root: 'label-color',
            focused: 'label-color',
          },
        }}
        {...restProps}
      />

      {/* {ErrorStatus ? <ValidationInCorrect className="icon-position" /> : <ValidationCorrect className="icon-position" />} */}
    </InputWrapper>
  );
};

export const PasswordTextField = props => {
  const [isVisible, setVisible] = React.useState(false);

  const visibilityToggleHandler = () => setVisible(visible => !visible);

  const InputProps = {
    endAdornment: (
      // <FontAwesomeIcon
      //   className="eye"
      //   icon={isVisible ? faEye : faEyeSlash}
      //   onClick={visibilityToggleHandler}
      // />
      (<span data-palette="InputProps">
        {isVisible ? (
          <Eye
            data-testid="show-content"
            className="eye"
            onClick={visibilityToggleHandler}
          />
        ) : (
          <EyeSlash
            data-testid="hide-content"
            className="eye"
            onClick={visibilityToggleHandler}
          />
        )}
      </span>)
    ),
    ...props.InputProps,
  };

  return (
    <Textfield
      {...props}
      type={isVisible ? 'text' : 'password'}
      InputProps={InputProps}
    />
  );
};

export default Textfield;
