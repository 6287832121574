/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const UnChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="UnChecked">
    <rect
      x={0.4}
      y={0.4}
      width={17.2}
      height={17.2}
      rx={2.6}
      fill="var(--secondaryBackground)"
      stroke="var(--secondaryText)"
      strokeWidth={0.8}
    />
  </svg>
);

export default UnChecked;
