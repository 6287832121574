import styled from 'styled-components';

import { device } from 'helpers/screenSizes';

const HoldingWrapper = styled.div`
  background: ${props => props.theme.primaryBackground};
  // margin-bottom : 30px;
  // height:100%;
  @media ${device.down.md} {
    min-height: 0;
    height: 100%;
  }

  .ht-mr-4 {
    margin-right: 4px;
  }

  .holdings-label {
    font-size: 0.9em;
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: left;
    color: ${props => props.theme.primaryText};
  }

  .tbody-style {
    display: table;
    width: 100%;
  }

  .holdings-body {
    height: auto;
    min-height: calc(8 * 52px);
  }

  .open-positions-body,
  .open-orders-body,
  .orders-history-body,
  .fills-body,
  .stop-orders-body,
  .holding-balances-body {
    display: none;

    &.active {
      display: block;
      height: 100%;
    }
  }

  .fills-body {
    .deltaCashToolTipIcon {
      position: absolute;
    }
  }

  //todo-h: Fix the 'orderS-history-body' classname everywhere
  .fills-body,
  .orders-history-body {
    position: relative;

    // Make space for 'Download' button #btnDownloadLogs
    @media ${device.down.sm} {
      .pagination {
        margin-top: 20px;
      }
    }
  }

  .open-positions-body table {
    display: block;
    border-spacing: 10px;
    height: 100%;
    overflow-y: auto;
    border-collapse: collapse;
  }

  .open-orders-body table,
  .orders-history-body table,
  .fills-body table,
  .holding-balances-body table,
  .stop-orders-body table {
    display: block;
    border-spacing: 10px;
    height: calc(100% - 26px);
    overflow-y: auto;
    border-collapse: collapse;
  }

  //.open-positions-body td,
  //.open-orders-body td,
  //.orders-history-body td,
  //.fills-body td,
  //.holding-balances-body td,
  //.stop-orders-body td {
  //  padding: 10px;
  //  text-align: center;
  //}

  .liquidation-price {
    color: ${props => props.theme.negative};
    // https://app.zeplin.io/project/5d25a54ee7f39e377ba77134/screen/5d7e79b7469d5e46656360c2
  }

  .open-positions-body tr:nth-child(2n),
  .open-orders-body tr:nth-child(2n),
  .orders-history-body tr:nth-child(2n),
  .fills-body tr:nth-child(2n),
  .holding-balances-body tr:nth-child(2n),
  .stop-orders-body tr:nth-child(2n) {
    background-color: ${props => props.theme.primaryBackground};
  }

  .profit {
    color: ${props => props.theme.positive};
  }

  .loss {
    color: ${props => props.theme.negative};
  }

  .desktop {
    display: block;
    border-bottom: 1px solid ${props => props.theme.separator};

    @media ${device.down.md} {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media ${device.down.md} {
      display: block;
      position: relative;
    }
  }

  .checkbox-margin {
    margin-top: 2px;
    padding-bottom: 5px;
  }

  .holdings-header {
    display: flex;
    width: 100%;
    background-color: ${props => props.theme.primaryBackground};
    justify-content: space-between;
    border-bottom: solid 1px var(--separator);
    align-items: center;
    height: 50px;
    min-height: 50px;
    /* &:hover {
      cursor: move;
    } */

    .contract-type-filter-section {
      display: flex;
      margin-left: 15px;
      cursor: pointer;

      .disabled-dropdown {
        height: 28px;
        width: 95px;
        background-color: var(--unselectedTab);
        border-radius: 2px;
        font-family: var(--semiBold) !important;
        font-size: 12.1px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: var(--secondaryText);
        margin-top: 7px;
        display: flex;
        padding: 0px 10px 0px 10px;
        justify-content: space-between;
        line-height: 27px;
        .arrow-up,
        .arrow-closed {
          g {
            g {
              fill: var(--primaryText);
            }
          }
        }
      }

      .disabled-selected-button,
      .active-selected-button {
        height: 28px;
        width: 120px;
        border-radius: 2px;
        font-family: var(--fontRegular);
        font-size: 12.1px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        margin-top: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .active-selected-button {
        background-color: ${props => props.theme.primaryTheme};
        color: var(--primaryCtaText);
      }

      .disabled-selected-button {
        background-color: var(--unselectedTab);
        color: ${props => props.theme.secondaryText};
        font-family: var(--fontRegular) !important;
        // margin-top: 5px;
      }

      .contract-selection-dropdown {
        margin-top: 6px;

        .Dropdown-control {
          position: relative;
          height: 28px;
          width: 95px;
          padding: 2px 8px;
          background-color: ${props => props.theme.primaryTheme};
          border-radius: var(--inputBorderRadius);
          font-family: var(--fontRegular);
          font-size: 11px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          display: flex;
          border: none;
          line-height: 1.1;
          color: var(--primaryCtaText);
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .Dropdown-arrow-wrapper {
            // position : absolute;
            // right : 10px;

            .arrow-up,
            .arrow-closed {
              g {
                g {
                  fill: var(--primaryCtaText);
                }
              }
            }
          }
        }

        .Dropdown-menu {
          width: 95px;
          background-color: ${props => props.theme.secondaryBackground};
          box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.265);
          border: none;

          .Dropdown-option {
            font-family: var(--fontRegular);
            font-size: 12.1px;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            color: ${props => props.theme.secondaryText};

            &:hover {
              background-color: ${props => props.theme.tertiaryBackground};
              color: ${props => props.theme.primaryTheme};
            }
          }
          .is-selected {
            background-color: ${props => props.theme.tertiaryBackground};
            color: ${props => props.theme.primaryTheme};
          }
        }
      }
    }

    .header-tabs {
      // cursor: move;
      flex-basis: 75%;

      .tab {
        margin-left: 30px !important;
        padding: 25px 0px !important;
        width: fit-content;
      }

      .tab.active {
        font-weight: 600 !important;

        &:after {
          width: 30px !important;
          height: 3px !important;
        }
      }
    }

    .filter-section {
      display: flex;
      flex-basis: 22%;
      justify-content: flex-start;

      .asset-history-link {
        font-family: var(--fontRegular);
        font-size: 12.1px;
        color: ${props => props.theme.primaryTheme};
        cursor: pointer;

        svg g g g g g g {
          path:nth-child(2) {
            fill: ${props => props.theme.primaryTheme};
          }
        }
      }

      .tool {
        font-size: 0.75rem;
        color: var(--secondaryText);
        font-family: var(--fontRegular);

        &:hover {
          color: var(--primaryTheme);

          svg {
            fill: var(--primaryTheme);
            path {
              fill: var(--primaryTheme);
            }
          }
        }
        svg {
          cursor: pointer;
          fill: var(--secondaryText);

          &:hover {
            fill: var(--primaryTheme);
          }

          path {
            fill: var(--secondaryText);
          }

          @media ${device.down.md} {
            width: 15px;
            height: 13px;
            position: relative;
            top: -3px;
          }
        }
      }
    }

    .filter {
      display: flex;
      justify-content: flex-end;
      color: ${props => props.theme.primaryText};
      position: relative;

      span {
        padding: 0 5px;
        font-size: 12.1px;
        color: ${props => props.theme.secondaryText};

        @media ${device.down.md} {
          font-size: 10px;
        }
      }

      input[type='checkbox'] {
        margin-top: 2px;
      }
    }
    @media ${device.down.md} {
      justify-content: flex-start;
    }

    label {
      padding-left: 20px;

      &::before {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        right: 0px;
        left: auto;
        top: 8px;
        background-color: ${props => props.theme.secondaryText};
      }
      &:after {
        width: 8.6px;
        height: 5px;
        left: auto;
        right: 4px;
        top: 13px;
      }
    }
  }
  .handcursor {
    cursor: pointer;
  }

  .main-section {
    .select-option {
      width: 146px;
      height: 20px;
      background-color: #273142;
      padding: 0 0 0 8px;
      font-size: 12.1px;
      color: ${props => props.theme.secondaryText};
      border: none;

      & option {
        background-color: #212934;
        border: none;

        &:hover {
          background-color: none;
        }
      }

      .icon {
        color: ${props => props.theme.secondaryText};
      }

      .optionText {
        font-size: 12.1px;

        line-height: 1.5;
        color: ${props => props.theme.secondaryText};
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }

    &:after {
      border-bottom: none;
    }
    .option-text {
      white-space: normal;
      color: ${props => props.theme.primaryText} !important;
    }
  }

  .cancel-text {
    font-size: 12.1px;
    font-family: var(--fontRegular);
    line-height: 1.5;
    color: ${props => props.theme.secondaryText};

    @media ${device.down.md} {
      font-size: 10px;
    }
  }

  .cross-icon {
    margin: 3px;
    width: 16px;
    height: 16px;
    padding: 3px;
    background-color: ${props => props.theme.negative};
    border-radius: 2px;
  }

  .holdings-indicator {
    height: 100%;
    position: absolute;
    left: 0;
    border-right: none !important;
  }

  .cashflow,
  .realized-cashflows {
    padding-right: 4px;
  }

  // This is specifically used where <EditSection> is used so that the btn-group (abs positioned) is visible
  // Classname is this long to make sure its usage is clear and specific to <EditSection>
  .edit-section-visible-overflow {
    overflow: visible !important;
  }

  .tabs-header-link {
    font-size: 12.1px;
    line-height: 18px;
    color: ${props => props.theme.secondayText2};
    margin: 0 10px;

    a {
      color: ${props => props.theme.primaryTheme};
      text-decoration: underline;
    }
  }

  @media ${device.down.md} {
    .mobile-section {
      border-bottom: 1px solid ${props => props.theme.separator};
      background-color: ${props => props.theme.secondaryBackground};

      .mobile span {
        padding: 0 10px;
        font-size: 10px;
        color: #a6a9ad;
        margin: auto 0;
      }
    }
  }

  .tab-actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 8px 20px;

    .search-input {
      width: 140px;
    }
  }

  .cancel-a {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;

    // Fix <HoldingsCancelButton> button positioning
    > span {
      line-height: 1;
      display: block;
    }
  }

  .stop-price-cell {
    display: flex;
    align-items: center;

    @media ${device.down.md} {
      line-height: 1.5;
    }
  }

  // .trail-amount-value-stop-order{
  //  display: inline-block !important;
  // }

  .request-in-progress {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(25, 34, 44, 0.7);
      cursor: wait;
      z-index: 1;
    }
  }

  @media ${device.down.md} {
    .mobile-link {
      color: ${props => props.theme.primaryTheme};
    }
  }

  .holdings-body {
    .ReactTable .rt-table {
      overflow-y: hidden;
      overflow-x: scroll; /* fallback */
      overflow-x: overlay;
    }
  }

  .holdings-body:hover {
    .ReactTable .rt-table {
      ::-webkit-scrollbar {
        height: var(--scrollBarHeight);
      }
      scrollbar-width: var(--scrollBarWidthFirefox);
    }
  }

  .hide-zero-balance {
    font-size: 0.75rem;
    font-family: var(--fontRegular);
    color: var(--secondaryText);
    cursor: pointer;
    margin-right: 30px;
  }
`;

export default HoldingWrapper;

export const LoaderTopMargin = styled.div`
  position: absolute;
  height: 85px;
  background: ${props => props.theme.primaryBackground};
  width: 100%;
  z-index: -1;
`;

export const HoldingsPriceFlexWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  max-width: 100%;
  ${props => props.secondary && 'color: #696f7a'};
  ${props => props.right && 'justify-content: flex-end'};
`;
HoldingsPriceFlexWrapper.displayName = 'HoldingsPriceFlexWrapper';

export const HoldingsPrice = styled.p`
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ${props => props.showElipsis && 'ellipsis'};
  padding-right: 4px;
`;

export const HoldingsPriceSymbol = styled.p`
  //todo-h: there are over 25 grey color values in color_constants.js; why is every design using a new value?
  color: ${props => props.theme.secondaryText};
`;
HoldingsPriceSymbol.displayName = 'HoldingsPriceSymbol';

export const HoldingUnderlyingPrice = styled.p`
  font-size: 10px;
  margin-right: 4px;
  ${props => props.secondary && 'color: #696f7a}'};
`;

HoldingUnderlyingPrice.displayName = 'HoldingUnderlyingPrice';

export const HoldingsIndicator = styled.div`
  width: 2px;
  height: 92%;

  &.short {
    background-color: ${props => props.theme.negative};
  }

  &.long {
    background-color: ${props => props.theme.positive};
  }
`;

export const HoldingsQty = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;

  &.long {
    color: ${props => props.theme.positive};
    @media ${device.down.xs} {
      max-width: 42px;
    }
  }

  &.short {
    color: ${props => props.theme.negative};
    @media ${device.down.xs} {
      max-width: 42px;
    }
  }
`;

export const UnrealisedPnLWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;

  .value-wrapper {
    height: 100%;
    width: calc(100% - 13px); // width of adl indicator 5px + right 3px + 5px margin
  }
`;

export const ADLIndicatorWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 0;
  width: 5px;
  height: 100%;
  display: flex;
  align-items: center;
`;

// Used by HoldingsCancelButton
export const FlexCenterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TagTop = styled.div`
  font-size: 9px;
  border-radius: 2px;
  margin-top: -20px;
  margin-left: -10px;
  background: ${props => props.theme.secondaryBackground};
  padding: 5px 10px;
  width: fit-content;
  color: ${props => props.theme.primaryText};
`;

export const Underliquidation = styled.div`
  color: ${props => props.theme.highlight};
`;
export const LiquidationRisk = styled.div`
  color: ${props => props.theme.negative};
`;
export const ColorWhite = styled.div`
  color: ${props => props.theme.secondaryText};
`;

export const HoldingsContractLink = styled.span`
  color: ${props => props.theme.primaryTheme};
  text-decoration: underline;
  cursor: pointer;
`;

export const HoldingsLoader = styled.div`
  max-width: 100%;

  .loader {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const TotalUnrealisedPnlPopoverWrapper = styled.div`
  width: 275px;
  height: auto;
  background-color: var(--separator2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: var(--fontRegular);
  line-height: 1.68;

  .header {
    font-family: var(--fontLight);
    font-size: 14px;
    color: ${props => props.theme.primaryText};
  }

  .text {
    font-family: var(--fontRegular);
    font-size: 12.1px;
    color: ${props => props.theme.secondaryText};
    margin-bottom: 8px;

    .negative,
    .positive,
    .neutral {
      font-size: 14px !important;
      font-family: var(--fontRegular);
    }

    .negative {
      color: ${props => props.theme.negative};
    }

    .positive {
      color: ${props => props.theme.positive};
    }

    .neutral {
      color: ${props => props.theme.primaryText};
    }
  }

  .note {
    font-family: var(--fontLight);
    color: var(--tooltipInfoBoxfontColor);
    text-align: left;
  }
`;
