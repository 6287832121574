import React from 'react';

import { useTranslation } from 'react-i18next';

import Render from 'components/render';
import { Info } from 'styles/assets/icons';
import Box from 'UIKit/Box/Box';

import styles from './disclaimerContainer.module.scss';

const DisclaimerContainer = ({
  text,
  showImportantText,
  showInfoIcon,
}: {
  text: string;
  showImportantText?: boolean;
  showInfoIcon?: boolean;
}) => {
  const { t } = useTranslation(['account', 'auth']);

  return (
    <Box className={styles.disclaimerContainer}>
      <Render when={showImportantText}>
        <Box className={styles.important}>
          <Info />
          <span>{t('auth:important')}</span>
        </Box>
      </Render>
      <Box className={styles.importantTextBox} flex="flex">
        <Render when={showInfoIcon}>
          <span className={styles.infoIcon}>
            <Info />
          </span>
        </Render>
        <p className={styles.information}>{text}</p>
      </Box>
    </Box>
  );
};

export default DisclaimerContainer;
