import { SIGNUP_CARDS_FLAG } from 'constants/featureFlag';
import i18n from 'i18n/config';
import DepositBonusImg from 'styles/assets/images/deposit_bonus.png';
import SecurityImg from 'styles/assets/images/security.png';
import UnlockImg from 'styles/assets/images/unlock.png';

import styles from './styles.module.scss';

interface InfoList {
  id: number;
  header: string;
  description: string;
  imageSrc: string;
  imageClassName?: string;
  alt: string;
}

const INFO_LIST_INDIA: InfoList[] = [
  {
    id: 1,
    header: i18n.t('auth:signUpInfoBoxes.tradeIndia.header'),
    description: i18n.t('auth:signUpInfoBoxes.tradeIndia.description'),
    imageSrc: 'https://static.delta.exchange/web/home-page/signup-trade.svg',
    alt: 'Trade Now',
    imageClassName: styles.commonInrSignupImage,
  },
  {
    id: 2,
    header: i18n.t('auth:signUpInfoBoxes.inrDeposits.header'),
    description: i18n.t('auth:signUpInfoBoxes.inrDeposits.description'),
    imageSrc: 'https://static.delta.exchange/web/home-page/signup-bank.svg',
    imageClassName: styles.commonInrSignupImage,
    alt: 'INR Deposits',
  },
  {
    id: 3,
    header: i18n.t('auth:signUpInfoBoxes.welcomeOfferIndia.header', {
      amount: `₹5000`,
    }),
    description: i18n.t('auth:signUpInfoBoxes.welcomeOfferIndia.description'),
    imageSrc: 'https://static.delta.exchange/web/home-page/signup-offer.svg',
    imageClassName: styles.commonInrSignupImage,
    alt: 'Welcome Offer',
  },
];

const INFO_BOX_LIST_GLOBAL: InfoList[] = [
  {
    id: 1,
    header: i18n.t('auth:signUpInfoBoxes.depositBonus.header'),
    description: i18n.t('auth:signUpInfoBoxes.depositBonus.description'),
    imageSrc: DepositBonusImg,
    imageClassName: styles.depositImg,
    alt: 'Deposit Bonus',
  },
  {
    id: 2,
    header: i18n.t('auth:signUpInfoBoxes.unlock.header'),
    description: i18n.t('auth:signUpInfoBoxes.unlock.description'),
    imageClassName: styles.unlockImg,
    imageSrc: UnlockImg,
    alt: 'Trade now',
  },
  {
    id: 3,
    header: i18n.t('auth:signUpInfoBoxes.security.header'),
    description: i18n.t('auth:signUpInfoBoxes.security.description'),
    imageSrc: SecurityImg,
    imageClassName: styles.securityImg,
    alt: 'Secure',
  },
];

const INFO_LIST: Record<typeof SIGNUP_CARDS_FLAG, InfoList[] | undefined> = {
  global: INFO_BOX_LIST_GLOBAL,
  india: INFO_LIST_INDIA,
};

export { INFO_LIST };
