import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import {
  changeBasketOrdersScreen,
  toggleMobileBasketOrderAssetDropdown,
} from 'actions/basketOrders';
import AccountReduceOnlyModeWarning from 'components/placeorder/partials/OrderContainer/accountReduceOnlyWarning';
import { RenderForGlobalExchange } from 'components/render/RenderByExchangeType';
import { noop } from 'helpers';
import { device } from 'helpers/screenSizes';
import { useAppDispatch } from 'hooks';
import { BluePlusIcon, BlueRightArrow } from 'styles/assets/icons';

import { AvailableMargin, Learnmore } from '../helpers';
import { EmptyBasketIcon } from '../icons';
import styles from './addorders.module.scss';

interface Props {
  asset: string | null;
}

const AddContracts = (props: Props) => {
  const { asset } = props;
  const { t } = useTranslation(['basketOrders']);
  const dispatch = useAppDispatch();

  const handleAddContractClick = () => {
    dispatch(changeBasketOrdersScreen('products-view'));
  };

  const handleDiffUnderlyingClick = () => {
    dispatch(toggleMobileBasketOrderAssetDropdown(true));
  };

  return (
    (<div className={styles['add-orders-container']} data-palette="AddContracts">
      <RenderForGlobalExchange>
        <AccountReduceOnlyModeWarning />
      </RenderForGlobalExchange>
      <MediaQuery query={device.up.md}>
        <div className={styles['empty-icon']}>
          <EmptyBasketIcon />{' '}
        </div>
        <div className={styles.text}>
          {isMobile
            ? t('basketOrders:basketOrder')
            : t('basketOrders:addContractText', { asset })}
        </div>
        <button
          type="button"
          data-testid="add-contracts-button"
          className={styles['add-button']}
          onClick={handleAddContractClick}
        >
          {isMobile
            ? t('basketOrders:createBasket', { asset })
            : t('basketOrders:addContractButtonText', { asset })}
        </button>
        <div className={styles['learn-more']}>
          <Learnmore />
        </div>
      </MediaQuery>
      <MediaQuery query={device.down.md}>
        <div className={styles.text}>{t('basketOrders:basketOrder')}</div>
        <button
          type="button"
          data-testid="create-basket-button"
          className={styles['add-button']}
          onClick={handleAddContractClick}
        >
          <BluePlusIcon />
          {t('basketOrders:createBasket', { asset })}
        </button>
        <div
          data-testid="1ikEMkDYm1rpLX612GP80"
          className={styles.selectUnderlying}
          onClick={handleDiffUnderlyingClick}
          role="button"
          onKeyDown={noop}
          tabIndex={0}
        >
          <span>{t('basketOrders:selectDiffUnderlying')}</span>
          <span>
            <BlueRightArrow />
          </span>
        </div>
      </MediaQuery>
      <AvailableMargin />
    </div>)
  );
};

export default AddContracts;
