/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import { memo } from 'react';

import cx from 'classnames';
import i18next from 'i18next';

import { isAuthenticated } from 'actions/user';
import OptionTypeColumn from 'components/Header/partials/headerDropdown/OptionsChain/OptionTypeColumn';
import StrikeColumn from 'components/Header/partials/headerDropdown/OptionsChain/StrikeColumn';
import FavoriteOption from 'components/options_chain/FavoriteOption';
import {
  AskIVValue,
  BestAskValue,
  BestBidValue,
  BidIVValue,
  DeltaValue,
  GammaValue,
  IVValue,
  MarkpriceValue,
  OiChangeValue,
  OiValue,
  OptionChainAskQtyValue,
  OptionChainBidQtyValue,
  PositionValue,
  ThetaValue,
  VeggaValue,
} from 'components/options_chain/helpers';
import { useAppSelector } from 'hooks';
import { basketOrderSwitchSelector, userSelector } from 'selectors';
// import { selectedProductState } from 'selectors/tradeSelectors';
import { Box } from 'UIKit';

import Column from './Column';
import { LastPriceChange, Volume } from './helpers';
import RenderCell from './RenderCell';
import styles from './styles.module.scss';
import { useOptionsChainData } from './useOptionsChainData';

// const strikeLabel = i18next.t('markets:optionsTable.strike');
const typeLabel = i18next.t('markets:optionsTable.type');
const gammaLabel = i18next.t('markets:optionsTable.gamma');
const bidLabel = i18next.t('markets:optionsTable.bid');
const markLabel = i18next.t('markets:optionsTable.mark');
const askLabel = i18next.t('markets:optionsTable.ask');
const bidQtyLabel = i18next.t('markets:optionsTable.bidQty');
const askQtyLabel = i18next.t('markets:optionsTable.askQty');
const oiChangeLabel = i18next.t('markets:optionsTable.changeInOi');
const deltaChangeLabel = i18next.t('markets:optionsTable.delta');
const vegaLabel = i18next.t('markets:optionsTable.vega');
const thetaLabel = i18next.t('markets:optionsTable.theta');
const watchListLabel = i18next.t('markets:optionsTable.watchList');

const DataTable = ({ onClose, extraClassName }) => {
  const user = useAppSelector(userSelector);
  const isLoggedIn = isAuthenticated(user);
  const isBasketOrderView = useAppSelector(basketOrderSwitchSelector);
  const { isPending } = useOptionsChainData();

  return (
    (<div
      className={cx(
        { [styles[extraClassName]]: extraClassName },
        styles.dataTable,
        extraClassName || '',
        isPending ? styles.loading : ''
      )}
      id="options-mobile"
      data-palette="DataTable">
      <Box className="flex-set flex-nowrap width-min-screen">
        <StrikeColumn />
        <Column
          when={!isBasketOrderView}
          label={typeLabel}
          labelClass="truncate"
          colClass={styles.typeColumn}
        >
          <OptionTypeColumn />
        </Column>
        <Column label="OI">
          <RenderCell onClose={onClose} Component={OiValue} valueClassName="" isOiCell />
        </Column>
        <Column label={bidLabel}>
          <RenderCell
            onClose={onClose}
            withIV
            IVComponent={BidIVValue}
            Component={BestBidValue}
            valueClassName={styles.greenColor}
          />
        </Column>
        <Column label={markLabel}>
          <RenderCell
            onClose={onClose}
            withIV
            IVComponent={IVValue}
            Component={MarkpriceValue}
            valueClassName=""
          />
        </Column>
        <Column label={askLabel}>
          <RenderCell
            onClose={onClose}
            withIV
            IVComponent={AskIVValue}
            Component={BestAskValue}
            valueClassName={styles.redColor}
          />
        </Column>
        <Column label={bidQtyLabel}>
          <RenderCell onClose={onClose} Component={OptionChainBidQtyValue} />
        </Column>
        <Column label={askQtyLabel}>
          <RenderCell onClose={onClose} Component={OptionChainAskQtyValue} />
        </Column>
        <Column label="POS">
          <RenderCell
            onClose={onClose}
            Component={PositionValue}
            valueClassName=""
            showUnderlyingSymbol
            emptySpace
          />
        </Column>
        <Column label="24h Chg.">
          <LastPriceChange />
        </Column>
        <Column label="24h Vol">
          <Volume />
        </Column>
        <Column label={oiChangeLabel}>
          <RenderCell Component={OiChangeValue} onClose={onClose} valueClassName="" />
        </Column>
        <Column label={deltaChangeLabel}>
          {' '}
          <RenderCell onClose={onClose} Component={DeltaValue} valueClassName="" />
        </Column>
        <Column label={gammaLabel}>
          <RenderCell onClose={onClose} Component={GammaValue} valueClassName="" />
        </Column>
        <Column label={vegaLabel}>
          <RenderCell onClose={onClose} Component={VeggaValue} valueClassName="" />
        </Column>
        <Column label={thetaLabel} colClass={cx({ 'pe-1': !isLoggedIn })}>
          <RenderCell
            onClose={onClose}
            Component={ThetaValue}
            valueClassName={cx({
              'pe-2': !isLoggedIn,
            })}
          />
        </Column>
        <Column label={watchListLabel} renderWhen={isLoggedIn} colClass="pe-1">
          <RenderCell
            onClose={onClose}
            Component={FavoriteOption}
            valueClassName=""
            isFavCel
          />
        </Column>
      </Box>
    </div>)
  );
};

export default memo(DataTable);
