/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { changeOrderLeverage } from 'actions/orderbook';
import { isNan } from 'helpers';
import { calculateMaxLeverage } from 'helpers/formulas';
import { append, pipe, sort, uniq } from 'helpers/ramda';
import { orderMarginSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { Product } from 'types/IProducts';

interface LeverageProps {
  product: Product;
}

const getSliderValues = (productLeverage: number, leverage_slider_values: number[]) => {
  const leverages = pipe(
    append(parseInt(String(productLeverage), 10)),
    sort((a, b) => Number(a) - Number(b)),
    uniq
  )(leverage_slider_values);
  return leverages;
};

// Todo : Refactor leverage slider component with help of this hook
const useLeverage = ({ product }: LeverageProps) => {
  const { id, ui_config, initial_margin } = product || {};

  const dispatch = useAppDispatch();
  const orderMargins = useAppSelector(state => orderMarginSelector(state));
  const maxLeverage =
    typeof initial_margin !== 'undefined' && calculateMaxLeverage(Number(initial_margin));
  const productLeverage = orderMargins[id as number]?.leverage;

  const [enableSubmit, setEnabledSubmit] = useState(false);
  const [leverage, setLeverage] = useState(() => productLeverage);
  const [sliderValues, setSliderValues] = useState(() =>
    getSliderValues(productLeverage, ui_config?.leverage_slider_values as number[])
  );

  const onLeverageChange = (value: number) => {
    setLeverage(value);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (Number(val) > Number(maxLeverage)) {
      return;
    }
    if (!val) {
      setLeverage('');
      setSliderValues(sliderValues);
      return;
    }

    if (isNan(val) || Number(val) < 0) {
      return;
    }

    if (!isNan(val) && Number(val) === 0) {
      setEnabledSubmit(false);
    }

    setLeverage(Number(Math.floor(Number(val))));

    const newSliderValues = getSliderValues(
      Number(val),
      ui_config?.leverage_slider_values as number[]
    );
    setSliderValues(Number(val) < Number(maxLeverage) ? newSliderValues : sliderValues);
  };

  const handleLeverageDecrement = () => {
    if (!leverage) {
      setLeverage(1);
      return;
    }
    const currentIndex = sliderValues.findIndex(elem => elem === leverage);
    if (currentIndex === 0) {
      return;
    }
    setLeverage(sliderValues[currentIndex - 1]);
  };

  const handleLeverageIncrement = () => {
    if (!leverage) {
      setLeverage(1);
      return;
    }
    const currentIndex = sliderValues.findIndex(elem => elem === leverage);
    if (currentIndex === sliderValues.length - 1) {
      return;
    }
    setLeverage(sliderValues[currentIndex + 1]);
  };

  const handleLeverageSubmit = () => {
    dispatch(changeOrderLeverage(id, leverage));
  };

  useEffect(() => {
    setSliderValues(() =>
      getSliderValues(productLeverage, ui_config?.leverage_slider_values as number[])
    );
  }, [product, productLeverage, ui_config?.leverage_slider_values]);

  return {
    leverage,
    enableSubmit,
    maxLeverage,
    sliderValues,
    onLeverageChange,
    onInputChange,
    handleLeverageDecrement,
    handleLeverageIncrement,
    handleLeverageSubmit,
    setEnabledSubmit,
  };
};

export default useLeverage;
