import React, { useState, useEffect } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'PropTypes';
import ReactTable from 'react-table';

import 'react-table/react-table.css';
import NoContracts from 'components/markets/no_contracts';
import Render from 'components/render';
import {
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
  DEFAULT_PAGINATION_PAGE,
  isTestnet,
  LANDING_PAGE_URL,
} from 'constants/constants';
import { getInt } from 'helpers/utils';
import { contractTypeSelector } from 'selectors/tradeSelectors';

import TablePagination from './pagination';
import { ReactTableStyle } from './table_styles';

const defaultPaginationConfig = {
  rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE,
  page: DEFAULT_PAGINATION_PAGE,
  options: [5, 10, 15, 20, 25, 30],
  totalCount: 0,
  onPageChange: () => null,
  onRowsPerPageChange: () => null,
  shouldHideRange: false,
};

const Table = props => {
  const {
    data,
    loading,
    manual,
    columns,
    className,
    pageSize,
    pages,
    fetchData,
    showPagination,
    paginationConfig,
    getTrProps,
    getTrGroupProps,
    renderBelowTable: RenderBelowTable,
  } = props;

  const {
    page: defaultPage,
    rowsPerPage: perPage,
    options,
    totalCount,
    fetchNextData,
    onPageChange,
    onRowsPerPageChange,
    shouldHideRange,
  } = { ...defaultPaginationConfig, ...paginationConfig };

  const PER_PAGE = totalCount < perPage ? totalCount : perPage;

  const { t } = useTranslation(['markets']);

  const [rowsPerPage, setRowsPerPage] = useState(PER_PAGE);
  const [page, setPage] = useState(defaultPage);

  const rangeData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const tableData = showPagination ? rangeData : data;

  const isNextDataFetched = () => !!rangeData.length;

  const getNoDataText = () => {
    if (isTestnet && !selectedContractType) {
      return (<>
        <NoContracts />
        <div className="robostrategies-link" data-palette="getNoDataText">
          <Trans i18nKey="markets:marketsTable.noProductsInTestnet">
            <a href={`${LANDING_PAGE_URL}/invest/robo-strategies`}> </a>
          </Trans>
        </div>
      </>);
    }
    if (!showPagination || !totalCount || !getInt(rowsPerPage) || page === 0) {
      return <NoContracts searchTerm={t('markets:marketsTable.noProducts')} />;
    }

    if (!isNextDataFetched()) {
      return 'Loading..';
    }

    return <NoContracts searchTerm={t('markets:marketsTable.noProducts')} />;
  };

  const [state, setState] = useState({ data: tableData, columns });

  const selectedContractType = useSelector(contractTypeSelector);

  useEffect(() => {
    setState(state => ({
      ...state,
      data: tableData,
      columns: (() => {
        switch (selectedContractType) {
          case 'futures':
            return columns;

          case 'interest_rate_swaps':
            return columns.map((obj, index) =>
              obj.id === 'leverage' || obj.id === 'premium'
                ? { ...obj, show: false }
                : obj
            );

          // case 'move_options':
          //   return columns.length > 6
          //     ? [...columns.slice(0, 8), columns[9], columns[8]]
          //     : columns;

          default:
            return columns;
        }
      })(),
    }));
  }, [selectedContractType, tableData, columns]);

  return (
    <ReactTableStyle>
      <ReactTable
        data={state.data}
        loading={loading}
        manual={manual}
        columns={state.columns}
        className={className}
        pageSize={pageSize}
        pages={pages}
        onFetchData={fetchData}
        resizable={false}
        showPagination={false}
        minRows={0}
        noDataText={getNoDataText()}
        getTrProps={getTrProps}
        defaultSortMethod={(a, b, desc) => {
          // force NaN, null and undefined to the bottom
          if (desc) {
            a = isNaN(a) || !a ? -Infinity : parseFloat(a);
            b = isNaN(b) || !b ? -Infinity : parseFloat(b);
          } else if (!desc) {
            a = isNaN(a) || !a ? Infinity : parseFloat(a);
            b = isNaN(b) || !b ? Infinity : parseFloat(b);
          }

          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        }}
        getTrGroupProps={getTrGroupProps}
      />
      <RenderBelowTable />
      <Render when={!!showPagination}>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          count={totalCount}
          options={options}
          fetchNextData={fetchNextData}
          originalData={data}
          shouldHideRange={shouldHideRange}
          currentRangeData={rangeData}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Render>
    </ReactTableStyle>
  );
};

Table.defaultProps = {
  loading: false,
  manual: false,
  pageSize: 5,
  showPagination: false,
  tableProps: {},
  paginationConfig: defaultPaginationConfig,
  renderBelowTable: () => null,
  data: [],
};

// Table.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.oneOfType([PropTypes.array]),
//   tableProps: PropTypes.object,
//   className: PropTypes.string,
//   loading: PropTypes.bool,
//   manual: PropTypes.bool,
//   pageSize: PropTypes.number,
//   pages: PropTypes.number,
//   fetchData: PropTypes.func,
//   showPagination: PropTypes.bool,
//   paginationConfig: PropTypes.shape({
//     rowsPerPage: PropTypes.stringOrNumber,
//     page: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     fetchNextData: PropTypes.func,
//     totalCount: PropTypes.number,
//     shouldHideRange: PropTypes.bool,
//     onPageChange: PropTypes.func,
//     onRowsPerPageChange: PropTypes.func,
//   }),
// };

export default Table;
