import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';

import { CenterLoader } from 'components/loader';

const Coin = lazy(
  () => import(/* webpackChunkName: "WalletCoinPage" */ './BalanceWalletCoin')
);

const WalletCoin = () => (
  <Suspense fallback={<CenterLoader />}>
    <Coin />
  </Suspense>
);

export default WalletCoin;
