import cn from 'classnames';
import QRCode from 'qrcode-react';
import { useTranslation } from 'react-i18next';

import { PopoverStyle } from 'components/deposit/deposit_styles';
import { noop } from 'helpers';
import { useAppSelector, useCopyToClipBoard, useWindowSize } from 'hooks';
import { allOpenPositionsSelector } from 'selectors';
import { CopyToClipboardIcon } from 'styles/assets/icons';
import { TwoFactorAuthQrData } from 'types/Auth';
import { Box, DeltaReactModal } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton/DeltaButton';

import styles from './reset2fa.module.scss';

export const GenerateQR = ({
  isOpen,
  close,
  next,
  data,
}: {
  isOpen: boolean;
  close: () => void;
  next: () => void;
  data: TwoFactorAuthQrData;
}) => {
  const { t } = useTranslation(['auth', 'account']);
  const { isScreenMd } = useWindowSize();

  const { isCopied, handleCopy } = useCopyToClipBoard(data.google_secret);
  const openPositions = useAppSelector(allOpenPositionsSelector);
  const isPositionsEmpty = openPositions.length === 0;

  return (
    (<DeltaReactModal
      title={t('auth:reset2faPopups.step2.title')}
      isOpen={isOpen}
      onClose={isPositionsEmpty ? noop : close}
      showHeader
      // eslint-disable-next-line no-unneeded-ternary
      showCrossIcon={isPositionsEmpty ? false : true}
      showCancel={false}
      titleRightContent={
        <span className={styles.stepCount} data-palette="GenerateQR">
          <span className={styles.left}>1 /</span> <span className={styles.right}>3</span>
        </span>
      }
      titleClassName={styles.title}
      headerClassName={styles.header}
      bodyClassName={cn(styles.body, styles.step2)}
      footerClassName={styles.footer}
      // cancelClassName={styles.cancelButton}
      confirmClassName={styles.confirmButton}
      fullScreen={isScreenMd}
      onConfirm={next}
    >
      <p className={styles.description}>{t('auth:reset2faPopups.step2.description1')}</p>
      <Box className={styles.qrContainer}>
        <QRCode value={data.qr_url} size={108} />
      </Box>
      <p className={styles.description}>{t('auth:reset2faPopups.step2.description2')}</p>
      <Box
        className={styles.googleCode}
        flex="flex"
        justifyContent="start"
        alignItems="center"
      >
        {data.google_secret}
        <DeltaButton
          type="button"
          data-testid="copy-button"
          className={styles.copyButton}
          onClick={handleCopy}
        >
          <CopyToClipboardIcon className={styles.iconCopy} />
          {isCopied && (
            <PopoverStyle className={styles.copied}>
              <p>{t('account:copied')}</p>
            </PopoverStyle>
          )}
        </DeltaButton>
      </Box>
    </DeltaReactModal>)
  );
};

export default GenerateQR;
