import React from 'react';
import classNames from 'classnames';
import DoubleLabeledRangeSilderStyle, {
  DownLabel,
  UpLabel,
} from './twolabeledRangeSlider_style';

export default class TwoLabeledRangeSlider extends React.Component {
  constructor(props) {
    super(props);
    // leverages = [1, 2, 25, 50, 100]
    // selectedValue = 25
    // sliderValue = 2 // index of 25 in leverages array
    this.state = {
      maxLeverage: props.leverages.length - 1,
      minLeverage: 0,
      rangeLabels: props.leverages,
      selectedValue: props.initialValue,
      sliderValue: props.leverages.indexOf(props.initialValue),
      totalLables: props.leverages.length,
    };
    this.rangeInput = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      maxLeverage: props.leverages.length - 1,
      minLeverage: 0,
      rangeLabels: props.leverages,
      selectedValue: props.initialValue,
      totalLables: props.leverages.length,
      sliderValue: props.leverages.indexOf(props.initialValue),
    };
  }
  // value = 1, 2, 25, 50, 100
  onLabelClick = value => {
    let leverageValue = this.state.rangeLabels.indexOf(value);
    this.setState({
      selectedValue: value,
      sliderValue: leverageValue,
    });
    this.rangeInput.current.value = leverageValue;
    this.props.onChange(value);
  };

  // index of the value
  // 1, 2, 3, 4, 5
  handleChange = event => {
    const leverageValue = Number(event.target.value);
    const newValue = this.state.rangeLabels[leverageValue];
    this.setState({
      selectedValue: newValue,
      sliderValue: leverageValue,
    });
    this.rangeInput.current.value = leverageValue;
    this.props.onChange(newValue);
  };

  render() {
    const { spotLeverages } = this.props;
    return (
      <DoubleLabeledRangeSilderStyle className="range-slider">
        <ul className="upper-range-labels">
          {this.state.rangeLabels.map(label => (
            <UpLabel
              key={label}
              totalItems={this.state.totalLables}
              totalLables={100 / (this.state.totalLables - 1)}
              onClick={() => this.onLabelClick(label)}
              className={classNames({
                active: label === this.state.selectedValue,
              })}>
              <div className="upper-label">{`${spotLeverages[label]}x`}</div>
            </UpLabel>
          ))}
        </ul>
        <div className="range">
          <input
            type="range"
            ref={this.rangeInput}
            step="1"
            min={this.state.minLeverage}
            max={this.state.maxLeverage}
            onChange={this.handleChange}
            value={this.state.sliderValue}
          />
        </div>
        <ul className="range-labels">
          {this.state.rangeLabels.map(label => (
            <DownLabel
              key={label}
              totalItems={this.state.totalLables}
              totalLables={100 / (this.state.totalLables - 1)}
              onClick={() => this.onLabelClick(label)}
              className={classNames({
                active: label === this.state.selectedValue,
              })}>{`${label}x`}</DownLabel>
          ))}
        </ul>
      </DoubleLabeledRangeSilderStyle>
    );
  }
}

TwoLabeledRangeSlider.defaultProps = {
  onChange: () => null,
};
