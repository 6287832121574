/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Popover from 'containers/Popover';
import { CloseIcon } from 'styles/assets/icons';

import { device } from '../../helpers/screenSizes';

const HoldingsCancelButtonStyles = styled.button`
  font-size: 11px;
  line-height: 11px;
  padding: ${props => (props.wide ? '4px 16px' : '2px 10px')};
  border: 0.6px solid var(--negative);
  border-radius: 2px;
  background-color: ${props => props.theme.secondaryBackground};
  color: ${props => props.theme.negative};
  transition: all 0.2s ease;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: ${props => props.theme.negative};
    color: var(--primaryCtaText);

    path {
      fill: white !important;
    }
  }

  @media ${device.down.md} {
    border: none;
    border-left: ${props => (props.separatorDisable ? 'none' : '1px solid #273340')};
    background-color: transparent;
    border-radius: 2px;
    padding: ${props => (props.wide ? '4px 0' : '2px 10px')};
    padding-right: ${props =>
      props.separatorDisable ? '0' : props.wide ? '16px' : '10px'};
    color: ${props => props.theme.negative};
    display: flex;
    min-width: 60px

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.negative};
    }
  }

  .icon {
    font-size: 12.1px;
    line-height: 12px;
    font-family: var(--fontRegular);
    padding: 0;
    display: inline-block;

    path {
      fill: var(--negative);
    }
  }

  .contents {
    padding-left: 0.25rem;
  }

  &:disabled {
    color: ${props => props.theme.negativeDisabled};
    cursor: not-allowed;
    background-color: ${props => props.theme.primaryBackground};
    border-color: ${props => props.theme.negativeDisabled};
    .icon {
      cursor: not-allowed;
    }

    &:hover {
      color: ${props => props.theme.negativeDisabled};
      background-color: ${props => props.theme.primaryBackground};
      border-color: ${props => props.theme.negativeDisabled};
    }
  }
`;

const HoldingsCancelButton = props => {
  const {
    tooltipText,
    // eslint-disable-next-line react/prop-types
    children,
    wide,
    disabled,
    tooltipPosition,
    tooltipDisable,
  } = props;

  return tooltipDisable ? (
    <HoldingsCancelButtonStyles
      wide={wide}
      disabled={disabled}
      {...props}
      data-testid="HoldingsCancelButton"
    >
      <CloseIcon fill="none" className="icon" />
      {children && <span className="contents" data-palette="HoldingsCancelButton">{children}</span>}
    </HoldingsCancelButtonStyles>
  ) : (
    <Popover body={tooltipText} placement={tooltipPosition}>
      <HoldingsCancelButtonStyles
        wide={wide}
        disabled={disabled}
        round={!children}
        data-testid="HoldingsCancelButtons"
        {...props}
      >
        <CloseIcon fill="none" className="icon" />
        {children && <span className="contents" data-palette="HoldingsCancelButton">{children}</span>}
      </HoldingsCancelButtonStyles>
    </Popover>
  );
};

HoldingsCancelButton.propTypes = {
  tooltipText: PropTypes.string,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  tooltipDisable: PropTypes.bool,
  separatorDisable: PropTypes.bool,
};

HoldingsCancelButton.defaultProps = {
  wide: false,
  disabled: false,
  tooltipPosition: 'top',
  tooltipDisable: false,
  separatorDisable: false,
};

export default HoldingsCancelButton;
