/* eslint-disable camelcase */
import i18next from 'i18next';
import { createSelector } from 'reselect';

import { getBalanceEquity } from 'components/holdings_balance/helpers';
import {
  calculateTakeProfitEstPrice,
  isValidTakeProfitValue,
  marketPlaceOrderConfirmationTable,
  spotMarketPlaceOrderConfirmationTable,
} from 'components/limit_place_order/helper';
import { ASSET_SYMBOL, MARGIN_TYPES } from 'constants/constants';
import { MARGIN_MODE, ORDER_TYPE, PLACE_ORDER_TYPE, SIDE } from 'constants/enums';
import {
  calculateEstimatedMarketPrice,
  checkLowBalance,
  convertOrderBook,
  getNotional,
  round,
} from 'helpers/formulas';
import { division } from 'helpers/math';
import { gt, path, pick, pipe, sort } from 'helpers/ramda';
import {
  convertExponentialToDecimal,
  getBalanceBySymbol,
  isEmpty,
  isOptions,
  isSpotContract,
  truncAfterDecimal,
} from 'helpers/utils';
import { impactSizeCheck, impactSizeCheckSpot, slippageCheck } from 'helpers/validations';
import {
  markPriceSelector,
  markPriceState,
  openPositionMarkPriceSelectorById,
  spotPriceSelector,
  spotPriceState,
} from 'selectors/priceSelectors';
import {
  getPrefilledValuesState,
  leverageState,
  openBuyOrdersState,
  openPositionState,
  openSellOrdersState,
  orderMarginSelector,
  selectedProductSelector,
  tradingCreditsSelector,
} from 'selectors/tradeSelectors';
import { ContractType, NotionalType } from 'types/IProducts';
import IState from 'types/Istore';
import { QuantityDropdownPreference } from 'types/IUser';

import {
  calculateEstimateMarginForPlaceOrder,
  convertNumberOfContractsToSelectedCurrency,
  convertQuantityValueToNumberOfContracts,
  getNonSpotMaxSize,
  getSelectedProductBalance,
  hintTextToShowInQuantityInput,
  positionSizeForSelectedProduct,
} from '../formulae';
import {
  aggregateSpotQuoteNotional,
  aggregateSpotUnderlyingNotional,
  calculateSpotEstimatedMarketPriceUsingUnderlyingSize,
  calculateSpotImpactPrice,
  computeSpotMaxQuantity,
  getSpotTotalCost,
} from '../formulae/spotFormulae';
import { allOpenPositionsSelector } from './holdingsSelectors';
import { l2OrderbookSelectorWithDepth } from './l2OrderbookSelector';
import { activeOffersSelector, appliedOffersSelector } from './offersSelector';
import {
  portfolioEnableMinBalanceSelector,
  tradePreferencesSelector,
  userMarginModeSelector,
  userPreferencesSelector,
} from './otherSelectors';
import {
  balanceBySymbolSelector,
  balanceSelector,
  enableWalletsSelector,
  multiCollateralSelector,
  portfolioMarginSelector,
} from './walletSelector';

export enum QuantityDropDownType {
  QUOTING = 'quoting_asset',
  SETTLING = 'settling_asset',
  UNDERLYING = 'underlying_asset',
  CONT = 'cont',
}

export const placeOrderSelector = (state: IState) => state.placeOrder;

/**
 * Form States
 */
export const sideSelection = (state: IState) => state.placeOrder?.side;
export const orderTypeSelection = (state: IState) => state.placeOrder?.orderType;
export const quantityInputDropdownValue = (state: IState) =>
  state.placeOrder?.dropdownValue;
export const quantityInputValue = (state: IState) => state.placeOrder?.quantityInputValue;
export const limitPriceSelector = (state: IState) => state.placeOrder?.limitPrice;
export const reduceOnlySelector = (state: IState) => state.placeOrder?.reduceOnly;
export const closePositionConfirmationModalIsOpenSelector = (state: IState) =>
  state.placeOrder?.closePositionConfirmationModalIsOpen; // TODO
export const takeProfitPercentSelector = (state: IState) =>
  state.placeOrder?.takeProfitPercent;
export const stopLossPercentSelector = (state: IState) =>
  state.placeOrder?.stopLossPercent;
export const showConfirmationPopupSelector = (state: IState) =>
  state.placeOrder?.showConfirmationPopup;
export const quantityInputErrorMessageSelector = (state: IState) =>
  state.placeOrder?.quantityInputErrorMessage;
export const dontShowConfirmationCheckboxCheckedSelector = (state: IState) =>
  state.placeOrder?.dontShowConfirmationCheckboxChecked;
export const selectedPercentageSelector = (state: IState) =>
  state.placeOrder?.selectedPercentage;
export const dontShowConfirmationOnChartCheckedSelector = (state: IState) =>
  state.placeOrder?.dontShowConfirmationOnChartChecked;
export const orderSubmitStatusSelector = (state: IState) =>
  state.placeOrder?.orderSubmitStatus;
export const portfolioMarginValueSelector = (state: IState) =>
  state.placeOrder?.marginValue;
export const placeOrderQuantityInputHasErrorSelector = (state: IState) =>
  state.placeOrder?.quantityInputHasError;
export const userLeverageSelector = (state: IState) =>
  state.placeOrder?.userInputLeverage;
export const leverageMaxNotionalValueSelector = (state: IState) =>
  state.placeOrder?.leverageMaxNotionalValue;
export const userSelectedQuantityPercentageSelector = (state: IState) =>
  state.placeOrder?.userSelectedQuantityPercentage;

export const decimalHandle = inputedQuantity => {
  switch (inputedQuantity?.toString?.()) {
    case '.':
    case '0.':
    case '.0':
      return 0;
    default:
      return inputedQuantity;
  }
};

/**
 * Calculations
 */
export const getTotalPositonSize = createSelector(
  [allOpenPositionsSelector, selectedProductSelector],
  positionSizeForSelectedProduct
);

export const selectedProductBalance = createSelector(
  [
    selectedProductSelector,
    balanceBySymbolSelector,
    userMarginModeSelector,
    tradingCreditsSelector,
    multiCollateralSelector,
    sideSelection,
  ],
  getSelectedProductBalance
);

// TODO: too much calculation. can be received from backend
export const optionsMaxQuantitySize = createSelector(
  [
    selectedProductSelector,
    l2OrderbookSelectorWithDepth,
    sideSelection,
    leverageState,
    selectedProductBalance,
    spotPriceSelector,
    markPriceSelector,
    getTotalPositonSize,
    limitPriceSelector,
  ],
  getNonSpotMaxSize
);

export const primaryCurrencySelector = createSelector(
  [selectedProductSelector],
  (selectedProduct): string => {
    const contractType = selectedProduct?.contract_type;
    const quotingAsset = selectedProduct?.quoting_asset;
    const underlyingAsset = selectedProduct?.underlying_asset;
    const notionalType = selectedProduct?.notional_type;
    if (isSpotContract(contractType)) {
      return quotingAsset?.symbol ?? '';
    }
    // TODO: convert to helper functions, isFutures, isOptions, isPerp
    if (
      (contractType === 'futures' || contractType === 'perpetual_futures') &&
      notionalType === 'inverse'
    ) {
      return quotingAsset?.symbol ?? '';
    }
    return underlyingAsset?.symbol ?? '';
  }
);

export const secondaryCurrencySelector = createSelector(
  [selectedProductSelector],
  (selectedProduct): string => {
    const contractType = selectedProduct?.contract_type;
    const quotingAsset = selectedProduct?.quoting_asset;
    const underlyingAsset = selectedProduct?.underlying_asset;
    const notionalType = selectedProduct?.notional_type;
    const settlingAsset = selectedProduct?.settling_asset;
    if (isSpotContract(contractType)) {
      return underlyingAsset?.symbol ?? '';
    }
    if (contractType === 'futures' || contractType === 'perpetual_futures') {
      if (notionalType === 'inverse') {
        return underlyingAsset?.symbol ?? '';
      }
      return quotingAsset?.symbol ?? '';
    }
    return settlingAsset?.symbol ?? '';
  }
);

export const quantityInputValueInContract = createSelector(
  [quantityInputValue, selectedProductSelector, sideSelection],
  (inputedQuantity, selectedProduct, side) => {
    if (isSpotContract(selectedProduct?.contract_type)) {
      return inputedQuantity;
    }
    return convertQuantityValueToNumberOfContracts(
      inputedQuantity ?? 0,
      selectedProduct ?? {},
      side
    );
  }
);

export const spotMaxQuantititySize = createSelector(
  [
    balanceBySymbolSelector,
    getPrefilledValuesState,
    sideSelection,
    selectedProductSelector,
    quantityInputDropdownValue,
    l2OrderbookSelectorWithDepth,
    tradingCreditsSelector,
  ],
  (
    balanceState,
    prefilledValues,
    side,
    selectedProduct,
    dropdownValue,
    orderbook,
    assetIdTradingCreditsMapping
  ): number => {
    return (
      !isEmpty(balanceState) &&
      computeSpotMaxQuantity({
        balanceState,
        orderPrice: prefilledValues?.price ?? 0,
        side,
        product: selectedProduct,
        isQuoteAsset: dropdownValue === selectedProduct?.quoting_asset?.symbol,
        orderbook,
        assetIdTradingCreditsMapping,
      })
    );
  }
);

const getCurrencyForInverseNotionalType = (
  primaryCurrency: string,
  secondaryCurrency: string,
  preference: QuantityDropdownPreference
) => {
  switch (preference?.inverse) {
    case QuantityDropDownType.QUOTING:
      return primaryCurrency;
    case QuantityDropDownType.UNDERLYING:
      return secondaryCurrency;
    case QuantityDropDownType.CONT:
      return 'Cont';
    default:
      return primaryCurrency;
  }
};

const getCurrencyForVanillaNotionalType = (
  primaryCurrency: string,
  secondaryCurrency: string,
  preference: QuantityDropdownPreference,
  isSecondaryCurrencyAssetSymbol: boolean
) => {
  const settlingType = isSecondaryCurrencyAssetSymbol
    ? preference?.vanilla?.usdt_settled
    : preference?.vanilla?.non_usdt_settled;

  switch (settlingType) {
    case QuantityDropDownType.SETTLING:
      return secondaryCurrency;
    case QuantityDropDownType.CONT:
      return 'Cont';
    default:
      return primaryCurrency;
  }
};

export const defaultCurrencySelector = createSelector(
  [
    selectedProductSelector,
    userPreferencesSelector,
    primaryCurrencySelector,
    secondaryCurrencySelector,
  ],
  (selectedProduct, userPreference, primaryCurrency, secondaryCurrency) => {
    const quantityDropdownPrefrence = userPreference?.quantity_dropdown_preference
      ? { ...userPreference.quantity_dropdown_preference }
      : {
          inverse: null,
          vanilla: {
            usdt_settled: null,
            non_usdt_settled: null,
          },
        };
    const notionalType = selectedProduct?.notional_type;

    if (notionalType === NotionalType.Inverse) {
      return getCurrencyForInverseNotionalType(
        primaryCurrency,
        secondaryCurrency,
        quantityDropdownPrefrence
      );
    }

    if (notionalType === NotionalType.Vanilla) {
      const isSecondaryCurrencyAssetSymbol = secondaryCurrency === ASSET_SYMBOL;
      return getCurrencyForVanillaNotionalType(
        primaryCurrency,
        secondaryCurrency,
        quantityDropdownPrefrence,
        isSecondaryCurrencyAssetSymbol
      );
    }

    return primaryCurrency;
  }
);

// Function to determine the value based on asset and notional type
export const valueForNotionalTypeSelector = createSelector(
  [primaryCurrencySelector, selectedProductSelector, quantityInputDropdownValue],
  (primaryCurrency, selectedProduct, asset) => {
    const notionalType = selectedProduct?.notional_type;

    if (asset === QuantityDropDownType.CONT) return QuantityDropDownType.CONT;
    if (notionalType === NotionalType.Inverse) {
      return asset === primaryCurrency
        ? QuantityDropDownType.QUOTING
        : QuantityDropDownType.UNDERLYING;
    }
    return asset === primaryCurrency
      ? QuantityDropDownType.UNDERLYING
      : QuantityDropDownType.SETTLING;
  }
);

// updates the user preference based on the assest he selects
export const quantityCurrencyInPreferencesSelector = createSelector(
  [userPreferencesSelector, valueForNotionalTypeSelector, selectedProductSelector],
  (userPreference, value, selectedProduct) => {
    const notionalType = selectedProduct?.notional_type;
    const settlingAsset = selectedProduct?.settling_asset;

    // Clone the existing preferences deeply as needed
    const quantityDropdownPreference = userPreference?.quantity_dropdown_preference || {};
    const preferencesUpdate = {
      preferences: {
        quantity_dropdown_preference: {
          inverse: quantityDropdownPreference.inverse ?? null,
          vanilla: quantityDropdownPreference.vanilla ?? {
            usdt_settled: null,
            non_usdt_settled: null,
          },
        },
      },
    };

    if (notionalType === NotionalType.Inverse) {
      preferencesUpdate.preferences.quantity_dropdown_preference.inverse = value;
    } else if (notionalType === NotionalType.Vanilla) {
      if (settlingAsset?.symbol === ASSET_SYMBOL) {
        preferencesUpdate.preferences.quantity_dropdown_preference.vanilla.usdt_settled =
          value;
      } else {
        // eslint-disable-next-line max-len
        preferencesUpdate.preferences.quantity_dropdown_preference.vanilla.non_usdt_settled =
          value;
      }
    }
    return preferencesUpdate;
  }
);

export const calculatedQuantityOnDropdownChangeSelector = createSelector(
  [
    quantityInputDropdownValue,
    selectedProductSelector,
    quantityInputValueInContract,
    getPrefilledValuesState,
    sideSelection,
    (state, valueFromPercent) => valueFromPercent,
  ],
  (asset, selectedProduct, qty, prefilledQuantity, side, valueFromPercent) =>
    convertNumberOfContractsToSelectedCurrency(
      valueFromPercent ?? qty ?? prefilledQuantity,
      selectedProduct,
      asset,
      side
    )
);

export const quantityInputPrefilledValuesSelector = createSelector(
  [
    selectedProductSelector,
    getPrefilledValuesState,
    l2OrderbookSelectorWithDepth,
    sideSelection,
    quantityInputDropdownValue,
  ],
  (selectedProduct, prefilledValues, orderbook, side, dropDownValue) => {
    const contractType = selectedProduct?.contract_type;
    // if contract type is not spot, no need to compute further
    if (contractType !== 'spot') {
      return prefilledValues?.size;
    }

    // check if dropdown value is quoting symbol
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;

    if (!isQuoteAsset) {
      return prefilledValues?.size;
    }

    const impactPrice =
      calculateSpotImpactPrice(
        orderbook,
        side,
        prefilledValues?.size,
        selectedProduct,
        isQuoteAsset
      ) ?? 0;
    const size = truncAfterDecimal(
      convertExponentialToDecimal(
        aggregateSpotQuoteNotional(impactPrice, orderbook, prefilledValues?.size, side)
      ),
      4
    );
    return size;
  }
);

export const hintTextSelector = createSelector(
  [
    quantityInputDropdownValue,
    selectedProductSelector,
    quantityInputValueInContract,
    sideSelection,
    quantityInputValue,
  ],
  hintTextToShowInQuantityInput
);

export const inputValueBasedOnPercentageSelector = createSelector(
  [
    optionsMaxQuantitySize,
    spotMaxQuantititySize,
    selectedProductSelector,
    (state, percentVal) => percentVal,
  ],
  (maxSize, spotMaxSize, selectedProduct, percentage) => {
    if (selectedProduct?.contract_type === ContractType.Spot) {
      return truncAfterDecimal(convertExponentialToDecimal(spotMaxSize * percentage), 5);
    }
    return parseInt(String(percentage * maxSize), 10);
  }
);

const openBuyOrdersSelector = createSelector([openBuyOrdersState], openBuyOrdersRaw =>
  pipe(
    () => openBuyOrdersRaw,
    orders => orders.map(pick(['unfilled_size', 'limit_price'])),
    sort((a, b) => Number(b?.limit_price ?? 0) - Number(a?.limit_price ?? 0))
  )()
);

const openSellOrdersSelector = createSelector([openSellOrdersState], openSellOrdersRaw =>
  pipe(
    () => openSellOrdersRaw,
    orders => orders.map(pick(['unfilled_size', 'limit_price'])),
    sort((a, b) => Number(a.limit_price) - Number(b.limit_price))
  )()
);

export const marginCalculationSelector = createSelector(
  [
    selectedProductSelector,
    userMarginModeSelector,
    openPositionState,
    leverageState,
    limitPriceSelector,
    quantityInputValueInContract,
    sideSelection,
    l2OrderbookSelectorWithDepth,
    markPriceState,
    spotPriceState,
    orderMarginSelector,
    openBuyOrdersSelector,
    openSellOrdersSelector,
  ],
  (
    selectedProduct,
    marginMode,
    position,
    leverage,
    orderPrice,
    orderSize,
    side,
    orderbooks,
    markPrice,
    spotPrice,
    orderMargins,
    openBuyOrders,
    openSellOrders
  ) => {
    if (marginMode === MARGIN_TYPES.PORTFOLIO || !orderSize) {
      return null; // Or some default state indicating not to proceed
    }

    const orderMargin = Number(
      orderMargins[selectedProduct?.id ?? '']?.order_margin ?? 0
    );

    const price = isOptions(selectedProduct?.contract_type)
      ? calculateEstimatedMarketPrice(
          orderSize ?? 0,
          convertOrderBook(orderbooks, side),
          selectedProduct?.tick_size ?? 0,
          selectedProduct?.notional_type ?? NotionalType.Vanilla,
          markPrice
        )
      : markPrice;

    const result = {
      mark_price: price,
      product: selectedProduct,
      position: position || 0,
      leverage,
      order_margin: orderMargin,
      user_buy_orders: openBuyOrders,
      user_sell_orders: openSellOrders,
      side,
      size: orderSize,
      limit_price: orderPrice,
      spot_price: spotPrice,
    };
    return result;
  }
);

export const marginValueSelector = createSelector(
  [
    quantityInputValueInContract,
    limitPriceSelector,
    sideSelection,
    leverageState,
    userMarginModeSelector,
    portfolioMarginValueSelector,
    selectedProductSelector,
    reduceOnlySelector,
  ],
  (
    size,
    limitPrice,
    side,
    leverage,
    marginMode,
    portfolioMarginValue,
    selectedProduct,
    reduceOnly
  ) => {
    if (marginMode === MARGIN_TYPES.PORTFOLIO) {
      return portfolioMarginValue;
    }
    const precision = selectedProduct?.settling_asset?.minimum_precision;
    if (reduceOnly) {
      return round(0, precision ?? 2);
    }
    const result = round(
      calculateEstimateMarginForPlaceOrder(
        size,
        limitPrice,
        selectedProduct ?? {},
        side,
        leverage
      ),
      precision ?? 2
    );
    if (marginMode === MARGIN_MODE.CROSS) {
      return `~${result}`;
    }
    return result;
  }
);

export const estimatedExecPriceSelector = createSelector(
  [
    selectedProductSelector,
    l2OrderbookSelectorWithDepth,
    sideSelection,
    quantityInputValueInContract,
    quantityInputDropdownValue,
    markPriceState,
  ],
  (selectedProduct, orderbook, side, orderSize, dropDownValue, markPrice) => {
    const orders = convertOrderBook(orderbook, side);
    const contractType = selectedProduct?.contract_type;

    // check if dropdown value is quoting symbol
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const isQuoteAsset =
      contractType === ContractType.Spot && dropDownValue === quotingSymbol;

    if (selectedProduct?.contract_type === ContractType.Spot) {
      const impactPrice = calculateSpotImpactPrice(
        orderbook,
        side,
        orderSize,
        selectedProduct,
        isQuoteAsset
      );
      const size = isQuoteAsset
        ? aggregateSpotUnderlyingNotional(
            impactPrice ?? 0,
            orderbook,
            orderSize ?? 0,
            side
          )
        : orderSize;
      return calculateSpotEstimatedMarketPriceUsingUnderlyingSize(size, orders);
    }
    return calculateEstimatedMarketPrice(
      orderSize ?? 0,
      orders,
      selectedProduct?.tick_size,
      selectedProduct?.notional_type,
      markPrice
    );
  }
);

export const takeProfitEstPriceSelector = createSelector(
  [
    estimatedExecPriceSelector,
    takeProfitPercentSelector,
    sideSelection,
    selectedProductSelector,
  ],
  (estimatedExecPrice, takeProfitPercent, side, selectedProduct) =>
    calculateTakeProfitEstPrice({
      val: takeProfitPercent,
      side,
      orderPrice: estimatedExecPrice,
      tick_size: selectedProduct?.tick_size,
    })
);

export const generateOrderSelector = createSelector(
  [
    orderTypeSelection,
    quantityInputValueInContract,
    sideSelection,
    selectedProductSelector,
    reduceOnlySelector,
    quantityInputDropdownValue,
    closePositionConfirmationModalIsOpenSelector,
    takeProfitEstPriceSelector,
    stopLossPercentSelector,
  ],
  (
    orderType,
    orderSize,
    side,
    selectedProduct,
    reduceOnly,
    dropDownValue,
    confirmationModalIsOpen,
    takeProfitEstPrice,
    stopLossPercent
  ) => {
    if (orderSize <= 0) {
      return null;
    }
    const order: any = {
      order_type: ORDER_TYPE[orderType?.toUpperCase()],
      side,
      product_id: selectedProduct?.id,
      reduce_only: reduceOnly.toString(),
    };
    const contractType = selectedProduct?.contract_type;
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;
    if (selectedProduct?.contract_type === ContractType.Spot && isQuoteAsset) {
      order.quote_size = Number(orderSize);
    } else {
      order.size = Number(orderSize);
    }

    if (takeProfitEstPrice) {
      order.bracket_take_profit_price = takeProfitEstPrice;
    }

    if (stopLossPercent) {
      order.bracket_stop_loss_price = stopLossPercent;
    }

    if (confirmationModalIsOpen) {
      order.cancel_orders_accepted = true;
    }

    return order;
  }
);

export const getAccountEquitySelector = createSelector(
  [enableWalletsSelector, allOpenPositionsSelector, portfolioMarginSelector],
  (balance, allOpenPositions, portfolioMarginData) => {
    const { positions_upl: positionsUpl, asset_id: portfolioEnabledAsset } =
      portfolioMarginData?.aggregate || {};
    const getMarkPrice = productId => openPositionMarkPriceSelectorById(productId);

    if (balance?.USDT) {
      return getBalanceEquity(
        balance?.USDT,
        allOpenPositions,
        getMarkPrice,
        positionsUpl,
        portfolioEnabledAsset!
      );
    }
    return 0;
  }
);

export const showReduceOnlyBanner = createSelector(
  [
    selectedProductSelector,
    userMarginModeSelector,
    getAccountEquitySelector,
    portfolioEnableMinBalanceSelector,
  ],
  (selectedProduct, marginMode, equity, pfThreshold) => {
    return (
      selectedProduct?.contract_type !== ContractType.Spot &&
      marginMode === MARGIN_MODE.PORTFOLIO &&
      Number(equity) <= Number(pfThreshold)
    );
  }
);

export const slippageCheckSelector = createSelector(
  [
    selectedProductSelector,
    quantityInputValueInContract,
    markPriceState,
    spotPriceState,
    sideSelection,
    l2OrderbookSelectorWithDepth,
    estimatedExecPriceSelector,
  ],
  (
    selectedProduct,
    size,
    markPrice,
    spotPrice,
    side,
    orderbooks,
    estimatedExecutionPrice
  ) => {
    const orders = convertOrderBook(orderbooks, side);
    const precision =
      selectedProduct?.contract_type === 'spot'
        ? selectedProduct?.quoting_asset?.minimum_precision
        : selectedProduct?.quoting_asset?.minimum_precision;

    const slippage = slippageCheck({
      estimatedExecutionPrice,
      markPrice,
      contractType: selectedProduct?.contract_type,
      precision,
      side,
      spotPrice,
      orders,
      size,
    });
    return slippage;
  }
);

export const spotLowBalanceErrorSelector = createSelector(
  [
    balanceBySymbolSelector,
    selectedProductSelector,
    sideSelection,
    quantityInputValue,
    l2OrderbookSelectorWithDepth,
    quantityInputDropdownValue,
  ],
  (balanceState, selectedProduct, side, size, orderbook, dropDownValue) => {
    const isBuyActionState = side === SIDE.BUY;
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const contractType = selectedProduct?.contract_type;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;
    const underlyingSymbol = selectedProduct?.underlying_asset?.symbol;

    if (contractType === ContractType.Spot && size) {
      const balanceSymbol = isBuyActionState ? quotingSymbol : underlyingSymbol;

      const price = calculateSpotImpactPrice(
        orderbook,
        side,
        size,
        selectedProduct,
        isQuoteAsset
      );

      const { available_balance } = getBalanceBySymbol(balanceSymbol, balanceState);

      const totalCost = getSpotTotalCost(price, size, isQuoteAsset, isBuyActionState);
      return Number(totalCost) > Number(available_balance);
    }
    return false;
  }
);

export const lowBalanceErrorSelector = createSelector(
  [
    balanceBySymbolSelector,
    selectedProductSelector,
    userMarginModeSelector,
    sideSelection,
    multiCollateralSelector,
    marginValueSelector,
    spotLowBalanceErrorSelector,
  ],
  (
    balanceState,
    selectedProduct,
    marginMode,
    side,
    multiCollateralData,
    margin,
    spotLowBalanceError
  ) => {
    if (spotLowBalanceError) {
      return true;
    }
    const isBuyActionState = side === SIDE.BUY;

    const { available_margin_usdt, available_margin_long_options } = multiCollateralData;
    /**
     * returns true if
     * - multiCollateralData is less than margin [ MarginMode = CROSS ]
     * - balanceState[symbol]?.available_balance is less than margin [ MarginMode != CROSS ]
     */
    const marginValue = String(margin)?.replace?.('~', '');
    const lowBalance = checkLowBalance(
      selectedProduct,
      marginValue,
      balanceState,
      marginMode,
      isBuyActionState && isOptions(selectedProduct?.contract_type)
        ? available_margin_long_options
        : available_margin_usdt
    );

    return lowBalance;
  }
);

export const quantityInputHasErrorSelector = createSelector(
  [
    placeOrderQuantityInputHasErrorSelector,
    quantityInputErrorMessageSelector,
    lowBalanceErrorSelector,
  ],
  (quantityInputHasError, errorMessage, lowBalanceError) =>
    quantityInputHasError || errorMessage || lowBalanceError
);

export const isFormValidSelector = createSelector(
  [
    selectedProductSelector,
    leverageState,
    quantityInputValueInContract,
    lowBalanceErrorSelector,
    reduceOnlySelector,
    l2OrderbookSelectorWithDepth,
    sideSelection,
    takeProfitEstPriceSelector,
    estimatedExecPriceSelector,
    quantityInputHasErrorSelector,
  ],
  (
    selectedProduct,
    leverage,
    orderSize,
    showLowBalanceMessage,
    reduceOnly,
    orderbooks,
    side,
    takeProfitEstPrice,
    orderPrice,
    quantityInputHasError
  ) => {
    if (quantityInputHasError) {
      return false;
    }
    const orders = convertOrderBook(orderbooks, side);
    const maxValue = 100 / parseInt(selectedProduct?.initial_margin ?? '0', 10);

    if (!reduceOnly && (showLowBalanceMessage || isEmpty(orders))) {
      return false;
    }

    if (!orderSize || orderSize <= 0) {
      return false;
    }

    if (
      (typeof leverage === 'undefined' ||
        Number(leverage) === 0 ||
        leverage === '' ||
        gt(leverage, maxValue)) &&
      selectedProduct?.contract_type !== ContractType.Spot
    ) {
      return false;
    }

    // TODO: check if this is needed
    // if (leverageEdit) {
    //   return false;
    // }

    const isTakeProfitValid = isValidTakeProfitValue({
      takeProfitEstPrice,
      orderPrice,
      side,
    });

    if (!isTakeProfitValid) {
      return false;
    }

    return true;
  }
);

export const impactSizeErrorSelector = createSelector(
  [
    selectedProductSelector,
    l2OrderbookSelectorWithDepth,
    sideSelection,
    quantityInputDropdownValue,
    quantityInputValueInContract,
  ],
  (selectedProduct, orderbook, side, dropDownValue, orderSize) => {
    const contractType = selectedProduct?.contract_type;

    // check if dropdown value is quoting symbol
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;

    const impactPrice = calculateSpotImpactPrice(
      orderbook,
      side,
      orderSize,
      selectedProduct,
      isQuoteAsset
    );

    const impactSizeError =
      selectedProduct?.contract_type === ContractType.Spot
        ? impactSizeCheckSpot({
            size: orderSize,
            isQuoteAsset,
            price: impactPrice,
            impactSize: selectedProduct.impact_size,
            contractCurrency: selectedProduct.contract_unit_currency,
          })
        : impactSizeCheck(
            orderSize,
            selectedProduct?.impact_size,
            selectedProduct?.contract_value,
            selectedProduct?.contract_unit_currency
          );

    return impactSizeError;
  }
);

export const isDepositSymbolEnabledSelector = createSelector(
  [balanceBySymbolSelector, selectedProductSelector, sideSelection],
  (balanceState, selectedProduct, side) => {
    const isBuyActionState = side === SIDE.BUY;
    let depositSymbol;
    if (selectedProduct?.contract_type === ContractType.Spot) {
      if (isBuyActionState) {
        depositSymbol = selectedProduct?.quoting_asset?.symbol;
      } else {
        depositSymbol = selectedProduct?.underlying_asset?.symbol;
      }
    } else {
      depositSymbol = selectedProduct?.settling_asset?.symbol;
    }

    const isDepositEnabled =
      path([depositSymbol, 'asset', 'deposit_status'], balanceState) ?? false;
    return { isDepositEnabled, depositSymbol };
  }
);

export const confirmationHeaderSelector = createSelector(
  [
    selectedProductSelector,
    quantityInputValueInContract,
    quantityInputDropdownValue,
    orderTypeSelection,
  ],
  (selectedProduct, size, dropDownValue, orderType) => {
    const contractType = selectedProduct?.contract_type;
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const underlyingSymbol = selectedProduct?.underlying_asset?.symbol;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;

    switch (contractType) {
      case 'spot':
        return `${size} ${
          isQuoteAsset ? `${quotingSymbol} ${i18next.t('trading:worthOf')}` : ''
        } ${underlyingSymbol} ${i18next.t('common:at')} ${orderType} ${
          orderType === PLACE_ORDER_TYPE.MARKET ? '' : quotingSymbol
        }`;

      default:
        return `${size} contract${Number(size) > 1 ? 's' : ''} ${i18next.t(
          'common:of'
        )} ${selectedProduct?.symbol} ${i18next.t('common:at')} ${orderType}`;
    }
  }
);

export const confirmationTableDataSelector = createSelector(
  [
    selectedProductSelector,
    quantityInputValueInContract,
    quantityInputDropdownValue,
    sideSelection,
    l2OrderbookSelectorWithDepth,
    balanceSelector,
    openPositionState,
    marginValueSelector,
    leverageState,
    estimatedExecPriceSelector,
    userMarginModeSelector,
    tradePreferencesSelector,
    marginValueSelector,
    appliedOffersSelector,
    activeOffersSelector,
    multiCollateralSelector,
    selectedProductBalance,
  ],
  (
    selectedProduct,
    size,
    dropDownValue,
    side,
    orderbook,
    balances,
    position,
    orderMargin,
    leverage,
    estimatedExecPrice,
    marginType,
    tradePreferences,
    estimatedPortfolioMargin,
    appliedOffers,
    activeOffers,
    multiCollateralData,
    productBalance
  ) => {
    const contractType = selectedProduct?.contract_type;
    const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
    const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;
    const isBuyActionState = side === SIDE.BUY;

    if (contractType === ContractType.Spot) {
      return spotMarketPlaceOrderConfirmationTable({
        selected_product: selectedProduct,
        side,
        orderSize: size,
        isQuoteAsset,
        balances,
        isBuyActionState,
        orderbook,
      });
    }
    const { roundedBalance } = productBalance;

    return marketPlaceOrderConfirmationTable({
      selected_product: selectedProduct,
      position,
      side,
      orderSize: size,
      orderMargin,
      isBuyActionState,
      leverage,
      balances,
      estimatedExecPrice,
      marginType,
      vip_discount_factor: tradePreferences.vip_discount_factor,
      estimatedPortfolioMargin,
      appliedOffers,
      activeOffers,
      multiCollateralData,
      margin_mode: marginType,
      roundedBalance,
    });
  }
);

export const effectiveLeveragePortfolioMarginSelector = createSelector(
  [selectedProductSelector, quantityInputValueInContract, marginValueSelector],
  (selectedProduct, orderSize, margin) => {
    const markPrice = markPriceState();
    const spotPrice = spotPriceState();

    const notionalInUsd = getNotional(
      selectedProduct?.notional_type,
      selectedProduct?.contract_value,
      orderSize,
      isOptions(selectedProduct?.contract_type) ? spotPrice : markPrice
    );
    const effectiveLeverage = division(Number(notionalInUsd), Number(margin));
    return effectiveLeverage;
  }
);

export const quantityDropdownValueSelector = createSelector(
  [primaryCurrencySelector, secondaryCurrencySelector, selectedProductSelector],
  (primaryCurrency, secondaryCurrency, selectedProduct) => {
    const contractType = selectedProduct?.contract_type;
    const values: string[] = [];

    if (primaryCurrency) {
      values.push(primaryCurrency);
    }

    if (secondaryCurrency) {
      values.push(secondaryCurrency);
    }

    if (!isSpotContract(contractType)) {
      values.push('Cont');
    }
    return values;
  }
);
