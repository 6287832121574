import React from 'react';
import errorImgPath from 'styles/assets/error.png';
// import PropTypes from 'PropTypes';
import { ErrorScreen } from './error_screen';

const Whoops = props => {
  const { title, onClick } = props;

  const handleSignout = () => {
    // Reload the current page without the browser cache
    localStorage.clear();
    onClick();
  };

  return (
    <ErrorScreen>
      <div className="error-img-wrapper">
        {/* //todo-h: why aren't responsive img utilities added to the main scss? */}
        <img src={errorImgPath} className="error-img" alt="Error" />
      </div>
      <p className="sorry">{title}</p>
      <button type="button" onClick={props.onClick} className="reload-button">
        Reload Page
      </button>
      <p className="signout-text">
        If that doesn't work, please try{' '}
        <button onClick={handleSignout} className="signout-link">
          signing out
        </button>{' '}
        and back in.
      </p>
    </ErrorScreen>
  );
};

Whoops.defaultProps = {
  title: 'Doh! Something went wrong...',
  onClick: () => {
    window.location.reload(true);
  },
};

// Whoops.propTypes = {
//   title: PropTypes.string,
//   onClick: PropTypes.func,
// };

export default Whoops;
