import React from 'react';
import Radio from '@material-ui/core/Radio';
import styles from './index.module.scss';

const RadioField = ({
  name,
  checked,
  onChange,
  radioRootClassName,
  dataTestID,
}) => {
  return (
    (<div
      onClick={onChange}
      className={`${checked ? styles.activeRadioField : ''} ${
        styles.radioField
      }`}
      data-palette="RadioField">
      <Radio
        className="radio-button"
        name="margin"
        checked={checked}
        classes={{
          root: `radio-root ${radioRootClassName || ''}`,
          checked: 'radio-checked',
        }}
        data-testid={dataTestID || 'radio-field'}
      />
      <span className="radio-label pointer">{name}</span>
    </div>)
  );
};

export default RadioField;
