/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MARGIN_TYPES, LANDING_PAGE_URL, IS_INDIAN_EXCHANGE } from 'constants/constants';
import routes from 'constants/routes';
import Popover from 'containers/Popover';
import {
  getUnrealisedPnl,
  // getIrsUnPnl,
  getPayOff,
  getIrsPayoff,
} from 'helpers/formulas';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { contains } from 'helpers/ramda';
import subscribe from 'HOC/subscribe';
import useOpenPositionExitPrice from 'hooks/componentHooks/useOpenPositionExitPrice';
import { conversionConfigSelector, stakingPoolSelector } from 'selectors';
import { spotPriceSelectorBySymbol } from 'selectors/priceSelectors';
import { useAppSelector } from 'storeHooks';
import {
  HoldingsWithdrawIcon,
  HoldingsConvertIcon,
  LeanTradeIcon,
  HoldingsDepositIcon,
} from 'styles/assets/icons';

import { BalanceActionWrapper } from './styles';

// import { useLastTradedPrice } from 'hooks';

const LocalizedText = ({ localeKey }) => {
  const { t } = useTranslation(['account']);
  return t(`account:${localeKey}`);
};

// todo-suraj:move this to formula.js
// This is the functin for upl isolated & it's renamed as unrealised cashflow; there won't be any premium but only payoff
export const getBalanceUnrealizedPnl = (id, openPositionsList) => {
  // (!margin_mode || margin_mode === 'isolated'): This condition has been added bcz positions under portfolio are supposed to be ignored
  return openPositionsList
    .filter(
      position =>
        position?.product?.settling_asset?.symbol === id &&
        (!position.margin_mode || position.margin_mode !== MARGIN_TYPES.PORTFOLIO)
    )
    .reduce((acc, position) => {
      const spotPrice = spotPriceSelectorBySymbol(position.product.spot_index.symbol);

      const exitPrice = useOpenPositionExitPrice(position);

      // const positionObj = {
      //   realized_funding: position.realized_funding,
      //   realized_pnl: position.realized_pnl,
      //   size: position.size,
      //   margin: position.margin,
      // };

      const unrealisedCashflow = (() => {
        switch (position.product?.contract_type) {
          case 'interest_rate_swaps':
            return getIrsPayoff(position.size, exitPrice, position.product, spotPrice);
          case 'move_options':
          case 'put_options':
          case 'call_options':
          case 'options_combos':
            return getPayOff(position.product?.contract_value, position.size, exitPrice);
          default:
            return getUnrealisedPnl(
              position.product?.notional_type,
              position.product?.contract_value,
              position.entry_price,
              exitPrice,
              position.size,
              position.product?.contract_type
            );
        }
      })();
      return acc + unrealisedCashflow;
    }, 0);
};

/**
 *
 * @param {object} balances
 * @param {array} openPositionsList
 * @param {function} getMarkPrice
 * @param {Number} portfolio_upl
 * @param {Number} [portfolioEnabledAsset]
 * @returns balanceEquity of that asset
 */
export const getBalanceEquity = (
  // eslint-disable-next-line default-param-last
  balances = {},
  openPositionsList,
  getMarkPrice,
  // eslint-disable-next-line default-param-last
  portfolio_upl = 0,
  portfolioEnabledAsset
) => {
  const unrealised_cashflow = getBalanceUnrealizedPnl(
    balances?.asset?.symbol,
    openPositionsList,
    getMarkPrice
  );
  const portfolio_cashflow =
    portfolioEnabledAsset === balances?.asset?.id ? Number(portfolio_upl) : 0;

  const balanceEquity =
    Number(unrealised_cashflow) + Number(balances.balance) + portfolio_cashflow;
  return balanceEquity;
};

export const ConvertButton = ({ coin, onClick, from }) => {
  if (from === 'account-balance-page-mobile') {
    return (
      (<span
        role="button"
        tabIndex={0}
        onKeyDown={() => { }}
        data-testid="convert-mobile"
        onClick={() => onClick(coin)}
        data-palette="ConvertButton">
        <HoldingsConvertIcon
          className="trade-icon"
          color="#FFFFFF"
          width="16px"
          height="16px"
        />{' '}
        <span>Convert</span>
      </span>)
    );
  }
  if (from === 'account-balance-page') {
    return (
      (<span
        role="button"
        tabIndex={0}
        onKeyDown={() => { }}
        data-testid="convert-desktop"
        onClick={() => onClick(coin)}
        data-palette="ConvertButton">
        <HoldingsConvertIcon
          className="trade-icon"
          color="#FFFFFF"
          width="16px"
          height="16px"
        />{' '}
        <span>Convert</span>
      </span>)
    );
  }
  return (
    (<span
      role="button"
      tabIndex={0}
      onKeyDown={() => { }}
      data-testid="holdings-convert"
      onClick={() => onClick(coin)}
      data-palette="ConvertButton">
      <HoldingsConvertIcon color="#FFFFFF" width="16px" height="16px" />{' '}
    </span>)
  );
};

ConvertButton.propTypes = {
  coin: PropTypes.string,
  onClick: PropTypes.func,
  from: PropTypes.string,
};

// todo-suraj:refactor styling and component
export const BalanceActions = subscribe(prop => {
  const { t } = useTranslation(['account']);
  const stakingPoolFunds = useAppSelector(stakingPoolSelector) || [];
  const symbol = stakingPoolFunds?.find(obj => {
    return obj?.asset?.symbol?.toLowerCase() === prop?.coin?.toLowerCase();
  })?.symbol;
  const strategySymbolSegment = symbol
    ? symbol?.split('_')?.join('-')?.toLowerCase()
    : '';
  const stakingUrl = symbol
    ? `${LANDING_PAGE_URL}/invest/staking-pools/${strategySymbolSegment}`
    : null;
  const {
    isDepositStatusEnabled = false,
    from = '',
    isWithdrawStatusEnabled = false,
  } = prop;

  const depositRoute = `${routes.account.deposit}/${prop.coin}`;
  const withdrawalRoute = `${routes.account.withdrawal}/${prop.coin}`;

  const { convertible_assets } = useAppSelector(state => conversionConfigSelector(state));

  const isConversionEnabled = contains(prop.coin, convertible_assets);

  const onDepositClick = () => {
    trackMixpanelEvent('Deposit Clicked - All Pages', {
      source: 'Balances Page',
    });
  };

  const onConvertClick = () => {
    trackMixpanelEvent('Conversion Clicked - All Pages', {
      source: 'Balances Page',
    });
  };

  const onWithdrawalClick = () => {
    trackMixpanelEvent('Withdrawal Clicked - All Pages', {
      source: 'Balances Page',
    });
  };

  if (from === 'account-balance-page-mobile') {
    return (
      (<BalanceActionWrapper className="account-balance-page-mobile d-flex">
        {isDepositStatusEnabled && (
          <div
            className={classnames('account-balance-page-mobile', {
              'col-7': isWithdrawStatusEnabled,
              'col-12': !isWithdrawStatusEnabled,
            })}
            data-palette="BalanceActions">
            <Link className="deposit-link" to={depositRoute}>
              {' '}
              <HoldingsDepositIcon color="#FFFFFF" width={12} height={12} />
              <span>
                <LocalizedText localeKey="deposit" />
              </span>
            </Link>
          </div>
        )}
        {isWithdrawStatusEnabled && (
          <div
            className={classnames('account-balance-page-mobile', {
              'col-7': isDepositStatusEnabled,
              'col-12': !isDepositStatusEnabled,
            })}
            data-palette="BalanceActions">
            <Link className="withdrawal-link" to={withdrawalRoute}>
              {' '}
              <HoldingsWithdrawIcon color="#FFFFFF" width={12} height={12} />
              <span>
                <LocalizedText localeKey="withdraw" />
              </span>
            </Link>
          </div>
        )}
        {isConversionEnabled && (
          <div
            className={classnames(
              'convert-icon-balance-section account-balance-page-mobile convert',
              {
                'col-7': isDepositStatusEnabled && isWithdrawStatusEnabled,
                'col-12':
                  (!isDepositStatusEnabled && isWithdrawStatusEnabled) ||
                  (isDepositStatusEnabled && !isWithdrawStatusEnabled),
                'col-24': !isDepositStatusEnabled && !isWithdrawStatusEnabled,
              }
            )}
            data-palette="BalanceActions">
            <ConvertButton
              data-testid="BZwjR64DI64p1QRwTkK3o"
              coin={prop.coin}
              onClick={prop.setCurrencyConverter}
              from={prop.from}
            />
          </div>
        )}
        {stakingUrl && !IS_INDIAN_EXCHANGE && (
          <div
            className="col-7 account-balance-page-mobile"
            data-palette="BalanceActions">
            <a
              className="deposit-link"
              href={stakingUrl}
              data-testid={`stake_${prop.coin}`}
            >
              <LeanTradeIcon className="trade-icon" />
              <span>
                <LocalizedText localeKey="stake" />
              </span>
            </a>
          </div>
        )}
      </BalanceActionWrapper>)
    );
  }
  if (from === 'account-balance-page') {
    return (
      (<BalanceActionWrapper className="accounts-balance-page">
        {isDepositStatusEnabled && (
          <div className="accounts-balance-page" data-palette="BalanceActions">
            <Link className="deposit-link" to={depositRoute}>
              {' '}
              <HoldingsDepositIcon color="#FFFFFF" width={15} height={16} />
              <span style={{ paddingLeft: '4px' }}>
                <LocalizedText localeKey="deposit" />
              </span>
            </Link>
          </div>
        )}
        {isWithdrawStatusEnabled && (
          <div
            className="accounts-balance-page"
            data-testid={`holdingsTable_withdrawalBtn_${prop.coin}`}
            data-palette="BalanceActions">
            <Link className="withdrawal-link" to={withdrawalRoute}>
              {' '}
              <HoldingsWithdrawIcon color="#FFFFFF" width={15} height={16} />
              <span style={{ paddingLeft: '4px' }}>
                <LocalizedText localeKey="withdraw" />{' '}
              </span>
            </Link>
          </div>
        )}
        {isConversionEnabled && (
          <div
            className="convert-icon-balance-section accounts-balance-page"
            data-palette="BalanceActions">
            <ConvertButton
              data-testid={`holdingsTable_convertIcon_${prop.coin}`}
              coin={prop.coin}
              onClick={prop.setCurrencyConverter}
              from={prop.from}
            />
          </div>
        )}
        {stakingUrl && !IS_INDIAN_EXCHANGE && (
          <div
            className="convert-icon-balance-section trade accounts-balance-page"
            data-palette="BalanceActions">
            <a
              className="deposit-link trade-link"
              href={stakingUrl}
              data-testid={`holdingsTable_stake_${prop.coin}`}
            >
              <LeanTradeIcon />
              <span style={{ paddingLeft: '5px' }}>
                <LocalizedText localeKey="trade" />
              </span>
            </a>
          </div>
        )}
      </BalanceActionWrapper>)
    );
  }

  return (
    (<BalanceActionWrapper>
      {isDepositStatusEnabled && (
        <div className="icon" data-palette="BalanceActions">
          <Popover
            leaveDelay={0}
            shouldClosePrevPopover
            togglePrevPopover
            body={t('account:deposit')}
            placement="top"
            css
          >
            <Link
              data-testid="deposit-btn-holdings-table"
              className="deposit-link"
              to={depositRoute}
              onClick={onDepositClick}
            >
              {' '}
              <HoldingsDepositIcon color="#FFFFFF" width={15} height={15} />{' '}
            </Link>
          </Popover>
        </div>
      )}
      {isWithdrawStatusEnabled && (
        <div className="icon" data-palette="BalanceActions">
          <Popover
            leaveDelay={0}
            shouldClosePrevPopover
            togglePrevPopover
            body={t('account:withdraw')}
            placement="top"
          >
            {' '}
            <Link
              data-testid="withdrawal-btn-holdings-table"
              className="withdrawal-link"
              to={withdrawalRoute}
              onClick={onWithdrawalClick}
            >
              <HoldingsWithdrawIcon color="#FFFFFF" width={15} height={15} />
            </Link>
          </Popover>
        </div>
      )}
      {isConversionEnabled && (
        <div
          data-testid="convertBtn"
          role="button"
          tabIndex={0}
          onKeyDown={() => { }}
          className="icon"
          onClick={onConvertClick}
          data-palette="BalanceActions">
          <Popover
            leaveDelay={0}
            shouldClosePrevPopover
            togglePrevPopover
            body="Convert"
            placement="top"
          >
            <ConvertButton
              data-testid="convert-btn-holdings-table"
              coin={prop.coin}
              onClick={prop.setCurrencyConverter}
              from={prop.from}
            />
          </Popover>
        </div>
      )}
      {stakingUrl && !IS_INDIAN_EXCHANGE && (
        <div className="icon" data-palette="BalanceActions">
          <Popover
            leaveDelay={0}
            shouldClosePrevPopover
            togglePrevPopover
            body={t('account:stake')}
            placement="top"
          >
            <a
              className="deposit-link trade-link"
              href={stakingUrl}
              target="_blank"
              rel="noreferrer"
              data-testid={`holdings-table_stake_${prop.coin}`}
            >
              <LeanTradeIcon width={17} height={17} />
            </a>
          </Popover>
        </div>
      )}
    </BalanceActionWrapper>)
  );
});
