/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table';

import Render from 'components/render';
import {
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
  DEFAULT_PAGINATION_PAGE,
} from 'constants/constants';
import { getInt } from 'helpers/utils';
// import PropTypes from 'PropTypes';

import 'react-table/react-table.css';
import TablePagination from './pagination';
import { ReactTableStyle } from './table_styles';

const defaultPaginationConfig = {
  rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE,
  page: DEFAULT_PAGINATION_PAGE,
  options: [5, 10, 15, 20, 25, 30],
  totalCount: 0,
  onPageChange: () => null,
  onRowsPerPageChange: () => null,
  shouldHideRange: false,
  defaultSorted: {},
};

const Table = props => {
  const { t } = useTranslation(['common']);
  const {
    data,
    loading,
    manual,
    columns,
    className,
    pageSize,
    pages,
    fetchData,
    tableProps,
    showPagination,
    paginationConfig,
    noDataComponent,
    renderBelowTable: RenderBelowTable,
    alwaysShowPagination,
  } = props;
  const {
    page: defaultPage,
    rowsPerPage: perPage,
    options,
    totalCount,
    fetchNextData,
    fetchPrevData,
    onPageChange,
    onRowsPerPageChange,
    shouldHideRange,
    frontendPagination,
    paginationClassName,
  } = { ...defaultPaginationConfig, ...paginationConfig };

  // totalCount = 1
  // perPage = 5 (default)
  // take totalCount when less than perPage
  const PER_PAGE = totalCount < perPage ? totalCount : perPage;
  const [rowsPerPage, setRowsPerPage] = useState(PER_PAGE);

  // useState won't reset rowsPerPage while it's changing only props. Hence useEffect
  useEffect(() => {
    setRowsPerPage(PER_PAGE);
  }, [PER_PAGE]);

  useEffect(() => {
    if (props.paginationConfig.page === 0) {
      setPage(0);
    }
  }, [data, props.paginationConfig]);

  const [page, setPage] = useState(defaultPage);
  const rangeData = frontendPagination
    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : data;
  // const rangeData = frontendPagination ? : data;

  const tableData = showPagination ? rangeData : data;

  const isNextDataFetched = () => !!rangeData.length;

  const getNoDataText = () => {
    // when pagination = disabled
    // total data count = 0
    // rowsPerPage = empty || 0
    // page = 0
    if (!showPagination || !totalCount || !getInt(rowsPerPage) || page === 0) {
      return t('common:noRowsFound');
    }

    // when current range data is not fetched
    if (!isNextDataFetched()) {
      return t('common:loading');
    }

    // by default
    return t('common:noRowsFound');
  };

  const noDataComponentValue =
    noDataComponent && React.isValidElement(noDataComponent)
      ? noDataComponent
      : getNoDataText();

  return (
    <ReactTableStyle>
      <ReactTable
        data={tableData}
        loading={loading}
        manual={manual}
        columns={columns}
        className={className}
        pageSize={pageSize}
        pages={pages}
        onFetchData={fetchData}
        resizable={false}
        showPagination={false}
        noDataText={noDataComponentValue}
        // minRows={(!rangeData.length ? minRows : 5) || 0}
        minRows={0}
        {...tableProps} // Todo: check if react table is rerendering because of this
      />
      <RenderBelowTable />
      <Render when={!!showPagination}>
        <TablePagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          count={totalCount}
          options={options}
          fetchNextData={fetchNextData}
          fetchPrevData={fetchPrevData}
          originalData={data}
          shouldHideRange={shouldHideRange}
          currentRangeData={rangeData}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          frontendPagination={frontendPagination}
          alwaysShowPagination={alwaysShowPagination}
          className={paginationClassName}
        />
      </Render>
    </ReactTableStyle>
  );
};

Table.defaultProps = {
  loading: false,
  manual: false,
  pageSize: 5,
  showPagination: false,
  tableProps: {},
  paginationConfig: defaultPaginationConfig,
  renderBelowTable: () => null,
  data: [],
};

// Table.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.oneOfType([PropTypes.array]),
//   tableProps: PropTypes.object,
//   className: PropTypes.string,
//   loading: PropTypes.bool,
//   manual: PropTypes.bool,
//   pageSize: PropTypes.number,
//   pages: PropTypes.number,
//   fetchData: PropTypes.func,
//   showPagination: PropTypes.bool,
//   paginationConfig: PropTypes.shape({
//     rowsPerPage: PropTypes.stringOrNumber,
//     page: PropTypes.number,
//     options: PropTypes.arrayOf(PropTypes.number),
//     fetchNextData: PropTypes.func,
//     fetchPrevData: PropTypes.func,
//     totalCount: PropTypes.number,
//     shouldHideRange: PropTypes.bool,
//     onPageChange: PropTypes.func,
//     onRowsPerPageChange: PropTypes.func,
//   }),
// };

export default Table;
