import { TFunction } from 'react-i18next';
import { z as zod } from 'zod';

import { AddBankCustodianStatus } from 'types/Account';

const ADD_BANK_CHECK_STATUS_INTERVAL = 3;

const BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL = 15;

const AddBankAccountSchema = (t: TFunction) => {
  return zod
    .object({
      accountNumber: zod
        .string()
        .min(8, {
          message: t('validations:fieldCharLimitByMinMax', {
            field: 'Account number',
            min: 8,
            max: 30,
          }),
        })
        .max(30, {
          message: t('validations:fieldCharLimitByMinMax', {
            field: 'Account number',
            min: 8,
            max: 30,
          }),
        }),
      ifscCode: zod.string().length(11, {
        message: t('validations:fieldAbsoluteLength', {
          field: 'IFSC Code',
          length: 11,
        }),
      }),
      label: zod
        .string()
        .min(3, {
          message: t('validations:fieldCharLimitByMinMax', {
            field: 'Label',
            min: 3,
            max: 30,
          }),
        })
        .max(30, {
          message: t('validations:fieldCharLimitByMinMax', {
            field: 'Label',
            min: 3,
            max: 30,
          }),
        }),
    })
    .required();
};

const AddBankAccountFields = (t: TFunction) => AddBankAccountSchema(t).keyof();

const AddBankAccount = (t: TFunction) => {
  return AddBankAccountSchema(t);
};

type AddBankAccountFieldsType = zod.infer<ReturnType<typeof AddBankAccountFields>>;

type AddBankAccountData = zod.infer<ReturnType<typeof AddBankAccount>>;

type AddBankAccountSubmissibleData = AddBankAccountData;

type VerifyOtpUuid = string;

type RequestId = string;

type UserBankDetailId = number;

interface BankAccount {
  account_name: string;
  account_number: string;
  bank_name: string;
  created_at: string;
  custodian: string;
  custodian_status: AddBankCustodianStatus;
  customized_bank_name: string;
  ifsc_code: string;
  is_active: boolean;
  meta_data?: {
    message: string;
    name_at_bank: string;
  };
  message: string;
  name_at_bank: string;
  user_bank_detail_id: number;
}

interface GetBankList {
  bank_details: BankAccount[];
}

interface VerifyOtpParams {
  uuid: VerifyOtpUuid;
  emailCode: string;
  mfaCode?: string;
}

interface VerifyOtpResponse {
  request_id: RequestId;
}

interface SendOTPResponse {
  uuid: VerifyOtpUuid;
}

interface SendOTPError {
  code: string;
}

interface AddBankCheckStatusResponse {
  user_id: number;
  request_id: string;
  status: AddBankCustodianStatus;
  error_code: string;
  bank_name: string;
}

export {
  AddBankAccount,
  BANK_ACCOUNT_VERIFICATION_WAIT_UNTIL,
  ADD_BANK_CHECK_STATUS_INTERVAL,
};

export type {
  AddBankAccountData,
  AddBankAccountSubmissibleData,
  AddBankAccountFieldsType as AddBankAccountFields,
  VerifyOtpUuid,
  RequestId,
  BankAccount,
  GetBankList,
  VerifyOtpParams,
  VerifyOtpResponse,
  SendOTPResponse,
  SendOTPError,
  UserBankDetailId,
  AddBankCheckStatusResponse,
};
