import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

/**
 * @description If anything is required from active url query params this hook can be used.
 * As URLSearchParams instance is returned so query can be done for any keys.
 * @example
 * ```typescript
 * // For /abc?hello=world
 * const searchParams = useRouterSearchParams();
 * searchParams.get("hello") // Will return world
 * ```
 */
const useRouterSearchParams = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return searchParams;
};

export { useRouterSearchParams };
