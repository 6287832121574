import { ReactNode, PropsWithChildren, isValidElement } from 'react';

import { IS_INDIAN_EXCHANGE } from 'constants/constants';

interface RenderByExchangeProps {
  indian?: ReactNode;
  global?: ReactNode;
}

/**
 * Render Component only for Indian Exchange
 */
const RenderForIndianExchange = ({ children }: PropsWithChildren<any>) => {
  return IS_INDIAN_EXCHANGE ? children : null;
};

/**
 * Render Component only for Global Exchange
 */
const RenderForGlobalExchange = ({ children }: PropsWithChildren<any>) => {
  return !IS_INDIAN_EXCHANGE ? children : null;
};

/**
 * Render Component conditionally for Indian / Global
 */
const RenderByExchange = ({ indian, global }: RenderByExchangeProps) => {
  if (indian && isValidElement(indian) && IS_INDIAN_EXCHANGE) {
    return indian;
  }
  if (global && isValidElement(global) && !IS_INDIAN_EXCHANGE) {
    return global;
  }
  return null;
};

export { RenderForIndianExchange, RenderForGlobalExchange, RenderByExchange };
