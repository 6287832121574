/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import BuySellHoverButton from 'components/options_chain/buySellHoverButton';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { basketOrderOrdersSelector, userMarginModeSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';
import { Product } from 'types/IProducts';

import styles from './productSelectionScreen.module.scss';
import MobileQuantityInputBox from '../mobileQuantityInputBox/quantityInput';

interface Props {
  isOptions?: boolean | undefined;
  product: Product;
}

const BuySellButtonsAndInputRow = ({ isOptions, product }: Props) => {
  const { t } = useTranslation(['basketOrders']);

  const ordersInBasket = useAppSelector(basketOrderOrdersSelector);

  const [inputError, setInputError] = useState('');

  const productData = product?.product || product;

  const order = ordersInBasket[productData?.id];
  const marginMode = useAppSelector(userMarginModeSelector);

  const handleButtonClick = side => {
    if (!order?.size) {
      setInputError(t('basketOrders:enterContracts'));
      return;
    }
    // this event triggers on mobile app and browser .
    trackMixpanelEvent('Basket Order - Mobile Basket Orders - Buy/Sell Button Clicked', {
      underlying_coin: product?.underlying_asset?.symbol,
      side,
      margin_type: marginMode,
    });
  };

  return (
    (<div
      className={cn(styles.buySellBtnsInputRow, {
        [styles.isOptions]: isOptions,
        [styles.error]: inputError,
      })}
      id="basket-order-input-row"
      data-palette="BuySellButtonsAndInputRow">
      <div
        className={cn(styles.inputContainer, {
          [styles.error]: inputError,
        })}
      >
        <MobileQuantityInputBox
          product={productData}
          orderData={order}
          className="inputRow"
          inputError={inputError}
          setInputError={setInputError}
        />
        {inputError && <div className={styles.errorText} data-palette="BuySellButtonsAndInputRow">{inputError}</div>}
      </div>
      <BuySellHoverButton
        isTableHoverButton={false}
        product={productData}
        className={cn(styles.buySellBtnContainer, {
          [styles.error]: inputError,
        })}
        buyBtnClassName={styles.buyButton}
        sellBtnClassName={styles.sellButton}
        handleBtnClick={handleButtonClick}
        order={order}
      />
    </div>)
  );
};

export default BuySellButtonsAndInputRow;
