import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RenderByExchange } from 'components/render/RenderByExchangeType';
import ToggleThemeSwitch from 'components/toggleThemeSwitch';
import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import routes from 'constants/routes';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { CrossIcon, DeltaLogoMobile, DeltaLogoText } from 'styles/assets/icons';
import DeltaIndiaLogo from 'styles/assets/icons/DeltaIndiaLogo';
import LanguageMenu from 'UIKit/LanguageMenu';

import styles from './styles.module.scss';

interface AuthheaderProps {
  variant: 'signup' | 'login';
}

const AuthHeader = (props: AuthheaderProps) => {
  const { variant } = props;

  const { t } = useTranslation();

  const { link, text, testID, mixpanel } = (() => {
    const res = {
      link: '',
      text: '',
      testID: '',
      mixpanel: {
        mixpanelEvent: '',
        pageName: '',
      },
    };
    switch (variant) {
      case 'login':
        res.text = t('auth:signUp');
        res.link = routes.signup;
        res.testID = 'signupPageLinkOnHeader';
        res.mixpanel = {
          mixpanelEvent: 'Signup Page - Form Opened',
          pageName: 'Login Page',
        };
        break;
      case 'signup':
        res.text = t('auth:logIn');
        res.link = routes.login;
        res.testID = 'loginPageLinkOnHeader';
        res.mixpanel = {
          mixpanelEvent: 'Login - Header',
          pageName: 'Signup Page',
        };
        break;
      default:
        break;
    }
    return res;
  })();

  const trackMixpanel = () => {
    trackMixpanelEvent(mixpanel.mixpanelEvent, {
      pageName: mixpanel.pageName,
    });
  };
  return (
    (<div className={styles.authHeader} data-palette="AuthHeader">
      <Link
        to={routes.default}
        className={cn(styles.deltaLogoBox, {
          [styles.deltaLogoBox_IndianExchange]: IS_INDIAN_EXCHANGE,
        })}
      >
        <RenderByExchange
          global={
            <>
              <DeltaLogoMobile className={styles.deltaLogoIcon} />
              <DeltaLogoText className={styles.deltaLogoText} />
            </>
          }
          indian={<DeltaIndiaLogo className="headerLogoIndianExchange" />}
        />
      </Link>
      <div className={styles.actionsSection}>
        {text && (
          <Link
            className={styles.redirectButton}
            to={link}
            data-testid={testID}
            onClick={trackMixpanel}
          >
            {text}
          </Link>
        )}
        {!IS_INDIAN_EXCHANGE && <LanguageMenu />}
        <ToggleThemeSwitch className={styles.themeSwitchOverride} />
        <Link to={routes.default} className={styles.crossBox}>
          <CrossIcon className={styles.crossIcon} />
        </Link>
      </div>
    </div>)
  );
};

export default AuthHeader;
