/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import cx from 'classnames';

import {
  MenuIdentityVerificationIcon,
  PNLAnalyticsLight,
  PositionsIcon,
  SubAccountsIcon,
  PortfolioMarginIcon,
  BalancesIcon,
  MenuProfileIcon,
  ApiKeysIcon,
  SecurityIcon,
  DepositsIcon,
  PreferencesIcon,
  WithdrawalsIcon,
  LogsIcon,
  Bank,
} from 'styles/assets/icons';
import SignalTradingIcon from 'styles/assets/icons/SignalTradingIcon';

const components = {
  balance: BalancesIcon,
  profile: MenuProfileIcon,
  identityVerification: MenuIdentityVerificationIcon,
  manageapikeys: ApiKeysIcon,
  deposit: DepositsIcon,
  security: SecurityIcon,
  withdrawal: WithdrawalsIcon,
  preferences: PreferencesIcon,
  bots: null,
  transaction_logs: LogsIcon,
  account_analytics: PNLAnalyticsLight,
  positions: PositionsIcon,
  subaccounts: SubAccountsIcon,
  portfolioMargin: PortfolioMarginIcon,
  bank_details: Bank,
  tradingBot: SignalTradingIcon,
};

export const SideNavIcons = props => {
  const NewComponent = components[props.id];
  if (NewComponent) {
    return (
      <NewComponent
        id={props.id}
        width="18"
        height="18"
        className={cx(props.className, {
          positions: props.id.includes('positions'),
        })}
        {...props}
      />
    );
  }
  return null;
};
