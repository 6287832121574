import { useEffect, useRef, SetStateAction, Dispatch } from 'react';

interface Props {
  children: JSX.Element;
  disappearingTime: number;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const AutoDisapperingComponent = ({
  children,
  disappearingTime,
  show,
  setShow,
}: Props): JSX.Element => {
  const timeOutRef = useRef(null);

  useEffect(() => {
    timeOutRef.current = setTimeout(() => {
      setShow(false);
    }, disappearingTime);

    return () => {
      clearTimeout(timeOutRef.current);
    };
  }, [show]);

  return show ? children : null;
};

export default AutoDisapperingComponent;
