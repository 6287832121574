import { createSelector } from 'reselect';

import {
  isUserIndianSelector,
  isUserKycDoneSelector,
  userSelector,
  userProfileSelector,
} from './otherSelectors';

const selectUserReferralCode = createSelector([userSelector], user => user.referral_code);

const selectIsUserSubAccount = createSelector([userSelector], user =>
  Boolean(user.is_sub_account)
);

const selectIsVerifiedIndianUser = createSelector(
  [isUserIndianSelector, isUserKycDoneSelector],
  (isIndian, isKycDone) => isIndian && isKycDone
);

const selectUserAccountName = createSelector([userSelector], user => user.account_name);

const selectMfaCheckForSubAndMainAccount = createSelector([userSelector], user => {
  if (user.is_sub_account) {
    if (user.main_account?.email === user.email) {
      return user.main_account?.isMfaEnabled;
    }
  }
  return user.isMfaEnabled;
});

/**
 * @description This will give the referral code used during signup process of the user.
 * It can be some custom referral code or the default referral code.
 */
const selectSignupReferralCode = createSelector([userProfileSelector], profile => {
  return profile?.signup_referral_code;
});

const selectTrackierMobileId = createSelector([userSelector], user => {
  return user.trackierMobileId;
});

export {
  selectUserReferralCode,
  selectIsUserSubAccount,
  selectIsVerifiedIndianUser,
  selectUserAccountName,
  selectMfaCheckForSubAndMainAccount,
  selectSignupReferralCode,
  selectTrackierMobileId,
};
