/* eslint-disable react/prop-types */
import React, { memo } from 'react';

// import { FaChevronLeft } from 'components/icons';
import { noop } from 'helpers';
import { CrossIconSymbol, AngleLeft } from 'styles/assets/icons';

import styles from './index.module.scss';

const MobileMenuBackButton = ({
  onBackButtonClick,
  text,
  onRightButtonClick,
  rightIcon,
  showRightIcon,
  showCloseIcon,
  onCrossIconClick,
}) => {
  return (
    (<div
      className={styles['back-button-header']}
      data-palette="MobileMenuBackButton">
      <button
        data-testid="menu-back-button"
        type="button"
        className={`${styles['back-button']} ${styles.ripple}`}
        onClick={onBackButtonClick}
      >
        <AngleLeft className={styles.icon} />
      </button>
      <div className={styles['header-text']}>{text}</div>
      {showRightIcon && (
        <button
          type="button"
          data-testid="menu-right-button"
          className={styles['right-button']}
          onClick={onRightButtonClick}
          data-palette="MobileMenuBackButton">
          {rightIcon}
        </button>
      )}
      {showCloseIcon && (
        <span
          type="button"
          tabIndex="0"
          role="button"
          onKeyDown={noop}
          data-testid="menu-cross-icon"
          className={styles[`cross-icon`]}
          onClick={() => onCrossIconClick()}
          data-palette="MobileMenuBackButton">
          <CrossIconSymbol />
        </span>
      )}
    </div>)
  );
};

export default memo(MobileMenuBackButton);
