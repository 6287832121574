/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable test-selectors/input */
/* eslint-disable test-selectors/onChange */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
    cursor: pointer;
  }
  label {
    position: relative;
    color: ${props => props.theme.primaryText};

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 22px;
    padding-top: 0px;
  }
  label::before,
  label::after {
    position: absolute;
    content: '';
    /*Needed for the line-height to take effect*/
    display: inline-block;
  }
  /*Outer box of the fake checkbox*/
  label::before {
    width: 18px;
    height: 18px;
    background-color: ${props => props.theme.secondaryBackground};
    border-radius: 3px;
    border: solid 0.8px ${props => props.theme.secondaryText};
    left: 0px;
    top: 5px;
  }
  /*Checkmark of the fake checkbox*/
  label::after {
    width: 10px;
    height: 5px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    color: var(--primaryCtaText);
    transform: rotate(-45deg);
    left: 4px;
    top: 10px;
  }
  /*Hide the checkmark by default*/
  input[type='checkbox'] + label::after {
    content: none;
  }
  /*Unhide on the checked state*/
  input[type='checkbox']:checked + label::after {
    content: '';
  }
  input[type='checkbox']:checked + label:before {
    background-color: ${props => props.theme.primaryTheme};
    border-radius: 3px;
    border: 1px solid ${props => props.theme.primaryTheme};
  }
  /* bottomTText css */
  p {
    padding-top: 14px;
    height: 18px;
    opacity: 0.6;
    font-size: 12.1px;
    color: ${props => props.theme.primaryText};
    line-height: 1.5;
  }
`;

/**
 * @deprecated: use MuiCheckBox instead
 */
const Checkbox = ({
  onChange,
  id,
  name,
  checked,
  labelClassName,
  className,
  disabled = false,
  'data-testid': tid,
  inputClassName = null,
}) => (
  <CheckboxWrapper className={cx({ [className]: className })}>
    <input
      type="checkbox"
      checked={checked}
      name={name}
      id={id}
      onChange={onChange}
      disabled={disabled}
      className={cx({ [inputClassName]: inputClassName })}
    />
    <label
      id={id}
      htmlFor={id}
      data-testid={tid}
      className={cx({
        [labelClassName]: labelClassName,
        'not-allowed': disabled,
      })}
    />
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;
