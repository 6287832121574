/* eslint-disable no-nested-ternary */
import { GREEK_PRECISION } from 'constants/constants';
import { isNan, numberPrettified } from 'helpers';
import { getGreekValue } from 'helpers/greeksCalculatorUSDT';
import { gammaPriceSelectorById } from 'selectors';
import { type OpenPosition } from 'types/IOpenPosition';

import useOpenPositionHelpers from '../useOpenPositionHelpers';

function usePositionsGammaSummation(positions: OpenPosition[]) {
  const { getNotionalValue } = useOpenPositionHelpers();

  const getCombinedGammaValues = positions?.reduce((result, position) => {
    const isLong = typeof position?.size !== 'undefined' && position?.size > 0;

    const { value: notionalSize } = getNotionalValue({
      entry_price: position.entry_price,
      size: position.size,
      product: position.product,
    });

    const price = gammaPriceSelectorById(String(position?.product_id));
    const gammaContractValue = isNan(price) ? 0.0 : Number(price);
    const thetaInUSDT = getGreekValue({
      greekContractValue: gammaContractValue,
      notional: Number(notionalSize),
      longOrShort: isLong ? 'long' : 'short',
    });

    // eslint-disable-next-line no-param-reassign
    result += Number(thetaInUSDT || 0);

    return result;
  }, 0);

  return isNan(getCombinedGammaValues)
    ? '-'
    : Number(getCombinedGammaValues) === 0
    ? '0.00'
    : numberPrettified(
        getCombinedGammaValues,
        GREEK_PRECISION.GAMMA,
        GREEK_PRECISION.GAMMA
      );
}

export default usePositionsGammaSummation;
