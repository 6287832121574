/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import { StylesProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import cn from 'classnames';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router';

import {
  changeBasketOrderContractType,
  changeBasketOrdersScreen,
  changeBasketUnderlyingAsset,
  toggleBasketOrdersView,
} from 'actions/basketOrders';
import { isAuthenticated } from 'actions/user';
import { BASKET_ORDERS_SCREEN_VIEW } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { noop } from 'helpers';
import {
  basketActiveContractTypeSelector,
  basketActiveUnderlyingAssetSelector,
  basketOrderCurrentScreenSelector,
  basketOrderOrdersSelector,
  enabledPortfolioAssetsSelector,
  OptionChainsProductList,
  productsSelector,
  selectedProductSelector,
  tradePreferencesSelector,
  userSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { CrossIcon, AngleLeft } from 'styles/assets/icons';
import { OrdersObject } from 'types/basketOrders';
import { Box } from 'UIKit';

import BasketOrderScreen from '../BasketOrderScreen';
import MobileAssetDropdown from './mobileAssetDropdown';
import styles from './mobileBasketOrders.module.scss';
import MobileBasketOrdersOrderPopup from './mobileProductSelectionScreen/ordersPopup';

const contractTypeTabs = [
  {
    id: 'options',
    label: 'Options',
  },
  {
    id: 'futures',
    label: 'Futures',
  },
];

const MobileBasketOrder = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const activeContractType = useAppSelector(basketActiveContractTypeSelector);
  const currentScreenView = useAppSelector(basketOrderCurrentScreenSelector);
  const selectedProduct = useAppSelector(selectedProductSelector);
  const orders = useAppSelector(basketOrderOrdersSelector);
  const activeUnderlyingAsset = useAppSelector(basketActiveUnderlyingAssetSelector);
  const productList = useAppSelector(OptionChainsProductList);
  const user = useAppSelector(userSelector);
  const tradePreferences = useAppSelector(state => tradePreferencesSelector(state));
  const products = useAppSelector(state => productsSelector(state));
  const enabledPortfolios = useAppSelector(state => enabledPortfolioAssetsSelector(state));
  const isLoggedIn = isAuthenticated(user);

  const { underlyingAssetSymbolList } = productList;
  const { margin_mode: marginMode } = user;

  const isProductsViewActive =
    currentScreenView === BASKET_ORDERS_SCREEN_VIEW.PRODUCTS_VIEW;
  const filteredOrders = Object.values(orders).filter(
    order => order?.asset === activeUnderlyingAsset && order.side
  );

  const handleClose = () => {
    dispatch(toggleBasketOrdersView(false));
    history.goBack();
  };

  const handleBackButtonClick = () => {
    if (isEmpty(filteredOrders)) {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.DEFAULT));
    } else {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
    }
  };

  const handleContractTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    contractType: string
  ) => {
    event.stopPropagation();
    dispatch(changeBasketOrderContractType(contractType));
  };

  useEffect(() => {
    const selectedAsset = selectedProduct?.underlying_asset?.symbol;
    const selectedIndex = selectedProduct?.spot_index?.symbol;
    let activeUnderlying;

    if (marginMode === MARGIN_MODE.PORTFOLIO) {
      activeUnderlying = (selectedIndex && enabledPortfolios[selectedIndex])
        ? selectedAsset
        : 'BTC';
    }
    if (marginMode === MARGIN_MODE.ISOLATED || marginMode === MARGIN_MODE.CROSS) {
      activeUnderlying = underlyingAssetSymbolList.includes(selectedAsset)
        ? selectedAsset
        : 'BTC';
    }
    dispatch(changeBasketUnderlyingAsset(activeUnderlying));

  }, [tradePreferences]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(toggleBasketOrdersView(false));
    } else {
      dispatch(toggleBasketOrdersView(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const ordersInBasket: OrdersObject[] = Object.values(orders);
    const ordersList = ordersInBasket.filter(
      order => products[order.product_id] && order.asset === activeUnderlyingAsset
    );

    if (ordersList.length === 0) {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.DEFAULT));
    } else if (currentScreenView === BASKET_ORDERS_SCREEN_VIEW.DEFAULT) {
      dispatch(changeBasketOrdersScreen(BASKET_ORDERS_SCREEN_VIEW.ORDERS_VIEW));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marginMode, products]);

  return (
    (<Box className={styles.mobileBasketOrderContainer}>
      <Box
        renderWhen={isProductsViewActive}
        data-testid="mobile-basket-orders-back-button"
        className={cn(styles.basketHeader, styles.productsHeader)}
        onClick={handleBackButtonClick}
        onKeyPress={noop}
        role="button"
        tabIndex={0}
      >
        <div>
          <AngleLeft className={styles.icon} />
        </div>
        <StylesProvider injectFirst>
          <AppBar
            position="static"
            classes={{
              root: styles.container,
            }}
          >
            <Tabs
              TabIndicatorProps={{
                // className: styles.tabIndicator,
                children: <span data-palette="MobileBasketOrder" />,
              }}
              classes={{
                root: styles.basketOrderTabs,
                indicator: styles.tabIndicator,
              }}
              value={activeContractType}
              onChange={handleContractTypeChange}
              aria-label="mobileBasketOrders tabs"
              variant="scrollable"
              scrollButtons="off"
              data-testid="mobile-basket-order-tabs"
            >
              {contractTypeTabs.map(type => (
                <Tab
                  classes={{
                    root: styles.basketOrderTab,
                    selected: styles.selected,
                    wrapper: cn(styles.tabLabel, {
                      [styles.activeTab]:
                        type.id.toLowerCase() === activeContractType.toLowerCase(),
                    }),
                  }}
                  value={type.label}
                  key={type.id}
                  label={`${activeUnderlyingAsset} ${type.label}`}
                  id={`mobile-basket-order-tab-${type.id}`}
                  aria-controls={`mobile-basket-order-tabPanel-${type.id}`}
                />
              ))}
            </Tabs>
          </AppBar>
        </StylesProvider>
      </Box>
      <Box renderWhen={!isProductsViewActive} className={styles.basketHeader}>
        <MobileAssetDropdown />
        <CrossIcon
          className={styles.crossIcon}
          data-testid="mobile-basket-order-cross-icon"
          onClick={() => handleClose()}
        />
      </Box>
      <Box
        className={cn(styles.basketContent, {
          [styles.fullScreen]: isEmpty(filteredOrders),
        })}
      >
        <BasketOrderScreen product={selectedProduct} />
        {!isEmpty(filteredOrders) && isProductsViewActive && (
          <MobileBasketOrdersOrderPopup />
        )}
      </Box>
    </Box>)
  );
};

export default MobileBasketOrder;
