import React, { InputHTMLAttributes, ReactNode } from 'react';

import cx from 'classnames';

import Render from 'components/render';
import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import Box from 'UIKit/Box';
import DeltaHelperText from 'UIKit/DeltaHelperText';
import InputBase from 'UIKit/InputBase';
import type { InputBaseProps } from 'UIKit/InputBase';

import classes from './deltaInput.module.scss';

interface InputProps extends Omit<InputBaseProps, 'error'> {
  id: string;
  label?: ReactNode;
  headerAdornment?: ReactNode;
  startAdornment?: ReactNode;
  control?: ReactNode;
  endAdornment?: ReactNode;
  error?: ReactNode;
  recommendation?: ReactNode;
  value?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  controlRootClassName?: string;
  controlClassName?: string;
  footerClassName?: string;
  showHelperTextIcon?: boolean;
  footerStartAdornment?: ReactNode;
  gap?: 4 | 8;
  showFooterSection?: boolean;
}

const DeltaInputWrapper = ({
  id,
  label,
  headerAdornment,
  startAdornment,
  control,
  controlRootClassName = '',
  controlClassName = '',
  footerClassName = '',
  endAdornment,
  error,
  recommendation,
  value,
  inputProps = {},
  showHelperTextIcon = true,
  footerStartAdornment = null,
  gap = 4,
  showFooterSection = true,
  inputRef,
  ...props
}: InputProps) => {
  const hasError = Boolean(error);
  const showFooter =
    showFooterSection && Boolean(error || recommendation || footerStartAdornment);

  return (
    <Box
      data-testid={`${id}-input-root`}
      className={cx(classes.inputRoot, { [classes[`gap-${gap}`]]: gap })}
    >
      <Box
        renderWhen={Boolean(label || headerAdornment)}
        data-testid={`${id}-header-root`}
        className={classes.headerRoot}
      >
        <Render when={typeof label === 'string'} else={label}>
          <Box
            renderWhen={Boolean(label)}
            as="label"
            htmlFor={id}
            data-testid={`${id}-input-label`}
            className={classes.inputLabel}
          >
            {label}
          </Box>
        </Render>

        <Box
          renderWhen={Boolean(headerAdornment)}
          data-testid={`${id}-header-adornment`}
          className={classes.headerAdornment}
        >
          {headerAdornment}
        </Box>
      </Box>

      <Box data-testid={`${id}-content-root`} className={classes.contentRoot}>
        <Box
          data-testid={`${id}-control-root`}
          className={cx(classes.controlRoot, {
            [classes.hasError]: hasError,
            [classes.isDefaultControlLayout]: Boolean(control),
            [classes.inputControlIndianExchange]: IS_INDIAN_EXCHANGE,
            [controlRootClassName]: Boolean(controlRootClassName),
          })}
        >
          <Render
            when={Boolean(control)}
            else={
              <InputBase
                id={id}
                value={value}
                type="text"
                autoComplete="off"
                inputRef={inputRef}
                className={classes.input}
                error={hasError}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                inputProps={{
                  'data-testid': `${id}-input`,
                  ...inputProps,
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            }
          >
            <Box
              renderWhen={Boolean(startAdornment)}
              data-testid={`${id}-start-adornment`}
              className={classes.startAdornment}
            >
              {startAdornment}
            </Box>

            <Box
              data-testid={`${id}-control`}
              className={cx(classes.control, {
                [controlClassName]: Boolean(controlClassName),
              })}
            >
              {control}
            </Box>

            <Box
              renderWhen={Boolean(endAdornment)}
              data-testid={`${id}-end-adornment`}
              className={classes.endAdornment}
            >
              {endAdornment}
            </Box>
          </Render>
        </Box>
      </Box>

      <Box
        renderWhen={showFooter}
        data-testid={`${id}-footer-root`}
        className={cx(classes.footerRoot, { footerClassName })}
      >
        <Render when={Boolean(footerStartAdornment)}>{footerStartAdornment}</Render>

        <DeltaHelperText
          id={id}
          showIcon={showHelperTextIcon}
          isError={hasError}
          content={error || recommendation}
        />
      </Box>
    </Box>
  );
};

export type { InputProps };

export default DeltaInputWrapper;
