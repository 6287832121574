const DeltaIndiaLogo = ({ className }: { className?: string }) => (
  <svg
    height="36"
    viewBox="0 0 138 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="var(--deltaLogoText)"
    className={className}
    data-palette="DeltaIndiaLogo">
    <path d="m9.76 10 9.757 5 9.759-5L9.759 0z" fill="#FD7D02" />
    <path d="M9.76 20v10l19.516-10-9.759-5z" fill="url(#DeltaIndiaLogoA)" />
    <path d="M29.276 20V10l-9.758 5z" fill="#2CB72C" />
    <path d="M9.758 10v10L0 15z" fill="#FF9300" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.732 4.663h-3.728v8.764h3.728c.904 0 1.7-.168 2.412-.534.712-.365 1.26-.87 1.645-1.545.383-.674.575-1.432.575-2.303 0-.87-.192-1.63-.575-2.303-.384-.675-.933-1.18-1.645-1.545-.685-.365-1.508-.534-2.412-.534m1.59 6.995c-.466.253-1.015.365-1.645.365h-2.083V6.068h2.083c.63 0 1.179.113 1.645.365.466.225.85.59 1.096 1.04s.384.983.384 1.573-.137 1.123-.384 1.573-.63.786-1.096 1.04m5.34-1.097h5.098q.017-.13.025-.24a2 2 0 0 1 .03-.237c0-.675-.137-1.264-.411-1.798-.302-.506-.685-.927-1.179-1.208-.493-.281-1.069-.421-1.7-.421a3.6 3.6 0 0 0-1.726.449c-.52.28-.905.702-1.206 1.208-.302.506-.439 1.095-.439 1.742 0 .674.137 1.236.439 1.77.301.505.712.926 1.26 1.207.549.309 1.18.45 1.892.45.548 0 1.07-.085 1.508-.281.438-.197.795-.45 1.096-.815l-.822-.955c-.439.477-1.042.73-1.755.73q-.822 0-1.397-.421a1.9 1.9 0 0 1-.713-1.18m.001-1.04c.055-.477.274-.87.603-1.179.357-.28.768-.45 1.234-.45.493 0 .904.141 1.233.45s.548.702.603 1.18z"
      fill="currentColor"
    />
    <path
      d="M56.023 4.129h1.535v9.27h-1.535zm7.487 8.905c-.192.14-.384.252-.658.337a2.5 2.5 0 0 1-.822.112c-.713 0-1.26-.197-1.645-.562-.383-.393-.575-.927-.575-1.685V8.033h-1.07V6.798h1.07V5.28h1.535v1.517h1.754v1.235h-1.754v3.175c0 .337.082.562.219.73q.246.252.658.253c.329 0 .63-.085.85-.281z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.975 9.495c0-.955-.246-1.658-.767-2.135-.493-.478-1.234-.702-2.193-.702-.548 0-1.069.084-1.535.224-.493.14-.904.365-1.233.618l.603 1.124c.246-.197.548-.365.904-.478a3.5 3.5 0 0 1 1.07-.168c.52 0 .931.112 1.205.365s.411.59.411 1.067v.113h-1.672c-.932 0-1.617.168-2.055.534q-.658.547-.658 1.432c0 .393.11.73.301 1.04.192.28.494.533.85.702a3.1 3.1 0 0 0 1.288.252c.494 0 .905-.084 1.261-.224a2.1 2.1 0 0 0 .795-.674v.814h1.425zm-1.533 1.826c-.137.337-.33.59-.63.758-.275.168-.631.28-1.015.28q-.576 0-.905-.252a.81.81 0 0 1-.328-.674c0-.59.438-.899 1.315-.899h1.563z"
      fill="currentColor"
    />
    <path
      d="M71.462 13.174a1.03 1.03 0 0 1-.301-.73c0-.31.082-.562.274-.759a.96.96 0 0 1 .712-.28.96.96 0 0 1 .713.28c.192.197.274.45.274.759s-.11.533-.301.73a.96.96 0 0 1-.713.281c-.22.028-.466-.084-.658-.28m-26.565 11.6v.562h-5.893v-8.736h5.701v.562h-5.07v3.455h4.55v.562h-4.55v3.595zm6 .59-2.193-2.921-2.193 2.921h-.685l2.522-3.37-2.385-3.147h.685l2.056 2.697 2.056-2.697h.685l-2.384 3.146 2.549 3.371zm2.665-.393a2.94 2.94 0 0 1-1.151-1.18c-.274-.505-.412-1.067-.412-1.713s.138-1.208.412-1.714a2.94 2.94 0 0 1 1.15-1.18 3.27 3.27 0 0 1 1.645-.42c.521 0 .987.111 1.398.308a2.44 2.44 0 0 1 1.015.899l-.439.337c-.22-.337-.52-.562-.85-.73a2.46 2.46 0 0 0-1.123-.253 2.73 2.73 0 0 0-1.344.337 2.57 2.57 0 0 0-.931.955q-.33.633-.33 1.433t.33 1.432c.219.422.52.73.931.955.384.225.85.337 1.343.337.412 0 .768-.084 1.124-.252.33-.169.63-.394.85-.73l.439.336a2.44 2.44 0 0 1-1.014.9 3.2 3.2 0 0 1-1.398.308 2.75 2.75 0 0 1-1.645-.365m10.273-5.477c.466.477.685 1.151.685 2.05v3.792h-.603v-3.764c0-.73-.192-1.292-.548-1.685s-.877-.562-1.508-.562c-.74 0-1.343.225-1.754.674q-.657.675-.658 1.854v3.483h-.603v-9.27h.603v4.102c.22-.45.548-.787.987-1.04.439-.252.932-.365 1.535-.365.768.028 1.398.253 1.864.73"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.127 21.236c0-.814-.219-1.404-.63-1.826-.439-.421-1.042-.618-1.754-.618a4 4 0 0 0-1.398.253 3.6 3.6 0 0 0-1.152.674l.302.45a2.9 2.9 0 0 1 .987-.59c.383-.14.794-.225 1.206-.225.603 0 1.069.169 1.398.478s.493.758.493 1.376v.534h-2.193q-1.233 0-1.809.505c-.384.337-.576.787-.576 1.32 0 .562.192 1.012.603 1.349.412.337.96.506 1.672.506.549 0 1.015-.085 1.398-.31.384-.224.686-.505.878-.898v1.151h.575zm-.627 2.19a2.22 2.22 0 0 1-.822 1.096c-.356.253-.822.365-1.343.337-.576 0-1.014-.112-1.316-.365a1.26 1.26 0 0 1-.466-1.011c0-.394.137-.73.411-.955.302-.225.74-.337 1.37-.337H70.5z"
      fill="currentColor"
    />
    <path
      d="M77.594 19.495c.466.477.685 1.151.685 2.05v3.792h-.603v-3.764c0-.73-.192-1.292-.548-1.685-.357-.394-.877-.562-1.508-.562-.74 0-1.343.225-1.754.674q-.658.674-.658 1.854v3.483h-.603V18.82h.576v1.405c.219-.45.548-.815.986-1.068a3.05 3.05 0 0 1 1.535-.393c.795.028 1.426.253 1.892.73"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.763 24.663v-5.759h-.575v1.461c-.275-.477-.63-.87-1.097-1.123a3.34 3.34 0 0 0-1.59-.394 3.45 3.45 0 0 0-1.617.393 3 3 0 0 0-1.151 1.124c-.274.478-.411 1.011-.411 1.63 0 .59.137 1.151.41 1.629.302.477.659.842 1.152 1.123a3.45 3.45 0 0 0 1.617.393 3.2 3.2 0 0 0 1.563-.393 2.6 2.6 0 0 0 1.096-1.095v1.095c0 .871-.192 1.489-.603 1.91-.384.422-1.014.618-1.864.618a4.1 4.1 0 0 1-1.425-.252 3.2 3.2 0 0 1-1.151-.703l-.33.478c.33.309.74.562 1.262.758.52.197 1.069.281 1.644.281 1.014 0 1.782-.28 2.303-.786s.767-1.32.767-2.388m-.93-1.376c-.219.393-.52.702-.931.927a2.73 2.73 0 0 1-1.343.337c-.494 0-.96-.112-1.343-.337a2.3 2.3 0 0 1-.932-.927 2.7 2.7 0 0 1-.33-1.349q0-.758.33-1.348a2.5 2.5 0 0 1 .931-.927 2.73 2.73 0 0 1 1.344-.337c.493 0 .931.112 1.343.337.41.225.712.534.932.927q.329.59.329 1.348 0 .76-.33 1.349m2.657-1.04h5.455l.055-.14c0-.618-.11-1.18-.384-1.686-.274-.505-.63-.898-1.096-1.18a2.97 2.97 0 0 0-1.562-.42q-.864 0-1.563.42c-.466.282-.822.675-1.096 1.18a3.8 3.8 0 0 0-.384 1.714c0 .646.137 1.208.411 1.713q.411.76 1.151 1.18c.494.281 1.07.422 1.7.422.493 0 .96-.085 1.37-.281.412-.197.768-.45 1.042-.787l-.356-.421a2.14 2.14 0 0 1-.877.674 3.1 3.1 0 0 1-1.152.225c-.52 0-.96-.113-1.37-.337a2.56 2.56 0 0 1-.96-.927c-.246-.394-.356-.843-.383-1.349m.385-1.741c.219-.394.493-.675.85-.871a2.14 2.14 0 0 1 1.205-.281c.439 0 .85.112 1.206.309.357.225.658.506.877.87.22.366.33.76.357 1.237h-4.88c.056-.478.165-.9.385-1.264"
      fill="currentColor"
    />
    <path
      d="M102.996 25.56V17.1h1.248v8.46zm3.714 0V17.1h.96l5.196 6.816h-.3V17.1h1.164v8.46h-.948l-5.184-6.816h.276v6.816zm9.48 0V17.1h2.928q1.428 0 2.412.492a3.3 3.3 0 0 1 1.5 1.44q.516.936.516 2.292 0 1.344-.516 2.292a3.38 3.38 0 0 1-1.5 1.452q-.984.492-2.412.492zm1.248-1.044h1.608q1.632 0 2.424-.792.804-.792.804-2.4t-.804-2.388q-.792-.792-2.424-.792h-1.608zm8.279 1.044V17.1h1.248v8.46zm2.839 0 3.768-8.46h1.068l3.828 8.46h-1.284l-1.044-2.4.528.336h-5.124l.564-.336-1.044 2.4zm4.284-7.092-1.824 4.308-.288-.276h4.26l-.24.276-1.86-4.308z"
      fill="currentColor"
    />
    <path stroke="currentColor" strokeWidth=".3" d="M98.15 16.06v10" />
    <defs>
      <linearGradient
        id="DeltaIndiaLogoA"
        x1="22.517"
        y1="10.787"
        x2="11.567"
        y2="20.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#168016" />
        <stop offset="1" stopColor="#2CB72C" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeltaIndiaLogo;
