/**
 * *Convert to .tsx from here "https://react-svgr.com/playground/?typescript=true"
 */

/**
 * * Before adding a new icon, visit "https://devnet.delta.exchange/app/icons" to see if it already exists
 */

import loadable from '@loadable/component';

const GoogleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GoogleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GoogleIcon'
    )
);
const GoogleVerifyDarkIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GoogleVerifyDarkIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GoogleVerifyDarkIcon'
    )
);
const GoogleVerifyLightIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GoogleVerifyLightIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GoogleVerifyLightIcon'
    )
);
const LeftArrowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LeftArrowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LeftArrowIcon'
    )
);

const JewelIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "JewelIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './JewelIcon'
    )
);

const MainAccount = loadable(
  () =>
    import(
      /* webpackChunkName: "MainAccount" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MainAccount'
    )
);

const SubAccounts = loadable(
  () =>
    import(
      /* webpackChunkName: "SubAccounts" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SubAccounts'
    )
);

const SubAccountPlusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SubAccountPlusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SubAccountPlusIcon'
    )
);

const AddUserIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AddUserIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AddUserIcon'
    )
);

const LogoutIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LogoutIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LogoutIcon'
    )
);

const SettingIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SettingIcon'
    )
);

const TransferButtonIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TransferButtonIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TransferButtonIcon'
    )
);

const TransferIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TransferIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TransferIcon'
    )
);

const PencilIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PencilIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PencilIcon'
    )
);

const LimitUserIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LimitUserIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LimitUserIcon'
    )
);

const SwitchAccount = loadable(
  () =>
    import(
      /* webpackChunkName: "SwitchAccount" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SwitchAccount'
    )
);

const SubAccount = loadable(
  () =>
    import(
      /* webpackChunkName: "SubAccount" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SubAccount'
    )
);

const GroupUsers = loadable(
  () =>
    import(
      /* webpackChunkName: "GroupUsers" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GroupUsers'
    )
);

const PortfolioMarginIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PortfolioMarginIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PortfolioMarginIcon'
    )
);

const ShareIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ShareIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ShareIcon'
    )
);

const ChevronCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronCircle'
    )
);

const HistoryIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HistoryIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HistoryIcon'
    )
);

const PlusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PlusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PlusIcon'
    )
);

const EditIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EditIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditIcon'
    )
);

const SortIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SortIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SortIcon'
    )
);

const SearchIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SearchIcon'
    )
);

const SearchIconSmall = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchIconSmall" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SearchIconSmall'
    )
);

const ClearInputTextIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ClearInputTextIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ClearInputTextIcon'
    )
);

const ArrowBackIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowBackIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowBackIcon'
    )
);

const ChartsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChartsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChartsIcon'
    )
);

const ActiveArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "ActiveArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ActiveArrow'
    )
);

const CrossIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CrossIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrossIcon'
    )
);

const ChevronDown = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronDown'
    )
);

const ChevronUp = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronUp'
    )
);

const WarningRedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningRedIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningRedIcon'
    )
);

const WarningYellowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningYellowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningYellowIcon'
    )
);

const GreyWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyWarning'
    )
);

const ComingSoonIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ComingSoonIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ComingSoonIcon'
    )
);

const PositionsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PositionsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PositionsIcon'
    )
);

const RedXIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedXIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedXIcon'
    )
);

const GreenTickIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GreenTickIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreenTickIcon'
    )
);

const CaretUp = loadable(
  () =>
    import(
      /* webpackChunkName: "CaretUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CaretUp'
    )
);

const CaretDown = loadable(
  () =>
    import(
      /* webpackChunkName: "CaretDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CaretDown'
    )
);

const ClearFilterIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ClearFilterIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ClearFilterIcon'
    )
);

const ArrowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowIcon'
    )
);

const NoDataIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NoDataIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoDataIcon'
    )
);

const BlueBadgeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueBadgeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueBadgeIcon'
    )
);

const BlueDownloadIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueDownloadIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueDownloadIcon'
    )
);

const BlueRadioButton = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueRadioButton" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueRadioButton'
    )
);

const GreyBadgeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyBadgeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyBadgeIcon'
    )
);

const GreyArrowBox = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyArrowBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyArrowBox'
    )
);

const GreyCalendar = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyCalendar" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyCalendar'
    )
);

const GreyCircleTick = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyCircleTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyCircleTick'
    )
);

const GreyClock = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyClock" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyClock'
    )
);

const GreenTick = loadable(
  () =>
    import(
      /* webpackChunkName: "GreenTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreenTick'
    )
);

const InProgressYellowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InProgressYellowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InProgressYellowIcon'
    )
);

const VolumeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "VolumeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './VolumeIcon'
    )
);

const ArrowDown = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowDown'
    )
);

const ConverterIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ConverterIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ConverterIcon'
    )
);

const YellowWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowWarningIcon'
    )
);

const RightGreyArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "RightGreyArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyRightArrow'
    )
);

const BlueTriangularTick = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueTriangularTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueTriangularTick'
    )
);

const TransakLogo = loadable(
  () =>
    import(
      /* webpackChunkName: "TransakLogo" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TransakLogo'
    )
);

const InfoCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InfoCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InfoCircleIcon'
    )
);

const FireIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FireIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FireIcon'
    )
);

const BlueRightArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueRightArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueRightArrow'
    )
);

const BracketIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BracketIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BracketIcon'
    )
);

const SpotIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SpotIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SpotIcon'
    )
);

const MenuIdentityVerificationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MenuIdentityVerificationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MenuIdentityVerificationIcon'
    )
);

const LightThemeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LightThemeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LightThemeIcon'
    )
);

const DarkThemeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DarkThemeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DarkThemeIcon'
    )
);

const SendIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SendIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SendIcon'
    )
);

const Smiley = loadable(
  () =>
    import(
      /* webpackChunkName: "Smiley" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Smiley'
    )
);

const DownArrowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DownArrowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DownArrowIcon'
    )
);

const RaiseSupportTicket = loadable(
  () =>
    import(
      /* webpackChunkName: "RaiseSupportTicket" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RaiseSupportTicket'
    )
);

const GreenArrowUp = loadable(
  () =>
    import(
      /* webpackChunkName: "GreenArrowUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreenArrowUp'
    )
);

const RedArrowDown = loadable(
  () =>
    import(
      /* webpackChunkName: "RedArrowDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedArrowDown'
    )
);

const SettingsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SettingsIcon'
    )
);

const NavigateIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NavigateIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NavigateIcon'
    )
);

const TotalCreditsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TotalCreditsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TotalCreditsIcon'
    )
);

const TotalDeltaCashIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TotalDeltaCashIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TotalDeltaCashIcon'
    )
);

const TotalAccountEquityIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TotalAccountEquityIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TotalAccountEquityIcon'
    )
);

const TradingBalanceTitleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TradingBalanceTitleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TradingBalanceTitleIcon'
    )
);

const InvestBalanceTitleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InvestBalanceTitleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InvestBalanceTitleIcon'
    )
);

const DepositIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DepositIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DepositIcon'
    )
);

const WithDrawIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WithDrawIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WithDrawIcon'
    )
);

const TradeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TradeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TradeIcon'
    )
);

const ConvertIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ConvertIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ConvertIcon'
    )
);

const NineSquareIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NineSquareIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NineSquareIcon'
    )
);

const LeanNineSquareIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LeanNineSquareIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LeanNineSquareIcon'
    )
);

const AnalyticsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AnalyticsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AnalyticsIcon'
    )
);

const AnnouncementIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AnnouncementIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AnnouncementIcon'
    )
);

const ApiDocsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ApiDocsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ApiDocsIcon'
    )
);

const ArrowSide = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowSide" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowSide'
    )
);

const BlogIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BlogIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlogIcon'
    )
);

const ContractSpecificationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ContractSpecificationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ContractSpecificationIcon'
    )
);

const DetoTokenIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DetoTokenIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DetoTokenIcon'
    )
);

const FeedBackIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FeedBackIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FeedBackIcon'
    )
);

const GlobalIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GlobalIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GlobalIcon'
    )
);

const InsuranceFundIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InsuranceFundIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InsuranceFund'
    )
);

const MockTrading = loadable(
  () =>
    import(
      /* webpackChunkName: "MockTrading" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MockTrading'
    )
);

const OptionAnalyticsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "OptionAnalyticsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OptionAnalyticsIcon'
    )
);

const RaiseSupportIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RaiseSupportIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RaiseSupportIcon'
    )
);

const SettlementPrices = loadable(
  () =>
    import(
      /* webpackChunkName: "SettlementPrices" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SettlementPrices'
    )
);

const StatusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "StatusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Status'
    )
);

const SupportCenterIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SupportCenterIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SupportCentreIcon'
    )
);

const TradingFeesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TradingFeesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TradingFeesIcon'
    )
);

const UserGuideIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "UserGuideIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UserGuideIcon'
    )
);

const PhoneIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PhoneIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PhoneIcon'
    )
);

const Notification = loadable(
  () =>
    import(
      /* webpackChunkName: "Notification" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Notification'
    )
);

const Profile = loadable(
  () =>
    import(
      /* webpackChunkName: "Profile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Profile'
    )
);

const WalletIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WalletIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WalletIcon'
    )
);

const DeltaLogo = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaLogo" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaLogo'
    )
);

const RoboStrategiesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RoboStrategiesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RoboStrategiesIcon'
    )
);

const StakingPoolIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "StakingPoolIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './StakingPool'
    )
);

const CircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircleIcon'
    )
);

const DragIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DragIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DragIcon'
    )
);

const SummationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SummationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SummationIcon'
    )
);

const FourSquareBox = loadable(
  () =>
    import(
      /* webpackChunkName: "FourSquareBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FourSquareBox'
    )
);

const WarningIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningIcon'
    )
);

const FranceIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FranceIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FranceIcon'
    )
);

const IndiaIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "IndiaIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './IndiaIcon'
    )
);

const PortugeseIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PortugeseIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Portugese'
    )
);

const SpainIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SpainIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SpainIcon'
    )
);

const TurkeyIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TurkeyIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TurkeyIcon'
    )
);

const ThreeDots = loadable(
  () =>
    import(
      /* webpackChunkName: "ThreeDots" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ThreeDots'
    )
);

const RedWarningIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedWarningIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedCircleWarningIcon'
    )
);

const YellowCircleFillWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowCircleFillWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowCircleFillWarning'
    )
);

const BlueFileIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueFileIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueFileIcon'
    )
);

const VideoPlay = loadable(
  () =>
    import(
      /* webpackChunkName: "VideoPlay" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './VideoPlay'
    )
);

const Strategy = loadable(
  () =>
    import(
      /* webpackChunkName: "Strategy" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Strategy'
    )
);

const PieChart = loadable(
  () =>
    import(
      /* webpackChunkName: "PieChart" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PieChart'
    )
);

const BarGraph = loadable(
  () =>
    import(
      /* webpackChunkName: "BarGraph" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BarGraph'
    )
);

const StackedSquares = loadable(
  () =>
    import(
      /* webpackChunkName: "StackedSquares" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './StackedSquares'
    )
);

const DollarPad = loadable(
  () =>
    import(
      /* webpackChunkName: "DollarPad" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DollarPad'
    )
);

const OrangeDiamond = loadable(
  () =>
    import(
      /* webpackChunkName: "OrangeDiamond" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OrangeDiamond'
    )
);

const GreenDollar = loadable(
  () =>
    import(
      /* webpackChunkName: "GreenDollar" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreenDollar'
    )
);

const KeyboardIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "KeyboardIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './KeyboardIcon'
    )
);

const ValidationCorrect = loadable(
  () =>
    import(
      /* webpackChunkName: "ValidationCorrect" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ValidationCorrect'
    )
);

const ValidationInCorrect = loadable(
  () =>
    import(
      /* webpackChunkName: "ValidationInCorrect" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ValidationInCorrect'
    )
);

const EyeClosed = loadable(
  () =>
    import(
      /* webpackChunkName: "EyeClosed" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EyeClosed'
    )
);

const EyeOpen = loadable(
  () =>
    import(
      /* webpackChunkName: "EyeOpen" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EyeOpen'
    )
);

const Discount = loadable(
  () =>
    import(
      /* webpackChunkName: "Discount" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Discount'
    )
);

const ModalCloseX = loadable(
  () =>
    import(
      /* webpackChunkName: "ModalCloseX" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ModalCloseX'
    )
);

const Info = loadable(
  () =>
    import(
      /* webpackChunkName: "Info" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Info'
    )
);

const CircularX = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularX" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularX'
    )
);

const CircularExclamationWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularExclamationWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularExclamationWarning'
    )
);

const CircularLoader = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularLoader" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularLoader'
    )
);

const ChevronLeft = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronLeft" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronLeft'
    )
);

const ChevronRight = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronRight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronRight'
    )
);

const WifiOff = loadable(
  () =>
    import(
      /* webpackChunkName: "WifiOff" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WifiOff'
    )
);

const AppleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AppleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AppleIcon'
    )
);

const PlayStoreIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PlayStoreIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PlayStoreIcon'
    )
);

const DeltaLogoMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaLogoMobile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaLogoMobile'
    )
);

const DeltaLogoText = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaLogoText" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaLogoText'
    )
);

const RefreshIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RefreshIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RefreshIcon'
    )
);

const GridCloseIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GridCloseIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GridCloseIcon'
    )
);

const SmallInfo = loadable(
  () =>
    import(
      /* webpackChunkName: "SmallInfo" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SmallInfo'
    )
);

const SmallRectangle = loadable(
  () =>
    import(
      /* webpackChunkName: "SmallRectangle" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SmallRectangle'
    )
);

const CircularTick = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularTick'
    )
);

const DeltaLogoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaLogoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaLogoIcon'
    )
);

const YellowInfoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowInfoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowInfoIcon'
    )
);

const NoCompleted = loadable(
  () =>
    import(
      /* webpackChunkName: "NoCompleted" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoCompleted'
    )
);

const NoPending = loadable(
  () =>
    import(
      /* webpackChunkName: "NoPending" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoPending'
    )
);

const WaterDropIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WaterDropIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WaterDropIcon'
    )
);

const SquareRightChevron = loadable(
  () =>
    import(
      /* webpackChunkName: "SquareRightChevron" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquareRightChevron'
    )
);

const SquareLeftChevron = loadable(
  () =>
    import(
      /* webpackChunkName: "SquareLeftChevron" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquareLeftChevron'
    )
);

const TrollBoxChatIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TrollBoxChatIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TrollBoxChatIcon'
    )
);

const ExitIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ExitIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ExitIcon'
    )
);

const BlueFillPencil = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueFillPencil" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueFillPencil'
    )
);

const SubAccountsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SubAccountsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SubAccountsIcon'
    )
);

const PNLAnalyticsLight = loadable(
  () =>
    import(
      /* webpackChunkName: "PNLAnalyticsLight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PNLAnalyticsLight'
    )
);

const CloseIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CloseIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CloseIcon'
    )
);

const DollarBill = loadable(
  () =>
    import(
      /* webpackChunkName: "DollarBill" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DollarBill'
    )
);

const Alert = loadable(
  () =>
    import(
      /* webpackChunkName: "Alert" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Alert'
    )
);

const ScannerIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ScannerIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ScannerIcon'
    )
);

const WarningFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningFilled'
    )
);

const RefreshSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "RefreshSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RefreshSymbol'
    )
);

const WarningSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningSymbol'
    )
);

const WithdrawalLimitIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WithdrawalLimitIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WithdrawalLimitIcon'
    )
);

const ABCSquares = loadable(
  () =>
    import(
      /* webpackChunkName: "ABCSquares" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ABCSquares'
    )
);

const Crown = loadable(
  () =>
    import(
      /* webpackChunkName: "Crown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Crown'
    )
);

const TrashBox = loadable(
  () =>
    import(
      /* webpackChunkName: "TrashBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TrashBox'
    )
);

const SuccessTick = loadable(
  () =>
    import(
      /* webpackChunkName: "SuccessTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SuccessTick'
    )
);

const LeanTradeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LeanTradeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LeanTradeIcon'
    )
);

const NoHistoryAvailable = loadable(
  () =>
    import(
      /* webpackChunkName: "NoHistoryAvailable" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoHistoryAvailable'
    )
);

const NoData = loadable(
  () =>
    import(
      /* webpackChunkName: "NoData" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoData'
    )
);

const HoldingsDepositIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsDepositIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsDepositIcon'
    )
);

const HoldingsWithdrawIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsWithdrawIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsWithdrawIcon'
    )
);

const HoldingsConvertIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsConvertIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsConvertIcon'
    )
);

const HoldingsClearIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsClearIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsClearIcon'
    )
);

const HoldingsEditicon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsEditicon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsEditicon'
    )
);

const HoldingsSortArrowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsSortArrowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsSortArrowIcon'
    )
);

const HoldingsDownloadIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "HoldingsDownloadIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HoldingsDownloadIcon'
    )
);

const EditableCellCrossIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EditableCellCrossIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditableCellCrossIcon'
    )
);

const EditableCellTickIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EditableCellTickIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditableCellTickIcon'
    )
);

const LockIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LockIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LockIcon'
    )
);

const MoreIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MoreIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MoreIcon'
    )
);

const BuyCryptoQuickLink = loadable(
  () =>
    import(
      /* webpackChunkName: "BuyCryptoQuickLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BuyCryptoQuickLink'
    )
);

const OffersQuickLink = loadable(
  () =>
    import(
      /* webpackChunkName: "OffersQuickLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OffersQuickLink'
    )
);

const TrollboxQuickLink = loadable(
  () =>
    import(
      /* webpackChunkName: "TrollboxQuickLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TrollboxQuickLink'
    )
);

const DepositsQuickLink = loadable(
  () =>
    import(
      /* webpackChunkName: "DepositsQuickLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DepositsQuickLink'
    )
);

const DetoQuickLink = loadable(
  () =>
    import(
      /* webpackChunkName: "DetoQuickLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DetoQuickLink'
    )
);

const DeltaInvestLink = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaInvestLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaInvestLink'
    )
);

const MobileSupportTicketIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileSupportTicketIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileSupportTicketIcon'
    )
);

const MobileFeedbackIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileFeedbackIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileFeedbackIcon'
    )
);

const MobileSupportCenterIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileSupportCenterIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SupportCenterIcon'
    )
);

const MobileUserGuideIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileUserGuideIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileUserGuideIcon'
    )
);

const MobileMockTradingIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileMockTradingIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MockTradingIcon'
    )
);

const MobileAnalyticsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileAnalyticsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileAnalyticsIcon'
    )
);

const MobileOptionAnalyticsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileOptionAnalyticsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileOptionAnalyticsIcon'
    )
);

const MobileApiDocsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileApiDocsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileApiDocsIcon'
    )
);

const MobileBlogIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileBlogIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileBlogIcon'
    )
);

const MobileTradingFeesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileTradingFeesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileTradingFeesIcon'
    )
);

const ContractSpecsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ContractSpecsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ContractSpecsIcon'
    )
);

const SettlementPriceIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SettlementPriceIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SettlementPriceIcon'
    )
);

const InsuranceFund = loadable(
  () =>
    import(
      /* webpackChunkName: "InsuranceFund" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InsuranceFundIcon'
    )
);

const PlatformStatusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PlatformStatusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PlatformStatusIcon'
    )
);

const AnnouncementsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AnnouncementsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AnnouncementsIcon'
    )
);

const GlobalIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "GlobalIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GlobalIconSymbol'
    )
);

const ABCSquaresMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "ABCSquaresMobile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ABCSquaresMobileIcon'
    )
);

const ArrowRightIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowRightIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowRightIcon'
    )
);

const LanguageIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LanguageIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LanguageIcon'
    )
);

const NothingToShow = loadable(
  () =>
    import(
      /* webpackChunkName: "NothingToShow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NothingToShow'
    )
);

const CrossIconMobileBlue = loadable(
  () =>
    import(
      /* webpackChunkName: "CrossIconMobileBlue" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrossIconMobileBlue'
    )
);
const CircularPlus = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularPlus" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularPlus'
    )
);

const DownloadIconBlue = loadable(
  () =>
    import(
      /* webpackChunkName: "DownloadIconBlue" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DownloadIconBlue'
    )
);

const DropDownArroBlue = loadable(
  () =>
    import(
      /* webpackChunkName: "DropDownArroBlue" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropDownArroBlue'
    )
);

const DropDownArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "DropDownArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropDownArrow'
    )
);

const Calender = loadable(
  () =>
    import(
      /* webpackChunkName: "Calender" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Calender'
    )
);

const CrownIconBlue = loadable(
  () =>
    import(
      /* webpackChunkName: "CrownIconBlue" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrownIconBlue'
    )
);

const Offer = loadable(
  () =>
    import(
      /* webpackChunkName: "Offer" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Offer'
    )
);

const InfoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InfoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InfoIcon'
    )
);

const EditSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "EditSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditSymbol'
    )
);

const CrossIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "CrossIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrossIconSymbol'
    )
);

const ShareIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "ShareIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ShareIconSymbol'
    )
);

const EditNewIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EditNewIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditNewIcon'
    )
);

const TickIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TickIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickIcon'
    )
);
const SelectedTag = loadable(
  () =>
    import(
      /* webpackChunkName: "SelectedTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SelectedTag'
    )
);

const LearnMoreIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LearnMoreIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LearnMoreIcon'
    )
);

const DoubleArrowLeft = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleArrowLeft" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleArrowLeft'
    )
);

const TimesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TimesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TimesIcon'
    )
);

const CleanIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CleanIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CleanIcon'
    )
);

const CheckIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CheckIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CheckIcon'
    )
);

const PointerUp = loadable(
  () =>
    import(
      /* webpackChunkName: "PointerUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PointerUp'
    )
);

const PointerDown = loadable(
  () =>
    import(
      /* webpackChunkName: "PointerDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PointerDown'
    )
);

const Track = loadable(
  () =>
    import(
      /* webpackChunkName: "Track" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Track'
    )
);

const ChevronDownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronDownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronDownIcon'
    )
);

const Conversion = loadable(
  () =>
    import(
      /* webpackChunkName: "Conversion" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Conversion'
    )
);

const InProgressIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InProgressIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InProgressIcon'
    )
);

const NewCopy = loadable(
  () =>
    import(
      /* webpackChunkName: "NewCopy" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewCopy'
    )
);

const QrCode = loadable(
  () =>
    import(
      /* webpackChunkName: "QrCode" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './QrCode'
    )
);

const MandatoryTag = loadable(
  () =>
    import(
      /* webpackChunkName: "MandatoryTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MandatoryTag'
    )
);

const Copy = loadable(
  () =>
    import(
      /* webpackChunkName: "Copy" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Copy'
    )
);

const Delete = loadable(
  () =>
    import(
      /* webpackChunkName: "Delete" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Delete'
    )
);

const Important = loadable(
  () =>
    import(
      /* webpackChunkName: "Important" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Important'
    )
);

const ValidationCorrectIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ValidationCorrectIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ValidationCorrectIcon'
    )
);

const ValidationInCorrectIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ValidationInCorrectIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ValidationInCorrectIcon'
    )
);

const ModalcrossIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ModalcrossIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ModalcrossIcon'
    )
);

const NotificationCloseIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationCloseIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NotificationCloseIcon'
    )
);

const NotificationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NotificationIcon'
    )
);

const Wallet = loadable(
  () =>
    import(
      /* webpackChunkName: "Wallet" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Wallet'
    )
);

const AngleRight = loadable(
  () =>
    import(
      /* webpackChunkName: "AngleRight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AngleRight'
    )
);

const Key = loadable(
  () =>
    import(
      /* webpackChunkName: "Key" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Key'
    )
);

const ChevronRightIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronRightIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronRightIcon'
    )
);

const ChevronLeftIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChevronLeftIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChevronLeftIcon'
    )
);

const NewCalculatorIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewCalculatorIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewCalculatorIcon'
    )
);

const ErrorIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ErrorIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ErrorIcon'
    )
);

const ArrowRight = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowRight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowRight'
    )
);

const ArrowIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowIconSymbol'
    )
);

const SearchIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SearchIconSymbol'
    )
);

const SortArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "SortArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SortArrow'
    )
);

const FilterArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "FilterArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FilterArrow'
    )
);

const NoFavIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NoFavIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NoFavIcon'
    )
);

const ArrowDownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowDownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowDownIcon'
    )
);

const MinusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MinusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MinusIcon'
    )
);

const AddIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AddIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AddIcon'
    )
);

const ExternalLink = loadable(
  () =>
    import(
      /* webpackChunkName: "ExternalLink" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ExternalLink'
    )
);

const FilledInfoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FilledInfoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FilledInfoIcon'
    )
);

const DownloadIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DownloadIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DownloadIcon'
    )
);

const Refresh = loadable(
  () =>
    import(
      /* webpackChunkName: "Refresh" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Refresh'
    )
);

const NewRefreshIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewRefreshIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewRefreshIcon'
    )
);

const DiagonalUpArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "DiagonalUpArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DiagonalUpArrow'
    )
);

const DiagonalDownArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "DiagonalDownArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DiagonalDownArrow'
    )
);

const SmallInfoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SmallInfoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SmallInfoIcon'
    )
);

const Play = loadable(
  () =>
    import(
      /* webpackChunkName: "Play" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Play'
    )
);

const Pause = loadable(
  () =>
    import(
      /* webpackChunkName: "Pause" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Pause'
    )
);

const Star = loadable(
  () =>
    import(
      /* webpackChunkName: "Star" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Star'
    )
);

const MobileModalCloseIconNew = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileModalCloseIconNew" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileModalCloseIconNew'
    )
);

const MostTraded = loadable(
  () =>
    import(
      /* webpackChunkName: "MostTraded" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MostTraded'
    )
);

const HistoryClock = loadable(
  () =>
    import(
      /* webpackChunkName: "HistoryClock" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './HistoryClock'
    )
);

const OfferTag = loadable(
  () =>
    import(
      /* webpackChunkName: "OfferTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OfferTag'
    )
);

const FastForward = loadable(
  () =>
    import(
      /* webpackChunkName: "FastForward" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FastForward'
    )
);

const ContractExpiryIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ContractExpiryIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ContractExpiryIcon'
    )
);

const Home = loadable(
  () =>
    import(
      /* webpackChunkName: "Home" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Home'
    )
);

const ForwardIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ForwardIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ForwardIcon'
    )
);

const UpIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "UpIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UpIcon'
    )
);

const UpiIcon2 = loadable(
  () =>
    import(
      /* webpackChunkName: "UpiIcon2" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UpiIcon2'
    )
);

const LimitIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LimitIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LimitIcon'
    )
);

const QuestionMarkInCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "QuestionMarkInCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './QuestionMarkInCircleIcon'
    )
);

const QuestionMarkInCircleFilledIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "QuestionMarkInCircleFilledIcon" */ /* webpackMode: "lazy" */ './QuestionMarkInCircleFilledIcon'
    )
);

const BankWithMoneyBagIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BankWithMoneyBagIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BankWithMoneyBagIcon'
    )
);

const NotAllowedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NotAllowedIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NotAllowedIcon'
    )
);

const NeftRtgsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NeftRtgsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NeftRtgsIcon'
    )
);

const ImpsIcon2 = loadable(
  () =>
    import(
      /* webpackChunkName: "ImpsIcon2" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ImpsIcon2'
    )
);

const DownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DownIcon'
    )
);

const AddDivideMinusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AddDivideMinusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AddDivideMinusIcon'
    )
);

const ArrowUpThinActive = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowUpThinActive" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowUpThinActive'
    )
);

const ArrowDownThin = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowDownThin" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowDownThin'
    )
);

const WhatsappCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WhatsappCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WhatsappcircleIcon'
    )
);

const WhatsappFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "WhatsappFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WhatsappFilled'
    )
);

const TwitterCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TwitterCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TwitterCircleIcon'
    )
);

const TelegramCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TelegramCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TelegramCircleIcon'
    )
);

const RedditCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedditCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedditCircleIcon'
    )
);

const FaceBookCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FaceBookCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FaceBookCircleIcon'
    )
);

const DownlaodCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DownlaodCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DownlaodIcon'
    )
);

const CopyIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CopyIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CopyIcon'
    )
);

const StarIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "StarIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './StarIcon'
    )
);

const InfoBracketicon = loadable(
  () =>
    import(
      /* webpackChunkName: "InfoBracketicon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InfoBracketIcon'
    )
);

const ConvertIocn = loadable(
  () =>
    import(
      /* webpackChunkName: "ConvertIocn" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ConvertIocn'
    )
);

const CircleTradeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CircleTradeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircleTradeIcon'
    )
);

const DetailsArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "DetailsArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DetailsArrow'
    )
);

const Favorite = loadable(
  () =>
    import(
      /* webpackChunkName: "Favorite" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Favorite'
    )
);

const GreyStar = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyStar" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyIcon'
    )
);

const FavoriteSelected = loadable(
  () =>
    import(
      /* webpackChunkName: "FavoriteSelected" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FavoriteSelected'
    )
);

const NewTag = loadable(
  () =>
    import(
      /* webpackChunkName: "NewTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewTag'
    )
);

const LabelRibbon = loadable(
  () =>
    import(
      /* webpackChunkName: "LabelRibbon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LabelRibbon'
    )
);

const LiquidityMiningTagMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "LiquidityMiningTagMobile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LiquidityMiningTagMobile'
    )
);

const LeaderBoardIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LiquidityMiningTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LeaderBoardIcon'
    )
);
const LiquidityMiningTag = loadable(
  () =>
    import(
      /* webpackChunkName: "LiquidityMiningTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LiquidityMiningTag'
    )
);

const MostTradedDesktop = loadable(
  () =>
    import(
      /* webpackChunkName: "MostTradedDesktop" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MostTradedDesktop'
    )
);

const OfferTagDesktop = loadable(
  () =>
    import(
      /* webpackChunkName: "OfferTagDesktop" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OfferTagDesktop'
    )
);

const ZeroPercentFeeTag = loadable(
  () =>
    import(
      /* webpackChunkName: "ZeroPercentFeeTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ZeroPercentFeeTag'
    )
);

const NewBtcIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewBtcIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewbtcIcon'
    )
);

const NewXrpIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewXrpIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewXrpIcon'
    )
);

const NewBnsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewBnsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewBnsIcon'
    )
);

const NewSolIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewSolIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewSolIcon'
    )
);

const NewEthIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewEthIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewEthIcon'
    )
);

const NewUsdtIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewUsdtIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewUsdtIcon'
    )
);

const NewDetoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewDetoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewDetoIcon'
    )
);

const DropDownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DropDownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropDownIcon'
    )
);

const DropUpIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DropUpIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropUpIcon'
    )
);

const SmallDropDownOpen = loadable(
  () =>
    import(
      /* webpackChunkName: "SmallDropDownOpen" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SmallDropDownOpen'
    )
);

const SmallDropDownClose = loadable(
  () =>
    import(
      /* webpackChunkName: "SmallDropDownClose" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SmallDropDownClose'
    )
);

const PointLeft = loadable(
  () =>
    import(
      /* webpackChunkName: "PointLeft" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PointLeft'
    )
);

const TickVerified = loadable(
  () =>
    import(
      /* webpackChunkName: "TickVerified" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickVerified'
    )
);

const TickYellow = loadable(
  () =>
    import(
      /* webpackChunkName: "TickYellow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickYellow'
    )
);

const TickRejected = loadable(
  () =>
    import(
      /* webpackChunkName: "TickRejected" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickRejected'
    )
);

const TickWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "TickWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickWarning'
    )
);

const TickRound = loadable(
  () =>
    import(
      /* webpackChunkName: "TickRound" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickRound'
    )
);

const Person = loadable(
  () =>
    import(
      /* webpackChunkName: "Person" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Person'
    )
);

const TickRectangle = loadable(
  () =>
    import(
      /* webpackChunkName: "TickRectangle" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickRectangle'
    )
);

const IdentityVerification = loadable(
  () =>
    import(
      /* webpackChunkName: "IdentityVerification" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './IdentityVerification'
    )
);

const SafeSession = loadable(
  () =>
    import(
      /* webpackChunkName: "SafeSession" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SafeSession'
    )
);

const VerifiedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "VerifiedIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './VerifiedIcon'
    )
);

const VerificationPendingIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "VerificationPendingIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './VerificationPendingIcon'
    )
);

const AccountActivity = loadable(
  () =>
    import(
      /* webpackChunkName: "AccountActivity" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AccountActivity'
    )
);

const SecurityKey = loadable(
  () =>
    import(
      /* webpackChunkName: "SecurityKey" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SecurityKey'
    )
);

const SecurityLock = loadable(
  () =>
    import(
      /* webpackChunkName: "SecurityLock" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SecurityLock'
    )
);

const Pencil = loadable(
  () =>
    import(
      /* webpackChunkName: "Pencil" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Pencil'
    )
);

const BluePlus = loadable(
  () =>
    import(
      /* webpackChunkName: "BluePlus" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BluePlus'
    )
);

const DeleteSecurity = loadable(
  () =>
    import(
      /* webpackChunkName: "DeleteSecurity" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeleteSecurity'
    )
);

const Logout = loadable(
  () =>
    import(
      /* webpackChunkName: "Logout" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Logout'
    )
);

const LockBackgroundIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LockBackgroundIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LockBackgroundIcon'
    )
);

const ExclamationBackgroundIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ExclamationBackgroundIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ExclamationBackgroundIcon'
    )
);

const DeleteRed = loadable(
  () =>
    import(
      /* webpackChunkName: "DeleteRed" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeletedRed'
    )
);

const CloseNew = loadable(
  () =>
    import(
      /* webpackChunkName: "CloseNew" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CloseNew'
    )
);

const AppleIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "AppleIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AppleIconSymbol'
    )
);

const PlayStore = loadable(
  () =>
    import(
      /* webpackChunkName: "PlayStore" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PlayStore'
    )
);

const CopyNew = loadable(
  () =>
    import(
      /* webpackChunkName: "CopyNew" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CopyNew'
    )
);

const WarningRed = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningRed" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningRed'
    )
);

const WarningYellow = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningYellow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningYellow'
    )
);

const MobileSecurityIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileSecurityIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileSecurityIcon'
    )
);

const MobilePreferencesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobilePreferencesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobilePreferencesIcon'
    )
);

const MobileAPIKeyIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileAPIKeyIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileAPIKey'
    )
);

const MobileTransactionLogs = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileTransactionLogs" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileTransactionLogs'
    )
);

const BookIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BookIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BookIcon'
    )
);

const SquarePlus = loadable(
  () =>
    import(
      /* webpackChunkName: "SquarePlus" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquarePlus'
    )
);

const DoubleArrowRight = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleArrowRight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleArrowRight'
    )
);

const WindowsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WindowsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WindowsIcon'
    )
);

const IosIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "IosIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './IosIcon'
    )
);

const ChunkLoadErrorDesktopRefreshIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChunkLoadErrorDesktopRefreshIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChunkLoadErrorDesktopRefreshIcon'
    )
);

const ChunkLoadErrorMobileRefreshIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ChunkLoadErrorMobileRefreshIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ChunkLoadErrorMobileRefreshIcon'
    )
);

const DropDownArrowUpIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DropDownArrowUpIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropDownArrowUpIcon'
    )
);

const DropDownArrowDownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DropDownArrowDownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DropDownArrowDownIcon'
    )
);

const RightArrowIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RightArrowIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RightArrowIcon'
    )
);

const CrossModalCloseIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CrossModalCloseIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrossModalCrossIcon'
    )
);

const LeftArrowNavigationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LeftArrowNavigationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LeftArrowNavigationIcon'
    )
);

const KycVerifyIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "KycVerifyIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './KycVerifyIcon'
    )
);

const CancelIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CancelIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CancelIcon'
    )
);

const BlueTick = loadable(
  () =>
    import(
      /* webpackChunkName: "BlueTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BlueTick'
    )
);

const GrowCryptoIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GrowCryptoIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GrowCryptoIcon'
    )
);

const SpotIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "SpotIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SpotIconSymbol'
    )
);

const FreeTag = loadable(
  () =>
    import(
      /* webpackChunkName: "FreeTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FreeTag'
    )
);

const MobileFreeTag = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileFreeTag" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileFreeTag'
    )
);

const GreenCircleTick = loadable(
  () =>
    import(
      /* webpackChunkName: "GreenCircleTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreenCircleTick'
    )
);

const NineSquaresIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NineSquaresIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NineSquaresIcon'
    )
);

const DetoFeatureMenuIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DetoFeatureMenuIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DetoFeatureIcon'
    )
);

const MockTradingIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MockTradingIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MockTradingIcon'
    )
);

const UserOnlineIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "UserOnlineIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UserOnlineIcon'
    )
);

const LastUpdatedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LastUpdatedIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LastUpdatedIcon'
    )
);

const ListIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ListIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ListIcon'
    )
);

const FeesDiscountIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "FeesDiscountIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FeesDiscountIcon'
    )
);

const LimitInputPlusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LimitInputPlusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LimitInputPlusIcon'
    )
);

const LimitInputMinusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LimitInputMinusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LimitInputMinusIcon'
    )
);

const OrderPlacementRightArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "OrderPlacementRightArrow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OrderPlacementRightArrow'
    )
);

const CrownIconYellow = loadable(
  () =>
    import(
      /* webpackChunkName: "CrownIconYellow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrownIconYellow'
    )
);

const YellowDollarIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowDollarIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowDollarIcon'
    )
);

const YellowWalletIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowWalletIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowWalletIcon'
    )
);

const YellowHandIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowHandIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowHandIcon'
    )
);

const BracketEditIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BracketEditIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BracketEditIcon'
    )
);

const BracketClearIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BracketClearIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BracketClearIcon'
    )
);

const YellowWarningIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowWarningIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowWarning'
    )
);

const YellowWarningOutline = loadable(
  () =>
    import(
      /* webpackChunkName: "YellowWarningOutline" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YellowWarningOutline'
    )
);

const RedWarning = loadable(
  () =>
    import(
      /* webpackChunkName: "RedWarning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedWarning'
    )
);

const PlayIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PlayIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PlayIcon'
    )
);

const PlayIconLg = loadable(
  () =>
    import(/* webpackChunkName: "PlayIconLg" */ /* webpackMode: "lazy" */ './PlayIconLg')
);

const ToggleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ToggleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ToggleIcon'
    )
);

const DangerTriangle = loadable(
  () =>
    import(
      /* webpackChunkName: "DangerTriangle" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DangerTriangle'
    )
);

const TickRoundFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "TickRoundFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TickRoundFilled'
    )
);

const DoubleArrowRightThin = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleArrowRightThin" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleArrowRightThin'
    )
);

const WarningWithExclamation = loadable(
  () =>
    import(
      /* webpackChunkName: "WarningWithExclamation" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WarningWithExclamation'
    )
);

const EditPencil = loadable(
  () =>
    import(
      /* webpackChunkName: "EditPencil" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EditIcon'
    )
);

const CircleWarningRed = loadable(
  () =>
    import(
      /* webpackChunkName: "CircleWarningRed" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircleWarningRed'
    )
);

const GreyArrowDown = loadable(
  () =>
    import(
      /* webpackChunkName: "GreyArrowDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GreyArrowDown'
    )
);

const FileNotFound = loadable(
  () =>
    import(
      /* webpackChunkName: "FileNotFound" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FileNotFound'
    )
);

const FileWithTick = loadable(
  () =>
    import(
      /* webpackChunkName: "FileWithTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FileWithTick'
    )
);

const ArrowDownDirected = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowDownDirected" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowDownDirected'
    )
);

const TimerYellow = loadable(
  () =>
    import(
      /* webpackChunkName: "TimerYellow" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TimerYellow'
    )
);

const TrollBoxQuickLinkIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TrollBoxQuickLinkIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TrollBoxQuickLinkIcon'
    )
);

const ValidationErrorIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ValidationErrorIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ValidationErrorIcon'
    )
);

const CancelSvg = loadable(
  () =>
    import(
      /* webpackChunkName: "CancelSvg" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CancelSvg'
    )
);

const Vertical = loadable(
  () =>
    import(
      /* webpackChunkName: "Vertical" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Vertical'
    )
);

const RightArrowNavigationIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RightArrowNavigationIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RightArrowNavigationIcon'
    )
);

const ErrorCrossIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ErrorCrossIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ErrorCrossIcon'
    )
);

const Completed = loadable(
  () =>
    import(
      /* webpackChunkName: "Completed" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Completed'
    )
);

const AngleLeft = loadable(
  () =>
    import(
      /* webpackChunkName: "AngleLeft" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AngleLeft'
    )
);

const ArrowUp = loadable(
  () =>
    import(
      /* webpackChunkName: "ArrowUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ArrowUp'
    )
);

const FontAwesomeArrowDown = loadable(
  () =>
    import(
      /* webpackChunkName: "FontAwesomeArrowDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FontAwesomeArrowDown'
    )
);

const CaretDownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CaretDownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CarretDown'
    )
);

const AngleDown = loadable(
  () =>
    import(
      /* webpackChunkName: "AngleDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AngleDown'
    )
);

const AngleRightIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AngleRightIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AngleRighticon'
    )
);

const Eye = loadable(
  () =>
    import(
      /* webpackChunkName: "Eye" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Eye'
    )
);

const EyeSlash = loadable(
  () =>
    import(
      /* webpackChunkName: "EyeSlash" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EyeSlash'
    )
);

const CarretUp = loadable(
  () =>
    import(
      /* webpackChunkName: "CarretUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CarretUp'
    )
);

const AngleUp = loadable(
  () =>
    import(
      /* webpackChunkName: "AngleUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AngleUp'
    )
);

const Clipboard = loadable(
  () =>
    import(
      /* webpackChunkName: "Clipboard" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Clipboard'
    )
);

const Warning = loadable(
  () =>
    import(
      /* webpackChunkName: "Warning" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Warning'
    )
);

const DoubleAngleRight = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleAngleRight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleAngleRight'
    )
);

const DoubleAngleLeft = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleAngleLeft" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleAngleLeft'
    )
);

const BalancesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BalancesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BalancesIcon'
    )
);

const CrownIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CrownIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CrownIcon'
    )
);

const Trophy = loadable(
  () =>
    import(
      /* webpackChunkName: "Trophy" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Trophy'
    )
);

const MenuProfileIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MenuProfileIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MenuProfileIcon'
    )
);

const ProfileIconNew = loadable(
  () =>
    import(
      /* webpackChunkName: "ProfileIconNew" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ProfileIconNew'
    )
);

const ApiKeysIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "ApiKeysIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ApiKeysIcon'
    )
);

const WithdrawalsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "WithdrawalsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './WithdrawalsIcon'
    )
);

const SecurityIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SecurityIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SecurityIcon'
    )
);

const DepositsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DepositsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DepositsIcon'
    )
);

const PreferencesIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PreferencesIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PreferencesIcon'
    )
);

const LogsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LogsIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LogsIcon'
    )
);

const Facebook = loadable(
  () =>
    import(
      /* webpackChunkName: "Facebook" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Facebook'
    )
);

const FacebookFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "FacebookFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FacebookFilled'
    )
);

const Reddit = loadable(
  () =>
    import(
      /* webpackChunkName: "Reddit" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Reddit'
    )
);

const RedditFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "RedditFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedditFilled'
    )
);

const Referral = loadable(
  () =>
    import(
      /* webpackChunkName: "Referral" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Referral'
    )
);

const Twitter = loadable(
  () =>
    import(
      /* webpackChunkName: "Twitter" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Twitter'
    )
);

const Weibo = loadable(
  () =>
    import(
      /* webpackChunkName: "Weibo" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Weibo'
    )
);

const Whatsapp = loadable(
  () =>
    import(
      /* webpackChunkName: "Whatsapp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Whatsapp'
    )
);

const Telegram = loadable(
  () =>
    import(
      /* webpackChunkName: "Telegram" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Telegram'
    )
);

const TelegramFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "TelegramFilled" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TelegramFilled'
    )
);

const BullHorn = loadable(
  () =>
    import(
      /* webpackChunkName: "BullHorn" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BullHorn'
    )
);

const CheckIconSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "CheckIconSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CheckIconSymbol'
    )
);

const SyncAlt = loadable(
  () =>
    import(
      /* webpackChunkName: "SyncAlt" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SyncAlt'
    )
);

const DoubleAngleUp = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleAngleUp" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleAngleUp'
    )
);

const DoubleAngleDown = loadable(
  () =>
    import(
      /* webpackChunkName: "DoubleAngleDown" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DoubleAngleDown'
    )
);

const SquareIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SquareIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquareIcon'
    )
);

const MobileIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileIcon'
    )
);

const MoveOptionIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MoveOptionIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MoveOptionIcon'
    )
);

const EasyOptionIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EasyOptionIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EasyOptionIcon'
    )
);

const Spot = loadable(
  () =>
    import(
      /* webpackChunkName: "Spot" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Spot'
    )
);

const RoboStrategyIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RoboStrategyIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RoboStrategyIcon'
    )
);

const StakingPool = loadable(
  () =>
    import(
      /* webpackChunkName: "StakingPool" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './StakingPoolIcon'
    )
);

const LongOrShortIndicatorIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LongOrShortIndicatorIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LongOrShortIndicator'
    )
);

const BasketOrderIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BasketOrderIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BasketOrderIcon'
    )
);

const BluePlusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BluePlusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BluePlusSquareIcon'
    )
);

const LikeIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LikeIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LikeIcon'
    )
);

const SquareEditIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SquareEditIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquareEditIcon'
    )
);

const RedMessageIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedMessageIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedMessageIcon'
    )
);

const RightHalfArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "RedMessageIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RightHalfArrow'
    )
);

const LineChartBox = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './LineChartBox'
    )
);

const EmailIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './EmailIcon'
    )
);

const MobilePhoneIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobilePhoneIcon'
    )
);

const InfoOutlinedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './InfoOutlined'
    )
);

const CopyToClipboardIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CopyToClipboardIcon'
    )
);

const UnverifiedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UnverifiedIcon'
    )
);

const UserAvatar = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UserAvatar'
    )
);

const UserAvatarLight = loadable(
  () =>
    import(
      /* webpackChunkName: "LineChartBox" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UserAvatarLight'
    )
);

const CircularUpArrow = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularUpArrow" */ /* webpackMode: "lazy" */ './CircleBackgroundUpArrow'
    )
);

const GiftIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GiftIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GiftIcon'
    )
);

const LinkExpiredIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "LinkExpiredIcon" */ /* webpackMode: "lazy" */ './LinkExpiredIcon'
    )
);

const VerificationInProgressIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "VerificationInProgressIcon" */ /* webpackMode: "lazy" */ './VerificationInProgressIcon'
    )
);

const AlreadyVerifiedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "AlreadyVerifiedIcon" */ /* webpackMode: "lazy" */ './AlreadyVerifiedIcon'
    )
);

const RupeeSymbol = loadable(
  () =>
    import(
      /* webpackChunkName: "RupeeSymbol" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RupeeSymbol'
    )
);

const OrderIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "OrderIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './OrderIcon'
    )
);

const INRTag = loadable(
  () =>
    import(
      /* webpackChunkName: "AlreadyVerifiedIcon" */ /* webpackMode: "lazy" */ './INRTag'
    )
);

const GmailIcon = loadable(
  () =>
    import(/* webpackChunkName: "GmailIcon" */ /* webpackMode: "lazy" */ './GmailIcon')
);

const FaceId = loadable(
  () =>
    import(
      /* webpackChunkName: "FaceId" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './FaceId'
    )
);

const Fingerprint = loadable(
  () =>
    import(
      /* webpackChunkName: "Fingerprint" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Fingerprint'
    )
);

const UserTick = loadable(
  () =>
    import(
      /* webpackChunkName: "UserTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './UserTick'
    )
);

const SelfieIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "SelfieIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SelfieIcon'
    )
);

const Instagram = loadable(
  () =>
    import(
      /* webpackChunkName: "InstagramIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './Instagram'
    )
);

const YoutubeCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "YoutubeCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './YoutubeCircleIcon'
    )
);
const NewPlayIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "NewPlayIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './NewPlayIcon'
    )
);

const GithubCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GithubCircleIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GithubCircleIcon'
    )
);

const GeneralFilterIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "GeneralFilterIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './GeneralFilterIcon'
    )
);

const RedirectionIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedirectionIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedirectionIcon'
    )
);
const MapIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MapIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MapIcon'
    )
);

const SquareLoadIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MapIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './SquareLoadIcon'
    )
);

const CircularCrossIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularCrossIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularCrossIcon'
    )
);

const RedirectIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RedirectIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './RedirectIcon'
    )
);

const CircularLockIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CircularLockIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './CircularLockIcon'
    )
);

const PanCardIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "PanCardIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './PanCard'
    )
);

const AntiPhishing = loadable(
  () =>
    import(
      /* webpackChunkName: "AntiPhishing" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './AntiPhishing'
    )
);

const Bank = loadable(() => import(/* webpackChunkName: "Bank" */ './Bank'));

const BorderLessLineChartIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BorderLessLineChartIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BorderLessLineChartIcon'
    )
);

const AdhaarIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BorderLessLineChartIcon" */ /* webpackMode: "lazy" */ './Adhaar'
    )
);

const DeltaIndiaLogoMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaIndiaLogoMobile" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './DeltaIndiaLogoMobile'
    )
);

const CircledPlus = loadable(
  () =>
    import(
      /* webpackChunkName: "CircledPlus" */ /* webpackMode: "lazy" */ './CircledPlus'
    )
);

const InfoFilled = loadable(
  () =>
    import(/* webpackChunkName: "InfoFilled" */ /* webpackMode: "lazy" */ './InfoFilled')
);

const DeleteFilled = loadable(
  () =>
    import(
      /* webpackChunkName: "DeleteFilled" */ /* webpackMode: "lazy" */ './DeleteFilled'
    )
);

const TelegramOutlineIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TelegramOutlineIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './TelegramOutlineIcon'
    )
);

const MobilePlatformStatusIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobilePlatformStatusIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobilePlatformStatusIcon'
    )
);

const MobileRaiseSupportTicketIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileRaiseSupportTicketIcon" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileRaiseSupportTicketIcon'
    )
);

const MobileSupportCenterIconIndian = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileSupportCenterIconIndian" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './MobileSupportCentreIconIndian'
    )
);

const UsdConversionIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "UsdConversion" */ /* webpackMode: "lazy" */ './UsdConversion'
    )
);

const ScanToLoginDark = loadable(
  () =>
    import(
      /* webpackChunkName: "ScanToLoginDark" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ScanToLoginDark'
    )
);

const ScanToLoginLight = loadable(
  () =>
    import(
      /* webpackChunkName: "ScanToLoginLight" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './ScanToLoginLight'
    )
);

const BlogPointsIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BlogPointsIcon" */ /* webpackMode: "lazy" */ './BlogPointsIcon'
    )
);

const BestPriceIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "BestPriceIcon" */ /* webpackMode: "lazy" */ './BestPrice'
    )
);

const TradeHistoryIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TradeHistoryIcon" */ /* webpackMode: "lazy" */ './TradeHistory'
    )
);

const RupeeSymbolIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "RupeeSymbolIcon" */ /* webpackMode: "lazy" */ './RupeeSymbolIcon'
    )
);

// !NOTE: Below step is mandatory
/**
 * *Convert to .tsx from here "https://react-svgr.com/playground/?typescript=true"
 */

/**
 * * Before adding a new icon, visit "https://devnet.delta.exchange/app/icons" to see if it already exists
 */

/**
 * * Also, make "webpackPrefetch: true" only if needed. Do not blindly make it true.
 */
const DeltaLogoWithTextIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaLogoWithTextIcon" */ /* webpackMode: "lazy" */ './DeltaLogoWithTextIcon'
    )
);

const TwitterXCircleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "TwitterXCircleIcon" */ /* webpackMode: "lazy" */ './TwitterXCircleIcon'
    )
);

const BonusTick = loadable(
  () =>
    import(
      /* webpackChunkName: "BonusTick" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ './BonusTick'
    )
);

const Hamburger = loadable(
  () => import(/* webpackChunkName: "Hamburger" */ './Hamburger')
);

const HourGlass = loadable(
  () =>
    import(/* webpackChunkName: "HourGlass" */ /* webpackMode: "lazy" */ './HourGlass')
);

const RupeeGoldCoin = loadable(
  () =>
    import(
      /* webpackChunkName: "HourGlass" */ /* webpackMode: "lazy" */ './RupeeGoldCoin'
    )
);

const EmailSendIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "EmailSendIcon" */ /* webpackMode: "lazy" */ './EmailSendIcon'
    )
);

const EmptyData = loadable(
  () =>
    import(/* webpackChunkName: "EmptyData" */ /* webpackMode: "lazy" */ './EmptyData')
);

const CalendarIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "CalendarIcon" */ /* webpackMode: "lazy" */ './CalendarIcon'
    )
);

const MailSpam = loadable(
  () => import(/* webpackChunkName: "HourGlass" */ /* webpackMode: "lazy" */ './MailSpam')
);

const LightningIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantlyCreditedTag" */ /* webpackMode: "lazy" */ './Lightning'
    )
);

const ZeroIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantlyCreditedTag" */ /* webpackMode: "lazy" */ './ZeroIcon'
    )
);

const YoutubeRedIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantlyCreditedTag" */ /* webpackMode: "lazy" */ './YoutubeRed'
    )
);
const UnderMaintenance = loadable(
  () =>
    import(
      /* webpackChunkName: "UnderMaintenance" */ /* webpackMode: "lazy" */ './UnderMaintenance'
    )
);

const DeltaIndiaEnlargedLogo = loadable(
  () =>
    import(
      /* webpackChunkName: "DeltaIndiaEnlargedLogo" */ /* webpackMode: "lazy" */ './DeltaIndiaEnlargedLogo'
    )
);

const NewYoutubeTutorialIcon = loadable(
  () =>
    import(
      /* webpackChunkName: " NewYoutubeTutorialIcon" */ /* webpackMode: "lazy" */ './NewYoutubeTutorialIcon'
    )
);

const DollarToggleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantlyCreditedTag" */ /* webpackMode: "lazy" */ './DollarToggleIcon'
    )
);

const INRToggleIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "InstantlyCreditedTag" */ /* webpackMode: "lazy" */ './INRToggleIcon'
    )
);

const PhonePortraitIcon = loadable(
  () =>
    import(
      /* webpackChunkName: " NewYoutubeTutorialIcon" */ /* webpackMode: "lazy" */ './PhonePortraitIcon'
    )
);

// !NOTE: Below step is mandatory
/**
 * *Convert to .tsx from here "https://react-svgr.com/playground/?typescript=true"
 */

/**
 * * Before adding a new icon, visit "https://devnet.delta.exchange/app/icons" to see if it already exists
 */

/**
 * * Also, make "webpackPrefetch: true" only if needed. Do not blindly make it true.
 */

export {
  INRToggleIcon,
  DollarToggleIcon,
  PhonePortraitIcon,
  MailSpam,
  YoutubeRedIcon,
  ZeroIcon,
  LightningIcon,
  ScanToLoginDark,
  ScanToLoginLight,
  GoogleIcon,
  GoogleVerifyDarkIcon,
  GoogleVerifyLightIcon,
  UserAvatar,
  UserAvatarLight,
  UnverifiedIcon,
  CopyToClipboardIcon,
  EmailIcon,
  MobilePhoneIcon,
  InfoOutlinedIcon,
  LeftArrowIcon,
  // JewelIcon,
  MainAccount,
  SubAccounts,
  SubAccountPlusIcon,
  AddUserIcon,
  LogoutIcon,
  SettingIcon,
  TransferButtonIcon,
  TransferIcon,
  PencilIcon,
  LimitUserIcon,
  SwitchAccount,
  SubAccount,
  GroupUsers,
  SettingsIcon,
  PortfolioMarginIcon,
  ShareIcon,
  ChevronCircleIcon,
  HistoryIcon,
  PlusIcon,
  EditIcon,
  SortIcon,
  SearchIcon,
  SearchIconSmall,
  ClearInputTextIcon,
  ArrowBackIcon,
  ChartsIcon,
  ActiveArrow,
  CrossIcon,
  ChevronDown,
  ChevronUp,
  WarningRedIcon,
  WarningYellowIcon,
  GreyWarning,
  ComingSoonIcon,
  PositionsIcon,
  RedXIcon,
  GreenTickIcon,
  CaretUp,
  CaretDown,
  ClearFilterIcon,
  ArrowIcon,
  NoDataIcon,
  BlueBadgeIcon,
  BlueDownloadIcon,
  BlueRadioButton,
  GreyBadgeIcon,
  GreyArrowBox,
  GreyCalendar,
  GreyCircleTick,
  GreyClock,
  GreenTick,
  InProgressYellowIcon,
  VolumeIcon,
  ArrowDown,
  ConverterIcon,
  YellowWarning,
  RightGreyArrow,
  BlueTriangularTick,
  TransakLogo,
  InfoCircleIcon,
  FireIcon,
  BlueRightArrow,
  BracketIcon,
  SpotIcon,
  MenuIdentityVerificationIcon,
  LightThemeIcon,
  DarkThemeIcon,
  SendIcon,
  Smiley,
  DownArrowIcon,
  RaiseSupportTicket,
  GreenArrowUp,
  RedArrowDown,
  NavigateIcon,
  TotalCreditsIcon,
  TotalDeltaCashIcon,
  TotalAccountEquityIcon,
  TradingBalanceTitleIcon,
  InvestBalanceTitleIcon,
  DepositIcon,
  WithDrawIcon,
  TradeIcon,
  ConvertIcon,
  NineSquareIcon,
  AnalyticsIcon,
  AnnouncementIcon,
  ApiDocsIcon,
  ArrowSide,
  BlogIcon,
  ContractSpecificationIcon,
  DetoTokenIcon,
  FeedBackIcon,
  GlobalIcon,
  InsuranceFundIcon,
  MockTrading,
  OptionAnalyticsIcon,
  RaiseSupportIcon,
  SettlementPrices,
  StatusIcon,
  SupportCenterIcon,
  TradingFeesIcon,
  UserGuideIcon,
  PhoneIcon,
  Notification,
  Profile,
  WalletIcon,
  DeltaLogo,
  RoboStrategiesIcon,
  StakingPoolIcon,
  CircleIcon,
  DragIcon,
  SummationIcon,
  FourSquareBox,
  WarningIcon,
  FranceIcon,
  IndiaIcon,
  PortugeseIcon,
  SpainIcon,
  TurkeyIcon,
  ThreeDots,
  RedWarningIcon,
  YellowCircleFillWarning,
  BlueFileIcon,
  VideoPlay,
  Strategy,
  PieChart,
  BarGraph,
  StackedSquares,
  DollarPad,
  OrangeDiamond,
  GreenDollar,
  KeyboardIcon,
  ValidationCorrect,
  ValidationInCorrect,
  EyeClosed,
  EyeOpen,
  Discount,
  ModalCloseX,
  Info,
  CircularX,
  CircularExclamationWarning,
  CircularLoader,
  ChevronLeft,
  ChevronRight,
  WifiOff,
  AppleIcon,
  PlayStoreIcon,
  DeltaLogoMobile,
  DeltaLogoText,
  RefreshIcon,
  GridCloseIcon,
  SmallInfo,
  SmallRectangle,
  CircularTick,
  DeltaLogoIcon,
  YellowInfoIcon,
  NoCompleted,
  NoPending,
  WaterDropIcon,
  SquareRightChevron,
  SquareLeftChevron,
  TrollBoxChatIcon,
  ExitIcon,
  BlueFillPencil,
  SubAccountsIcon,
  PNLAnalyticsLight,
  CloseIcon,
  DollarBill,
  Alert,
  ScannerIcon,
  WarningFilled,
  RefreshSymbol,
  WarningSymbol,
  WithdrawalLimitIcon,
  ABCSquares,
  Crown,
  TrashBox,
  SuccessTick,
  LeanTradeIcon,
  NoHistoryAvailable,
  NoData,
  HoldingsDepositIcon,
  HoldingsWithdrawIcon,
  LockIcon,
  HoldingsConvertIcon,
  HoldingsClearIcon,
  HoldingsEditicon,
  HoldingsSortArrowIcon,
  HoldingsDownloadIcon,
  EditableCellCrossIcon,
  EditableCellTickIcon,
  MoreIcon,
  BuyCryptoQuickLink,
  OffersQuickLink,
  TrollboxQuickLink,
  DepositsQuickLink,
  DetoQuickLink,
  DeltaInvestLink,
  MobileSupportTicketIcon,
  MobileFeedbackIcon,
  MobileSupportCenterIcon,
  MobileUserGuideIcon,
  MobileMockTradingIcon,
  MobileAnalyticsIcon,
  MobileOptionAnalyticsIcon,
  MobileApiDocsIcon,
  MobileBlogIcon,
  MobileTradingFeesIcon,
  ContractSpecsIcon,
  SettlementPriceIcon,
  InsuranceFund,
  PlatformStatusIcon,
  AnnouncementsIcon,
  GlobalIconSymbol,
  ABCSquaresMobile,
  ArrowRightIcon,
  LanguageIcon,
  NothingToShow,
  CrossIconMobileBlue,
  DownloadIconBlue,
  DropDownArroBlue,
  DropDownArrow,
  Calender,
  CrownIconBlue,
  Offer,
  InfoIcon,
  EditSymbol,
  CrossIconSymbol,
  ShareIconSymbol,
  EditNewIcon,
  TickIcon,
  LearnMoreIcon,
  DoubleArrowLeft,
  TimesIcon,
  CleanIcon,
  CheckIcon,
  PointerUp,
  PointerDown,
  Track,
  ChevronDownIcon,
  Conversion,
  InProgressIcon,
  NewCopy,
  MandatoryTag,
  Copy,
  Delete,
  CrownIcon,
  Trophy,
  Important,
  ValidationCorrectIcon,
  ValidationInCorrectIcon,
  ModalcrossIcon,
  NotificationCloseIcon,
  NotificationIcon,
  Wallet,
  AngleRight,
  Key,
  ChevronRightIcon,
  ChevronLeftIcon,
  NewCalculatorIcon,
  ErrorIcon,
  ArrowRight,
  ArrowIconSymbol,
  SearchIconSymbol,
  SortArrow,
  FilterArrow,
  NoFavIcon,
  ArrowDownIcon,
  MinusIcon,
  AddIcon,
  ExternalLink,
  FilledInfoIcon,
  DownloadIcon,
  Refresh,
  NewRefreshIcon,
  DiagonalUpArrow,
  DiagonalDownArrow,
  SmallInfoIcon,
  Play,
  PlayIconLg,
  Pause,
  Star,
  MobileModalCloseIconNew,
  MostTraded,
  HistoryClock,
  OfferTag,
  FastForward,
  ContractExpiryIcon,
  Home,
  ForwardIcon,
  UpIcon,
  DownIcon,
  AddDivideMinusIcon,
  ArrowUpThinActive,
  ArrowDownThin,
  WhatsappCircleIcon,
  TwitterCircleIcon,
  TelegramCircleIcon,
  RedditCircleIcon,
  FaceBookCircleIcon,
  DownlaodCircleIcon,
  CopyIcon,
  StarIcon,
  InfoBracketicon,
  ConvertIocn,
  CircleTradeIcon,
  DetailsArrow,
  Favorite,
  GreyStar,
  FavoriteSelected,
  NewTag,
  LabelRibbon,
  LiquidityMiningTagMobile,
  LiquidityMiningTag,
  MostTradedDesktop,
  OfferTagDesktop,
  ZeroPercentFeeTag,
  NewBtcIcon,
  NewXrpIcon,
  NewBnsIcon,
  NewSolIcon,
  NewEthIcon,
  NewUsdtIcon,
  NewDetoIcon,
  DropDownIcon,
  DropUpIcon,
  SmallDropDownOpen,
  SmallDropDownClose,
  PointLeft,
  TickVerified,
  TickYellow,
  TickRejected,
  TickWarning,
  TickRound,
  Person,
  TickRectangle,
  IdentityVerification,
  SafeSession,
  VerifiedIcon,
  VerificationPendingIcon,
  AccountActivity,
  SecurityKey,
  SecurityLock,
  Pencil,
  BluePlus,
  DeleteSecurity,
  Logout,
  LockBackgroundIcon,
  ExclamationBackgroundIcon,
  DeleteRed,
  CloseNew,
  AppleIconSymbol,
  PlayStore,
  CopyNew,
  WarningRed,
  WarningYellow,
  MobileSecurityIcon,
  MobilePreferencesIcon,
  MobileAPIKeyIcon,
  MobileTransactionLogs,
  BookIcon,
  SquarePlus,
  DoubleArrowRight,
  WindowsIcon,
  IosIcon,
  ChunkLoadErrorDesktopRefreshIcon,
  ChunkLoadErrorMobileRefreshIcon,
  DropDownArrowUpIcon,
  DropDownArrowDownIcon,
  RightArrowIcon,
  CrossModalCloseIcon,
  LeftArrowNavigationIcon,
  KycVerifyIcon,
  CancelIcon,
  BlueTick,
  GrowCryptoIcon,
  SpotIconSymbol,
  FreeTag,
  MobileFreeTag,
  GreenCircleTick,
  NineSquaresIcon,
  DetoFeatureMenuIcon,
  MockTradingIcon,
  UserOnlineIcon,
  LastUpdatedIcon,
  ListIcon,
  FeesDiscountIcon,
  LimitInputPlusIcon,
  LimitInputMinusIcon,
  OrderPlacementRightArrow,
  CrownIconYellow,
  YellowDollarIcon,
  YellowWalletIcon,
  YellowHandIcon,
  BracketEditIcon,
  BracketClearIcon,
  YellowWarningIcon,
  YellowWarningOutline,
  RedWarning,
  PlayIcon,
  ToggleIcon,
  DangerTriangle,
  TickRoundFilled,
  DoubleArrowRightThin,
  WarningWithExclamation,
  EditPencil,
  CircleWarningRed,
  GreyArrowDown,
  FileNotFound,
  FileWithTick,
  ArrowDownDirected,
  TimerYellow,
  TrollBoxQuickLinkIcon,
  ValidationErrorIcon,
  CancelSvg,
  Vertical,
  RightArrowNavigationIcon,
  ErrorCrossIcon,
  Completed,
  AngleLeft,
  ArrowUp,
  FontAwesomeArrowDown,
  CaretDownIcon,
  AngleDown,
  AngleRightIcon,
  Eye,
  EyeSlash,
  CarretUp,
  AngleUp,
  Clipboard,
  Warning,
  DoubleAngleRight,
  DoubleAngleLeft,
  BalancesIcon,
  MenuProfileIcon,
  ProfileIconNew,
  ApiKeysIcon,
  WithdrawalsIcon,
  SecurityIcon,
  DepositsIcon,
  PreferencesIcon,
  LogsIcon,
  Facebook,
  FacebookFilled,
  Reddit,
  RedditFilled,
  Referral,
  Twitter,
  Weibo,
  Whatsapp,
  WhatsappFilled,
  Telegram,
  TelegramFilled,
  BullHorn,
  CheckIconSymbol,
  SyncAlt,
  DoubleAngleUp,
  DoubleAngleDown,
  SquareIcon,
  MobileIcon,
  MoveOptionIcon,
  EasyOptionIcon,
  Spot,
  RoboStrategyIcon,
  StakingPool,
  LeaderBoardIcon,
  LongOrShortIndicatorIcon,
  LeanNineSquareIcon,
  BasketOrderIcon,
  BluePlusIcon,
  LikeIcon,
  SquareEditIcon,
  RedMessageIcon,
  RightHalfArrow,
  LineChartBox,
  CircularPlus,
  GiftIcon,
  CircularUpArrow,
  LinkExpiredIcon,
  AlreadyVerifiedIcon,
  VerificationInProgressIcon,
  QrCode,
  RupeeSymbol,
  OrderIcon,
  CircularLockIcon,
  RedirectIcon,
  INRTag,
  GmailIcon,
  FaceId,
  Fingerprint,
  UserTick,
  SelfieIcon,
  YoutubeCircleIcon,
  Instagram,
  GithubCircleIcon,
  GeneralFilterIcon,
  RedirectionIcon,
  MapIcon,
  SquareLoadIcon,
  CircularCrossIcon,
  PanCardIcon,
  AntiPhishing,
  Bank,
  BorderLessLineChartIcon,
  AdhaarIcon,
  DeltaIndiaLogoMobile,
  CircledPlus,
  InfoFilled,
  DeleteFilled,
  TelegramOutlineIcon,
  MobilePlatformStatusIcon,
  MobileRaiseSupportTicketIcon,
  MobileSupportCenterIconIndian,
  UsdConversionIcon,
  DeltaLogoWithTextIcon,
  TwitterXCircleIcon,
  BonusTick,
  Hamburger,
  HourGlass,
  BlogPointsIcon,
  BestPriceIcon,
  JewelIcon,
  NewPlayIcon,
  SelectedTag,
  TradeHistoryIcon,
  RupeeGoldCoin,
  EmailSendIcon,
  EmptyData,
  CalendarIcon,
  UpiIcon2,
  BankWithMoneyBagIcon,
  NotAllowedIcon,
  NeftRtgsIcon,
  ImpsIcon2,
  LimitIcon,
  QuestionMarkInCircleIcon,
  UnderMaintenance,
  DeltaIndiaEnlargedLogo,
  NewYoutubeTutorialIcon,
  RupeeSymbolIcon,
  QuestionMarkInCircleFilledIcon,
};
