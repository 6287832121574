/* eslint-disable import/no-unresolved */
import { VANILLA_SETTLING_ASSET } from 'constants';

import React from 'react';

import { pure } from 'recompose';

import { cropAfterDecimals } from 'helpers';
import subscribe from 'HOC/subscribe';
import { spotPriceSelectorBySymbol } from 'selectors';

export const getSpotIndexSymbol = (assetSymbol: string) => {
  const currentSymbol = assetSymbol === 'BTC' ? 'XBT' : assetSymbol;
  return `.DE${currentSymbol}${VANILLA_SETTLING_ASSET}`;
};

const MobileBasketOrderSpotpriceComponent = pure(
  subscribe(({ assetSymbol, className }) => {
    const spotPrice = spotPriceSelectorBySymbol(
      getSpotIndexSymbol(assetSymbol)
    ) as number;

    return <span className={className} data-palette="MobileBasketOrderSpotpriceComponent">{cropAfterDecimals(spotPrice, 2)}</span>;
  })
);

export default MobileBasketOrderSpotpriceComponent;
