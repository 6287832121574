import styled from 'styled-components';

import { device } from '../../helpers/screenSizes';
import Modal from '../modal';

export const DepositsWrapper = styled.div`
  color: ${props => props.theme.primaryText};
  padding: 0px 20px;

  @media ${device.down.md} {
    padding: 0;
  }

  .deposit-container {
    display: flex;
    margin-bottom: 100px;

    @media ${device.down.md} {
      margin-bottom: 0;
      padding: 20px 15px;
      // height: 100vh;
    }

    .coin-address-section {
      display: flex;
      flex-direction: column;
      gap: 40px;
      .coin-dropdown-section {
        width: 300px;
      }
    }

    .howItWorksSection {
      position: relative;
      margin-left: 14px;
      font-family: var(--fontRegular);
      .title {
        font-family: var(--fontSemiBold);
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 22px;
        position: relative;
        top: 7px;
        color: var(--secondaryText);
        @media ${device.down.md} {
          svg {
            margin-right: 12px;
            &.rotate-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
      ul {
        list-style-type: disc;
        li {
          font-family: var(--fontLight);
          font-size: 12.1px;
          color: ${props => props.theme.secondaryText};
          line-height: 22px;
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
          &::marker {
            font-size: 16px;
            color: ${props => props.theme.primaryTheme};
          }
        }
      }
      .support-link {
        font-size: 12.1px;
        line-height: 22px;
        color: ${props => props.theme.secondaryText};
        margin-top: 20px;
        &:hover {
          .highlight {
            color: ${props => props.theme.primaryButtonHover};
          }
          svg {
            path:nth-of-type(2) {
              fill: ${props => props.theme.primaryButtonHover};
            }
          }
        }
        .highlight {
          color: ${props => props.theme.primaryTheme};
          cursor: pointer;
        }
        svg {
          margin-top: -3px;
          cursor: pointer;
        }
      }
      @media ${device.down.md} {
        margin-left: 0;
        .title {
          font-size: 12.1px;
          font-family: var(--fontRegular);
          color: var(--secondaryText);
          top: 0;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .support-link {
          font-size: 12.1px;
          margin-top: 20px;
        }
        ul {
          margin-left: 20px;
          margin-top: 20px;
          list-style-type: disc;
          li {
            &::marker {
              font-size: 18px;
              color: ${props => props.theme.primaryTheme};
            }
          }
          li:not(:last-of-type) {
            margin-bottom: 18px;
          }
        }
      }
    }

    .buy-crypto-section {
      margin-left: 190px;
      // margin-top: 120px;
      width: 305px;
      font-family: var(--fontRegular);
      @media screen and (max-width: 1100px) and (${device.up.sm}) {
        margin-left: 90px;
      }

      .slippage-area {
        font-size: 14px;
        line-height: 16px;
        color: var(--secondaryText);
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        min-width: 500px;
        .fiat-value {
          color: var(--primaryText);
        }
        .equals-to-icon {
          margin-left: 4px;
        }
        .slippage {
          display: flex;
          font-size: 12.1px;
          margin-left: 8px;
          .slippage-popover {
            margin-left: 3px;
            border-bottom: 0.2px dashed var(--tooltipBottomDashedAndUnderlineColor);
          }
          .slippage-popover-icon-active {
            border-bottom: 0.2px dashed ${props => props.theme.primaryTheme};
            .slippage-popover {
              border-bottom: none;
            }
          }
          .slippage-value {
            margin-left: 5px;
          }
        }
        @media ${device.down.md} {
          font-size: 14px;
          margin-bottom: 30px;
        }
      }
      .input-label {
        font-size: 12.1px;
        line-height: 18px;
        color: var(--secondaryText);
        margin-bottom: 10px;
      }
      .input-wrapper {
        margin-bottom: 30px;
        position: relative;
        .active-coin {
          position: absolute;
          top: 42px;
          right: 20px;

          font-size: 14px;
          line-height: 20px;
          color: var(--secondaryText);
          @media ${device.down.md} {
            right: 15px;
          }
        }
      }
      .agreement {
        display: flex;
        align-items: center;
        cursor: pointer;
        .text {
          margin: 0 0 0 6px;
          font-weight: 300;
          font-size: 12.1px;
          line-height: 18px;
          color: var(--secondaryText);
          position: relative;
          top: 4px;
          .highlight {
            color: ${props => props.theme.primaryTheme};
            cursor: pointer;
            &:hover {
              color: ${props => props.theme.primaryButtonHover};
            }
          }
          svg {
            margin-left: 5px;
            path {
              fill: ${props => props.theme.primaryTheme};
            }
          }
        }
        @media ${device.down.md} {
          margin-top: 20px;
        }
      }
      .disclaimer-text {
        font-family: var(--fontLight);
        font-size: 12.1px;
        line-height: 22px;
        color: ${props => props.theme.secondaryText};
        margin-top: 20px;
        a {
          text-decoration-line: underline;
          color: ${props => props.theme.primaryTheme};
          &:hover {
            color: ${props => props.theme.primaryButtonHover};
          }
        }
      }
      .buy-button {
        background: ${props => props.theme.primaryTheme};
        border-radius: 2px;
        font-weight: 600;
        font-size: 14px;
        color: var(--primaryCtaText);
        width: 100%;
        height: 42px;
        border: none;
        margin-top: 20px;
        cursor: pointer;
        &.disable {
          cursor: none;
          background: ${props => props.theme.primaryButtonDisable};
          pointer-events: none;
        }
        &:hover {
          background: ${props => props.theme.primaryButtonHover};
        }
        @media ${device.down.md} {
          margin-top: 30px;
        }
      }
      .payment-input {
        background: var(--inputBackground);
        border: 1px solid var(--inputBorderColor);
        border-radius: var(--inputBorderRadius);
        padding: 0 20px;
        cursor: pointer;
        height: 42px;
        font-size: 14px;
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg.transak-logo {
          margin-left: 13px;
        }
        svg.right-arrow {
          position: relative;
          right: -7px;
        }
        @media ${device.down.md} {
          padding: 0 15px;
          // svg.right-arrow {
          //   margin-right: -5px;
          // }
        }
      }
      input {
        text-align: left;
        padding: 10px 20px;
        height: 42px;

        font-size: 14px;
        line-height: 20px;
        color: var(--primaryText);
        background: var(--inputBackground);
        border: 1px solid var(--inputBorderColor);
        border-radius: var(--inputBorderRadius);
        margin-top: 7px;
        &:focus {
          border: solid 1px var(--primaryTheme);
        }
        @media ${device.down.md} {
          padding: 10px 15px;
        }
      }
      .fiat-currency-container {
        position: relative;
        .currency {
          position: absolute;
          top: -32px;
          right: 12px;

          font-size: 14px;
          line-height: 20px;
          color: var(--secondaryText);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            margin-left: 0;
          }
          @media ${device.down.md} {
            right: 9px;
          }
        }
      }
      .error-message {
        font-size: 12.1px;
        margin-top: 10px;
        font-weight: 300;
        color: var(--negative);
      }
      .vendor-fees {
        font-size: 12.1px;
        line-height: 18px;
        color: var(--secondaryText);
        margin-top: 10px;
        &.value {
          color: var(--primaryText);
          margin-left: 5px;
        }
      }
    }

    .select-coin-label {
      font-size: 12.1px;
      margin-bottom: 7px;
      color: ${props => props.theme.secondaryText};
      font-family: var(--fontRegular);
    }

    .memo-container {
      margin-top: 20px;
      position: relative;
      @media ${device.down.md} {
        margin-top: 0;
      }
      .memo-value {
        font-size: 14px;
      }
    }

    .address-label,
    .network-label,
    .memo-label {
      font-size: 12.1px;
      font-family: var(--fontRegular);
      margin-bottom: 7px;
      color: ${props => props.theme.secondaryText};
      display: inline-block;
    }

    .spacing {
      top: 1.5px;
    }

    svg.mandatory-tag {
      position: absolute;
      top: -10px;
      left: -13px;
      @media ${device.down.md} {
        left: -10px;
      }
    }

    .network-container {
      .loader {
        @media ${device.down.md} {
          position: relative;
          width: 100%;
          height: 100px;
          top: -12px;
          left: -7px;
        }
      }
    }

    .networks {
      display: flex;
      gap: 20px;
      @media ${device.down.sm} {
        flex-wrap: wrap;
        gap: 5px;
      }
    }

    .network-checkbox {
      background: var(--unselectedTab);
      border-radius: 2px;
      height: 42px;
      width: 135px;
      @media ${device.down.sm} {
        width: auto;
        flex-grow: 1;
        max-width: 120px;
      }
      display: flex;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      color: ${props => props.theme.primaryText};
      cursor: pointer;
      &.active {
        border: 1px solid ${props => props.theme.primaryTheme};
        // background: var(--primaryBackground);
      }
    }

    .disabled-strip {
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;
      color: ${props => props.theme.highlight};
      svg {
        width: 13px;
        height: 13px;
        margin-right: 6px;
        margin-top: -2px;
      }
      @media ${device.down.md} {
        font-size: 14px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .info-section {
      width: 305px;
      .bonus-banner {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .info-area .bonus-banner {
      width: 100%;
      margin-bottom: 25px;
    }

    .desktop-qr-section {
      display: flex;
      padding: 0 0 0 100px;
      flex-wrap: wrap;
      .address {
        margin-right: 100px;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        color: ${props => props.theme.primaryText};

        .wallet-address {
          font-size: 14px;
          @media ${device.up.md} and (${device.down.lg}) {
            overflow-wrap: anywhere;
          }
        }
      }
      @media ${device.up.md} and (max-width: 1200px) {
        padding: 0 0 0 60px;
        .address {
          margin-right: 50px;
        }
      }
    }

    .mobile-coin-dropdown-section {
      margin-bottom: 30px;
      width: 100%;
      .dropdown-label {
        position: relative;
        overflow: hidden;
        background-color: var(--inputBackground);
        border: 1px solid ${props => props.theme.separator};
        border-radius: var(--inputBorderRadius);
        box-sizing: border-box;
        color: ${props => props.theme.primaryText};
        font-family: var(--fontRegular);
        font-size: 14px;
        cursor: pointer;
        outline: none;
        padding: 10px 18px;
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease;
        svg {
          position: absolute;
          right: 17px;
          top: 20px;
        }
      }
    }

    .mobile-address-section {
      display: flex;
      flex-direction: column;
      // margin-bottom : 40px;
      width: 100%;
      .asset-label {
        font-size: 12.1px;
        color: ${props => props.theme.secondaryText};
        margin-bottom: 8px;
        font-family: var(--fontRegular);
      }

      .address,
      .memo-value {
        height: 42px;
        line-height: 40px;
        background-color: ${props => props.theme.secondaryBackground};
        font-family: var(--fontRegular);
        font-size: 12.1px;
        color: ${props => props.theme.primaryText};
        margin-bottom: 30px;
        padding: 10px 15px;
        display: flex;
        align-items: center;

        span.copy-icon {
          margin-left: 10px;
        }

        @media ${device.down.xs} {
          .address-value {
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .deposit-button {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .coming-soon-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    .title {
      font-size: 14px;
      line-height: 22px;
      color: var(--primaryText);
      margin-top: 40px;
      font-family: var(--fontSemiBold);
    }
    .content {
      font-size: 12.1px;
      line-height: 22px;
      color: var(--secondaryText);
      margin-top: 7px;
      text-align: center;
      font-family: var(--fontRegular);
    }
  }

  .deposit-pending-table {
    tr {
      td {
        text-align: center !important;
        justify-content: center !important;
      }
    }
  }

  .desktop-section-deposit {
    width: 100%;
    flex: 1;
  }
  //   .coin-col-values {
  //  .coin-col-value {
  //   padding-left:5px;
  //  }
  .coin-col-label {
    svg {
      margin-top: -5px;
    }
  }

  // This class not used anywhere
  .completed-withdrawal-history-section {
    text-align: center;
    height: 50px;
    margin-bottom: 27px;
    width: 100%;

    .completed-header {
      border-bottom: 1px solid ${props => props.theme.separator};
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -6px;
    }
  }

  .back-to-pending {
    height: 38px;
    background-color: ${props => props.theme.secondaryBackground};
    margin-top: -24px;
    display: inline-block;
    text-align: center;
    width: 100vw;
    position: relative;

    .back-to-deposit-button {
      font-size: 12.1px;
      font-family: var(--fontRegular);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: ${props => props.theme.primaryTheme};
      padding-top: 8px;
    }

    .col-24 {
      padding: 9px;
      padding-top: 8px;
      height: 25px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      font-style: normal;
      line-height: 1.5;
      color: ${props => props.theme.primaryTheme};
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      text-align: center;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      margin-left: -23%;
      svg {
        color: ${props => props.theme.primaryTheme};
        margin-top: 3px;
      }
      span {
        padding-left: 5px;
      }
    }
  }
  .information-box {
    width: calc(min(400px, 100%));
    color: ${props => props.theme.primaryText};
    background-color: #192c39;
    border: solid 0.6px #19627d;
    font-size: 14px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.6px;
    margin-top: 25px;
    margin-bottom: 10px;
    padding: 10px 0px 10px 0px;
    cursor: pointer;

    img {
      margin: 3px;
    }

    .info-utf8-icon {
      color: #0090bc;
      font-size: 14px;
    }

    &:hover {
      border: 0.8px solid ${props => props.theme.primaryTheme};
    }

    @media ${device.down.md} {
      font-size: 12.1px;
      margin-top: 7px;
    }
  }
  .Dropdown-root {
    position: relative;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    color: ${props => props.theme.primaryText};
    font-family: var(--fontRegular);
    font-size: 14px;
    cursor: default;
    outline: none;
    transition: all 200ms ease;
    padding: 10px 18px;
    @media ${device.down.md} {
      padding: 10px 15px;
    }
  }

  // Not used anywhere
  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu {
    background-color: ${props => props.theme.secondaryBackground};
    border: 0px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    color: rgb(51, 51, 51);
    color: rgba(51, 51, 51, 1);
    font-family: var(--fontRegular);
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    background-color: ${props => props.theme.secondaryBackground};
    color: ${props => props.theme.primaryText};
    cursor: pointer;
    display: block;
    padding: 8px 18px;
    border-bottom: 0.8px solid ${props => props.theme.separator};
    font-size: 14px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom: none;
  }

  .Dropdown-option:hover {
    color: ${props => props.theme.primaryTheme};
    svg {
      color: ${props => props.theme.primaryText};
    }
  }

  .Dropdown-option.is-selected {
    color: ${props => props.theme.primaryTheme};
    background-color: ${props => props.theme.secondaryBackground};
    &:hover {
      background: ${props => props.theme.tertiaryBackground};
    }
    svg {
      color: ${props => props.theme.primaryText};
    }
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }

  .qr-code-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;

    .qr-code-tabs {
      font-family: var(--fontRegular);
      font-size: 12.1px;
      color: ${props => props.theme.secondaryText};
      display: flex;
      border-bottom: solid 0.8px ${props => props.theme.separator};
      width: auto;
      .tab {
        padding-bottom: 7px;
        &:nth-of-type(2) {
          margin-left: 20px;
        }
        &.active {
          color: ${props => props.theme.primaryTheme};
          border-bottom: solid 2px ${props => props.theme.primaryTheme};
        }
      }
    }
    .steps-container {
      margin-top: 10px;
      ul li {
        list-style-type: none;
        position: relative;
        &::before {
          content: '';
          border-radius: 100%;
          width: 6px;
          height: 6px;
          background: var(--secondaryText);
          display: inline-block;
          position: absolute;
          left: -12px;
          top: 7px;
        }
      }
      .step-2 {
        margin-top: 30px;
      }
      .title {
        font-family: var(--fontRegular);
        font-size: 12.1px;
        line-height: 18px;
        span.highlight {
          color: var(--secondaryText);
          text-transform: uppercase;
        }
      }
    }
    .qr-code-container {
      border: 1px solid ${props => props.theme.secondaryBackground};
      border-radius: 4px;
      margin-top: 30px;
      padding: 20px;
      &.memo {
        margin: 20px auto 0;
        height: auto;
        width: 120px;
        padding: 10px;
      }
    }
    .qr-code {
      border: 4px solid ${props => props.theme.separator} !important;
    }
  }

  .Desktop-qr-section {
    .wallet-address {
      font-family: var(--fontRegular);
      line-height: 1.33;
      font-size: 18px;
      padding-right: 10px;
      color: ${props => props.theme.primaryText};
    }
  }

  .Mobile-qr-section {
    .sub-header {
      height: 18px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      line-height: 1.5;
      color: ${props => props.theme.secondaryText};
      margin-top: 24px;
    }

    .click-section {
      border-radius: 2px;
      background-color: ${props => props.theme.secondaryBackground};
      text-align: center;
      padding: 16px;
      margin-top: 32px;
    }
    .wallet-address {
      font-size: 14px;
      padding-right: 10px;
      color: ${props => props.theme.primaryText};
    }

    .vl {
      width: 1px;
      height: 40px;
      margin: 0 auto;
    }

    .vertical-line {
      margin: auto;
    }

    p {
      color: ${props => props.theme.primaryTheme};
    }
  }

  .pending-Deposits {
    margin-top: 25px;
  }

  .deposit-tips {
    margin-top: 40px;
    font-size: 14px;
    background-color: ${props => props.theme.secondaryBackground};
    padding: 16px;

    @media ${device.up.md} {
      width: 80%;
    }
    @media ${device.up.lg} {
      width: 100%;
      width: 550px;
    }

    .icon {
      margin-right: 16px;
    }

    .deposit-header {
      font-size: 16px;
      font-family: var(--fontRegular);
      line-height: 1.5;
      color: ${props => props.theme.primaryText};
      margin: auto 0;

      @media ${device.down.md} {
        font-size: 14px;
        font-family: var(--fontRegular);
        line-height: 1.43;
      }
    }

    .deposit-statement {
      margin-left: 38px;
      margin-top: 16px;
      padding: 0;

      p {
        width: 100%;
        opacity: 0.6;
        font-size: 14px;
        font-family: var(--fontRegular);
        line-height: 1.43;
        color: ${props => props.theme.primaryText};
        padding: 5px 0;

        @media ${device.down.md} {
          font-size: 12px;
          font-family: var(--fontRegular);
          line-height: 1.5;
          opacity: 0.4;
        }
      }

      @media ${device.up.lg} {
        width: 478px;
      }

      @media ${device.down.sm} {
        margin-left: 0;
      }
    }
  }

  .copy-icon {
    cursor: pointer;
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.primaryButtonHover};
        }
      }
    }

    position: relative;
    @media ${device.up.md} {
      margin-bottom: 0.25rem;
    }
  }

  .model-main-section {
    padding: 10px;
  }

  .horizontal-line {
    width: 100%;
    margin: 20px 0;
    opacity: 0.1;
    color: ${props => props.theme.primaryText};
  }
`;

export const ModelSection = styled(Modal)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: ${props => props.theme.secondaryBackground};

  .model-main-section {
    width: 296px;
    background-color: ${props => props.theme.primaryText};
    padding: 20px;
  }

  .modal-qr-code {
    padding: 0 50px 6px 50px;
  }

  .model-address-code {
    padding: 6px 0 10px 0;
    word-break: break-word;
    text-align: center;
  }

  .buttonSection {
    padding: 10px 0 8px 0;
  }

  .model-copy-button {
    width: 100%;
    background-color: ${props => props.theme.primaryText};
    color: ${props => props.theme.primaryTheme};
    border: 1px solid ${props => props.theme.primaryTheme};
    border-radius: 0px;
  }

  .model-download-button {
    border-radius: 0px;
    width: 100%;
  }

  .Close-icon {
    position: relative;
    top: 20px;
    margin: auto;
    border-radius: 50%;
    height: 32px;
    font-size: 18px;
    text-align: center;
    width: 32px;
  }

  &:focus {
    outline: none;
  }

  a {
    text-decoration: none;
  }
`;

export const CoinDropDownModal = styled(Modal)`
  width: 100% !important;
  height: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  top: unset !important;
  padding: 0 !important;
  position: fixed;
  background: ${props => props.theme.primaryBackground} !important;
  .modal-header {
    font-family: var(--fontRegular);
    font-size: 14px;
    color: ${props => props.theme.secondaryText};
    height: 45px;
    border-bottom: 0.8px solid ${props => props.theme.separator};
    display: flex;
    align-items: center;
    justify-content: center;
    .close-icon {
      position: absolute;
      right: 25px;
    }
  }
  .modal-body {
    font-family: var(--fontRegular);
    font-size: 14px;
    color: ${props => props.theme.primaryText};
    display: flex;
    flex-direction: column;
    .coin-symbol {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: var(--fontRegular);
      &:not(:last-of-type) {
        border-bottom: 0.8px solid ${props => props.theme.separator};
      }
      &.active {
        color: ${props => props.theme.primaryTheme};
      }
    }
  }
`;

export const QRCodeModal = styled(Modal)`
  width: 100% !important;
  height: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  top: unset !important;
  padding: 0 !important;
  position: fixed;

  .qr-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: ${props => props.theme.primaryBackground};
    padding: 52px 48px;
    .qr-code-tabs {
      font-family: var(--fontRegular);
      font-size: 14px;
      color: ${props => props.theme.secondaryText};
      display: flex;
      border-bottom: solid 0.8px ${props => props.theme.separator};
      width: auto;
      margin-bottom: 40px;
      padding: 0 10px;
      .tab {
        padding-bottom: 9px;
        &:nth-of-type(2) {
          margin-left: 20px;
        }
        &.active {
          color: ${props => props.theme.primaryTheme};
          border-bottom: solid 2px ${props => props.theme.primaryTheme};
        }
      }
    }
    .qr-code {
      border: 4px solid ${props => props.theme.separator} !important;
    }
  }

  .close-icon {
    position: absolute;
    bottom: 100px;

    @media ${device.down.md} {
      top: 17px;
      right: 25px;
      bottom: unset;
    }
  }
`;

export const LogList = styled.section`
  width: 100%;
  .checkicon {
    margin-bottom: 5px;
  }
`;

export const DetailSection = styled.div`
  margin-top: 8px;
  padding: 12px 16px 16px;
  word-break: break-all;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.primaryBackground};
  width: 100%;

  hr {
    border: none;
    border-bottom: 1px solid red;
    margin: 10px auto;
  }

  .time {
    font-size: 11px;
    text-align: right;
    color: ${props => props.theme.primaryText};
    line-height: 1.4;
  }

  .amountValue {
    height: 18px;
    font-size: 12.1px;
    font-family: var(--fontRegular);
    line-height: 1.5;
    color: ${props => props.theme.primaryText};
    text-align: right;

    span {
      font-size: 12.1px;
      line-height: 1.17;
      color: ${props => props.theme.tertiartText};
      padding-right: 5px;
    }
  }

  .detail-Section {
    font-size: 12.1px;
    line-height: 1.67;
    color: ${props => props.theme.secondaryText};
    display: flex;
    flex-direction: column;

    p {
      color: white;
    }

    a {
      color: ${props => props.theme.primaryTheme};
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .recived-from-label {
    padding-bottom: 5px;
  }
  .transaction-text {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .external-link-open {
    svg {
      margin-left: 5px;
      width: 12px;
      height: 12px;
    }
  }
  .header-row-completed-values {
    margin-left: 0px;
    .header-details {
      text-align: left;
      margin-left: -5px;
      .coin-info {
        .coin-icon-value {
          padding-left: 0px;
          svg {
            margin-top: -5px;
          }
        }
        .coin-symbol {
          padding-left: 5px;
        }
      }
      .tooltip-hover-info {
        padding-left: 4px;
      }
    }
    .coin-amount {
      text-align: right;
      .amount-label {
        width: 45px;
        height: 14px;
        opacity: 0.9;
        font-family: var(--fontRegular);
        font-size: 12.1px;
        line-height: 1.17;
        color: ${props => props.theme.secondaryText};
      }
      .amount-value {
        padding-left: 5px;
        width: 68px;
        height: 14px;
        font-size: 12.1px;
        font-family: var(--fontRegular);
        line-height: 1.17;
        text-align: right;
        color: white;
      }
    }
  }
  .left-align-row {
    margin-left: 2px;
  }
  .amount-symbol-value {
    margin-left: -15px;
    font-size: 14px;
    font-family: var(--fontRegular);
  }
  .mobile-coin-value {
    svg {
      width: 10px;
      height: 14px;
      margin-top: -2px;
    }
  }
`;

export const CopySpan = styled.span`
  color: ${props => props.theme.primaryText};
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: ${props => props.theme.primaryTheme} !important;
  }
`;

export const PopoverStyle = styled.div`
  padding: 2px;
  background: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.secondaryText};
  font-size: 0.8em;
  z-index: 100000001;
  display: flex;
  justify-content: center;
  width: 100px;
  margin: -28px 0px 5px -30px;
  border-radius: 4px;
  animation-name: showpopup;
  animation-duration: 1s;
  position: absolute;

  &.memo {
    top: -2px;
    left: 4px;
  }

  &.copied {
    top: 0px;
    left: 0px;
  }

  @keyframes showpopup {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.9;
    }
  }

  @media ${device.down.xs} {
    position: relative;
    left: 40%;
  }
`;

export const DepositButton = styled.div`
  height: 42px;
  line-height: 40px;
  border-radius: 2px;
  border: solid 0.8px ${props => props.theme.primaryTheme};
  font-family: var(--fontRegular);
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.primaryTheme};
  padding: 0px 29px 11px 24px;
  margin-right: 2px;

  &:hover {
    background-color: ${props => props.theme.secondaryCTAHover};
  }

  svg {
    margin-right: 2px;
  }

  &.qr-code {
    width: 100%;
  }

  @media ${device.down.xxs} {
    font-size: 12.1px;
  }
`;

export const SlippageTooltip = styled.div`
  background-color: var(--secondaryBackground);
  padding: 10px 15px;
  color: var(--secondaryText);
  font-family: var(--fontLight);
  max-width: 200px;
  font-size: 12.1px;
  line-height: 1.8;
`;
