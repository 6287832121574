import { ERROR_CODES, IS_INDIAN_EXCHANGE, MARGIN_TYPES } from 'constants/constants';
import { view, lensPath } from 'helpers/ramda';
import { resetTimeErrMsg } from 'helpers/utils';
import i18n from 'i18n/config';

const bodyLens = data => view(lensPath(['response', 'body']), data);

const userProfileFailureReason = error => {
  let message = ``;
  const { code, context = {} } = error;
  const resetTime = context.time ? Math.ceil(context.time / 60) : null;
  const attemptsLeft = context.max_attempts - context.count;
  const schemaError =
    Array.isArray(context.schema_errors) && context.schema_errors.length
      ? `${context.schema_errors[0].message} - ${context.schema_errors[0].param}`
      : '';
  switch (code) {
    case 'pending_verification':
      message = i18n.t('errors:user.pending_verification');
      break;
    case 'invalid_captcha':
      message = i18n.t('errors:user.invalid_captcha');
      break;
    case 'user_not_found':
      message = i18n.t('errors:user.user_not_found');
      break;
    case 'invalid_password':
      message = i18n.t('errors:user.invalid_password');
      break;
    case 'invalid_mfa_code':
      message = i18n.t('errors:user.invalid_mfa_code', { count: attemptsLeft });
      break;
    case 'invalid_otp':
      message = i18n.t('errors:user.invalid_otp', { count: attemptsLeft });
      break;
    case 'invalid_email_code':
    case 'invalid_email_otp':
      message = i18n.t('errors:user.invalid_email_code');
      break;
    case 'reset_password_blocked':
    case 'mfa_check_blocked':
      message = resetTimeErrMsg(resetTime);
      break;
    case 'invalid_pre_2fa_token':
      message = i18n.t('errors:user.invalid_pre_2fa_token');
      break;
    case 'invalid_email_format':
      message = i18n.t('errors:user.invalid_email_format');
      break;
    case 'mfa_not_active':
      message = i18n.t('errors:user.mfa_not_active');
      break;
    case 'login_not_found':
      message = i18n.t('errors:user.login_not_found');
      break;
    case 'kyc_user_update':
      message = i18n.t('errors:user.kyc_user_update');
      break;
    case 'kyc_pending_for_deto_commission':
      message = i18n.t('errors:user.kyc_pending_for_deto_commission');
      break;
    case 'ip_blocked':
      message = i18n.t('errors:user.ip_blocked');
      break;
    case 'bad_schema':
      message = schemaError;
      break;
    case 'nick_name_already_taken':
      message = i18n.t('errors:user.nick_name_already_taken');
      break;
    case 'invalid_nick_name_format':
      message = i18n.t('errors:user.invalid_nick_name_format');
      break;
    case 'country_update_not_allowed_for_kyc_in_progress':
      message = i18n.t('errors:user.countryUpdateNotAllowed');
      break;
    default:
      message = i18n.t('errors:user.defaultMessage');
  }
  return message;
};

const userSignupFailureReason = error => {
  // return message = null if you dont want to show the notification popup
  const { code, context = {} } = error;
  const errorObj = context.schema_errors ? context.schema_errors[0] : {};
  let title = i18n.t('errors:user.defaultMessage');
  let message;
  switch (code) {
    case 'ip_blocked':
      message = i18n.t('errors:user.ip_restricted');
      break;
    case 'ip_api_error':
      title = i18n.t('errors:user.sign_up_failed');
      message = i18n.t('errors:user.ip_api_error');
      break;
    case 'already_registered':
    case 'already_registered_verification_pending':
    case ERROR_CODES.BCRYPT_HASH_LIMIT:
      message = null;
      break;
    case 'bad_schema':
      message = errorObj.message || i18n.t('errors:user.defaultMessage');
      break;
    case 'invalid_captcha':
      message = i18n.t('errors:user.invalid_captcha');
      break;
    case 'blocked_domain':
      title = i18n.t('errors:user.signup_failed');
      message = i18n.t('errors:user.blocked_domain');
      break;
    default:
      message = i18n.t('errors:user.defaultMessage');
      break;
  }

  return {
    title,
    message,
  };
};
const subAccountFailureReason = (error, additionalContext) => {
  let message = ``;
  const { code, context } = error;

  switch (code) {
    case 'sub_account_found':
      message = i18n.t('errors:sub_account.sub_account_found');
      break;

    case 'sub_account_name_already_exist':
      message = i18n.t('errors:sub_account.sub_account_name_already_exist');
      break;
    case 'email_already_registered':
      message = i18n.t('errors:sub_account.email_already_registered');
      break;
    case 'password_without_email':
      message = i18n.t('errors:sub_account.password_without_email');
      break;

    case 'mfa_is_disabled':
      message = i18n.t('errors:sub_account.mfa_is_disabled');
      break;
    case 'kyc_not_approved':
      message = i18n.t('errors:sub_account.kyc_not_approved');
      break;

    case 'max_sub_account':
      if (
        additionalContext.margin_mode === MARGIN_TYPES.PORTFOLIO &&
        !IS_INDIAN_EXCHANGE
      ) {
        message = i18n.t('errors:sub_account.max_sub_account_portfolio', {
          value: context.limit,
          margin_mode: additionalContext.margin_mode,
          selectedCoin: additionalContext.selectedCoin,
        });
      } else {
        message = i18n.t('errors:sub_account.max_sub_account', {
          value: context.limit,
          margin_mode: additionalContext.margin_mode,
        });
      }
      break;
    case 'sub_account_not_connected':
      message = i18n.t('errors:sub_account.sub_account_not_connected');
      break;
    case 'transfer_not_allowed_from_sub_account_login':
      message = i18n.t('errors:sub_account.transfer_not_allowed_from_sub_account_login');
      break;
    case 'transferrer_and_transferee_is_same':
      message = i18n.t('errors:sub_account.transferrer_and_transferee_is_same');
      break;

    case 'insufficient_balance':
      message = i18n.t('errors:sub_account.insufficient_balance');
      break;
    case 'mfa_update_withdrawal_block':
      message = i18n.t('errors:sub_account.mfa_update_withdrawal_block');
      break;
    case 'password_update_withdrawal_block':
      message = i18n.t('errors:sub_account.password_update_withdrawal_block');
      break;
    case 'blocked_domain':
      message = i18n.t('errors:sub_account.blocked_domain');
      break;
    case ERROR_CODES.BCRYPT_HASH_LIMIT:
      message = i18n.t(`errors:sub_account.${ERROR_CODES.BCRYPT_HASH_LIMIT}`);
      break;
    default:
      message = i18n.t('errors:user.defaultMessage');
  }
  return message;
};
const parseSubAccountError = (error, additionalContext) => {
  const errorObj = bodyLens(error).error;
  return subAccountFailureReason(errorObj, additionalContext);
};
export {
  userProfileFailureReason,
  userSignupFailureReason,
  subAccountFailureReason,
  parseSubAccountError,
};
