import styled from 'styled-components';

import { device } from '../../helpers/screenSizes';

const AppWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.theme.primaryText};
  position: relative;
  box-sizing: border-box;

  .navlink-item {
    cursor: pointer;
    height: 100%;
    width: 100%;
    transition: background 0.5s;
    &:hover {
      color: ${props => props.theme.primaryTheme};
    }
  }

  #page-wrap {
    display: flex;
    flex-direction: column;

    @media screen and ${device.up.sm} {
      min-height: 100%;
    }
  }

  ${props =>
    props.isTestNet || props.showEnvironmentMessage
      ? `
      .bm-menu-wrap {
        @media ${device.down.xl} {
          top: 56px !important;
        }
        @media ${device.down.md} {
          top: 56px !important;
        }
        @media ${device.down.sm} {
          top: 56px !important;
        }
      }
    `
      : ``};

  // .troll-chat-box {
  //   @media ${device.down.md} {
  //     height: calc(
  //       100% - ${props => (props.showEnvironmentMessage ? '110px' : '75px')}
  //     );
  //   }
  // }
  main {
    position: auto;
    height: auto;
  }
  footer {
    position: relative;
  }
`;

export default AppWrapper;
