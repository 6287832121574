import React from 'react';
import styled from 'styled-components';

const ChevronRightStyle = styled.div`
  background: transparent;
  height: 16px;
  width: 40px;
  transform: rotate(45deg);

  .vertical {
    position: absolute;
    top: 16px;
    right: 17px;
    height: 12px;
    border-right: 2px solid ${props => props.theme.secondaryText};
  }

  .horizontal {
    position: absolute;
    top: 16px;
    right: 17px;
    width: 13px;
    border-top: 2px solid ${props => props.theme.secondaryText};
  }
`;

const ChevronRight = ({ onClick, className }) => (
  <ChevronRightStyle onClick={onClick} className={'close ' + className}>
    <div className="vertical" />
    <div className="horizontal" />
  </ChevronRightStyle>
);

export default ChevronRight;
