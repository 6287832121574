import React from 'react';

export const SortUp = props => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      {...props}
      data-palette="SortUp">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path
                d="M3.5 4L0 0 7 0z"
                transform="translate(-356.000000, -154.000000) translate(-6.000000, 150.000000) translate(362.333333, 4.000000) translate(3.500000, 2.000000) scale(1, -1) translate(-3.500000, -2.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>)
  );
};

export const SortDown = props => {
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="4"
      viewBox="0 0 8 4"
      {...props}
      data-palette="SortDown">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M3.5 4L0 0 7 0z"
                  transform="translate(-356.000000, -160.000000) translate(-6.000000, 150.000000) translate(362.333333, 4.000000) translate(0.000000, 6.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>)
  );
};
