const reset2faSteps = {
  INITIAL: 'initial',
  SCAN_QR: 'scanQr',
  BACKUP_KEY: 'backupKey',
  RESET_2FA: 'reset2fa',
  SUCCESS: 'success',
  CLOSED: 'closed',
};

export { reset2faSteps };
