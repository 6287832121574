import { useLayoutEffect, useState } from 'react';
const viewport = window.visualViewport;

function useMobileWindowScroll() {
  const [top, setTop] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setTop(viewport.offsetTop);
    }
    window.visualViewport.addEventListener('scroll', updateSize);
    updateSize();
    return () =>
      window.visualViewport.removeEventListener('scroll', updateSize);
  }, []);
  return top;
}
export default useMobileWindowScroll;
