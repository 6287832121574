import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import {
  ALPYNE_API,
  CHARTS_API,
  FUNDS,
  HOLDINGS,
  MARKETS_API,
  ORDERS,
  POSITIONS,
  PRODUCTS,
  STATS,
  TRADES_API,
  TRANSACTION_LOGS,
  TRANSAK_API,
  USER_PROFILE,
  WALLET_API,
} from 'constants/api';
import {
  HOLDINGS_TABLE_API_BASE,
  mobileDefault,
  PAGINATION_PERSIST_STATE_TABLES,
  TABLE_DATA_FETCH_COUNT,
} from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import {
  DownloadInvoiceRequestPayload,
  MailInvoiceRequestPayload,
} from 'containers/transaction_logs/invoices/Invoice.types';
import { getRowsPerPage } from 'helpers';
import createAction from 'helpers/createAction';
import { compose, mergeDeepRight, omit } from 'helpers/ramda';
import type {
  GetTopFuturesMarketDataArgs,
  GetTopOptionsCombosMarketDataArgs,
  GetTopOptionsMarketDataArgs,
  PriceAlertPayload,
} from 'types/actions/trade';
import { BasketOrderPayload } from 'types/IOpenPosition';
import { CustomAction } from 'types/Istore';
import type { GetUsdtRatesData, TradePreferences } from 'types/ITrade';
import type { Preferences as UserPreferences } from 'types/IUser';

import {
  allTickers,
  fiatCryptoConversionParams,
  filterHoldingsParams,
  getHoldingsFilterId,
  getTicker,
  transactionAction,
} from './helpers';
import { authAction } from './user';

// export const activateRestMode = createAction(
//   TRADE_CONSTANTS.ACTIVATE_REST_MODE
// );

export const changeSelectedProduct = createAction(TRADE_CONSTANTS.PRODUCT_SELECTED);

export const changePreviousSelectedProduct = createAction(
  TRADE_CONSTANTS.PREVIOUS_PRODUCT_SELECTED
);

export const changeContractType = createAction(TRADE_CONSTANTS.CHANGE_CONTRACT_TYPE);

export const changeMobileContractType = createAction(
  TRADE_CONSTANTS.CHANGE_MOBILE_CONTRACT_TYPE
);

export const changeHoldingsContractType = createAction(
  TRADE_CONSTANTS.CHANGE_HOLDINGS_CONTRACT_TYPE
);

export const changeHoldingsFilterType = createAction(
  TRADE_CONSTANTS.CHANGE_HOLDINGS_FILTER_TYPE
);

export const updateOrderbook = createAction(TRADE_CONSTANTS.UPDATE_ORDERBOOK);

export const updateRecentTrades = createAction(TRADE_CONSTANTS.UPDATE_RECENT_TRADES);

export const initiateTicker = createAction(TRADE_CONSTANTS.INITIATE_TICKER);

export const getRecentTrades = createAction(TRADE_CONSTANTS.RECENT_TRADES);

export const updateProduct = createAction(TRADE_CONSTANTS.UPDATE_PRODUCT);

export const changeSelectedAsset = createAction(TRADE_CONSTANTS.ASSET_SELECTED);

export function placeOrder(order, mixpanelData = {}) {
  return authAction({
    types: TRADE.ORDER,
    payload: order,
    mixpanelPayload: mixpanelData,
    promise: ({ request }) =>
      request.post(ORDERS.ORDERS, {
        data: order,
      }),
  });
}

export function estimateMargin(order, mixpanelData = {}) {
  return authAction({
    types: TRADE.ESTIMATE_MARGIN,
    payload: order,
    mixpanelPayload: mixpanelData,
    promise: ({ request }) =>
      request.post(ORDERS.ESTIMATE_MARGIN, {
        data: order,
      }),
  });
}

// close position is just placing an order with opposite side
export function closePosition(order, metaData = {}) {
  return authAction({
    types: TRADE.CLOSE_POSITION,
    payload: order,
    mixpanelPayload: metaData,
    promise: ({ request }) =>
      request.post(ORDERS.ORDERS, {
        data: order,
      }),
  });
}

export function getShareCard(payload, params) {
  return authAction({
    types:
      params?.template === 'closed_position'
        ? TRADE.GET_CLOSE_POSITION_SHARE_CARD
        : TRADE.SHARE_CARD,
    params,
    promise: ({ request }) =>
      request.post('v2/share_card', {
        data: payload,
      }),
  });
}

export function cancelOrder(order, metaData = {}) {
  return authAction({
    types: TRADE.ORDER_CANCEL,
    params: order,
    mixpanelPayload: metaData,
    promise: ({ request }) =>
      request.delete(ORDERS.ORDERS, {
        data: order,
      }),
  });
}

export function getOrdersHistory(payload = HOLDINGS_TABLE_API_BASE) {
  const productId = getHoldingsFilterId(
    payload,
    PAGINATION_PERSIST_STATE_TABLES.ORDERS_HISTORY
  );

  return authAction({
    types: TRADE.ORDERS_HISTORY,
    params: {
      product_id: productId,
      backgroundFetch: payload.backgroundFetch,
    },
    promise: ({ request }) =>
      request.get(HOLDINGS.GET_ORDER_HISTORY, {
        params: filterHoldingsParams(payload, productId),
        keepHeaders: true,
      }),
  });
}

export function getFills(payload = HOLDINGS_TABLE_API_BASE) {
  const productId = getHoldingsFilterId(payload, PAGINATION_PERSIST_STATE_TABLES.FILLS);
  return authAction({
    types: TRADE.FILLS,
    params: {
      product_id: productId,
      backgroundFetch: payload.backgroundFetch,
    },
    promise: ({ request }) =>
      request.get(HOLDINGS.GET_FILLS_HISTORY, {
        params: filterHoldingsParams(payload, productId),
        keepHeaders: true,
      }),
  });
}

export function getPositionsFromApi() {
  return authAction({
    types: TRADE.POSITIONS,
    promise: ({ request }) => request.get(HOLDINGS.GET_POSITIONS),
  });
}

// not using as rest mode not required now
export function getOpenStopOrdersFromApi({
  backgroundFetch,
  ...payload
} = HOLDINGS_TABLE_API_BASE) {
  const productId = getHoldingsFilterId(
    payload,
    PAGINATION_PERSIST_STATE_TABLES.STOP_ORDERS
  );

  return authAction({
    types: TRADE.OPEN_STOP_ORDERS,
    params: {
      product_id: productId,
      backgroundFetch,
    },
    promise: ({ request }) =>
      request.get('/v2/orders', {
        params: {
          states: 'pending',
          ...filterHoldingsParams(payload, productId),
        },
        keepHeaders: true,
      }),
  });
}

// not required as rest mode not required now
export function getOpenOrdersFromApi(payload = HOLDINGS_TABLE_API_BASE) {
  const productId = getHoldingsFilterId(
    payload,
    PAGINATION_PERSIST_STATE_TABLES.OPEN_ORDERS
  );

  return authAction({
    types: TRADE.OPEN_ORDERS,
    params: {
      product_id: productId,
      backgroundFetch: payload.backgroundFetch,
    },
    promise: ({ request }) =>
      request.get('/v2/orders', {
        params: { states: 'open', ...filterHoldingsParams(payload, productId) },
        keepHeaders: true,
      }),
  });
}

export function getOpenOrders(payload: any) {
  return {
    type: TRADE_CONSTANTS.OPEN_ORDERS,
    result: payload,
  };
}

export function placeorderClicked(payload: any) {
  return {
    type: TRADE_CONSTANTS.PLACEORDER_CLICKED,
    payload,
  };
}

export function closePositionClicked(payload: any) {
  return {
    type: TRADE_CONSTANTS.CLOSE_POSITION_CLICKED,
    payload,
  };
}

export function getOpenStopOrders(payload: any) {
  return {
    type: TRADE_CONSTANTS.OPEN_STOP_ORDERS,
    result: payload,
  };
}

export function updateOpenOrders(payload: any) {
  return {
    type: TRADE_CONSTANTS.UPDATE_OPEN_ORDERS,
    result: payload,
  };
}

export function updateStopOrders(payload: any) {
  return {
    type: TRADE_CONSTANTS.UPDATE_STOP_ORDERS,
    result: payload,
  };
}

export function getPositions(payload) {
  return {
    type: TRADE_CONSTANTS.OPEN_POSITIONS,
    result: payload,
  };
}

export function updatePosition(payload: any) {
  return {
    type: TRADE_CONSTANTS.UPDATE_POSITIONS,
    result: payload,
  };
}

export function updateBracketOrder(payload: any) {
  return {
    type: TRADE_CONSTANTS.UPDATE_BRACKET_ORDER,
    result: payload,
  };
}

export function getUniversalSearchProds(payload: string): CustomAction {
  return {
    types: TRADE.UNIVERSAL_SEARCH,
    promise: ({ request }) =>
      request.get(`${PRODUCTS.GET_UNIVERSAL_SEARCH_PRODUCTS}/${payload}`),
  };
}

export const getRecentSearchedProducts = (payload: { user_id: number }) => {
  return authAction({
    types: TRADE.RECENT_PRODUCT_SEARCHES,
    promise: ({ request }) =>
      request.get(PRODUCTS.GET_RECENT_SEARCHES, {
        params: payload,
      }),
  });
};

export const saveProductSearches = (payload: {
  user_id: number;
  product_symbol: string;
}) => {
  return authAction({
    types: TRADE.SAVE_PRODUCT_SEARCHES,
    promise: ({ request }) =>
      request.post(PRODUCTS.SAVE_LAST_PRODUCT_SEARCH, {
        data: payload,
      }),
  });
};

export function getAssetList() {
  return {
    types: TRADE.ASSETS,
    promise: ({ request }) => request.get(PRODUCTS.GET_ASSETS),
  };
}

export function getDepositAssetList() {
  return authAction({
    types: TRADE.DEPOSIT_ASSETS,
    promise: ({ request }) => request.get(PRODUCTS.GET_DEPOSIT_ASSETS),
  });
}

export function getProductList() {
  return {
    types: TRADE.PRODUCTS,
    promise: ({ request }) => request.get(PRODUCTS.GET_PRODUCTS),
  };
}

export function getSpotIndices() {
  return {
    types: TRADE.SPOT_INDICES,
    promise: ({ request }) => request.get(PRODUCTS.GET_SPOT_INDICES),
  };
}

export function getFundsList() {
  return {
    types: TRADE.FUNDS,
    promise: ({ request }) => request.get(FUNDS.GET_FUNDS),
  };
}

export function getExpiredProducts(payload) {
  const params = { states: 'expired', ...payload };
  return {
    types: TRADE.EXPIRED_PRODUCTS,
    promise: ({ request }) =>
      request.get(PRODUCTS.GET_PRODUCT, {
        params,
      }),
  };
}

export function getProduct(symbol) {
  return {
    types: TRADE.GET_PRODUCT,
    promise: ({ request }) => request.get(`${PRODUCTS.GET_PRODUCT}/${symbol}`),
  };
}

export const get24HrTicker = getTicker(TRADE.TICKER);
export const getAllTickers = allTickers(TRADE.ALL_TICKERS);

export const getFiatCurrencies = () => {
  return {
    types: TRADE.GET_FIAT_CURRENCIES,
    promise: ({ request }) => request.get(TRANSAK_API.GET_FIAT_COINS),
  };
};

export const getFiatDepositAssets = () => {
  return {
    types: TRADE.GET_FIAT_DEPOSIT_ASSETS,
    promise: ({ request }) => request.get(TRANSAK_API.GET_FIAT_DEPOSIT_ASSETS),
  };
};

export const getCryptoFiatConversion = payload => {
  return {
    types: TRADE.GET_CRYPTO_FIAT_CONVERSION,
    promise: ({ request }) =>
      request.get(TRANSAK_API.GET_FIAT_CRYPTO_CONVERSION, {
        params: fiatCryptoConversionParams(payload),
      }),
  };
};

export function getL2Orderbook(productId) {
  return {
    types: TRADE.L2ORDERBOOK,
    promise: ({ request }) => request.get(`v2/l2orderbook/${productId}`),
  };
}

export const pollOptionsTicker = createAction(TRADE_CONSTANTS.POLL_OPTIONS_TICKER);

export const killTickerTimer = createAction(TRADE_CONSTANTS.KILL_TICKER_TIMER);
export const pollAllOpenPositionTicker = createAction(
  TRADE_CONSTANTS.POLL_ALL_OPEN_POSITION_TICKER
);
export const killAllOpenPositionTickerTimer = createAction(
  TRADE_CONSTANTS.KILL_ALL_OPEN_POSITION_TICKER_TIMER
);
export const pollProductTicker = createAction(TRADE_CONSTANTS.POLL_PRODUCT_TICKER);
export const killProductTickerTimer = createAction(
  TRADE_CONSTANTS.KILL_PRODUCT_TICKER_TIMER
);

// export function getL2Orderbook(product_id) {
//   return {
//     types: TRADE.L2ORDERBOOK,
//     promise: ({ request }) => request.get(`v2/l2orderbook/${product_id}`),
//     // promise: ({ request }) => request.get(`/orderbook/${product_id}/l2`),
//   };
// }

// export function getRecentTrades(product_id) {
//   return {
//     types: TRADE.RECENT_TRADES,
//     promise: ({ request }) => request.get(`v2/trades/${product_id}`),
//     // promise: ({ request }) => request.get(`/orderbook/${product_id}/l2`),
//   };
// }

export const getTransactions = (
  // eslint-disable-next-line default-param-last
  payload = { page_size: TABLE_DATA_FETCH_COUNT },
  params
) => {
  return transactionAction(
    TRADE.TRANSACTIONS,
    {
      currency: params.currency,
    },
    payload
  );
};

export const getAssetHistory = (
  payload = {
    page_size: TABLE_DATA_FETCH_COUNT,
    page_num: 1,
    backgroundFetch: false,
  }
) => {
  return transactionAction(
    TRADE.ASSET_HISTORY,
    {
      backgroundFetch: payload.backgroundFetch,
    },
    payload
  );
};

export const downloadTransactions = (payload: { asset_ids: string }) => {
  return authAction({
    promise: ({ request }) =>
      request.get(TRANSACTION_LOGS.DOWNLOAD_WALLET_TRANSACTIONS, {
        params: payload,
      }),
  });
};

export const downloadOrderHistory = payload => {
  return authAction({
    promise: ({ request }) =>
      request.get(TRANSACTION_LOGS.DOWNLOAD_ORDER_HISTORY, {
        params: payload,
      }),
  });
};

export const downloadTradeHistory = payload => {
  return authAction({
    promise: ({ request }) =>
      request.get(TRANSACTION_LOGS.DOWNLOAD_FILLS_HISTORY, {
        params: payload,
      }),
  });
};

export function onWithdrawalSubmit(payload: {
  release_trading_credits: boolean;
  amount: string | number;
  address: string;
  asset_symbol: string;
  otp: number;
  network: string;
  memo?: string;
  mfa_code?: string;
  uid: string;
}) {
  return authAction({
    types: TRADE.WITHDRAWAL_DATA,
    payload,
    promise: ({ request }) =>
      request.post(WALLET_API.SUBMIT_WITHDRAWALS, {
        data: payload,
      }),
  });
}

export function getEmailOtpForWithdrawal(payload: {
  amount: string;
  address: string;
  asset_symbol: string;
}) {
  return authAction({
    types: TRADE.GET_WITHDRAWAL_OTP,
    promise: ({ request }) =>
      request.post(WALLET_API.WITHDRAWAL_EMAIL_OTP, {
        data: payload,
      }),
  });
}

export function cancelWithdrawal(withdrawalId) {
  return authAction({
    types: TRADE.CANCEL_WITHDRAWAL,
    promise: ({ request }) =>
      request.post(WALLET_API.WITHDRAWAL_CANCEL, {
        data: {
          withdrawal_id: withdrawalId,
        },
      }),
  });
}

export function getPendingWithdrawalData(payload) {
  return authAction({
    types: TRADE.PENDING_WITHDRAWAL_DATA,
    promise: ({ request }) =>
      request.get(WALLET_API.WITHDRAWALS, {
        params: payload,
      }),
  });
}

export function getTradeData(payload) {
  return {
    type: TRADE_CONSTANTS.LOAD_TRADE_PRODUCTS,
    payload,
  };
}

// This is not being used anymore.
// export function getWithdrawalData() {
//   return authAction({
//     types: TRADE.PENDING_WITHDRAWAL_DATA,
//     promise: ({ request }) => request.get(WALLET_API.WITHDRAWALS),
//   });
// }

export function getWithdrawalLimitData(assetSymbol = 'BTC') {
  return authAction({
    types: TRADE.WITHDRAWAL_LIMITS,
    promise: ({ request }) =>
      request.get(WALLET_API.WITHDRAWAL_LIMITS, {
        params: { asset_symbol: assetSymbol },
      }),
  });
}

export function getWithdrawalTradingCredits() {
  return authAction({
    types: TRADE.WITHDRAWAL_TRADING_CREDITS,
    promise: ({ request }) => request.get(WALLET_API.WITHDRAWAL_TRADING_CREDITS),
  });
}

export function tradingCreditsForfeit() {
  return authAction({
    types: TRADE.WITHDRAWAL_TRADING_CREDITS_FORFEIT,
    promise: ({ request }) => request.post(WALLET_API.WITHDRAWAL_TRADING_CREDITS_FORFEIT),
  });
}

// export function showWithdrawalHistory(showHistory) {
//   return {
//     type: TRADE_CONSTANTS.SHOW_WITHDRAWAL_HISTORY,
//     payload: showHistory,
//   };
// }

// export function showDepositHistory(showHistory) {
//   return {
//     type: TRADE_CONSTANTS.SHOW_DEPOSIT_HISTORY,
//     payload: showHistory,
//   };
// }

export function getSparklineData(productNames) {
  return {
    types: TRADE.SPARKLINES,
    promise: ({ request }) =>
      request.get(`/chart/sparklines`, {
        params: { symbols: productNames },
      }),
  };
}

export const getChartHistory = ({ symbol, type, resolution = 15, from, to }) => ({
  types: TRADE.CHART_HISTORY,
  params: {
    symbol,
    type,
  },
  promise: ({ request }) =>
    request.get(CHARTS_API.HISTORY, {
      params: {
        symbol,
        from,
        to,
        resolution,
      },
    }),
});

export const getStats = () => ({
  types: TRADE.GET_STATISTICS,
  promise: ({ request }) => request.get(STATS),
});

export function placeBracketOrder(data) {
  return authAction({
    types: TRADE.BRACKET_ORDER,
    payload: data,
    promise: ({ request }) =>
      request.post(ORDERS.BRACKET, {
        data,
      }),
  });
}

export function deleteBracketOrder(payload: any) {
  return {
    type: TRADE_CONSTANTS.DELETE_BRACKET_ORDER,
    result: payload,
  };
}
export function editBracketOrder(data, metaData = {}) {
  return authAction({
    types: TRADE.EDIT_BRACKET_ORDER,
    params: data,
    mixpanelPayload: metaData,
    promise: ({ request }) =>
      request.put(ORDERS.ORDERS, {
        data,
      }),
  });
}

export function updateBracketOrderFromOpenOrder(data, metaData = {}) {
  return authAction({
    types: TRADE.UPDATE_BRACKET_ORDER_FROM_OPEN_ORDER,
    params: data,
    mixpanelPayload: metaData,
    promise: ({ request }) =>
      request.put(ORDERS.BRACKET, {
        data,
      }),
  });
}

export function cancelBracketOrder(data) {
  return authAction({
    types: TRADE.CANCEL_BRACKET_ORDER,
    promise: ({ request }) =>
      request.delete(ORDERS.ORDERS, {
        data,
      }),
  });
}

export function cancelBracketOrderPosition(data) {
  return authAction({
    types: TRADE.CANCEL_BRACKET_ORDER_POSITION,
    promise: ({ request }) =>
      request.delete(ORDERS.ORDERS, {
        data,
      }),
  });
}
export function editBracketOrderPosition(data) {
  return authAction({
    types: TRADE.EDIT_BRACKET_ORDER_POSITION,
    params: data,
    promise: ({ request }) =>
      request.put(ORDERS.ORDERS, {
        data,
      }),
  });
}

export function placeBracketOrderPosition(data) {
  return authAction({
    types: TRADE.PLACE_BRACKET_ORDER_POSITION,
    payload: data,
    promise: ({ request }) =>
      request.post(ORDERS.BRACKET, {
        data,
      }),
  });
}

export function positionBracketOrderMessage(data) {
  return {
    type: TRADE.POSITION_BRACKET_ORDER_MESSAGE,
    data,
  };
}

export function positionBracketOrderErrorMessage(data) {
  return {
    type: TRADE.POSITION_BRACKET_ORDER_ERROR_MESSAGE,
    data,
  };
}

export function cancelAllOrder(data) {
  const { product_id: productId, tableName } = data;
  return authAction({
    types: TRADE.CANCEL_ALL_ORDER,
    params: {
      productId,
      tableName,
    },
    promise: ({ request }) =>
      request.delete(ORDERS.CLOSE_ALL, {
        data: omit(['tableName'], { ...data }),
      }),
  });
}

export function updatePositionAutoTopup(data: {
  auto_topup: boolean;
  product_id: number;
}) {
  return authAction({
    types: TRADE.POSITION_AUTO_TOPUP,
    promise: ({ request }) => request.put(POSITIONS.AUTO_TOPUP, { data }),
  });
}

export function getTradePreferences() {
  return authAction({
    types: TRADE.TRADE_PREFERENCES,
    promise: ({ request }) => request.get(USER_PROFILE.GET_TRADE_PREFERENCES),
  });
}

export function updatePriceAlertPreference(payload: PriceAlertPayload) {
  return authAction({
    types: TRADE.UPDATE_PRICE_ALERT_PREFERENCE,
    payload,
    promise: ({ request }) =>
      request.put(USER_PROFILE.UPDATE_PRICE_ALERT_PREFERENCE, payload),
  });
}

export function updateTradePreference(data: TradePreferences | UserPreferences) {
  return authAction({
    types: TRADE.UPDATE_TRADE_PREFERENCE,
    promise: ({ request }) =>
      request.put(USER_PROFILE.UPDATE_TRADE_PREFERENCES, { data }),
  });
}

export function updateMarginMode(data: {
  margin_mode: MARGIN_MODE;
  showBottomNotif?: boolean;
  showChangeNotif?: boolean;
  user_id: number;
}) {
  const dataObj = {
    margin_mode: data.margin_mode,
    subaccount_user_id: data.user_id,
  } as Record<string, any>;
  return authAction({
    types: data.showBottomNotif
      ? TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION
      : TRADE.UPDATE_MARGIN_MODE,
    params: {
      showChangeNotif: data.showChangeNotif,
      margin_mode: data.margin_mode,
    },
    fetch_main_acc_token: true,
    promise: ({ request }) =>
      request.put(USER_PROFILE.UPDATE_PORTFOLIO_MARGIN, {
        data: dataObj,
      }),
  });
}

// This has to be deprecated
export function updatePortfolioMarginMode(data: {
  margin_mode: MARGIN_MODE;
  index_symbol: string;
  showBottomNotif?: boolean;
  showChangeNotif?: boolean;
  user_id?: string;
}) {
  return authAction({
    types: data.showBottomNotif
      ? TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION
      : TRADE.UPDATE_PORTFOLIO_MARGIN,
    params: {
      showChangeNotif: data.showChangeNotif,
    },
    promise: ({ request }) =>
      request.put(USER_PROFILE.UPDATE_PORTFOLIO_MARGIN, {
        data: {
          margin_mode: data.margin_mode,
          portfolio_index_symbol: data.index_symbol,
          user_id: data.user_id,
        },
      }),
  });
}

export function resetAssetHistory() {
  return {
    type: TRADE_CONSTANTS.RESET_ASSET_HISTORY,
    payload: [],
  };
}

export function closeAllPositions(data: {
  close_all_portfolio?: boolean;
  close_all_isolated?: boolean;
  close_all_cross?: boolean;
  product_ids?: number[];
}) {
  return authAction({
    types: TRADE.CLOSE_ALL_POSITION,
    promise: ({ request }) => request.post(POSITIONS.CLOSE_ALL, { data }),
  });
}

export function closePositionBasketOrder(data: BasketOrderPayload) {
  return authAction({
    types: TRADE.CLOSE_POSITION_BASKET,
    promise: ({ request }) => request.post(ORDERS.BASKET, { data }),
  });
}

export const getAlpyneSignature = () => {
  return {
    types: TRADE.GET_ALPYNE_SIGNATURE,
    promise: ({ request }) => request.get(ALPYNE_API.GET_SIGNATURE),
  };
};

export const getAlpyneUsdtRates = (data: GetUsdtRatesData) => {
  return authAction({
    types: TRADE.GET_ALPYNE_USDT_QUOTE,
    promise: ({ request }) => request.post(ALPYNE_API.GET_USDT_QUOTE, data),
  });
};

// API gives last 500 options trades for given assetSymbols
const getOptionsRecentTrades = (assetSymbols: string[]) => {
  return {
    type: TRADE.GET_OPTIONS_RECENT_TRADES,
    promise: ({ request }) =>
      request.get(
        `${TRADES_API.GET_OPTIONS_RECENT_TRADES}?underlying_asset=${assetSymbols.join(
          ','
        )}`
      ),
  };
};

const getTopOptionsMarketsData = (payload: GetTopOptionsMarketDataArgs) => {
  return {
    types: TRADE.GET_TOP_OPTIONS_MARKETS_DATA,
    promise: ({ request }) => request.get(MARKETS_API.OPTIONS, payload),
  };
};

const getTopFuturesMarketsData = (payload: GetTopFuturesMarketDataArgs) => {
  return {
    types: TRADE.GET_TOP_FUTURES_MARKETS_DATA,
    promise: ({ request }) => request.get(MARKETS_API.FUTURES, payload),
  };
};

const getTopOptionsCombosMarketData = (payload: GetTopOptionsCombosMarketDataArgs) => {
  return {
    types: TRADE.GET_TOP_OPTIONS_COMBOS_MARKETS_DATA,
    promise: ({ request }) => request.get(MARKETS_API.OPTIONS_COMBOS, payload),
  };
};

const downloadInvoice = (payload: DownloadInvoiceRequestPayload) => {
  return authAction({
    promise: ({ request }) =>
      request.post(TRANSACTION_LOGS.DOWNLOAD_INVOICE, {
        data: payload,
      }),
  });
};

const mailInvoice = (payload: MailInvoiceRequestPayload) => {
  return authAction({
    promise: ({ request }) =>
      request.post(TRANSACTION_LOGS.EMAIL_INVOICE, {
        data: payload,
      }),
  });
};

const resetFiatDepositAssets = () => {
  return { type: TRADE_CONSTANTS.RESET_FIAT_DEPOSIT_ASSETS };
};

const getParams = (obj = {}) => mergeDeepRight(HOLDINGS_TABLE_API_BASE, obj);

export const withHoldingFilters = actionFn => () => (dispatch, getState) => {
  const { holdingsTableFilter, holdings, trade } = getState();
  const { selectedContractType, holdingsFilterType } = holdings;
  let params;
  let page_size;
  if (actionFn === getFills) {
    page_size = getRowsPerPage('fills');
  } else if (actionFn === getOrdersHistory) {
    page_size = getRowsPerPage('ordersHistory');
  }

  if (holdingsFilterType === 'selected') {
    params = getParams({
      holdingsTableFilter,
      product_ids: trade.selected_product.id.toString(),
      page_size,
    });
  } else {
    switch (selectedContractType) {
      case 'all':
        params = getParams({
          page_size,
        });
        break;
      case 'futures':
        params = getParams({
          contract_types: 'futures,perpetual_futures',
          page_size,
        });
        break;
      case 'options':
        params = getParams({
          contract_types:
            'move_options,put_options,call_options,turbo_put_options,turbo_call_options,options_combos',
          page_size,
        });
        break;
      // case 'turbo_options':
      //   params = getParams({
      //     contract_types: 'turbo_put_options,turbo_call_options',
      //     page_size,
      //   });
      //   break;
      case 'spreads':
        params = getParams({
          contract_types: 'spreads,interest_rate_swaps',
          page_size,
        });
        break;
      default:
        params = getParams({ contract_types: selectedContractType, page_size });
        break;
    }
  }

  return compose(dispatch, actionFn)(params);
};

export const contractTypeFilter = actionFn => () => (dispatch, getState) => {
  const { holdings } = getState();
  const contractType = (() => {
    switch (holdings.mobileHoldingsContractType) {
      case 'all':
        return '';
      case 'futures':
        return 'futures,perpetual_futures';
      case 'options':
        return 'move_options,put_options,call_options,turbo_call_options,turbo_put_options,options_combos';
      case 'spreads':
        return 'spreads,interest_rate_swaps';
      // case 'turbo_options':
      //   return 'turbo_call_options,turbo_put_options';
      default:
        return holdings.mobileHoldingsContractType;
    }
  })();
  const params = {
    ...mobileDefault,
    contract_types: contractType,
  };

  return compose(dispatch, actionFn)(params);
};

export const withoutContractType = actionFn => dispatch => {
  const params = {
    ...HOLDINGS_TABLE_API_BASE,
  };
  if (actionFn === getFills) {
    params.page_size = getRowsPerPage('transaction-log-trade-history');
  } else if (actionFn === getOrdersHistory) {
    params.page_size = getRowsPerPage('transaction-log-order-history');
  }
  return compose(dispatch, actionFn)(params);
};

export const withSelectedProductFilterType = actionFn => () => (dispatch, getState) => {
  const { trade } = getState();
  const { selected_product } = trade;
  const { id } = selected_product;
  const params = {
    ...HOLDINGS_TABLE_API_BASE,
    product_ids: id.toString(),
  };
  return compose(dispatch, actionFn)(params);
};

export {
  getOptionsRecentTrades,
  getTopFuturesMarketsData,
  getTopOptionsCombosMarketData,
  getTopOptionsMarketsData,
  resetFiatDepositAssets,
  downloadInvoice,
  mailInvoice,
};
