/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { useState } from 'react';

import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { pure } from 'recompose';

import { isAuthenticated } from 'actions/user';
import { NewRedirectIcon } from 'components/customicons/holdings';
import { ConditionalRender } from 'components/markets/helpers';
import Leverage from 'components/markets/leverage';
import Render from 'components/render';
import {
  settling_type,
  contractLabel,
  IS_RN_WEBVIEW,
  IS_INDIAN_EXCHANGE,
} from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import Popover from 'containers/Popover';
import { dateFormat, parseFormat } from 'helpers/day';
import { isNil } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import {
  isOptionsCombosSymbol,
  convertExponentialToDecimal,
  getProductSymbol,
  getProductUrlByContractType,
  preLoginFavoriteClick,
  isSpotContract,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import { lastPriceSelector } from 'selectors/tickerSelectors';
import { FilterArrow, FavoriteSelected } from 'styles/assets/icons';
import { IconButton } from 'UIKit';

import styles from '../index.module.scss';
import { AssetDropdownWrapper } from '../style';

export const getContractDescription = ({
  contract_type,
  settlement_time,
  underlying_asset,
  symbol,
  contract_unit_currency,
  short_description,
  initial_margin,
  description,
  quoting_asset,
}) => {
  const settling_date =
    !isNil(settlement_time) && dateFormat(settlement_time, TIME_FORMATS.MMM_YYYY);
  const maxValue = 100 / initial_margin;

  switch (contract_type) {
    case 'futures': {
      const contractSpecs = ` ${underlying_asset.symbol}  ${settling_date} `;
      return (<>
        {`${contractLabel[contract_type]}   `}|{`   ${contractSpecs}`}|{'  '}
        <span
          style={{ color: 'var(--primaryTheme)' }}
          data-palette="getContractDescription">{`${maxValue}x`}</span>
      </>);
    }
    case 'perpetual_futures': {
      const contractSpecs = ` ${underlying_asset.symbol} `;
      return (<>
        {`${contractLabel[contract_type]}   `}|{`   ${contractSpecs}`}|{'  '}
        <span
          style={{ color: 'var(--primaryTheme)' }}
          data-palette="getContractDescription">{`${maxValue}x`}</span>
      </>);
    }
    case 'call_options':
    case 'put_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
      const contractSpecs = ` ${underlying_asset.symbol} ${settling_type(symbol)} `;

      return (<>
        {`${contractLabel[contract_type]}   `}|{`   ${contractSpecs}`}|{'  '}
        <span
          style={{ color: 'var(--primaryTheme)' }}
          data-palette="getContractDescription">{`${maxValue}x`}</span>
      </>);

    case 'interest_rate_swaps':
      return (<>
        {`${contractLabel[contract_type]}   `}|{`   ${short_description}`}|{'  '}
        <span
          style={{ color: 'var(--primaryTheme)' }}
          data-palette="getContractDescription">{`${maxValue}x`}</span>
      </>);

    case 'spreads':
      return (<>
        {' '}
        {`${short_description}   `}|{'  '}
        <span
          style={{ color: 'var(--primaryTheme)' }}
          data-palette="getContractDescription">{`${maxValue}x`}</span>
      </>);

    case 'spot':
      return (
        <>
          {' '}
          {underlying_asset.symbol}-{quoting_asset.symbol} spot market
        </>
      );
    default:
      return '';
  }
};

export const ThreeDotsPopover = ({ popoverBody, popoverBase }) => {
  return (
    (<Popover
      shouldDisablePortal
      body={
        <div className={styles[`item-specs`]} data-palette="ThreeDotsPopover">
          <p>{popoverBody}</p>
        </div>
      }
    >
      <div data-testid="description" aria-hidden="true" className={styles[`item-specs`]}>
        <p>{popoverBase}</p>
      </div>
    </Popover>)
  );
};

export const Description = ({ description }) => {
  const shortDescription =
    description.length > 35 ? `${description.slice(0, 18)}...` : description;

  if (shortDescription.includes('...')) {
    return <ThreeDotsPopover popoverBase={shortDescription} popoverBody={description} />;
  }
  return (
    (<div
      data-testid="description"
      aria-hidden="true"
      className={styles[`item-specs`]}
      data-palette="Description">
      <p>{description}</p>
    </div>)
  );
};

export const Contract = props => {
  const { product, favorites, handleFavClick, showFavIcon } = props;
  const { t } = useTranslation(['trading']);
  const isLoggedin = useSelector(({ user }) => isAuthenticated(user));
  const symbol = getProductSymbol(product);
  const description = product.short_description ?? '';
  const isFavorite = favorites.some(pid => pid === product.id);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function splitAndColorizeSpreadsSymbol(symbol) {
    const parts = symbol.split('-');

    return parts.map((part, index) => {
      if (isMobile && index === 4) {
        const parsedExpiryTime = parseFormat(symbol.split('-')[4], TIME_FORMATS.DDMMYY);
        const formattedExpiry = dateFormat(parsedExpiryTime, TIME_FORMATS.DD_MMM_YY);

        return (
          (<p
            className={styles.spreadsExpiryDate}
            key={index}
            data-palette="splitAndColorizeSpreadsSymbol">Exp.{' '}
            <span
              className={cn({
                positive: index === 2,
                negative: index === 3,
              })}
            >
              {formattedExpiry}
            </span>
          </p>)
        );
      }

      return (
        (<span key={index} data-palette="splitAndColorizeSpreadsSymbol">
          {index > 0 ? '-' : ''}
          <span
            className={cn({
              positive: index === 2,
              negative: index === 3,
            })}
          >
            {part}
          </span>
        </span>)
      );
    });
  }

  const isAdditionalClassApplicable = symbol.length > 18 && symbol.includes('-');
  const customStyle = styles['double-lined-col-isLoggedin'];
  const customStylee = styles['double-lined-col'];

  return (
    (<div
      className={cn('row-item', styles['contract-data'], {
        [customStyle]: isAdditionalClassApplicable && isLoggedin,
        [customStylee]: isAdditionalClassApplicable && !isLoggedin,
      })}
      data-palette="Contract">
      <Render when={showFavIcon}>
        <Render
          when={isLoggedin}
          else={
            <Popover
              body={t('trading:addWatchlistToolTip')}
              shouldEnableClickListener
              shouldDisablePortal
            >
              {thisProps => (
                <IconButton
                  data-testid="add-to-favorite"
                  onClick={e => {
                    preLoginFavoriteClick(e, thisProps.setOpen);
                  }}
                  icon={<FavoriteSelected className={styles[`fav-icon`]} />}
                />
              )}
            </Popover>
          }
        >
          <IconButton
            data-testid={isFavorite ? 'remove-from-favorite' : 'add-to-favorite'}
            onClick={e => handleFavClick(e, product.id)}
            icon={
              <FavoriteSelected
                className={cn(styles[`fav-icon`], {
                  [styles.active]: isFavorite,
                })}
              />
            }
          />
        </Render>
      </Render>
      <div className={styles[`item-symbol`]}>
        {isOptionsCombosSymbol(symbol) ? (
          <span data-palette="Contract">{splitAndColorizeSpreadsSymbol(symbol)}</span>
        ) : (
          <span data-palette="Contract">{symbol}</span>
        )}
        <Render when={!isSpotContract(product.contract_type) && IS_INDIAN_EXCHANGE}>
          <Leverage product={product} />
        </Render>
        <br />
        {!(isMobile || IS_RN_WEBVIEW) && <Description description={description} />}
      </div>
    </div>)
  );
};

export const getLastPrice = (lastTrade, tickerData) => {
  return (
    (lastTrade && lastTrade.price && convertExponentialToDecimal(lastTrade.price)) ||
    (tickerData && tickerData.close && convertExponentialToDecimal(tickerData.close))
  );
};

export const LastPrice = pure(
  subscribe(props => {
    const { product } = props;
    const { contract_type, trading_status } = product;
    const lastPriceValue = lastPriceSelector(product);

    const lastPrice = trading_status !== 'operational' ? ' ' : lastPriceValue || '-';
    const value =
      contract_type === 'interest_rate_swaps' && lastPriceValue
        ? `${lastPriceValue}%`
        : lastPrice;

    return (
      <ConditionalRender value={lastPriceValue}>
        <div className={`row-item ${styles['last-price']}`}>
          {convertExponentialToDecimal(value)}
        </div>
      </ConditionalRender>
    );
  })
);

export const SettlementCurrency = props => {
  const {
    contract_type,
    underlying_asset,
    // settling_asset,
    symbol,
  } = props.product;
  const redirect_link = getProductUrlByContractType(
    contract_type,
    underlying_asset.symbol,
    symbol
  );
  const [isIconHovered, setIconHover] = useState(false);
  const toggleIconHover = () => {
    setIconHover(!isIconHovered);
  };

  return (
    (<div
      className={`d-flex ${styles['redirect-link-wrapper']}`}
      data-palette="SettlementCurrency">
      {/* <div className="">{settling_asset.symbol}</div> */}
      <a
        data-testid="qfPKcNXOvLezmOz87JS4F"
        href={`/app${redirect_link}`}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={toggleIconHover}
        onMouseLeave={toggleIconHover}
        className={`${styles[`redirect-wrapper`]} ${
          isIconHovered && styles[`redirect-hover`]
        } `}
        onClick={e => e.stopPropagation()}
      >
        <NewRedirectIcon
          className={isIconHovered ? null : 'hover'}
          style={{ width: '15px', height: '15px' }}
        />
      </a>
    </div>)
  );
};

export const AssetsDropdownFilter = props => {
  const { settlingAssetFilter, activeAssetType } = props;
  const handleDropdownVisibility = e => {
    e.stopPropagation();
    document.getElementById('dropdown-content').classList.toggle('show');
    document.getElementById('settlement-header').classList.toggle('filter-active');
  };

  // const settling_assets = productsList.map(
  //   product => product.settling_asset.symbol
  // );
  // const all_settling_assets = settling_assets.filter(
  //   (item, idx, arr) => arr.indexOf(item) === idx
  // );

  const assetTypes = ['All', 'BTC', 'USDT'];

  return (
    (<MediaQuery query={device.up.md}>
      <AssetDropdownWrapper
        data-testid="O3Myt_zmNM61c4nsS3zn6"
        onClick={e => handleDropdownVisibility(e)}
      >
        <span className="dropdown-span">
          <span className="dropdown-title">{activeAssetType}</span>
          <FilterArrow className="dropdown-icon" />
        </span>
        <div id="dropdown-content" className="dropdown-container">
          {assetTypes.map((item, index) => {
            return (
              (<div
                data-testid="Ujut-xTKOnaP-ix23XdAY"
                aria-hidden="true"
                key={index}
                className={`dropdown-item ${item === activeAssetType ? 'active' : ''}`}
                onClick={() => settlingAssetFilter(item)}
                data-palette="AssetsDropdownFilter">
                {item}
              </div>)
            );
          })}
        </div>
      </AssetDropdownWrapper>
    </MediaQuery>)
  );
};
