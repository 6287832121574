/* eslint-disable import/no-unresolved */
/* eslint-disable test-selectors/onChange */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';

import { editOrdersFromBasket } from 'actions/basketOrders';
import { isOptions } from 'helpers';
import { useAppSelector, useQuantity } from 'hooks';
import Dropdown from 'react-dropdown';
import {
  markPriceSelectorById,
  productsSelector,
  showBasketErrorSelector,
  spotPriceSelectorBySymbol,
} from 'selectors';
import { useAppDispatch } from 'storeHooks';
import { DropDownArrowDownIcon, DropDownArrowUpIcon } from 'styles/assets/icons';
import { OrdersObject } from 'types/basketOrders';
import NumberInput from 'UIKit/NumberInput';

import styles from './orders.module.scss';

interface Props {
  orders: OrdersObject;
}

// To do @suraj-kawale-au2 - abstract quantity change logic to a hook.
const BasketOrderQuantity = ({ orders }: Props) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => productsSelector(state));
  const product = products[orders.product_id];
  const showError = useAppSelector(state => showBasketErrorSelector(state));

  const spotPrice = spotPriceSelectorBySymbol(product?.spot_index?.symbol as string);
  const markPrice = markPriceSelectorById(orders.product_id.toString());

  const getPrice = () => {
    if (isOptions(product?.contract_type)) {
      return spotPrice;
    }
    if (orders.limit_price && orders.order_type === 'limit') {
      return orders.limit_price;
    }

    return markPrice;
  };

  const editSizeOfOrderInBasket = (quantityInContracts: number) => {
    dispatch(editOrdersFromBasket({ ...orders, size: Number(quantityInContracts) }));
  };

  const {
    quantity,
    quantityCurrency,
    setIsQuantityInputEditable,
    currencyDropdownOption,
    onDropdownChange,
    onQuantityChange,
  } = useQuantity({
    product,
    price: getPrice() as string,
    prefilledQuantity: orders.size as number,
    callback: editSizeOfOrderInBasket,
  });

  return (
    (<div
      className={`${styles[`quantity-section`]} ${
        showError && Number(orders.size) <= 0 ? styles['red-border'] : ''
      }`}
      onFocus={() => setIsQuantityInputEditable(true)}
      onBlur={() => setIsQuantityInputEditable(false)}
      data-palette="BasketOrderQuantity">
      {/* @ts-ignore */}
      <NumberInput
        data-testid="basket-quantity-input"
        value={quantity || ''}
        onChange={onQuantityChange}
        autocomplete="off"
        inputMode="decimal"
        numberType="decimal"
      />
      <Dropdown
        data-testid="basket-quantity-currency-dropdown"
        options={currencyDropdownOption}
        onChange={onDropdownChange}
        value={quantityCurrency}
        placeholderClassName={styles['dropdown-placeholder']}
        menuClassName={styles['dropdown-menu']}
        controlClassName={styles['dropdown-control']}
        placeholder={quantityCurrency}
        /* @ts-ignore */
        arrowClosed={<DropDownArrowUpIcon className={styles['up-arrow']} />}
        /* @ts-ignore */
        arrowOpen={<DropDownArrowDownIcon className={styles['down-arrow']} />}
      />
    </div>)
  );
};

export default BasketOrderQuantity;
