import routes from 'constants/routes';

const redirectToEmailVerification = (email, history) => {
  history.push({
    pathname: routes.verifyRegister,
    state: {
      email,
    },
  });
};

const emailDomainSuggestions = [
  'gmail.com',
  'yahoo.com',
  'protonmail.com',
  'outlook.com',
  'icloud.com',
  'hotmail.com',
];

const defaultEmailDomainSuggestion = 'gmail.com';

/**
 * @returns Gives an email domain suggestion based on the provided email.
 */
const getEmailDomainSuggestion = (email: string) => {
  if (email.length === 0) return '';

  const indexOfAtSign = email.indexOf('@');
  // If @ is not contained
  if (indexOfAtSign === -1) return '';

  // If @ is the last character, then suggest a default domain
  if (indexOfAtSign === email.length - 1) return defaultEmailDomainSuggestion;

  // Extract domain part of the email.
  const emailDomainSubstring = email.slice(indexOfAtSign + 1);

  // If the domain part of the email is found in suggestionList then suggest the remaining part of the domain to the user.
  const fullDomain =
    emailDomainSuggestions.find(suggestion =>
      suggestion.startsWith(emailDomainSubstring)
    ) || '';
  return fullDomain.slice(emailDomainSubstring.length);
};

export { redirectToEmailVerification, getEmailDomainSuggestion };
