import styled from 'styled-components';

const BasketTable = styled.div`
  max-height: 550px;
  font-size: 12.1px;
  font-family: var(--fontRegular);

  .ReactTable {
    border: none;

    .rt-table .rt-thead {
      border-bottom: 1px solid var(--separator) !important;
      box-shadow: none !important;
      margin-bottom: 10px;
      color: var(--secondaryText);

      .rt-tr {
        padding-bottom: 10px;
      }
    }

    .rt-thead .rt-th,
    .rt-thead .rt-td,
    .rt-tbody .rt-td {
      border-right: none;
    }

    .rt-tbody {
      color: var(--secondaryText);
      height: 500px;
      overflow-y: scroll;

      .rt-tr-group {
        flex: none;
      }

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--tertiaryBackground);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--secondaryText);
      }
    }
  }

  .tableheader {
    color: var(--secondaryText);
  }
`;

export default BasketTable;
