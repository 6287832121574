/* eslint-disable no-nested-ternary */
import React, { ReactNode, useMemo } from 'react';

import { LongOrShortIndicatorIcon } from 'icons';

import { POSITION_TYPE } from 'constants/enums';
import { getOpenPosition } from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import { useAppSelector } from 'hooks';
import { allOpenPositionsSelector } from 'selectors';
import { type OpenPosition } from 'types/IOpenPosition';
import { Space, Box } from 'UIKit';

interface IndicatorProps {
  children: ReactNode;
  optionId: number;
}

const LongShortIndicator = ({ optionId, children }: IndicatorProps) => {
  const openPositions = useAppSelector(allOpenPositionsSelector) as OpenPosition[];

  // const openOptionPosition = useAppSelector(state =>
  //   openOptionPositionSelector(state, optionId)
  // );

  const openOptionPosition = useMemo(
    () => (optionId ? getOpenPosition(openPositions, optionId) : null),
    [optionId, openPositions]
  );

  const getIndicator = (size: number) =>
    size < 0 ? POSITION_TYPE.SHORT : POSITION_TYPE.LONG;

  const optionPositionType = openOptionPosition
    ? getIndicator(openOptionPosition.size)
    : null;

  return (
    <Space size="xs" className="relative">
      {children}
      <Box as="span" absolute relative renderWhen={Boolean(optionPositionType)}>
        <LongOrShortIndicatorIcon type={optionPositionType!} />
      </Box>
    </Space>
  );
};

export default subscribe(LongShortIndicator);
