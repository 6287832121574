import styled from 'styled-components';
import Table from 'components/table';
import { device } from 'helpers/screenSizes';

const AccountTable = styled(Table)`
  &.ReactTable {
    .rt-tbody {
      .rt-td {
        border-right: 1px solid ${props => props.theme.separator};
      }
    }
    .rt-noData {
      left: 34%;
    }

    .rthfc-th-fixed-left {
      position: -webkit-sticky;
      position: sticky !important;
      z-index: 1;
      background-color: ${props => props.theme.secondaryBackground};
    }
    .rthfc-td-fixed-left {
      position: -webkit-sticky;
      position: sticky !important;
    }

    color: ${props => props.theme.primaryText};
    border: none !important;
    font-size: 12.1px;

    .rt-table {
      min-height: 70px;
      .rt-thead {
        background-color: unset !important;
      }
    }

    .rt-thead {
      color: ${props => props.theme.secondaryText};
      border-bottom: 0.8px solid ${props => props.theme.separator} !important;
      height: 40px;

      &.-header {
        box-shadow: none !important;
      }

      .rt-th.-sort-desc {
        box-shadow: none !important;
      }

      .rt-th.-sort-asc {
        box-shadow: none !important;
      }

      .rt-th {
        font-family: var(--fontRegular);
        padding-top: 7px !important;
        padding-bottom: 7px !important;
        padding-left: 10px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .rt-tr {
        text-align: left !important;
        padding-left: 0px;

        @media ${device.down.sm} {
          padding-left: 2px;
        }
      }
    }

    .-loading.-active {
      background: ${props => props.theme.primaryBackground} !important;
    }

    .rt-tbody {
      color: ${props => props.theme.primaryText};
      font-family: var(--fontRegular);
      border-top: none;

      .rt-tr-group {
        flex: none;
        border-bottom: 0.8px solid ${props => props.theme.secondaryBackground};
      }

      .rt-tr {
        height: 45px;
        flex: none;
        padding-left: 0px;
      }

      .rt-td {
        text-overflow: ellipsis;
        white-space: nowrap;
        align-self: center;
        padding: 16px 0px 16px 10px;
        overflow: hidden;
        border-right: none;
        font-family: var(--fontRegular);

        .whiteListedIp {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div:nth-child(1) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          button.whiteListEdit {
            border-radius: 4px;
            background-color: var(--unselectedTab);
            padding: 6px 8px;
            margin-left: 10px;

            &:hover {
              background-color: var(--primaryTheme);
              svg {
                path {
                  fill: var(--white);
                }
              }
            }
          }
        }

        .profit,
        .loss {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          justify-content: center;
        }

        .value {
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media ${device.down.xs} {
          font-size: 12.1px;
          padding: 10px 10px 20px 10px;
        }
      }
    }
  }

  .pagination-bottom {
    background: ${props => props.theme.primaryBackground};
    font-size: 12.1px;
    margin-top: 20px;

    @media ${device.down.sm} {
      margin-top: 20px;
    }

    .-pagination {
      box-shadow: none;
      border: none;
      padding: 0;

      .-pageInfo {
        margin: 2px 10px;

        input {
          padding: 3px 7px;
          background: ${props => props.theme.primaryBackground};
        }
      }

      .-previous,
      .-next {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rt-noData {
    margin: 20px 10px 10px 10px;
  }
`;

export default AccountTable;
