/* eslint-disable import/no-unresolved */

import React from 'react';

import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import OrderSectionPopoverContent from 'components/popover/OrderSectionPopoverContent';
import { RenderForGlobalExchange } from 'components/render/RenderByExchangeType';
import { BASKET_ORDER_GUIDE_LINK, VANILLA_SETTLING_ASSET } from 'constants/constants';
import { getBalanceBySymbol, negativeBalanceCheck } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
import {
  balanceBySymbolSelector,
  // selectedProductSelector,
  userMarginModeSelector,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import {
  LearnMoreIcon,
  CircularExclamationWarning,
  CircularTick,
} from 'styles/assets/icons';
import { AutoDisappearingComponent, PopoverWithCustomIcon } from 'UIKit';

import styles from './basketorders.module.scss';

const AvailableMargin = () => {
  const { t } = useTranslation(['basketOrders', 'portfolioMargin']);
  // const selectedProduct = useAppSelector(selectedProductSelector);
  const allBalances = useAppSelector(balanceBySymbolSelector);
  const marginMode = useAppSelector(state => userMarginModeSelector(state));

  const balance = getBalanceBySymbol(VANILLA_SETTLING_ASSET, allBalances);
  const roundedBalance = negativeBalanceCheck(balance?.available_balance, 2);
  return (
    <>
      <MediaQuery query={device.up.md}>
        <div className={styles['available-margin']}>
          <div className={styles['available-margin-text']}>
            {t('basketOrders:availableMargin')}
          </div>
          <div className={styles['available-margin-value']}>
            {roundedBalance} {VANILLA_SETTLING_ASSET}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query={device.down.md}>
        <div className={styles['user-data-container']}>
          <div className={styles['available-margin']}>
            <div className={styles['available-margin-text']}>
              {t('basketOrders:availableMargin')}
            </div>
            <div className={styles['available-margin-value']}>
              {roundedBalance}
              <span>{VANILLA_SETTLING_ASSET}</span>
            </div>
          </div>
          <RenderForGlobalExchange>
            <div className={styles['margin-mode']}>
              <div className={styles['margin-mode-text']}>
                {t('basketOrders:marginMode')}
              </div>
              <div className={styles['margin-mode-value']}>
                {t(`portfolioMargin:${marginMode}`)}
              </div>
            </div>
          </RenderForGlobalExchange>
        </div>
      </MediaQuery>
    </>
  );
};

const Learnmore = () => {
  const { t } = useTranslation(['basketOrders']);
  return (
    (<a
      data-testid="basket-order-guide-link"
      className={styles.learnMore}
      href={BASKET_ORDER_GUIDE_LINK}
      onClick={() => trackMixpanelEvent('Basket Order - Learn More Clicked')}
      target="_blank"
      rel="noreferrer noopener"
      data-palette="Learnmore">
      {t('basketOrders:learnmoretext')}
      <LearnMoreIcon className={styles.learnMoreIcon} />
    </a>)
  );
};

const PorfolioAsset = () => {
  const { t } = useTranslation(['portfolioMargin']);
  return (
    (<div className={styles['portfolio-asset']} data-palette="PorfolioAsset">
      <span>{t('portfolioMargin:portfolio')}</span>
    </div>)
  );
};

interface Props {
  isResponseSuccess: boolean;
  errorText: string;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ValidationIconComponent = ({
  isResponseSuccess,
  errorText,
  show,
  setShow,
}: Props) =>
  isResponseSuccess ? (
    <AutoDisappearingComponent disappearingTime={10000} show={show} setShow={setShow}>
      <CircularTick className={styles.tick} />
    </AutoDisappearingComponent>
  ) : (
    <AutoDisappearingComponent disappearingTime={10000} show={show} setShow={setShow}>
      <PopoverWithCustomIcon
        iconComponent={
          <span data-palette="ValidationIconComponent">
            <CircularExclamationWarning />
          </span>
        }
        hoveredIconComponent={null}
        popoverComponent={<OrderSectionPopoverContent text={errorText} />}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        dataTestId="basket-error-popover"
      />
    </AutoDisappearingComponent>
  );

export { AvailableMargin, Learnmore, PorfolioAsset, ValidationIconComponent };
