import { useState, useEffect } from 'react';

const useVisibleElm = ({ ref, rootMargin, debugLabel }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        entries => {
          const [firstEntry] = entries;
          if (firstEntry && firstEntry.isIntersecting) {
            // eslint-disable-next-line no-console
            console.log('debug', `${debugLabel} is visible`);
            setIsVisible(true);
            observer.unobserve(ref.current);
            observer.disconnect();
          }
        },
        {
          rootMargin,
        }
      );

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref, rootMargin, debugLabel]);

  return isVisible;
};

export default useVisibleElm;
