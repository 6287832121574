/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { getImpactFromPremium } from 'components/easyOptions/partials/OptionCard/helpers';
import { ORDER_SIDE } from 'constants/constants';
import { isEmpty, isOptions } from 'helpers';
// eslint-disable-next-line import/named
import {
  computeMaxQuantity,
  calculateMaxLossAtSettlementByLegs,
  convertOrderBook,
} from 'helpers/formulas';
import { division } from 'helpers/math';
import {
  allOpenPositionsSelector,
  l2OrderbookSelectorWithDepth,
  markPriceSelectorById,
  spotPriceSelectorBySymbol,
} from 'selectors';
import { useAppSelector } from 'storeHooks';
import { OpenPosition } from 'types/IOpenPosition';
import { ContractType, Product } from 'types/IProducts';
import { IBook } from 'types/ITrade';

import useSelectedProductBalance from './useSelectedProductBalance';

export interface maxQuantityProps {
  product: Product;
  orderbook: IBook[];
  isBuyActionState: boolean;
  limit_price: number;
  leverage: number;
  initialLeverage: number;
}
/**
 * calculate max quantity for order based on user balance and max leverage
 */
const useOptionsMaxQuantity = ({
  product,
  // orderbook,
  isBuyActionState,
  limit_price,
  leverage,
  initialLeverage,
}: maxQuantityProps) => {
  const { balance } = useSelectedProductBalance(product, isBuyActionState);
  const orderbooks = useAppSelector(l2OrderbookSelectorWithDepth);
  const side = isBuyActionState ? ORDER_SIDE.BUY : ORDER_SIDE.SELL;
  const orderbook = convertOrderBook(orderbooks, side);

  // const allBalance = useAppSelector(state => balanceBySymbolSelector(state));
  const allOpenPositions = useAppSelector(state => allOpenPositionsSelector(state));

  if (product?.contract_type === 'spot') return 0;

  if (!product || isEmpty(balance)) return 0;

  const {
    id,
    // settling_asset,
    spot_index,
    contract_value,
    taker_commission_rate,
    contract_type,
    notional_type,
    product_specs,
  } = product;
  // const { symbol: settlingSymbol } = settling_asset as Asset;

  const spotPrice = spotPriceSelectorBySymbol(spot_index?.symbol as string);
  const markPrice = markPriceSelectorById(String(id));

  // const { available_balance } = allBalance[settlingSymbol] || {};
  const availableBalance = Math.max(Number(balance), 0.0);

  const totalPositionSize = allOpenPositions.reduce(
    (acc: number, position: OpenPosition) => {
      if (position?.product?.id === id) {
        return acc + Number(position?.size);
      }
      return acc;
    },
    0
  );

  if (isOptions(contract_type)) {
    // impact price from orderbook assuming there’s no fee and all the AB can be used for the order.
    const { impactPrice: impactByBalance } = getImpactFromPremium(
      Number(availableBalance),
      orderbook,
      product
    );

    // Buy: If ImpactPrice < Limit Price, then Entry Price = Imp else Entry Price = Limit Price
    // Sell: If ImpactPrice > Limit Price, Entry Price = Imp else Entry Price = Limit Price
    const entryPrice = (() => {
      if (Number(limit_price)) {
        if (side === ORDER_SIDE.BUY) {
          return Number(impactByBalance) < Number(limit_price)
            ? Number(impactByBalance)
            : Number(limit_price);
        }
        return Number(impactByBalance) > Number(limit_price)
          ? Number(impactByBalance)
          : Number(limit_price);
      }
      return impactByBalance;
    })() as number;

    // Fee = min (10% * entry price, spot price * fee_rate) * contract_value
    // fee capping at 10% of est. premium for options
    const feePerContract =
      Math.min(0.1 * entryPrice, Number(spotPrice) * Number(taker_commission_rate)) *
      Number(contract_value);

    if (side === ORDER_SIDE.BUY) {
      // MaxNumberofContracts = AB/ (Entry Price * contract_value + 3* Fee)
      const buyMaxQuantity = Math.floor(
        division(
          Number(availableBalance),
          Number(entryPrice) * Number(contract_value) + 3 * Number(feePerContract)
        ) + Math.abs(Math.min(totalPositionSize, 0.0))
      );

      const offSettedMax = Math.min(
        Number(product?.position_size_limit) - Math.max(totalPositionSize, 0.0),
        buyMaxQuantity
      );
      return offSettedMax;
    }

    let marginRequired = 0;
    if (contract_type === ContractType.OptionsCombos) {
      const { legs } = product_specs;
      const max_loss_at_settlement = calculateMaxLossAtSettlementByLegs(legs ?? []);
      marginRequired = Math.min(
        division(Number(spotPrice), leverage),
        Math.abs(max_loss_at_settlement)
      );
    } else {
      marginRequired = division(Number(spotPrice), leverage);
    }
    // todo : MaxLeverage too is dependent on position size. So, ideally, we should use different equations here
    // MaxNumberofContracts = AB/ (Margin_required * contract_value + 3* Fee) + NumberofContracts_Long_Position
    const sellMax = Math.floor(
      division(
        Number(availableBalance),
        Number(marginRequired) * Number(contract_value) + 3 * Number(feePerContract)
      ) + Math.max(totalPositionSize, 0.0)
    );
    const offSettedMax = Math.min(
      Number(product.position_size_limit) - Math.abs(Math.min(totalPositionSize, 0.0)),
      sellMax
    );
    return offSettedMax;
  }

  const maxQuantity = computeMaxQuantity({
    availableBalance,
    entryPrice: Number(limit_price) || Number(markPrice),
    leverage: leverage || initialLeverage,
    isVanillaProduct: notional_type === 'vanilla',
    totalPositionSize,
    isBuyActionState,
    commissionRate: taker_commission_rate,
    contractValue: contract_value,
    product,
    spotPrice,
    markPrice,
  });

  return maxQuantity;
};

export default useOptionsMaxQuantity;
