/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { pure } from 'recompose';
import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import usePopoverBaseIcons from 'hooks/usePopoverBaseIcons';

import Tab from './tab';

const WidgetTabsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.theme.primaryBackground};
  width: 100%;
  justify-content: flex-start;
  /* &:hover {
    cursor: move;
  } */
  @media ${device.down.xs} {
    padding-bottom: 0;
    height: auto;
  }
  &.mobile-tabs {
    .tab {
      /* padding: 11px 5px; */
      color: ${props => props.theme.secondaryText};
      cursor: pointer;
      font-size: 12.1px;
      text-align: center;
      /* width: ${props => props.tablen / props.len}%; */
      font-family: var(--fontRegular);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
    .active {
      position: relative;
      /* color: white; */
      font-family: var(--fontRegular);
      font-size: 12.1px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      color: ${props => props.theme.secondaryText};

      &:after {
        content: '';
        position: absolute;
        width: 100px;
        height: 2px;
        background-color: ${props => props.theme.primaryTheme};
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .tab {
    padding: 11px 5px;
    color: ${props => props.theme.secondaryText};
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    width: ${props => props.tablen / props.len}%;
    font-family: var(--fontRegular);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    @media ${device.down.lg} {
      padding: 5px 0px;
    }

    @media ${device.down.xs} {
      height: auto;
    }
  }

  .active {
    position: relative;
    color: ${props => props.theme.primaryText};

    &:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 2px;
      background-color: ${props => props.theme.primaryTheme};
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &.options_chain {
    .tab {
      padding: 11px 1px;
      font-size: 11px;
    }
  }
`;

const WidgetTabs = pure(
  ({
    tabList,
    activeTab,
    onClick,
    tabLength,
    className,
    isDisabled,
    containerRef,
    tabRefs,
  }) => {
    const iconsNameList = tabList.map(v => v.id);
    const { toggleIconById, getIconMapValueById } = usePopoverBaseIcons(iconsNameList);
    // const stateContractType = useSelector(contractTypeSelector);
    return (
      <WidgetTabsContainer
        className={className}
        len={tabList.length > 0 ? tabList.length : 1}
        tablen={tabLength || 100}
        ref={containerRef}
      >
        {tabList.map(item => (
          <Tab
            data-testid={`${tabList.label}`}
            key={item.id}
            {...item}
            activeTab={activeTab}
            isDisabled={isDisabled}
            onClick={onClick}
            testId={item.testId ?? null}
            toggleIconById={toggleIconById}
            getIconMapValueById={getIconMapValueById}
            tabRef={tabRefs?.current?.[item?.id]}
          />
        ))}
      </WidgetTabsContainer>
    );
  }
);

export default WidgetTabs;
