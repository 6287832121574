import React from 'react';

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import useLanguageChange from 'hooks/useLanguageChange';
import { ArrowDownThin } from 'styles/assets/icons';

import styles from './styles.module.scss';

const LanguageMenu = () => {
  const { activeLanguage, availableLocales, handleLanguageSwitch } = useLanguageChange();

  const onLanguageChange = locale => {
    handleLanguageSwitch(locale);
    trackMixpanelEvent('Language Changed - All Pages', {
      language_selected: locale,
    });
  };

  return (
    (<div
      className={`dropdown-menu-container-parent ${styles.languages} ${styles.headerElement} d-lg-inline-block`}
      data-palette="LanguageMenu">
      <Menu
        menuButton={({ open }) => (
          <MenuButton
            className={`${open ? styles.active : ''} ${styles.menuButton}`}
            data-testid="languagesMenu"
          >
            {activeLanguage}
            <ArrowDownThin
              className={`${open ? styles.iconActive : styles.iconInactive}`}
            />
          </MenuButton>
        )}
        menuClassName={`dropdown-menu-container language-dropdown-wrapper ${styles.languages} ${styles.menu}`}
        gap={8}
      >
        {Object.keys(availableLocales).map(locale => (
          <MenuItem
            key={locale}
            onClick={() => onLanguageChange(locale)}
            className={`dropdown-menu-item ${
              activeLanguage === locale ? styles.active : ''
            } ${styles.menuItem}`}
            data-testid={`languagesMenu-${locale}-option`}
          >
            <div data-locale={locale}>{locale}</div>

            <div className={styles.dropdownLabel}>{availableLocales[locale]}</div>
          </MenuItem>
        ))}
      </Menu>
    </div>)
  );
};

export default LanguageMenu;
