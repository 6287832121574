/* eslint-disable react/prop-types */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { mergeDeepRight } from 'helpers/ramda';

const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 100,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: '0%',
    padding: '0',
    width: 'fit-content',
    background: 'none',
    boxShadow: '0 3px 20px 0 rgba(0, 0, 0, 0.16)',
    border: 'none',
    overflow: 'auto',
  },
};

const Modal = ({ children, style = {}, ...props }) => {
  return (
    <ReactModal
      style={mergeDeepRight(defaultStyles, style)}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      className={cx('common-modal-content', props.modalClassName)}
      overlayClassName={`common-modal-overlay ${props.classOverlayName || ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  // isOpen: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  // onRequestClose: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
};

export default Modal;
