import { TFunction } from 'react-i18next';

import { IdentityVerificationError } from './BankDetails.types';

function getMappedErrorsForIdentityVerification(
  t: TFunction,
  error: IdentityVerificationError = null
) {
  if (!error) return null;

  const { code, context } = error ?? {};

  switch (code) {
    // * INFO: Commented this block as backend have not enabled MFA, will use this once they enable it
    // * ------------------------------------------------------------------------------------------------

    // case 'invalid_mfa_code': {
    //   // * INFO: Old MFA code
    //   if (context) {
    //     const {
    //       otp_retry_attempts: count = 0,
    //       max_otp_validation_attemps: maxAttempts = 5,
    //     } = context ?? {};
    //     const attemptsRemaining = maxAttempts - count;

    //     return {
    //       mfaCode: i18n.t('errors:custom.invalid_mfa_code', { count: attemptsRemaining }),
    //     };
    //   }

    //   return {
    //     mfaCode: i18n.t('errors:custom.invalid_old_mfa_code'),
    //   };
    // }

    // case 'invalid_new_mfa_code': {
    //   return { mfaCode: i18n.t('errors:custom.invalid_new_mfa_code') };
    // }

    // case 'mfa_check_blocked': {
    //   return { mfaCode: i18n.t('auth:processBlocked') };
    // }

    case 'invalid_email_code':
    case 'otp_does_not_match': {
      // * INFO: Email OTP verification
      const {
        otp_retry_attempts: count = 0,
        max_otp_validation_attemps: maxAttempts = 5,
      } = context ?? {};
      const attemptsRemaining = maxAttempts - count;

      if (attemptsRemaining > 1) {
        return {
          emailCode: t('errors:custom.invalid_email_otp_plural', {
            count: attemptsRemaining,
          }),
        };
      }

      return {
        emailCode: t('errors:custom.invalid_email_code', {
          count: attemptsRemaining,
        }),
      };
    }

    case 'otp_expired': {
      return {
        emailCode: t('errors:custom.expired_email_code'),
      };
    }

    case 'all_attempts_used': {
      return {
        emailCode: t('errors:custom.exhausted_email_code_attempts'),
      };
    }

    case 'source_down': {
      return {
        emailCode: t('errors:custom.source_down'),
      };
    }

    case 'id_not_found': {
      return {
        emailCode: t('errors:custom.id_not_found'),
      };
    }

    default: {
      return null;
    }
  }
}

export { getMappedErrorsForIdentityVerification };
