/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';

import { getTotalTradingEquity } from 'components/primaryHeader/partials/walletMenu/computeTotalBalance';
import {
  cropAfterDecimals,
  sortBalancesBySortPriority,
  scientificToDecimalNotation,
} from 'helpers';
import {
  allOpenPositionsSelector,
  enableWalletsSelector,
  openPositionMarkPriceSelectorById,
  portfolioMarginSelector,
  roboTradingEquitySelector,
  walletSpotPriceBySymbol,
} from 'selectors';
import { useAppSelector } from 'storeHooks';

const useTotalAccountEquity = (): string | number => {
  const balance = useAppSelector(enableWalletsSelector);
  const balancesArray = sortBalancesBySortPriority(balance);

  const openPositions = useAppSelector(allOpenPositionsSelector);
  const totalRoboStrategiesEquity = useAppSelector(roboTradingEquitySelector);
  const portfolioMarginData = portfolioMarginSelector();
  const { positions_upl: positionsUPL, asset_id: portfolioEnabledAsset } =
    portfolioMarginData?.aggregate || {};

  const totalTradingEquity = useMemo(() => {
    return getTotalTradingEquity(
      balancesArray,
      openPositions,
      openPositionMarkPriceSelectorById,
      walletSpotPriceBySymbol,
      positionsUPL,
      portfolioEnabledAsset!
    );
  }, [balancesArray, openPositions, portfolioEnabledAsset, positionsUPL]);

  if (!Object.keys(balance).length) return '-';

  const totalEquityInUsd = cropAfterDecimals(
    scientificToDecimalNotation(
      Number(totalRoboStrategiesEquity) + Number(totalTradingEquity)
    ),
    2
  );

  return totalEquityInUsd;
};

export default useTotalAccountEquity;
