import OTHER, {
  TAB_INACTIVE,
  TAB_REACTIVE,
  OPEN_ADD_ADDRESSMNGT_MODAL,
  SOCKET_DISCONNECTED_ON_PAGE_INACTIVE,
  SHOW_KYC_UPGRADE_STRIP,
  SHOW_CONVERT_BLOCKED_POPUP,
  SHOW_OTP_SUCCESS_POPUP,
  SET_COUNTRY_FROM_IP,
} from 'actionTypes/other';
import createReducer from 'helpers/createReducer';
import { add, timeNow } from 'helpers/day';
import IOther from 'types/IOther';

const returnNextMonthDate = () => add(timeNow(), 1, 'M');

const initialState: IOther = {
  isPageInactive: false,
  inActiveTime: returnNextMonthDate(),
  isTabInactive: false,
  isAddressManagementPopupOpen: false,
  socketDisconnectedOnPageInactive: false,
  tabReactiveTime: '',
  tabInactiveTime: '',
  showKycUpgradeStrip: true,
  showConvertBlockedPopup: false,
  showOtpSuccessPopup: false,
  countryFromIp: '',
};

export default createReducer(initialState, {
  [TAB_REACTIVE]: (state, action) => ({
    ...state,
    isPageInactive: false,
    isTabInactive: false,
    socketDisconnectedOnPageInactive: false,
    tabReactiveTime: action.payload.reactiveTime,
  }),
  [TAB_INACTIVE]: (state, action) => ({
    ...state,
    isPageInactive: true,
    inActiveTime: timeNow(),
    isTabInactive: true,
    tabInactiveTime: action.payload.inactiveTime,
  }),
  [SOCKET_DISCONNECTED_ON_PAGE_INACTIVE]: state => {
    return {
      ...state,
      socketDisconnectedOnPageInactive: true,
    };
  },
  [OPEN_ADD_ADDRESSMNGT_MODAL]: (state, action) => ({
    ...state,
    isAddressManagementPopupOpen: action.payload,
  }),
  [SHOW_KYC_UPGRADE_STRIP]: (state, action) => ({
    ...state,
    showKycUpgradeStrip: action.payload,
  }),
  [SHOW_CONVERT_BLOCKED_POPUP]: (state, action) => ({
    ...state,
    showConvertBlockedPopup: action.payload,
  }),
  [SHOW_OTP_SUCCESS_POPUP]: (state, action) => ({
    ...state,
    showOtpSuccessPopup: action.payload,
  }),
  [SET_COUNTRY_FROM_IP]: (state, action) => ({
    ...state,
    countryFromIp: action.payload,
  }),
  [OTHER.COUNTRY_FROM_IP.SUCCESS]: (state, action) => ({
    ...state,
    countryFromIp: action.result.result.country,
  }),
});
