import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import Render from 'components/render';
import { noop } from 'helpers/utils';
import { CrossIcon } from 'styles/assets/icons';
import IconButton from 'UIKit/IconButton';

import classes from './deltaModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  accessoryTitleLeft?: React.ReactNode;
  accessoryTitleRight?: React.ReactNode;
  title?: React.ReactNode;
  titleRightContent?: React.ReactNode;
  showHeader?: boolean;
  showCrossIcon?: boolean;
  headerClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  confirmButtonText?: string;
  confirmText?: string;
  cancelButtonText?: string;
  showFooter?: boolean;
  footerClassName?: string;
  onCancel?: () => void;
  onConfirm?: () => Promise<void> | void;
  cancelClassName?: string;
  confirmClassName?: string;
  loading?: boolean;
  hideConfirmButton?: boolean;
  disableConfirmButton?: boolean;
  showCancel?: boolean;
  fullScreen?: boolean;
};

/**
 * @description
 * Modal component with custom header, body and footer for mobile devices only
 * Inspired by MaterialConfirmDialog component from UIKit
 * MaterialConfirmDialog Component does not support dom accessibility , hence functionality like copy to clipboard does not work on MaterialConfirmDialog. This component is created to support dom accessibility
 */
const DeltaReactModal = ({
  isOpen,
  onClose,
  children,
  accessoryTitleLeft,
  accessoryTitleRight,
  title,
  titleRightContent,
  showHeader = true,
  showCrossIcon = true,
  headerClassName,
  titleClassName,
  bodyClassName,
  confirmButtonText,
  confirmText,
  cancelButtonText,
  showFooter = true,
  footerClassName,
  onCancel,
  onConfirm,
  cancelClassName,
  confirmClassName,
  loading,
  hideConfirmButton,
  disableConfirmButton,
  showCancel = true,
  fullScreen = false,
}: Props) => {
  const { t } = useTranslation(['common']);
  return (
    (<Modal
      isOpen={isOpen}
      overlayClassName={classes.mobileContainer}
      onRequestClose={onClose}
      className={cx(classes.mobileModal, { [classes.fullScreen]: fullScreen })}
    >
      <Render when={showHeader}>
        <div
          className={cx(classes.header, {
            [headerClassName as string]: headerClassName,
          })}
        >
          {accessoryTitleLeft}

          <h2
            className={cx({
              [titleClassName as string]: titleClassName,
            })}
          >
            {title}
            {titleRightContent}
          </h2>

          {accessoryTitleRight || (
            <Render when={showCrossIcon}>
              <IconButton
                data-testid="modal-close"
                onKeyDown={noop}
                onClick={onClose}
                icon={<CrossIcon />}
                testID="modal-close"
              />
            </Render>
          )}
        </div>
      </Render>
      <div className={cx(classes.body, { [bodyClassName as string]: bodyClassName })}>
        {children || <p className={classes.confirmText} data-palette="DeltaReactModal">{confirmText}</p>}
      </div>
      <Render when={showFooter}>
        <div
          className={cx(classes.footer, {
            [footerClassName as string]: footerClassName,
          })}
        >
          <Render when={showCancel}>
            <button
              type="button"
              data-testid="modal-cancel"
              onClick={typeof onCancel === 'function' ? onCancel : onClose}
              className={cx(classes.cancelBtn, {
                [cancelClassName as string]: cancelClassName,
              })}
            >
              {cancelButtonText || t('common:cancel')}
            </button>
          </Render>
          <Render when={!hideConfirmButton}>
            <button
              type="button"
              data-testid="modal-confirm"
              disabled={disableConfirmButton}
              onClick={loading ? undefined : onConfirm}
              className={cx({
                'w-100': !showCancel,
                [confirmClassName as string]: confirmClassName,
              })}
            >
              <Render when={loading} else={confirmButtonText || t('common:confirm')}>
                <CircularProgress size={18} color="inherit" />
              </Render>
            </button>
          </Render>
        </div>
      </Render>
    </Modal>)
  );
};

export default DeltaReactModal;
