import styled from 'styled-components';
import { device } from '../helpers/screenSizes';

const Button = styled.button`
  background-color: ${props => props.theme.primaryTheme};
  color: var(--primaryCtaText);
  border: 0px;
  font-size: 14px;
  padding: 6px;
  margin: 0px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  height: 40px;
  width: 150px;
  &:focus {
    outline: none;
  }
  &:hover:not([disabled]) {
    background-color: var(--primaryButtonHover);
    color: var(--primaryCtaTextHover);
  }
  &:disabled {
    background: var(--primaryButtonDisable);
    color: var(--primaryCtaText);
    cursor: not-allowed;
  }
`;

export const AccountButton = styled(Button)`
  font-family: var(--fontRegular) !important;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  width: 100% !important;
  height: 40px !important;
  color: var(--primaryCtaText);
  &:hover {
    background-color: ${props => props.theme.primaryButtonHover} !important;
  }
  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.primaryButtonDisable} !important;
  }
  @media ${device.down.sm} {
    &.btn.submit-btn {
      width: 140px !important;
      border-radius: 2px !important;
    }
  }
`;

export const KycConfirmButton = styled(Button)`
  font-family: var(--fontRegular) !important;
  font-size: 14px;
  line-height: 1.43;
  width: 160px;
  height: 40px;
  border-radius: 0;
  box-shadow: none;
  &:hover {
    background-color: var(--primaryButtonHover);
  }
  &.disable {
    opacity: 0.8;
    pointer-events: none;
    cursor: none;
  }
`;

export const KycCancelButton = styled(Button)`
  font-family: var(--fontRegular) !important;
  font-size: 14px;
  line-height: 1.43;
  width: 160px;
  height: 40px;
  border: solid 1px ${props => props.theme.secondaryText};
  border-radius: 0;
  background-color: ${props => props.theme.secondaryBackground};
  color: ${props => props.theme.secondaryText};
  box-shadow: none;
  &:hover {
    background-color: initial;
    color: ${props => props.theme.secondaryText};
  }
`;

export const HoldingsActionButton = styled(Button)`
  font-size: 12px
  width: 60px;
  margin: 0;
  padding: 0px 0px;
  height: 20px;
  border: 1px solid ${props => props.theme.primaryTheme};

  @media ${device.down.sm} {
    width: 50px;
  }
`;

export const BuyButton = styled(Button)`
  width: 100%;
  padding: 4px 6px 6px 6px;
  background: ${props => props.theme.positive};
  display: flex;
  justify-content: center;
`;

export const SellButton = styled(Button)`
  width: 100%;
  padding: 4px 6px 6px 6px;
  background: ${props => props.theme.negative};
  display: flex;
  justify-content: center;
`;

//todo-h: @Faiyaz: The width of a button should automatically be set based on its content + padding;
// That's the reason the text isn't vertically aligned currently. See btn-download-logs.js for reference.
export const DownloadButton = styled(Button)`
  width: 105px;
  height: 32px;
  font-size: 12.1px;
  border-radius: 2px;
  background-color: ${props => props.theme.primaryBackground};
  border: solid 1px ${props => props.theme.primaryBackground};
  color: ${props => props.theme.secondaryText};

  @media ${device.down.md} {
    width: 100px;
    height: 30px;
  }

  & svg {
    position: relative;
    top: -3px;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${props => props.theme.primaryTheme};
    border-color: ${props => props.theme.primaryTheme};
    color: ${props => props.theme.primaryText};

    & > svg path {
      fill: ${props => props.theme.primaryText};
    }
  }
`;

export const SecurityButton = styled(Button)`
  background: ${props => props.theme.secondaryBackground};
  border-radius: 2px;
  width: max-content;
  border: solid 0.8px ${props => props.theme.primaryTheme};
  color: ${props => props.theme.primaryTheme};

  @media ${device.up.md} {
    // margin-right: auto;
  }
  ${props =>
    !props.safeSessionSwitch &&
    `
  &:hover:not([disabled]) {
    background-color: ${props => props.theme.primaryTheme};
    color: var(--primaryCtaText) !important;
  }
  `}
`;

SecurityButton.displayName = 'SecurityButton';

export default Button;
