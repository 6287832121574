/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';

import InputBaseMaterial from '@material-ui/core/InputBase';

import classes from './inputBase.module.scss';

export type InputProps = ComponentProps<typeof InputBaseMaterial>;

/**
 * @see https://v4.mui.com/api/input-base/
 */
const InputBase = (props: InputProps) => {
  return (
    <InputBaseMaterial
      classes={{
        root: classes.root,
        input: classes.input,
      }}
      {...props}
    />
  );
};

export default InputBase;
