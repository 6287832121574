import { ReactNode, SVGProps } from 'react';

import {
  type AddBankAccountSubmissibleData,
  type UseBankDetails,
  type BankAccount as BankAccountDetails,
} from 'hooks/componentHooks/useBankDetails';
import {
  WithdrawalIndiaStatus,
  WithdrawalStatusTransactionMetaIndia,
} from 'hooks/componentHooks/useWithdrawal';
import { AddBankCustodianStatus } from 'types/Account';
import { AllUnionMemberKeys, ErrorWithCode } from 'types/utils';
import { Codes } from 'UIKit/DeltaOTPVerification';

const RIGHT_PANELS = {
  ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',
  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
} as const;

const MAX_ACCOUNTS_ALLOWED = 4;

const DEFAULT_DEPOSIT_AMOUNT = 1;

type RightPanels = AllUnionMemberKeys<typeof RIGHT_PANELS>;

type IdentityVerificationError = null | ErrorWithCode;

interface NoBankAccountsPlaceholder {
  onAddButtonClick: () => void;
  showHelperTexts?: boolean;
}

interface WithdrawalPendingResult {
  address: string;
  amount: string;
  asset_symbol: string;
  created_at: string;
  custodian: string;
  fee: string;
  fiat_amount: string;
  fiat_asset: string;
  fiat_fee: string | null;
  network: string;
  state: WithdrawalIndiaStatus;
  withdrawal_type: string;
  id: number;
  memo: null;
  user_id: number;
  transaction_meta: WithdrawalStatusTransactionMetaIndia;
}

interface WithdrawalPendingAPIResponse {
  result: WithdrawalPendingResult[];
  success: boolean;
}

interface AddBankAccount {
  onClose: (options?: PanelCloseOptions) => void;
  sendOtp: UseBankDetails['sendOtpToAddBankAccount'];
  resendOtp: UseBankDetails['resendOtpToAddBankAccount'];
  verifyOtp: UseBankDetails['verifyOtpToAddBankAccount'];
  addBankStatus: UseBankDetails['addBankStatus'];
}

interface AddNewBankAccountPanel {
  error: null | string;
  isLoading?: boolean;
  onConfirm: (addBankAccountData: AddBankAccountSubmissibleData) => void;
}

interface BankAccount {
  details: BankAccountDetails;
  isActive: boolean;
  isVerifying: boolean;
  onSelect: (details: BankAccountDetails) => void;
}

interface AccountList {
  list: null | BankAccountDetails[];
  onItemSelect: (details: BankAccountDetails) => void;
  selectedAccountId?: null | number;
}

interface BankIcon extends SVGProps<SVGSVGElement> {
  bankName: string | null;
  size: 'small' | 'large';
}

interface PanelIcon {
  type: 'success' | 'error';
  icon: ReactNode;
}

type PanelCloseOptions = Partial<{
  shouldRefetchList: boolean;
}>;

interface StatusPanel {
  title?: string | null;
  onClose: () => void;
  children: ReactNode;
}

interface BankDetailsResponse {
  ifsc_code: string;
  bank_name: string;
  is_bank_addition_allowed: boolean;
}

interface AddBankAccountVerifyingPanel {
  onClose: () => void;
}

interface AddBankAccountAckPanel {
  bankName?: string | null;
  error?: string | null;
  onClose: (options?: PanelCloseOptions) => void;
  onResetAddBankAccountFlow: () => void;
  status: undefined | AddBankCustodianStatus;
}

interface AddNewBankAccountOTPVerification {
  isLoading: boolean;
  errors: UseBankDetails['verifyOtpToAddBankAccount']['error'];
  onClose: () => void;
  onResendOtp: () => void;
  onConfirm: (codes: Codes) => void;
}

interface AccountDetail {
  isDeleting: boolean;
  detail: null | BankAccountDetails;
  onClose: (options?: PanelCloseOptions) => void;
  onDeleteAccount: (accountId: number) => void;
}

export { RIGHT_PANELS, MAX_ACCOUNTS_ALLOWED, DEFAULT_DEPOSIT_AMOUNT };

export type {
  AccountDetail,
  AccountList,
  AddBankAccount,
  AddBankAccountAckPanel,
  AddBankAccountVerifyingPanel,
  AddNewBankAccountOTPVerification,
  AddNewBankAccountPanel,
  BankAccount,
  BankIcon,
  IdentityVerificationError,
  NoBankAccountsPlaceholder,
  PanelIcon,
  RightPanels,
  StatusPanel,
  PanelCloseOptions,
  WithdrawalPendingAPIResponse,
  BankDetailsResponse,
};
