import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import { HoldingsTable } from 'UIKit';

export const BalanceWrapper = styled.div`
  .rt-thead.-header {
    min-height: 25px !important;
  }
  .ReactTable .rt-table {
    overflow-y: hidden;
  }

  .ReactTable .rt-table {
    .rt-thead,
    .rt-tbody {
      overflow-x: hidden;
    }
  }

  .ReactTable .rt-table .rt-thead:nth-child(1) .rt-tr .rt-th > div {
    min-width: 100%;
  }
  .ReactTable .rt-thead.-headerGroups {
    background-color: transparent;
  }
`;

export const AssetHistoryStyle = styled.div`
display: flex;
span {
  text-align: center;
  width: 84px;
  height: 24px;
  justify-items: center;
  border-radius: 1px;
  border: solid 1px ${props => props.theme.separator};
  background-color: ${props => props.theme.primaryBackground};
  font-size: 10px;
  letter-spacing: 0.6px;
  color: ${props => props.theme.secondaryText};
  padding-left: 5px;
  padding-right: 5px;
  padding-top:3px;
   &:hover{
     border-radius: 1px;
     background-color: ${props => props.theme.primaryTheme};
  }
  .account-asset-history-column {
      font-size: 12.1px;
      font-family: var(--fontRegular);
      width: 100px;
      height: 24px;
      border-radius: 1px;
      display:flex;
      align-items:center;
      justify-content : center;

  }
  a {
     color: ${props => props.theme.primaryText} !important;
    &:hover {
     color: ${props => props.theme.primaryText} !important;
  }

 }
`;

export const BalanceActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    width: 28px !important;
    margin: 0 3px;
    background-color: var(--buttonSecondaryT3);
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &:hover {
      background-color: var(--primaryTheme) !important;

      path {
        fill: white !important;
        stroke: white !important;
      }
    }
  }

  .icon path {
    fill: var(--primaryTheme) !important;
    stroke: var(--primaryTheme);
  }

  .icon .trade-icon path {
    stroke: none !important;
  }

  gap: 17px;
      a {
          &:hover{
            color:${props => props.theme.primaryTheme} !important;
              svg {
                  path {
                  fill: ${props => props.theme.primaryTheme};
                }
                g {
                fill: ${props => props.theme.primaryTheme};
                stroke: ${props => props.theme.primaryTheme};
                }
              }
              svg.trade-icon {
                g {
                  stroke: none;
                }
              }
            }
        }

  .col-8{
        &.accounts-balance-page {
        span {
          font-size: 11px !important;
          color: ${props => props.theme.secondaryText} !important;
          font-family: var(--fontRegular);
            &:hover{
            color:${props => props.theme.primaryTheme} !important;
            }
          }
        }
      }
    

 .deposit-icon-balance-section{
  margin-left : 9px;
}
  
    // Styling for all action icons
    svg {
      cursor: pointer;
      path {
        fill: ${props => props.theme.primaryText};
      }
      g {
        fill: ${props => props.theme.primaryText};
        stroke: ${props => props.theme.primaryText};
      }

      &:hover {
        path {
          fill: ${props => props.theme.primaryTheme};
        }
        g {
          fill: ${props => props.theme.primaryTheme};
          stroke: ${props => props.theme.primaryTheme};
        }
      }

      &.trade-icon {
        g {
          fill: ${props => props.theme.primaryText};
          stroke: none;
        }
      }

    }
    
  .account-balance-page {
  svg {
      width: 15px;
      height: 16px;
    }
  }
.account-balance-page-mobile {
  &.convert {
    svg {
      margin-right: 2px;
    } 
  }
  svg {
    width: 15px;
    height: 14px;
    margin-top: -10px;
    margin-right: 5px;
  }
  svg.trade-icon {
    width: 20px;
    height: 20px;
  }
  height: 20px;
  font-family: var(--fontRegular);
  font-size: 11px;
  line-height: 1.82;
  text-align: center;
  letter-spacing: 0.29px;
  color: ${props => props.theme.secondaryText} !important;
  span {
    color: ${props => props.theme.secondaryText} !important;
    padding-right:5px;
    font-family: var(--fontRegular);
    font-size: 12px !important;
  }
}
.convert-icon-balance-section {
  padding-left: 11px;
  cursor: pointer;
  &.trade {
    padding: 0 11px;
  }
 span {
  &:hover {
   color:${props => props.theme.primaryTheme} !important;
   svg {
       path {
      fill: ${props => props.theme.primaryTheme};
    }
       g {
      fill: ${props => props.theme.primaryTheme};
      stroke: ${props => props.theme.primaryTheme};
    }
  }
 }
}

.account-balance-page-desktop {
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
    path {
      fill: ${props => props.theme.primaryText};
    }
    g {
      fill: ${props => props.theme.primaryText};
      stroke: ${props => props.theme.primaryText};
    }
    &:hover {
      path {
        fill: ${props => props.theme.primaryTheme};
      }
      g {
        fill: ${props => props.theme.primaryTheme};
        stroke: ${props => props.theme.primaryTheme};
      }
    }
  }

}

`;

export const BalanceTable = styled(HoldingsTable)`
  .rt-th {
    padding-left: 10px !important;

    &:last-of-type {
      padding-left: 20px !important;
    }
  }

  .rt-tbody {
    /* Targeting first row (which has secondary-text) */
    .rt-tr-group:first-of-type {
      .rt-td {
        padding: 15px 10px !important;

        @media ${device.down.lg} {
          padding: 7px 10px !important;
        }

        @media ${device.up.lg} {
          .balance-coin {
            top: 0;
          }
        }
      }
    }

    .rt-td {
      padding: 10px !important;
      display: grid;

      .in-used {
        svg {
          margin-left: 10px;
          cursor: pointer;
          path {
            fill: ${props => props.theme.secondaryText};
          }
        }
        .chevron-up {
          transform: rotate(180deg);
        }
      }

      &:nth-child(3) {
        cursor: pointer;
        .in-used {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        svg {
          margin-left: 11px;
          path {
            fill: ${props => props.theme.secondaryText};
          }
        }
      }

      @media ${device.down.lg} {
        padding: 7px 10px !important;
      }

      @media ${device.up.lg} {
        .balance-coin {
          top: 0;
        }
      &:first-of-type {
        display: flow-root;
      }
    }
  }

  .balance-coin {
    position: relative;
    top: 5px;
    svg {
      width: 14px;
      height: 14px;
      margin-top: -2px;
    }
  }

  .coins {
    margin-right: 5px;
  }

  .coin-text {
    font-size: 12.1px;

    @media ${device.up.xxl} {
      font-size: 14px;
    }
  }
`;

export const Value = styled.div`
  display: inline-block;
  font-size: 12.1px;
  line-height: 1.74;
  color: ${props => props.theme.primaryText};

  @media ${device.up.xxl} {
    font-size: 14px;
  }
`;

export const SecondaryText = styled.div`
  color: ${props => props.theme.secondaryText};
  font-size: 9px;
  line-height: 2.35;

  @media ${device.up.xxl} {
    font-size: 11px;
  }
`;

export const ActionButtonWrapper = styled.div`
  text-align: left;
  padding-left: 10px;

  @media ${device.down.lg} {
    flex-direction: column;
    width: 80%;
  }

  & .action-btn {
    border-radius: 2px;
    border: 1px solid ${props => props.theme.separator};
    padding: 5px 15px;
    line-height: 1.8;
    font-size: 12.1px;
    cursor: pointer;
    color: ${props => props.theme.secondaryText};
    display: inline-block;
    text-align: center;

    @media ${device.down.lg} {
      padding: 3px 15px;
      font-size: 10px;
      display: flex !important;
      justify-content: center;
    }

    & label {
      cursor: pointer;
    }

    &:hover {
      background: ${props => props.theme.primaryButtonHover};
      border-color: ${props => props.theme.primaryButtonHover};
      color: ${props => props.theme.primaryText};
    }

    @media ${device.up.xl} {
      padding: 5px 20px;
    }

    @media ${device.up.xxl} {
      font-size: 14px;
      padding: 5px 25px;
    }
  }

  & .withdrawal-action-btn {
    margin-right: 10px;

    @media ${device.down.lg} {
      margin-bottom: 5px;
      margin-right: 0;
    }
  }

  & .convert-action-btn {
    margin: auto;

    @media ${device.up.lg} {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media ${device.up.xl} {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media ${device.up.xxl} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;
