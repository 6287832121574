import { useEffect } from 'react';

import { tag } from '@palette.dev/browser';
import { useLocation } from 'react-router';

import { getContractTypeFromUrl, isEmpty, isMarketsPage, isTradePage } from 'helpers';
import { userSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';

const usePaletteTags = () => {
  const { pathname } = useLocation();
  const url = window.location.href;
  const user = useAppSelector(userSelector);
  const userId = isEmpty(user?.id) ? 'logged-out' : user?.id;

  const pageType = (() => {
    if (isMarketsPage()) {
      return `${getContractTypeFromUrl()}-markets`;
    }

    if (isTradePage()) {
      return `${getContractTypeFromUrl()}-trade`;
    }

    return pathname.split('/')[1];
  })();

  useEffect(() => {
    tag('palette.location', pathname);
  }, [pathname]);

  // console.log('usePaletteTags', user, pageType);

  tag('user-id', String(userId));
  tag('page-type', pageType);
  tag('absolute-path', url);
};

export default usePaletteTags;
