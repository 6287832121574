/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import { memo } from 'react';

import { withTheme } from 'styled-components';

import useMediaQuery from 'hooks/useMediaQuery';
// import PropTypes from 'PropTypes';
import { FilledInfoIcon, InfoBracketicon, InfoIcon } from 'styles/assets/icons';

import { PopoverBaseStyle } from './popover_styles';

const PopoverBase = props => {
  const {
    open,
    toggleOpen,
    shouldEnableClickListener,
    color,
    theme,
    bracketIcon,
    ...rest
  } = props;
  const fillColor = color || theme.secondaryText;

  const { whenBelowMd } = useMediaQuery();

  const onClick = e => {
    if (whenBelowMd || shouldEnableClickListener) {
      e.preventDefault();
      e.stopPropagation();
      toggleOpen();
    }
  };
  if (bracketIcon) {
    return (
      <PopoverBaseStyle
        data-testid="popover-info"
        {...rest}
        className="bracket-section-icon-info"
        onClick={onClick}
      >
        <InfoBracketicon className="popover-base bracket-order-icon" />
      </PopoverBaseStyle>
    );
  }
  return (
    <PopoverBaseStyle data-testid="check-info" {...rest} onClick={onClick}>
      {open ? (
        <FilledInfoIcon className="popover-base popover-base-filled" />
      ) : (
        <InfoIcon
          className="popover-base position-relative zIndex-10"
          fillColor={fillColor}
        />
      )}
    </PopoverBaseStyle>
  );
};

export const CustomPopoverBase = props => {
  const {
    open,
    toggleOpen,
    shouldEnableClickListener,
    color,
    theme,
    filledIcon,
    unfilledIcon,
    ...rest
  } = props;

  const { whenBelowMd } = useMediaQuery();

  const onClick = e => {
    if (whenBelowMd || shouldEnableClickListener) {
      e.preventDefault();
      e.stopPropagation();
      toggleOpen();
    }
  };
  return (
    <PopoverBaseStyle data-testid="az3dzcrwAPn1e5-32OMuv" {...rest} onClick={onClick}>
      {open ? filledIcon : unfilledIcon}
    </PopoverBaseStyle>
  );
};

PopoverBase.defaultProps = {
  color: '',
};

// PopoverBase.propTypes = {
//   open: PropTypes.bool.isRequired,
//   toggleOpen: PropTypes.func.isRequired,
//   shouldEnableClickListener: PropTypes.bool.isRequired,
//   theme: PropTypes.theme.isRequired,
//   color: PropTypes.string,
// };

export default memo(withTheme(PopoverBase));
