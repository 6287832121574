import { useEffect } from 'react';

import { setUserTrackierId } from 'actions/user';
import { IS_RN_WEBVIEW } from 'constants/constants';
import { useAppDispatch } from 'storeHooks';

const SEND_TRACKIER_ID = 'SEND_TRACKIER_ID';

const useTrackierMobileMessage = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    const trackierMessageHandler = (message: MessageEvent<any>) => {
      if (
        IS_RN_WEBVIEW &&
        message.data.type === 'RN_MESSAGE_EVENTS' &&
        message.data.payload?.name === SEND_TRACKIER_ID
      ) {
        const { data } = message.data.payload;

        appDispatch(setUserTrackierId({ trackierId: data.trackierId }));
      }
    };

    window.addEventListener('message', trackierMessageHandler);
    return () => {
      window.removeEventListener('message', trackierMessageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useTrackierMobileMessage };
