/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import cx from 'classnames';
import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import { PopoverWithCustomIcon } from 'UIKit';

const Title = styled.div`
  width: fit-content;
  border-bottom: 1px dashed var(--tooltipBottomDashedAndUnderlineColor);
  cursor: pointer;
  &:hover {
    border-bottom: 1px dashed var(--primaryTheme);
  }
`;

const Body = styled.div`
  background: var(--tooltipInfoBoxColor);
  box-shadow: 0px 9px 18px rgb(0 0 0 / 15%);
  border-radius: 2px;
  color: var(--tooltipInfoBoxfontColor);
  padding: 10px 12px 10px 15px;
  max-width: 340px;
  font-size: 12.1px;
  line-height: 1.68;
  font-family: var(--fontLight);
  letter-spacing: 0.6px;
  @media ${device.down.md} {
    width: 100%;
    // left: -40px;
    top: 5px;
    position: relative;
  }
`;

const Tooltip = props => {
  const {
    title,
    body,
    bodyClass,
    titleClass,
    transformOrigin,
    anchorOrigin,
    isTopLevelContainer,
    parentDivStyle,
  } = props;
  return (
    <PopoverWithCustomIcon
      dataTestId={title}
      iconComponent={
        <Title className={cx('customIconTooltipTitle', titleClass)}>{title}</Title>
      }
      popoverComponent={
        <Body className={cx('customIconTooltipBody', bodyClass)}>{body}</Body>
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      isTopLevelContainer={isTopLevelContainer}
      parentDivStyle={parentDivStyle}
    />
  );
};

export default Tooltip;
