import styled from 'styled-components';

export default styled.button`
  color: ${props => props.theme.primaryText};
  border: none;
  background: ${props => props.theme.negative};
  cursor: pointer;
  padding: 0 3px;
  &:hover {
    background: ${props => props.theme.negativeHover};
  }
`;
