import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';
import { combineReducers } from 'redux';
import { reducer as burgerMenu } from 'redux-burger-menu';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import account from './account';
import appDrawer from './appDrawer';
import assetHistory from './assetHistory';
// import positions from './position';
import auth from './auth';
import basketOrders from './basketOrders';
import bellAlerts from './bellAlerts';
import bots from './bots';
import cancellations from './cancellations';
import expiredProducts from './expiredProducts';
import fills from './fills';
import generalData from './generalData';
import holdings from './holdings';
import holdingsTableFilter from './holdingsTableFilter';
import l2Orderbook from './l2Orderbook';
import layoutSetup from './layoutSetup';
import loading from './loading';
import markets from './markets';
import mobileOrderBookOverLayout from './mobileOrderBookOverLayout';
import offers from './offers';
import openOrders from './openOrders';
import openStopOrders from './openStopOrders';
import optionsChain from './options_chain';
import orderbook from './orderbook';
import orderHistory from './orderHistory';
import other from './other';
import overlay from './overlay';
import pagination from './pagination';
import placeOrderReducer from './placeorder';
import popover from './popover';
import settings from './settings';
import shareCard from './shareCard';
// import positions from './position';
import socket from './socket';
import theme from './theme';
import trade from './trade';
import tradePreference from './trade_preference';
import tradingView from './tradingView';
import transactions from './transactions';
import transferFundsHistory from './transferFundsHistory';
import user from './user';
import wallet from './wallet';
import withdrawalsHistory from './withdrawalsHistory';

const optionPersistConfig = {
  key: 'optionsChain',
  whitelist: ['optionChainVisualisation'],
  storage: storageSession,
};
const result = history =>
  combineReducers({
    settings,
    loading,
    bots,
    burgerMenu,
    trade,
    holdings,
    // l2Updates,
    l2Orderbook,
    notifications,
    orderbook,
    router: connectRouter(history),
    theme,
    user,
    wallet,
    markets,
    tradePreference,
    pagination,
    orderHistory,
    transferFundsHistory,
    fills,
    openStopOrders,
    openOrders,
    popover,
    holdingsTableFilter,
    transactions,
    assetHistory,
    other,
    cancellations,
    tradingView,
    overlay,
    appDrawer,
    shareCard,
    expiredProducts,
    // positions,
    optionsChain: persistReducer(optionPersistConfig, optionsChain),
    socket,
    offers,
    bellAlerts,
    basketOrders,
    layoutSetup,
    auth,
    account,
    mobileOrderBookOverLayout,
    withdrawalsHistory,
    placeOrder: placeOrderReducer,
    generalData,
  });

export default result;
