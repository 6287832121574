/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const AlreadyVerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={62}
    height={62}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="AlreadyVerifiedIcon">
    <path
      d="M31 61C14.431 61 1 47.569 1 31 1 14.431 14.431 1 31 1c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30Zm0-6a24 24 0 1 0 0-48 24 24 0 0 0 0 48Zm-2.991-12L15.28 30.271l4.242-4.242 8.487 8.487 16.968-16.971 4.245 4.242L28.009 43Z"
      fill="#00BD84"
      stroke="currentColor"
    />
  </svg>
);

export default AlreadyVerifiedIcon;
