import React, { memo, CSSProperties, ReactChild, useRef, useEffect } from 'react';

import cx from 'classnames';

import useToggle from 'hooks/useToggle';
import { SetTimeOut } from 'types/utils';

import styles from './collapsibleCard.module.scss';

interface CollapsibleCardProps {
  collapsedHeight?: number;
  expandedHeight?: number | 'fit-content';
  className?: string;
  role?: string;
  style?: CSSProperties;
  children(toggleCollapse: () => void, isCollapsed: boolean): ReactChild;
}

/**
 * @summary
 * Card with expand, collapse modes
 *
 * @example
 * ```tsx
 *    <CollapsibleCard>
 *     {
 *       (toggleCollapse, isCollapsed) => (
 *          <div>
 *             <div>
 *               {isCollapsed && <p>Rendered when collapsed</p>}
 *               {!isCollapsed && <p>Rendered when expanded</p>}
 *             </div>
 *            <button onClick={toggleCollapse}>Toggle Collapse</button>
 *          </div>
 *       )
 *     }
 *    </CollapsibleCard>
 * ```
 */
const CollapsibleCard = ({
  collapsedHeight,
  expandedHeight,
  className,
  children,
  style,
  role,
}: CollapsibleCardProps) => {
  const timerRef = useRef<SetTimeOut>();
  const [isCollapsed, toggleCollapse] = useToggle(true);

  // Adding delay to match collapse animation timing
  const handleDelayedCollapse = () => {
    timerRef.current = setTimeout(() => {
      toggleCollapse();
    }, 50);
  };

  useEffect(() => {
    return clearTimeout(timerRef.current as SetTimeOut);
  }, []);

  return (
    (<div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(role && { role })}
      className={cx(styles.collapsibleCard, { [`${className}`]: className })}
      style={{
        ...{ maxHeight: isCollapsed ? collapsedHeight : expandedHeight },
        ...style,
      }}
      data-palette="CollapsibleCard">
      {children(handleDelayedCollapse, isCollapsed)}
    </div>)
  );
};

CollapsibleCard.defaultProps = {
  collapsedHeight: 220,
  expandedHeight: 365,
};

export default memo(CollapsibleCard);
