import React, { memo, useCallback, useEffect, useRef } from 'react';

import Slider from 'react-slick';

import { SquareLeftChevron, SquareRightChevron } from 'styles/assets/icons';

import styles from './index.module.scss';
import ListItem from './Item';

const SampleNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    (<div
      data-testid="NUM7M8anQfLnuSWBCy11f"
      className={`${className} ${styles.slickArrowIcons}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      data-palette="SampleNextArrow">
      <SquareRightChevron />
    </div>)
  );
};

const SamplePrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    (<div
      data-testid="n3oJToRUAQ1OE-PXblRTW"
      className={`${className} ${styles.slickArrowIcons}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      data-palette="SamplePrevArrow">
      <SquareLeftChevron />
    </div>)
  );
};

const ExpiryDatesList = ({
  selectedExpiryDate,
  setSelectedExpiryDate,
  expiryDates,
  selectedAsset,
  sliderClassName,
  listItemClassName,
}) => {
  const sliderRef = useRef(null);
  const timerRef = useRef(false);
  const timeoutRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToScroll: 3,
    centerMode: false,
    variableWidth: true,
    draggable: true,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slideOnScroll = useCallback(e => {
    const { wheelDelta, deltaX } = e;
    if (deltaX < 0) {
      e.preventDefault();
    }
    if (!timerRef.current) {
      if (wheelDelta < 0) {
        if (sliderRef.current) {
          sliderRef.current.slickNext();
        }
      } else if (wheelDelta > 0) {
        if (sliderRef.current) {
          sliderRef.current.slickPrev();
        }
      }
      timerRef.current = true;
      timeoutRef.current = setTimeout(() => {
        timerRef.current = false;
      }, 1000);
      return null;
    }
  }, []);

  useEffect(() => {
    if (document.getElementById('expiryDatesSlider')) {
      document
        .getElementById('expiryDatesSlider')
        .addEventListener('wheel', slideOnScroll);
    }

    return () => {
      if (document.getElementById('expiryDatesSlider')) {
        document
          .getElementById('expiryDatesSlider')
          .removeEventListener('wheel', slideOnScroll);
      }
      clearTimeout(timeoutRef.current);
    };
  }, [selectedAsset]);

  const parentStyles = {
    paddingLeft: '25px',
    paddingRight: '25px',
  };

  if (expiryDates.length < 4) {
    parentStyles.paddingLeft = 0;
    parentStyles.paddingRight = 0;
  }

  const allExpiryItem = (
    <ListItem
      data-testid="d2jLli0_PbvGQ84tDknn6"
      key="All Expiries"
      item="All Expiries"
      extraClass={`ms-2 ${
        selectedExpiryDate === 'All Expiries' ? styles.active : ''
      } ${listItemClassName}`}
      onClick={setSelectedExpiryDate.bind(this, 'All Expiries')}
    />
  );

  if (expiryDates.length < 4) {
    return (
      (<div
        className={`flex-set flex-wrap ${styles.itemsList}`}
        data-palette="ExpiryDatesList">
        {allExpiryItem}
        {expiryDates.map(expiryDate => {
          return (
            <ListItem
              data-testid="4yUCvlNxHfOEDErS4atHU"
              key={expiryDate}
              item={expiryDate}
              extraClass={`${
                expiryDate === selectedExpiryDate ? styles.active : ''
              } ${listItemClassName} `}
              onClick={setSelectedExpiryDate.bind(this, expiryDate)}
            />
          );
        })}
      </div>)
    );
  }

  return (
    (<div
      id="expiryDatesSlider"
      style={parentStyles}
      className={`${styles.itemsList} ${sliderClassName}`}
      data-palette="ExpiryDatesList">
      <Slider ref={sliderRef} {...settings}>
        {allExpiryItem}
        {expiryDates.map(expiryDate => {
          return (
            <ListItem
              data-testid="OfKcYBULLVA3yZ79D7nFY"
              key={expiryDate}
              item={expiryDate}
              extraClass={`ms-2 ${
                expiryDate === selectedExpiryDate ? styles.active : ''
              } ${listItemClassName} `}
              onClick={setSelectedExpiryDate.bind(this, expiryDate)}
            />
          );
        })}
      </Slider>
    </div>)
  );
};

export default memo(ExpiryDatesList);
