import { useCallback, useRef, useState } from 'react';

import { copyToClipboard } from 'helpers/utils';
import { SetTimeOut } from 'types/utils';

/**
 * React Hook to copy text to clipboard
 * @param text - text to copy to clipboard
 * @returns isCopied - boolean to indicate if text is copied
 * @returns handleCopy - function to copy text to clipboard
 * Wont work in Material UI Dialogs. Use DeltaReactModal if need to used in Dialogs.
 * */
const useCopyToClipBoard = (
  text: string = '',
  options:
    | {
        toggleTime: number;
      }
    | undefined = {
    toggleTime: 1000,
  }
) => {
  const [isCopied, setIsCopied] = useState(false);
  const timerRef = useRef<SetTimeOut | null>(null);
  const handleCopy = useCallback(
    (overrideText?: string) => {
      setIsCopied(true);
      // navigator.clipboard.writeText(text);
      copyToClipboard(overrideText ?? text);

      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        setIsCopied(false);
        timerRef.current = null;
      }, options.toggleTime);
    },
    [options.toggleTime, text]
  );

  return { isCopied, handleCopy };
};

export default useCopyToClipBoard;
