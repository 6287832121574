import styled from 'styled-components';
import Table from './../components/table';
import { device } from './../helpers/screenSizes';

//todo-h: There are some styles remaining below that are from UITable, which require refactoring.
// See Git history. Start deleting styles that were not added by me.
// Start by removing all `!important` declarations.

/**
 * #ResponsiveTablesDocument (#tag for quick bookmarks/search)
 * ==========================================
 * Rules:
 * 1. 1920 [XXL]: padding L 10px, R flexible; font-size: 18px/16px (primary/secondary text);
 *    table should ideally fit on screen w/o overflowing columns.
 * 2. 1440 [XL]: LR 10px; font-size: 12px/10px; table should ideally fit on screen w/o overflowing columns.
 * 3. 1280 [LG]: LR 9px (start wrapping secondary text); font-size: 11.5px/9.5px; table scroll allowed
 * 4. 1024 [MD]: LR 3px, overflow-x: auto / scroll allowed by default
 */

const HoldingsTable = styled(Table)`
  color: ${props => props.theme.primaryText};
  font-size: 12.1px;
  line-height: 1.43;

  // Class for secondary font size / text (see #ResponsiveTablesDocument)
  .ht-secondary-text {
    font-size: 10px;
  }

  .realize-pnl {
    font-size: 10px;
  }

  /**
  /* Extensive Font-Size and Spacing Rules (Do NOT alter w/o referring to the #ResponsiveTablesDocument)
   * =====================================
   */

  .rt-tbody {
    .rt-td {
      padding: 5px 10px;
      border-right:0px !important;
    }
  }

  // High specificity styles (reqd for ReactTable overrides and to avoid !important)
  &.ReactTable {
    .rt-thead {
      .rt-th {
        padding: 5px 10px;
      }
    }
  }

  //todo-h: remove this style block when mobile-view uses cards instead of tables
  @media ${device.down.md} {
    font-size: 10px;

    .ht-secondary-text {
      font-size: 8px;
    }
  }

  // MD styles; 1024 and above
  @media ${device.up.md} {
    font-size: 12.1px;

    .ht-secondary-text {
      font-size: 10px;
    }

    .rt-tbody {
      .rt-td {
        padding: 3px 10px;
        height: 48px;
        min-height: 40px !important;
        max-height: 48px !important;
      }
    }

    &.ReactTable {
      .rt-thead {
        .rt-th {
          padding: 5px 10px;
        }
      }
    }
  }

  ${
    '' /* // LG styles; 1280 and above
  @media ${device.up.lg} {
    font-size: 12.1px;

    .ht-secondary-text {
      font-size: 9.5px;
    }

    .rt-tbody {
      .rt-td {
        padding: 5px 9px;
      }
    }

    &.ReactTable {
      .rt-thead {
        .rt-th {
          padding: 5px 9px;
        }
      }
    }
  }

  // XL styles; 1440 and above
  @media ${device.up.xl} {
    font-size: 12.1px;

    .ht-secondary-text {
      font-size: 10px;
    }

    .rt-tbody {
      .rt-td {
        padding: 5px 10px;
      }
    }

    &.ReactTable {
      .rt-thead {
        .rt-th {
          padding: 5px 10px;
        }
      }
    }
  }

  // XXL styles; 1920+
  @media ${device.up.xxl} {
    font-size: 16px;

    .ht-secondary-text {
      font-size: 16px;
    }

    .rt-tbody {
      .rt-td {
        padding: 5px 10px;
      }
    }

    &.ReactTable {
      .rt-thead {
        .rt-th {
          padding: 5px 10px;
        }
      }
    }
  } */
  }

  //todo-h: scan the styles below and remove all font-size and padding variants
  // + replace high-spec styles with an 'id' selector

  // High specificity styles (reqd for ReactTable overrides and to avoid !important)
  &.ReactTable {
    border: none;

    .rt-thead {
      .rt-tr {
        text-align: left;
      }
    }

    .rt-tbody {
      .rt-tr-group {
        border-bottom: none;
      }
    }

    //todo-h: Remove the following rule when new Empty States go live.
    .rt-noData {
      top: 90%;
    }
  }

  .rt-thead {
    border-bottom: 1px solid ${props => props.theme.separator};
  }

  .-loading.-active {
    background: ${props => props.theme.primaryBackground} !important;
  }

  .rt-tbody {
    color: ${props => props.theme.primaryText};

    .rt-tr-group {
      flex: none;
    }

    //todo-h: these styles would never work (ellipsis/flex). who added these?
    .rt-td {
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: center;
      overflow: hidden;

      .profit,
      .loss {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        justify-content: center;
      }

      //todo-h: remove this already-existing style when mobile-view uses cards instead of tables
      @media ${device.down.xs} {
        font-size: 10px;
      }
    }
  }

  .pagination-bottom {
    background: ${props => props.theme.primaryBackground};
    font-size: 12.1px;
    margin-top: 20px;

    @media ${device.down.sm} {
      margin-top: 20px;
    }

    .-pagination {
      box-shadow: none;
      border: none;
      padding: 0;

      .-pageInfo {
        margin: 2px 10px;

        input {
          padding: 3px 7px;
          background: ${props => props.theme.primaryBackground};
        }
      }

      .-previous,
      .-next {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // styles copied from UITableChange

  // ========================
  // ALL NEW STYLES ARE BELOW
  // ========================

  .rt-thead {
    .rt-tr {
      padding-left: 0;
      border-bottom: 1px solid ${props => props.theme.separator};
    }
  }

  .rt-tbody {
    .rt-tr {
      padding-left: 5px; // same as the width of .holdings-indicator
      position: relative;
    }
  }

  .rt-thead {
    border-bottom: none;
    color:${props => props.theme.secondaryText} ;

    .rt-th {
      // Let table headers wrap text
      white-space: normal;

      display: flex;
      align-items: center;
    }
    &.-header {
      min-height: 45px;
      box-shadow: 0px 0px 0px 0px !important;
    }
    }

    &.-header,
    &.-headerGroups {
      // background-color: rgba(48, 61, 78, 0.3);
      background-color: #202a37 !important;
      box-shadow: none !important;
    }
  }

  .rt-tbody {
    /* max-height: 230px; // = 45px * 5 rows + 5px accounting for extra spacing and border */
    margin-bottom: -1px !important;
    .rt-tr-group {
      .rt-tr {
        &.-even {
          background-color: ${props => props.theme.primaryBackground};
          .share-cell {
            svg {
              g {
                fill: ${props => props.theme.primaryBackground};
              }
            }
            text-align: center;
            cursor: pointer;
          }
        }
        &.-odd {
          background-color: ${props => props.theme.primaryBackground};
          .share-cell {
            svg {
              g {
                fill: ${props => props.theme.primaryBackground};
              }
            }
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .rt-td {
      min-height: 50px; // fixed height cells
      display: flex;
      align-items: center;
      align-self: stretch; // allow the cells to take full-height of the parent

      .redirect-icon {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }

  // High specificity styles (reqd for ReactTable overrides and to avoid !important)
  &.ReactTable {
    .rt-thead {
      .rt-th {
        border-right: none;
      }
      .rt-resizable-header {
        &:hover {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            display: block;
            border-left: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-top: 10px solid ${props => props.theme.tertiaryBackground};
          }
        }
      }
    }
  }

  // High specificity styles (reqd for ReactTable overrides and to avoid !important)
  &.ReactTable {
    .rt-tbody {
      .rt-td {
        //todo-h: See refactoring Note
        border-bottom: 1px solid ${props => props.theme.separator};
      }

      .rt-tr-group {
        &:last-child {
          border-bottom: 1px solid ${props => props.theme.primaryBackground};
        }
      }
    }
  }

  // Holdings Indicator
  // Remove padding from header
  .rt-thead {
    > .rt-tr {
      > .rt-th {
        &:first-child {
          padding: 0;
        }
      }
    }
  }
  // Remove padding from body
  &.ReactTable {
    .holdings-indicator {
      padding: 0;
    }
  }
`;

export default HoldingsTable;
