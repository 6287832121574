/* eslint-disable import/no-unresolved */
import { ORDER_SIDE } from 'constants/constants';

import React from 'react';

import cx from 'classnames';
import { useBasketBuySellButtonState } from 'hooks';
import { Product } from 'types/IProducts';

import styles from './productsSelection.module.scss';

interface IBuySellButtonProps {
  product: Product;
}

const BuySellButton: React.FunctionComponent<IBuySellButtonProps> = ({ product }) => {
  const { buyActive, sellActive, handleBuySellClick } = useBasketBuySellButtonState(
    product,
    'Contracts Page'
  );

  return (
    (<div className={styles['buy-sell-button-area']} data-palette="BuySellButton">
      <button
        type="button"
        data-testid="buy-button"
        className={cx({
          [styles.buy]: true,
          [styles['buy-active']]: buyActive,
        })}
        onClick={() => handleBuySellClick(ORDER_SIDE.BUY, false)}>
        B
      </button>
      <button
        type="button"
        data-testid="sell-button"
        className={cx({
          [styles.sell]: true,
          [styles['sell-active']]: sellActive,
        })}
        onClick={() => handleBuySellClick(ORDER_SIDE.SELL, false)}>
        S
      </button>
    </div>)
  );
};

export default BuySellButton;
