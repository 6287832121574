/* eslint-disable prettier/prettier */
/* eslint-disable test-selectors/onClick */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable test-selectors/onChange */
import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ClickOutside from 'components/click-outside';
import Render from 'components/render';
import {
  TABLE_DATA_FETCH_COUNT,
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
} from 'constants/constants';
import { localeNumber, isNegative, getInt } from 'helpers/utils';
import { ArrowDown, AngleLeft, AngleRightIcon } from 'styles/assets/icons';
import FormInput from 'UIKit/FormInput';

import { StyledTablePagination, Options, OptionItem, Divider } from './table_styles';

const TablePagination = props => {
  const { t } = useTranslation(['common']);
  const {
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    count,
    options,
    shouldHideRange,
    originalData,
    fetchNextData,
    currentRangeData,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const [showOptions, toggleOptions] = useState(false);

  const onSelectIconClick = () => toggleOptions(!showOptions);

  const changeRowsPerPage = value => {
    setRowsPerPage(value);
    setPage(0); // reset page
  };

  const persistRowsPerPage = value => {
    onRowsPerPageChange(value); // call rows per page function (used to persist state)
    onPageChange(0); // call on page change function (used to persist state)
  };

  const onItemClick = value => () => {
    changeRowsPerPage(value);
    persistRowsPerPage(value);
    toggleOptions(false);
  };

  const invalidInput = value => {
    const valueNo = getInt(value) || 0;
    const greaterThanLimit = valueNo > TABLE_DATA_FETCH_COUNT;
    return isNegative(value) || greaterThanLimit;
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;
    if (invalidInput(value)) {
      return;
    }
    changeRowsPerPage(getInt(value) || value); // NaN || '5' => '5'
  };

  const handleInputBlur = () => persistRowsPerPage(rowsPerPage);

  const addPerPage = k => k + rowsPerPage;

  const from = count === 0 ? 0 : page * rowsPerPage + 1;
  const to = Math.min(count, (page + 1) * rowsPerPage);

  // Checks whether to fetch the next range of data
  const shouldFetchNextData = () => {
    const nextData = [...originalData].slice(addPerPage(from) - 1, addPerPage(to));
    return nextData.length !== rowsPerPage;
  };

  // Checks if next range of data is fetched
  const isNextDataFetched = () => !!currentRangeData.length;

  const changePage = changeBy => {
    const nextPage = page + changeBy;
    setPage(nextPage);
    onPageChange(nextPage);
  };

  const onPrevClick = () => changePage(-1);

  const onNextClick = () => {
    if (shouldFetchNextData()) {
      const apiPageSize = TABLE_DATA_FETCH_COUNT;
      fetchNextData({ page, rowsPerPage, apiPageSize });
    }
    changePage(1);
  };

  const disabled = {
    prev: page === 0, // when page == 0
    next: page >= Math.ceil(count / rowsPerPage) - 1 || !isNextDataFetched(), // when page is greater than data && next set of data is not fetched
  };

  // Return if count or originalData is empty/zero.
  // count < 5 = Product requirement
  const shouldHideControls = () => {
    const noCount = !count;
    const noData = !originalData.length;
    const itemsLessThen5 = count < DEFAULT_PAGINATION_ROWS_PER_PAGE;
    return noCount || noData || itemsLessThen5;
  };

  if (shouldHideControls()) {
    return null;
  }

  return (
    <StyledTablePagination className="pagination">
      <div className="left">
        <div className="label">{t('common:pagination.itemPerPage')}</div>
        <div className="inputs">
          <FormInput
            className="input-text"
            value={rowsPerPage}
            type="number"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
          <ArrowDown
            className="input-icon"
            onClick={onSelectIconClick}
          />
          <Render when={showOptions}>
            <ClickOutside onOutsideClick={() => toggleOptions(false)}>
              <Options className="pagination-options">
                {options.map(option => (
                  <OptionItem
                    data-testid="OOOZ7NvPs3hz_pFXscbjz"
                    key={option}
                    className={classNames('pagination-option-item', {
                      'option-item-active': rowsPerPage === option,
                    })}
                    onClick={onItemClick(option)}
                    isSelected={rowsPerPage === option}
                  >
                    {option}
                  </OptionItem>
                ))}
              </Options>
            </ClickOutside>
          </Render>
        </div>
      </div>

      <Divider className="divider" shouldHideRange={shouldHideRange} />

      <div className="right">
        <Render when={!shouldHideRange}>
          <div className="range">
            <span className="from-to">
              {from} - {to}
            </span>{' '}
            <span className="count">
              {t('common:pagination.of')} {localeNumber(count)}
            </span>
          </div>
        </Render>
        <div className="nav">
          <IconButton
            data-testid="YkrA_7B9k6pIDSQAOfHaB"
            aria-label="previous page"
            component="span"
            classes={{
              root: 'icon-btn-root',
              disabled: 'icon-btn-disabled',
            }}
            disabled={disabled.prev}
            onClick={onPrevClick}
          >
            {/* <FaChevronLeft className="nav-icon" />
             */}
            <AngleLeft className="nav-icon" />
          </IconButton>
          <IconButton
            data-testid="Y6me0n3h5v0K7QwfO07eA"
            aria-label="Next page"
            component="span"
            classes={{
              root: 'icon-btn-root',
              disabled: 'icon-btn-disabled',
            }}
            disabled={disabled.next}
            onClick={onNextClick}
          >
            {/* <FaChevronRight className="nav-icon" /> */}
            <AngleRightIcon className="nav-icon" />
          </IconButton>
        </div>
      </div>
    </StyledTablePagination>
  );
};

TablePagination.defaultProps = {
  fetchNextData: () => null,
  onPageChange: () => null,
  onRowsPerPageChange: () => null,
};

// TablePagination.propTypes = {
//   rowsPerPage: PropTypes.stringOrNumber.isRequired,
//   setRowsPerPage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   setPage: PropTypes.func.isRequired,
//   count: PropTypes.number.isRequired,
//   options: PropTypes.arrayOf(PropTypes.number).isRequired,
//   shouldHideRange: PropTypes.bool.isRequired,
//   fetchNextData: PropTypes.func,
//   currentRangeData: PropTypes.array.isRequired,
//   originalData: PropTypes.array.isRequired,
//   onPageChange: PropTypes.func,
//   onRowsPerPageChange: PropTypes.func,
// };

export default React.memo(TablePagination);
