/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';

import {
  deleteBasketResult,
  estimateBasketMargin,
  placeBasketOrder,
  showBasketError,
} from 'actions/basketOrders';
import { RenderForGlobalExchange } from 'components/render/RenderByExchangeType';
import SpinnerLoader from 'components/spinnerLoader';
import {
  IS_INDIAN_EXCHANGE,
  ORDER_SIDE,
  VANILLA_SETTLING_ASSET,
} from 'constants/constants';
import { ORDER_TYPE } from 'constants/enums';
import { cropAfterDecimals, snakeCaseToSentenceCase } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
import { useToggle } from 'hooks';
import useAssetBalance from 'hooks/componentHooks/useAssetBalance';
import {
  basketOrderOrdersSelector,
  productsSelector,
  selectedProductSelector,
  showBasketLoadingSelector,
  userSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { DangerTriangle } from 'styles/assets/icons';

import { ReloadIcon } from '../icons';
import { BasketEstimateMarginSuccessResult } from '../interface';
import styles from './orders.module.scss';

interface Props {
  asset: string;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const EstimateBasketMarginAndPlaceorder = ({ asset, setShow }: Props) => {
  const [orderMargin, setMargin] = useState(0);
  const [isError, setisError] = useState({
    value: false,
    errorMessage: '',
  });

  const dispatch = useAppDispatch();

  const { t } = useTranslation(['trading', 'errors', 'basketOrders']);
  const [marginloading, togglemarginloading] = useToggle(false);

  const ordersInBasket = useAppSelector(state => basketOrderOrdersSelector(state));
  const showLoading = useAppSelector(state => showBasketLoadingSelector(state));
  const products = useAppSelector(state => productsSelector(state));
  const selectedProduct = useAppSelector(state => selectedProductSelector(state));
  const { margin_mode: marginMode } = useAppSelector(userSelector);
  const enabledIndexSymbol =
    asset === 'BTC'
      ? `.DEXBT${VANILLA_SETTLING_ASSET}`
      : `.DE${asset}${VANILLA_SETTLING_ASSET}`;

  const selectedAsset = !isMobile
    ? selectedProduct?.settling_asset?.symbol
    : VANILLA_SETTLING_ASSET;
  const { roundedAvailBalance } = useAssetBalance(selectedAsset!);

  const liveOrdersInbasket = Object.values(ordersInBasket).filter(
    order => products[order.product_id] && order.asset === asset
  );
  const ordersArray = liveOrdersInbasket.map(order => {
    const { limit_price, size, time_in_force, order_type, symbol, side, product_id } =
      order;
    return {
      limit_price,
      size,
      time_in_force,
      order_type,
      symbol,
      side,
      product_id,
    };
  });

  const checkError = () =>
    ordersArray.reduce(
      (acc: any, order: any) => {
        if (order.order_type === ORDER_TYPE.LIMIT && !order.limit_price) {
          return { value: true };
        }
        if (!Number(order.size)) {
          return { value: true };
        }
        return acc;
      },
      { value: false }
    );

  const getBasketOrdersMixpanelPayload = (orders: any) => {
    const mixpanelPayload = orders.reduce(
      (acc: any, order: any) => {
        const { order_type, side } = order;
        const result = { ...acc };
        if (order_type === ORDER_TYPE.LIMIT) {
          result.limitOrders += 1;
        } else if (order_type === ORDER_TYPE.MARKET) {
          result.marketOrders += 1;
        }
        if (side === ORDER_SIDE.BUY) {
          result.buyOrders += 1;
        } else {
          result.sellOrders += 1;
        }
        return result;
      },
      {
        underlyingCoin: asset,
        limitOrders: 0,
        marketOrders: 0,
        buyOrders: 0,
        sellOrders: 0,
        numberOfContracts: orders.length,
        basketOrder: true,
        marginMode,
        source: 'Basket Order',
      }
    );
    return mixpanelPayload;
  };

  const trackEstimateMarginClick = () => {
    trackMixpanelEvent(`Basket Order - Margin Checked`, {
      underlying_coin: asset,
      margin_type: `Portfolio Margin`,
    });
  };

  // eslint-disable-next-line consistent-return
  const onEstimateMarginClick = async () => {
    const validationError = checkError();
    if (validationError.value) {
      dispatch(showBasketError(validationError.value));
    } else {
      togglemarginloading();
      const estMarginResult = await dispatch(
        estimateBasketMargin({
          index_symbol: enabledIndexSymbol,
          orders: ordersArray,
        })
      )
        .then((result: BasketEstimateMarginSuccessResult) => {
          if (result?.success) {
            const margin = result?.result?.additional_required_margin;
            setMargin(margin);
            togglemarginloading();
            dispatch(showBasketError(false));
            // if (Number(margin) > Number(balance?.available_balance)) {
            //   setisError({
            //     value: true,
            //     errorMessage: '',
            //   });
            // } else {
            //   setisError({
            //     value: false,
            //     errorMessage: '',
            //   });
            // }
          } else {
            dispatch(showBasketError(false));
            setShow(true);
            setisError({
              value: true,
              errorMessage: result?.error?.code || t('errors:defaultMessage'),
            });
          }
          return result;
        })
        .catch(err => {
          togglemarginloading();
          setisError({
            value: true,
            errorMessage: err.response?.body?.error?.code || t('errors:defaultMessage'),
          });
          return err.response?.body;
        });
      return estMarginResult;
    }
  };

  const onPlaceOrderClick = async () => {
    dispatch(deleteBasketResult());
    const validationError = checkError();
    if (validationError.value) {
      dispatch(showBasketError(validationError.value));
    } else {
      const mixpanelPayload = getBasketOrdersMixpanelPayload(ordersArray);
      await onEstimateMarginClick();
      dispatch(
        placeBasketOrder(
          {
            index_symbol: enabledIndexSymbol,
            orders: ordersArray,
          },
          mixpanelPayload
        )
      )
        .then()
        .catch((err: { response: { body: { error: { code: any } } } }) => {
          setisError({
            value: true,
            errorMessage: err.response?.body?.error?.code || t('errors:defaultMessage'),
          });
        });
    }
  };

  useEffect(() => {
    onEstimateMarginClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersInBasket]);

  return (
    (<div
      className={styles['margins-container']}
      data-palette="EstimateBasketMarginAndPlaceorder">
      <div className={styles.margins}>
        <div className={styles['margin-row']}>
          <div className={styles['margin-label']}>
            {t(`basketOrders:${IS_INDIAN_EXCHANGE ? 'requiredMargin' : 'orderMargin'}`)}{' '}
            <button
              type="button"
              data-testid="on-estimate-margin-click"
              className={styles.reload}
              onClick={() => {
                onEstimateMarginClick();
                trackEstimateMarginClick();
              }}
              disabled={marginloading}
            >
              {marginloading ? <SpinnerLoader /> : <ReloadIcon />}
            </button>
          </div>
          <div className={styles['margin-value']}>
            {cropAfterDecimals(
              orderMargin,
              selectedProduct?.settling_asset?.minimum_precision
            ) || '-'}{' '}
            {selectedAsset}
          </div>
        </div>
        <div className={styles['margin-row']}>
          <div className={styles['margin-label']}>
            {t('basketOrders:availableMargin')}
          </div>
          <div className={styles['margin-value']}>
            {roundedAvailBalance} {selectedAsset}
          </div>
        </div>
        <RenderForGlobalExchange>
          <MediaQuery query={device.down.md}>
            <div className={styles['margin-row']}>
              <div className={styles['margin-label']}>{t('basketOrders:marginMode')}</div>
              <div className={styles['margin-value']}>
                {t(`portfolioMargin:${marginMode}`)}
              </div>
            </div>
          </MediaQuery>
        </RenderForGlobalExchange>
      </div>
      <div className={styles.buttonArea}>
        <button
          type="button"
          data-testid="placeorder-button"
          className={styles['placeorder-button']}
          onClick={onPlaceOrderClick}
        >
          {showLoading ? <SpinnerLoader /> : t('trading:placeOrder')}
        </button>
        {isError.value && (
          <div className={styles.error} data-palette="EstimateBasketMarginAndPlaceorder">
            {' '}
            <DangerTriangle /> {snakeCaseToSentenceCase(isError.errorMessage)}
          </div>
        )}
      </div>
    </div>)
  );
};

export default EstimateBasketMarginAndPlaceorder;
