import { useLayoutEffect, useState } from 'react';
const viewport = window.visualViewport;
function useMobileWindowSize() {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(viewport.height);
    }
    window.visualViewport.addEventListener('resize', updateSize);
    updateSize();
    return () =>
      window.visualViewport.removeEventListener('resize', updateSize);
  }, []);
  return height;
}

export default useMobileWindowSize;
