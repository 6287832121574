import styled from 'styled-components';

export const ErrorScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  text-align: center;

  /* Setting screen position fixed to so it covers the whole page */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: ${props => props.theme.primaryBackground};

  .error-img-wrapper {
    width: 385px;
    margin-bottom: 2.5rem;

    .error-img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .sorry {
    font-size: 2.2rem;
    font-family: var(--fontRegular);
    margin-bottom: 1.8rem;
  }

  /* todo-h: replace with button component from style guide */
  .reload-button {
    cursor: pointer;
    background-color: ${props => props.theme.primaryTheme};
    border: 1px solid ${props => props.theme.primaryTheme};
    font-size: 1.3rem;
    padding: 0.2rem 2rem;
    margin-bottom: 2rem;
    color: var(--primaryCtaText);
  }

  .signout-text {
    font-size: 1.3rem;
    font-family: var(--fontRegular);
  }

  /* todo-h: this won't be reqd if btn-link is imported; check twbs button imports and fix them in a separate PR. */
  .signout-link {
    cursor: pointer;
    text-decoration: underline;
    border: none;

    &:hover {
      color: ${props => props.theme.primaryTheme};
    }
  }

  /* todo-h: xs styles; replace with scss variable */
  @media (max-width: 576px) {
    .sorry {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .reload-button {
      font-size: 0.8rem;
      margin-bottom: 1rem;
      padding: 0.1rem 1.4rem;
    }

    .signout-text {
      font-size: 0.8rem;
    }

    .error-img-wrapper {
      width: 220px;
    }
  }
`;

export const UpdatePageModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  text-align: center;
  .update-text {
    color: ${props => props.theme.primaryText};
  }
  .update-button {
    margin-top: 10px;
    float: right;
  }
`;
