/* eslint-disable import/no-named-as-default */
/* eslint-disable test-selectors/onClick */
/* eslint-disable test-selectors/onChange */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';

import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import HoldingsActionButton from 'components/button';
import { NUMBER_TYPES } from 'constants/constants';
import {
  getEffectiveLeverage,
  round,
  positionMaxRemovableMargin,
  calculateInitialMargin,
  calculateMaxLeverage,
  // calculateImpactPrice,
} from 'helpers/formulas';
import { equals, isNil } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import { cropAfterDecimals, getFormattedInputValue, isOptions } from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import { balanceState } from 'selectors';
import {
  // markPriceSelectorById,
  openPositionMarkPriceSelectorById,
  spotPriceSelectorBySymbol,
  // spotPriceSelectorBySymbol,
} from 'selectors/priceSelectors';
// import { Vertical, CancelSvg } from 'components/customicons/common';
import { Vertical, CancelSvg } from 'styles/assets/icons';
import NumberInput from 'UIKit/NumberInput';

const Title = styled.h1`
  font-size: 14px;
  font-family: var(--fontRegular);
  color: ${props => props.theme.primaryText};
  background-color: ${props => props.theme.primaryBackground};
  height: 52px;
  text-align: center;
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.separator};

  @media ${device.down.xs} {
    align-items: center;
    text-align: center;
    padding: 15px;
    background-color: ${props => props.theme.primaryBackground};
    border-bottom: 0.8px solid ${props => props.theme.separator};
  }
`;

const PositionMarginWrapper = styled.div`
  background: ${props => props.theme.primaryBackground};
  width: 384px;
  height: 390px;
  color: ${props => props.theme.secondaryText};

  @media ${device.down.xs} {
    width: 100vw;
    height: 410px;
    margin-top: 0px;
  }

  .title-wrapper {
    @media ${device.down.xs} {
      background-color: ${props => props.theme.primaryBackground} !important;
      height: 42px;
    }
  }

  .cancel-btn {
    display: none;

    @media ${device.down.xs} {
      display: inline;
      float: right;
      margin-top: -47px;
      margin-right: 27px;
    }
  }
  .container {
    height: 36px;
    background-color: var(--inputBackground);
    border: 1px solid var(--inputBorderColor);
    border-radius: var(--inputBorderRadius);
    width: 320px;
    display: flex;

    @media ${device.down.xs} {
      width: 345px;
      margin-left: 0px;
    }
  }
  .input-section {
    color: ${props => props.theme.primaryText};
    background-color: var(--inputBackground);
    height: 100%;
    border-radius: 2px;
    font-size: 12.1px;
    padding: 9px;
  }
  .max-btn {
    font-size: 14px;
    font-family: var(--fontRegular) !important;
    color: ${props => props.theme.primaryTheme};
    border-radius: 2px;
    cursor: pointer;
    padding: 10px 0px 11px 13px;
  }

  .symbol {
    color: ${props => props.theme.secondaryText};
    font-size: 12.1px;
    padding: 9px 15px 12px 9px;
  }
`;
export const CancelButton = styled(HoldingsActionButton)`
  width: 152px;
  height: 36px;
  border: 1px solid ${props => props.theme.secondaryText};
  background-color: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.secondaryText};

  @media ${device.down.xs} {
    display: none;
  }

  &:hover {
    background: ${props => props.theme.secondaryBackground};
  }
`;

export const ConfirmButton = styled(HoldingsActionButton)`
  background-color: ${props => props.theme.primaryTheme};
  width: 152px;
  height: 36px;
  border-radius: 2px;
  color: var(--primaryCtaText);

  &:hover {
    background-color: var(--primaryButtonHover) !important;
  }

  @media ${device.down.md} {
    font-size: 12.1px;
  }

  @media ${device.down.xs} {
    width: 100%;
    height: 42px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;
const SpaceAround = styled.div`
  display: flex;
  width: 320px;
  margin: auto;
  margin-top: 25px;
  column-gap: 15px;

  @media ${device.down.xs} {
    width: 351px;
    margin-top: 0px;
  }
`;

const SpaceEvenly = styled(SpaceAround)`
  width: 384px;
  display: flex;
  column-gap: 10px;
  padding-left: 32px;
  margin-top: 25px;

  @media ${device.down.xs} {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 14px;
    padding: 20px 0px 0px 10px;
  }

  .input-area {
    display: flex;
  }

  .toggle-text {
    color: ${props => props.theme.secondaryText};
    cursor: none;
    font-size: 12.1px;
    height: 30px;
    width: 155px;
    background-color: var(--unselectedTab);
    font-family: var(--fontRegular);
    display: flex;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid transparent;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media ${device.down.xs} {
      font-size: 12.1px;
      width: 165px;
      justify-content: center;
      height: 42px;
      text-align: center;
      display: flex;
    }
  }

  .select-text {
    color: ${props => props.theme.primaryText};
    border: 1px solid ${props => props.theme.primaryTheme};
    background-color: ${props => props.theme.secondaryBackground};
    width: 155px;
    font-size: 12.1px;
    height: 30px;
    width: 155px;
    font-family: var(--fontRegular);
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    align-items: center;

    @media ${device.down.xs} {
      font-size: 12.1px;
      width: 165px;
      height: 42px;
      justify-content: center;
      text-align: center;
      display: flex;
    }
  }
`;

const ContentWrapper = styled.div`
  font-size: 12.1px;
  background: ${props => props.theme.secondaryBackground};
  width: 320px;
  margin: auto;
  height: auto;
  margin-top: 30px !important;

  @media ${device.down.xs} {
    text-align: left;
    font-size: 12.1px;
    width: 344px;
    margin-top: 30px;
  }
`;

const LowerContentWrapper = styled.div`
  font-size: 12.1px;
  color: ${props => props.theme.secondaryText};
  margin-top: 25px !important;
  @media ${device.down.xs} {
    width: 345px;
    margin: auto;
  }

  .alignleft {
    font-size: 12.1px;
    float: left;
    margin: -5px 0px 0px 32px;

    @media ${device.down.xs} {
      margin-left: 0px;
      margin-top: -2px;
    }
  }
  .alignright {
    font-size: 10px;
    float: right;
    margin: 0px 31px 4px 0px;

    @media ${device.down.xs} {
      margin-right: 0px;
    }
  }

  .btn {
    float: left;
    font-size: 12.1px;
    font-family: var(--fontRegular) !important;
    min-width: 57px;
    height: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: ${props => props.theme.primaryTheme};
    border-radius: 2px;
    cursor: pointer;
    white-space: nowrap;
  }
`;

const TextSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  max-width: 50%;
  color: ${props => props.theme.secondaryText};

  @media ${device.down.xxs} {
    width: calc(50% - 3px) !important;
  }
`;

const WhiteSpan = styled.span`
  color: ${props => props.theme.primaryText};
  font-family: var(--fontRegular);
  display: flex;
`;

const TextWappper = styled.span`
  padding: 5px 0;
  // display: flex;
  // justify-content: space-between;
  // position: relative;
  display: grid;
  grid-template-columns: 39% 30% 7% 28%;
  padding: 9px 13px 9px 8px;
  margin-top: 17px;

  .left-text {
    // position: fixed;
    // margin-left: 110px;
    display: flex;
    justify-content: right;
    padding-right: 2px;
  }

  .right-text {
    // float: right;
    // width: 80px;
    display: flex;
    justify-content: right;
    // padding-right: 5px;
  }

  .center {
    text-align: center;
    // position: fixed;
    // margin-left: 200px;
  }

  .center-text {
    // position: fixed;
    // margin-left: 200px;
    text-align: center;
    margin-top: -6px;
  }

  .left-second {
    margin-top: -4px;
    max-width: unset;
  }
  .second-left {
    // position: fixed;
    // margin-left: 140px;
    margin-top: -4px;
    display: flex;
    justify-content: right;
    padding-right: 2px;
  }

  .right-second {
    margin-top: -4px;
    display: flex;
    justify-content: right;
    padding-right: 2px;
  }

  @media ${device.down.xs} {
    grid-template-columns: 39% 30% 7% 28%;
  }
`;

const ClickableWhiteSpan = styled.span`
  color: ${props => props.theme.primaryText};
  font-family: var(--fontRegular);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

// const ActionButton = styled(Button)`
//   border-radius: 4px;
// `;

const MarginRadioButtonGroup = ({ mode, onClick }) => {
  const { t } = useTranslation(['trading']);
  return (
    <SpaceEvenly>
      <div
        data-testid="qU_l7lAClbW2zIE-qe4Wc"
        aria-hidden="true"
        onClick={() => onClick('add')}
        checked={mode === 'add'}
        className={`${mode === 'add' ? 'select-text' : 'toggle-text'}`}
        // className={AddText}
      >
        {' '}
        {t('trading:addMargin')}
      </div>
      {}
      <div
        data-testid="whTUmahp48XSdfp5d4ckP"
        aria-hidden="true"
        onClick={() => onClick('remove')}
        checked={mode === 'remove'}
        className={`${mode === 'remove' ? 'select-text' : 'toggle-text'}`}
      >
        {t('trading:removeMargin')}
      </div>
    </SpaceEvenly>
  );
};

const getMarginValue = symbol => {
  switch (symbol) {
    case 'BTC':
      return 0.0001;
    case 'USDC':
      return 0.1;
    default:
      return 0.0001;
  }
};

class PositionMargin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'add',
      margin: getMarginValue(this.props.position.product.settling_asset.symbol),
    };
  }

  onRadioClick = value => {
    this.setState({
      mode: value,
      margin: getMarginValue(),
    });
  };

  submit = () => {
    const { mode } = this.state;
    const margin = `${mode === 'remove' ? '-' : ''}${this.state.margin}`;
    this.props.submitMargin(margin);
  };

  onInputChange = ({ target: { value } }) => {
    const formattedVal = getFormattedInputValue(value, this.state.margin);
    this.setState({
      margin: formattedVal,
    });
  };
  // handleClick = () => {
  //   console.log('handleclc', this.state.margin);
  //   this.setState({
  //     margin: '',
  //   });
  // };

  shouldComponentUpdate(nextProps, nextState) {
    const { position, balances } = nextProps;
    const availableMargin =
      balances[position.product.settling_asset.id].available_balance;
    const { mode, margin } = nextState;

    const shouldUpdate =
      !equals(position, this.props.position) ||
      !equals(
        availableMargin,
        this.props.balances[position.product.settling_asset.id].available_balance
      ) ||
      !equals(mode, this.state.mode) ||
      !equals(margin, this.state.margin);

    return shouldUpdate;
  }

  render() {
    const { position, balances, positionProductId, cancel, t } = this.props;
    const availableMargin =
      balances[position.product.settling_asset.id].available_balance;

    const markPriceObj = openPositionMarkPriceSelectorById(positionProductId);
    const { mark_price: markPrice } = markPriceObj;
    const spotPrice = spotPriceSelectorBySymbol(position.product.spot_index.symbol);
    const initial_margin = calculateInitialMargin(
      position.product,
      position.size,
      position.entry_price
    );
    const maxLeverage = calculateMaxLeverage(initial_margin);
    // const impactPrice = calculateImpactPrice(
    //   this.props.orderbooks,
    //   markPrice,
    //   position.size,
    //   position.product
    // );

    const positionObj = {
      realized_funding: position.realized_funding,
      realized_pnl: position.realized_pnl,
      size: position.size,
      margin: position.margin,
    };

    const { mode } = this.state;
    const currentLeverage =
      position.product?.contract_type === 'interest_rate_swaps' ||
      (isOptions(position.product?.contract_type) && position.size > 0)
        ? null
        : getEffectiveLeverage(
            position.product,
            position.entry_price,
            markPrice,
            spotPrice,
            positionObj,
            position.created_at
          );

    const maxValue =
      mode === 'remove'
        ? round(
            positionMaxRemovableMargin(
              position.product,
              position.size,
              position.entry_price,
              markPrice,
              position.margin,
              spotPrice,
              maxLeverage
            ),
            position.product.settling_asset.minimum_precision
          ) // 0 if position margin is less than min required margin
        : cropAfterDecimals(
            availableMargin,
            position.product.settling_asset.minimum_precision
          );

    const newLeveragepositionObj = {
      realized_funding: position.realized_funding,
      realized_pnl: position.realized_pnl,
      size: position.size,
      margin:
        parseFloat(position.margin) +
        (mode === 'remove' ? -1 : 1) *
          parseFloat(this.state.margin ? Math.min(this.state.margin, maxValue) : 0),
    };
    const currentMargin =
      mode === 'add'
        ? Number(position.margin) + Number(this.state.margin)
        : Number(position.margin) - Number(this.state.margin);

    const currentMarginVal = String(currentMargin).substring(0, 6);
    const newLeverage =
      position.product?.contract_type === 'interest_rate_swaps' ||
      (isOptions(position.product.contract_type) && position.size > 0)
        ? null
        : getEffectiveLeverage(
            position.product,
            position.entry_price,
            markPrice,
            spotPrice,
            newLeveragepositionObj,
            position.created_at
          );
    const handleMax = () => {
      this.setState({
        margin: maxValue,
      });
    };

    return (
      <PositionMarginWrapper>
        <div className="title-wrapper">
          <Title>{t('trading:addRemovePosMargin')}</Title>
          <div
            data-testid="WUAlt1MQCNofiyDWU4WZt"
            aria-hidden="true"
            className="cancel-btn"
            onClick={cancel}
          >
            <CancelSvg />
          </div>
        </div>
        <MarginRadioButtonGroup
          data-testid="9gCt9Y76CZgLKDkPXRFCW"
          mode={this.state.mode}
          onClick={this.onRadioClick}
        />
        <LowerContentWrapper>
          <div>
            <label className="alignleft" htmlFor="margin">
              {mode === 'add' ? t('trading:add') : t('trading:remove')}
            </label>
            <p className="alignright">
              {mode === 'add' ? t('trading:available') : t('trading:removable')}{' '}
              <ClickableWhiteSpan
                data-testid="oQG2xPDCKUd65UBNYR8x6"
                onClick={() => this.setState({ margin: maxValue < 0 ? 0 : maxValue })}
              >
                {maxValue} {position?.product?.settling_asset?.symbol}
              </ClickableWhiteSpan>
            </p>
          </div>
          <br />
          <div className="container">
            <div
              data-testid="4_FwTjUbLTdJseQYZ_4rY"
              aria-hidden="true"
              className="max-btn"
              onClick={handleMax}
            >
              {t('max')}
            </div>
            <NumberInput
              value={this.state.margin}
              onChange={e => this.onInputChange(e)}
              className="input-section"
              inputMode="decimal"
              numberType={NUMBER_TYPES.decimal}
            />
            <div className="symbol">{position?.product?.settling_asset?.symbol}</div>
          </div>
        </LowerContentWrapper>

        <ContentWrapper>
          <TextWappper>
            <TextSpan>{t('trading:currentMargin')}</TextSpan>{' '}
            <WhiteSpan className="left-text">
              {round(position.margin, position.product.settling_asset.minimum_precision)}{' '}
              {position.product.settling_asset.symbol}{' '}
            </WhiteSpan>
            <span className="center">
              <Vertical />
            </span>
            <WhiteSpan className="right-text">
              {currentMarginVal} {position.product.settling_asset.symbol}
            </WhiteSpan>
          </TextWappper>

          <TextWappper>
            <TextSpan className="left-second">{t('trading:newLeverage')}</TextSpan>{' '}
            <WhiteSpan className="second-left">
              {!isNil(currentLeverage) ? `${currentLeverage}x` : '-'}
            </WhiteSpan>
            <span className="center-text">
              <Vertical />
            </span>
            <WhiteSpan className="right-second">
              {!isNil(newLeverage) ? `${newLeverage}x` : '-'}{' '}
            </WhiteSpan>
          </TextWappper>
        </ContentWrapper>

        <SpaceAround>
          <ConfirmButton primary onClick={this.submit}>
            {mode === 'add' ? t('trading:addMargin') : t('trading:removeMargin')}
          </ConfirmButton>
          <CancelButton data-testid="SR20eI1Nbi7hs9x3MXI_1" primary onClick={cancel}>
            {t('common:cancel')}
          </CancelButton>
        </SpaceAround>
      </PositionMarginWrapper>
    );
  }
}

const mapStateToProps = state => ({
  balances: balanceState(state),
});

const enhance = compose(connect(mapStateToProps), subscribe);

const positionMarginWithTranslation = withTranslation(['trading', 'common'])(
  PositionMargin
);

export default enhance(positionMarginWithTranslation);
