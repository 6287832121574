/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import { SIDE } from 'constants/enums';
import { l2OrderbookSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';

const useOrderbook = (
  selectedPriceClubbingValue: number,
  side: SIDE,
  hideDepth: Boolean
) => {
  const l2Updates = useAppSelector(state => l2OrderbookSelector(state));

  return {
    computedBook: l2Updates[side],
  };
  // const selectedProduct = useAppSelector(state => selectedProductSelector(state));
  // const book = side === SIDE.BUY ? l2Updates.bids : l2Updates.asks;

  // const data = useMemo(() => {
  //   const compute = new ComputeOrderbook({
  //     book,
  //     selectedPriceClubbingValue,
  //     side,
  //     hideDepth,
  //     selectedProduct,
  //   });
  //   return compute.getResult();
  // }, [book, selectedPriceClubbingValue, side, hideDepth, selectedProduct]);

  // return {
  //   computedBook: data,
  // };
};

export default useOrderbook;
