/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import { DownloadIcon } from 'styles/assets/icons';
import { Button } from 'UIKit';

export const StyledDownloadButton = styled(Button)`
  width: 105px;
  height: 32px;
  font-size: 12.1px;
  border-radius: 2px;
  background-color: ${props => props.theme.primaryBackground};
  border: solid 1px ${props => props.theme.secondaryBackground};
  color: ${props => props.theme.secondaryText};

  @media ${device.down.md} {
    width: 100px;
    height: 30px;
  }

  & svg {
    position: relative;
    top: -3px;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${props => props.theme.primaryTheme};
    border-color: ${props => props.theme.primaryTheme};
    color: ${props => props.theme.primaryText};

    & > svg path {
      fill: ${props => props.theme.primaryText};
    }
  }
`;

const DownloadButton = props => {
  const { label, ...rest } = props;
  return (
    <StyledDownloadButton {...rest}>
      <DownloadIcon />
      {label}
    </StyledDownloadButton>
  );
};

DownloadButton.defaultProps = {
  label: 'Download',
};

export default DownloadButton;
