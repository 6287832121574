/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Media from 'react-responsive';
import { compose, pure } from 'recompose';
import styled, { withTheme } from 'styled-components';

import { TIME_FORMATS } from 'constants/timeformats';
import {
  add,
  dateFormat as dateFmt,
  endOf,
  getDateTime,
  getDiff,
  getDuration,
  getHour,
  parseFormat,
  timeNow,
  toUTC,
} from 'helpers/day';
import { round_by_tick_size } from 'helpers/formulas';
import { isNil } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
// import Popover from 'containers/Popover';
import { getFormattedVolume, isOptions, dd_mm_hh } from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import {
  fundingRateState,
  estimatedFundingRateState,
  nextFundingRealizationState,
  oiSelectorById,
} from 'selectors/priceSelectors';
import {
  selectedProductTickerSelector,
  selectedProductTickerSelectorByKey,
} from 'selectors/tickerSelectors';
import { ArrowUp, FontAwesomeArrowDown } from 'styles/assets/icons';
import { Box, PopoverMaterial } from 'UIKit';

import { getChange } from './get_change';

export const TickerUnitWrapper = styled.div`
  color: ${props => props.theme.primaryText};
  font-family: var(--fontRegular) !important;
  margin-right: 30px;
  .header-label {
    font-family: var(--fontRegular);
    display: flex;
    justify-content: left;
    font-size: 11px;
    color: ${props => props.theme.secondaryText};
    @media ${device.down.md} {
      font-size: 11px;
      padding-bottom: 2px;
    }

    svg {
      margin-right: 5px;
      height: 18px;

      @media ${device.down.xs} {
        margin-right: 2px;
        width: 13px;
      }
    }
  }

  .dotted-line {
    border-bottom: 1px dashed var(--tooltipBottomDashedAndUnderlineColor);
    width: max-content;
    &:hover {
      border-bottom: 1px dashed ${props => props.theme.primaryTheme};
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .header-value {
    font-family: var(--fontRegular);
    display: flex;
    justify-content: left;
    font-size: 12.1px;
    color: ${props => props.theme.primaryText};
    @media ${device.down.md} {
      font-size: 12.1px;
    }
  }

  .down {
    color: ${props => props.theme.negative};
  }

  .up {
    color: ${props => props.theme.positive};
  }

  .change-icon {
    margin: 0 5px;
    @media ${device.down.lg} {
      margin: 0 1px;
    }
  }

  .space {
    padding-left: 8px;
  }

  .pointer {
    cursor: pointer;
  }

  .funding-header {
    margin-top: 0px;
  }

  .funding-value {
    padding-left: 0px;
  }

  // @media (max-width: 1200px) and (min-width: 1110px) {
  //   margin-right: 20px;
  // }

  // @media (max-width: 1109px) and (min-width: 1024px) {
  //   margin-right: 15px;
  // }

  @media ${device.down.md} {
    margin-right: 0px;
  }
`;

const enhance = compose(withTheme, pure);

const LocalizedValue = ({ type = 'header', localeKey, prefix }) => {
  const { t } = useTranslation(['trading']);
  if (prefix) {
    return `${prefix} ${t(`trading:${type}.${localeKey}`)}`;
  }
  return t(`trading:${type}.${localeKey}`);
};

const HoverWrapper = styled.div`
  a {
    color: ${props => props.theme.primaryTheme};
    text-decoration: none;
  }
  font-size: 12.1px;
  line-height: 20px;
  color: var(--tooltipInfoBoxfontColor);
  padding: 15px;
  font-family: var(--fontLight);
  background-color: var(--tooltipInfoBoxColor);
  width: 300px;
`;

const HoverInfo = pure(({ localKey }) => (
  <HoverWrapper>
    <LocalizedValue localeKey={localKey} />
  </HoverWrapper>
));

export const TickerUnit = enhance(
  ({ label, value, movement, hasPriceMovement, className }) => (
    <TickerUnitWrapper className={className}>
      <div className="header-label">{label}</div>
      <div className="header-value">
        <div className={movement}>
          {value}
          {hasPriceMovement &&
            value !== '-' &&
            movement !== 'none' &&
            (movement === 'up' ? (
              <ArrowUp className="change-icon" />
            ) : (
              <FontAwesomeArrowDown className="change-icon" />
            ))}
        </div>
      </div>
    </TickerUnitWrapper>
  )
);

export const TickerUnitWithPopover = enhance(
  ({ label, value, movement, hasPriceMovement, className, popOverText }) => {
    const [anchorElement, setAnchorElement] = useState(null);
    const handleClick = event => {
      setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElement(null);
    };
    return (
      <TickerUnitWrapper className={className}>
        <span
          data-testid={label}
          onClick={handleClick}
          className="header-label dotted-line cursor-pointer"
        >
          {label}
        </span>
        <div className="header-value">
          <div className={movement}>
            {value}
            {/* {hasPriceMovement && value !== '-' && movement !== 'none' && (
              <FontAwesomeIcon
                className="change-icon"
                icon={movement === 'up' ? faArrowUp : faArrowdown}
              />
            )} */}
            {hasPriceMovement &&
              value !== '-' &&
              movement !== 'none' &&
              (movement === 'up' ? (
                <ArrowUp className="change-icon" />
              ) : (
                <FontAwesomeArrowDown className="change-icon" />
              ))}
          </div>
        </div>
        <PopoverMaterial
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
        >
          <HoverWrapper>{popOverText}</HoverWrapper>
        </PopoverMaterial>
      </TickerUnitWrapper>
    );
  }
);

export const TickerPriceUnit = subscribe(
  ({ label, price, hasPriceMovement, className, contract_type }) => {
    const tickerData = selectedProductTickerSelector();
    const { price_movement: movement, price_percentage_change: priceChange } = tickerData
      ? getChange(tickerData.open, tickerData.close, contract_type)
      : {};
    const ltprice = contract_type === 'interest_rate_swaps' ? `${price}%` : price;
    return (
      <TickerUnitWrapper className={className}>
        <div className="header-label">{label}</div>
        <div className="header-value">
          <span>{ltprice}</span>
          <div className={`space ${movement}`}>
            {priceChange}
            {hasPriceMovement &&
              priceChange !== '-' &&
              movement !== 'none' &&
              (movement === 'up' ? (
                <ArrowUp className="change-icon" />
              ) : (
                <FontAwesomeArrowDown className="change-icon" />
              ))}
            <ArrowUp className="change-icon" />
          </div>
        </div>
      </TickerUnitWrapper>
    );
  }
);

export const Ticker24hrChangePercent = subscribe(({ className, contract_type }) => {
  const tickerData = selectedProductTickerSelector();
  const { price_movement: movement, price_percentage_change: priceChange } = getChange(
    tickerData ? Number(tickerData.open) : null,
    tickerData ? Number(tickerData.close) : null,
    contract_type
  );
  return (
    <TickerUnitWrapper className={className}>
      <Media query={device.up.md}>
        <div className="header-label">
          <LocalizedValue prefix="24h" localeKey="change" />
        </div>
      </Media>
      <div className={`header-value ${movement}`}>
        <span>{priceChange}</span>
      </div>
    </TickerUnitWrapper>
  );
});

export const TickerFundingUnit = subscribe(
  ({ label, movement, hasPriceMovement, className }) => {
    const fundingRate = fundingRateState();
    const value = fundingRate ? `${fundingRate}% /8h` : '-';
    const [anchorElement, setAnchorElement] = useState(null);

    const handleClick = event => {
      setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorElement(null);
    };

    return (
      <TickerUnitWrapper className={`${className} pointer`}>
        <span
          data-testid={label}
          className="header-label dotted-line"
          onClick={handleClick}
        >
          {label}
        </span>
        <div className="header-value funding-header">
          <div className={movement}>
            <span className="funding-value">{value}</span>
            {/* {hasPriceMovement && value !== '-' && movement !== 'none' && (
              <FontAwesomeIcon
                className="change-icon"
                icon={movement === 'up' ? faArrowUp : faArrowdown}
              />
            )} */}
            {hasPriceMovement &&
              value !== '-' &&
              movement !== 'none' &&
              (movement === 'up' ? (
                <ArrowUp className="change-icon" />
              ) : (
                <FontAwesomeArrowDown className="change-icon" />
              ))}
          </div>
        </div>
        <PopoverMaterial
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleClose}
        >
          <HoverInfo localKey="fundingInfo" />
        </PopoverMaterial>
      </TickerUnitWrapper>
    );
  }
);

export const TickerEstimatedFundingUnit = subscribe(
  ({ label, hasPriceMovement, className }) => {
    const estimatedFunding = estimatedFundingRateState();
    const value = estimatedFunding ? `${estimatedFunding}% /8h` : '-';
    return (
      <TickerUnitWithPopover
        label={label}
        value={value}
        popOverText={<LocalizedValue localeKey="estNextFundingInfo" />}
        hasPriceMovement={hasPriceMovement}
        className={className}
      />
    );
  }
);

export const dateFormat = key => {
  const now = toUTC(timeNow());

  const end = toUTC(getDateTime(key));
  const duration = getDiff(getDateTime(end), getDateTime(now), 'milliseconds');
  if (duration < 86400 * 1000) {
    return dateFmt(toUTC(getDateTime(duration)), TIME_FORMATS.HHhmmmsss);
  }
  return dd_mm_hh(now, end);
};

export const NextFundingTicker = subscribe(props => {
  const nextFunding = nextFundingRealizationState() / 1000; // dividing by 1000 because time is coming in microseconds
  const [duration, setduration] = useState(dateFormat(nextFunding));

  useEffect(() => {
    const setTime = setInterval(() => {
      setduration(dateFormat(nextFunding));
    }, 1000);

    return () => clearInterval(setTime);
  }, [nextFunding]);

  return (
    <TickerUnitWithPopover
      label={<LocalizedValue localeKey="nextFunding" />}
      value={duration}
      hasPriceMovement={false}
      className={props.className}
      popOverText={<LocalizedValue localeKey="nextFundingInfo" />}
    />
  );
});

// eslint-disable-next-line react/prop-types
export const SettlementUnit = ({ product, className }) => {
  const [, forceUpdate] = useState(0);

  useEffect(() => {
    // To update the settlement time after every sec. So it works like a normal timer.
    const timerId = setInterval(() => forceUpdate(prev => prev + 1), 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <TickerUnit
      label={<LocalizedValue localeKey="settlement" />}
      // eslint-disable-next-line react/prop-types
      value={dateFormat(product.settlement_time)}
      hasPriceMovement={false}
      className={className}
    />
  );
};

export const FundingUnit = ({ className }) => {
  return (
    <TickerFundingUnit
      label={<LocalizedValue localeKey="funding" />}
      hasPriceMovement={false}
      className={className}
    />
  );
};

export const EstimatedFundingUnit = ({ className }) => {
  return (
    <TickerEstimatedFundingUnit
      label={<LocalizedValue localeKey="estNextFunding" />}
      hasPriceMovement={false}
      className={className}
    />
  );
};

// eslint-disable-next-line react/prop-types
export const MaturityUnit = ({ product, className }) => {
  return (
    <TickerUnit
      label={<LocalizedValue localeKey="maturity" />}
      // eslint-disable-next-line react/prop-types
      value={dateFormat(product.settlement_time)}
      hasPriceMovement={false}
      className={className}
    />
  );
};

// eslint-disable-next-line react/prop-types
export const SettlementFundingUnit = ({ product, className }) => {
  return (
    // eslint-disable-next-line react/jsx-fragments
    (<Fragment>
      {product.contract_type === 'futures' && (
        <TickerUnit
          label={<LocalizedValue localeKey="settlement" />}
          value={dateFormat(product.settlement_time)}
          hasPriceMovement={false}
          className={className}
        />
      )}
      {product.contract_type === 'perpetual_futures' && (
        <>
          <TickerFundingUnit
            label={<LocalizedValue localeKey="funding" />}
            hasPriceMovement={false}
            className={className}
          />
          <TickerEstimatedFundingUnit
            label={<LocalizedValue localeKey="estNextFunding" />}
            hasPriceMovement={false}
            className={className}
          />
          <NextFundingTicker className={className} />
        </>
      )}
      {product.contract_type === 'interest_rate_swaps' && (
        <TickerUnit
          label={<LocalizedValue localeKey="maturity" />}
          value={dateFormat(product.settlement_time)}
          hasPriceMovement={false}
          className={className}
        />
      )}
      {isOptions(product.contract_type) && (
        <TickerUnit
          label={<LocalizedValue localeKey="settlement" />}
          value={dateFormat(product.settlement_time)}
          hasPriceMovement={false}
          className={className}
        />
      )}
    </Fragment>)
  );
};
export const HighUnit = subscribe(props => {
  const { product } = props;
  const { tick_size, contract_type } = product;
  const tickerHigh = selectedProductTickerSelectorByKey('high');

  const value = isNil(tickerHigh) ? '-' : round_by_tick_size(tickerHigh, tick_size);
  return (
    <TickerUnit
      label={<LocalizedValue prefix="24h" localeKey="high" />}
      value={`${value}${
        tickerHigh && contract_type === 'interest_rate_swaps' ? '%' : ''
      }`}
      hasPriceMovement={false}
      className="initial-padding"
      {...props}
    />
  );
});

export const LowUnit = subscribe(props => {
  const { product } = props;
  const { tick_size, contract_type } = product;
  const tickerLow = selectedProductTickerSelectorByKey('low');

  const value = isNil(tickerLow) ? '-' : round_by_tick_size(tickerLow, tick_size);
  return (
    <TickerUnit
      label={<LocalizedValue prefix="24h" localeKey="low" />}
      value={`${value}${tickerLow && contract_type === 'interest_rate_swaps' ? '%' : ''}`}
      hasPriceMovement={false}
      {...props}
    />
  );
});

export const VolumeUnit = subscribe(props => {
  const tickerVolume = selectedProductTickerSelectorByKey('turnover_usd');
  const number = getFormattedVolume(true)(tickerVolume);
  const volume = isNil(number) ? '-' : number;
  return (
    <TickerUnit
      label="24h Vol."
      value={tickerVolume ? `$${volume}` : '-'}
      hasPriceMovement={false}
      className=""
      {...props}
    />
  );
});

export const StrikePrice = ({ product, className }) => {
  const strikePrice = isNil(product.strike_price)
    ? '-'
    : round_by_tick_size(product.strike_price, product.tick_size);
  return (
    <TickerUnit
      label={<LocalizedValue localeKey="strikePrice" />}
      value={strikePrice}
      hasPriceMovement={false}
      className={className}
    />
  );
};

export const CombosLegsStrikePrices = ({ product, className }) => {
  const { t } = useTranslation(['trading']);
  const {
    product_specs: { legs },
  } = product;

  return (
    <Box flex="flex" alignItems="center" flexDir="row" justifyContent="center">
      {legs.map((leg, index) => (
        <TickerUnit
          key={leg.strike_price}
          label={t('trading:header:legStrikePrice', { leg: index + 1 })}
          value={leg.strike_price}
          hasPriceMovement={false}
          className={className}
        />
      ))}
    </Box>
  );
};

export const KnockoutPriceUnit = ({ product, className }) => {
  const knockoutPrice = isNil(product.barrier_price)
    ? '-'
    : round_by_tick_size(product.barrier_price, product.tick_size);
  return (
    <TickerUnit
      label={<LocalizedValue localeKey="knockoutPrice" />}
      value={knockoutPrice}
      hasPriceMovement={false}
      className={className}
    />
  );
};

export const NextFunding = ({ className }) => {
  const currentTime = new Date();
  const FourOClock = dateFmt(
    parseFormat('4.00', [TIME_FORMATS.HHmm]),
    TIME_FORMATS.HHmmss
  );
  const TweleveOClock = dateFmt(
    parseFormat('12.00', [TIME_FORMATS.HHmm]),
    TIME_FORMATS.HHmmss
  );
  const TwentyOClock = dateFmt(
    parseFormat('20.00', [TIME_FORMATS.HHmm]),
    TIME_FORMATS.HHmmss
  );
  const TwentyFourClock = dateFmt(
    parseFormat('24.00', [TIME_FORMATS.HHmm]),
    TIME_FORMATS.HHmmss
  );
  const end = endOf(timeNow(), 'day');
  const currentMoment = dateFmt(toUTC(getDateTime(currentTime)), TIME_FORMATS.HHmmss);
  let countDownTime;
  if (currentMoment > TwentyFourClock && currentMoment <= FourOClock) {
    if (getHour(parseFormat(currentMoment, TIME_FORMATS.HHmm)) === 0) {
      countDownTime = getDiff(
        parseFormat(FourOClock, TIME_FORMATS.HHmmss),
        parseFormat(currentMoment, TIME_FORMATS.HHmmss)
      );
    } else {
      countDownTime = getDiff(
        parseFormat(FourOClock, TIME_FORMATS.HHmmss),
        parseFormat(currentMoment, TIME_FORMATS.HHmmss)
      );
    }
  } else if (currentMoment > FourOClock && currentMoment <= TweleveOClock) {
    countDownTime = getDiff(
      parseFormat(TweleveOClock, TIME_FORMATS.HHmmss),
      parseFormat(currentMoment, TIME_FORMATS.HHmmss)
    );
  } else if (currentMoment > TweleveOClock && currentMoment <= TwentyOClock) {
    countDownTime = getDiff(
      parseFormat(TwentyOClock, TIME_FORMATS.HHmmss),
      parseFormat(currentMoment, TIME_FORMATS.HHmmss)
    );
  } else if (currentMoment > TwentyOClock) {
    countDownTime = dateFmt(
      add(getDateTime(getDiff(end, timeNow(), 'milliseconds')), '240', 'minutes'),
      TIME_FORMATS.HHmmss
    );
  }

  const ms = getDuration(countDownTime);
  //  console.log("DEBUG",ms.hours(),ms.minutes(),ms.seconds())
  return (
    <TickerUnit
      label={<LocalizedValue localeKey="nextFloatIn" />}
      value={`${ms.hours()}h:${ms.minutes()}m:${ms.seconds()}s`}
      hasPriceMovement={false}
      className={className}
    />
  );
};

export const OiUnit = subscribe(props => {
  const oi = oiSelectorById(props.product.id);
  return (
    <TickerUnit
      label="OI"
      value={oi ? `$${getFormattedVolume()(oi)}` : '-'}
      hasPriceMovement={false}
      className={props.className}
    />
  );
});
