/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { memo, type ElementType, Suspense } from 'react';

import Box, { type BoxProps } from 'UIKit/Box/Box';

import { ImpsIcon, MasterCardIcon, NeftIcon, UpiIcon, VisaIcon } from './icons';

interface PaymentTypeIconSelectorProps {
  type: 'visa' | 'mastercard' | 'imps' | 'upi' | 'neft';
  boxProps?: BoxProps<ElementType>;
}

const icons = {
  visa: VisaIcon,
  mastercard: MasterCardIcon,
  imps: ImpsIcon,
  upi: UpiIcon,
  neft: NeftIcon,
} as const;

/**
 * Returns Icon for selected payment type
 *
 * @param type 'visa' | 'mastercard' | 'imps' | 'upi' | 'neft'
 * @param boxProps - see Box component for props
 */
const PaymentTypeIconSelector = memo(function PaymentTypeIconSelector({
  type,
  boxProps,
}: PaymentTypeIconSelectorProps) {
  const IconComponent = typeof type === 'undefined' ? null : icons[type];

  if (!IconComponent) return null;

  return (
    <Box as="span" renderWhen={IconComponent !== null} {...boxProps}>
      {/* @ts-ignore */}
      <Suspense fallback={null}>
        <IconComponent data-type={type} />
      </Suspense>
    </Box>
  );
});

export default PaymentTypeIconSelector;
