import { useEffect } from 'react';

// Scrolls into start of component block
const useScrollIntoView = (componentId, shouldScroll) => {
  useEffect(() => {
    if (shouldScroll) {
      document.getElementById(componentId)?.previousSibling?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [shouldScroll, componentId]);
};

export default useScrollIntoView;
