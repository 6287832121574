import { useRef, useEffect } from 'react';

enum BlinkType {
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

/**
 * Currently used for orderbook size blinking.
 * @param value value for which blinking has to be applied. Since this hook is made for number , send only numberic value.
 * @returns neutral or positive or negative
 */
const useBlink = (value?: number): BlinkType => {
  // const [blink, setBlink] = useState<BlinkType>('neutral');
  const prevValue = useRef<number>();

  // Store current value in ref
  useEffect(() => {
    prevValue.current = value;
  }, [value]);
  // Return blink type (happens before update in useEffect above)
  // eslint-disable-next-line no-nested-ternary
  return Number(prevValue.current) < Number(value)
    ? BlinkType.POSITIVE
    : Number(prevValue.current) > Number(value)
    ? BlinkType.NEGATIVE
    : BlinkType.NEUTRAL;
};

export default useBlink;
