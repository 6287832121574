import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { RenderForGlobalExchange } from 'components/render/RenderByExchangeType';
import {
  KYC_LEVEL_ONE_DISCONTINUATION_SUPPORT_LINK,
  LOCAL_STORAGE_KYC_UPGRADE_POPUP_KEY,
} from 'constants/constants';
import routes from 'constants/routes';
import { kycUpgradePopupLastOpenedOneDayAgo } from 'helpers';
import { useLocalStorage } from 'hooks';
import { WarningSymbol } from 'styles/assets/icons';
import { MaterialDialog } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton';

import styles from './kycUpgradeModal.module.scss';

const KycUpgradeModal = () => {
  const [lastClosedAt, setLastClosedAt] = useLocalStorage<number | null>(
    LOCAL_STORAGE_KYC_UPGRADE_POPUP_KEY,
    null
  );
  /* Show modal based on last Closed Time */
  const showModal = kycUpgradePopupLastOpenedOneDayAgo(lastClosedAt);

  const history = useHistory();
  const { t } = useTranslation(['account', 'common']);

  const closeModal = () => {
    /* Update lastClosedAt to current time */
    const closingTimeInMilliseconds = new Date().getTime();
    setLastClosedAt(closingTimeInMilliseconds);
  };

  const handleStartKyc = () => {
    closeModal();
    history.push(routes.account.identityVerification);
  };

  return (
    <RenderForGlobalExchange>
      <MaterialDialog
        open={showModal}
        onClose={closeModal}
        contentClassName={styles.kycUpgradeModal}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <WarningSymbol />
            {t('account:security.kycUpgradeRequired')}
          </div>

          <div className={styles.body}>
            <p>{t('account:security.kycUpgradeModalDescription1')}</p>
            <p>
              {t('account:security.kycUpgradeModalDescription2')}{' '}
              <DeltaButton
                variant="link-t1"
                className={styles.learnMoreLink}
                href={KYC_LEVEL_ONE_DISCONTINUATION_SUPPORT_LINK}
                data-testid="kycUpgradeSupportPageLink"
                openLinkInNewTab
              >
                {t('common:learnMore')}
              </DeltaButton>
            </p>
          </div>

          <div className={styles.footer}>
            <DeltaButton
              onClick={closeModal}
              variant="link-t1"
              data-testid="remindLaterBtn-kycUpgradeModal"
              className={styles.cancelButton}
            >
              {t('account:profile.kycRefreshModal.remindLater')}
            </DeltaButton>
            <DeltaButton
              onClick={handleStartKyc}
              variant="primary-t4"
              data-testid="startKycBtn-kycUpgradeModal"
              className={styles.confirmButton}
            >
              {t('account:profile.startKyc')}
            </DeltaButton>
          </div>
        </div>
      </MaterialDialog>
    </RenderForGlobalExchange>
  );
};

export default KycUpgradeModal;
