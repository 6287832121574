import React, { memo } from 'react';

import cn from 'classnames';

import Render from 'components/render';
import StripPortal, { STRIPS_ORDER } from 'components/StripPortal';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import { noop } from 'helpers';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { useAppSelector } from 'hooks';
import useShowInformationStrip from 'hooks/componentHooks/useShowInformationStrip';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import { activeTabSelector } from 'selectors';
import { CloseIcon, ExternalLink, Alert } from 'styles/assets/icons';
import BonusTick from 'styles/assets/icons/BonusTick';
import { IconButton } from 'UIKit';
import Box from 'UIKit/Box';

import styles from './InformationStrip.module.scss';

const InformationStrip = memo(() => {
  const { whenBelowMd } = useCustomMediaQuery();

  const {
    renderStrip,
    remainVisible,
    redirectionLink,
    isErrorStrip,
    isWarningStrip,
    heading,
    isInformationStrip,
    message,
    name,
    setCurrentClosedStrip,
    openInNewTab,
  } = useShowInformationStrip();

  const drawerTab = useAppSelector(activeTabSelector);

  const isVisibleOnActiveTab = [
    APP_DRAWER_TAB_ID_MAPPING.HOME,
    APP_DRAWER_TAB_ID_MAPPING.POSITIONS,
    APP_DRAWER_TAB_ID_MAPPING.TRADE,
    APP_DRAWER_TAB_ID_MAPPING.MARKETS,
  ].includes(drawerTab);

  if ((!isVisibleOnActiveTab && whenBelowMd) || !renderStrip) {
    return null;
  }

  const messageClasses = {
    [styles.errorText]: isErrorStrip,
    [styles.warningText]: isWarningStrip,
  };

  const onCloseClickHandler = () => {
    if (!name) {
      return;
    }

    setCurrentClosedStrip(name);

    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STRIP_CLICKED, {
      strip_name: `${name}_Close`,
    });
  };

  const onExternalLinkClickHandler = () => {
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.STRIP_CLICKED, {
      strip_name: name,
    });
  };

  return (
    <StripPortal isSticky={false}>
      <Box
        className={cn(styles.stripContainer, {
          [styles.error]: isErrorStrip,
          [styles.warn]: isWarningStrip,
        })}
        flex="flex"
        style={{
          order: STRIPS_ORDER.INFORMATION_STRIP,
        }}
      >
        <Render
          when={isWarningStrip || isErrorStrip}
          else={
            <BonusTick
              className={styles.stripIcon}
              color="var(--white)"
              height={18}
              width={18}
            />
          }
        >
          <Alert
            color="currentColor"
            className={cn(styles.stripIcon, {
              [styles.error]: isErrorStrip,
              [styles.warn]: isWarningStrip,
            })}
            height={18}
            width={18}
          />
        </Render>

        <Render
          when={redirectionLink}
          else={
            <Box as="span" className={cn(styles.informationText, messageClasses)}>
              <Render when={heading} else={null}>{`${heading}: `}</Render>
              {message}
            </Box>
          }
        >
          <a
            onClick={onExternalLinkClickHandler}
            target={openInNewTab ? '_blank' : '_self'}
            rel="noopener noreferrer"
            href={redirectionLink!}
            className={cn(styles.informationText, messageClasses)}
            data-testid="information-strip-link"
          >
            <Render when={heading}>{`${heading}: `}</Render>
            {message}
            <ExternalLink className={styles.linkIcon} />
          </a>
        </Render>
        <IconButton
          className={cn(styles.closeIcon, {
            [styles.informationStrip]: isInformationStrip,
          })}
          data-testid="information-strip-close-icon"
          onClick={onCloseClickHandler}
          onKeyDown={noop}
          renderWhen={!remainVisible}
          icon={<CloseIcon color="currentColor" />}
        />
      </Box>
    </StripPortal>
  );
});

InformationStrip.displayName = 'InformationStrip';

export { InformationStrip };
