/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const TwoFAResetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="TwoFAResetIcon">
    <circle cx="40" cy="40" r="40" fill="var(--modalContentBackground)" />
    <g clipPath="url(#clip0_965_10)">
      <path
        d="M32.1875 51.2188L29.0625 48.0922L26.8578 50.2953L32.1875 55.625L41.5625 46.25L39.3594 44.0469L32.1875 51.2188Z"
        fill="var(--secondaryText)"
      />
      <path
        d="M58.75 61.875H52.5V58.75H58.75V40H52.5V27.5C52.4981 25.843 51.8391 24.2543 50.6674 23.0826C49.4957 21.9109 47.907 21.2519 46.25 21.25V18.125C48.7356 18.1278 51.1185 19.1164 52.8761 20.8739C54.6336 22.6315 55.6222 25.0144 55.625 27.5V36.875H58.75C59.5785 36.8759 60.3729 37.2054 60.9587 37.7913C61.5446 38.3771 61.8741 39.1715 61.875 40V58.75C61.8741 59.5785 61.5446 60.3729 60.9587 60.9587C60.3729 61.5446 59.5785 61.8741 58.75 61.875Z"
        fill="var(--secondaryText)"
      />
      <path
        d="M46.25 36.875H43.125V27.5C43.125 25.0136 42.1373 22.629 40.3791 20.8709C38.621 19.1127 36.2364 18.125 33.75 18.125C31.2636 18.125 28.879 19.1127 27.1209 20.8709C25.3627 22.629 24.375 25.0136 24.375 27.5V36.875H21.25C20.4212 36.875 19.6263 37.2042 19.0403 37.7903C18.4542 38.3763 18.125 39.1712 18.125 40V58.75C18.125 59.5788 18.4542 60.3737 19.0403 60.9597C19.6263 61.5458 20.4212 61.875 21.25 61.875H46.25C47.0788 61.875 47.8737 61.5458 48.4597 60.9597C49.0458 60.3737 49.375 59.5788 49.375 58.75V40C49.375 39.1712 49.0458 38.3763 48.4597 37.7903C47.8737 37.2042 47.0788 36.875 46.25 36.875ZM27.5 27.5C27.5 25.8424 28.1585 24.2527 29.3306 23.0806C30.5027 21.9085 32.0924 21.25 33.75 21.25C35.4076 21.25 36.9973 21.9085 38.1694 23.0806C39.3415 24.2527 40 25.8424 40 27.5V36.875H27.5V27.5ZM46.25 58.75H21.25V40H46.25V58.75Z"
        fill="var(--secondaryText)"
      />
    </g>
    <defs>
      <clipPath id="clip0_965_10">
        <rect width="50" height="50" fill="white" transform="translate(15 15)" />
      </clipPath>
    </defs>
  </svg>
);

export default TwoFAResetIcon;
