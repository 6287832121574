import { isEmpty, negativeBalanceCheck } from 'helpers';
import { balanceBySymbolSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';

/**
 * Get Balance data of a particular asset
 */
const useAssetBalance = (asset: string) => {
  const allBalances = useAppSelector(state => balanceBySymbolSelector(state));
  if (isEmpty(asset)) {
    return {
      roundedAvailBalance: 0,
      roundedWalletBalance: 0,
    };
  }
  const balanceObj = allBalances[asset];
  const assetPrecision = balanceObj?.asset.minimum_precision;
  const roundedAvailBalance = negativeBalanceCheck(
    balanceObj?.available_balance,
    assetPrecision
  );
  const roundedWalletBalance = negativeBalanceCheck(balanceObj?.balance, assetPrecision);
  return {
    roundedAvailBalance,
    roundedWalletBalance,
  };
};

export default useAssetBalance;
