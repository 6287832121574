import React from 'react';

import { LastPriceValue } from 'components/markets/helpers';
import { DeltaValue, MarkpriceValue } from 'components/options_chain/helpers';
import { STRIKE_PRICE_TICK_SIZE } from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import { round_by_tick_size } from 'helpers';
import { dateFormat } from 'helpers/day';
import i18n from 'i18n/config';

import BuySellButton from './buySellButton';

const OptionsColumn = (asset: string) => {
  return [
    {
      id: 'Type',
      headerClassName: 'text-start tableheader',
      Header: (
        <span data-palette="OptionsColumn">
          <span className="me-1">{i18n.t('trading:optionChainDropdownCols.type')}</span>
        </span>
      ),
      accessor: 'type',
      resizable: false,
      maxWidth: 50,
      className: 'flex-set align-items-center type-col',
    },
    {
      id: 'Strike',
      headerClassName: `text-end tableheader`,
      Header: (
        <span data-palette="OptionsColumn">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.strikes')}
          </span>
        </span>
      ),
      accessor: 'strike_price',
      Cell: props => {
        const asset = props.original.underlying_asset_symbol;
        return `${round_by_tick_size(
          props.original.strike_price,
          STRIKE_PRICE_TICK_SIZE[asset]
        )}`;
      },
      maxWidth: 80,
      resizable: false,
      className: 'flex--content-end flex-set align-items-center',
    },
    {
      id: 'expiry_date',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="OptionsColumn">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.expiryDate')}
          </span>
        </span>
      ),
      accessor: 'expiryDate',
      Cell: props => {
        return `${dateFormat(props.original.expiryDate, TIME_FORMATS.DD_MMM)}`;
      },
      resizable: false,
      maxWidth: 80,
      className: 'flex--content-end flex-set align-items-center',
    },
    {
      id: 'Delta',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="OptionsColumn">
          <span className="me-1">{i18n.t('trading:optionChainDropdownCols.delta')}</span>
        </span>
      ),
      accessor: (props: any) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <DeltaValue {...props.product} />;
      },
      maxWidth: 80,
      resizable: false,
      sortable: false,
      className: 'flex--content-end flex-set align-items-center',
    },
    {
      id: 'MarkPrice',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="OptionsColumn">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.markprice')}
          </span>
        </span>
      ),
      accessor: (props: any) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MarkpriceValue {...props.product} />;
      },
      maxWidth: 80,
      resizable: false,
      sortable: false,
      className: 'flex--content-end flex-set align-items-center',
    },
    {
      id: 'buy/sell',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="OptionsColumn">
          <span>B(Buy) / S(Sell)</span>
        </span>
      ),
      accessor: (props: any) => {
        return (
          <BuySellButton
            data-testid="buy-sell-button"
            product={props.product}
            asset={asset}
          />
        );
      },
      maxWidth: 110,
      className: 'flex--content-end flex-set align-items-center',
    },
  ];
};

const FuturesColumn = () => {
  return [
    {
      id: 'Contracts',
      headerClassName: `text-start tableheader`,
      Header: (
        <span data-palette="FuturesColumn">
          <span className="me-1">Contracts</span>
        </span>
      ),
      accessor: prop => prop.symbol,
      resizable: false,
      maxWidth: 120,
      className: 'flex-set align-items-center type-col',
    },
    {
      id: 'Last Price',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="FuturesColumn">
          <span className="me-1">
            {i18n.t('trading:optionChainDropdownCols.lastPrice')}
          </span>
        </span>
      ),
      Cell: props => {
        return <LastPriceValue product={{ ...props.original }} className="faded-text" />;
      },
      resizable: false,
      // maxWidth: 80,
      className: 'flex--content-end flex-set align-items-center',
    },
    {
      id: 'buy/sell',
      headerClassName: 'text-end tableheader',
      Header: (
        <span data-palette="FuturesColumn">
          <span className="me-1">B(Buy) / S(Sell)</span>
        </span>
      ),
      accessor: (props: any) => {
        // eslint-disable-next-line test-selectors/button
        return <BuySellButton product={props} />;
      },
      className: 'flex--content-end flex-set align-items-center',
    },
  ];
};

export { OptionsColumn, FuturesColumn };
