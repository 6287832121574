import { useLocation } from 'react-router';

import { showComponentOnSelectedRoute } from 'components/app/helpers';
import { HOMEPAGE_ROUTE } from 'constants/constants';
import { getHexaDecimalUserId, userCountryVisibilityFilter } from 'helpers';
import { isDateAfter, isDateWithin, timeNow, toUTC } from 'helpers/day';

import { DEFAULT_INFORMATION_STIP_ROUTES } from './useShowInformationStrip.constants';
import {
  INFO_STRIP_LOGIN_STATES,
  type PathNameItem,
  type StripData,
} from './useShowInformationStrip.types';

/** @description checks if pathnames provided matches the current route */
const isStripUrlValidForRoute = (
  pathnames: StripData['webAppPathnames'],
  location: ReturnType<typeof useLocation>
) => {
  if (pathnames === null) {
    return false;
  }

  // filtering pathnames to remove invalid data
  const cleanedPathnames = pathnames?.reduce((formattedPathname, item) => {
    // when values to match pathname is invalid skip logic
    if (typeof item === 'string' || typeof item?.path === 'string') {
      let path = typeof item === 'string' ? item : item.path;

      const indexOfBasePath = path.indexOf(HOMEPAGE_ROUTE);

      // if BASE_PATH not present consider it as landing page route and dont push into final pathname list .
      if (indexOfBasePath !== -1) {
        // removing 'BASE_PATH' as it is not stored by react router.
        path = path.substring(indexOfBasePath + HOMEPAGE_ROUTE.length);

        // pushing the item when valid.
        formattedPathname.push({
          path,
          exact: typeof item === 'string' ? false : item.exact,
        });
      }

      return formattedPathname;
    }

    return formattedPathname;
  }, [] as PathNameItem[]);

  // if no pathnames provided check for urls where primary header in web-app is displayed
  return cleanedPathnames?.length
    ? showComponentOnSelectedRoute(cleanedPathnames)(location)
    : showComponentOnSelectedRoute(DEFAULT_INFORMATION_STIP_ROUTES)(location);
};

// compares isLoggedIn w.r.t to strip login display state.
const loginStateValidator = (
  isLoggedIn: boolean,
  showWhenLogin: StripData['showWhenLogin']
) => {
  switch (showWhenLogin) {
    case INFO_STRIP_LOGIN_STATES.POST:
      return Boolean(isLoggedIn);
    case INFO_STRIP_LOGIN_STATES.PRE:
      return !isLoggedIn;
    default:
      return true;
  }
};

/** @description compares country from ip to stripData and returns true if check passes */
const countryFromIpValidator = (
  country: string | undefined,
  countriesToValidate?: string[]
) => {
  return Boolean(country && userCountryVisibilityFilter(country, countriesToValidate));
};

/** @description compares current time and time provided for strip visibility */
const stripValidForCurrentTime = (displayTime: StripData['displayTime']) => {
  if (displayTime && typeof displayTime === 'object') {
    const startTime = toUTC(displayTime.start);

    if (!startTime.isValid()) {
      return false;
    }

    const endTimeValid = displayTime.end && toUTC(displayTime.end).isValid();

    const currentTime = toUTC(timeNow());

    return endTimeValid
      ? isDateWithin(displayTime.start, displayTime.end!, currentTime)
      : isDateAfter(currentTime, startTime);
  }

  return true;
};

/** @description  does final check based on other attributes.
 * @param remainVisible - should always show the strip when this is true.
 * @param currentClosedStrip - stores the strip which has been closed by user
 */
const shouldShowStrip = ({
  remainVisible,
  currentClosedStrip,
  stripName,
  displayTime,
}: {
  remainVisible: StripData['remainVisible'];
  stripName: StripData['name'] | undefined;
  currentClosedStrip: string | null;
  displayTime: StripData['displayTime'];
}) => {
  if (!stripValidForCurrentTime(displayTime) || !stripName) {
    return false;
  }

  return remainVisible || currentClosedStrip !== stripName;
};

const userIdsFilter = (userId?: number, userIds?: StripData['userIds']) =>
  !userIds ? true : userIds.includes(getHexaDecimalUserId(userId));

export {
  shouldShowStrip,
  isStripUrlValidForRoute,
  loginStateValidator,
  countryFromIpValidator,
  userIdsFilter,
};
