import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'PropTypes';
import { useTranslation } from 'react-i18next';

// import { getFeeCreditExpiryDate } from '../../helpers/utils';
import Popover from 'containers/Popover';
import PopoverBase from './../popover/popover_base';

const PopoverWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: -1px;
`;

//code commented because expiry code might be applied later in future.
// const PopoverBodyWrapper = styled.div`
//   p {
//     margin-bottom: 1rem;
//   }
// `;

// const PopoverBody = () => (
//   <PopoverBodyWrapper>
//     <p>
//       Trading fees credits offset your transaction costs. As long as you have fees credits, your trading is free.
//     </p>
//     )}
//   </PopoverBodyWrapper>
// );

const TradeFeeCreditPopover = props => {
  // const { feeCredit } = props;
  const { t } = useTranslation(['account']);
  return (
    <PopoverWrapper>
      <Popover
        body={t('account:walletColumns.feeCreditPopover')}
        shouldEnableClickListener
        shouldDisablePortal={props.shouldDisablePortal}>
        {thisProps => <PopoverBase {...thisProps} />}
      </Popover>
    </PopoverWrapper>
  );
};

TradeFeeCreditPopover.defaultProps = {
  shouldDisablePortal: false,
  feeCredit: '',
};

// TradeFeeCreditPopover.propTypes = {
//   shouldDisablePortal: PropTypes.bool,
//   feeCredit: PropTypes.string,
// };

export default TradeFeeCreditPopover;
