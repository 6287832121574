/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  // GAMMA_TICK_SIZE,
  // THETA_TICK_SIZE,
  // VEGA_TICK_SIZE,
  GREEK_PRECISION,
  CONTRACT_VALUE_FALLBACK,
} from 'constants/constants';
// eslint-disable-next-line import/named
import { getPositionValue, round, round_by_tick_size } from 'helpers/formulas';
import {
  cropAfterDecimals,
  decimalMultiplication,
  // getFormattedVolume,
  numberPrettified,
  afterDecimalLength,
  addZeroesUntilCorrectPrecision,
  isNan,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import useL2BestValue from 'hooks/componentHooks/useOrderBook/useL2BestValue';
import { calcPrecision } from 'reducers/helpers/trade';
import {
  allOpenPositionsSelector,
  selectedProductBestAskPriceSelector,
  selectedProductBestBidPriceSelector,
} from 'selectors';
import {
  bestAskById,
  bestBuyById,
  bidQtyById,
  deltaPriceSelectorById,
  gammaPriceSelectorById,
  thetaPriceSelectorById,
  vegaPriceSelectorById,
  impliedVolatilitySelectorById,
  AskIVById,
  BidIVById,
  askQtyById,
  markPriceSelectorById,
  // orderBookBuySelector,
  // orderBookSellSelector,
  markPriceState,
  impliedVolatilityState,
  oiSelectorById,
  oiChangeSelectorById,
} from 'selectors/priceSelectors';
import {
  selectedProductIdSelector,
  selectedContractCurrencySelector,
  selectedProductSelector,
} from 'selectors/tradeSelectors';
import { Box } from 'UIKit';

import BuySellHoverButton from './buySellHoverButton';
import styles from './index.module.scss';

// const PositionValue = React.memo(prop => {
//   const { id } = prop;
//   const position = useSelector(
//     positionByProductIdSelector(id !== undefined ? String(prop?.id) : null)
//   );
//   if (id) {
//     if (!position) {
//       return <div> - </div>;
//     }
//     return <div> {position.size} </div>;
//   }
//   return <div> - </div>;
// });

const optionTypeProp = Object.freeze({
  call: 'callOptions',
  put: 'putOptions',
});

const MarkpriceValue = subscribe(prop => {
  const { id, tick_size } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const markPrice =
    Number(selectedProductId) === Number(id)
      ? markPriceState()
      : markPriceSelectorById(String(prop?.id));
  const precision = calcPrecision(tick_size);
  return <div data-palette="MarkpriceValue"> {Number.isNaN(markPrice) ? '-' : round(markPrice, precision)} </div>;
});

const ThetaValue = subscribe(prop => {
  // const { underlying_asset } = prop;
  // const { symbol } = underlying_asset || {};
  const price = thetaPriceSelectorById(String(prop?.id));
  // const tickPrecision = calcPrecision(THETA_TICK_SIZE[symbol] || 0.01);
  if (Number.isNaN(price)) {
    return <span data-palette="ThetaValue"> - </span>;
  }
  return <div data-palette="ThetaValue"> {cropAfterDecimals(price, GREEK_PRECISION.THETA)} </div>;
});

const DeltaValue = subscribe(prop => {
  const price = deltaPriceSelectorById(String(prop?.id));
  if (Number.isNaN(Number(price))) {
    return <span data-palette="DeltaValue"> - </span>;
  }
  return <div data-palette="DeltaValue"> {Number(price).toFixed(2)} </div>;
});

const GammaValue = subscribe(prop => {
  const { contract_type, showBuySellButton } = prop;
  // const { symbol } = underlying_asset || {};
  const price = gammaPriceSelectorById(String(prop?.id));
  // const tickPrecision = calcPrecision(symbol ? GAMMA_TICK_SIZE[symbol] || 0.01 : 0.01);
  const absoluteStyling =
    contract_type === 'call_options' ? { left: '30%' } : { right: '30%' };

  if (Number.isNaN(Number(price))) {
    return (
      (<span data-palette="GammaValue">
        {' '}-{showBuySellButton && (
          <div
            className={
              contract_type === 'call_options'
                ? 'call-buy-sell-button'
                : 'put-buy-sell-button'
            }
            style={absoluteStyling}
            data-palette="GammaValue">
            <BuySellHoverButton isTableHoverButton product={{ ...prop }} />
          </div>
        )}
      </span>)
    );
  }

  return (
    (<div data-palette="GammaValue">
      {cropAfterDecimals(price, GREEK_PRECISION.GAMMA)}
      {showBuySellButton && (
        <div
          className={
            contract_type === 'call_options'
              ? 'call-buy-sell-button'
              : 'put-buy-sell-button'
          }
          style={absoluteStyling}
          data-palette="GammaValue">
          <BuySellHoverButton isTableHoverButton product={{ ...prop }} />
        </div>
      )}
    </div>)
  );
});

const VeggaValue = subscribe(prop => {
  // const { underlying_asset } = prop;
  // const { symbol } = underlying_asset || {};
  const price = vegaPriceSelectorById(String(prop?.id));
  // const tickPrecision = calcPrecision(VEGA_TICK_SIZE[symbol] || 0.01);
  if (Number.isNaN(Number(price))) {
    return <span data-palette="VeggaValue"> - </span>;
  }
  return <div data-palette="VeggaValue"> {cropAfterDecimals(price, GREEK_PRECISION.VEGA)} </div>;
});

const BestAskValue = subscribe(prop => {
  const { id, tick_size } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  // const sellOrderbook = orderBookSellSelector();
  const selectedProductAsk = useSelector(selectedProductBestAskPriceSelector);
  const price =
    Number(selectedProductId) === Number(id)
      ? selectedProductAsk || bestAskById(String(prop?.id))
      : bestAskById(String(prop?.id));
  if (!price || Number.isNaN(Number(price))) {
    return <span data-palette="BestAskValue"> - </span>;
  }
  return <div data-palette="BestAskValue"> {round_by_tick_size(price, tick_size)} </div>;
});

const BestBidValue = subscribe(prop => {
  const { id, tick_size } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  // const buyOrderbook = orderBookBuySelector();
  const selectedProductBid = useSelector(selectedProductBestBidPriceSelector);
  const price =
    Number(selectedProductId) === Number(id)
      ? selectedProductBid || bidQtyById(String(prop?.id))
      : bestBuyById(String(prop?.id));
  if (!price || Number.isNaN(Number(price))) {
    return <span data-palette="BestBidValue"> - </span>;
  }
  return <div data-palette="BestBidValue"> {round_by_tick_size(price, tick_size)} </div>;
});

const IVValue = subscribe(prop => {
  const { id } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const selectedImpliedVolatility = impliedVolatilityState();
  const ivPrice =
    Number(selectedProductId) === Number(id)
      ? selectedImpliedVolatility || impliedVolatilitySelectorById(String(prop?.id))
      : impliedVolatilitySelectorById(String(prop?.id));
  if (!ivPrice || Number.isNaN(Number(ivPrice))) {
    return <span data-palette="IVValue"> - </span>;
  }
  return <div data-palette="IVValue"> {ivPrice}% </div>;
});

const SelectedProductBidQtyValue = () => {
  const selectedProduct = useSelector(selectedProductSelector);
  const { contract_value } = selectedProduct || {};
  const selectedProductBidQty = useL2BestValue({ price: false, bid: true });

  const fractionLength = afterDecimalLength(contract_value);

  const qtyInUnderlying = decimalMultiplication(selectedProductBidQty, contract_value);
  return (
    (<div data-cv={fractionLength} data-palette="SelectedProductBidQtyValue">
      {' '}
      {addZeroesUntilCorrectPrecision(qtyInUnderlying, fractionLength)}{' '}
    </div>)
  );
};

const BidQtyValue = subscribe(prop => {
  const { contract_value } = prop;
  const selectedContractCurrency = useSelector(state =>
    selectedContractCurrencySelector(state)
  );
  const contractFallback = CONTRACT_VALUE_FALLBACK[selectedContractCurrency];
  // const buyOrderbook = orderBookBuySelector();

  const fractionLength = afterDecimalLength(contract_value);
  const fallbackFractionLength = afterDecimalLength(contractFallback);

  const qty = bidQtyById(String(prop?.id));
  if (!qty || Number.isNaN(Number(qty))) {
    return (
      (<div data-cv={fractionLength} data-palette="BidQtyValue">
        {' '}
        {addZeroesUntilCorrectPrecision(0, fallbackFractionLength)}{' '}
      </div>)
    );
  }
  const qtyInUnderlying = decimalMultiplication(qty, contract_value);

  return (
    (<div data-cv={fractionLength} data-palette="BidQtyValue">
      {' '}
      {addZeroesUntilCorrectPrecision(qtyInUnderlying, fractionLength)}{' '}
    </div>)
  );
});

const OptionChainBidQtyValue = prop => {
  const { id } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  if (Number(selectedProductId) === Number(id)) {
    return <SelectedProductBidQtyValue />;
  }
  return <BidQtyValue {...prop} />;
};

const SelectedProductAskQtyValue = () => {
  const selectedProduct = useSelector(selectedProductSelector);
  const { contract_value } = selectedProduct || {};
  const selectedProductAskQty = useL2BestValue({ price: false, ask: true });
  const qtyInUnderlying = decimalMultiplication(selectedProductAskQty, contract_value);
  const fractionLength = afterDecimalLength(contract_value);
  return (
    (<div data-cv={contract_value} data-palette="SelectedProductAskQtyValue">
      {' '}
      {addZeroesUntilCorrectPrecision(qtyInUnderlying, fractionLength)}{' '}
    </div>)
  );
};

const AskQtyValue = subscribe(prop => {
  const { contract_value } = prop;
  // const sellOrderbook = orderBookSellSelector();
  // const selectedProductAskQty = useSelector(selectedProductBestAskSizeSelector);

  const fractionLength = afterDecimalLength(contract_value);

  const qty = askQtyById(String(prop?.id));
  if (!qty || Number.isNaN(Number(qty))) {
    return <span data-palette="AskQtyValue"> - </span>;
  }
  const qtyInUnderlying = decimalMultiplication(qty, contract_value);
  return (
    (<div data-cv={contract_value} data-palette="AskQtyValue">
      {' '}
      {addZeroesUntilCorrectPrecision(qtyInUnderlying, fractionLength)}{' '}
    </div>)
  );
});

const OptionChainAskQtyValue = prop => {
  const { id } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  if (Number(selectedProductId) === Number(id)) {
    return <SelectedProductAskQtyValue />;
  }
  return <AskQtyValue {...prop} />;
};

const BidIVValue = subscribe(prop => {
  const { componentAs, className } = prop;
  const BidIV = BidIVById(String(prop?.id));

  if (!BidIV || Number.isNaN(Number(BidIV))) {
    return <span data-palette="BidIVValue"> - </span>;
  }
  return (
    <Box as={componentAs || 'div'} className={className}>
      {' '}
      {(BidIV * 100).toFixed(1)}%{' '}
    </Box>
  );
});

const SelectedProductBidValue = () => {
  const selectedProduct = useSelector(selectedProductSelector);
  const { id, tick_size: tickSize } = selectedProduct;
  // const selectedProductBid = useSelector(selectedProductBestBidPriceSelector);
  const selectedProductBid = useL2BestValue({ price: true, bid: true });
  const precision = afterDecimalLength(tickSize);
  return (
    (<div
      className={styles[`option-table-cell`]}
      data-palette="SelectedProductBidValue">
      <span className={styles.bid} data-tick={tickSize} title={selectedProductBid}>
        {addZeroesUntilCorrectPrecision(round(selectedProductBid, precision), precision)}
      </span>
      <BidIVValue className={styles.bottom} componentAs="span" id={id} />
      {/* <span className={styles.bottom}>{(BidIV * 100).toFixed(1)}%</span> */}
    </div>)
  );
};

const BidAndBidIVValue = subscribe(prop => {
  const { tick_size: tickSize, id } = prop;
  // const buyOrderbook = orderBookBuySelector();
  // const selectedProductBid = useSelector(selectedProductBestBidPriceSelector
  const price = bestBuyById(String(prop?.id));
  const precision = afterDecimalLength(tickSize);
  const BidIV = BidIVById(String(prop?.id));
  if (!price || Number.isNaN(Number(price)) || !BidIV || Number.isNaN(Number(BidIV))) {
    return <span data-palette="BidAndBidIVValue"> - </span>;
  }
  return (
    (<div className={styles[`option-table-cell`]} data-palette="BidAndBidIVValue">
      <span className={styles.bid} data-tick={tickSize} title={price}>
        {addZeroesUntilCorrectPrecision(round(price, precision), precision)}
      </span>
      <BidIVValue className={styles.bottom} componentAs="span" id={id} />
      {/* <span className={styles.bottom}>{(BidIV * 100).toFixed(1)}%</span> */}
    </div>)
  );
});

const OptionChainBidAndBidIVValue = prop => {
  const { id } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  if (Number(selectedProductId) === Number(id)) {
    return <SelectedProductBidValue />;
  }
  return <BidAndBidIVValue {...prop} />;
};

const AskIVValue = subscribe(prop => {
  const { componentAs, className } = prop;
  const AskIV = AskIVById(String(prop?.id));
  if (!AskIV || Number.isNaN(Number(AskIV))) {
    return <span data-palette="AskIVValue"> - </span>;
  }
  return (
    <Box as={componentAs || 'div'} className={className}>
      {' '}
      {(AskIV * 100).toFixed(1)}%{' '}
    </Box>
  );
});

const SelectedProductAskValue = showBuySellButton => {
  const selectedProduct = useSelector(selectedProductSelector);
  const { id, tick_size: tickSize, contract_type } = selectedProduct;
  // const selectedProductBid = useSelector(selectedProductBestBidPriceSelector);
  const selectedProductAsk = useL2BestValue({ price: true, ask: true });
  const precision = afterDecimalLength(tickSize);
  const absoluteStyling =
    contract_type === 'call_options' ? { left: '27%' } : { right: '25%' };
  if (!selectedProductAsk || Number.isNaN(Number(selectedProductAsk))) {
    return (
      (<div data-palette="SelectedProductAskValue">
        {' '}-{showBuySellButton && (
          <div
            className={
              contract_type === 'call_options'
                ? 'call-buy-sell-button'
                : 'put-buy-sell-button'
            }
            style={absoluteStyling}
            data-palette="SelectedProductAskValue">
            <BuySellHoverButton isTableHoverButton product={{ ...selectedProduct }} />
          </div>
        )}
      </div>)
    );
  }
  return (
    (<div
      className={styles[`option-table-cell`]}
      data-palette="SelectedProductAskValue">
      <span className={styles.ask} data-tick={tickSize} title={selectedProductAsk}>
        {addZeroesUntilCorrectPrecision(round(selectedProductAsk, precision), precision)}
      </span>
      <AskIVValue className={styles.bottom} componentAs="span" id={id} />
      {showBuySellButton && (
        <div
          className={
            contract_type === 'call_options'
              ? 'call-buy-sell-button'
              : 'put-buy-sell-button'
          }
          style={absoluteStyling}
          data-palette="SelectedProductAskValue">
          <BuySellHoverButton isTableHoverButton product={{ ...selectedProduct }} />
        </div>
      )}
    </div>)
  );
};

const AskAndAskIVValue = subscribe(prop => {
  const { tick_size: tickSize, contract_type, showBuySellButton } = prop;

  // const [leftMargins, setLeftMargins] = useState({left : 160});
  // const [rightMargins, setRightMargins] = useState({right : 160});
  // const sellOrderbook = orderBookSellSelector();
  // const enableColumns = useSelector(state =>
  //   optionChainTradeSelectiveColumns(state)
  // );
  const price = bestAskById(String(prop?.id));
  const precision = afterDecimalLength(tickSize);
  const AskIV = AskIVById(String(prop?.id));

  const absoluteStyling =
    contract_type === 'call_options' ? { left: '27%' } : { right: '25%' };

  if (!price || Number.isNaN(Number(price)) || !AskIV || Number.isNaN(Number(AskIV))) {
    return (
      (<div data-palette="AskAndAskIVValue">
        {' '}-{showBuySellButton && (
          <div
            className={
              contract_type === 'call_options'
                ? 'call-buy-sell-button'
                : 'put-buy-sell-button'
            }
            style={absoluteStyling}
            data-palette="AskAndAskIVValue">
            <BuySellHoverButton isTableHoverButton product={{ ...prop }} />
          </div>
        )}
      </div>)
    );
  }
  return (
    (<div className={styles[`option-table-cell`]} data-palette="AskAndAskIVValue">
      <span className={styles.ask} data-tick={tickSize} title={price}>
        {addZeroesUntilCorrectPrecision(round(price, precision), precision)}
      </span>
      <span className={styles.bottom}>{(AskIV * 100).toFixed(1)}%</span>
      {showBuySellButton && (
        <div
          className={
            contract_type === 'call_options'
              ? 'call-buy-sell-button'
              : 'put-buy-sell-button'
          }
          style={absoluteStyling}
          data-palette="AskAndAskIVValue">
          <BuySellHoverButton isTableHoverButton product={{ ...prop }} />
        </div>
      )}
    </div>)
  );
});

const OptionChainAskAndAskIVValue = prop => {
  const { id } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  if (Number(selectedProductId) === Number(id)) {
    // eslint-disable-next-line react/destructuring-assignment
    return <SelectedProductAskValue showBuySellButton={prop?.showBuySellButton} />;
  }
  return <AskAndAskIVValue {...prop} />;
};

const MarkAndMarkIVValue = subscribe(prop => {
  const { id, tick_size: tickSize } = prop;
  const selectedProductId = useSelector(state => selectedProductIdSelector(state));
  const price =
    Number(selectedProductId) === Number(id)
      ? markPriceState()
      : markPriceSelectorById(String(id));
  const precision = afterDecimalLength(tickSize);
  const MarkIV =
    Number(selectedProductId) === Number(id)
      ? impliedVolatilityState() || impliedVolatilitySelectorById(String(id))
      : impliedVolatilitySelectorById(String(id));
  if (!price || Number.isNaN(Number(price)) || !MarkIV || Number.isNaN(Number(MarkIV))) {
    return <span data-palette="MarkAndMarkIVValue"> - </span>;
  }
  return (
    (<div className={styles[`option-table-cell`]} data-palette="MarkAndMarkIVValue">
      <span className={styles.top} data-tick={tickSize} title={price}>
        {addZeroesUntilCorrectPrecision(round(price, precision), precision)}
      </span>
      <span className={styles.bottom}>{MarkIV}%</span>
    </div>)
  );
});

const OiValue = subscribe(prop => {
  const oiValue = oiSelectorById(String(prop?.id));
  // const tickSize = prop?.tick_size;
  // All OI and volume numbers are in $. so their precisions will always be 2 decimal digits
  const precision = 2;

  if (!oiValue || Number.isNaN(Number(oiValue))) {
    return <span data-palette="OiValue">${addZeroesUntilCorrectPrecision(0, precision)}</span>;
  }
  // const formattedValue = getFormattedVolume()(oiValue);
  const formattedValue = numberPrettified(Number(oiValue), precision, precision);
  return (
    (<div title={oiValue} data-precision={precision} data-palette="OiValue">
      {formattedValue ? `$${formattedValue}` : '-'}
    </div>)
  );
});

const OiChangeValue = subscribe(prop => {
  const oiValue = oiChangeSelectorById(String(prop?.id));
  const precision = 2;

  if (!oiValue || isNan(oiValue)) {
    return <span data-palette="OiChangeValue">{addZeroesUntilCorrectPrecision(0, precision)}</span>;
  }
  const formattedValue = numberPrettified(Number(oiValue), precision, precision);
  return (
    (<div title={oiValue} data-precision={precision} data-palette="OiChangeValue">
      {formattedValue || '-'}
    </div>)
  );
});

const PositionValue = props => {
  const {
    id,
    notional_type,
    contract_value,
    contract_type,
    underlying_asset,
    showUnderlyingSymbol,
  } = props;
  const positions = useSelector(state => allOpenPositionsSelector(state));
  const productPosition = positions.find(pos => Number(pos.product_id) === id);
  if (productPosition) {
    const notional = getPositionValue(
      notional_type,
      contract_value,
      productPosition.entry_price,
      productPosition.size,
      contract_type
    );
    const roundedNotional = cropAfterDecimals(
      notional,
      underlying_asset.minimum_precision
    );
    return (
      (<div data-palette="PositionValue">
        {' '}
        {roundedNotional} {showUnderlyingSymbol ? underlying_asset.symbol : ''}{' '}
      </div>)
    );
  }
  return <span data-palette="PositionValue" />;
};

const MultiLineColumnHeader = ({ topName, bottomName }) => {
  return (
    (<div
      className={styles[`option-column-header`]}
      data-palette="MultiLineColumnHeader">
      <span className={styles.top}>{topName}</span>
      <span className={styles.bottom}>{bottomName}</span>
    </div>)
  );
};

MultiLineColumnHeader.propTypes = {
  topName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottomName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export {
  MarkpriceValue,
  ThetaValue,
  DeltaValue,
  GammaValue,
  VeggaValue,
  BestAskValue,
  BestBidValue,
  IVValue,
  BidQtyValue,
  OptionChainBidQtyValue,
  AskQtyValue,
  OptionChainAskQtyValue,
  AskIVValue,
  BidIVValue,
  OptionChainBidAndBidIVValue,
  BidAndBidIVValue,
  AskAndAskIVValue,
  OptionChainAskAndAskIVValue,
  MarkAndMarkIVValue,
  OiValue,
  PositionValue,
  MultiLineColumnHeader,
  optionTypeProp,
  OiChangeValue,
};
