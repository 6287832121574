import { useMemo } from 'react';

import {
  lastTradePriceSelector,
  selectedProductIdSelector,
  productsSelector,
  lastTradesState,
} from 'selectors';
import {
  // lastPriceSelector,
  tickerDataSelectorById,
} from 'selectors/tickerSelectors';
import { getLastPrice } from 'helpers/formulas';
import { isEmpty } from 'helpers';
import { useAppSelector } from 'storeHooks';

import type { ITicker } from 'types/IVariableStore';

/**
 * @param productId
 * @returns lastTradedPrice based on SelectedProduct or from ticker api
 */
const useLastTradedPrice = (productId: number | string) => {
  const selectedProductId = useAppSelector(state =>
    selectedProductIdSelector(state)
  );
  const products = useAppSelector(productsSelector);

  const isProductsLoaded = !isEmpty(products);

  const productTickerData = !productId
    ? null
    : (tickerDataSelectorById(String(productId)) as ITicker);

  const lastTradedPrice = useMemo(() => {
    if (!productId || !isProductsLoaded) {
      return null;
    }

    if (Number(selectedProductId) === Number(productId)) {
      const selectedProductLastPrice = lastTradePriceSelector();
      return selectedProductLastPrice;
    }

    // const lastPrice = lastPriceSelector(products[productId]);
    const lastPrice = getLastPrice(
      lastTradesState[String(productId)],
      productTickerData
    );

    return lastPrice;
  }, [productId, selectedProductId, isProductsLoaded, productTickerData]);

  return lastTradedPrice;
};

export default useLastTradedPrice;
