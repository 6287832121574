/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
// import rpap from 'request-promise';

import { UNSUBSCRIBE_OHLC_CANDEL } from 'actionTypes/socket';
import { CHART_SUBSCRIPTION_PAYLOAD } from 'actionTypes/tradingView';
import { CHARTS_API } from 'constants/api';
import { API_URL } from 'constants/constants';
import { TIME_FORMATS } from 'constants/timeformats';
import { add, dateFormat, getDateTime, getDiff, getNativeDate } from 'helpers/day';
import { last, max, min } from 'helpers/ramda';
import { constructQueryString } from 'helpers/utils';
import StoreProxy from 'storeProxy';

import { CHART_TABS } from './chart.constants';
import {
  calculateFromTime,
  calculateToTime,
  currentResolution,
  getChartCacheTTL,
  getSubscriptionValue,
} from './helper';
import { tvReadyBehaviorSubject } from './withTradingView';

// const rp = rpap.defaults({ json: true });

// const rp = require('request-promise').defaults({ json: true });

// history data
const apiRoot = API_URL;
const history = {};

// realtime data
let symbolInfoList = {};
let markPriceUpdate = false;
let markSymbolList = {};
let indexPriceUpdate = false;
let indexSymbolList = {};
const lastMarkClosingPrice = null;
const lastIndexClosingPrice = null;

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let timeoutId = null;
let lastUpdatedSocketTime = '';

if (timezone && timezone.toLowerCase() === 'asia/calcutta') {
  timezone = 'Asia/Kolkata';
}

const convertToSeconds = value => {
  return value * 60000;
};

const valueToSeconds = {
  1: convertToSeconds(1),
  5: convertToSeconds(5),
  3: convertToSeconds(3),
  15: convertToSeconds(15),
  30: convertToSeconds(30),
  60: convertToSeconds(60),
  120: convertToSeconds(120),
  240: convertToSeconds(240),
  360: convertToSeconds(360),
  D: convertToSeconds(1440),
  '1D': convertToSeconds(1440),
  '7D': convertToSeconds(10080),
  '30D': convertToSeconds(43200),
  '1W': convertToSeconds(10080),
  '2W': convertToSeconds(20160),
};

const valueToMinutes = {
  1: 1,
  5: 5,
  3: 3,
  15: 15,
  30: 30,
  60: 60,
  120: 120,
  240: 240,
  360: 360,
  D: 1440,
  '1D': 1440,
  '7D': 10080,
  '30D': 43200,
  '1W': 10080,
  '2W': 20160,
};

function updateBar(data) {
  if (symbolInfoList[data.symbol]) {
    const sub = symbolInfoList[data.symbol];
    // Last Bar is for Bars last records from history API
    const lastBar = sub.lastBar || {};
    const lastTime = lastBar?.time;
    // Create New Candle
    if (data.candle_start_time > lastTime * 1000) {
      lastUpdatedSocketTime = new Date();
      markPriceUpdate = false;
      indexPriceUpdate = false;
      const newLastBar = {
        time: data.candle_start_time / 1000,
        open: data.open || lastBar.close,
        high: data.high || lastBar.close,
        low: data.low || lastBar.close,
        close: data.close || lastBar.close,
        volume: data.volume || 0,
      };
      sub.onRealtimeCallback(newLastBar);
      sub.lastBar = newLastBar;
      symbolInfoList[data.symbol] = sub;

      if (Object.keys(markSymbolList).length > 0) {
        Object.keys(markSymbolList).forEach(item => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const newLastBar = {
            time: data.candle_start_time / 1000,
            open: lastMarkClosingPrice,
            high: lastMarkClosingPrice,
            low: lastMarkClosingPrice,
            close: lastMarkClosingPrice,
          };
          markSymbolList[item].onRealtimeCallback(newLastBar);
          markSymbolList[item].lastBar = newLastBar;
        });
        markPriceUpdate = true;
      }
      if (Object.keys(indexSymbolList).length > 0) {
        Object.keys(indexSymbolList).forEach(item => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const newLastBar = {
            time: data.candle_start_time / 1000,
            open: lastIndexClosingPrice,
            high: lastIndexClosingPrice,
            low: lastIndexClosingPrice,
            close: lastIndexClosingPrice,
          };
          indexSymbolList[item].onRealtimeCallback(newLastBar);
          indexSymbolList[item].lastBar = newLastBar;
        });
        indexPriceUpdate = true;
      }
      // dataFeed.updateMarkPrice(lastMarkClosingPrice, data.candle_start_time);
    } else {
      // console.log("DEBUG",lastBar);
      // update lastBar candle!
      lastBar.low = data.low || lastBar.low;
      lastBar.high = data.high || lastBar.high;
      lastBar.open = data.open || lastBar.open;
      lastBar.close = data.close || lastBar.close;
      lastBar.volume = data.volume || lastBar.volume;
      if (!lastBar.low) lastBar.low = 0;
      if (!lastBar.close) lastBar.close = 0;
      if (!lastBar.high) lastBar.high = 0;
      if (!lastBar.open) lastBar.open = 0;
      if (!lastBar.time) lastBar.time = new Date().getTime();
      if (!lastBar.volume) lastBar.volume = 0;
      if (lastBar.isBarClosed === undefined) lastBar.isBarClosed = false;
      if (lastBar.isLastBar === undefined) lastBar.isLastBar = true;
      sub.onRealtimeCallback(lastBar);
      sub.lastBar = lastBar;
      symbolInfoList[data.symbol] = sub;
    }
  }
}

function updatePrice(
  closingPrice,
  priceUpdate,
  // time = null,
  isActiveTab,
  symbolList = {}
) {
  if (isActiveTab) {
    if (Object.keys(symbolList).length > 0) {
      Object.keys(symbolList).forEach(item => {
        const lastBar = symbolList[item].lastBar || {};
        const { time } = lastBar;
        const currentTime = new Date().getTime();
        if (currentTime > time + valueToSeconds[currentResolution()?.value]) {
          const newLastBar = {
            open: closingPrice,
            high: closingPrice,
            low: closingPrice,
            close: closingPrice,
            time: time + valueToSeconds[currentResolution()?.value],
          };
          symbolList[item].onRealtimeCallback(newLastBar);
          symbolList[item].lastBar = newLastBar;
        } else {
          const newLastBar = {
            open: lastBar.open,
            high: max(lastBar.high, closingPrice),
            low: min(lastBar.low, closingPrice),
            close: closingPrice,
            time: lastBar.time,
          };
          symbolList[item].onRealtimeCallback(newLastBar);
          symbolList[item].lastBar = newLastBar;
        }
      });
    }
  } else if (priceUpdate) {
    if (Object.keys(symbolList).length > 0) {
      Object.keys(symbolList).forEach(item => {
        const { lastBar } = symbolList[item];
        if (lastBar) {
          lastBar.low = min(lastBar.low, closingPrice);
          lastBar.high = max(lastBar.high, closingPrice);
          lastBar.close = closingPrice;
          symbolList[item].onRealtimeCallback(lastBar);
          symbolList[item].lastBar = lastBar;
        }
      });
    }
  }
}

const dataFeed = {
  onReady: onreadyCallVBack => {
    // rp({
    //   uri: CHARTS_API.CONFIG,
    // }).then(data => {
    //   onreadyCallVBack(data.result);
    // });
    fetch(CHARTS_API.CONFIG)
      .then(response => {
        // console.log('DEBUG: datafeed.onReady called');
        if (!response.ok) {
          throw new Error('Network Error: Datafeed charts config');
        }
        return response.json();
      })
      .then(data => {
        onreadyCallVBack(data.result);
      });
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    const queryString = {
      limit: 30,
      query: userInput,
      exchange,
      symbolType,
    };
    const url = constructQueryString(`${apiRoot}/v2/chart/search`, queryString);
    return fetch(url)
      .then(response => {
        // console.log('DEBUG: datafeed.searchSymbols called');
        if (!response.ok) {
          throw new Error('Network Error: Datafeed charts config');
        }
        return response.json();
      })
      .then(response => {
        onResultReadyCallback(response.result);
      });
    // return rp({
    //   uri: `${apiRoot}/v2/chart/search`,
    //   qs: queryString,
    // }).then(response => {
    //   onResultReadyCallback(response.result);
    // });
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    if (symbolName.includes('Delta:')) {
      symbolName = symbolName.split(':')[1];
    }
    const qs = {
      symbol: symbolName,
    };

    const url = constructQueryString(CHARTS_API.SEARCH_SYMBOL, qs);
    // rp({
    //   uri: CHARTS_API.SEARCH_SYMBOL,
    //   qs,
    // })
    fetch(url)
      .then(response => {
        // console.log('DEBUG: datafeed.resolveSymbol called');
        if (!response.ok) {
          throw new Error('Network Error: Datafeed charts config');
        }
        return response.json();
      })
      .then(data => {
        const { has_no_volume: hasNoVolume, ...rest } = data.result;

        const symbolInfo = {
          timezone,
          has_intraday: true,
          // visible_plots_set: hasNoVolume,
          exchange: 'Delta',
          listed_exchange: 'Delta',
          ...rest,
        };
        onSymbolResolvedCallback(symbolInfo);
      })
      .catch(err => {
        onResolveErrorCallback(err);
      });
  },
  getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    const { from, to, countBack, firstDataRequest } = periodParams;
    const symbol = symbolInfo.name?.replace('/', '');
    const isFundingChart = symbolInfo.type === 'funding';

    const currentResolutionValue = currentResolution()?.value;

    if (resolution === '1D') {
      resolution = 'D';
    }

    const apiResolution = currentResolutionValue === '1D' ? 'D' : currentResolutionValue;

    const toTime = isFundingChart
      ? to
      : calculateToTime({ resolution: apiResolution, to });
    const fromTime = isFundingChart
      ? from
      : calculateFromTime({ countBack, resolution: apiResolution, to: toTime, from });
    const chartCacheTTL = getChartCacheTTL(currentResolutionValue);

    // console.log('DEBUG', 'datafeed.getBars called', {
    //   symbolInfo,
    //   resolution,
    //   currentResolutionValue: currentResolution(),
    //   periodParams,
    //   toTime,
    //   to,
    //   from,
    //   fromTime,
    //   chartCacheTTL,
    // });

    const qs = {
      symbol,
      resolution: isFundingChart ? '240' : apiResolution,
      from: fromTime,
      to: toTime,
      cache_ttl: chartCacheTTL,
    };

    const url = constructQueryString(CHARTS_API.HISTORY_SYMBOL, qs);

    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network Error: Datafeed charts config');
        }
        return response.json();
      })
      .then(response => {
        const data = response.result;
        if (data.s === 'ok') {
          // eslint-disable-next-line func-names
          const bars = data.t.map(function (t, i) {
            if (i === data.t.length - 1) {
              if (data.h[i] === null || data.o[i] === null || data.c[i] === null) {
                return {
                  time: t * 1000,
                  low: data.l[i] ? data.l[i] : data.l[i - 1],
                  high: data.h[i] ? data.h[i] : data.h[i - 1],
                  open: data.o[i] ? data.o[i] : data.o[i - 1],
                  close: data.c[i] ? data.c[i] : data.c[i - 1],
                  volume: data.v[i] ? data.v[i] : data.v[i - 1],
                };
              }
            }
            return {
              time: t * 1000, // trading view need time in milisecond
              low: data.l[i] ? data.l[i] : 0,
              high: data.h[i] ? data.h[i] : 0,
              open: data.o[i] ? data.o[i] : 0,
              close: data.c[i] ? data.c[i] : 0,
              volume: data.v[i] ? data.v[i] : 0,
            };
          });

          if (firstDataRequest) {
            const lastBar = bars[bars.length - 1];
            history[symbolInfo.name] = { lastBar };
          }

          return bars;
        }
        return [];
      })
      .then(bars => {
        // console.log('DEBUG:', fromTime < 1580515200, bars, countBack);
        // considering last data point as Feb 1, 2020 = 1580515200
        if (from < 1580515200 || from < 0 || to < 0) {
          setTimeout(() => {
            onHistoryCallback(bars, { noData: true });
          }, 0);
        } else {
          const isOptions =
            window.location.href.includes('options_chain') ||
            window.location.href.includes('options') ||
            window.location.href.includes('move_options');

          if (bars.length < countBack && isOptions) {
            setTimeout(() => {
              onHistoryCallback(bars, { noData: true });
            }, 0);
          } else {
            setTimeout(() => {
              onHistoryCallback(bars);
            }, 0);
          }
        }

        lastUpdatedSocketTime = new Date();

        if (sessionStorage.getItem('currentSymbol') === symbol) {
          const timerValueForInterval =
            Number(valueToSeconds[currentResolutionValue] || 3600000) + 1000;
          const time = (timerValueForInterval / 10) * 10;

          if (!timeoutId) {
            timeoutId = setInterval(() => {
              if (symbolInfoList[symbol]) {
                const lastTimeDate = dateFormat(
                  getDateTime(lastUpdatedSocketTime),
                  TIME_FORMATS.DD_MM_YYYY_HH_mm
                );
                const currentTimeDate = dateFormat(
                  new Date(),
                  TIME_FORMATS.DD_MM_YYYY_HH_mm
                );

                const minutes = getDiff(currentTimeDate, lastTimeDate, 'minutes');
                const { lastBar } = symbolInfoList[symbol];

                if (minutes >= Number(currentResolutionValue)) {
                  const newTime = new Date(
                    getNativeDate(
                      add(
                        getDateTime(lastBar?.time),
                        Number(valueToMinutes[currentResolutionValue]),
                        'minutes'
                      )
                    )
                  ).getTime();
                  // console.log('DEBUG', 'datafeed.getBars called', {
                  //   symbolInfo,
                  //   currentResolutionValue: currentResolution(),
                  //   periodParams,
                  //   newTime,
                  // });
                  console.log('DEBUG', lastBar);
                  const clientResponse = {
                    candle_start_time: newTime * 1000,
                    close: lastBar?.close,
                    high: lastBar?.close,
                    low: lastBar?.close,
                    open: lastBar?.close,
                    symbol,
                    volume: lastBar?.volume,
                  };
                  updateBar(clientResponse, false);
                }
              }
            }, Number(time));
          }
        }
        return bars;
      })
      .catch(err => {
        onErrorCallback(err);
      });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    // if (symbolInfo.type !== 'mark' && symbolInfo.type !== 'funding') {
    const symbol = symbolInfo.name.replace('/', '');
    if (resolution === '1D') {
      resolution = 'D';
    }
    const newSub = {
      symbol,
      subscribeUID,
      resolution,
      symbolInfo,
      lastBar: !history[symbolInfo.name]
        ? { time: new Date().getTime() }
        : history[symbolInfo.name].lastBar,
      onRealtimeCallback,
      lastSubscribed: currentResolution()?.value,
      onResetCacheNeededCallback,
    };

    if (
      symbolInfo.type !== 'mark' &&
      symbolInfo.type !== 'funding' &&
      symbolInfo.type !== 'spot_index'
    ) {
      symbolInfoList[symbol] = newSub;
      StoreProxy.dispatch({
        type: CHART_SUBSCRIPTION_PAYLOAD,
        payload: {
          type: getSubscriptionValue(currentResolution()?.value),
          symbol,
          subscribedFrom: 'dataFeed',
        },
      });
    } else if (symbolInfo.type === 'mark') {
      markPriceUpdate = true;
      markSymbolList[symbol] = newSub;
    } else if (symbolInfo.type === 'spot_index') {
      indexPriceUpdate = true;
      indexSymbolList[symbol] = newSub;
    }
  },

  unsubscribeBars: subscriberUID => {
    // Once the Chart update issue is resolved we can comment or remove this function as it is not impact in the chart
    localStorage.setItem('unsubscribeBarsCalled', subscriberUID);
  },
  updatedData: data => {
    if (symbolInfoList[data.symbol]) {
      updateBar(data, true);
    }
  },

  resetCache: () => {
    tvReadyBehaviorSubject.subscribe(tvWidgetView => {
      if (tvWidgetView) {
        try {
          Object.keys(symbolInfoList).forEach(item => {
            symbolInfoList[item].onResetCacheNeededCallback();
          });
        } catch (e) {
          localStorage.setItem('isChartFailedtoReset', new Date());
        }
      }
    });
    // }, time);
  },
  removeAllSubscriptions: () => {
    clearInterval(timeoutId);
    timeoutId = null;
    Object.keys(symbolInfoList).forEach(item => {
      StoreProxy.dispatch({
        type: UNSUBSCRIBE_OHLC_CANDEL,
        payload: {
          type: getSubscriptionValue(symbolInfoList[item].lastSubscribed),
          symbol: symbolInfoList[item].symbol,
        },
      });
    });
    symbolInfoList = {};
    markSymbolList = {};
    indexSymbolList = {};
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateMarkPrice: (closingPrice, _time = null, currentActiveTab) => {
    // lastMarkClosingPrice = closingPrice;
    updatePrice(
      closingPrice,
      markPriceUpdate,
      // time,
      currentActiveTab === CHART_TABS.MARK_CHART,
      markSymbolList
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateIndexPrice: (closingPrice, _time = null, currentActiveTab) => {
    // lastIndexClosingPrice = closingPrice;
    updatePrice(
      closingPrice,
      indexPriceUpdate,
      // time,
      currentActiveTab === CHART_TABS.INDEX_PRICE_CHART,
      indexSymbolList
    );
  },
};

export default dataFeed;
