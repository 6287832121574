import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { fetchCountryFromIP } from 'actions/other';
import { REUSABLE_STRIP_URL } from 'constants/api';
import { STORAGE_KEYS } from 'constants/enums';
import { addLogsToSentry } from 'helpers/sentry';
import { userCountryVisibilityFilter } from 'helpers/user';
import { useAppDispatch, useAppSelector, useLocalStorage } from 'hooks';
import {
  getUserId,
  isUserLoggedInSelector,
  selectCountryFromIp,
  selectUserCountry,
} from 'selectors/otherSelectors';

import {
  countryFromIpValidator,
  isStripUrlValidForRoute,
  shouldShowStrip,
  loginStateValidator,
  userIdsFilter,
} from './useShowInformationStrip.helpers';
import { STRIP_TYPES, type StripData } from './useShowInformationStrip.types';

const useShowInformationStrip = () => {
  const [stripsData, setStripsData] = useState<StripData[] | null>(null);

  const history = useHistory();

  const appDispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(isUserLoggedInSelector);

  const country = useAppSelector(selectUserCountry);

  const countryFromIp = useAppSelector(selectCountryFromIp);

  const userId = useAppSelector(getUserId);

  const [currentClosedStrip, setCurrentClosedStrip] = useLocalStorage<string | null>(
    STORAGE_KEYS.INFORMATION_STRIP,
    null
  );

  const loadStripData = useCallback(async () => {
    try {
      const response: StripData[] = await (await fetch(REUSABLE_STRIP_URL)).json();

      setStripsData(response);
    } catch {
      setStripsData(null);
    }
  }, []);

  useEffect(() => {
    loadStripData();
  }, [loadStripData]);

  useEffect(() => {
    appDispatch(fetchCountryFromIP()).catch(addLogsToSentry);
  }, [appDispatch]);

  // added ?. for find earlier we had single object as strip data.
  const stripDataMatchingAllChecks = stripsData?.find?.(data => {
    return (
      userCountryVisibilityFilter(country, data.countriesValue) &&
      countryFromIpValidator(countryFromIp, data.ipCountries) &&
      loginStateValidator(isLoggedIn, data.showWhenLogin) &&
      // !! history.location is being used here temporarily dont use this pattern without consultation with other devs
      isStripUrlValidForRoute(data.webAppPathnames, history.location) &&
      shouldShowStrip({
        remainVisible: data.remainVisible,
        currentClosedStrip,
        displayTime: data.displayTime,
        stripName: data.name,
      }) &&
      userIdsFilter(userId, data.userIds)
    );
  });

  return {
    renderStrip: Boolean(stripDataMatchingAllChecks),
    isWarningStrip: stripDataMatchingAllChecks?.type === STRIP_TYPES.WARN,
    isErrorStrip: stripDataMatchingAllChecks?.type === STRIP_TYPES.ERROR,
    isInformationStrip: stripDataMatchingAllChecks?.type === STRIP_TYPES.INFORMATION,
    isHeadingPresent: Boolean(stripDataMatchingAllChecks?.heading),
    remainVisible: Boolean(stripDataMatchingAllChecks?.remainVisible),
    redirectionLink: stripDataMatchingAllChecks?.redirectionLink,
    setCurrentClosedStrip,
    name: stripDataMatchingAllChecks?.name,
    heading: stripDataMatchingAllChecks?.heading,
    message: stripDataMatchingAllChecks?.message,
    openInNewTab: stripDataMatchingAllChecks?.openInNewTab,
  };
};

export default useShowInformationStrip;
