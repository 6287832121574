import styled from 'styled-components';

import { device } from 'helpers/screenSizes';
import { PageHeader, HoldingsTable } from 'UIKit';

export const BalanceWrapper = styled.div`
  .asset-history {
    text-align: center;

    .asset-history-btn {
      width: 100px;
      height: 24px;
      border-radius: 1px;
      border: solid 1px ${props => props.theme.separator};
      background-color: transparent;
      padding: 2px 12px 4px 12px;

      &:hover {
        background-color: ${props => props.theme.primaryTheme};
        color: white;
      }
    }
  }

  .btc-balance {
    font-size: 18px;
    text-align: right;
  }

  .usd-balance {
    font-size: 14px;
    text-align: right;
    opacity: 0.6;
  }

  .page-title {
    font-size: 18px;
    margin-bottom: 32px;

    @media ${device.down.sm} {
      margin-bottom: 16px;
    }
  }

  .sub-page-title {
    font-size: 18px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .header-bottom {
    margin-bottom: 32px;
  }

  .table {
    margin-bottom: 0;
    width: 654px;

    td {
      border: 0.5px solid ${props => props.theme.primaryTheme}99;
      font-size: 14px;
    }

    tr {
      height: 64px;
      text-align: center;
    }

    th {
      border: none;
    }
  }

  .wallet-tabs {
    width: 29%;
    .tab {
      border-bottom: 0.5px solid ${props => props.theme.separator};
    }
    .tab.active {
      border-bottom: 2px solid ${props => props.theme.primaryTheme};
    }
  }

  .trans-history {
    color: ${props => props.theme.primaryTheme};
    cursor: pointer;
    text-align: center;
  }

  .coins {
    width: 20px;
    display: inline-block;

    svg {
      margin-top: -2px;
    }
  }

  .Collapsible {
    margin-bottom: 2px;
  }

  .balance-text {
    font-size: 12.1px;
    padding-top: 2px;
    color: ${props => props.theme.secondaryText};
  }

  .balanceValue {
    font-size: 14px;
  }

  .go-back-div {
    margin: -15px 0px 20px;
    cursor: pointer;
  }

  .go-back {
    font-size: 12.1px;
    opacity: 0.8;
    margin: -20px 0px 0px 20px;
  }

  .wallet_text {
    margin-right: 5px;
  }

  .icon_color .menu-last {
    width: 18px;
    height: 18px;
    fill: ${props => props.theme.primaryText};
  }

  .btc-action {
    cursor: pointer;
    font-size: 14px;
    @media ${device.down.md} {
      font-size: 12.1px;
      justify-content: right;
    }

    .withdrawal-text {
      line-height: 1.29;
      color: ${props => props.theme.secondaryText};
      padding-left: 12px;
      cursor: pointer;
      padding-right: 16px;
      @media ${device.down.md} {
        padding-right: 10px;
        padding-left: 0px;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme};
      }
    }

    .deposit-text {
      line-height: 1.29;
      color: ${props => props.theme.secondaryText};
      padding-left: 12px;
      cursor: pointer;
      @media ${device.down.md} {
        padding: 0 10px;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme};
      }
    }
  }

  .convert-action {
    cursor: pointer;
    font-size: 14px;
    @media ${device.down.md} {
      font-size: 12.1px;
      justify-content: right;
    }

    .convert-text {
      line-height: 1.29;
      color: ${props => props.theme.secondaryText};
      padding-left: 10px;
      cursor: pointer;

      @media ${device.down.md} {
        padding: 0;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme};
      }
    }
  }

  .conversion-icon {
    width: 18px !important;
    height: 18px !important;
  }

  .ReactTable {
    .rt-table {
      .rt-head {
        height: 40px;
      }
    }
  }

  .rt-td {
    font-size: 14px;
  }
  @media ${device.down.md} {
    padding: 12px 12px;
  }
`;

export const BigMargin = styled.div`
  margin-bottom: 40px;
`;

export const HeaderText = styled(PageHeader)`
  margin-bottom: 25px;
  font-family: var(--fontRegular);
  font-size: 18px;

  @media ${device.down.md} {
    font-size: 16px;
  }
`;

export const CollapsibleComponentWrapper = styled.div`
  background-color: ${props => props.theme.primaryBackground};
  padding: 10px 10px 8px;

  .collapsible-div {
    padding: 2px 10px;
  }

  .collapsible-ballance {
    padding: 2px 10px;
    padding-left: 35px;
  }

  .coins {
    padding-right: 18px !important;
  }

  .coins svg {
    margin-top: -5px;
  }
`;

export const AssetCard = styled.div`
  padding: 10px;
  margin-bottom: 22px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.primaryBackground};

  svg {
    margin-right: 10px;
    transform: translateY(4px);
  }

  hr {
    border: none;
    border-bottom: 1px solid ${props => props.theme.separator};
    margin: 10px auto;
  }

  .collapsible-body {
    margin-bottom: 10px;
  }

  .view-history {
    cursor: pointer;
    color: ${props => props.theme.primaryTheme};
    font-size: 12.1px;
    padding-bottom: 2px;
    border-bottom: 1px solid ${props => props.theme.primaryTheme};
  }

  .action-section {
    width: 300px;
  }
  .balanceValue {
    width: 72px;
    height: 14px;
    font-size: 12.1px;
    font-family: var(--fontRegular);
    line-height: 1.17;
    text-align: right;
  }
`;

export const BalanceTable = styled(HoldingsTable)`
  .rt-th {
    padding-left: 10px !important;

    &:last-of-type {
      padding-left: 20px !important;
    }
  }

  .rt-tbody {
    /* Targeting first row (which has secondary-text) */
    .rt-tr-group:first-of-type {
      .rt-td {
        padding: 15px 10px !important;

        @media ${device.down.lg} {
          padding: 7px 10px !important;
        }

        @media ${device.up.lg} {
          .balance-coin {
            top: 0;
          }
        }
      }
    }

    .rt-td {
      padding: 10px !important;
      display: grid;

      &:first-of-type {
        display: flow-root;
      }
    }
  }

  .balance-coin {
    position: relative;
    top: 5px;
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .coin-text {
    font-size: 12.1px;

    @media ${device.up.xxl} {
      font-size: 14px;
    }
  }
`;
