/* eslint-disable react/prop-types */
import { forwardRef, memo, useState } from 'react';

import cn from 'classnames';
import Timer from 'react-compound-timer';
import ReCAPTCHA, { ReCAPTCHA as IReCAPTCHA } from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import styled from 'styled-components';

import Render from 'components/render';
import { noop } from 'helpers';
import { device } from 'helpers/screenSizes';

// import SingleInput from './singleInput';
import { IS_INDIAN_EXCHANGE, RECAPTCHA_SITE_KEY } from '../../constants/constants';

export const OTPContainer = styled.div`
  margin-bottom: 5px;
  margin-top: 12px;
  font-family: var(--fontRegular);
  display: flex;
  &.withdrawal-verification {
    display: flex;
    align-items: center;
  }
  &.login-security-verification {
    .otpInput:first-of-type {
      margin-left: 0;
    }
  }
  .otpInput {
    color: ${props => props.theme.primaryText};
    width: 38px !important;
    height: 42px;
    margin: 0 7px;
    font-size: 20px;
    font-family: var(--fontRegular);
    text-align: center;
    border-radius: 4px;
    border: solid 1px ${props => props.theme.secondaryText};
    &:focus {
      border: solid 1px ${props => props.theme.primaryTheme};
    }
    &.invalid {
      border: solid 1px ${props => props.theme.negative};
    }
  }
  .resend-code-link {
    display: inline-block;
    &.disabled {
      color: ${props => props.theme.secondaryText};
      pointer-events: none;
      cursor: no-drop !important;
    }
  }
  .resend-otp-container {
    display: inline-block;
    margin-left: 45px;
    border-radius: 2px;
    border: solid 1px ${props => props.theme.primaryTheme};
    text-align: center;
    padding: 5px 23px;
    font-size: 12.1px;
    font-family: var(--fontRegular);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    float: right;
    letter-spacing: 0.07px;
    cursor: pointer;
    background-color: ${props => props.theme.secondaryCTA};
    .resend-text {
      color: ${props => props.theme.secondaryText};
    }
    .resend-text-india {
      color: var(--white);
    }
    .timer {
      color: ${props => props.theme.primaryTheme};
    }
    .timer-india {
      color: var(--white);
    }
    .send-code-text {
      padding: 9px 0px;
    }
    &.disabled {
      background-color: ${props => props.theme.secondaryCTA};
    }
    &:hover {
      background-color: ${props => props.theme.primaryTheme};
      color: ${props => props.theme.primaryText};
      .send-code-text,
      .timer {
        color: var(--primaryCtaText);
      }
      .resend-text {
        color: var(--primaryCtaText);
      }
    }
    &.updatePswVerificationResendOtp {
      a {
        text-decoration: none;
      }
      .timer {
        color: var(--secondaryText);
        &.send-code-text {
          color: var(--primaryTheme);
        }
      }
      .resend-text {
        color: var(--primaryButtonDisable);
      }
      &.disabled {
        background-color: transparent;
      }
      &:hover {
        background-color: transparent;
        a,
        .send-code-text {
          color: var(--primaryButtonHover);
        }
      }
    }
  }
  @media ${device.down.md} {
    &.login-security-verification {
      .otpInput {
        margin: 0 3px;
        &:first-of-type {
          margin-left: 0;
        }
      }
      .resend-otp-container {
        padding: 5px;
        margin-left: 20px;
        font-size: 9px;
        &.disabled {
          font-size: 11px;
        }
      }
    }
  }
  @media ${device.down.xxs} {
    &.login-security-verification {
      .otpInput {
        margin: 0 2px;
      }
      .resend-otp-container {
        padding: 5px;
        margin-left: 0;
        font-size: 8px;
        &.disabled {
          font-size: 11px;
        }
      }
    }
  }
  @media ${device.up.xxxs} and ${device.down.xxs} {
    &.login-security-verification {
      .otpInput {
        margin: 0 2px;
        width: 33px !important;
        height: 33px;
      }
      .resend-otp-container {
        padding: 5px;
        margin: 6px 0;
        font-size: 8px;
        &.disabled {
          font-size: 11px;
        }
      }
    }
  }
`;

export interface IOTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => void;
  resendOTP?: (recaptcha_response: string) => void;
  handleSubmit?: () => void;

  autoFocus?: boolean;
  // hideInitialFocus?: boolean;
  hideSendCodeRightSection?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  isInputSecure?: boolean;

  // style?: CSSProperties;
  className?: string;
  id?: string;

  // inputStyle?: CSSProperties;
  inputClassName?: string;
  hideSendCode?: boolean;
  testID?: string;
  resendCodeClassName?: string;
  resendCodeText?: string;
  renderRecaptcha?: boolean;
  // recaptchaRef: object;
}

const OTPInputComponent = forwardRef<IReCAPTCHA, IOTPInputProps>((props, ref) => {
  const {
    length,
    isNumberInput,
    autoFocus,
    // hideInitialFocus,
    id,
    disabled,
    onChangeOTP,
    resendOTP,
    inputClassName,
    // inputStyle,
    hideSendCodeRightSection,
    // recaptchaRef,
    handleSubmit = noop,
    className,
    isInputSecure,
    hideSendCode,
    testID = null,
    resendCodeClassName,
    resendCodeText,
    renderRecaptcha = true,
    // ...rest
  } = props;

  const { t } = useTranslation(['common']);

  const [disableResend, setDisableResend] = useState(true);

  const [otpValues, setOTPValues] = useState<string | undefined>();

  const handleOTPInputChange = (value: string) => {
    if (!Number.isNaN(Number(value))) {
      setOTPValues(value);
      onChangeOTP(value); // Using 2 setStates to keep original api and avoid changes in other places used
    }
  };

  return (
    (<div id={id} data-palette="OTPInputComponent">
      <OTPContainer className={className}>
        {/*  https://github.com/devfolioco/react-otp-input#api */}
        {/* @ts-ignore */}
        <OtpInput
          value={otpValues}
          onChange={handleOTPInputChange}
          numInputs={length}
          shouldAutoFocus={autoFocus}
          inputStyle={inputClassName}
          isDisabled={disabled}
          isInputNum={isNumberInput}
          isInputSecure={isInputSecure}
          // Empty placeholder is required to detect when input is filled so that styling can be applied.
          placeholder="      "
          data-testid={testID}
        />
        {!hideSendCode && (
          <div
            className={`resend-otp-container ${disableResend && 'disabled'} ${
              hideSendCodeRightSection && 'd-none'
            } ${resendCodeClassName || ''}`}
            data-palette="OTPInputComponent">
            <Timer
              initialTime={60000}
              direction="backward"
              checkpoints={[
                {
                  time: 0,
                  callback: () => setDisableResend(false),
                },
              ]}
            >
              {({ start, reset, getTime }) => (
                <>
                  {/* eslint-disable */}
                  <a
                    href="#"
                    className={`resend-code-link ${disableResend ? 'disabled' : ''}`}
                    onClick={() => {
                      handleSubmit();
                      reset();
                      start();
                    }}
                    data-palette="OTPInputComponent">
                    <div
                      className={cn('timer', {
                        'd-none': !disableResend,
                        'timer-india': IS_INDIAN_EXCHANGE,
                      })}
                    >
                      <div>{Math.round(getTime() / 1000)}s</div>
                      <div
                        className={cn('resend-text', {
                          'resend-text-india': IS_INDIAN_EXCHANGE,
                        })}
                      >
                        {resendCodeText ?? t('common:resend')}
                      </div>
                    </div>
                    <div
                      className={cn('timer send-code-text', {
                        'd-none': disableResend,
                        'timer-india': IS_INDIAN_EXCHANGE,
                      })}
                      onClick={() => setDisableResend(true)}
                    >
                      {resendCodeText || t('common:sendCode')}
                    </div>
                  </a>
                  <Render when={renderRecaptcha}>
                    <ReCAPTCHA
                      ref={ref}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={resendOTP}
                      size="invisible"
                      theme="dark"
                    />
                  </Render>
                  {/* eslint-enable */}
                </>
              )}
            </Timer>
          </div>
        )}
      </OTPContainer>
    </div>)
  );
});

const OTPInput = memo(OTPInputComponent);
export default OTPInput;

// Keeping old code below for reference

// const [activeInput, setActiveInput] = useState(hideInitialFocus ? -1 : 0);
// const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));

// Helper to return OTP from inputs
// const handleOtpChange = useCallback(
//   (otp: string[]) => {
//     const otpValue = otp.join('');
//     onChangeOTP(otpValue);
//   },
//   [onChangeOTP]
// );

// // Helper to return value with the right type: 'text' or 'number'
// const getRightValue = useCallback(
//   (str: string) => {
//     const changedValue = str;
//     if (!isNumberInput) {
//       return changedValue;
//     }
//     return !changedValue || /\d/.test(changedValue) ? changedValue : '';
//   },
//   [isNumberInput]
// );

// // Change OTP value at focussing input
// const changeCodeAtFocus = useCallback(
//   (str: string) => {
//     const updatedOTPValues = [...otpValues];
//     updatedOTPValues[activeInput] = str[0] || '';
//     setOTPValues(updatedOTPValues);
//     handleOtpChange(updatedOTPValues);
//   },
//   [activeInput, handleOtpChange, otpValues]
// );

// // Focus `inputIndex` input
// const focusInput = useCallback(
//   (inputIndex: number) => {
//     const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
//     setActiveInput(selectedIndex);
//   },
//   [length]
// );

// const focusPrevInput = useCallback(() => {
//   focusInput(activeInput - 1);
// }, [activeInput, focusInput]);

// const focusNextInput = useCallback(() => {
//   focusInput(activeInput + 1);
// }, [activeInput, focusInput]);

// // Handle onFocus input
// const handleOnFocus = useCallback(
//   (index: number) => () => {
//     focusInput(index);
//   },
//   [focusInput]
// );

// // Handle onChange value for each input
// const handleOnChange = useCallback(
//   (e: React.ChangeEvent<HTMLInputElement>) => {
//     const val = getRightValue(e.currentTarget.value);
//     if (!val) {
//       e.preventDefault();
//       return;
//     }
//     changeCodeAtFocus(val);
//     focusNextInput();
//   },
//   [changeCodeAtFocus, focusNextInput, getRightValue]
// );

// // Hanlde onBlur input
// const onBlur = useCallback(() => {
//   setActiveInput(-1);
// }, []);

// // Handle onKeyDown input
// const handleOnKeyDown = useCallback(
//   (e: React.KeyboardEvent<HTMLInputElement>) => {
//     switch (e.key) {
//       case 'Backspace':
//       case 'Delete': {
//         e.preventDefault();
//         if (otpValues[activeInput]) {
//           changeCodeAtFocus('');
//         } else {
//           focusPrevInput();
//         }
//         break;
//       }
//       case 'ArrowLeft': {
//         e.preventDefault();
//         focusPrevInput();
//         break;
//       }
//       case 'ArrowRight': {
//         e.preventDefault();
//         focusNextInput();
//         break;
//       }
//       case ' ': {
//         e.preventDefault();
//         break;
//       }
//       default:
//         break;
//     }
//   },
//   [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
// );

// const handleOnPaste = useCallback(
//   (e: React.ClipboardEvent<HTMLInputElement>) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData
//       .getData('text/plain')
//       .trim()
//       .slice(0, length - activeInput)
//       .split('');
//     if (pastedData) {
//       let nextFocusIndex = 0;
//       const updatedOTPValues = [...otpValues];
//       updatedOTPValues.forEach((val, index) => {
//         if (index >= activeInput) {
//           const changedValue = getRightValue(pastedData.shift() || val);
//           if (changedValue) {
//             updatedOTPValues[index] = changedValue;
//             nextFocusIndex = index;
//           }
//         }
//       });
//       setOTPValues(updatedOTPValues);
//       setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
//       if (updatedOTPValues.length === 6) {
//         onChangeOTP(updatedOTPValues.join(''));
//       }
//     }
//   },
//   [activeInput, getRightValue, length, otpValues, onChangeOTP]
// );

//      Array(length)
//       .fill('')
//       .map((_, index) => (
//         <SingleInput
//           key={`SingleInput-${index}-${id}`}
//           focus={activeInput === index}
//           value={otpValues && otpValues[index]}
//           autoFocus={autoFocus}
//           onFocus={handleOnFocus(index)}
//           onChange={handleOnChange}
//           onKeyDown={handleOnKeyDown}
//           onBlur={onBlur}
//           onPaste={handleOnPaste}
//           style={inputStyle}
//           className={inputClassName}
//           disabled={disabled}
//           type="tel"
//         />
//       ))
