/*tslint:disable*/
import React from 'react';
import styles from './index.module.scss';

export const GetBuyOrderEmptyRow: any = ({ buyLength, rowClassName }: any) => {
  const remainingRowsNo = 5 - buyLength;
  const emptyRowArray = new Array(remainingRowsNo > 0 ? remainingRowsNo : 0);
  emptyRowArray.fill('-');
  if (remainingRowsNo >= 1) {
    return emptyRowArray.map((item, i) => (
      <div
        key={i}
        className={`${styles['buy-data']} ${rowClassName}`}
        data-palette="GetBuyOrderEmptyRow">
        <span>{item}</span>
        <span className={styles['buy-price']}>{item}</span>
      </div>
    ));
  } else return null;
};

export const GetSellOrderEmptyRows: any = ({
  sellLength,
  rowClassName,
}: any) => {
  const remainingRowsNo = 5 - sellLength;
  const emptyRowArray = new Array(remainingRowsNo > 0 ? remainingRowsNo : 0);
  emptyRowArray.fill('-');
  if (remainingRowsNo >= 1) {
    return emptyRowArray.map((item, i) => (
      <div
        key={i}
        className={`${styles['buy-data']} ${rowClassName}`}
        data-palette="GetSellOrderEmptyRows">
        <span className={styles['sell-price']}>{item}</span>
        <span>{item}</span>
      </div>
    ));
  } else return null;
};
