/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import Media from 'react-responsive';
import { onlyUpdateForKeys, pure } from 'recompose';

import { NewRedirectIcon } from 'components/customicons/holdings';
import BuySellHoverButton from 'components/options_chain/buySellHoverButton';
import { CONTRACT_TYPE, VANILLA_SETTLING_ASSET } from 'constants/constants';
import routes from 'constants/routes';
import { round_by_tick_size } from 'helpers/formulas';
import { pipe, filter, sortBy, sort, map } from 'helpers/ramda';
import { device } from 'helpers/screenSizes';
import {
  appendPercent,
  appendNothing,
  convertExponentialToDecimal,
  getFormattedVolume,
  afterDecimalLength,
  addZeroesUntilCorrectPrecision,
} from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import {
  premiumSelector,
  indexPriceSelector,
  walletSpotPriceBySymbol,
  spotPriceSelectorBySymbol,
  markPriceSelectorById,
  deltaPriceSelectorById,
} from 'selectors/priceSelectors';
import {
  highSelector,
  lowSelector,
  fundingRateSelector,
  lastPriceSelector,
  lastPriceChangeSelector,
  turnoverStateBySymbolSelector,
  lastPriceChangeIRSSelector,
  openPriceSelector,
} from 'selectors/tickerSelectors';
import { LiquidityMiningTag, LiquidityMiningTagMobile } from 'styles/assets/icons';

import Volume24Hr from './24hr_volume';
import PercentValue from './percent_value';

const AppendPercent = ({ value }) => (
  <PercentValue value={value}>{appendPercent(value)}</PercentValue>
);

const ConditionalRender = onlyUpdateForKeys(['value'])(props => {
  return props.children;
});

const PremiumValue = subscribe(props => {
  const value = premiumSelector(props.product);
  return (
    <ConditionalRender value={value}>
      <AppendPercent value={value} />
    </ConditionalRender>
  );
});

// const FundingValue = subscribe(props => {
//   const value = fundingSelector(props.product);
//   return (
//     <ConditionalRender value={value}>
//       <AppendPercent value={value} />
//     </ConditionalRender>
//   );
// });

const PremimumAndFunding = pure(
  subscribe(props => {
    const { product, styles } = props;
    const value = product.isContractPerpetual
      ? fundingRateSelector(product)
      : premiumSelector(product);
    const { trading_status, isContractPerpetual } = product;
    if (trading_status === 'operational' && isContractPerpetual) {
      const textStyle = value >= 0 ? styles['positive-text'] : styles['negative-text'];
      return <span className={textStyle} data-palette="PremimumAndFunding">{value}%</span>;
    }
    return '';
  })
);

const OpenPriceValue = subscribe(props => {
  const value = openPriceSelector(props.product);
  const absoluteStyling =
    props.product.contract_type === 'call_options' ? { left: '32%' } : { right: '32%' };
  return (
    (<ConditionalRender value={value}>
      {appendNothing(value)}
      {props.showBuySellButton && (
        <div
          className={
            props.product.contract_type === 'call_options'
              ? 'call-buy-sell-button'
              : 'put-buy-sell-button'
          }
          style={absoluteStyling}
          data-palette="OpenPriceValue">
          <BuySellHoverButton isTableHoverButton product={props.product} />
        </div>
      )}
    </ConditionalRender>)
  );
});

const IndexPriceValue = subscribe(props => {
  const value = indexPriceSelector(props.product);
  return <ConditionalRender value={value}>{appendNothing(value)}</ConditionalRender>;
});

const HighPriceValue = subscribe(props => {
  const value = highSelector(props.product);
  return <ConditionalRender value={value}>{appendNothing(value)}</ConditionalRender>;
});

const LowPriceValue = subscribe(props => {
  const value = lowSelector(props.product);
  return <ConditionalRender value={value}>{appendNothing(value)}</ConditionalRender>;
});

const MarkPriceValue = subscribe(props => {
  const price = markPriceSelectorById(props?.product?.id);
  const roundedValue = round_by_tick_size(price, props?.product?.tick_size, 'floor');

  return props?.product.trading_status === 'operational' ? (
    <ConditionalRender value={price}>
      <div>{roundedValue}</div>
    </ConditionalRender>
  ) : (
    ''
  );
});

const DeltaValue = subscribe(props => {
  const value = deltaPriceSelectorById(props.product.id);
  return props?.product.trading_status === 'operational' ? (
    <ConditionalRender value={value}>{Number(value).toFixed(2)}</ConditionalRender>
  ) : (
    ''
  );
});

const LastPriceValue = pure(
  subscribe(props => {
    const lastPrice = lastPriceSelector(props.product);
    const { trading_status, quotingAssetCurrency, tick_size: tickSize } = props.product;
    const precision = afterDecimalLength(tickSize);

    if (trading_status === 'operational') {
      return lastPrice || lastPrice === 0 ? (
        <ConditionalRender value={lastPrice}>
          <span data-tick={tickSize}>
            {`${addZeroesUntilCorrectPrecision(
              convertExponentialToDecimal(lastPrice),
              precision
            )}`}
            {!isUSDConverted(quotingAssetCurrency) ? (
              <GetUSDValue
                symbol={quotingAssetCurrency}
                value={lastPrice}
                className={props.className}
              />
            ) : (
              ''
            )}
          </span>
        </ConditionalRender>
      ) : (
        <span
          className={cx({
            [props?.styles?.['empty-last-price-value']]: [
              props?.styles?.['empty-last-price-value'],
            ],
          })}
          data-palette="LastPriceValue">
          -
        </span>
      );
    }
    return '';
  })
);

const FundUnitPrice = props => {
  const { current_nav, asset } = props.fund;
  return (<>
    <span data-palette="FundUnitPrice"> {current_nav} </span>
    <span
      className="currency"
      style={{ marginLeft: '5px' }}
      data-palette="FundUnitPrice">
      {' '}
      {asset.symbol}{' '}
    </span>
  </>);
};

const FundAssets = props => {
  const { total_units, current_nav, asset } = props.fund;

  return (<>
    <span data-palette="FundAssets"> {getFormattedVolume()(total_units * current_nav)} </span>
    <span
      className="currency"
      style={{ marginLeft: '5px' }}
      data-palette="FundAssets">
      {' '}
      {asset.symbol}{' '}
    </span>
  </>);
};

const FundName = props => {
  const { short_description, symbol, fund_specs = {} } = props.fund;
  const fundName = symbol.split('_').join('-').toLowerCase();
  const url = `/invest/robo-strategies/${fundName}`;
  const [isIconHovered, setIconHover] = useState(false);
  const toggleIconHover = () => {
    setIconHover(!isIconHovered);
  };
  const showTag = !!fund_specs.daily_fund_rewards;

  return (<>
    {/* {!isMobile && <FundIcon icon={symbol.toUpperCase()} className="icon" />} */}
    {!isMobile && (
      <>
        {short_description}
        {showTag && (
          <div className="tags-container" data-palette="FundName">
            <LiquidityMiningTag />
          </div>
        )}
      </>
    )}
    {isMobile && (
      <div className="flex-set align-items-center" data-palette="FundName">
        {short_description}
        {showTag && (
          <span className="ms-1" data-palette="FundName">
            <LiquidityMiningTagMobile />
          </span>
        )}
      </div>
    )}
    <Media query={device.up.md}>
      <a
        data-testid="fund-name"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={toggleIconHover}
        onMouseLeave={toggleIconHover}
        className={`redirect-wrapper ${isIconHovered && 'redirect-hover'} `}
        onClick={e => e.stopPropagation()}
      >
        <NewRedirectIcon
          className={isIconHovered ? null : 'hover'}
          style={{ width: '15px', height: '15px' }}
        />
      </a>
    </Media>
  </>);
};

const FundReturns = props => {
  const { returnsValue } = props;
  const textStyle = returnsValue >= 0 ? 'positive-text' : 'negative-text';
  return (
    (<div value={returnsValue} data-palette="FundReturns">
      <span className={textStyle}>{returnsValue ? `${returnsValue}%` : '-'}</span>
    </div>)
  );
};

const GetUSDValue = pure(
  subscribe(props => {
    const { symbol, value, className } = props;
    const spotSymbol = getSpotSymbol(symbol);
    const spotPrice = walletSpotPriceBySymbol(spotSymbol);
    const usdValue = Number(spotPrice) * Number(value);
    if (Number.isNaN(usdValue)) {
      return null;
    }
    return <p className={className} data-palette="GetUSDValue">{`${usdValue.toFixed(2)} USD`}</p>;
  })
);

const LastPriceChangeValue = pure(
  subscribe(props => {
    const { product, styles } = props;
    const { trading_status, contract_type } = product;
    const lastPrice =
      contract_type === CONTRACT_TYPE.IRS
        ? lastPriceChangeIRSSelector(product)
        : lastPriceChangeSelector(product);
    if (trading_status === 'operational') {
      const textStyle =
        lastPrice >= 0 ? styles[`positive-text`] : styles[`negative-text`];
      const boxStyle = lastPrice >= 0 ? styles[`positive-box`] : styles[`negative-box`];
      return lastPrice ? (
        <ConditionalRender value={lastPrice}>
          <span className={`${props.extraClass} ${boxStyle}`}>
            <span className={textStyle}>{lastPrice}%</span>
          </span>
        </ConditionalRender>
      ) : (
        <div
          className={cx('pe-2', {
            [styles['last-price']]: styles['last-price'],
            [styles?.['empty-last-price-change-value']]:
              styles?.['empty-last-price-change-value'],
          })}
          data-palette="LastPriceChangeValue">
          -
        </div>
      );
    }
    return '';
  })
);

const Get24hVolume = subscribe(prop => {
  switch (prop.contract_type) {
    case 'futures':
      return <Volume24Hr product={prop} />;
    case 'perpetual_futures':
      return <Volume24Hr product={prop} />;
    case 'move_options':
      return turnoverStateBySymbolSelector(prop.symbol);
    case 'interest_rate_swaps':
      return turnoverStateBySymbolSelector(prop.symbol);
    default:
      return '-';
  }
});

const isUSDConverted = symbol =>
  symbol === 'USD' || symbol === 'USDT' || symbol === 'USDC' || symbol === 'IR';

const getSpotSymbol = quotingAssetCurrency => {
  switch (quotingAssetCurrency) {
    case 'BTC':
      return `.DEXBT${VANILLA_SETTLING_ASSET}`;

    case 'ETH':
      return `.DEETH${VANILLA_SETTLING_ASSET}`;

    default:
      break;
  }
};

const getUSDVolume = (num, currency) => {
  switch (currency) {
    case 'BTC':
      return spotPriceSelectorBySymbol('.DEXBTUSD') * num;

    case 'ETH':
      return spotPriceSelectorBySymbol('.DEETHUSDT') * num;

    case 'BNB':
      return spotPriceSelectorBySymbol('.DEBNBUSDT') * num;

    case 'LINK':
      return spotPriceSelectorBySymbol('.DELINKUSDT') * num;

    case 'DAI':
      return 1 * num;

    case 'USDT':
      return num;

    case 'USDC':
      return num;

    case 'USD':
      return num;

    default:
      break;
  }
};

const getTaggedProductsToTop = products => {
  const productsWithTags = products.filter(
    p => p.ui_config.tags && p.ui_config.tags.length
  );
  const productsWithoutTags = products.filter(
    p => !(p.ui_config.tags && p.ui_config.tags.length)
  );
  return [...productsWithTags, ...productsWithoutTags];
};

const getUnderlyingAssetSpotRoute = assetSymbol =>
  assetSymbol === 'USDT'
    ? `${routes.spot.trade}/BTC`
    : `${routes.spot.trade}/${assetSymbol}`;

const getFavoritesToTop = (products, favoritesIds) => {
  if (products.length > 0 && favoritesIds.length > 0) {
    const sortedProducts = [];
    // const moveFavoritesToTop = R.sort(
    //   (elem1, elem2) =>
    //     (favoritesIds.includes(elem2.id) ? elem2.id : 0) -
    //     (favoritesIds.includes(elem1.id) ? elem1.id : 0),
    //   products
    // );

    const favoriteProds = product => favoritesIds.includes(product.id);
    const nonFavoriteProds = product => !favoritesIds.includes(product.id);

    const sortedFavorites = pipe(
      filter(favoriteProds),
      sortBy(product => product.ui_config.sort_priority)
    )(products);

    const sortedNonFavorites = pipe(
      filter(nonFavoriteProds),
      sortBy(product => product.ui_config.sort_priority)
    )(products);

    // favorite products sorted using sort_priority
    // non-favorite products sorted using sort_priority
    sortedProducts.push(...sortedFavorites, ...sortedNonFavorites);
    return sortedProducts;
  }
  return products;
};

const filterProducts = products => {
  const futures = [];
  const options = [];
  const spot = [];
  const move = [];
  const spreads = [];
  const irs = [];
  const optionsCombos = [];

  // eslint-disable-next-line
  products.filter(product => {
    switch (product.contract_type) {
      case 'futures':
      case 'perpetual_futures':
        futures.push(product);
        break;

      case 'move_options':
        move.push(product);
        break;

      case 'call_options':
      case 'put_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
        options.push(product);
        break;

      case 'interest_rate_swaps':
        irs.push(product);
        break;

      case 'spreads':
        spreads.push(product);
        break;

      case 'spot':
        spot.push(product);
        break;

      case 'options_combos':
        optionsCombos.push(product);
        break;

      default:
        break;
    }
  });

  return {
    futures,
    move,
    options,
    irs,
    spreads,
    spot,
    optionsCombos,
  };
};

const getFavoritesFromAllProductsList = (allProductsList, favoritesIds) => {
  if (favoritesIds && favoritesIds.length > 0) {
    if (allProductsList && allProductsList.length > 0) {
      const favoritedProducts = map(fav => {
        const product = allProductsList.find(product => product.id === fav);
        return (
          product && {
            ...product,
            currency: product.underlying_asset.symbol,
            quotingAssetCurrency: product.quoting_asset && product.quoting_asset.symbol,
          }
        );
      }, favoritesIds);

      // sort favorites based on contract_type
      // order required - Futures, Options, Spot, MOVE, Spreads, IRS
      // within each product group sort as per sort_priority

      if (favoritedProducts.length > 0) {
        const filteredFavs = filter(
          favoritedProduct => favoritedProduct !== undefined,
          favoritedProducts
        );
        const sortedFavoritesList = [];

        const sortByPriority = (product1, product2) =>
          product1.ui_config.sort_priority - product2.ui_config.sort_priority;

        const { futures, move, options, irs, spreads, spot, optionsCombos } =
          filterProducts(filteredFavs);

        sortedFavoritesList.push(
          ...sort(sortByPriority, futures),
          ...sort(sortByPriority, options),
          ...sort(sortByPriority, spot),
          ...sort(sortByPriority, move),
          ...sort(sortByPriority, spreads),
          ...sort(sortByPriority, irs),
          ...sort(sortByPriority, optionsCombos)
        );

        return sortedFavoritesList;
      }
      return [];
    }
  }
  return [];
};

// Calculating max-width for a table column based on
// the text content of the biggest cell.
const getColumnWidth = (
  rows,
  accessor,
  headerText,
  headerFontSize,
  cellFontSize,
  maxWidth
) => {
  // Find longest text in all the rows
  const longestText = (() => {
    let longest = '';
    rows.forEach(row => {
      const text = accessor(row);
      if (text?.length > longest?.length) {
        longest = text;
      }
    });
    return longest;
  })();

  // width required for the longest text cell
  const cellWidth = measureTextWidth(longestText, cellFontSize);
  // width required for the column header
  const headerWidth = measureTextWidth(headerText, headerFontSize);
  // column width
  const columnWidth = Math.max(cellWidth, headerWidth);

  return Math.min(maxWidth, columnWidth);
};

// This function measures width required for a particular text.
function measureTextWidth(pText, pFontSize, pStyle) {
  let lDiv = document.createElement('div');

  document.body.appendChild(lDiv);

  if (pStyle != null) {
    lDiv.style = pStyle;
  }
  lDiv.style.fontSize = `${pFontSize}px`;
  lDiv.style.position = 'absolute';
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;

  lDiv.textContent = pText;

  const lResult = {
    width: lDiv.clientWidth,
    height: lDiv.clientHeight,
  };

  document.body.removeChild(lDiv);
  lDiv = null;
  return lResult.width;
}

export {
  AppendPercent,
  ConditionalRender,
  PremiumValue,
  PremimumAndFunding,
  OpenPriceValue,
  IndexPriceValue,
  HighPriceValue,
  LowPriceValue,
  MarkPriceValue,
  DeltaValue,
  LastPriceValue,
  FundUnitPrice,
  FundAssets,
  FundName,
  FundReturns,
  GetUSDValue,
  LastPriceChangeValue,
  Get24hVolume,
  isUSDConverted,
  getSpotSymbol,
  getUSDVolume,
  getTaggedProductsToTop,
  getUnderlyingAssetSpotRoute,
  getFavoritesToTop,
  getFavoritesFromAllProductsList,
  filterProducts,
  getColumnWidth,
  measureTextWidth,
};
