import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { PopoverStyle } from 'components/deposit/deposit_styles';
import { noop } from 'helpers';
import { useAppSelector, useCopyToClipBoard, useWindowSize } from 'hooks';
import { allOpenPositionsSelector } from 'selectors';
import { CopyToClipboardIcon } from 'styles/assets/icons';
import { TwoFactorAuthQrData } from 'types/Auth';
import { Box, DeltaReactModal, DisclaimerContainer } from 'UIKit';
import DeltaButton from 'UIKit/DeltaButton/DeltaButton';

import styles from './reset2fa.module.scss';

type Props = {
  isOpen: boolean;
  data: TwoFactorAuthQrData;
  confirmClick: () => void;
  closeModal: () => void;
};

const BackupKey = ({ isOpen, data, closeModal, confirmClick }: Props) => {
  const { t } = useTranslation(['auth', 'account']);
  const { isScreenMd } = useWindowSize();
  const { isCopied, handleCopy } = useCopyToClipBoard(data.google_secret);

  const openPositions = useAppSelector(allOpenPositionsSelector);
  const isPositionsEmpty = openPositions.length === 0;

  return (
    (<DeltaReactModal
      title={t('auth:reset2faPopups.step3.title')}
      titleRightContent={
        <span className={styles.stepCount} data-palette="BackupKey">
          <span className={styles.left}>2 /</span>
          <span className={styles.right}> 3</span>
        </span>
      }
      isOpen={isOpen}
      showHeader
      showCancel={false}
      // eslint-disable-next-line no-unneeded-ternary
      showCrossIcon={isPositionsEmpty ? false : true}
      confirmButtonText={t('auth:reset2faPopups.next')}
      onClose={isPositionsEmpty ? noop : closeModal}
      onConfirm={confirmClick}
      titleClassName={styles.title}
      headerClassName={styles.header}
      bodyClassName={cn(styles.body, styles.step3)}
      footerClassName={styles.footer}
      // cancelClassName={styles.cancelButton}
      confirmClassName={styles.confirmButton}
      fullScreen={isScreenMd}
    >
      <p className={styles.description}>{t('auth:reset2faPopups.step3.description1')}</p>
      <Box
        className={styles.googleCode}
        flex="flex"
        justifyContent="start"
        alignItems="center"
      >
        {data.google_secret}
        <DeltaButton
          data-testid="backup-key-copy-button"
          className={styles.copyButton}
          onClick={() => handleCopy()}
        >
          <CopyToClipboardIcon className={styles.iconCopy} />
          {isCopied && (
            <PopoverStyle className={styles.copied}>
              <p>{t('account:copied')}</p>
            </PopoverStyle>
          )}
        </DeltaButton>
      </Box>
      <DisclaimerContainer
        text={t('auth:reset2faPopups.step3.description2')}
        showInfoIcon
      />
    </DeltaReactModal>)
  );
};

export default BackupKey;
