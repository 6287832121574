import { useEffect } from 'react';

import { IS_RN_WEBVIEW } from 'constants/constants';

import { useBiometrics } from './componentHooks/useBiometrics/useBiometrics.Provider';
import { useOAuthLogin } from './useOAuthLogin';

const RN_MESSAGE_EVENT_TYPE = 'RN_MESSAGE_EVENTS';

const EVENT_TYPES = {
  BIOMETRICS_MESSAGES: 'BIOMETRICS_MESSAGES',
  KEYBOARD_HEIGHT: 'KEYBOARD_HEIGHT',
  MOBILE_DEVICE_ID: 'MOBILE_DEVICE_ID',
  OAUTH_SIGNIN_RESPONSE: 'OAUTH_SIGNIN_RESPONSE',
} as const;

const useRNMessageEvents = () => {
  const { onMessageFromApp: onBiometricsMessageFromApp } = useBiometrics();
  const { setOAuthLoginState } = useOAuthLogin();

  useEffect(() => {
    const onMessageHandler = (event: MessageEvent) => {
      if (!IS_RN_WEBVIEW || event?.data?.type !== RN_MESSAGE_EVENT_TYPE) return;

      const { name, data } = event?.data?.payload ?? {};

      switch (name) {
        case EVENT_TYPES.BIOMETRICS_MESSAGES: {
          onBiometricsMessageFromApp(data);
          break;
        }

        case EVENT_TYPES.OAUTH_SIGNIN_RESPONSE: {
          setOAuthLoginState(data);
          break;
        }

        case EVENT_TYPES.KEYBOARD_HEIGHT: {
          break;
        }

        case EVENT_TYPES.MOBILE_DEVICE_ID: {
          localStorage.setItem('mobileDeviceId', data.deviceId);
          break;
        }

        default: {
          break;
        }
      }
    };

    window.addEventListener('message', onMessageHandler);

    return () => {
      window.removeEventListener('message', onMessageHandler);
    };
  }, [onBiometricsMessageFromApp, setOAuthLoginState]);
};

export default useRNMessageEvents;
export { RN_MESSAGE_EVENT_TYPE };
