import React, { ComponentProps } from 'react';

import cn from 'classnames';

import Render from 'components/render';
import { useWindowSize } from 'hooks';
import { CrossIcon } from 'styles/assets/icons';
import Box from 'UIKit/Box';
import IconButton from 'UIKit/IconButton';
import MaterialConfirmDialog from 'UIKit/MaterialConfirmDialog';

import styles from './deltaAdaptivePanel.module.scss';

type DeltaMagicPanelProps = ComponentProps<typeof MaterialConfirmDialog> & {
  panelRootClassName?: string;
};

/**
 * DeltaAdaptivePanel is a React component that represents a panel that adapts to modal if viewed on mobile.
 *
 * @component
 * @example
 * // Example usage of DeltaAdaptivePanel
 * import React from 'react';
 * import DeltaAdaptivePanel from './DeltaAdaptivePanel';
 *
 * const ExampleComponent = () => {
 *   return (
 *     <div>
 *       <h1>Example Page</h1>
 *       <DeltaAdaptivePanel
 *         open={true}
 *         showHeader={true}
 *         title="Panel Title"
 *         onClose={() => {
 *           // Handle close event
 *         }}
 *       >
 *         <p>This is the content of the panel.</p>
 *       </DeltaAdaptivePanel>
 *     </div>
 *   );
 * };
 *
 * export default ExampleComponent;
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Indicates whether the panel is open or not.
 * @param {boolean} [props.showHeader] - Specifies whether to show the panel header.
 * @param {string} [props.title] - The title of the panel.
 * @param {function} [props.onClose] - The callback function to handle the panel close event.
 * @param {string} [props.panelRootClassName] - Additional CSS class name for the panel root element.
 * @returns {JSX.Element|null} The rendered DeltaAdaptivePanel component.
 */
const DeltaAdaptivePanel = ({ children, ...props }: DeltaMagicPanelProps) => {
  const { isMobile } = useWindowSize();

  if (!props.open) return null;

  if (isMobile) {
    return (
      <MaterialConfirmDialog
        variant="v2"
        fullWidth={isMobile}
        transition={isMobile}
        marginTopInherit
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </MaterialConfirmDialog>
    );
  }

  return (
    <Box
      data-testid="adaptive-panel-root"
      className={cn(styles.panelRoot, {
        [props.panelRootClassName as string]: Boolean(props.panelRootClassName),
      })}
    >
      <Render when={props.showHeader}>
        <Box
          data-testid="panel-header"
          className={cn(styles.header, {
            [props.headerClassName as string]: props.headerClassName,
          })}
        >
          {props.accessoryTitleLeft}

          <Box
            data-testid="panel-title"
            as="h2"
            textColor="primary"
            fontSize="md1"
            className={cn(styles.title, {
              [props.titleClassName as string]: props.titleClassName,
            })}
          >
            {props.title}
            {props.titleRightContent}
          </Box>

          {props.accessoryTitleRight || (
            <IconButton
              data-testid="panel-close"
              className={styles.closeIcon}
              onKeyDown={() => {}}
              onClick={props.onClose}
              // @ts-ignore
              icon={<CrossIcon />}
              testID="modal-close"
            />
          )}
        </Box>
      </Render>

      <Box
        data-testid="panel-body"
        className={cn(styles.body, {
          [props.bodyClassName as string]: props.bodyClassName,
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DeltaAdaptivePanel;
