import { THETA_TICK_SIZE, VEGA_TICK_SIZE } from 'constants/constants';
import { cropAfterDecimals, isNan } from 'helpers';
import { multiply } from 'helpers/ramda';
import { calcPrecision } from 'reducers/helpers/trade';
import { OpenPosition } from 'types/IOpenPosition';
import { ContractType, NotionalType } from 'types/IProducts';

import greeksPrecision from './greeksPrecision';

/**
 * Delta = notional size in USDT  * contract_delta * ( -1 (for short) / 1 (for long)
 */
const getDeltaValue = ({
  deltaContractValue,
  notionalInUSDT,
  longOrShort,
  position,
  indexPrice,
}: {
  deltaContractValue: number;
  notionalInUSDT: number;
  longOrShort: 'long' | 'short';
  position: OpenPosition;
  indexPrice: number;
}) => {
  if (isNan(deltaContractValue)) {
    return 0.0;
  }
  const product = position?.product;
  const numberOfContracts = position?.size;
  const contractSize = Number(product?.contract_value); // contract_value
  // [WEB-3515]:  for inverse perpetual contracts, the formula for delta need to be:
  // number_of_contracts * contract_size/ index_price
  if (
    product?.contract_type === ContractType.PerpetualFutures &&
    product?.notional_type === NotionalType.Inverse
  ) {
    return (
      (Number(numberOfContracts) * Math.abs(contractSize || 0) * 1.0) / Number(indexPrice)
    );
  }
  // [WEB-3515]: for futures return notional value
  if (
    product?.contract_type === ContractType.Futures ||
    product?.contract_type === ContractType.PerpetualFutures
  ) {
    return Number(numberOfContracts) * Math.abs(contractSize || 0) * 1.0;
  }
  const lsValue = longOrShort === 'long' ? 1 : -1;
  return multiply(multiply(deltaContractValue, notionalInUSDT), lsValue);
};
/**
 * Use only for Delta and Gamma
 * @param greekContractValue option_delta/option_gamma
 * @returns Number number of contracts in position with sign * contract_size * option_delta
 */
const getGreekValue = ({
  greekContractValue,
  notional,
  longOrShort,
}: {
  greekContractValue: number;
  notional: number;
  longOrShort: 'long' | 'short';
}) => {
  if (isNan(greekContractValue)) {
    return 0.0;
  }
  const lsValue = longOrShort === 'long' ? 1 : -1;
  return multiply(multiply(greekContractValue, notional), lsValue);
};

/**
 * Gamma = (contract_gamma * notional size in USDT * 1%) * (spotPriceUnderlying * ( -1 (for short) / 1 (for long))
 */
const getGammaValue = ({
  gammaContractValue,
  symbol,
  notionalInUSDT,
  longOrShort,
  spotPriceInUnderlyingAsset,
}: {
  gammaContractValue: number;
  symbol?: OpenPosition['product']['underlying_asset']['symbol'];
  notionalInUSDT: number;
  spotPriceInUnderlyingAsset: number;
  longOrShort: 'long' | 'short';
}) => {
  if (isNan(gammaContractValue)) {
    return 0.0;
  }

  const tickPrecision = calcPrecision(0.00001);
  const lsValue = longOrShort === 'long' ? 1 : -1;
  const notionalInUSDTrimmed = cropAfterDecimals(notionalInUSDT, 2);
  const spotPriceInUnderlyingTrimmed = cropAfterDecimals(spotPriceInUnderlyingAsset, 2);
  const gammaTrimmed = cropAfterDecimals(
    gammaContractValue,
    greeksPrecision[symbol]?.gamma ?? 2
  );
  const value =
    gammaTrimmed * notionalInUSDTrimmed * 0.01 * (spotPriceInUnderlyingTrimmed * lsValue);

  return cropAfterDecimals(value, tickPrecision);
};

/**
 * Theta = contract_theta * notional size in underlying * ( -1 (for short) / 1 (for long))
 */
const getThetaValue = ({
  thetaContractValue,
  symbol,
  notional,
  longOrShort,
}: {
  thetaContractValue: number;
  symbol?: OpenPosition['product']['underlying_asset']['symbol'];
  notional: number;
  longOrShort: 'long' | 'short';
}) => {
  if (isNan(thetaContractValue)) {
    return 0.0;
  }
  const tickPrecision = calcPrecision(THETA_TICK_SIZE[symbol] || 0.01);
  const lsValue = longOrShort === 'long' ? 1 : -1;
  const thetaTrimmed = cropAfterDecimals(thetaContractValue, tickPrecision);
  const value = multiply(multiply(thetaTrimmed, notional), lsValue);

  return cropAfterDecimals(value, tickPrecision);
};

/**
 * Vega = contract_vega * notional size in underlying * ( -1 (for short) / 1 (for long))
 */
const getVegaValue = ({
  vegaContractValue,
  symbol,
  notional,
  longOrShort,
}: {
  vegaContractValue: number;
  symbol?: OpenPosition['product']['underlying_asset']['symbol'];
  notional: number;
  longOrShort: 'long' | 'short';
}) => {
  if (isNan(vegaContractValue)) {
    return 0.0;
  }
  const tickPrecision = calcPrecision(VEGA_TICK_SIZE?.[symbol] || 0.00001);
  const lsValue = longOrShort === 'long' ? 1 : -1;
  const vegaTrimmed = cropAfterDecimals(vegaContractValue, tickPrecision);

  const value = multiply(multiply(vegaTrimmed, notional), lsValue);

  return cropAfterDecimals(value, tickPrecision);
};

export { getDeltaValue, getGammaValue, getThetaValue, getVegaValue, getGreekValue };
