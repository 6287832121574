import { useEffect, useState } from 'react';

import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import { appDrawerSelector } from 'selectors';
import { useAppSelector } from 'storeHooks';

import useCustomMediaQuery from './useMediaQuery';

/**
 * Custom hook to determine if the active tab is the Trade tab on a mobile device
 * @returns {boolean} - Returns a boolean value indicating whether the active tab is the Trade tab on a mobile device
 */
const useTradeTabMobile = () => {
  const { activeTab } = useAppSelector(appDrawerSelector);
  const [isTradeTabMobile, setIsTradeTabMobile] = useState<boolean>(false);
  const { whenBelowMd } = useCustomMediaQuery();

  useEffect(() => {
    const checkTradeTabMobile = () => {
      const isMobile = activeTab === APP_DRAWER_TAB_ID_MAPPING.TRADE && whenBelowMd;
      setIsTradeTabMobile(isMobile);
    };

    checkTradeTabMobile();
  }, [activeTab, whenBelowMd]);

  return isTradeTabMobile;
};

export default useTradeTabMobile;
