/* eslint-disable react/prop-types */
import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import NavTooltip from 'components/nav_tooltip';
import { SideNavIcons } from 'components/side_nav/side_nav_icons';
import { ArrowRight } from 'styles/assets/icons';
import Box from 'UIKit/Box';

import styles from './index.module.scss';

interface DrawerItem {
  id: string;
  text: string;
  active: boolean;
  url: string;
  disabled: boolean;
  key: string;
  mixpanelLabel: string;
  isShown: boolean;
}

interface DeltaDrawerProps {
  drawerOpen: boolean;
  toggleDrawer: (value: boolean) => void;
  data: DrawerItem[];
  onItemClick: (item: DrawerItem) => void;
}

const DeltaDrawer = (props: DeltaDrawerProps) => {
  const { data, toggleDrawer, drawerOpen: open, onItemClick } = props;

  const renderItem = (item: DrawerItem, index: number) => (
    <ListItem
      button
      key={index}
      component={Link}
      to={item.url}
      selected={item.active}
      className={cx({ [styles.navLink]: open && item.active })}
      data-testid={item.id}
      id={item.key}
      onClick={() => onItemClick(item)}
    >
      <ListItemIcon
        className={cx(styles.iconWrapper, { [styles.iconWrapperOpen]: open })}
      >
        <SideNavIcons id={item.id} />
      </ListItemIcon>
      <ListItemText
        className={cx(styles.textWrapper, {
          [styles.textWrapperOpen]: open,
          [styles.navTextClosed]: !open,
          [styles.navTextAnimateToOpen]: open,
        })}
        primary={item.text}
      />
    </ListItem>
  );

  const getList = () => (
    <div
      className={`${open ? styles.open : styles.close} ${styles['list-container']}`}
      style={{ width: open ? 220 : 62, transition: 'width 0.3s ease-in-out' }}
      data-palette="getList">
      {data
        .filter(item => !item.disabled)
        .map((item, index) => {
          if (!open)
            return (
              <NavTooltip title={item.text} arrow placement="right" classes={{}}>
                {renderItem(item, index)}
              </NavTooltip>
            );
          return renderItem(item, index);
        })}
    </div>
  );

  return (
    (<div className={styles['delta-drawer-container']} data-palette="DeltaDrawer">
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        onClose={() => toggleDrawer(false)}
      >
        <Box
          as="div"
          data-testid="accountPage-sideDrawer-toggler"
          style={{ transition: 'left 0.3s ease-in-out' }}
          className={`${styles['icon-container']} ${
            open ? styles['drawer-open'] : styles['drawer-close']
          } `}
          onClick={() => toggleDrawer(!open)}
        >
          <ArrowRight />
        </Box>
        {getList()}
      </Drawer>
    </div>)
  );
};

export default DeltaDrawer;
