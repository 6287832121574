import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Popover from 'containers/Popover';
import { device } from 'helpers/screenSizes';
import { EditIcon } from 'styles/assets/icons';

const EditableStyles = styled.div`
  // border-bottom: 0.8px solid ${props => props.theme.tertiaryText};
  padding: 1px;
  // line-height: 1.74;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    margin-right: 7px;
  }

  svg {
    margin-left: 5px;
    @media ${device.down.xs} {
      margin-left: 0;
      overflow: visible !important;
    }
  }

  &:hover {
    background-color: ${props => props.theme.secondaryBackground};
    color: ${props => props.theme.primaryText};
  }
`;

const Editable = props => {
  const { t } = useTranslation(['trading']);
  const {
    children,
    tooltipText,
    shouldDisplayTooltip,
    tooltipPlacement,
  } = props;

  if (shouldDisplayTooltip) {
    return (
      <Popover
        isInteractive={false}
        leaveDelay={500}
        shouldClosePrevPopover={true}
        body={tooltipText || t('trading:clickToEdit')}
        placement={tooltipPlacement}>
        <EditableStyles>
          {children}
          <MediaQuery query={device.down.md}>
            <EditIcon />
          </MediaQuery>
        </EditableStyles>
      </Popover>
    );
  } else {
    return (
      <EditableStyles title={tooltipText || t('trading:clickToEdit')}>
        {children}
      </EditableStyles>
    );
  }
};

Editable.propTypes = {
  children: PropTypes.node.isRequired,
  shouldDisplayTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
};

Editable.defaultProps = {
  // tooltipText: 'Click to Edit',
  shouldDisplayTooltip: false,
  tooltipPlacement: 'right',
};

export default Editable;
