import React from 'react';
import styled from 'styled-components';
import { isNegative } from 'helpers/utils';
import { compose, onlyUpdateForKeys } from 'recompose';

const StyledPercentValue = styled.span`
  color: ${({ value, theme }) => {
    const v = parseFloat(value);
    return !v
      ? theme.primaryText
      : isNegative(v)
      ? theme.negative
      : theme.positive;
  }};
`;

const enhance = compose(onlyUpdateForKeys(['value']));

const PercentValue = enhance(props => {
  return (
    <StyledPercentValue value={props.value}>
      {props.children}
    </StyledPercentValue>
  );
});

export default PercentValue;
