import { useEffect, useMemo } from 'react';

import { subscribeOpenPostionMark, unsubscribeMark } from 'actions/socket';
import { round } from 'helpers/formulas';
import { devError } from 'helpers/logger';
import { calcPrecision } from 'helpers/utils';
import {
  allOpenPositionsSelector,
  getProductByID,
  openPositionMarkPriceSelectorById,
  selectedProductMarkDataSelector,
  selectedProductSelector,
} from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';

const useMarkPrice = (productId: number): string | '-' => {
  if (!productId) {
    devError(false, 'useMarkPrice', `Parameter 'productId' is missing`);
  }

  const appDispatch = useAppDispatch();
  const selectedProduct = useAppSelector(selectedProductSelector);
  const allOpenPositions = useAppSelector(allOpenPositionsSelector);

  const { symbol: productSymbol, tick_size: tickSize } = useAppSelector(state =>
    getProductByID(state, {
      filterProductId: productId,
    })
  );

  const precision = useMemo(() => calcPrecision(tickSize), [tickSize]);

  let markPrice: number | null =
    productId === Number(selectedProduct?.id)
      ? Number(selectedProductMarkDataSelector()?.price)
      : null;

  if (!markPrice) {
    const openPositionPriceData = openPositionMarkPriceSelectorById(
      String(productId)
    ) as {
      mark_price: number;
    };

    markPrice = Number(openPositionPriceData?.mark_price ?? '');
  }

  useEffect(() => {
    if (markPrice) return undefined;

    appDispatch(subscribeOpenPostionMark(productSymbol));

    return () => {
      const isPresentInOpenPosition = allOpenPositions.find(
        position => Number(position.product.id) === productId
      );

      if (!isPresentInOpenPosition) {
        appDispatch(unsubscribeMark(productSymbol));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return markPrice ? String(round(markPrice, precision)) : '-';
};

export default useMarkPrice;
