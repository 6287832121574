import styled, { css } from 'styled-components';

const rangeThumb = css`
  width: 12px;
  height: 12px;
  margin: -3px 0px 0px 0px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 ${props => props.theme.boxShadow};
  // border: 0.8px solid ${props => props.theme.primaryText};
  background-color: ${props => props.theme.primaryTheme};
`;

const rangeTrack = css`
  width: 100%;
  height: 6px;
  background-color: var(--separator2);
  border-radius: 5px;
  cursor: pointer;
`;

const RangeSilderStyle = styled.div`
  .range {
    position: relative;
    height: 5px;
  }

  .range input {
    width: 100%;
    position: absolute;
    top: 2px;
    height: 0;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      ${rangeThumb};
    }

    &::-moz-range-thumb {
      ${rangeThumb};
    }

    &::-ms-thumb {
      ${rangeThumb};
    }

    // Track
    &::-webkit-slider-runnable-track {
      ${rangeTrack};
    }

    &::-moz-range-track {
      ${rangeTrack};
    }

    &::-ms-track {
      ${rangeTrack};
    }

    &:focus {
      // override outline/background on focus
      background: none;
      outline: none;
    }

    &::-ms-track {
      // A little somethin' somethin' for IE
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
  }

  // Labels below slider
  .range-labels {
    margin: 4px 0 0;
    padding: 0;
    list-style: none;

    .active {
      color: ${props => props.theme.primaryTheme};
    }

    .active::before {
      opacity: 0 !important;
    }

    .selected::before {
      background: ${props => props.theme.primaryTheme};
    }

    .active.selected::before {
      display: none;
    }
  }
`;

export const SliderLabel = styled.li`
  @-moz-document url-prefix() {
    width: calc(
      ${props => props.totalLables}% - (19px / ${props => props.totalItems - 1})
    );
  }
  width: calc(
    ${props => props.totalLables}% - (18px / ${props => props.totalItems - 1})
  );
  position: relative;
  float: left;
  text-align: left;
  color: ${props => props.theme.secondaryText};
  opacity: 0.8;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.6px;
  cursor: pointer;

  &:first-child {
    &::before {
      left: 4px;
    }
  }

  &:last-child {
    width: 18px;
    text-align: right;
    &::before {
      right: 4px;
      left: auto;
    }
  }

  &::before {
    position: absolute;
    top: -10px;
    left: 6px;
    content: '';
    margin: 0 auto;
    margin-left: 0;
    width: 6px;
    height: 6px;
    background: ${props => props.theme.secondaryText};
    border-radius: 50%;
    border: 1px solid ${props => props.theme.secondaryText};
  }

  .precent-symbol {
    font-size: 10px;
  }
`;

export default RangeSilderStyle;
