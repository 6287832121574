import { ReactNode } from 'react';

import { AllUnionMemberKeys } from 'types/utils';

const CODE_TYPE = {
  EMAIL: 'emailCode',
  MFA: 'mfaCode',
} as const;

const OTP_VERIFICATION_VARIANT = {
  PANEL: 'panel',
  MODAL: 'modal',
} as const;

interface ModalWrapper {
  children: ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  canSubmit?: boolean;
  fullHeight?: boolean;

  onConfirm: () => void;
  onClose: () => void;
  showError?: boolean;
  error?: string;
}

type CodeType = AllUnionMemberKeys<typeof CODE_TYPE>;

interface OTPVerification {
  isLoading?: boolean;
  canSubmit?: boolean;
  errors?: null | Partial<Record<'emailCode' | 'mfaCode' | 'custom', string>>;
  emailCodeConfig?: {
    recommendation?: string;
  };
  showMfa?: boolean;
  mfaCodeConfig?: {
    recommendation?: string;
  };
  canResendEmailCode?: boolean;
  onCodeChange: (code: string, type: CodeType) => void;
  onResendOtp: () => void;

  onConfirm?: () => void;
}

type OTPVerificationVariant = AllUnionMemberKeys<typeof OTP_VERIFICATION_VARIANT>;

interface Codes {
  emailCode: string;
  mfaCode?: string;
  custom?: string;
}

type CodeErrors = undefined | null | Partial<Codes>;

interface DeltaOTPVerificationBase {
  variant?: OTPVerificationVariant;
  showMfa?: boolean;
  isLoading?: boolean;
  fullHeight?: boolean;
  canResendEmailCode?: boolean;
  errors: CodeErrors;
  emailCodeConfig?: {
    recommendation?: string;
  };
  mfaCodeConfig?: {
    recommendation?: string;
  };

  onResendOtp: () => void;
  onConfirm: (codes: Codes) => void;
}

interface DeltaOTPVerificationPanel extends DeltaOTPVerificationBase {
  variant: 'panel';
  isOpen?: never;
  onClose?: never;
}

interface DeltaOTPVerificationModal extends DeltaOTPVerificationBase {
  variant: 'modal';
  isOpen?: boolean;
  onClose?: () => void;
}

type DeltaOTPVerification = DeltaOTPVerificationPanel | DeltaOTPVerificationModal;

export type {
  ModalWrapper,
  OTPVerification,
  DeltaOTPVerification,
  CodeType,
  OTPVerificationVariant,
  Codes,
  CodeErrors,
};

export { CODE_TYPE, OTP_VERIFICATION_VARIANT };
