import  { ReactNode } from 'react';
import memoize from 'fast-memoize';
import cx from 'classnames';
// import { shallowEqual } from 'react-redux';
// import { useParams } from 'react-router-dom';

import { SCREEN_SIZES } from 'constants/enums';
import { TIME_FORMATS } from 'constants/timeformats';
import { isEmpty, windowWidth, isNan } from 'helpers';
import { dateFormat, toUTC } from 'helpers/day';
import subscribe from 'HOC/subscribe';
// import { useAppSelector } from 'hooks';
import {
  // productsSelector,
  oiSelectorById,
  // oiCallSelector,
  // oiPutSelector,
} from 'selectors';
import { maxCallOiSelector, maxPutOiSelector } from 'selectors/oiIndicatorSelector';
import { useAppSelector } from 'storeHooks';
import store from 'variableStore/store';

import { Box } from 'UIKit';

import { optionTypeProp } from '../helpers';
import styles from './oiIndicator.module.scss';

interface OiIndicatorProps {
  children: ReactNode;
  optionType: 'callOptions' | 'putOptions';
  expiry: Date;
  productId: number;
  topAdjust?: number;
}

const formatDate = memoize((date: Date) => {
  // console.time("debug, formatDate");
  const res = dateFormat(toUTC(date), TIME_FORMATS.DD_MMM_YY);
  // console.timeEnd("debug, formatDate");
  // console.log('debug, fmt date', date, res);
  return res;
});

// const parseObject = <T extends unknown>(obj: T) => JSON.parse(JSON.stringify(obj));

const OiIndicator = ({
  productId,
  optionType,
  expiry,
  children,
  topAdjust,
}: OiIndicatorProps) => {
  const expiryDate = formatDate(expiry);
  const maxCall = useAppSelector(maxCallOiSelector);
  const maxPut = useAppSelector(maxPutOiSelector);
  // const oiValue = oiSelectorById(String(productId));
  const oiValue = store.tickerData.oi[productId];

  const maxValue = optionType === optionTypeProp.call ? Number(maxCall) : Number(maxPut);

  const maxWidth = Number(windowWidth) < SCREEN_SIZES.MD ? 100 : 95;

  // Allow upto 95% of width for desktop
  const barWidth = maxWidth * (Number(oiValue) / maxValue);

  return (
    <Box className={styles.oiCell}>
      {children}
      <Box
        data-expiry={expiryDate}
        data-pid={productId}
        data-oi={oiValue || 'NA'}
        data-max-oi={isNan(maxValue) ? '' : maxValue}
        style={{
          width: `${isEmpty(oiValue) ? 0 : barWidth}%`,
          ...(topAdjust ? { top: topAdjust } : {}),
        }}
        className={cx(styles.indicator, {
          [styles.call]: optionType === optionTypeProp.call,
          [styles.put]: optionType === optionTypeProp.put,
        })}
      />
    </Box>
  );
};

export default subscribe(OiIndicator);
