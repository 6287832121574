import React, { useState } from 'react';

import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { resetMFA } from 'actions/user';
import OTPInput from 'components/OTPVerification';
import Render from 'components/render';
import { OTP_LENGTH, RECAPTCHA_SITE_KEY } from 'constants/constants';
import routes from 'constants/routes';
import { hideEmailAddress, noop, parseErrorMsg } from 'helpers';
import {
  useAppDispatch,
  useAppSelector,
  useEmailOtpVerification,
  useWindowSize,
} from 'hooks';
import { allOpenPositionsSelector, userSelector } from 'selectors';
import { TwoFactorAuthQrData } from 'types/Auth';
import { Box, DeltaReactModal } from 'UIKit';

import styles from './reset2fa.module.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  data: TwoFactorAuthQrData;
  // confirmClick: () => void;
};

const OTPVerification = ({ isOpen, closeModal, data }: Props) => {
  const { email } = useAppSelector(userSelector);
  const history = useHistory();

  const { t } = useTranslation(['auth', 'account', 'common', 'errors']);

  const appDispatch = useAppDispatch();

  const { recaptchaRef, resendOTP, sendCode, emailOTP, onChangeEmailOTP } =
    useEmailOtpVerification();

  const [old2FaCode, setOld2FaCode] = useState<string | undefined>();
  const [new2faCode, setNew2faCode] = useState<string | undefined>();
  const [emailOtpError, setEmailOtpError] = useState('');
  const [oldMfaError, setoldMfaError] = useState('');
  const [newMfaError, setNewMfaError] = useState('');
  const { isScreenMd } = useWindowSize();

  const hiddenEmail = hideEmailAddress(email);

  const openPositions = useAppSelector(allOpenPositionsSelector);
  const isPositionsEmpty = openPositions.length === 0;

  const handleOld2faChange = (mfaCode: string) => {
    setOld2FaCode(mfaCode);
  };

  const handleNew2faChange = (mfaCode: string) => {
    setNew2faCode(mfaCode);
  };

  const handleConfirmClick = () => {
    const payload = {
      mfa_code: old2FaCode,
      new_mfa_code: new2faCode,
      email_code: emailOTP,
      google_secret: data.google_secret,
    };
    appDispatch(resetMFA(payload))
      .then(() => {
        history.push(routes.login);
        // closeModal();
      })
      .catch(err => {
        const errorObj = parseErrorMsg(err);
        const { code, context } = errorObj.error;
        setNewMfaError('');
        setoldMfaError('');
        setEmailOtpError('');

        switch (code) {
          case 'invalid_mfa_code': {
            // old 2fa code
            if (context) {
              const { count = 0, max_attempts: maxAttempts = 5 } = context || {};
              const attemptsRemaining = maxAttempts - count;
              setoldMfaError(
                t('errors:custom.invalid_mfa_code', {
                  count: attemptsRemaining,
                })
              );
            } else {
              setoldMfaError(t('errors:custom.invalid_old_mfa_code'));
            }
            break;
          }

          case 'invalid_new_mfa_code':
            setNewMfaError(t('errors:custom.invalid_new_mfa_code'));
            break;
          case 'invalid_email_code': {
            // email otp
            const { count: emailCount = 0, max_attempts: emailMaxAttempts = 5 } =
              context || {};
            const emailAttemptsRemaining = emailMaxAttempts - emailCount;

            if (emailAttemptsRemaining > 1) {
              setEmailOtpError(
                t('errors:custom.invalid_email_otp_plural', {
                  count: emailAttemptsRemaining,
                })
              );
            } else {
              setEmailOtpError(
                t('errors:custom.invalid_email_code', {
                  count: emailAttemptsRemaining,
                })
              );
            }
            break;
          }
          case 'mfa_check_blocked':
            setNewMfaError(t('auth:processBlocked'));
            break;
          default:
            break;
        }
      });
  };

  return (<>
    <DeltaReactModal
      title={t('auth:reset2faPopups.step4.title')}
      titleRightContent={
        <span className={styles.stepCount} data-palette="OTPVerification">
          <span className={styles.left}>3 /</span>
          <span className={styles.right}> 3</span>
        </span>
      }
      confirmButtonText={t('common:submit')}
      isOpen={isOpen}
      onClose={isPositionsEmpty ? noop : closeModal}
      // onCancel={closeModal}
      onConfirm={handleConfirmClick}
      titleClassName={styles.title}
      headerClassName={styles.header}
      bodyClassName={cn(styles.body, styles.step3)}
      footerClassName={styles.footer}
      cancelClassName={styles.cancelButton}
      confirmClassName={styles.confirmButton}
      showCancel={false}
      // eslint-disable-next-line no-unneeded-ternary
      showCrossIcon={isPositionsEmpty ? false : true}
      fullScreen={isScreenMd}
      disableConfirmButton={!old2FaCode || !new2faCode || !emailOTP}
    >
      {/* email otp input */}
      <Box className={cn(styles.emailInputContainer, styles.inputContainer)}>
        <div className={styles.label}>{t('account:emailCode')}</div>
        <OTPInput
          length={OTP_LENGTH}
          onChangeOTP={otp => onChangeEmailOTP(otp)}
          className={styles.otpInputContainer}
          inputClassName={cn(styles.verificationOtpInput, {
            [styles.invalidInput]: emailOtpError,
          })}
          resendOTP={sendCode}
          handleSubmit={resendOTP}
          autoFocus
          isNumberInput
          resendCodeText={t('common:resend')}
          resendCodeClassName={cn(styles.resendOtp, 'updatePswVerificationResendOtp')}
          // ref={recaptchaRef}
          renderRecaptcha={false}
        />
        <div className={styles.subText}>
          {`${t('auth:enterEmailCode')} ${hiddenEmail}`}
        </div>
        <Render when={emailOtpError}>
          <div className={styles.errorMsg}>{emailOtpError}</div>
        </Render>
      </Box>
      {/* old mfa input */}
      <Box className={cn(styles.mfaInput, styles.inputContainer)}>
        <div className={styles.label}>{t('auth:reset2faPopups.old2fa.title')}</div>
        <OTPInput
          // autoFocus
          isNumberInput
          hideSendCodeRightSection
          length={OTP_LENGTH}
          id="verification-2fa-input"
          className={styles.otpContainer}
          inputClassName={cn('otpInput', styles.verificationOtpInput, {
            [styles.invalidInput]: oldMfaError,
          })}
          onChangeOTP={otp => handleOld2faChange(otp)}
        />
        <div className={styles.subText}>
          {t('auth:reset2faPopups.old2fa.description')}
        </div>
        <Render when={oldMfaError}>
          <div className={styles.errorMsg}>{oldMfaError}</div>
        </Render>
      </Box>
      {/* new mfa input */}
      <Box className={cn(styles.mfaInput, styles.inputContainer)}>
        <div className={styles.label}>{t('auth:reset2faPopups.new2fa.title')}</div>
        <OTPInput
          // autoFocus
          isNumberInput
          hideSendCodeRightSection
          length={OTP_LENGTH}
          id="verification-2fa-input"
          className={styles.otpContainer}
          inputClassName={cn('otpInput', styles.verificationOtpInput, {
            [styles.invalidInput]: newMfaError,
          })}
          onChangeOTP={otp => handleNew2faChange(otp)}
        />
        <div className={styles.subText}>
          {t('auth:reset2faPopups.new2fa.description')}
        </div>
        <Render when={newMfaError}>
          <div className={styles.errorMsg}>{newMfaError}</div>
        </Render>
      </Box>
    </DeltaReactModal>
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={resendOTP}
      size="invisible"
      theme="dark"
      data-testid="recaptcha"
    />
  </>);
};

export default OTPVerification;
