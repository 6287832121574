import { branch, renderComponent } from 'recompose';
import { CenterLoader } from '../components/loader';

const loaderCompose = (showLoader, PrimaryComponent) =>
  branch(
    props => {
      return showLoader(props);
    },
    renderComponent(CenterLoader),
    renderComponent(PrimaryComponent)
  )();

export default loaderCompose;
