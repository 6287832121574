import React, { SVGProps } from 'react';

const BonusTick = ({ color = 'currentColor', ...props }: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  (<svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
    data-palette="BonusTick">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M2.339 3.804A1.465 1.465 0 0 1 3.804 2.34h.666c.387 0 .758-.153 1.032-.426l.466-.466a1.465 1.465 0 0 1 2.078 0l.466.466c.275.273.646.426 1.033.426h.666a1.465 1.465 0 0 1 1.465 1.465v.666c0 .386.153.758.426 1.032l.466.467a1.465 1.465 0 0 1 0 2.077l-.466.467a1.465 1.465 0 0 0-.426 1.032v.666a1.465 1.465 0 0 1-1.465 1.465h-.666c-.387 0-.758.153-1.033.426l-.466.466a1.465 1.465 0 0 1-2.078 0l-.466-.466a1.465 1.465 0 0 0-1.032-.426h-.666A1.465 1.465 0 0 1 2.34 10.21v-.666c0-.387-.153-.758-.426-1.032l-.466-.467a1.465 1.465 0 0 1 0-2.077l.466-.467c.273-.274.426-.645.426-1.032v-.666Z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="m5.003 7 1.332 1.333 2.664-2.664"
    />
  </svg>)
);

export default BonusTick;
