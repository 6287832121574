import { max, min } from 'ramda';

import store from '../store';

export interface ChartRealtimePayloadType {
  candle_start_time: number;
  close: number;
  high: number;
  last_updated: number;
  low: number;
  open: number;
  resolution: string;
  symbol: string;
  timestamp: number;
  type: string;
  volume: number;
}

const resolutionMap = value => {
  const map = {
    '1m': { value: '1', label: '1m' },
    '3m': { value: '3', label: '3m' },
    '5m': { value: '5', label: '5m' },
    '15m': { value: '15', label: '15m' },
    '30m': { value: '30', label: '30m' },
    '1h': { value: '60', label: '1h' },
    '2h': { value: '120', label: '2h' },
    '4h': { value: '240', label: '4h' },
    '6h': { value: '360', label: '6h' },
    '1d': { value: '1D', label: '1d' },
    '7D': { value: '7D', label: '7D' },
  };
  return map[value];
};

export const setTradingViewSubscriptionData = (payload: any) => {
  if (payload.symbolInfo.type === 'mark' || payload.symbolInfo.type === 'spot_index') {
    store.tradingViewSubscriptionData[payload.symbol] = payload;
  } else {
    store.tradingViewSubscriptionData[payload.subscribeUID] = payload;
  }
};

// export const setTradingViewSubscriptionDataLastBar = (payload: any) => {
//   store.tradingViewSubscriptionData.lastBar = payload;
// };

// export const clearTradingViewSubscriptionData = () => {
//   store.tradingViewSubscriptionData = {};
// };

export const updateTradingViewData = (data: ChartRealtimePayloadType) => {
  try {
    // create subscribeUID from data.symbol, format of subscribe UID is symbol_#_symbol_#_resolution
    const subscribeUID = `${data.symbol}_#_${data.symbol}_#_${
      resolutionMap(data.resolution).value
    }`;
    const { onRealtimeCallback, lastBar } =
      store.tradingViewSubscriptionData[subscribeUID];

    const newLastBar = {
      time: data.candle_start_time / 1000,
      open: data.open || lastBar.close,
      high: data.high || lastBar.close,
      low: data.low || lastBar.close,
      close: data.close || lastBar.close,
      volume: data.volume || null,
    };
    onRealtimeCallback(newLastBar);
  } catch (e) {
    console.error('DEBUG :: Error :: updateTradingViewData', e);
  }
};

export const updateSpotMarkData = data => {
  // MARK:BTCUSDT_#_MARK:BTCUSDT_#_1
  console.log('DEBUG updateSpotMarkData', data, store.tradingViewSubscriptionData);
  if (store.tradingViewSubscriptionData[data.symbol]) {
    const { symbolInfo, onRealtimeCallback, lastBar } =
      store.tradingViewSubscriptionData[data.symbol];
    // const lastBar = lastBar || {};
    const lastTime = lastBar?.time;
    const currentTime = new Date().getTime();
    if (symbolInfo?.name !== data.symbol) return data;
    if (currentTime > lastTime) {
      // lastUpdatedSocketTime = new Date();
      // markPriceUpdate = false;
      // indexPriceUpdate = false;
      const newLastBar = {
        time: currentTime,
        open: data.price || lastBar.close,
        high: data.price || lastBar.close,
        low: data.price || lastBar.close,
        close: data.price || lastBar.close,
        volume: null,
      };
      // console.log('DEBUG', {
      //   lastTime,
      //   currentTime,
      //   resolution,
      //   condition: lastTime,
      //   price:data.price,
      //   newLastBar
      // });
      onRealtimeCallback ? onRealtimeCallback(newLastBar) : () => {};
      store.tradingViewSubscriptionData.lastBar = newLastBar;
    } else {
      const newLastBar = {
        open: lastBar?.open ?? data.price,
        high: max(lastBar?.high, data.price),
        low: min(lastBar?.low, data.closingPrice),
        close: data.price,
        time: lastBar?.time,
        volume: null,
      };
      onRealtimeCallback ? onRealtimeCallback(newLastBar) : () => {};
      store.tradingViewSubscriptionData.lastBar = newLastBar;
    }
  }

  return data;
};
